import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { SpinnerService } from "../../../core/spinner/spinner.service";
import { LoaderService } from "../../../main/services/loader.service";
import { LoginServiceService } from "../../services/login-service.service";
import { VerifyMyAccountComponent } from "../verify-my-account/verify-my-account.component";
import { MatDialog } from "@angular/material/dialog";
import { PatientService } from "../../services/patient.service";
import { FindAccountRequest, FindAccountResponse } from "./find-my-account.model";
import { MessageService } from "../../../main";
import { ToastrService } from "ngx-toastr";
import { constants } from "../constant";
@Component({
  selector: 'app-find-my-account',
  templateUrl: './find-my-account.component.html',
  styleUrls: ['./find-my-account.component.scss']
})
export class FindMyAccountComponent implements OnInit {

  @Input() sliderFormData: any;

  @Output() noRecordFound = new EventEmitter<any>();
  @Output() goToConfirm = new EventEmitter<any>();

  @Output() goToEdit = new EventEmitter<any>();
  @Output() isVerified = new EventEmitter<any>();
  @Output() proceedLogin = new EventEmitter<any>();

  choices = [
    { Id: 1, KeyName: "Yes" },
    { Id: 2, KeyName: "No" },
  ];

  public firstName = new FormControl("", [
    Validators.required,
    Validators.pattern(".{1,50}$"), this.loader.validateAllowedCharacters(),
  ]);
  public birthName = new FormControl("", [
    Validators.required,
    Validators.pattern(".{1,50}$"), this.loader.validateAllowedCharacters(),
  ]);
  public dateOfBirth = new FormControl("", [Validators.required]);
  public gender = new FormControl("", [Validators.required]);

  public form = this.formBuilder.group({
    firstName: this.firstName,
    birthName: this.birthName,
    dateOfBirth: this.dateOfBirth,
    gender: this.gender,
  });

  genders: any[];

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  public maxDate: Date;
  accounts: FindAccountResponse[];
  constructor(
    public router: Router,
    private spinnerService: SpinnerService,
    public loader: LoaderService,
    private formBuilder: FormBuilder,
    public loginService: LoginServiceService,
    public translate: TranslateService,
    public dialog: MatDialog,
    public patientService: PatientService,
    public messageService: MessageService,
    public toastr: ToastrService
  ) {

    this.genders = new Array<any>();
  }
  ngOnInit(): void {
    this.maxDate = new Date();
    this.getGenders();
  }

  public getGenders() {
    this.loginService.getGenderMasters().subscribe((data: Array<any>) => {
      this.genders = data.sort((a, b) => a.KeyOrder - b.KeyOrder);
      this.gender.setValue(this.genders[0].Id.toString());
    });
  }

  clear() {
    this.form.reset();
    this.gender.setValue(this.genders[0].Id.toString());
  }

  restrictSpace(event: any) {
    const testVal = event.target.value.replace(/\s/g, '');
    event.target.value = testVal
  }

  findAccount() {
    if (this.form.valid && this.dateOfBirth.valid) {
      const datePipe = new DatePipe('en-US');
      const payload: FindAccountRequest =
      {
        first_name: this.firstName.value.trim(),
        birth_name: this.birthName.value.trim(),
        gender: parseInt(this.gender.value, 10),
        dob: datePipe.transform(this.dateOfBirth.value, 'yyyy-MM-dd')
      };
      this.patientService.findAccount(payload).subscribe(
        (response: FindAccountResponse[]) => {
          this.accounts = response;
          if (this.loader.userRole == constants.nurseRole) {
            if (response?.length == 0 || response == null) {
              this.noRecordFound.emit({ found: true, searchData: { firstName: this.firstName.value, birthName: this.birthName.value, gender: this.gender.value, dob: this.dateOfBirth.value } });
            }
            else {
              //this.loader.user_name = payload.first_name + " " + payload.birth_name;
              this.loader.dob = payload.dob;

              this.patientService.getPatientInfo(response[0].unique_id).subscribe((patRes: any) => {
                if (patRes) {
                  this.loader.email = patRes.email;
                  this.loader.bookingPatientId = patRes.id;
                  this.loader.user_name = patRes.first_name + " " + patRes.last_name;
                  if (this.loader.email == "") {
                    this.goToEdit.emit({ found: true, data: patRes, searchData: response });
                  } else {
                    this.goToConfirm.emit({ found: false, searchData: response });
                  }
                }
              });
            }
          } else {

            if (response?.length == 0 || response == null) {
              this.noRecordFound.emit({ found: true, searchData: { firstName: this.firstName.value, birthName: this.birthName.value, gender: this.gender.value, dob: this.dateOfBirth.value } });
            } else if (response?.length == 1 && response[0]?.is_already_linked_to_other_patient == true) {
              this.toastr.warning(this.translate.instant('add-member.duplicate-to-another-patient', { data: payload?.first_name + " " + payload?.birth_name }));
            }
            else if (response?.length == 1 && response[0]?.is_active == true) {
              this.openInformationModal(response[0].email);
            }
            else {
              let activeAccount = response?.filter(i => i.is_active == true);
              if (activeAccount.length == 0) {
                this.openVerifyAccountWindow();
              }
              else {
                this.openInformationModal(activeAccount.map(i => i.email).join(", "));
              }
            }
          }
          this.spinnerService.show(false);
        },
        () => {
          this.spinnerService.show(false);
        }
      );
    }
  }


  openVerifyAccountWindow() {
    const dialogRef = this.dialog.open(VerifyMyAccountComponent, {
      maxWidth: '90vw',
      width: "500px",
      height: 'auto',
      disableClose: false,
      autoFocus: false,
      restoreFocus: false,
      data: { dynamicData: this.accounts },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.isVerified.emit(result);
    });

  }

  openInformationModal(email: string) {
    this.messageService
      .confirm(
        this.translate.instant("find-account.active-account-msg-part2"),
        this.translate.instant("find-account.active-account-msg-part1"), undefined, undefined, undefined, undefined, email,
      )
      .subscribe((actionResult: boolean) => {
        if (actionResult) {
          this.proceedLogin.emit(true);
        }
      });
  }
}
