<div class="slider-data-container">
    <div class="header">
        <div class="pull-left">
            <app-section-title title="{{'update-email.update-email-title'|translate}}"></app-section-title>
        </div>
        <div class="pull-right">
            <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
                <span class="icon-ic-close"></span>
            </button>
        </div>
    </div>

    <form class="pt-30" [formGroup]="editEmailForm">
        <ng-container *ngIf="step==1">
            <div id="reset_form" class="form-layout">
                <mat-form-field>

                    <mat-label>{{'createAccount.email' | translate}}</mat-label>
                    <input type="email" matInput name="Email" maxlength="60" id="Email" formControlName="email" />
                    <mat-error *ngIf="!email.valid  && !email.hasError('emailExist')">{{'createAccount.valid-email' |
                        translate}}</mat-error>
                    <mat-error *ngIf="!email.valid && email.hasError('emailExist')">{{'update-email.current-email-new-email-not-same' |
                        translate}}</mat-error>

                </mat-form-field>
            </div>

            <div class="actions">
                <button mat-raised-button color="primary" class="button_width mr-20" type="submit" id="save_btn"
                    (click)="updateEmail()">
                    {{'update-email.submit' | translate}}
                </button>
            </div>
        </ng-container>



        <div id="otpinner_container" *ngIf="step==2">
            <p class="otp-header">{{'update-email.confirm-email' | translate}}{{email.value}}</p>
            <h3 class="otp-header">{{'login.otp-entermessage' | translate}}</h3>
            <mat-hint class="otp-hint">{{'login.otp-hint' | translate}}</mat-hint>
            <br>
            <ng-otp-input clas="otp" #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
            <div class="otp-counter">
                <h5>{{'login.otp-time-remaining' | translate}} : {{display}}</h5>
                <button [disabled]="btndisabled" color="primary" type="submit" class="btn-login" (click)="resetOTP()">
                    {{'login.otp-resend' | translate}} </button>
            </div>
            <div class="otp-buttons">
                <button mat-raised-button color="primary" type="submit" class="btn-login" (click)="verifylogIn()">
                    {{'login.otp-verify' | translate}} </button>
            </div>
        </div>
    </form>
</div>