import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PasswordPolicyState } from './password-policy-state.modal';

@Injectable({
    providedIn: 'root'
})
export class PasswordPolicyService {
    private passwordPolicySubject = new Subject<PasswordPolicyState>();
    policyState = this.passwordPolicySubject.asObservable();
    constructor() { }
    show(password: string, min: number, max: number) {
        this.passwordPolicySubject.next({
            show: true,
            password,
            min, max
        } as PasswordPolicyState);
    }
    hide() {
        this.passwordPolicySubject.next({
            show: false,
            password: ''
        } as PasswordPolicyState);
    }
    length(password: string, min: number, max: number) {
        return password && password.length >= min && password.length <= max;
    }

    capital(password: string) {
        return password && password.match(/[A-Z]/g);
    }

    small(password: string) {
        return password && password.match(/[a-z]/g);
    }

    number(password: string) {
        return password && password.match(/\d/g);
    }

    symbol(password: string) {
        return (
            password &&
            password.match(/([!@#$%^&*\.\-_+=[\]{}\\:'?/`~();])+/g)
        );
    }

    valid(password: string, min: number, max: number): any {
        return (
            (this.symbol(password) &&
                this.length(password, min, max) &&
                this.capital(password) &&
                this.small(password) &&
                this.number(password)) ||
            false
        );
    }
}
