import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LocaleService } from '../../../../app.locale.service';
import { LoaderService } from '../../../../main';
import { PatientAppointmentService } from '../../../services/patient-appointment.service';
import { SpinnerService } from '../../../../core/spinner/spinner.service';

@Component({
  selector: 'app-relative-member-upcoming-apointment',
  templateUrl: './relative-member-upcoming-apointment.component.html',
  styleUrls: ['./relative-member-upcoming-apointment.component.scss']
})
export class RelativeMemberUpcomingApointmentComponent implements OnInit {
  @Input() displayedColumns: any;
  dataSource: any;
  @Output() editButtonClicked = new EventEmitter();
  @Output() cancelButtonClicked = new EventEmitter();
  @Output() viewPdf = new EventEmitter();
  @Output() uploadPresc = new EventEmitter();
  @Output() viewPdfMobile = new EventEmitter();
  @Output() deletePdfMobile = new EventEmitter();
  @Output() appointmentCnt = new EventEmitter();
  totalRecords: number;
  pageSize: number = 10;
  currentPage: number = 1;
  refresh: boolean = true;
  sub: any;
  isMobile: boolean = false;
  constructor(private translate: TranslateService,
    private localeService: LocaleService,
    public loader: LoaderService,
    private spinner: SpinnerService,
    private patientAppointmentService: PatientAppointmentService) {
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
  }

  ngOnInit(): void {
    this.getAppointment();
    this.sub = this.loader.refreshDialog.subscribe((isRefresh) => {
      if (isRefresh) {
        this.getAppointment();
      }
    });
    this.loader.subjectClose.subscribe(() => this.getAppointment());
    if (screen.width < 962) { this.isMobile = true; }
  }

  onPageChange(evt: any) {
    this.currentPage = evt?.pageIndex + 1;
    this.pageSize = evt?.pageSize;
    this.getAppointment();
  }

  public getAppointment() {
    this.spinner.show(true);
    this.refresh = false;
    this.patientAppointmentService.getAppointments(JSON.parse(sessionStorage.getItem('userDetails')).id, true, this.pageSize, "future_relative", this.currentPage).subscribe((appointmentDetails: any) => {
      this.dataSource = [];
      if (appointmentDetails !== null) {
        appointmentDetails = appointmentDetails.map((details: any) => { return { ...details, isOpen: false, history: [] } });
        this.totalRecords = appointmentDetails[0]?.Total;
        this.appointmentCnt.emit(this.totalRecords);
        this.dataSource = appointmentDetails;
        if (this.loader.loginRedirect) {
          const index = appointmentDetails.findIndex((item: any) => item.AppointmentId == this.loader.loginRedirect);
          if (index >= 0) {
            //this.getSlots(appointmentDetails[index].SamplingStationId, appointmentDetails[index].TestId, appointmentDetails[index]);
            this.loader.redirectRelMem = true;
            this.editButtonClicked.emit({ 'row_data': appointmentDetails[index] })
            this.loader.loginRedirect = null;
          }
        }
      }
      this.refresh = true;
      this.spinner.show(false);
    });
  }

  checkDataForDelete(element: any) {
    if (element.ArrivedTime != "" || element.IsDeleted || element.StatusId != 0) {
      return true;
    }
    const st = new Date(element.SlotTime);
    if (st.getHours() > 0 || st.getMinutes() > 0) {
      return moment(element.SlotTime)
        .startOf('second')
        .isBefore(moment().startOf('second'));
    } else {
      return moment(element.SlotTime)
        .startOf('date')
        .isBefore(moment().startOf('date'));
    }
  }

  checkDateForUploadPrescription(element: any) {
    if (element.ArrivedTime != "" || element.IsDeleted || element.PrescriptionUrl != '' || !element.IsPrescriptionRequired) {
      return true;
    }
    const st = new Date(element.SlotTime);
    if (st.getHours() > 0 || st.getMinutes() > 0) {
      return moment(element.SlotTime)
        .startOf('second')
        .isBefore(moment().startOf('second'));
    } else {
      return moment(element.SlotTime)
        .startOf('date')
        .isBefore(moment().startOf('date'));
    }
  }

  checkDate(element: any) {
    if (element.SlotTime == null || element.SlotTime == "" || new Date(element.SlotTime).getFullYear() == 1 || element.ArrivedTime != "") {
      return true;
    }
    const st = new Date(element.SlotTime);
    if (st.getHours() > 0 || st.getMinutes() > 0) {
      return moment(element.SlotTime)
        .startOf('second')
        .isBefore(moment().startOf('second'));
    } else {
      return moment(element.SlotTime)
        .startOf('date')
        .isSameOrBefore(moment().startOf('date'));
    }

  }

  openDirections(station: any) {
    event.stopPropagation();
    const lat = Number(
      station.Coordinates.substring(
        station.Coordinates.indexOf(":") + 1,
        station.Coordinates.indexOf(",")
      )
    ).toString();
    const lng = Number(
      station.Coordinates.substring(
        station.Coordinates.lastIndexOf(":") + 1,
        station.Coordinates.indexOf("}")
      )
    ).toString();
    window.open(
      "https://www.google.com/maps/dir/?api=1&destination=" +
      lat +
      "," +
      lng +
      "&travelmode=driving",
      "_blank"
    );
  }

  getHistory(element: any) {
    if (element.isOpen == false && element.IsModified) {
      element.isOpen = true;
      this.patientAppointmentService.getAppointmentHistory(element.AppointmentId).subscribe((appHistory: any) => {
        appHistory?.forEach((appointment: any) => {
          element.history.push(appointment.slot_time);
        })
        element.history.reverse();
      })
    } else {
      element.isOpen = false;
      element.history = [];
    }
  }

}
