import { Component, Input, OnInit } from '@angular/core';
import { DrawerComponent } from '../../../core';
import { LoaderService } from '../../../main';
import { PatientService } from '../../services/patient.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, DrawerComponent {
  @Input() sliderFormData: any;

  constructor(
    private loaderService: LoaderService,
    private patientService: PatientService,
    protected translate: TranslateService,
  ) { }

  isSliderDataEdited?: boolean;

  ngOnInit(): void {
  }

  public dismiss() {
    this.loaderService.cancelForm();
  }

  public markNotificationRead(notification: any) {
    this.patientService.putNotification(notification.Id.toString()).subscribe(
        () => {
            this.patientService.getNotification().subscribe(res => {
            this.sliderFormData = res;
        });
      }
    );
  }

  public markAllNotificationRead() {
    let notificationids = (this.sliderFormData.reduce((ids: string, notification: any) => ids = ids + ',' + notification.Id, "").substring(1));
    this.patientService.putNotification(notificationids).subscribe(
      () => {
          this.patientService.getNotification().subscribe(res => {
          this.sliderFormData = res;
      });
    }
  );
  }

  public routeLink(notification: any) {
    switch (notification.NotificationType.split('|')[0]) {
      case 'PASSWORD_EXPIRE_NOTIFICATION' : {
        this.dismiss();
        this.loaderService.highlightLeftMenu(["patient/info"]);
        break;
      }

      case 'EXPIRED_DOCUMENT_NOTIFICATION' : {
        this.dismiss();
        this.loaderService.highlightLeftMenu(["patient/documents"]);
        break;
      }

      case 'MISSING_DOCUMENT_NOTIFICATION' : {
        this.dismiss();
        this.loaderService.highlightLeftMenu(["patient/documents"]);
        break;
      }
    }
  }
}
