<div class="create-establishment-container">
    <div class="header flex-box">
        <div class="pull-left">
            <div class="heading d-flex">
                <span class="title">
                    {{ sliderFormData[0].edit ? ('add-establish.edit-heading' | translate) :
                    ('add-establish.add-heading' | translate) }}
                </span>
            </div>
        </div>
        <div class="pull-right">
            <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
                <span class="icon-ic-close"></span>
            </button>
        </div>
    </div>

    <form [formGroup]="EstablishForm" #formDirective="ngForm" class="input-min-spacing">
        <div class="createAccountwrapper">
            <mat-form-field>
                <mat-label>{{'add-establish.establish-name'|translate}}</mat-label>
                <input matInput name="EstablishName" id="EstablishName" [formControl]="establishName"
                    formControlName="establishName" />
                <mat-error
                    *ngIf="EstablishForm.get('establishName').hasError('pattern')">{{'add-establish.invalid-name'|translate}}
                </mat-error>
                <mat-error
                    *ngIf="EstablishForm.get('establishName').hasError('maxlength')">{{'add-establish.invalid-name'|translate}}</mat-error>
            </mat-form-field>

            <mat-form-field def="Status">
                <mat-label>{{'establishment-list.status'|translate}}</mat-label>
                <mat-select placeholder="Status" formControlName="status" required="true" name="status">
                    <mat-option [value]="true">{{'establishment-list.active'|translate}}</mat-option>
                    <mat-option [value]="false">{{'establishment-list.suspended'|translate}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-label class="label-float" appearance="none">
                    {{ 'establishment-list.department' | translate }}
                </mat-label>

                <mat-select formControlName="department" [disableOptionCentering]="true" multiple>
                    <mat-select-trigger>
                        <ng-container
                            *ngIf="(EstablishForm.get('department')?.value || [])?.length > 0; else placeholder">
                            <span class="selected-chip"
                                *ngFor="let deptId of (EstablishForm.get('department')?.value || []).slice(0, 2)"
                                [matTooltip]="getTruncatedDepartmentName(deptId).tooltip"
                                [matTooltipDisabled]="!getTruncatedDepartmentName(deptId).tooltip"
                                matTooltipPosition="above">
                                {{ getTruncatedDepartmentName(deptId).displayName }}
                            </span>
                            <span *ngIf="(EstablishForm.get('department')?.value || []).length > 2" class="more-text"
                                [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                                +{{ (EstablishForm.get('department')?.value || []).length - 2 }} more
                            </span>
                        </ng-container>
                        <ng-template #placeholder>Select Departments</ng-template>
                    </mat-select-trigger>

                    <!-- Department Options -->
                    <mat-option *ngFor="let dept of departments" [value]="dept.id">
                        {{ dept.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Mat Menu for Additional Departments -->
            <mat-menu #menu="matMenu" class="establish-popover">
                <div class="arrow-top"></div>
                <ng-container *ngFor="let deptId of (EstablishForm.get('department')?.value || []).slice(2)">
                    <button mat-menu-item>{{ getDepartmentName(deptId) }}</button>
                </ng-container>
            </mat-menu>


            <mat-form-field>
                <mat-label>{{'add-establish.address'|translate}}</mat-label>
                <input matInput type="text" id="autocompleteinput" name="address"
                    placeholder="{{'createAccount.placeholder-address' | translate}}" autocomplete="off"
                    [formControl]="address" />
                <mat-error *ngIf="EstablishForm.get('address')?.hasError('invalidChars') && address.invalid">
                    {{'createAccount.invalid-char'| translate}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="actions">
            <div class="inner-btn">
                <button *ngIf="!sliderFormData[0].edit" mat-button color="accent" class="ghost button_width mr-20"
                    type="button" (click)="reset()" id="btn_clear">
                    {{'add-establish.clear'|translate}}
                </button>
                <button *ngIf="sliderFormData[0].edit" mat-button color="accent" class="ghost button_width mr-20"
                    type="button" (click)="dismiss()" id="btn_clear">
                    {{'department-list.cancel'|translate}}
                </button>
                <button mat-raised-button color="primary" class="button_width" type="submit" id="btn_save"
                    (click)="onSave()">
                    {{'add-establish.save'|translate}}
                </button>
            </div>
        </div>
    </form>
</div>