import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
import { ActivatedRoute} from '@angular/router';
import { SpinnerService } from '../../../core/spinner/spinner.service';
import { RelativeMemberService } from '../../services/relative-member.service';
import { RMData } from '../relative-members/relative-member-data.model';
import { LoaderService } from '../../../main';
@Component({
    selector: 'app-qr-code-comp',
    templateUrl: './qr-code-comp.component.html',
    styleUrls: ['./qr-code-comp.component.scss']
})
export class QrCodeCompComponent implements OnInit {
    firstName: string;
    lastName: string;
    qr_id: string;
    details: any;
    tabIndex: number = 0;
    patientId = '';
    public RM_DATA: any[];

    constructor(
        private route: ActivatedRoute,
        private translate: TranslateService,
        private spinner: SpinnerService,
        public loader: LoaderService,
        private rmService: RelativeMemberService,
        private localeService: LocaleService
    ) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
        this.tabIndex = this.route.snapshot.params["tabIndex"];
    }

    ngOnInit(): void {
        this.details = JSON.parse(sessionStorage.getItem('userDetails'));
        // this.name=this.details.first_name+" "+this.details?.last_name;
        this.firstName = this.details.first_name;
        this.lastName = this.details.last_name;
        this.qr_id = this.details.qr_id;
        this.patientId = this.details.id;
        this.getRelativeMemberData(this.patientId);
        this.loader.onFormClose().subscribe(() => {
            this.getRelativeMemberData(this.patientId);
        });
    }
    getRelativeMemberData(patientId: string) {
        this.spinner.show(true);
        this.rmService.getRmData(patientId).subscribe((data: RMData[]) => {
          this.RM_DATA = data;
          this.spinner.show(false);
    
        }, () => {
          this.spinner.show(false);
        })
    }
    onTabChange() {

        //this.loader.highlightLeftMenu(['admin/landing-page-config/' + event.index]);
      }
}
