import { OverlayRef } from '@angular/cdk/overlay';
import { ElementRef, InjectionToken } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

/** Class for OverlayPopupConfig */
export class OverlayPopupConfig<D = any> {
    public element?: ElementRef;
    public width?: number;
    public height?: number;
    public data?: D | null = null;
    public displayPointer?: boolean;
    public offsetStartX?: number;
    public offsetStartY?: number;
    public offsetEndX?: number;
    public offsetEndY?: number;
    public hasBackdrop?: boolean;
}

/** Class for OverlayPopupRef */
export class OverlayPopupRef<T, R = any> {

    /* #region Fields */
    public componentInstance: T;

    private readonly _afterClosed = new Subject<R | undefined>();
    private _result?: R;
    /* #endregion */

    constructor(private readonly overlayRef: OverlayRef) {
        overlayRef.detachments().subscribe(() => {
            this._afterClosed.next(this._result);
            this._afterClosed.complete();
        });

        this.afterClosed().subscribe(() => {
            this.overlayRef.detach();
            this.overlayRef.dispose();
            this.componentInstance = null;
        });

        overlayRef.keydownEvents()
            .pipe(filter(event => event.key === 'Escape'))
            .subscribe(() => this.close());
    }

    /* #region Public Methods */
    /** Method to subscribe to after closed */
    public afterClosed(): Observable<R | undefined> {
        return this._afterClosed.asObservable();
    }

    /** Method to close overlayRef */
    public close(dialogResult?: R): void {
        this._result = dialogResult;
        this.overlayRef.dispose();
    }
    /* #endregion */
}

export const MAT_OVERLAY_DATA = new InjectionToken<any>('MatDialogData');
