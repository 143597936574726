export class FileData {
    ID: number;
    Content: File;
    Name: string;
    Type: string;
    Size: number;
    ModifiedAt: Date;
    constructor(file: File) {
        this.Content = file;
        this.Name = file.name;
        this.Size = file.size;
        this.Type = file.type;
        this.ModifiedAt = new Date(file.lastModified);
        this.ID = Math.floor(Math.random() * 999) + 1 ;
    }
}
