import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../../../main/services/message.service';
import { CommonApiService } from '../../../services/common-api.service';
import { Company } from '../../../models/common.model';
import { SamplingStations } from '../../user/User';
import { DatePipe } from '@angular/common';
import { SpinnerService } from '../../../../core';
import { HttpErrorResponse } from '@angular/common/http';
import { MasterData } from '../../ss-slot-configuration/ss-slot-configuration.model';
import { constants } from '../../../shared/constant';

@Component({
  selector: 'app-add-holiday',
  templateUrl: './add-holiday.component.html',
  styleUrls: ['./add-holiday.component.scss']
})
export class AddHolidayComponent implements OnInit {
  public ssLevelCode: string = constants.ssLevelCode;
  public companyLevelCode: string = constants.companyLevelCode;
  public countryLevelCode: string = constants.countryLevelCode;
  public title: string = 'add-holiday.add';
  public editMode = false;
  public HolidayForm: FormGroup;
  public disableDropDowns = true;
  public fromMinDate: Date = new Date();
  public toMinDate: Date = new Date();
  public todaytDate: Date = new Date();
  public start_time: any;
  public end_time: any;
  public StartDate = new FormControl();
  //public Company = new FormControl();
  public EndDate = new FormControl();
  public HolidayType = new FormControl();
  public companies: Company[] = null;
  public reasonMaxLength = 500;
  public Reason = new FormControl("", [Validators.max(this.reasonMaxLength), Validators.maxLength(this.reasonMaxLength)]);
  public samplingStations: any[];
  public allSamplingStations: any[];
  public selectedSamplingStation: any[];
  public disableEndDate: boolean = false;
  public disableSTdate: boolean = false;
  public saveAlert = {
    edit: "admin.save-alert.edit",
    add: "admin.save-alert.add",
  };
  // public todayDate
  selectedLevel: MasterData;
  constructor(private readonly dialogRef: MatDialogRef<AddHolidayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder, public translateService: TranslateService, private commonAPi: CommonApiService,
    public messageService: MessageService, private spinner: SpinnerService
  ) {
    this.selectedSamplingStation = new Array<any>();
    this.allSamplingStations = new Array<any>();
    this.samplingStations = new Array<SamplingStations>();
  }

  ngOnInit(): void {
    const currentDate = new Date();
    this.selectedLevel = this.data.selectedLevel
    if (this.data.edit == false) {
      this.StartDate.setValue(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
      this.EndDate.setValue(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
      this.title = 'add-holiday.add';
      this.editMode = false;
      this.HolidayType.setValue(this.selectedLevel);
      // today_date = this.StartDate
    }

    this.HolidayForm = this.fb.group({
      Company: [''],
      SamplingStationsIds: [''],
      StartDate: [this.StartDate, [Validators.required]],
      EndDate: [this.EndDate, [Validators.required]],
      Reason: this.Reason,
      HolidayType: this.HolidayType
    });
    this.commonAPi.GetAllCompany().subscribe(x => {
      this.companies = x;
      this.companies.sort((a, b) => {
        const nameA = a.org_name.toLowerCase().trim();
        const nameB = b.org_name.toLowerCase().trim();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.getAllSamplingStations();
    });

  }

  private getAllSamplingStations() {
    this.commonAPi.GetAllSamplingStations(true).subscribe((stationsList: any) => {
      this.allSamplingStations = stationsList.sampling_stations;
      this.samplingStations = stationsList.sampling_stations;
      this.selectedSamplingStation = stationsList.sampling_stations;
      if (this.data.edit) {
        this.bindHolidayData();
      }
    });
  }

  bindHolidayData() {
    let selectedHoliday = this.data.holiday;
    let s_date = new Date(selectedHoliday.start_date);
    let e_date = new Date(selectedHoliday.end_date);
    this.StartDate.setValue(new Date(s_date.getFullYear(), s_date.getMonth(), s_date.getDate()));
    this.EndDate.setValue(new Date(e_date.getFullYear(), e_date.getMonth(), e_date.getDate()));
    let t_date = new Date( new Date().setHours(0,0,0)).getTime();
    let Start_date = new Date(s_date.setHours(0,0,0)).getTime();
    if (t_date > Start_date){
      this.disableSTdate= true;
    }
    this.title = 'add-holiday.edit';
    this.editMode = true;
    this.HolidayType.setValue(this.data.selectedLevel);
    //this.selectedLevel = this.data.levels.find((i: MasterData) => i.Id == this.data.levelId);

    //let selectedHoliday = this.data.holiday;
    let selectedCompanies = this.companies.filter(item => selectedHoliday.org_id == item.id);
    let selectedSS = this.allSamplingStations.filter(item => selectedHoliday.ss_id == item.id);
    this.ssFilterOnCompany(selectedCompanies);
    this.HolidayForm.patchValue({
      Company: selectedCompanies,
      SamplingStationsIds: selectedSS,
      StartDate: this.StartDate.value,
      EndDate: this.EndDate.value,
      Reason: selectedHoliday.reason,
      HolidayType: this.selectedLevel
    });

    this.spinner.show(false);
  }
  onSelectedDate(e: any) {
    this.changeMinDateInDatePicker(e);
  }

  changeMinDateInDatePicker(inputData: any) {
    const datePipe = new DatePipe('en-US');

    const formattedDate = datePipe.transform(inputData, 'dd-MM-yyyy');
    if (formattedDate == null) {
      return;
    }

    const splittedDateArray = formattedDate.split('-');
    const year = Number(splittedDateArray[2]);
    const month = Number(splittedDateArray[1]) - 1;
    const date = Number(splittedDateArray[0]);
    this.toMinDate = new Date(year, month, date);
    if (this.disableEndDate) {
      this.EndDate.setValue(this.StartDate.value);
    }
    else if (this.StartDate.value > this.EndDate.value) {
      this.EndDate.setValue(this.StartDate.value);
    }
  }

  allChecked(event: any) {
    if (event) {
      this.samplingStations = this.allSamplingStations;
      this.disableDropDowns = false;

    } else {
      this.HolidayForm.controls['SamplingStationsIds'].setValue([]);
      this.samplingStations = [];
      this.disableDropDowns = true;
    }
  }

  ssFilterOnCompany(event: any) {
    if (event.length == 0) {
      this.disableEndDate = false;
      this.disableDropDowns = true;
    }
    else if (event.length > 1) {
      this.setEndDate();
    }
    else {
      this.disableEndDate = false;
      this.disableDropDowns = false;

    }
    this.samplingStations = Object.assign([], this.selectedSamplingStation);
    const cArr = event.map((item: any) => item.id);
    const myArrayFiltered = this.samplingStations.filter((el: any) => {
      return cArr.some((f: any) => {
        return f === el.org_id;
      });
    });
    this.samplingStations = myArrayFiltered;


    let selectedSS = this.HolidayForm.controls['SamplingStationsIds'].value;
    let filterSelectedSS: any = [];
    if (selectedSS) {
      selectedSS.forEach((element: any) => {
        let availableSS = this.samplingStations.filter(i => i.id == element.id);
        if (availableSS.length > 0) {
          filterSelectedSS.push(availableSS[0]);
        }
      });
    }
    if (event.length > 0) {
      this.HolidayForm.controls['SamplingStationsIds'].setValue(filterSelectedSS);
    }
    else {
      this.HolidayForm.controls['SamplingStationsIds'].setValue(0);
    }
  }


  onSaveClick() {
    if (this.HolidayForm.valid) {
      let popupRef = this.messageService
        .confirm(
          this.translateService.instant('landing-page-config.save-confirm-description'),
          this.translateService.instant('landing-page-config.save-title'),
          this.translateService.instant('landing-page-config.yes'),
          this.translateService.instant('landing-page-config.no')
        );

      popupRef.subscribe((actionResult) => {
        if (actionResult) {
          const datePipe = new DatePipe('en-US');
          let start_date = datePipe.transform(this.StartDate.value, 'yyyy-MM-dd');
          let end_date = datePipe.transform(this.EndDate.value, 'yyyy-MM-dd');
          if (this.data.edit) {
            this.updateHoliday(start_date, end_date);
          } else {
            this.addHoliday(start_date, end_date);
          }
        }
      });
    }
  }

  addHoliday(start_date: string, end_date: string) {
    let sub_url = "common/specialdate";
    let payload = {
      "org_ids": [0],
      "sampling_station_ids": [0],
      "start_date": start_date,
      "end_date": end_date,
      "numofdays": this.getDifferenceInDays(
        new Date(this.StartDate.value),
        new Date(this.EndDate.value)
      ),
      "comment": this.Reason.value,
      "level": this.HolidayType.value?.Id
    };
    if (this.selectedLevel.Key == this.companyLevelCode) {
      let selectedCompany = this.HolidayForm.get("Company").value.map((item: any) => item.id);
      payload = {
        "org_ids": selectedCompany,
        "sampling_station_ids": Array.from({ length: selectedCompany.length }, (v: any, k: any) => { v; k; return 0 }),
        "start_date": start_date,
        "end_date": end_date,
        "numofdays": this.getDifferenceInDays(
          new Date(this.StartDate.value),
          new Date(this.EndDate.value)
        ),
        "comment": this.Reason.value,
        "level": this.HolidayType.value?.Id
      };
    }
    else if (this.selectedLevel.Key == this.ssLevelCode) {
      payload["org_ids"] = this.HolidayForm.get("SamplingStationsIds").value.map((item: any) => item.org_id);
      payload["sampling_station_ids"] = this.HolidayForm.get("SamplingStationsIds").value.map((item: any) => item.id);
    }
    this.commonAPi.addHoliday(payload, sub_url).subscribe(
      () => {
        this.spinner.show(false);
        this.messageService.success(
          this.translateService.instant(this.saveAlert[this.data.action])
        );
        this.dialogRef.close(null);
      },
      (errorResponse: HttpErrorResponse) => {
        this.spinner.show(false);
        console.log(errorResponse);
        if (errorResponse.error.code === 400) {
          this.messageService.alert(
            this.translateService.instant("add-holiday.error.400")
          );
        }
        else {
          this.messageService.alert(
            this.translateService.instant("add-holiday.error.422")
          );
        }
        this.dialogRef.close(null);
      }
    );
  }

  updateHoliday(start_date: any, end_date: any) {
    let sub_url = "common/specialdate";
    let payload = {
      "id": this.data.holiday.id,
      "org_id": this.data.holiday.org_id,
      "sampling_station_id": this.HolidayForm.get("SamplingStationsIds").value.map((item: any) => item.id)[0],
      "start_date": start_date,
      "end_date": end_date,
      "numofdays": this.getDifferenceInDays(
        new Date(this.StartDate.value),
        new Date(this.EndDate.value)
      ),
      "comment": this.HolidayForm.get("Reason").value,
      "level": this.HolidayType.value.Id,
      "link_time": this.data.holiday.link_time,
      "reference_id": this.data.holiday.reference_id
    };

    if (this.selectedLevel.Key == this.companyLevelCode) {
      payload["sampling_station_id"] = 0;
    }
    else if (this.selectedLevel.Key == this.countryLevelCode) {
      payload["org_id"] = 0;
      payload["sampling_station_id"] = 0;
    }

    this.commonAPi.updateHoliday(payload, sub_url).subscribe(
      () => {
        this.spinner.show(false);
        this.messageService.success(
          this.translateService.instant(this.saveAlert[this.data.action])
        );
        this.dialogRef.close(null);
      },
      (errorResponse: HttpErrorResponse) => {
        this.spinner.show(false);
        console.log(errorResponse);
        if (errorResponse.error.code === 400) {
          this.messageService.alert(
            this.translateService.instant("add-holiday.error.400")
          );
        }
        else {
          this.messageService.alert(
            this.translateService.instant("add-holiday.error.422")
          );
        }
        this.dialogRef.close(null);
      }
    );
  }

  getDifferenceInDays(date1: any, date2: any) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24) + 1;
  }

  holidayTypeChange(eventValue: any) {
    this.selectedLevel = eventValue;
    switch (this.selectedLevel.Key) {
      case this.ssLevelCode:
        this.HolidayForm = this.fb.group({
          Company: [this.HolidayForm.controls['Company'].value, [Validators.required]],
          SamplingStationsIds: ['', [Validators.required]],
          StartDate: [this.StartDate, [Validators.required]],
          EndDate: [this.EndDate, [Validators.required]],
          Reason: this.Reason,
          HolidayType: this.HolidayType
        })
        break;
      case this.companyLevelCode:
        this.HolidayForm = this.fb.group({
          Company: [this.HolidayForm.controls['Company'].value, [Validators.required]],
          SamplingStationsIds: [[0]],
          StartDate: [this.StartDate, [Validators.required]],
          EndDate: [this.EndDate, [Validators.required]],
          Reason: this.Reason,
          HolidayType: this.HolidayType
        })
        break;
      case this.countryLevelCode:
        this.HolidayForm = this.fb.group({
          Company: [[0]],
          SamplingStationsIds: [[0]],
          StartDate: [this.StartDate, [Validators.required]],
          EndDate: [this.EndDate, [Validators.required]],
          Reason: this.Reason,
          HolidayType: this.HolidayType
        })
        break;

      default:
        this.HolidayForm = this.fb.group({
          Company: [this.HolidayForm.controls['Company'].value, [Validators.required]],
          SamplingStationsIds: ['', [Validators.required]],
          StartDate: [this.StartDate, [Validators.required]],
          EndDate: [this.EndDate, [Validators.required]],
          Reason: this.Reason,
          HolidayType: this.HolidayType
        })
        break;
    }
  }

  filterOnSS(ssList: any) {
    if (ssList.length > 1) {
      this.setEndDate();
    }
    else {
      this.disableEndDate = false;

    }
  }

  setEndDate() {
    this.EndDate.setValue(this.StartDate.value);
    this.disableEndDate = true;
  }

  clear() {
    this.HolidayForm.reset();
  }

}
