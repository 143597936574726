import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {

  transform(date: Date, startTime: string, endTime: string): string {
    const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const formattedStartTime = this.formatTime(startTime);
    const formattedEndTime = this.formatTime(endTime);

    return `${formattedDate} | ${formattedStartTime} - ${formattedEndTime}`;
  }

  formatTime(time: string): string {
    const splitTime = time.split(':');
    const hours = parseInt(splitTime[0], 10);
    const minutes = parseInt(splitTime[1], 10);
    let hh = hours > 9 ? `${hours}` : `0${hours}`;
    let mm = minutes > 9 ? `${minutes}` : `0${minutes}`;
    // const ampm = hours >= 12 ? 'PM' : 'AM';
    // const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    // const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${hh}:${mm}`;
  }

}
