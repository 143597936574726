<div class="iframe-container" (click)="closePdf()">
  <div class="iframe-overlay" (click)="$event.stopPropagation();">
    <div class="image-header" [class.image-loaded]="isPdfLoaded">
      <div class="page-section">
        <h3>{{pdftitle}}</h3>
        <div class="page-all only-mobile" *ngIf="allPages">
          <p>{{'receptionist.page' | translate}} ({{pdfPage}} / {{totalPage}})</p>
        </div>
      </div>
      <div class="page-navigate" *ngIf="!allPages">
        <mat-form-field def="TestType">
          <mat-select [(ngModel)]="pageModel">
            <mat-option *ngFor="let page of pages" [value]="page">{{page}}</mat-option>
          </mat-select>
        </mat-form-field>
        <span class="totalPage">{{totalPage}}</span>
      </div>
      <div class="page-all only-web" *ngIf="allPages">
        <p>{{'receptionist.page' | translate}} ({{pdfPage}} / {{totalPage}})</p>
      </div>
      <div>
        <button *ngIf="replacePrescription" mat-raised-button color="primary" (click)="replacePres()"
          type="submit">{{'view-prescription.replace-prescription' | translate}}</button>
        <button class="slider_closebtn ml-10" mat-button color="accent" (click)="closePdf()">
          <span class="icon-ic-close"></span>
        </button>
      </div>
    </div>
    <pdf-viewer class="pdf-view" [src]="blobSrc" [(page)]="pdfPage" (after-load-complete)="afterLoadComplete($event)"
      [show-all]="allPages" [fit-to-page]="true" [render-text]="true"></pdf-viewer>
    <!-- <div class="download-pdf">
      <button mat-raised-button color="primary" (click)="downloadPres(blobSrc)" type="submit">{{'image-viewer.download'
        | translate}} {{pdftitle}}</button>

      <button class="only-mobile" mat-raised-button color="primary" (click)="closePdf()"
        type="submit">{{'upload-pic.close' | translate}}</button>
    </div> -->
    <div class="only-web" [ngClass]="patientComments ? 'image-footer':'image-footer align-center'">
      <div *ngIf="patientComments">
        <div class="comments-text">{{'image-viewer.patient-comments' | translate}}: </div>
        <div class="comments-content">{{patientComments}}</div>
      </div>
      <button mat-raised-button color="primary" (click)="downloadPres(blobSrc)" type="submit">{{'image-viewer.download'
        | translate}}</button>
    </div>
    <div class="only-mobile image-footer-mobile">
      <div [ngClass]="patientComments ? 'section':'section align-center'">
      <button mat-button color="primary" class="ghost" (click)="downloadPres(blobSrc)" type="submit">{{'image-viewer.download'
        | translate}}</button>
        <div *ngIf="patientComments">
          <div class="view-comment" (click)="viewComment = !viewComment">{{(viewComment ? 'image-viewer.hide-comment' : 'image-viewer.view-comment') | translate}}</div>
        </div>
      </div>
      <div class="section">
        <div></div>
        <div class="comments read-more sb10" *ngIf="viewComment">{{patientComments}}</div>
      </div>
    </div>
  </div>
</div>