<table class="ecl-table" width="100%" mat-table [dataSource]="appointments">
    <ng-container matColumnDef="recordNo">
        <th mat-header-cell *matHeaderCellDef class="wp-15"> {{'appointments.record-no' |
            translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <span class="title" *ngIf="element.AppointmentCode">{{element.AppointmentCode}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="bookedSlot">
        <th mat-header-cell *matHeaderCellDef class="wp-15">{{'patient-info.booked-slot' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <span class="title">{{element?.SlotTime | dateFormat}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="testDetails">
        <th mat-header-cell *matHeaderCellDef class="wp-15">{{'patient-info.test-details' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <span class="title">{{element?.TestShortName}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="lab">
        <th mat-header-cell *matHeaderCellDef class="wp-15">{{'patient-info.lab' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <span class="title">{{element?.SamplingStationName}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="wp-15">
        </th>
        <td mat-cell *matCellDef="let element" class="force-display">
            <span style="height: 36px;"></span>
            <button class="mat-tbl-icon" matTooltip="{{'appointments.view-prescription' | translate}}"
                matTooltipClass="tooltip-style" mat-icon-button (click)="viewPrecription(element)"
                *ngIf="element?.PrescriptionUrl != ''">
                <span class="icon-ic-view-prescription"></span>
            </button>
            <ng-container *ngIf="element?.IsTestKitRequired && element.IsArrived">
                <button (click)="redirectRegisterTestKit(element)" class="mat-tbl-icon" matTooltip="{{'patient-info.register-test-kit' |
                    translate}}" [disabled]="(element.SamplingStationId !==loader.ss_id)"
                    matTooltipClass="tooltip-style" mat-icon-button>
                    <span class="icon-ic-register-testkit"></span>
                </button>
            </ng-container>
            <button *ngIf="!element.IsArrived && !element.IsDeleted && checkIsTodayDate(element)"
                [disabled]="(element.SamplingStationId !==loader.ss_id)"
                (click)="confirmArrival.emit({'appointment':element})" class="mat-tbl-icon"
                matTooltip=" {{'receptionist.confirm-arrival' | translate}}" matTooltipClass="tooltip-style"
                mat-icon-button>
                <span class="icon-ic-confirm-arrival"></span>
            </button>
            <div class="isArrived" *ngIf="element.IsArrived">{{'appointments.arrived' | translate}}</div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
</table>