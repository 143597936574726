import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
import { LoaderService } from '../../../main/services/loader.service';
import { PatientService } from '../../services/patient.service';
import { MessageService } from '../../../main/services/message.service';
import { NgOtpInputConfig } from "ng-otp-input";
import { AuthenticationService } from '../../services/authentication.service';
import { constants } from '../constant';


@Component({
  selector: 'app-edit-email',
  templateUrl: './edit-email.component.html',
  styleUrls: ['./edit-email.component.scss']
})
export class EditEmailComponent implements OnInit {

  @Input() sliderFormData: any;
  @Input() public isSliderDataEdited = false;
  public step: number = 1;
  public email = new FormControl("", [
    Validators.required,
    Validators.pattern(this.patientService.emailPattern),
  ]);

  public editEmailForm = this.formBuilder.group({
    email: this.email,
  });

  otp: string;
  display: any;
  btndisabled = true;
  passwordSalted = "";
  verifyID: string;


  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
  };
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;


  sucussfullychangedmesssages = 'update-email.successfully-updated';
  emailchangedfromrec = 'update-email.email-updated';


  constructor(private translate: TranslateService,
    private localeService: LocaleService,
    private loaderService: LoaderService,
    private patientService: PatientService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    public authenticationService: AuthenticationService,

  ) {

    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
  }

  ngOnInit(): void {


  }

  dismiss() {
    this.loaderService.cancelForm();
  }

  updateEmail() {
    if (this.editEmailForm.valid) {
      var isRecp = JSON.parse(sessionStorage.getItem('access_token'))['role'] == constants.nurseRole;
        const updateEmailData = {
          id: String(
            JSON.parse(sessionStorage.getItem(isRecp ? 'patientDetails' : 'userDetails')).id
          ),
          email: String(
            JSON.parse(sessionStorage.getItem(isRecp ? 'patientDetails' : 'userDetails')).email
          ),
          new_email: this.email.getRawValue()
        };
      this.patientService.updateEMail(updateEmailData).subscribe(
        (response: any) => {
          this.verifyID = response.verify_id
          this.timer();
          this.step = 2;
        },
        (err: {
          error: { code: any; message: string };
          message: any;
          code: any;
        }) => {
          if (err.error.code == 422) {
            this.editEmailForm.controls["email"].setErrors({ emailExist: true });
          }
          else {
            this.messageService.alert(err.error.message);
          }
        });

    } else {
      console.log('Data Error');
    }

  }
  onOtpChange(otp: string) {
    this.otp = otp;
  }
  setVal(val: any) {
    this.ngOtpInput.setValue(val);
  }

  timer() {
    const minute = 5;
    let seconds: number = minute * 60;
    let textSec = 0;
    let statSec = 60;
    const prefix = minute < 10 ? "0" : "";
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) {
        statSec--;
      } else {
        statSec = 59;
      }
      if (statSec < 10) {
        textSec = 0 + statSec;
      } else {
        textSec = statSec;
      }
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec
        .toString()
        .padStart(2, "0")}`;
      if (seconds == 0) {
        clearInterval(timer);
        this.btndisabled = false;
      }
    }, 1000);
  }


  resetOTP() {
    this.ngOtpInput.setValue("");
    this.timer();
    this.btndisabled = true;
    var isRecp = JSON.parse(sessionStorage.getItem('access_token'))['role'] == constants.nurseRole;
      const updateEmailData = {
        id: String(
          JSON.parse(sessionStorage.getItem(isRecp ? 'patientDetails' : 'userDetails')).id
        ),
        email: String(
          JSON.parse(sessionStorage.getItem(isRecp ? 'patientDetails' : 'userDetails')).email
        ),
        new_email: this.email.getRawValue()
      };

    this.patientService.updateEMail(updateEmailData).subscribe(
      (response: any) => {
        console.log(response);
        this.verifyID = response.verify_id
        this.timer();
        this.step = 2;
      });

  }
  verifylogIn() {
    var isRecp = JSON.parse(sessionStorage.getItem('access_token'))['role'] == constants.nurseRole;
      const updateEmailData = {
        id: String(
          JSON.parse(sessionStorage.getItem(isRecp ? 'patientDetails' : 'userDetails')).id
        ),
        email: String(
          JSON.parse(sessionStorage.getItem(isRecp ? 'patientDetails' : 'userDetails')).email
        ),
        new_email: this.email.getRawValue(),
        otp: this.otp,
        verify_id: this.verifyID
      };

    this.patientService.veriryOTPOfNewEmail(updateEmailData).subscribe(
      () => {
        if (JSON.parse(sessionStorage.getItem('access_token'))['role'] == constants.nurseRole) {
          this.dismiss();
          this.messageService.success(this.translate.instant(this.emailchangedfromrec), 5000);
          sessionStorage.removeItem('patientDetails');
          const payload = {
            isRec: true,
            email: updateEmailData.new_email
          };
          this.loaderService.onFormClose(payload);
        }
        else {
          this.messageService.success(this.translate.instant(this.sucussfullychangedmesssages), 5000);
          this.loaderService.Logout();
        }

      },
      (err: {
        error: { code: any; message: string };
        message: any;
        code: any;
      }) => {
        if (err.error.code == 422) {
          // invalid OTP error message
          this.messageService.alert(
            this.translate.instant("login.invalid-otp")
          );
        } else {
          this.messageService.alert(err.error.message);
        }

      });

  }


}


