import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as CryptoJS from "crypto-js";
import jwtDecode from "jwt-decode";
import { NgOtpInputConfig } from "ng-otp-input";
import { Language } from "rrule/dist/esm/nlp/i18n";
import { distinctUntilChanged, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { LocaleService } from "../../app.locale.service";
import { AppSettingsService, SpinnerService } from "../../core";
import { AuthenticationService } from "../../eClinical/services/authentication.service";
import { CommonApiService } from "../../eClinical/services/common-api.service";
import { LoginServiceService } from "../../eClinical/services/login-service.service";
import {
  GetAuthLoginRequest,
  GetAuthOtpRequest,
  SaltRequest,
} from "../../eClinical/services/Models/getAuthAccessTokenRequestModel";
import { PatientService } from "../../eClinical/services/patient.service";
import { ResetPasswordServiceService } from "../../eClinical/services/reset-password-service.service";
import { PasswordPolicyService } from "../../eClinical/shared/password-policy/password-policy.service";
import { RegisterComponent } from "../../eClinical/shared/register/register.component";
import { LoaderService, MessageService } from "../../main";
import { PrivacyPolicySliderComponent } from "./privacy-policy-slider/privacy-policy-slider.component";
import { constants } from "../../eClinical/shared/constant";
import { CookieService } from "ngx-cookie";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild("RegisteredEmail") RegisteredEmail: ElementRef;
  @ViewChild("newpassword") newpassword: ElementRef;
  @ViewChild("loginEmail") loginEmail: ElementRef;
  @ViewChild("loginContainer") loginContainer: any;
  _flow_id: string;
  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    if (window.innerWidth > 950 && !this.loader.mobileLogin) {
      this.loader.mobileLogin = true;
    }
  }
  otp: string;
  deviceId: any;
  btndisabled = true;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  windowAreas: any;
  protected showAbout = true;
  scrWidth = 0;
  hide = true;
  isResetPasswordHide = true;
  isOpen = false;
  uiMode: any;
  Breakpoints = Breakpoints;
  username: any;
  createAccountsliderWidth: any = 890;
  privacyPolicysliderWidth: any = 550;
  currentBreakpoint = "";
  public data: any;
  userExist = false;
  errMsg: any;
  tokenResponse: any;
  isDisable = true;
  length: any;
  passwordsMatch = false;
  token: any;
  automationEmail: string;
  captchaVerified = false;
  isAccountLocked = false;
  isAccountDeActive = false;
  display: any;
  selectedLanguage = "en";
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
  };
  passwordSalted = "";
  public terms = new FormControl(false);
  public rememberMe = new FormControl(false);
  public privacy = new FormControl(false);
  public loginName = new FormControl("", [
    Validators.required,
    Validators.pattern(this.patientService.emailPattern),
  ]);
  public password = new FormControl("", [
    Validators.required,
    Validators.pattern(constants.passwordValidation),
  ]);
  public resetpassword = new FormControl("", [
    Validators.required,
    Validators.pattern(constants.passwordValidation),
    this.noWhitespaceValidator,
  ]);
  public repeatresetpassword = new FormControl("", [Validators.required]);
  public email = new FormControl("", [
    Validators.required,
    Validators.pattern(this.patientService.emailPattern),
  ]);
  public registeredEmail = new FormControl("", [
    Validators.required,
    Validators.pattern(this.patientService.emailPattern),
  ]);
  public form = this.fb.group({
    loginName: this.loginName,
    password: this.password,
    deviceDetail: "",
    rememberMe: this.rememberMe,
  });

  public termsForm = this.fb.group({
    terms: this.terms,
    privacy: this.privacy,
  });

  @Output() handleNavigationNodes = new EventEmitter();
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
    .pipe(
      tap((value) => value),
      distinctUntilChanged()
    );

  success = "";
  public forgetPasswordForm = new FormGroup({
    password: this.resetpassword,
    repeatpassword: this.repeatresetpassword,
  });
  public sendEmailForm = new FormGroup({
    RegisteredEmail: this.registeredEmail,
  });
  public currentYear: number = new Date().getFullYear();

  popupmessage: any;
  public languages: Language[];
  userEmail: any;

  isUser = false;
  isNurse = false;
  isSupport = false;
  ifFrance = false;
  isPasswordValid: boolean;
  lastLogin: string;

  private apiLoaded = false;
  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    public loader: LoaderService,
    public loginService: LoginServiceService,
    public authenticationService: AuthenticationService,
    private spinnerService: SpinnerService,
    public router: Router,
    private fb: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private resetPasswordService: ResetPasswordServiceService,
    private translate: TranslateService,
    public localeService: LocaleService,
    public cdr: ChangeDetectorRef,
    public commonAPi: CommonApiService,
    private passwordPolicy: PasswordPolicyService,
    private patientService: PatientService,
    private messageService: MessageService,
    public readonly appSettings: AppSettingsService,
    private cookieService: CookieService,
    private route: ActivatedRoute
  ) {
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
    this.automationEmail = environment.automationEmail;

    this.getScreenSize();

  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.localeService.setCaptchalang();
    this.loader.getLoginCookie(this.form, this.rememberMe);
    this.loader.uiMode = 0;
    const role = this.route.snapshot.params["role_id"];
    if (role == constants.adminRole || role == constants.supervisorRole) {
      this.isUser = true;
    } else if (role == constants.nurseRole) {
      this.isNurse = true;
    }
    else if (role == constants.supportRole) {
      this.isSupport = true;
    }
    if (this.route.snapshot.params["user_id"]) {
      this.loader.user_id = this.route.snapshot.params["user_id"];
      this.loader.uiMode = 4;
    } else if (this.route.snapshot.params["sp_u_id"]) {
      this.loader.user_id = this.route.snapshot.params["sp_u_id"];
      this.loader.uiMode = 5;
    } else if (this.route.snapshot.params["ve_user_id"]) {
      this.loader.mobileLogin = true;
      this.loader.user_id = this.route.snapshot.params["ve_user_id"];
      this.authenticationService.ActivateLogin(this.loader.user_id).subscribe(
        () => {
          this.loader.uiMode = 6;
        },

        (error) => {
          this.loader.uiMode = 8;
          console.log(error);
        }
      );
    } else if (this.route.snapshot.params["ac_mail"]) {
      this.userEmail = this.route.snapshot.params["ac_mail"];
      this.loader.mobileLogin = true;
      this.loader.uiMode = 7;
    } else if (window.location.href.includes("patient/book-appointment")) {
      this.loader.isPreLoginFLow = true;
      this.loader.mobileLogin = true;
      this.loader.highlightLeftMenu([window.location.href.split("#/")[1]]);
    } else if (this.route.snapshot.params["bc_mail"]) {
      this.loader.loginRedirect = this.route.snapshot.params["bc_mail"]
      this.loader.mobileLogin = true;
      this.loader.uiMode = 1;
    } else if (this.route.snapshot.params["doc_id"]) {
      this.loader.redirectDocuments = this.route.snapshot.params["doc_id"]
      this.loader.mobileLogin = true;
      this.loader.uiMode = 1;
    } else if (window.location.href.includes("relative_member")) {
      this.loader.redirectRelMem = true;
      this.loader.mobileLogin = true;
      this.loader.uiMode = 1;
    }
    else if (window.location.href.includes("qr_code")) {
      this.loader.qrCode = true;
      this.loader.mobileLogin = true;
      this.loader.uiMode = 1;
    }
    else if (window.location.href.includes("result")) {
      this.loader.result = true;
      this.loader.mobileLogin = true;
      this.loader.uiMode = 1;
    } else if (window.location.href.includes("ss")) {
      this.loader.recRedirection = {
        action: this.route.snapshot.params["action"],
        org_id: this.route.snapshot.params["org_id"],
        ss_id: this.route.snapshot.params["sampling_station_id"],
        patient_qr: this.route.snapshot.params["qr_id"],
        appointment_id: this.route.snapshot.params["appointment_id"],
        patient_id: this.route.snapshot.params["patient_id"]
      };
      this.loader.mobileLogin = true;
      this.loader.uiMode = 1;
    }
    else {
      this.loader.isPreLoginFLow = true;
      this.loader.mobileLogin = true;
      this.loader.uiMode = 1;
    }
    this.breakpoint$.subscribe(() => this.breakpointChanged());
    this.loader.getLanguageInfo();
    if (this.isUser) {
      this.resetpassword = new FormControl("", [
        Validators.required,
        Validators.pattern(constants.adminpasswordValidation),
        this.noWhitespaceValidator,
      ]);
    } else if (this.isNurse) {
      this.resetpassword = new FormControl("", [
        Validators.required,
        Validators.pattern(constants.receptionistpasswordValidation),
        this.noWhitespaceValidator,
      ]);
    } else if (this.isSupport) {
      this.resetpassword = new FormControl("", [
        Validators.required,
        Validators.pattern(constants.receptionistpasswordValidation),
        this.noWhitespaceValidator,
      ]);
    } else {
      this.resetpassword = new FormControl("", [
        Validators.required,
        Validators.pattern(constants.passwordValidation),
        this.noWhitespaceValidator,
      ]);
    }
    //  this.logIn()

    this.resetpassword.valueChanges.subscribe(() => {
      this.showErrorBox();
    });

    if (!this.apiLoaded) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
  }

  ngAfterViewInit() {
    const self = this;
    this.ifFrance = window.location.host.includes("-fr");
    if (!this.ifFrance) {
      setTimeout(() => {
        const docEle: any = document.getElementById("myVideo");
        if (docEle) {
          docEle.muted = true;
          docEle.pause();
        }
      }, 0);
    }
    this.calculateVideoHeight(this.loginContainer);
    self.cdr.detectChanges();
  }

  private breakpointChanged() {
    if (
      this.breakpointObserver.isMatched(
        "(max-width: 1050px) and (min-width: 601px)"
      )
    ) {
      this.createAccountsliderWidth = 726;
    } else if (this.breakpointObserver.isMatched("(max-width: 600px)")) {
      this.createAccountsliderWidth = "100%";
      this.privacyPolicysliderWidth = "100%";
    } else {
      this.createAccountsliderWidth = 890;
      this.privacyPolicysliderWidth = 550;
    }
    if (this.loginContainer) {
      this.calculateVideoHeight(this.loginContainer);
    }
  }

  createAccountSlider() {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(RegisterComponent);
    this.loader.addDynamicComponent(
      componentFactory,
      this.createAccountsliderWidth,
      []
    );
  }

  privacyPolicySlider() {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        PrivacyPolicySliderComponent
      );
    this.loader.addDynamicComponent(
      componentFactory,
      this.privacyPolicysliderWidth,
      []
    );
  }
  termsOfUseSlider() {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        PrivacyPolicySliderComponent
      );
    this.loader.addDynamicComponent(
      componentFactory,
      this.privacyPolicysliderWidth,
      []
    );
  }

  redirectBooking() {
    this.loader.isPreLoginFLow = true;
    this.loader.highlightLeftMenu(["patient/book-appointment"]);
  }

  redirectLab() {
    this.loader.isPreLoginFLow = true;
    this.loader.highlightLeftMenu(["patient/book-appointment/0"]);
  }

  redirectRegistration() {
    this.loader.isPreLoginFLow = true;
    this.loader.highlightLeftMenu(["patient/registration"]);
  }
  showCapchaError = false;
  logIn() {
    this.form.controls["loginName"].setValue(
      this.form.controls["loginName"].value.toLowerCase()
    );
    this.form.markAllAsTouched();
    if ((this.captchaVerified && environment.isCaptchaRequired) || environment.automationEmail
      .split(";")
      .includes(this.form.controls["loginName"].value.toLowerCase()) || (!environment.isCaptchaRequired)) {
      if (this.form.valid) {
        const saltRequest: SaltRequest = new SaltRequest();
        saltRequest.user_name = this.form.controls.loginName.value.trim();
        this.authenticationService.getSalt(saltRequest).subscribe(
          (response: any) => {

            const password = this.loader.encryptData(
              this.form.controls.password.value.trim(),
              response.Salt
            );
            this.passwordSalted = password;
            this.loader.setResetLoginCookie(
              this.form,
              this.rememberMe,
              response.Salt
            );
            const cookiekey = 'device-unique-id-' + this.loader.encryptData(this.form.controls.loginName.value.trim(), response.Salt)
            this.deviceId = this.cookieService.get(cookiekey);
            if (!this.deviceId) {
              this.loader.generateDeviceId(this.form, cookiekey);
            } else {
              this.form.controls.deviceDetail.setValue(this.deviceId);
            }
            this.isAccountLocked = false;
            this.isAccountDeActive = false;
            this.loader.login(this.form, password).subscribe(
              (response: any) => {
                this._flow_id = response._flow_id;
                if (!response.device_verified) {
                  this.loader.generateDeviceId(this.form, cookiekey);
                  if (response.sent) {
                    this.loader.uiMode = 9;
                    this.timer();
                    this.spinnerService.show(false);
                  } else {
                    this.messageService.alert(response.message);
                    this.spinnerService.show(false);
                  }
                } else {
                  this.verifylogIn();
                }
              },
              (error: any) => {
                const resetPasswordRequest = {
                  email: this.form.controls["loginName"].value.toLowerCase(),
                };
                if (error.error.internal_code == 1003) {
                  // send email for reset password for patient
                  this.resetPasswordService
                    .postAuthforget(resetPasswordRequest)
                    .subscribe(
                      (inputValue) => {
                        inputValue;
                        this.showUserBlockedMessage();
                      },
                      (err: any) => {
                        console.log(err.error.message);
                        this.spinnerService.show(false);
                      }
                    );
                } else if (error.error.internal_code == 1004) {
                  // send email for reset password for user
                  this.resetPasswordService
                    .postAdminAuthforget(resetPasswordRequest)
                    .subscribe(
                      (inputValue) => {
                        inputValue;
                        this.showUserBlockedMessage();
                      },
                      (err: any) => {
                        console.log(err.error.message);
                        this.spinnerService.show(false);
                      }
                    );
                } else if (error.error.internal_code == 1005) {
                  // no email, just show message
                  this.showUserBlockedMessage();
                } else if (error.error.internal_code == 2000) {
                  // send activation email, and show message
                  this.loader.uiMode = 7;
                  this.userEmail =
                    this.form.controls["loginName"].value.toLowerCase();
                  this.timer();
                  this.loader.sendactivationLink(this.userEmail);
                  this.spinnerService.show(false);
                } else if (error.error.internal_code == 2001) {
                  this.resetPasswordService
                    .postAuthforget(resetPasswordRequest)
                    .subscribe(
                      (inputValue) => {
                        inputValue;
                      },
                      (err: any) => {
                        console.log(err.error.message);
                        this.spinnerService.show(false);
                      }
                    );
                  this.showDeActiveMessage();
                } else {
                  this.spinnerService.show(false);
                  this.form.controls.password.setErrors([
                    { passwordMismatch: true },
                  ]);
                }
              }
            );
          },
          (error: any) => {
            {
              console.log(error);
              this.spinnerService.show(false);
              this.form.controls.password.setErrors([{ passwordMismatch: true }]);
            }
          }
        );
      }
    } else {
      this.showCapchaError = true;
    }
  }

  showUserBlockedMessage() {
    this.spinnerService.show(false);
    this.lastLogin = this.form.controls.loginName.value;
    this.form.controls.password.setErrors([{ accountblocked: true }]);
    this.isAccountLocked = true;
  }
  showDeActiveMessage() {
    this.spinnerService.show(false);
    this.lastLogin = this.form.controls.loginName.value;
    this.form.controls.password.setErrors([{ accountblocked: true }]);
    this.isAccountDeActive = true;
  }

  // protected checkLogin() {
  //   if (this.form.controls.loginName.value != this.lastLogin) {
  //     this.form.controls.password.setErrors([{ accountblocked: false }]);
  //     this.isAccountLocked = false;
  //     this.isAccountDeActive = false;
  //   }
  // }

  captchaResolved(event: any) {
    if (event != null) {
      this.captchaVerified = true;
      this.showCapchaError = false;
    } else {
      this.captchaVerified = false;
      this.showCapchaError = true;
    }
  }

  public ResetPasswordPopup() {
    const msg =
      this.loader.uiMode == 4 ? "login.password-reset" : "login.password-set";
    this.messageService.success(this.translate.instant(msg), 5000);
  }
  isValid() {
    this.userExist = false;
  }

  onSend() {
    this.registeredEmail.markAllAsTouched();
    if (!this.registeredEmail.invalid) {
      const inputValue = {
        email: this.RegisteredEmail.nativeElement.value,
      };
      this.username = inputValue.email;
      this.resetPasswordService.postAuthforget(inputValue).subscribe(
        (inputValue) => {
          this.userExist = false;
          console.log(inputValue);
          this.loader.uiMode = 3;
        },
        (error) => {
          if (error.error.internal_code == 429) {
            this.resetPasswordService.postAdminAuthforget(inputValue).subscribe(
              (res) => {
                console.log(res);
                this.userExist = false;
                this.loader.uiMode = 3;
              },
              () => {
                this.userExist = false;
                this.loader.uiMode = 3;
              }
            );
          } else {
            this.userExist = false;
            this.loader.uiMode = 3;
          }
        }
      );
    }
  }

  showforgetError(error: any) {
    this.userExist = true;
    this.registeredEmail.setErrors({ notExist: true } as ValidationErrors);

    this.errMsg = error.error.message;
  }

  passwordMinMaxValidation(): boolean {
    if (this.isUser) {
      if (
        this.repeatresetpassword.value.length < constants.extMinPwdLength ||
        this.repeatresetpassword.value.length > constants.pwdMaxLength
      ) {
        return true;
      }
    } else if (this.isNurse) {
      if (
        this.repeatresetpassword.value.length < constants.nurseMinPwdLength ||
        this.repeatresetpassword.value.length > constants.pwdMaxLength
      ) {
        return true;
      }
    } else if (this.isSupport) {
      if (
        this.repeatresetpassword.value.length < constants.supportMinPwdLength ||
        this.repeatresetpassword.value.length > constants.pwdMaxLength
      ) {
        return true;
      }
    } else {
      if (
        this.repeatresetpassword.value.length < constants.intMinPwdLength ||
        this.repeatresetpassword.value.length > constants.pwdMaxLength
      ) {
        return true;
      }
    }
    return false;
  }

  passwordMatch() {
    if (
      this.repeatresetpassword.value !== "" &&
      this.resetpassword.value !== this.repeatresetpassword.value
    ) {
      this.passwordsMatch = true;
    } else {
      this.passwordsMatch = false;
      this.isDisable = false;
    }

    if (this.resetpassword.value.indexOf(" ") > -1) {
      this.resetpassword.setErrors({ whitespace: true });
      this.isDisable = true;
    }
  }

  public noWhitespaceValidator(password: FormControl) {
    const isWhitespace = (password.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  onResetPassword() {
    if (this.resetpassword.valid) {
      this.hide = true;
      const newPasswordBody = {
        Id: this.loader.user_id,
        Password: this.newpassword.nativeElement.value,
        ModifyUser: 0, // when the patient himself updates the password we are sending this value as zero
      };
      if (
        this.resetpassword.value === this.repeatresetpassword.value &&
        this.resetpassword.value !== ""
      ) {
        const saltRequest: SaltRequest = new SaltRequest();

        saltRequest.id = this.loader.user_id;
        this.authenticationService.getSalt(saltRequest).subscribe(
          (response: any) => {
            let salt = "";
            const newsalt = CryptoJS.lib.WordArray.random(128 / 20).toString();
            if (response.Salt?.length > 1) {
              salt = response.Salt;
            } else {
              salt = newsalt;
            }
            const password = this.loader.encryptData(
              this.resetpassword.value,
              salt
            );

            this.loader.salt = salt;
            this.loader.password = password;
            newPasswordBody.Password = password;
            newPasswordBody["salt"] = salt;

            this.spinnerService.show(true);
            this.resetPasswordService
              .putAuthsavePassword(newPasswordBody)
              .subscribe(
                (newPasswordBody) => {
                  this.spinnerService.show(false);
                  if (newPasswordBody != null) {
                    this.ResetPasswordPopup();
                    this.loader.highlightLeftMenu(["/auth/login"]);
                    this.loader.uiMode = 1;
                  }
                },
                (error) => {
                  this.messageService.alert(
                    this.translate.instant(
                      "backend-errors." + error.error.internal_code
                    ),
                    5000
                  );
                  this.spinnerService.show(false);
                }
              );
          },
          (error) => {
            this.messageService.alert(
              this.translate.instant("backend-errors.500")
            );
            this.spinnerService.show(false);
            console.log(error);
          }
        );
      }
      if (this.loader.uiMode == 5 && !this.isUser) {
        const body = {
          login_id: Number(this.loader.user_id),
          is_terms_accepted: this.terms.value,
          is_privacy_accepted: this.terms.value,
        };
        this.patientService.putTermsandPolicy(body).subscribe(
          (data: any) => {
            console.log(data);
          },
          (error: any) => {
            console.log(error);
          }
        );
      }
    }
  }
  enableButton(): any {
    if (
      this.isDisable ||
      this.terms.value == false ||
      this.privacy.value == false
    ) {
      return true;
    } else {
      return false;
    }
  }
  forgotpasswordshow() {
    document.getElementById("forgetPassword_container").style.display = "block";
    document.getElementById("login-wrapper").style.display = "none";
  }

  backtologin() {
    document.getElementById("forgetPassword_container").style.display = "none";
    document.getElementById("login-wrapper").style.display = "block";
  }

  /* Method to open separate browser window to show help / user guide */
  public openHelp(): void {
    this.loader.isPreLoginFLow = true;
    this.router.navigate(["patient/faq"]);
  }

  changeLocale(locale: string) {
    this.translate.use(locale);
    this.localeService.setLocale(locale);
  }

  /**
   * This method will execute the when user will try to use space key keyboard and in the search box if the space is first character then
   * it will wont allow space to execute, It will block the space untill user enter a character.
   * @param event
   */
  disableSpace(event: Event) {
    event.preventDefault();
  }

  checkIsValid(event: any) {
    if (event) {
      this.hideErrorBox();
    }
  }

  hideErrorBox() {
    this.passwordPolicy.hide();
  }

  showErrorBox() {
    if (this.isUser) {
      // for user role
      this.passwordPolicy.show(
        this.resetpassword.value,
        constants.extMinPwdLength,
        constants.pwdMaxLength
      );
      this.isPasswordValid = this.passwordPolicy.valid(
        this.resetpassword.value,
        constants.extMinPwdLength,
        constants.pwdMaxLength
      );
    } else if (this.isNurse) {
      // for nurse role
      this.passwordPolicy.show(
        this.resetpassword.value,
        constants.nurseMinPwdLength,
        constants.pwdMaxLength
      );
      this.isPasswordValid = this.passwordPolicy.valid(
        this.resetpassword.value,
        constants.nurseMinPwdLength,
        constants.pwdMaxLength
      );
    } else if (this.isSupport) {
      // for IT support role
      this.passwordPolicy.show(
        this.resetpassword.value,
        constants.supportMinPwdLength,
        constants.pwdMaxLength
      );
      this.isPasswordValid = this.passwordPolicy.valid(
        this.resetpassword.value,
        constants.supportMinPwdLength,
        constants.pwdMaxLength
      );
    } else {
      // for patient role
      this.passwordPolicy.show(
        this.resetpassword.value,
        constants.intMinPwdLength,
        constants.pwdMaxLength
      );
      this.isPasswordValid = this.passwordPolicy.valid(
        this.resetpassword.value,
        constants.intMinPwdLength,
        constants.pwdMaxLength
      );
    }
  }

  rememberMeCheck(event: any) {
    this.rememberMe.setValue(event.checked);
  }

  openLogin() {
    this.loader.uiMode = 1;
    this.loginEmail?.nativeElement?.focus();
  }

  redirectToLogin() {
    this.loader.uiMode = 1;
    this.loginEmail?.nativeElement?.focus();
    this.captchaVerified = false;
    this.showCapchaError = true;
  }

  clearPassword() {
    this.password.reset();
    this.form.controls.password.setErrors([{ accountblocked: false }]);
    this.isAccountLocked = false;
    this.isAccountDeActive = false;
  }

  private calculateVideoHeight(containerRef: any) {
    const self = this;
    setTimeout(() => {
      self.windowAreas =
        window.outerWidth < 600
          ? window.outerHeight -
          containerRef?.nativeElement?.clientHeight +
          25 +
          "px"
          : "100vh";
    }, 0);
  }
  onOtpChange(otp: string) {
    this.otp = otp;
  }
  setVal(val: any) {
    this.ngOtpInput.setValue(val);
  }

  timer() {
    const minute = 5;
    let seconds = minute * 60;
    let statSec = 60;
    const prefix = minute < 10 ? "0" : "";

    const startTime = Date.now();
    const endTime = startTime + seconds * 1000;

    const timer = setInterval(() => {
      const now = Date.now();
      const remainingTime = Math.max(0, endTime - now);
      seconds = Math.floor(remainingTime / 1000);

      if (remainingTime === 0) {
        clearInterval(timer);
        this.btndisabled = false;
      }

      const minutes = Math.floor(seconds / 60);
      statSec = seconds % 60;

      this.display = `${prefix}${minutes}:${statSec.toString().padStart(2, "0")}`;
    }, 1000);
  }

  resetOTP() {
    this.ngOtpInput.setValue("");
    this.timer();
    this.btndisabled = true;
    const otpRequest: GetAuthLoginRequest = new GetAuthLoginRequest();
    otpRequest.password = this.passwordSalted;
    otpRequest.username = this.form.controls.loginName.value.trim();
    this.authenticationService
      .postAuthOtpSend(otpRequest)
      .subscribe((res: any) => {
        this._flow_id = res._flow_id;
      });
  }
  verifylogIn() {
    const otpRequest: GetAuthOtpRequest = new GetAuthOtpRequest();
    otpRequest.password = this.passwordSalted;
    otpRequest.username = this.form.controls.loginName.value.trim();
    otpRequest.otp = this.otp;
    otpRequest._flow_id = this._flow_id;
    otpRequest.device_detail = this.form.controls.deviceDetail.value;
    this.authenticationService.postAuthOtpVerify(otpRequest).subscribe(
      (response) => {
        if (this.loader.checkExpiryDate(response["password_expiry_time"])) {
          const access_token = jwtDecode(response["access_token"]);
          this.spinnerService.show(false);
          this.loader.uiMode = 4;
          this.loader.passwordExpired = true;
          this.loader.userRole = access_token["role"];
          this.loader.user_id = access_token["unique_id"];
          if (this.loader.userRole === constants.adminRole || this.loader.userRole === constants.supervisorRole) {
            this.isUser = true;
            this.resetpassword = new FormControl("", [
              Validators.required,
              Validators.pattern(constants.adminpasswordValidation),
              this.noWhitespaceValidator,
            ]);
          } else if (this.loader.userRole === constants.nurseRole) {
            this.isNurse = true;
            this.resetpassword = new FormControl("", [
              Validators.required,
              Validators.pattern(constants.receptionistpasswordValidation),
              this.noWhitespaceValidator,
            ]);
          }
          else if (this.loader.userRole === constants.supportRole) {
            this.isSupport = true;
            this.resetpassword = new FormControl("", [
              Validators.required,
              Validators.pattern(constants.receptionistpasswordValidation),
              this.noWhitespaceValidator,
            ]);
          }
          else {
            this.resetpassword = new FormControl("", [
              Validators.required,
              Validators.pattern(constants.passwordValidation),
              this.noWhitespaceValidator,
            ]);
          }
          if (!this.rememberMe) {
            this.form.controls["loginName"].setValue("");
            this.form.controls["password"].setValue("");
          }
          return;
        }
        if (response["access_token"]) {
          this.loader.startRefreshTokenTimer(response);
          this.tokenResponse = response;
          const access_token = jwtDecode(this.tokenResponse["access_token"]);
          sessionStorage.setItem("access_token", JSON.stringify(access_token));
          sessionStorage.setItem("header", this.tokenResponse["access_token"]);
          sessionStorage.setItem(
            "refresh_token",
            this.tokenResponse["refresh_token"]
          );
          this.loader.userRole = access_token["role"];

          if (access_token["role"] == constants.patientRole) {
            this.loginService.getPatientDetails().subscribe((resp: any) => {
              sessionStorage.setItem("userDetails", JSON.stringify(resp));
              this.loader.user_name = resp.first_name + " " + resp.birth_name;
              this.loader.dob = resp.dob;
              this.spinnerService.show(false);
              this.loader.handleNavigationNodes();
              this.loader.updateLoginStatus(true);
            });
          } else {
            this.loader.handleNavigationNodes();
            this.spinnerService.show(false);
            this.loader.updateLoginStatus(true);
          }

        } else {
          this.messageService.alert("Please Check the code you entered.");
        }
      },
      (error: any) => {
        const resetPasswordRequest = {
          email: this.form.controls.loginName.value.trim(),
        };
        if (error.error.internal_code == 1003) {
          // send email for reset password for patient
          this.resetPasswordService
            .postAuthforget(resetPasswordRequest)
            .subscribe(
              (inputValue) => {
                inputValue;
                this.messageService.alert(
                  this.translate.instant("login.password-account-blocked")
                );
              },
              (err: any) => {
                console.log(err.error.message);
                this.spinnerService.show(false);
              }
            );
        } else if (error.error.internal_code == 1004) {
          // send email for reset password for user
          this.resetPasswordService
            .postAdminAuthforget(resetPasswordRequest)
            .subscribe(
              (inputValue) => {
                inputValue;
                this.messageService.alert(
                  this.translate.instant("login.password-account-blocked")
                );
              },
              (err: any) => {
                console.log(err.error.message);
                this.spinnerService.show(false);
              }
            );
        } else if (error.error.internal_code == 1006) {
          // no email, just show message
          this.messageService.alert(
            this.translate.instant("login.password-account-blocked")
          );
          this.showUserBlockedMessage();
        } else if (error.error.code == 422) {
          // invalid OTP error message
          this.messageService.alert(
            this.translate.instant("login.invalid-otp")
          );
        } else {
          this.messageService.alert(error.error.message);
        }
      }
    );
  }
}
