import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
import { SpinnerService } from '../../../core';
import { MessageService } from '../../../main';
import { CommonApiService } from '../../services/common-api.service';
import { AddSamplingComponent } from '../add-sampling/add-sampling.component';

@Component({
    selector: 'app-sampling-station',
    templateUrl: './sampling-station.component.html',
    styleUrls: ['./sampling-station.component.scss']
})
export class SamplingStationComponent implements OnInit {
    public dataSource: MatTableDataSource<any>;
    public hoverindex = '';
    search: String = '';
    searchinput = '';
    status = 'All';
    filter: string;
    public columnList = [
        'SamplingStationId',
        'SamplingStationName',
        'CompanyName',
        'Status',
        'OpenFrom',
        'OpenUntill',
        'ADELI',
        'icon-column'
    ];
    filterObject = {
        search: '',
        status: ''
    };
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    sampleData: { [key: string]: string } = {
        All: 'company.all',
        Active: 'admin.active',
        Suspended: 'admin.suspended'
    };

    deleteTitle = 'admin.confirm-delete';
    deleteconfirmtext = 'admin.delete-confirm-text';
    savealert = {
        edit: 'admin.save-alert.edit',
        add: 'admin.save-alert.add',
        delete: 'admin.save-alert.delete'
    };
    errorMessage = 'admin.error-message';
    constructor(
        // private readonly dialog: MatDialog,
        private commonAPi: CommonApiService,
        private readonly messageService: MessageService,
        private readonly spinner: SpinnerService,
        private readonly dialog: MatDialog,
        private translate: TranslateService,
        private localeService: LocaleService,
    ) {
        this.dataSource = new MatTableDataSource<any>();
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }
    ngOnInit(): void {
        this.loadSamplingStations();
        this.dataSource.sortingDataAccessor = (
            data: any,
            sortHeaderId: string
        ): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            }

            return data[sortHeaderId];
        };

    }


    public loadSamplingStations() {
        this.commonAPi.GetAllSamplingStations(true).subscribe((stationslist: any) => {
            stationslist.sampling_stations.sort((a: any, b: any) => b.sampling_station_name - a.sampling_station_name);

            this.dataSource.data = stationslist.sampling_stations;
            this.dataSource.filterPredicate = (data, filter) => {
                const filterObj = JSON.parse(filter);
                if (filterObj.search !== '' && filterObj.status == '') {
                    return (
                        String(data.id)
                            .toLowerCase()
                            .includes(filterObj.search.toLowerCase()) ||
                        String(data.org_name)
                            .toLowerCase()
                            .includes(filterObj.search.toLowerCase()) ||
                        String(data.external_id)
                            .toLowerCase()
                            .includes(filterObj.search.toLowerCase()) ||
                        String(data.sampling_station_name)
                            .toLowerCase()
                            .includes(filterObj.search.toLowerCase())
                    );
                } else if (filterObj.search == '' && filterObj.status !== '') {
                    return (
                        String(data.status) === filterObj.status ||
                        filterObj.status == 'All'
                    );
                } else {
                    return (
                        (String(data.id)
                            .toLowerCase()
                            .includes(filterObj.search.toLowerCase()) ||
                            String(data.org_name)
                                .toLowerCase()
                                .includes(filterObj.search.toLowerCase()) ||
                            String(data.external_id)
                                .toLowerCase()
                                .includes(filterObj.search.toLowerCase()) ||
                            String(data.sampling_station_name)
                                .toLowerCase()
                                .includes(filterObj.search.toLowerCase())) &&
                        (String(data.status) === filterObj.status ||
                            filterObj.status == 'All')
                    );
                }
            };
        });
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    /** Method to identify hovered grid row */
    public rowMouseOver(row: any): void {
        this.hoverindex = row.id;
    }

    public displaySpinner(flag: boolean): void {
        this.spinner.show(flag);
        // setTimeout(() => {
        //     this.spinner.show(false);
        // }, 5000);
    }
    public success(message: any): void {
        this.messageService.success(this.translate.instant(message));
    }
    public alert(error: any): void {
        this.messageService.alert(this.translate.instant(error), 10000);
    }

    /* Method for search button */
    onSearch() {
        this.filterObject.search = this.searchinput;
        this.dataSource.filter = JSON.stringify(this.filterObject);
    }

    /* Method for Reset Button */
    public reset(): void {
        this.dataSource.filter = '';
        this.searchinput = '';
        this.status = 'All';
        this.filterObject.search = '';
        this.filterObject.status = '';
    }

    /* Method for Status Dropdown */
    // public statusFilter() {
    //   if (this.status != "All") {
    //     this.filterObject.status = this.status == "Active" ? "true" : "false";
    //     this.dataSource.filter = JSON.stringify(this.filterObject);
    //   } else {
    //     this.filterObject.status = "All";
    //     this.dataSource.filter = JSON.stringify(this.filterObject);
    //   }
    // }
    public statusFilter() {
        if (this.status != 'All') {
            this.filterObject.status = this.status;
            this.dataSource.filter = JSON.stringify(this.filterObject);
        } else {
            this.filterObject.status = 'All';
            this.dataSource.filter = JSON.stringify(this.filterObject);
        }
    }

    /**
   * Show the add sampling dialog
   */
    addSampling() {
        const dialogRef = this.dialog.open(AddSamplingComponent, {
            width: '1000px',
            disableClose: true,
            maxHeight: '90vh',
            data: { action: 'add', edit: false, savealert: this.savealert }
        });
        dialogRef.afterClosed().subscribe(() => {
            this.loadSamplingStations();
        });
    }

    /**
   * Show the edit sampling dialog.
   * @param entity
   */

    editSampling(entity: any) {
        const dialogRef = this.dialog.open(AddSamplingComponent, {
            width: '1000px',
            disableClose: true,
            maxHeight: '90vh',
            data: {
                action: 'edit',
                edit: true,
                samplingData: entity,
                savealert: this.savealert
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.loadSamplingStations();
            console.log(result);
        });
    }

    /**
   * This method will execute the when user will try to use space key keyboard and in the search box if the space is first character then
   * it will wont allow space to execute, It will block the space untill user enter a character.
   * @param event
   */
    keyDownHandler(event: Event) {
        if (this.searchinput.length === 0) {
            event.preventDefault();
        }
    }

    /* Method to view data in the given order */
    unsorted() { }
}
