export class SSTest {
    public id: number;
    public name: string;
    public short_name: string;
    public test_name: string;
    public long_name: string;
    public description: string;
    public test_type_id: number;
    public test_type: string;
    public limits: number;
    public avg_time: string;
    public max_limits: number;
    public limit_bookings: number;
    public total_time: number;
}

export class MasterData {
    public Id: number;
    public Category: string;
    public KeyName: string;
    public KeyOrder: number;
    public ParentId: number;
    public IsActive: boolean;
    public Key: string;
    public EngKeyName: string;
    public Expiry: boolean;
    public ImageUrl: string;
}


export class ConfiguredData {
    constructor() {
        this.tests = new Array<SSTest>();
    }
    public id: number;
    public ss_id: number;
    public slot_name: string;
    public start_date: Date;
    public end_date: Date;
    public start_time: Date;
    public start_time_string: string;
    public end_time_string: string;
    public end_time: Date;
    public is_allday: boolean;
    public occurance: number;
    public repeat_every: number;
    public is_unavilability: boolean;
    public unavilable_reason: string;
    public is_all_tests: boolean;
    public tests: SSTest[];
    public dows: any;
}


export class ActiveDate {
    public start_date: Date;
    public start_time: string;
    public end_time: string;
    public break_begins: string;
    public break_ends: string;
}

