<div class="Container">
  <div class="d-flex justify-content-between search-mr">
    <div class="d-flex Search">
      <div id="inputsearch">
        <mat-form-field>
          <mat-label>{{'test-type.id-type' | translate}}</mat-label>
          <input [(ngModel)]="searchinput" matInput (keydown.enter)="onSearch()"
            (keydown.space)="keyDownHandler($event)" />
        </mat-form-field>
      </div>
      <div>
        <button (click)="onSearch()" id="btn_search" style="margin-left: 15%" mat-raised-button color="primary"
          class="search-btn">
          {{'test-type.search' | translate}}
        </button>
      </div>

      <div class="mx-8" style="text-align: center">
        <button mat-button color="accent" id="btn_reset" class="ghost" (click)="reset()" style="margin-left: 15%">
          {{'test-type.reset' | translate}}
        </button>
      </div>
    </div>

    <div class="d-flex Button">
      <div id="statusfilter" style="text-align: center">
        <mat-form-field floatLabel="never" class="dropdown-filter">
          <mat-select placeholder="{{'admin.status' | translate}}" disableOptionCentering [(ngModel)]="status">
            <mat-option *ngFor="let stepOption of sampleData | keyvalue: unsorted" (click)="statusFilter()"
              [value]="stepOption.key">
              {{ stepOption.value | translate}}
            </mat-option>

          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <button mat-raised-button color="primary" *ngIf="adminRole" id="btn_add" (click)="add()" class="test-btn"
          style="margin-left: 10%">
          {{'add-test-type.add-test-type' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="main-container main-container-res">
    <mat-table #table [dataSource]="dataSource" matSort class="table-height input-min-spacing table-flex"
      id="TestTypeListingTable">
      <ng-container matColumnDef="Id">
        <mat-header-cell *matHeaderCellDef>
          <span>{{'test-type.id' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity.Id }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Name">
        <mat-header-cell *matHeaderCellDef>
          <span>{{'user.name' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity" matTooltip="{{loader.TranslateLang(entity.Name)}}">
          {{ loader.TranslateLang(entity.Name) }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Description">
        <mat-header-cell *matHeaderCellDef>
          <span>{{'test-type.description' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity" matTooltip="{{ loader.TranslateLang(entity.Description)}}">
          {{ loader.TranslateLang(entity.Description)}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Status">
        <mat-header-cell *matHeaderCellDef>
          <span>{{'admin.status' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity"
          matTooltip="{{ entity.IsActive ? ('admin.active' | translate) : ('admin.suspended' | translate)}}">
          {{ entity.IsActive ? ('admin.active' | translate) : ('admin.suspended' | translate) }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="icon-column">
        <mat-header-cell *matHeaderCellDef>
          {{'test-type.options' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          <button class="mat-tbl-icon" (click)="edit($event, entity)" mat-icon-button>
            <span class="icon-ic-edit" matTooltip="{{'user.edit' | translate}}"></span>
          </button>
          <!-- <button class="mat-tbl-icon" *ngIf="entity.id == hoverindex"
                (click)="confirmDeleteWithYesNo( entity)" mat-icon-button>
                <span class="icon-ic-delete"></span>
            </button> -->
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnList; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnList; let i = index" class="element-grid-row"
        (mouseover)="rowMouseOver(row)">
      </mat-row>
    </mat-table>

    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons="true">
    </mat-paginator>
  </div>
</div>