<div id="privacyPolicy_Slider" class="slider-data-container">
    <div class="header">
        <div class="pull-left">
            <app-section-title title="{{'privacy-policy-slider.title' | translate}}"></app-section-title>
        </div>
        <div class="pull-right">
            <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
                <span class="icon-ic-close"></span>
            </button>
        </div>
    </div>
    <p class="pt-20">{{'privacy-policy-slider.parah-1' | translate}}
    </p>
    <p>
        {{'privacy-policy-slider.parah-2' | translate}}
    </p>

    <h4>{{'privacy-policy-slider.cookies' | translate}}</h4>
    <p>{{'privacy-policy-slider.parah-3' | translate}}</p>
</div>