<div class="covidInfoHeader">
  <div class="ecl-container">
    <span></span>
    <p class="checkin">
      {{heroBanner[localeService.localeLang]['top_blue_bar_message']}}
    </p>
    <span class="flex-spacer"></span>
    <div class="language-cover">
      <button id="languageButton" class="icon-color" mat-button [matMenuTriggerFor]="languageMenu">
        <span class="flag-image">
          <img src="./../../../../assets/images/flag-images/{{localeService.localeLang}}.svg"></span>
        <span class="icon-ic-expand-nav"></span>
      </button>
      <button id="languageButton" class="title-info faq-align" mat-button (click)="openFaq()">
        <span class="faq">?</span>
        <!-- <span class="contact">{{'login.help' | translate}}</span> -->
      </button>
      <mat-menu #languageMenu="matMenu" class="toolbar-lang-menu" xPosition="before">
        <ng-container *ngFor="let language of loader.languages">
          <button mat-menu-item (click)="changeLocale(language.Name)"><span><img
                src="./../../../../assets/images/flag-images/{{language.Name}}.svg"></span>{{language.FullName}}</button>
        </ng-container>
      </mat-menu>

      <button mat-icon-button class="title-info d-flex-center" style="display: none;">
        <a id="manual" href="">
          <span class="icon-ic_help"></span></a>
      </button>
    </div>
  </div>
</div>
<mat-toolbar class="toolbar-height">
  <div class="ecl-container" [class.isAdmin]="loader.userRole == adminRole || loader.userRole == supervisorRole">
    <div class="side-menu" *ngIf="loader.loggedIn" (click)="openDrawer.emit();"><span
        class="icon-icon-hamburger"></span>
    </div>
    <div [hidden]="!showLogo" class="toolbar-logo">
      <a class="toolbar-logo-margin" (click)="onLogoClick()" [routerLink]="">
        <img src="./../../../../assets/images/logo-eclinical.svg" />
      </a>
    </div>
    <div id="header_div_mainHeader" class="app-titles"
      *ngFor="let breadcrumb of (breadCrumbObs$ | async) last as isLast;" [ngClass]="{'active': isLast}">
      <span *ngIf="!isLast; else lastRoute">
        <a [routerLink]="[breadcrumb.url]" routerLinkActive="active">
          {{ breadcrumb.label }}
        </a>/</span>
      <ng-template #lastRoute>{{ breadcrumb.label }}</ng-template>
    </div>
    <span class="flex-spacer"></span>
    <!--Options-->
    <div class="options" *ngIf="!hideToolbar()">
      <mat-form-field *ngIf="loader.loggedIn && loader.userRole == 1" class="selectLab onlyDesktop">
        <mat-select #selectedCompanyValue [formControl]="loader.samplingcompany"
          placeholder="{{'toolbar.select-lab'|translate}}">
          <mat-option class="selectLabOption" *ngFor="let company of sampling_station_companies"
            [value]='company.org_id' (click)='loadSamplingStations(selectedCompanyValue.value)'>
            {{company["org_name"]}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="loader.loggedIn && loader.userRole == 1" class="selectLab onlyDesktop">
        <mat-select #selectedValue [formControl]="loader.sampling" placeholder="{{'toolbar.select-lab'|translate}}">
          <mat-option class="selectLabOption" *ngFor="let station of sampling_stations" [value]='station.id'
            (click)='getSampling(selectedValue.value)'>
            {{station["sampling_station_name"]}}</mat-option>
        </mat-select>
      </mat-form-field>
      <!--Book Appointment button-->
      <button mat-button id="btn_book_appointment_tool"
        *ngIf="(!loader.loggedIn) || (loader.loggedIn && loader.userRole == patientRole)"
        class="web-icon pre-login-book-button" type="submit" (click)=" redirectBooking()" mat-button color="accent">
        <img src="./../../../../assets/images/application-logos/svg/ic-checkin.svg" /> {{'toolbar.process1-heading' |
        translate}}</button>
      <button *ngIf="loader.loggedIn && loader.userRole == 1 || loader.userRole == 4" id="btn_book_appointment_tool"
        (click)=" redirectGuestBooking()" rippleOnHover class="mat-tbl-icon" mat-icon-button>
      </button>
      <span *ngIf="(!loader.loggedIn) || (loader.loggedIn && loader.userRole == patientRole)"
        class="web-icon separator"></span>

      <!--Results Button-->
      <button *ngIf="(!loader.loggedIn)" (click)="redirectGuestResults()" mat-button color="accent"
        class="web-icon pre-login-result-button" id="btn_get_results">
        <img src="./../../../../assets/images/application-logos/svg/ic-results.svg" /> {{'toolbar.results' |
        translate}}</button>
      <button *ngIf="(loader.loggedIn  && loader.userRole == patientRole)" (click)="redirectResults()" mat-button
        color="accent" class="web-icon pre-login-result-button" id="btn_get_results">
        <img src="./../../../../assets/images/application-logos/svg/ic-results.svg" /> {{'toolbar.results' |
        translate}}</button>
      <span *ngIf="(!loader.loggedIn) || (loader.loggedIn && loader.userRole == patientRole)"
        class="web-icon separator"></span>

      <!--Find lab button-->
      <button *ngIf="(!loader.loggedIn) || (loader.loggedIn  && loader.userRole == patientRole)"
        (click)="redirectFindALab()" mat-button color="accent" class="web-icon pre-login-lab-button"
        id="btn_find_lab"><img src="./../../../../assets/images/application-logos/svg/ic-map.svg" />
        {{'toolbar.laboratory' |
        translate}}</button>
      <button class="location" *ngIf="loader.loggedIn && loader.userRole == 4" (click)="redirectFindALab()"
        rippleOnHover class="mat-tbl-icon" mat-icon-button id="btn_find_lab">
      </button>

      <mat-menu #locationMenu="matMenu" xPosition="after">
        <button (click)='loadSamplingStations(company.org_id)' *ngFor="let company of sampling_station_companies"
          mat-menu-item>
          {{company["org_name"]}}
        </button>
      </mat-menu>
      <mat-menu #locationMenu="matMenu" xPosition="after">
        <button (click)='getSampling(station.id)' *ngFor="let station of sampling_stations" mat-menu-item>
          {{station["sampling_station_name"]}}
        </button>
      </mat-menu>

      <button *ngIf="loader.loggedIn && loader.userRole == 4" id="btn_open_notification" (click)=" openNotificatios()"
        rippleOnHover class="mat-tbl-icon" mat-icon-button>
        <span [matBadge]="getUnreadNotification()" class="icon-ic-notification"
          [ngClass]="getUnreadNotification() === 0 ? 'noBadge' : 'showBadge'"></span>
      </button>

      <button mat-raised-button color="primary" *ngIf="loader.loggedIn && loader.userRole == nurseRole" class="web-icon"
        type="submit" id="btn_book_appointment_recep" (click)="redirectGuestBookingFlow()">{{'toolbar.process1-heading'
        | translate}}
      </button>

      <mat-menu #locationMenu="matMenu" xPosition="after">
        <button (click)='loadSamplingStations(company.org_id)' *ngFor="let company of sampling_station_companies"
          mat-menu-item>
          {{company["org_name"]}}
        </button>
      </mat-menu>
      <mat-menu #locationMenu="matMenu" xPosition="after">
        <button (click)='getSampling(station.id)' *ngFor="let station of sampling_stations" mat-menu-item>
          {{station["sampling_station_name"]}}
        </button>
      </mat-menu>

    </div>
    <span class="flex-spacer" *ngIf="(loader.userRole != nurseRole)"></span>
    <!-- Divider  -->
    <div *ngIf="loader.loggedIn && loader.userRole == 4" [matBadge]="getUnreadNotification()" class="user-container"
      [ngClass]="getUnreadNotification() === 0 ? 'noBadge' : 'showBadge'">
      <span [matMenuTriggerFor]="userMenu" class="titleWrapBox">{{userInitial}}</span>
    </div>
    <!--Mobile Icons-->

    <div class="mobile-container" *ngIf="!hideToolbar()">
      <button
        *ngIf="(!loader.loggedIn) || (loader.loggedIn && loader.userRole == nurseRole || loader.userRole == patientRole)"
        (click)=" redirectGuestBooking()" id="btn_book_appointment_guest_tool" rippleOnHover
        class="mat-tbl-icon appointment-button" mat-icon-button>
        <span class="button-span">
          <img src="./../../../../assets/images/application-logos/svg/ic-checkin.svg" />
          <span>{{'toolbar.process1-heading' | translate}}</span></span>
      </button>

      <button *ngIf="(!loader.loggedIn)" (click)="redirectGuestResults()" color="accent" id="btn_get_results_mb"
        rippleOnHover class="mat-tbl-icon result-button" mat-icon-button>
        <span class="button-span">
          <img src="./../../../../assets/images/application-logos/svg/ic-results.svg" />
          <span>{{'toolbar.results' | translate}}</span>
        </span>
      </button>
      <button *ngIf="(loader.loggedIn  && loader.userRole == patientRole)" id="btn_get_results_mb" rippleOnHover
        (click)="redirectResults()" color="accent" class="mat-tbl-icon result-button" mat-icon-button>
        <span class="button-span">
          <img src="./../../../../assets/images/application-logos/svg/ic-results.svg" />
          <span>{{'toolbar.results' | translate}}</span>
        </span>
      </button>

      <button class="location" *ngIf="(!loader.loggedIn) || (loader.loggedIn && loader.userRole == patientRole)"
        (click)="redirectFindALab()" id="btn_find_a_lab" rippleOnHover class="mat-tbl-icon lab-button" mat-icon-button>
        <span class="button-span">
          <img src="./../../../../assets/images/application-logos/svg/ic-map.svg" />
          <span>{{'toolbar.laboratory' | translate}}</span></span>
      </button>
    </div>

    <div *ngIf="loader.loggedIn && loader.userRole !== 4" class="user-container">
      <span class="titleWrapBox" [matMenuTriggerFor]="userMenu">{{userInitial}}</span>
    </div>
    <div *ngIf="!loader.loggedIn" class="user-container d-block">
      <span class="titleWrapBox icon-ic-mail-1" (click)="loader.RedirecttoLogin()"></span>
    </div>
    <div *ngIf="!loader.loggedIn" class="d-block login" id="btn_login" (click)="loader.RedirecttoLogin()">{{
      'toolbar.login' |
      translate}}</div>
    <mat-menu class="user-menu-popover" #userMenu="matMenu" xPosition="before">
      <div class="arrow-top"></div>
      <div (click)="$event.stopPropagation();$event.preventDefault();" class="user-main p-20">
        <div *ngIf="userName !== 'Guest'" class="user-icon">
          <span class="icon-ic-mail-1"></span>
        </div>
        <div class="user-infor">
          <p class="name" matTooltip="{{userName}}">{{userName}}</p>
          <p class="email">{{getLoggedInUserData()}}</p>
        </div>
      </div>
      <div *ngIf="loader.loggedIn && loader.userRole == 4" class="user-notifications pl-20 pt-15 pb-15">
        <div class="notification-header">
          <div class="notification-title">{{'notifications' | translate}}</div>
          <div class="notification-view-all" (click)="openNotificatios()">{{'view-all' | translate}}</div>
        </div>
        <div *ngFor="let notification of notifications">
          <div class="notifications-border" [ngClass]="notification.IsRead ? '' : 'notiffication-background'">
            <div class="notifications">
              <div class="notifications-header">
                <div class="notifications-header-text">{{notification.NotificationType.split('|')[0] + '.header-text' |
                  translate }}</div>
                <div *ngIf="!notification.IsRead" (click)="markNotificationRead(notification)"
                  class="notifications-header-link">{{'mark-as-read' | translate}}</div>
              </div>
              <ng-template #notification_title>
                <span class="notif-content">
                  {{ notification.NotificationType.split('|')[0] + '.title' | translate : {
                    data: translateService.instant(notification.NotificationType.split('|')[1]) 
                  } }}
                </span>
              </ng-template>              
              <div class="notifications-title"
                *ngIf="notification.NotificationType.split('|')[0] === 'PASSWORD_EXPIRE_NOTIFICATION' && (notification.NotificationType.split('|')[1] === '0' || notification.NotificationType.split('|')[1].substring(0,1) === '-') else notification_title"
                class="notifications-title">{{ 'password-expired' | translate }}</div>
              <div class="notifications-time">{{notification.CreateTime | date: 'dd/MM/yyyy | H:mm'}}</div>
              <div (click)="routeLink(notification)" class="notifications-link">
                {{notification.NotificationType.split('|')[0] + '.link' | translate }}</div>
            </div>
          </div>
        </div>
        <div class="no-notifications" *ngIf="notifications && notifications.length === 0">
          <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
          <div class="desc">
            <p>{{'no-notifications'|translate}}</p>
          </div>
        </div>
      </div>
      <div class="sign-out pl-20">
        <button mat-menu-item *ngIf="userLoggedIn" id="btn_logout" (click)="onLogOut()">{{'toolbar.sign-out' |
          translate}}</button>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>