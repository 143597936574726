import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LocaleService } from '../../../../app.locale.service';
import { SpinnerService } from '../../../../core';
import { MessageService } from '../../../../main';
import { SamplingStationSlot } from '../../../models/common.model';
import { TestService } from '../../../services/add-test-service.service';
import { CommonApiService } from '../../../services/common-api.service';
import { PatientService } from '../../../services/patient.service';

import { SpecialDate } from '../special-dates/special-date';
import {
    BookedAppointment,
    CalendarModel,
    PatientDetail,
    SearchPatientRequest
} from './view-booking.model';

@Component({
    selector: 'app-view-booking',
    templateUrl: './view-booking.component.html',
    styleUrls: ['./view-booking.component.scss']
})
export class ViewBookingComponent implements OnInit {
    calendarSlots: any;
    slotDuration: CalendarModel;
    calendar: SamplingStationSlot;
    ss_id: number;
    currDate: Date = new Date();
    spr: SearchPatientRequest;
    dateofBirth = new FormControl();
    public dataSource: MatTableDataSource<BookedAppointment>;
    public newAppointmentDataSource: MatTableDataSource<PatientDetail>;

    selectedPatient: any;
    bookingDate = new FormControl();
    dateTimePickerValue = new FormControl(moment());
    selectedDate = '';
    selectedDate1 = '';
    progressValue: number;
    startDate = new Date();
    endDate = new Date(2024, 10, 1);
    maxDate: Date;
    bookedAppointments: Array<BookedAppointment>;
    specialDates: SpecialDate[];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public hoverindex = '';
    step = 1;
    public columnList = ['PatientID', 'PatientName', 'Date', 'Test', 'Options'];

    public newAppointmentColumnList = [
        'ID',
        'FamilyName',
        'FirstName',
        'DateofBirth',
        'Gender',
        'SSN/Passport',
        'Email',
        'PhoneNumber'
    ];
    testItems: any[];

    filterObject = {
        is_active: true
    };

    editBookingTitle = 'view-booking.add-booking';

    public testControl = new FormControl(0, [Validators.required]);
    public statusControl = new FormControl('true');
    noPatientFoundText = '';
    isSubmitted = false;
    s_time: any;
    editMode = false;
    selectedDateTime: Date;
    selectedWeek: string;
    userDetails: any;

    slots: {
        sundaySlots: string[];
        mondaySlots: string[];
        tuesdaySlots: string[];
        wednesdaySlots: string[];
        thursdaySlots: string[];
        fridaySlots: string[];
        saturdaySlots: string[];
    } = {
            sundaySlots: [],
            mondaySlots: [],
            tuesdaySlots: [],
            wednesdaySlots: [],
            thursdaySlots: [],
            fridaySlots: [],
            saturdaySlots: []
        };
    weekSelected = false;


    constructor(
        public patientService: PatientService,
        private messageService: MessageService,
        private readonly spinner: SpinnerService,
        private testService: TestService,
        private translate: TranslateService,
        public localeService: LocaleService,
        public commonAPi: CommonApiService,
        private datepipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.bookedAppointments = new Array<BookedAppointment>();
        this.spr = new SearchPatientRequest();
        this.dataSource = new MatTableDataSource<BookedAppointment>();
        this.maxDate = new Date();
        this.newAppointmentDataSource = new MatTableDataSource<PatientDetail>();
        this.calendarSlots = data.calendarSlots;
        this.slotDuration = data.slotDuration;
        this.calendar = data.calendar;

        this.ss_id = data.ss_id;
        this.s_time = data.s_time;
        this.selectedWeek = data.selectedWeek;
        this.testItems = new Array<any>();
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    ngOnInit(): void {
        this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
        this.dataSource.data = this.bookedAppointments.slice(0, 3);
        this.getPatientBooking();
        this.getTests();
        this.newAppointmentDataSource.filterPredicate = (data, filter: any) => data.is_active == filter;

        this.commonAPi.GetCalenderSpecialDates(this.ss_id).subscribe(x => {
            this.specialDates = x;
            this.specialDates.forEach(element => {
                element.start_date = new Date(element.start_date);
                element.end_date = new Date(element.end_date);
            });
        });
        this.newAppointmentDataSource.filter = JSON.parse('true');
    }

    viewAll() {
        this.dataSource.data = this.bookedAppointments;
    }

    public rowMouseOver(row: any, isNew: boolean = false): void {
        if (isNew) {
            this.hoverindex = row.pid;
        } else {
            this.hoverindex = row.patient_id;
        }
    }

    selectRow(row: any) {
        this.editMode = false;
        this.editBookingTitle = 'view-booking.add-booking';
        this.selectedPatient = new BookedAppointment();
        this.selectedPatient.id = row.id;
        this.selectedPatient.patient_id = row.id;
        this.selectedPatient.patient_name = row.first_name;
        const currDate = this.s_time;
        const year = currDate.getFullYear();
        const month = currDate.getMonth();
        const date = currDate.getDate();
        const hour = currDate.getHours();
        const minute = currDate.getMinutes();

        const slotDateTime = moment()
            .set('year', year)
            .set('month', month)
            .set('date', date)
            .set('hour', hour)
            .set('minute', minute)
            .set('second', 0);
        this.selectedDateTime = new Date(year, month, date, hour, minute, 0);

        this.dateTimePickerValue.setValue(slotDateTime);
        this.selectedPatient.test_id = '';
        this.selectedPatient.test_name = '';
        this.step = 2;
    }

    omit_special_char(event: any) {
        const k = event.charCode;
        return (
            (k > 64 && k < 91) ||
            (k > 96 && k < 123) ||
            k == 8 ||
            k == 32 ||
            k == 13 ||
            (k >= 48 && k <= 57)
        );
    }

    editAppointment(entity: any) {
        this.selectedPatient = new BookedAppointment();
        this.selectedPatient = entity;
        if (this.editMode) {
            this.editBookingTitle = 'view-booking.update-booking';
            this.selectedPatient.test_id = parseInt(this.selectedPatient.test_id);
        } else {
            this.editBookingTitle = 'view-booking.add-booking';
        }
        const slot_date = new Date(this.selectedPatient.slot_time);
        const currDate = slot_date;
        const year = currDate.getFullYear();
        const month = currDate.getMonth();
        const date = currDate.getDate();
        const hour = currDate.getHours();
        const minute = currDate.getMinutes();

        const slotDateTime = moment()
            .set('year', year)
            .set('month', month)
            .set('date', date)
            .set('hour', hour)
            .set('minute', minute)
            .set('second', 0);
        this.selectedDateTime = new Date(year, month, date, hour, minute, 0);

        this.dateTimePickerValue.setValue(slotDateTime);
        this.step = 2;
    }

    onTestSelect(id: number) {
        this.selectedPatient.test_id = id;
    }

    getTests() {
        this.testService.getTestByCalendar(this.slotDuration.id).subscribe(
            (response: any[]) => {
                this.slotDuration.tests.forEach((c_test: any) => {
                    const c_test_obj = response.find((i) => i.id == c_test.id);
                    if (c_test_obj) {
                        this.testItems.push(c_test_obj);
                    }
                });
            },
            (error) => {
                console.log(error);
            }
        );
    }

    padTo2Digits(num: any) {
        return num.toString().padStart(2, '0');
    }

    formatDate(date: any) {
        return (
            [
                date.getFullYear(),
                this.padTo2Digits(date.getMonth() + 1),
                this.padTo2Digits(date.getDate())
            ].join('-') +
            ' ' +
            [
                this.padTo2Digits(date.getHours()),
                this.padTo2Digits(date.getMinutes()),
                this.padTo2Digits('0')
            ].join(':')
        );
    }

    getPatientBooking() {
        this.spinner.show(true);
        // let s_time=this.formatDate(this.s_time);
        this.patientService
            .getBookedAppointment(this.slotDuration.id, moment.utc(this.s_time).local().format('YYYY-MM-DDTHH:mm:SS.sssZ'))
            .subscribe(
                (response: any[]) => {
                    this.bookedAppointments = response;
                    this.progressValue =
                        (this.bookedAppointments.length / this.slotDuration.capacity) * 100;
                    this.dataSource.data = this.bookedAppointments.slice(0, 3);
                    this.spinner.show(false);
                    if (this.bookedAppointments.length == this.slotDuration.capacity) {
                        this.newAppointmentDataSource =
                            new MatTableDataSource<PatientDetail>();
                        this.reset();
                        this.dateofBirth.disable();
                    }
                },
                (error) => {
                    console.log(error);
                    this.spinner.show(false);
                }
            );
    }

    onSearch() {
        if (
            this.spr.MixedData.trim() == '' &&
            this.spr.QrId.trim() == '' &&
            this.dateofBirth.value == null
        ) {
            this.messageService.alert(
                this.translate.instant('view-booking.search-criteria'),
                5000
            );
        } else {
            this.isSubmitted = true;
            const searchReq = {
                MixedData: this.spr.MixedData,
                QrId: this.spr.QrId,
                Dob:
                    this.dateofBirth.value != null
                        ? this.dateofBirth.value.getFullYear() +
                        '-' +
                        (this.dateofBirth.value.getMonth() + 1) +
                        '-' +
                        this.dateofBirth.value.getDate()
                        : ''
            };
            this.patientService.getPatients(searchReq).subscribe(
                (response: any) => {
                    this.newAppointmentDataSource.data = response.data;
                    if (this.newAppointmentDataSource.data.length == 0) {
                        this.noPatientFoundText = 'view-booking.patient-not-found';
                    }
                },
                (error) => {
                    console.log(error);
                    this.noPatientFoundText = 'view-booking.patient-not-found';
                }
            );
        }
    }

    public reset(): void {
        this.spr = new SearchPatientRequest();
        this.isSubmitted = false;
        this.noPatientFoundText = '';
        this.newAppointmentDataSource = new MatTableDataSource<PatientDetail>();
        this.statusControl.setValue('true');
        this.dateofBirth.setValue(null);
    }

    selectedSlot(e: any) {
        this.selectedPatient.slot_time = e.event.start;
        this.editAppointment(this.selectedPatient);
    }


    onStatusSelect(filterValue: any) {
        filterValue = filterValue.value.trim();
        if (filterValue == 'All') {
        } else {
            this.newAppointmentDataSource.filter = JSON.parse(filterValue);
        }
    }

    submitAppointment(isValid: boolean) {
        const currDate = new Date();
        const curMomentTime = moment()
            .set('year', currDate.getFullYear())
            .set('month', currDate.getMonth())
            .set('day', currDate.getDay())
            .set('hour', currDate.getHours())
            .set('minute', currDate.getMinutes())
            .set('second', 0);

        if (isValid) {
            if (this.dateTimePickerValue.value > curMomentTime) {
                const payload = {
                    calendar_id: this.slotDuration.id,
                    slot_time: moment.utc(this.selectedDateTime).local().format('YYYY-MM-DDTHH:mm:SS.sssZ'),
                    capacity: this.slotDuration.capacity,
                    tests: [this.selectedPatient.test_id],
                    sampling_station_id: this.ss_id
                };
                if (!this.editMode) {
                    payload['patient_id'] = this.selectedPatient.patient_id;
                    this.spinner.show(true);
                    this.patientService.saveAppointment(payload).subscribe(
                        (response: any[]) => {
                            this.spinner.show(false);
                            response;
                            this.messageService.success(
                                this.translate.instant('view-booking.booking-saved'),
                                5000
                            );
                            this.step = 1;
                            this.getPatientBooking();
                        },

                        (errorResponse: HttpErrorResponse) => {
                            if (errorResponse.error.internal_code === 425) {
                                this.spinner.show(false);
                                this.messageService.alert(
                                    this.translate.instant('view-booking.already-booked-error'),
                                    5000
                                );
                            } else if (errorResponse.error.internal_code === 424) {
                                this.spinner.show(false);
                                this.messageService.alert(
                                    this.translate.instant('view-booking.booking-full-error'),
                                    5000
                                );
                            } else {
                                this.spinner.show(false);
                                this.messageService.alert(
                                    this.translate.instant(
                                        'Common.GlobalMessages.InternalServerErrorMessage'
                                    ),
                                    5000
                                );
                            }
                        }
                    );
                } else {
                    payload['patient_id'] = this.selectedPatient.patient_id;
                    payload['id'] = this.selectedPatient.id;
                    payload['sampling_station_id'] = this.ss_id;

                    this.patientService.updateAppointment(payload).subscribe(
                        (response: any[]) => {
                            response;
                            this.messageService.success(
                                this.translate.instant('view-booking.booking-saved'),
                                5000
                            );
                            this.step = 1;
                            this.getPatientBooking();
                        },

                        (error) => {
                            if (error.status === 423) {
                                this.messageService.alert(error.error.message, 5000);
                            } else if (error.error['internal_code'] == 424) {
                                this.messageService.alert(error.error['message'], 5000);
                            } else {
                                this.messageService.alert(error['message'], 5000);
                            }
                        }
                    );
                }
            } else {
                this.messageService.alert(
                    this.translate.instant('view-booking.valid-slot'),
                    5000
                );
            }
        }
    }

    deleteAppointment(id: any, patient_id: any) {
        this.messageService
            .confirm(
                this.translate.instant('view-booking.proceed'),
                this.translate.instant('view-booking.alert')
            )
            .subscribe((actionResult: boolean) => {
                if (actionResult) {
                    this.patientService.deleteAppointment(id, patient_id).subscribe(
                        (data: any) => {
                            data;
                            this.messageService.success(
                                this.translate.instant('view-booking.deleted'),
                                5000
                            );
                            this.getPatientBooking();
                        },
                        (err: { error: { message: string }; message: any }) => {
                            this.messageService.alert(err.error.message, 5000);
                        }
                    );
                }
            });
    }

    public weekChange(val: any, event: any): void {
        if (event?.isUserInput || event == null) {
            this.CalculateSlotsWeek(val);
            console.log(val);
        }
    }

    private CalculateSlotsWeek(val: any) {
        this.weekSelected = false;
        this.selectedWeek = val;
        const finalobj = this.commonAPi.calculateSlots(this.calendar, this.calendarSlots);
        const calender = finalobj.calendar;
        this.slots = finalobj.slots;
        const td = new Date(val);
        const ws_date = new Date(td.getFullYear(), td.getMonth(), td.getDate() - 1, 0, 0, 1);
        const we_date = new Date(td.getFullYear(), td.getMonth(), td.getDate(), 23, 59, 59);
        we_date.setDate(we_date.getDate() + 7);
        // exclude already booked dates
        this.commonAPi.GetCalenderBookedSlots(calender.id, this.datepipe.transform(ws_date, 'yyyy-MM-ddThh:mm:ss').toString() + 'Z', this.datepipe.transform(we_date, 'yyyy-MM-ddThh:mm:ss').toString() + 'Z').subscribe(x => {
            this.data.bookedSlotsCount = x;
        });
        // exclude special date
        for (let i = 0; i < 7; i++) {
            var date = new Date(val);
            date.setDate(date.getDate() + i);
            const eSpecialDates = this.specialDates.find(d => ((date >= d.start_date && date <= d.end_date) || (d.start_date.toDateString() == date.toDateString()) || (d.end_date.toDateString() == date.toDateString())));
            if (eSpecialDates != null && eSpecialDates != undefined) {
                switch (i) {
                    case 1: {
                        this.slots.mondaySlots = null;
                        break;
                    }
                    case 2: {
                        this.slots.tuesdaySlots = null;
                        break;
                    }
                    case 3: {
                        this.slots.wednesdaySlots = null;
                        break;
                    }
                    case 4: {
                        this.slots.thursdaySlots = null;
                        break;
                    }
                    case 5: {
                        this.slots.fridaySlots = null;
                        break;
                    }
                    case 6: {
                        this.slots.saturdaySlots = null;
                        break;
                    }
                    case 0: {
                        this.slots.sundaySlots = null;
                        break;
                    }
                }

            }
        }
        this.weekSelected = true;
        this.step = 3;
    }
}
