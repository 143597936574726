<div class="pull-right">
    <button mat-icon-button color="accent" (click)="dismiss()">
        <span class="icon-ic-close"></span>
    </button>
</div>
<div class="dialog-container">
    <h5>{{ 'results.modify-test' | translate}}</h5>

    <div class="dialog-content">
        {{ 'results.modify-test-desc' | translate}}
        <mat-form-field class="test mt-20">
            <mat-label class="label-float">{{'results.test'|translate}}</mat-label>
            <mat-select required="true" [disableOptionCentering]="true" [formControl]="test_id" (selectionChange)="testChange($event)">
                <mat-option value="{{test.id}}" matTooltip={{test.short_name}}  *ngFor="let test of tests">{{test.short_name}}</mat-option>
              </mat-select>
        </mat-form-field>
        
    </div>

    <div class="actions">        
        <button mat-button (click)="dismiss()" color="accent" class="ghost">{{ 'results.no' | translate}}</button>
            <button mat-raised-button color="primary" type="submit" class="button_content" (click)="confirmed()">{{ 'results.yes' | translate}}</button>
    </div>
</div>
