<div class="container">
    <div class="d-flex justify-content-between heading-box">
        <div class="d-flex align-items-center">
            <span class="heading">{{'ehp-user.users'|translate}}</span>
        </div>

        <div class="d-flex right-box">
            <div id="statusfilter" class="status-filter">
                <mat-form-field floatLabel="never">
                    <mat-select placeholder="All Statuses" [(ngModel)]="status">
                        <mat-option *ngFor="let stepOption of sampleData | keyvalue: unsorted" (click)="statusFilter()"
                            [value]="stepOption.key">
                            {{stepOption.value| translate }}
                        </mat-option>

                    </mat-select>
                </mat-form-field>
            </div>

            <div id="inputsearch">
                <mat-form-field>
                  <mat-label>{{'establishment-list.search' | translate}}</mat-label>
                  <span *ngIf="!searchinput" class="icon-ic-Search mat-icon" matSuffix (click)="onSearch()"></span>
                  <span *ngIf="searchinput" class="icon-ic-clear" matSuffix (click)="clearSearch()"></span>
                  <input [(ngModel)]="searchinput" matInput (keydown.enter)="onSearch()"/>
                </mat-form-field>
              </div>
              

            <div class="divider"></div>

            <div>
                <button mat-raised-button color="primary" id="btn_add" (click)="addUser()"
                    class="add-hospital">
                    + {{'ehp-user.add'|translate}}
                </button>
            </div>
        </div>

    </div>
    <div class="main-container">
        <table mat-table [dataSource]="dataSource"
            class="table-height input-min-spacing table-flex table-with-rounded-corners">
            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{'ehp-user.name'|translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <span>{{element.name}}</span>
                </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> {{'ehp-user.email'|translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <span>{{element.name}}</span>
                </td>
            </ng-container>

            <!-- Role Column -->
            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef> {{'ehp-user.role'|translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <span>{{element.name}}</span>
                </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> {{'ehp-user.type'|translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <span>{{element.name}}</span>
                </td>
            </ng-container>

            <!-- Esatblishment Column -->
            <ng-container matColumnDef="establishment">
                <th mat-header-cell *matHeaderCellDef> {{'ehp-user.establishment'|translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <span>{{element.name}}</span>
                </td>
            </ng-container>

            <!-- Department Column -->
            <ng-container matColumnDef="dept">
                <th mat-header-cell *matHeaderCellDef> {{'ehp-user.department'|translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <span>{{element.name}}</span>
                </td>
            </ng-container>


            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> {{'ehp-user.status'|translate}} </th>
                <td mat-cell *matCellDef="let element">
                    
                    <span class="status" [ngClass]="{
                        'active': element.is_active === true,
                        'inactive': element.is_active === false,
                        'pending': element.is_active === null
                      }">
                        {{ 
                          element.is_active === true ? ('ehp-user.active'|translate) : 
                          (element.is_active === false ? ('ehp-user.suspended'|translate) : 
                          ('ehp-user.pending'|translate))
                        }}
                      </span>
                      
                </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <button class="mat-tbl-icon" (click)="editDepartment(element)" mat-icon-button>
                        <span class="icon-ic-edit" matTooltip="{{'ehp-user.edit'|translate}}"></span>
                    </button>
                    <button class="mat-tbl-icon" (click)="editDepartment(element)" mat-icon-button>
                        <span class="icon-ic-delete" matTooltip="{{'ehp-user.delete'|translate}}"></span>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnList"></tr>
            <tr mat-row *matRowDef="let row; columns: columnList;"></tr>
        </table>
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons="true">
        </mat-paginator>
    </div>
</div>