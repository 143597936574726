<div class="register-kit-container">

  <header class="pull-left">
    <h3 class="pull-left">
      <span class="icon-ic-back" (click)="goToPrevious()" *ngIf="steps > 0"></span>
      {{'appointments.register-test-kit' | translate}}
    </h3>
    <mat-form-field class="search-input">
      <input matInput #searchInput [formControl]="seacrchInput" placeholder="{{'appointments.search' | translate}}"
        name="search" id="search" (keyup)="findPatient(true)" />
      <div *ngIf="!searchInput.value" (click)="scanQR()" class="bar-code-icon"><span
          class="icon-ic-scan-barcode"></span></div>
      <div *ngIf="searchInput.value" (click)="clearSearch(); reset()" class="clear">
        <p>{{'appointments.clear-search' | translate}}</p>
      </div>
    </mat-form-field>
  </header>
  <table *ngIf="refresh || steps === 0" class="ecl-table" width="100%" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef> {{'appointments.first-name' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="flex-td">
        <div class="avatar onlyMobile"><span class="titleWrapBox">{{element.first_name.charAt(0)}}</span></div>
        <span class="title onlyDesktop" *ngIf="element.first_name">{{element.first_name}}</span>
        <div class="mobile-td">
          <span class="title" *ngIf="element.first_name"><span>{{element.first_name}} {{element.last_name}}
              {{element.birth_name}}</span></span>
          <!-- <span class="subText">{{'appointments.ssn' | translate}}: {{element.ssn}}</span> -->
          <ng-container *ngIf="element.ssn">
            <span class="subText">{{'appointments.ssn' | translate}}: {{element.ssn}}</span>
          </ng-container>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef> {{'appointments.last-name' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="hide-column"><span>{{element.last_name}} </span></td>
    </ng-container>
    <ng-container matColumnDef="birthName">
      <th mat-header-cell *matHeaderCellDef> {{'appointments.birth-name' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="hide-column"><span>{{element.birth_name}} </span></td>
    </ng-container>
    <ng-container matColumnDef="dateOfBirth">
      <th mat-header-cell *matHeaderCellDef> {{'appointments.dob' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="flex2-row">
        <div class="row-flex">
          <span class="colT"><span class="icon-ic-date onlyMobile"></span>{{element.dob | date:'dd/MM/yyyy'}}</span>
          <span class="colT onlyMobile"><span class="icon-ic-gender"></span>{{element.gender| translate}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="ssn">
      <th mat-header-cell *matHeaderCellDef> {{'appointments.ssn' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="hide-column"><span>{{element.ssn}} </span></td>
    </ng-container>
    <ng-container matColumnDef="gender">
      <th mat-header-cell *matHeaderCellDef> {{'appointments.gender' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="hide-column"> <span>{{element.gender | translate}}</span></td>
    </ng-container>
    <ng-container matColumnDef="noRecord">
      <td colspan="6" mat-footer-cell *matFooterCellDef>
        <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
        <div class="desc">
          <p>{{'appointments.empty' | translate}}</p>
          <p>{{'appointments.search-patient' | translate}}</p>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row (click)="rowClicked(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
    <ng-template [ngIf]="dataSource && dataSource.length === 0">
      <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
    </ng-template>
  </table>
  <form id="registerKitForm">
    <ng-template [ngIf]="steps === 1 || steps === 2">
      <div class="selected-value">
        <div class="flex-td">
          <div class="avatar onlyMobile"><span
              class="titleWrapBox">{{selectedPatient?.first_name[0].toUpperCase()}}</span></div>
          <div class="mobile-td">
            <span class="title"><span>{{selectedPatient.first_name}} {{selectedPatient.birth_name}}
                {{selectedPatient.last_name}}</span></span>
            <ng-container *ngIf="selectedPatient?.ssn">
              <span class="subText">{{'appointments.ssn' | translate}}<span class="light pl-0">:
                  {{selectedPatient?.ssn}}</span></span>
            </ng-container>

          </div>
        </div>
        <div class="flex2-row">
          <div class="row-flex">
            <span class="colT"><span class="icon-ic-date onlyMobile"></span>{{selectedPatient?.dob | date:
              'dd/MM/yyyy'}} </span>
            <span class="colT onlyMobile"><span class="icon-ic-gender"></span>{{selectedPatient?.gender |
              translate}}</span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template [ngIf]="steps === 1">
      <div class="radio-selection" *ngIf="appointments.length>0">
        <h3>{{'appointments.booking-info' | translate}}</h3>
        <mat-radio-group [formControl]="testSelection">
          <ng-container *ngFor="let appointment of appointments">
            <mat-radio-button (click)="selectedAppointment=appointment" value="{{appointment.AppointmentId}}"
              [checked]="selectedAppointment.AppointmentId==appointment.AppointmentId">
              <div class="twoColumnGrid single gap">
                <div class="infoTabs pl-30">
                  <span class="icon-ic-test font-l"></span>
                  <p class="form-label">{{'appointments.test-detail' | translate}}:</p>
                  <div class="form-value pre-wrap">{{appointment.TestShortName}}</div>
                </div>
                <div class="infoTabs pl-30">
                  <span class="icon-ic-location font-l"></span>
                  <p class="form-label">{{'appointments.lab' | translate}}</p>
                  <div class="form-value name-class">{{appointment.SamplingStationName}}</div>
                </div>
                <div class="infoTabs pl-30">
                  <span class="icon-ic-date font-l"></span>
                  <p class="form-label">{{'appointments.booked-slot' | translate}}</p>
                  <div class="form-value" *ngIf="appointment.SlotTime">{{appointment.SlotTime | dateFormat}}</div>
                  <div class="form-value" *ngIf="!appointment.SlotTime">NA</div>
                </div>
              </div>
            </mat-radio-button>
          </ng-container>
          <!--       
        <mat-radio-button value="2" [checked]="true">
          <div class="twoColumnGrid single gap">
            <div class="infoTabs pl-30">
              <span class="icon-ic-test font-l"></span>
              <p class="form-label">{{'appointments.test-detail' | translate}}:</p>
              <div class="form-value">Fit to Fly - Antigen - Post</div>
            </div>
            <div class="infoTabs pl-30">
              <span class="icon-ic-location font-l"></span>
              <p class="form-label">{{'appointments.lab' | translate}}</p>
              <div class="form-value">Eurofins Analclinic</div>
            </div>
            <div class="infoTabs pl-30">
              <span class="icon-ic-date font-l"></span>
              <p class="form-label">{{'appointments.booked-slot' | translate}}</p>
              <div class="form-value">11/06/2020 | 9:00 - 9:20 am</div>
            </div>
          </div>
        </mat-radio-button>
        <mat-radio-button value="3" [checked]="true">
          <div class="twoColumnGrid single gap">
            <div class="infoTabs pl-30">
              <span class="icon-ic-test font-l"></span>
              <p class="form-label">{{'appointments.test-detail' | translate}}:</p>
              <div class="form-value">Fit to Fly - Antigen - Post</div>
            </div>
            <div class="infoTabs pl-30">
              <span class="icon-ic-location font-l"></span>
              <p class="form-label">{{'appointments.lab' | translate}}</p>
              <div class="form-value">Eurofins Analclinic</div>
            </div>
            <div class="infoTabs pl-30">
              <span class="icon-ic-date font-l"></span>
              <p class="form-label">{{'appointments.booked-slot' | translate}}</p>
              <div class="form-value">11/06/2020 | 9:00 - 9:20 am</div>
            </div>
          </div>
        </mat-radio-button> -->
        </mat-radio-group>
      </div>
    </ng-template>
    <ng-template [ngIf]="steps === 2 && selectedAppointment.AppointmentId>0">
      <div class="main-container">
        <div class="info-cover">
          <h3>{{'appointments.booking-info' | translate}}</h3>
          <div class="twoColumnGrid single gap">
            <div class="infoTabs pl-30">
              <span class="icon-ic-test font-l"></span>
              <p class="form-label">{{'appointments.test-detail' | translate}}:</p>
              <div class="form-value">{{selectedAppointment.TestShortName}}</div>
            </div>
            <div class="infoTabs pl-30">
              <span class="icon-ic-location font-l"></span>
              <p class="form-label">{{'appointments.lab' | translate}}</p>
              <div class="form-value">{{selectedAppointment.SamplingStationName}}</div>
            </div>
            <div class="infoTabs pl-30">
              <span class="icon-ic-date font-l"></span>
              <p class="form-label">{{'appointments.booked-slot' | translate}}</p>
              <div class="form-value" *ngIf="selectedAppointment.SlotTime">{{selectedAppointment.SlotTime | dateFormat}}
              </div>
              <div class="form-value" *ngIf="!selectedAppointment.SlotTime">NA</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="steps === 1 || steps === 2">
      <mat-form-field>
        <mat-label class="label-float">{{'appointments.scan-id' | translate}}</mat-label>
        <input matInput name="testqr_idKitId" id="qr_id" [formControl]="qr_id" required readonly />
        <mat-error *ngIf="qr_id.errors?.required" class="my-15">{{'appointments.require-qr_id' | translate}}</mat-error>
        <mat-error *ngIf="qr_id.invalid && !qr_id.errors?.required" class="my-15">{{'appointments.valid-qr_id' |
          translate}}</mat-error>
      </mat-form-field>
    </ng-template>
    <ng-template [ngIf]="steps === 2">
      <div class="main-container">
        <div class="form-cover mt-25">
          <h3 class="pull-left">{{'appointments.test-type' | translate}}</h3>
          <mat-form-field def="Status" class="mb-20">
            <mat-label class="label-float">{{'appointments.test-type' | translate}}</mat-label>
            <mat-select required="true" [disableOptionCentering]="true" [formControl]="test_id"
              (selectionChange)="testChange($event)">
              <mat-option value="{{test.id}}" *ngFor="let test of tests">{{test.short_name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="scan-cover mb-20">
            <label>{{'appointments.scan-test-code' | translate}}</label>
            <div class="image-cover">
              <!-- <img src="https://via.placeholder.com/200/92c952"/> -->
              <span class="icon icon-ic-scan-barcode" (click)="scanTestKitCode()"></span>
            </div>
          </div>
          <mat-form-field>
            <mat-label class="label-float">{{'appointments.scan-test-code' | translate}}</mat-label>
            <input matInput name="testKitId" id="testKitId" [formControl]="test_kit_id" />
            <mat-error *ngIf="test_kit_id.errors?.required" class="my-15">{{'appointments.require-test_kit_id' |
              translate}}</mat-error>
            <mat-error *ngIf="test_kit_id.invalid && !test_kit_id.errors?.required"
              class="my-15">{{'appointments.valid-test_kit_id' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-template>
    <ng-template [ngIf]="steps === 1 || steps === 2">
      <div class="actions mt-40">
        <button mat-raised-button (click)="onRegister()" class="button_width" color="primary" type="submit"
          [disabled]="!selectedAppointment?.Barcode == ''">{{(steps ===
          2 ? 'appointments.register-test-kit' : 'appointments.submit' ) | translate}}</button>
        <button *ngIf="steps === 2" (click)="steps = steps - 1" mat-button color="accent"
          class="ghost button_width">{{'appointments.cancel' | translate}}</button>
      </div>
    </ng-template>
  </form>
</div>