import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appFileDrop]'
})
export class FileDropDirective {
    /* #region Fields */
    @Output() public filesDropped = new EventEmitter<FileList>();
    @Output() public filesHovered = new EventEmitter<boolean>();
    /* #endregion */

    /* #region Event Listeners and Methods */
    @HostListener('drop', ['$event'])
    onDrop($event: any) {
        $event.preventDefault();
        const transfer = $event.dataTransfer;
        this.filesDropped.emit(transfer.files);
        this.filesHovered.emit(false);
    }

    @HostListener('dragover', ['$event'])
    onDragOver($event: any) {
        $event.preventDefault();
        this.filesHovered.emit(true);
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave($event: any) {
        $event.preventDefault();
        this.filesHovered.emit(false);
    }
    /* #endregion */

}
