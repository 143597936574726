<div class="ecl-table-container">
    <header class="pull-left">
        <h3 class="pull-left"> {{'landing-page-config.heading'|translate}}</h3>
    </header>
    <mat-tab-group [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabChange($event)" class="tab-app pull-left"
        animationDuration="1000ms">
        <mat-tab label="{{'landing-page-config.hero-banner'|translate}}">
            <app-hero-banner [heroBanner]="heroBannerWrapper" (cancelClicked)="cancel()"
                (updateData)="updateLandingPageConfiguration($event)"></app-hero-banner>
        </mat-tab>

        <mat-tab label="{{'landing-page-config.key-feature'|translate}}">
            <app-key-feature [keyFeatures]="keyFeaturesWrapper" (cancelClicked)="cancel()"
                (updateData)="updateLandingPageConfiguration($event)"></app-key-feature>
        </mat-tab>
        <mat-tab label="{{'landing-page-config.our-presence'|translate}}">
            <app-our-presence [ourPresence]="ourPresenceWrapper" (cancelClicked)="cancel()"
                (updateData)="updateLandingPageConfiguration($event)"></app-our-presence>
        </mat-tab>
        <mat-tab label="{{'landing-page-config.patient-journey'|translate}}">
            <app-patient-journey [patientJourney]="patientJourney" (cancelClicked)="cancel()"
                (updateData)="updateLandingPageConfiguration($event)"></app-patient-journey>
        </mat-tab>
        <mat-tab label="{{'landing-page-config.frequently-test'|translate}}">
            <app-frequently-booked-test [fbtw]="fbtws" (cancelClicked)="cancel()"
                (updateData)="updateLandingPageConfiguration($event)"> </app-frequently-booked-test>
        </mat-tab>
        <mat-tab label="{{'landing-page-config.company-info'|translate}}">
            <app-company-info [companyInfo]="companyInfo" (cancelClicked)="cancel()"
                (updateData)="updateLandingPageConfiguration($event)"></app-company-info>
        </mat-tab>
        <mat-tab label="{{'landing-page-config.seo'|translate}}">
            <app-seo [seoData]="seoWrapper" (cancelClicked)="cancel()"
                (updateData)="updateLandingPageConfiguration($event)"></app-seo>
        </mat-tab>
    </mat-tab-group>
</div>