import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '../../core';


@Injectable({
    providedIn: 'root'
})
export class TestService {

    constructor(private http: HttpClient, private readonly appSettings: AppSettingsService) { }

    getAdminTests() {

        return this.http.get<any>(this.appSettings.adminBaseUrl + 'product/tests?active=yes');

    }
    ValidateExternalID(external_id: string) {

        return this.http.get<any>(this.appSettings.adminBaseUrl + 'test/isvalidexternalid?external_id=' + external_id);

    }
    getAllAdminTestTypes() {

        return this.http.get<any>(this.appSettings.adminBaseUrl + 'product/tests');

    }
    postAdminTestTypes(inputValue: any) {
        return this.http.post(this.appSettings.adminBaseUrl + 'product/tests', inputValue);
    }
    postAdminTestTypesCreate(data:any){
        return this.http.post(this.appSettings.adminBaseUrl + 'testtype/add',data)
    }
    putAdminTestTypesUpdate(data:any){
        return this.http.put(this.appSettings.adminBaseUrl+'testtype/update',data)
    }
    getTestByCalendar(c_id: any, s_id: any = 0, external_code: any = '') {
        return this.http.get<any>(this.appSettings.adminBaseUrl + 'calendar/tests?sampling_station_id=' + s_id + '&calendar_id=' + c_id + '&external_code=' + external_code);
    }
    getTestBySamplingStation(s_id: any, c_id: any) {
        return this.http.get<any>(this.appSettings.adminBaseUrl + 'stations/tests?sampling_station_id=' + s_id + '&calendar_id=' + c_id);
    }
    getAntigenPCRTests(s_id: any) {
        return this.http.get<any>(this.appSettings.adminBaseUrl + 'calendar/tests?sampling_station_id=' + s_id + '&test_kit_required=true');
    // return this.http.get<any>(this.appSettings.adminBaseUrl+"product/testsbykit?test_kit_required=true");
    }
}
