<div class="edit-container">
    <div class="header">
        <div class="pull-right mrbtm">
            <button class="slider_closebtn" mat-button color="accent" type="button" (click)="dismiss()">
                <span class="icon-ic-close"></span>
            </button>
        </div>
    </div>
    <div class="content">
        <img class="previewImg w-100" src="{{sliderFormData?.imageData}}" />

        <div class="actions">
            <button mat-button color="accent" class="ghost" type="button" (click)="dismiss()" id="clear_btn"> {{'close'
                | translate}}</button>
        </div>
    </div>
</div>