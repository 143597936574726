import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
import { SpinnerService } from '../../../core';
import { LoaderService } from '../../../main';
import { MessageService } from '../../../main/services/message.service';
import { ResultStatus } from '../../patient/results/results.model';
import { BookingAppointmentGuestService } from '../../services/booking-appointment-guest.service';
import { CommonApiService } from '../../services/common-api.service';
import { PatientAppointmentService } from '../../services/patient-appointment.service';
import { ScanQrComponent } from '../find-patient/scan-qr/scan-qr.component';
import { ChangeTestComponent } from './change-test/change-test.component';
import { RedirectDialogComponent } from '../../patient/results/redirect-dialog/redirect-dialog.component';
import { PatientService } from '../../services/patient.service';

export interface ReceptionistResult {
    firstName: string;
    lastName: string;
    birthName: string;
    test: string;
    dateTime: string;
    status: Array<{ title: string; status: string }>;
    resultLink: string;
    resultAccessCode: string;
    resultAvailable: boolean;
}

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
    seacrchInput = new FormControl();
    @Input() infoPage: boolean = false;
    testFormControl = new FormControl();

    public appointmentDate = new FormControl(null, [Validators.required]);
    public selectedDate = '';

    isPdfViewerOpened: boolean;
    isPdfResultViewerOpened: boolean;
    displayedColumns: string[] = [
        'name',
        'gender',
        'dob',
        'test',
        'testKitCode',
        'dateTime',
        'status',
        'actions'
    ];
    patient: any;
    allResultsdataSource: any = [];
    watitingResultsdataSource: any = [];
    readyResultsdataSource: any = [];
    isDOB = false;
    searchText = '';
    testArray: any;

    test = '';

    testList: any[];
    selectedTest: any;

    refresh = false;
    resultStatusList: ResultStatus[];
    selectedTestList: any[] = [];
    fromDateMin: Date;
    pdfBlob: string;
    sampleFileName: any;
    resultId: any;

    constructor(
        private translate: TranslateService,
        private localeService: LocaleService,
        public patientAppointmentService: PatientAppointmentService,
        private commonAPi: CommonApiService,
        private dialog: MatDialog,
        public router: Router,
        public loader: LoaderService,
        private readonly messageService: MessageService,
        public bookingAppointmentGuestService: BookingAppointmentGuestService,
        private readonly spinner: SpinnerService,
        private readonly patientService: PatientService
    ) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
        const datePipe = new DatePipe('en-US');
        this.fromDateMin = new Date();
        this.fromDateMin.setDate(this.fromDateMin.getDate());
        this.appointmentDate.setValue(this.fromDateMin);
        this.selectedDate = datePipe.transform(this.fromDateMin, 'yyyy-MM-dd');
    }

    ngOnInit(): void {
        if (this.infoPage) {
            this.displayedColumns = [
                'test',
                'testKitCode',
                'dateTime',
                'status',
                'actions'
            ];
        }
        this.getMasterResultStatusList();
        this.getTestBySamplingStationId();
    }

    editTestClicked(event: any) {
        const rowData = event.data;
        const dialogRef = this.dialog.open(ChangeTestComponent, {
            width: 'auto',
            disableClose: true,
            autoFocus: false,
            restoreFocus: false,
            data: { rowData }
        });
        dialogRef.afterClosed().subscribe(() => {
            this.getMasterResultStatusList();
            this.getTestBySamplingStationId();
        });
    }
    showResultData(entity: any) {
        if (entity.DiagnosisReportUrl != "") {
            this.openResultPdf(entity);
        }
        else {
            const dialogRef = this.dialog.open(RedirectDialogComponent, {
                width: "600px",
                height: "500px",
                disableClose: true,
                data: { title: this.translate.instant("history.Result available"), result: entity, isFromPatient: false },
            });
            dialogRef.afterClosed().subscribe(() => {
            });
        }
    }
    openPdf(event: any) {
        const rowData = event.data;
        this.spinner.show(true);
        this.sampleFileName = rowData?.KitImagePath.substring(rowData?.KitImagePath.lastIndexOf('/') + 1);
        this.patientAppointmentService
            .getSampleKit(rowData?.KitImagePath)
            .subscribe((res: any) => {
                const byteArray = new Uint8Array(
                    atob(res)
                        .split('')
                        .map((char) => char.charCodeAt(0))
                );
                const file = new Blob([byteArray], { type: 'application/octet-stream' });
                this.pdfBlob = URL.createObjectURL(file);
                this.spinner.show(false);
                this.resultId = rowData.Barcode;
                this.isPdfViewerOpened = true;
            });
    }
    openResultPdf(selectedRowData: any) {
        this.spinner.show(true);
        var resultCertificateName = selectedRowData.DiagnosisReportUrl.substring(selectedRowData.DiagnosisReportUrl.lastIndexOf("/") + 1);
        this.patientAppointmentService
            .getResultCertificate(resultCertificateName)
            .subscribe((res: any) => {
                const byteArray = new Uint8Array(
                    atob(res)
                        .split("")
                        .map((char) => char.charCodeAt(0))
                );
                const file = new Blob([byteArray], { type: "application/octet-stream" });
                this.pdfBlob = URL.createObjectURL(file);
                this.spinner.show(false);
                this.resultId = selectedRowData.Barcode;
                this.isPdfResultViewerOpened = true;
            });
    }
    getMasterResultStatusList() {
        // Getting Result Status List
        this.commonAPi.getResultStatusList().subscribe((resultStatus) => {
            resultStatus.sort((a, b) => a.KeyOrder - b.KeyOrder);
            this.resultStatusList = resultStatus;
            this.seacrchInput.setValue(this.loader.patientSearchText);

            this.patient = this.loader.patient;
            this.loadPatientResult();
        });
    }

    findPatient() {
        if (this.resultStatusList.length === 0) {
            this.getMasterResultStatusList();
        }

        this.loader.patientSearchText = this.seacrchInput.value;
        this.loader.patient = {};
        this.patient = {};
        if (this.seacrchInput?.value.length == 0 || this.seacrchInput?.value.length > 2) {
            this.loadPatientResult();
        } else {
            this.reset();
        }
    }

    private loadPatientResult() {
        const self = this;
        self.refresh = false;
        const re = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/; // regex to validate DOB format
        if (re.test(this.seacrchInput.value)) {
            this.isDOB = true;
            this.searchText = this.seacrchInput.value.split('/').reverse().join('-');
        } else {
            this.isDOB = false;
            this.searchText = this.seacrchInput.value;
        }
        const searchReq = {
            search_text: this.searchText, ss_id: this.loader.ss_id, test_ids: this.selectedTest, from_appointment: false,
            is_dob: this.isDOB, patient_id: this.patient?.id
        };
        if (!this.infoPage) {
            searchReq["appointment_date"] = this.selectedDate;
        }
        setTimeout(() => {

            this.patientAppointmentService
                .getAppointmentListBySearch(searchReq
                )
                .subscribe(
                    (response: any[]) => {
                        self.refresh = false;
                        if (
                            response != null &&
                            response['message'] !== 'No Patient Found'
                        ) {
                            this.allResultsdataSource = response;
                            this.watitingResultsdataSource = this.allResultsdataSource.filter(
                                (obj: any) => obj.StatusId < this.resultStatusList[3].Id && obj.StatusId >= this.resultStatusList[0].Id
                            );

                            this.readyResultsdataSource = this.allResultsdataSource.filter(
                                (obj: any) => obj.StatusId === this.resultStatusList[3].Id
                            );
                            self.refresh = true;
                        } else {

                            this.reset();
                        }
                    },
                    (error) => {
                        this.reset();
                        console.log(error);
                    }
                );
        }, 0);
    }

    reset() {
        const self = this;
        if (this.seacrchInput.value || this.selectedTest || this.selectedDate) {
            self.refresh = false;
            setTimeout(() => {
                self.allResultsdataSource = [];
                self.watitingResultsdataSource = [];
                self.readyResultsdataSource = [];
                self.refresh = true;
            }, 0);
        }
    }

    clearSearch() {
        const self = this;
        self.refresh = false;
        this.loader.patientSearchText = '';
        this.loader.patient = {};
        this.seacrchInput.reset();
        setTimeout(() => {
            this.getMasterResultStatusList();
            self.refresh = true;
        }, 0);
    }

    scanQR() {
        this.dialog
            .open(ScanQrComponent, {
                width: '500px',
                height: '450px',
                autoFocus: false
            })
            .afterClosed()
            .subscribe((res) => {
                setTimeout(() => {
                    const searchReq = {
                        search_text: res, ss_id: this.loader.ss_id, appointment_date: this.selectedDate, test_ids: this.selectedTest, from_appointment: false,
                        is_dob: false,
                        patient_id: this.patient?.id
                    };
                    this.patientAppointmentService
                        .getAppointmentListBySearch(searchReq)
                        .subscribe((response: any[]) => {
                            if (
                                response != null &&
                                response['message'] !== 'No Patient Found'
                            ) {
                                this.refresh = false;
                                this.allResultsdataSource = response;
                                this.watitingResultsdataSource =
                                    this.allResultsdataSource.filter((obj: any) => obj.StatusId < this.resultStatusList[3].Id && obj.StatusId >= this.resultStatusList[0].Id);

                                this.readyResultsdataSource = this.allResultsdataSource.filter(
                                    (obj: any) => obj.StatusId === this.resultStatusList[3].Id
                                );
                                this.refresh = true;
                            } else {
                                this.messageService.warning(this.translate.instant('receptionist.invalid-QR'));
                            }
                        });
                }, 0);
            });
    }

    getTestBySamplingStationId() {
        this.bookingAppointmentGuestService
            .getAdmintests(this.loader.ss_id)
            .subscribe(
                (getResponse) => {
                    this.testArray = getResponse;
                    this.testList = [...this.testArray];
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    allChecked(event: any) {
        if (event) {
            this.testList.forEach((itemTestType: any) => {
                this.selectedTestList.push(itemTestType);
            });
        } else {
            this.selectedTestList = [];
        }
        this.selectedTest = this.selectedTestList.map((item: any) => item.id).join(',');
        if (this.selectedTest != '') {
            this.loadPatientResult();
        }

    }

    onTestSelected(event: any) {
        console.log(event);
        if (this.testFormControl.value.length > 0) {
            this.selectedTest = this.testFormControl.value.map((item: any) => item.id).join(',');
        } else {
            this.selectedTest = '';
        }
        this.loadPatientResult();

        console.log(this.selectedTest);
    }

    /**
   * This method will envoke when user will change the date by datepicker or manually.
   * @param e ChangedDate Value
   */
    onSelectedDate(e: any) {
        const datePipe = new DatePipe('en-US');
        this.selectedDate = datePipe.transform(e, 'yyyy-MM-dd');

        this.loadPatientResult();

    }


    onNameClicked(event: any) {
        const searchReq = {
            QrId: event.data.QrId,
            "org_id": this.loader.ss_selected_org_id,
        };
        //this.loader.patientSearchText = event.data.QrId;

        this.patientService
            .getPatients(searchReq)
            .subscribe((response: any) => {
                if (response.data["message"] !== "No Patient Found") {
                    console.log(response)
                    this.loader.patient = response?.data[0];
                    this.loader.patientSearchText = response?.data[0].first_name + " " + response?.data[0].last_name;
                    this.loader.highlightLeftMenu(["receptionist/patient-info"], {
                        state: { patient: response.data[0], searchInputValue: this.seacrchInput.value },
                    });
                } else {
                    this.messageService.warning(
                        this.translate.instant("receptionist.invalid-QR")
                    );
                }
            });

    }
}
