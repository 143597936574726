// import { Time } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { LocaleService } from "../../../../app.locale.service";
import { MessageService } from "../../../../main";
// import { MatCheckbox } from "@angular/material/checkbox";
import { BookingSetupService } from "../../../../services/booking-setup.service";
import { MultiSelect } from "../../../../shared/models/multiple-select";
import { TestService } from "../../../services/add-test-service.service";
import { CalendarInfo, Dow } from "./add-calendar.model";
import { int } from "@zxing/library/esm/customTypings";

@Component({
  selector: "app-add-calendar",
  templateUrl: "./add-calendar.component.html",
  styleUrls: ["./add-calendar.component.scss"],
})
export class AddCalendarComponent implements OnInit {
  fieldDisabled = false;
  isEditMode = false;
  calendarInfo: CalendarInfo;
  @Output() updateCalendarData = new EventEmitter<any>();
  // @Output() cancelProcessEvent = new EventEmitter<any>();

  @Input() samplingStationId: any;
  @Input() isCalendarAvailable = false;
  @Input() calendar: any[];
  @Input() openingAndClosingData: any[];
  WeeklyCalender: Array<Dow>;
  private DefaultWeeklyCalender: Array<Dow> = [
    {
      id: 0,
      calendar_id: 0,
      dow: 1,
      day: "admin.monday",
      start_time: "00:00",
      end_time: "00:00",
      break_begins: "00:00",
      break_ends: "00:00",
      active: false,
      isDisabled: true,
      is_open_valid: true,
      is_close_valid: true,
      is_break_end_valid: true,
      is_break_start_valid: true,
    },
    {
      id: 0,
      calendar_id: 0,
      dow: 2,
      day: "admin.tuesday",
      start_time: "00:00",
      end_time: "00:00",
      break_begins: "00:00",
      break_ends: "00:00",
      active: false,
      isDisabled: true,
      is_open_valid: true,
      is_close_valid: true,
      is_break_end_valid: true,
      is_break_start_valid: true,
    },
    {
      id: 0,
      calendar_id: 0,
      dow: 3,
      day: "admin.wednesday",
      start_time: "00:00",
      end_time: "00:00",
      break_begins: "00:00",
      break_ends: "00:00",
      active: false,
      isDisabled: true,
      is_open_valid: true,
      is_close_valid: true,
      is_break_end_valid: true,
      is_break_start_valid: true,
    },
    {
      id: 0,
      calendar_id: 0,
      dow: 4,
      day: "admin.thursday",
      start_time: "00:00",
      end_time: "00:00",
      break_begins: "00:00",
      break_ends: "00:00",
      active: false,
      isDisabled: true,
      is_open_valid: true,
      is_close_valid: true,
      is_break_end_valid: true,
      is_break_start_valid: true,
    },
    {
      id: 0,
      calendar_id: 0,
      dow: 5,
      day: "admin.friday",
      start_time: "00:00",
      end_time: "00:00",
      break_begins: "00:00",
      break_ends: "00:00",
      active: false,
      isDisabled: true,
      is_open_valid: true,
      is_close_valid: true,
      is_break_end_valid: true,
      is_break_start_valid: true,
    },
    {
      id: 0,
      calendar_id: 0,
      dow: 6,
      day: "admin.saturday",
      start_time: "00:00",
      end_time: "00:00",
      break_begins: "00:00",
      break_ends: "00:00",
      active: false,
      isDisabled: true,
      is_open_valid: true,
      is_close_valid: true,
      is_break_end_valid: true,
      is_break_start_valid: true,
    },
    {
      id: 0,
      calendar_id: 0,
      dow: 7,
      day: "admin.sunday",
      start_time: "00:00",
      end_time: "00:00",
      break_begins: "00:00",
      break_ends: "00:00",
      active: false,
      isDisabled: true,
      is_open_valid: true,
      is_close_valid: true,
      is_break_end_valid: true,
      is_break_start_valid: true,
    },
  ];
  savealert = {
    edit: "admin.save-alert.edit",
    add: "admin.save-alert.add",
    delete: "admin.save-alert.delete",
    test_required: "admin.save-alert.test-required",
    dow_active: "admin.save-alert.dow-active",
  };
  errorMessage = "";

  @Input() testList: MultiSelect[] = [];
  range: any[] = [
    { id: 5, displayText: "5 " },
    { id: 10, displayText: "10 " },
    { id: 15, displayText: "15 " },
    { id: 30, displayText: "30 " },
    { id: 45, displayText: "45 " },
    { id: 60, displayText: "60 " },
  ];
  slots: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20];
  public selectedtestList: MultiSelect[];
  public selectedYears: MultiSelect[];
  modifiedTest = false;

  constructor(
    private data: BookingSetupService,
    private messageService: MessageService,
    private translate: TranslateService,
    private localeService: LocaleService,
    private testService: TestService
  ) {
    this.WeeklyCalender = new Array<Dow>();
    this.calendarInfo = new CalendarInfo();
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
  }

  editCalendar() {
    this.isEditMode = true;
    this.fieldDisabled = false;
  }

  ngOnInit(): void {
    this.WeeklyCalender = this.DefaultWeeklyCalender;
    this.updateDowByOpeningAndClosingHours();

    if (this.calendar && this.calendar.length > 0) {
      if (parseInt(sessionStorage.getItem("uci")) > 0) {
        Object.assign(
          this.calendarInfo,
          this.calendar.find(
            (i) => i.id == parseInt(sessionStorage.getItem("uci"))
          )
        );
      } else {
        Object.assign(this.calendarInfo, this.calendar[0]);
      }

      this.buildDows();
      this.loadTest();
      this.fieldDisabled = true;
      if (this.isCalendarAvailable == false) {
        this.calendarInfo = new CalendarInfo();
        this.buildDows();
        this.fieldDisabled = false;
        this.selectedtestList = new Array<MultiSelect>();
      }
    }
  }

  public loadTest() {
    this.testService
      .getTestBySamplingStation(this.samplingStationId, this.calendarInfo.id)
      .subscribe((item: any) => {
        const tests = item;
        this.testList = [];
        tests.forEach((t: any) => {
          this.testList.push({
            id: t.id,
            displayText: t.short_name,
            active: true,
          });
        });
        if (this.calendarInfo.tests && this.calendarInfo.tests.length > 0) {
          // test is available
          this.buildSelectedTest();
        } else {
          // test is not available
        }
      });
  }

  private buildSelectedTest() {
    this.selectedtestList = new Array<MultiSelect>();
    if (this.calendarInfo.tests && this.calendarInfo.tests.length > 0) {
      this.calendarInfo.tests.forEach((t: any) => {
        const testItem = this.testList.find((i) => i.id == t.id);
        if (testItem) {
          this.selectedtestList.push({
            id: t.id,
            displayText: testItem.displayText,
            active: true,
          });
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isCalendarAvailable?.currentValue == false) {
      this.calendarInfo = new CalendarInfo();
      this.buildDows();
      this.fieldDisabled = false;
      this.selectedtestList = new Array<MultiSelect>();
    }
  }

  private buildDows() {
    if (this.calendarInfo.dows != null && this.calendarInfo.dows.length > 0) {
      this.WeeklyCalender = this.DefaultWeeklyCalender;

      this.updateDowByOpeningAndClosingHours();
      this.openingAndClosingData.forEach((wc: any) => {
        const mcDow = this.calendarInfo.dows.find((i) => i.dow == wc.day);

        if (mcDow) {
          const selectedWeekData = this.WeeklyCalender.find(
            (i) => i.dow == wc.day
          );
          selectedWeekData.id = mcDow.id;
          selectedWeekData.calendar_id = mcDow.calendar_id;
          selectedWeekData.start_time = mcDow.start_time;
          selectedWeekData.end_time = mcDow.end_time;
          selectedWeekData.break_begins = mcDow.break_begins;
          selectedWeekData.break_ends = mcDow.break_ends;
          selectedWeekData.active = mcDow.active;
        }
      });
    } else {
      this.WeeklyCalender = this.DefaultWeeklyCalender;
      this.updateDowByOpeningAndClosingHours();
    }
  }

  updateDowByOpeningAndClosingHours() {
    // Case 1: If opening and closing hours are not setup in sampling station then disable those days in booking setup
    // Case 2: If opening and closing hours are alreday setup in sampling station and for the same day if it is not setup already in booking setup
    // apply the same opening and closing hours intially in booking setup
    // Case 3: If If opening and closing hours are alreday setup in sampling station and booking setup then check the hours in booking setup should be
    // in range of what has been setup from sampling station.
    this.WeeklyCalender.forEach((wc: Dow) => {
      const mcDow = this.openingAndClosingData.find((i) => i.day == wc.dow);
      if (mcDow) {
        wc.start_time = mcDow.start_time;
        wc.end_time = mcDow.end_time;
        wc.break_begins = mcDow.break_begins;
        wc.break_ends = mcDow.break_ends;
        wc.active = false;
        wc.isDisabled = false;
      }
    });
  }

  weekdaySelect(event: any) {
    console.log(event);
  }

  selectTests($event: any) {
    this.modifiedTest = true;
    const testIds: any[] = [];
    this.selectedtestList = new Array<MultiSelect>();
    const tempTestArray = $event;
    tempTestArray.forEach((x: any) => {
      testIds.push({ id: x.id, active: true });
      this.selectedtestList.push({
        id: x.id,
        displayText: x.displayText,
        active: true,
      });
    });
    this.calendarInfo.tests = testIds;
  }

  submitMe(form: NgForm) {
    this.validateAllTime();

    if (form.valid && this.selectedtestList.length > 0) {
      const invalidFormats = this.WeeklyCalender.filter(
        (i) =>
          i.active &&
          (i.is_break_end_valid == false ||
            i.is_break_start_valid == false ||
            i.is_close_valid == false ||
            i.is_open_valid == false)
      );
      if (invalidFormats.length > 0) {
        return;
      }

      if (this.WeeklyCalender.filter((i) => i.active == true).length > 0) {
        this.calendarInfo.dows = new Array<Dow>();
        this.WeeklyCalender.forEach((wc: any) => {
          if (!wc.isDisabled) {
            this.calendarInfo.dows.push(wc);
          }
        });
        this.calendarInfo.sampling_station_id = this.samplingStationId;
        if (this.calendarInfo.id > 0) {
          if (this.calendarInfo.tests.length > 0) {
            this.data
              .updateCalendarPutRequest(
                this.samplingStationId,
                this.calendarInfo
              )
              .subscribe(
                (data: any) => {
                  data;
                  sessionStorage.setItem(
                    "uci",
                    this.calendarInfo.id.toString()
                  );
                  this.messageService.success(
                    this.translate.instant(this.savealert["add"])
                  );
                  this.updateCalendarData.emit(true);
                },
                (err: { error: { message: string }; message: any }) => {
                  this.messageService.alert(err.error.message);
                  return err.message;
                }
              );
          } else {
            this.messageService.alert(
              this.translate.instant(this.savealert["test_required"])
            );
          }
        } else {
          // add
          if (this.calendarInfo.tests && this.calendarInfo.tests.length > 0) {
            this.data
              .addCalendarPostRequest(this.samplingStationId, this.calendarInfo)
              .subscribe(
                (data: any) => {
                  data;
                  sessionStorage.setItem("uci", "0");

                  this.messageService.success(
                    this.translate.instant(this.savealert["add"])
                  );
                  this.updateCalendarData.emit(true);
                },
                (err: { error: { message: string }; message: any }) => {
                  this.messageService.alert(err.error.message);
                }
              );
          } else {
            this.messageService.alert(
              this.translate.instant(this.savealert["test_required"])
            );
          }
        }
      } else {
        this.messageService.alert(
          this.translate.instant(this.savealert["dow_active"])
        );
      }
    }
  }

  deleteCalendar() {
    this.messageService
      .confirm(
        this.translate.instant("add-calendar.proceed"),
        this.translate.instant("add-calendar.alert")
      )
      .subscribe((actionResult: boolean) => {
        if (actionResult) {
          this.data
            .deleteCalendar(this.calendarInfo.id, this.samplingStationId)
            .subscribe(
              (data: any) => {
                data;
                sessionStorage.setItem("uci", "0");

                this.updateCalendarData.emit(true);
                this.isEditMode = false;
                this.messageService.success(
                  this.translate.instant(this.savealert["delete"])
                );
              },
              (err: {
                error: { code: any; message: string };
                message: any;
                code: any;
              }) => {
                if (err.error.code == 422) {
                  this.messageService.alert(
                    this.translate.instant("admin.appointment-err-msg")
                  );
                } else {
                  this.messageService.alert(err.error.message);
                }
              }
            );
        }
      });
  }

  reset() {
    this.calendarInfo.calendar_name = "";
    this.calendarInfo.capacity = 0;
    this.calendarInfo.duration = 0;

    this.selectedtestList = new Array<MultiSelect>();
    this.WeeklyCalender = this.DefaultWeeklyCalender;
  }

  onCalendarSelect(evt: any) {
    this.modifiedTest = false;
    const selectedCal = this.calendar.find((i) => i.id == evt.value);
    Object.assign(this.calendarInfo, selectedCal);
    this.buildDows();
    this.loadTest();
  }

  cancelProcess() {
    if (this.calendarInfo && this.calendarInfo.id) {
      sessionStorage.setItem("uci", this.calendarInfo.id.toString());
      // this.messageService.success(this.translate.instant(this.savealert['add']));
      this.updateCalendarData.emit(true);
    } else {
      this.updateCalendarData.emit(true);
    }
  }

  shouldDisableCheckboxClick(event: any, index: number) {
    if (this.fieldDisabled) {
      event.preventDefault();
      return;
    } else {
      this.daySelect(event, index);
    }
  }

  validateAllTime() {
    this.WeeklyCalender.forEach((element: any, index: number) => {
      if (element.active) {
        this.compareMinAndMaxInputForLunchHours(
          element.start_time,
          element.end_time,
          element.break_begins + ":00",
          element.break_ends + ":00",
          index,
          false
        );
      } else {
        element.is_break_end_valid = true;
        element.is_break_start_valid = true;
        element.is_close_valid = true;
        element.is_open_valid = true;
      }
    });
  }

  validateTime(index: number) {
    if (this.fieldDisabled) {
      return;
    }
    const wd = this.WeeklyCalender[index];
    if (wd.active) {
      this.compareMinAndMaxInputForLunchHours(
        wd.start_time,
        wd.end_time,
        wd.break_begins + ":00",
        wd.break_ends + ":00",
        index
      );
    } else {
      wd.is_break_end_valid = true;
      wd.is_break_start_valid = true;
      wd.is_close_valid = true;
      wd.is_open_valid = true;
      const oacds = this.openingAndClosingData.filter((i) => i.day == wd.dow);
      if (oacds.length > 0) {
        wd.break_begins =
          oacds[0].break_begins != "" ? oacds[0].break_begins : "00:00";
        wd.break_ends =
          oacds[0].break_ends != "" ? oacds[0].break_ends : "00:00";
        wd.start_time = oacds[0].start_time;
        wd.end_time = oacds[0].end_time;
      }
    }
  }

  private compareMinAndMaxInputForLunchHours(
    ssOpeningHours: string,
    ssClosingHours: string,
    lunchStartHours: string,
    lunchEndHours: string,
    index: number,
    showErrorBox: boolean = true
  ) {
    const day = this.WeeklyCalender[index].dow;
    const oacds = this.openingAndClosingData.filter((i) => i.day == day);
    let oacd;
    if (oacds.length > 0) {
      oacd = oacds[0];
    }
    const dt = new Date();
    const ssOpenTime = this.buildTimeFromHours(ssOpeningHours, dt);
    const ssClosingTime = this.buildTimeFromHours(ssClosingHours, dt);
    const lunchStartTime = this.buildTimeFromHours(lunchStartHours, dt);
    const lunchEndTime = this.buildTimeFromHours(lunchEndHours, dt);

    const standardOpenTime = this.buildTimeFromHours(oacd.start_time, dt);
    const standardLunchStartTime = this.buildTimeFromHours(
      oacd.break_begins,
      dt
    );
    const standardCloseTime = this.buildTimeFromHours(oacd.end_time, dt);
    const standardLunchEndTime = this.buildTimeFromHours(oacd.break_ends, dt);

    const lunchStartSplitTime: string[] = lunchStartHours.split(":");
    const lunchEndSplitTime: string[] = lunchEndHours.split(":");
    if (ssOpenTime.getTime() == ssClosingTime.getTime()) {
      // start time is greater than end time, invalid case
      if (showErrorBox) {
        this.messageService.alert(
          this.translate.instant("add-calendar.can-not-same")
        );
      }
      this.WeeklyCalender[index].is_close_valid = false;
    } else if (ssOpenTime.getTime() > ssClosingTime.getTime()) {
      // start time is greater than end time, invalid case
      if (showErrorBox) {
        this.messageService.alert(
          this.translate.instant("add-calendar.invalid-start-end-time")
        );
      }
      this.WeeklyCalender[index].is_close_valid = false;
    } else if (
      ssOpenTime.getTime() < standardOpenTime.getTime() ||
      ssClosingTime.getTime() > standardCloseTime.getTime()
    ) {
      // opening and closing time is not in range of standard open and close time, invalid case
      if (showErrorBox) {
        this.messageService.alert(
          this.translate.instant("add-calendar.select-between") +
          " " +
          oacd.start_time +
          " " +
          this.translate.instant("add-calendar.and") +
          " " +
          oacd.end_time
        );
      }
      this.WeeklyCalender[index].is_close_valid = false;
    }
    else if (standardLunchStartTime.getTime() == standardLunchEndTime.getTime()) {
      this.setHoursForLunchBreak(lunchStartSplitTime, lunchEndSplitTime, lunchStartTime, lunchEndTime, ssOpenTime, ssClosingTime, index, showErrorBox, ssOpeningHours,
        ssClosingHours)

    } else if (
      (lunchStartTime.getTime() < ssOpenTime.getTime() &&
        lunchStartTime.getTime() > ssClosingTime.getTime() &&
        lunchEndTime.getTime() < ssOpenTime.getTime() &&
        lunchEndTime.getTime() > ssClosingTime.getTime()) ||
      lunchStartTime.getTime() > lunchEndTime.getTime() ||
      lunchStartTime.getTime() > standardLunchStartTime.getTime() ||
      (lunchEndTime.getTime() < standardLunchEndTime.getTime() &&
        (lunchStartTime.getTime() != standardLunchStartTime.getTime() ||
          lunchEndTime.getTime() != standardLunchEndTime.getTime()))
    ) {
      // lunch start and lunch end  time is not in range of standard open and close time, invalid case
      if (showErrorBox) {
        this.messageService.alert(
          this.translate.instant("add-calendar.can-not-between") +
          " " +
          oacd.break_begins +
          " " +
          this.translate.instant("add-calendar.and") +
          " " +
          oacd.break_ends
        );
      }

      this.WeeklyCalender[index].is_close_valid = true;
      this.WeeklyCalender[index].is_break_end_valid = false;
      this.WeeklyCalender[index].is_break_start_valid = false;
    } else {
      this.setHoursForLunchBreak(lunchStartSplitTime, lunchEndSplitTime, lunchStartTime, lunchEndTime, ssOpenTime, ssClosingTime, index, showErrorBox, ssOpeningHours,
        ssClosingHours)
    }
  }

  private setHoursForLunchBreak(lunchStartSplitTime: string[],
    lunchEndSplitTime: string[],
    lunchStartTime: Date,
    lunchEndTime: Date,
    ssOpenTime: Date,
    ssClosingTime: Date,
    index: int,
    showErrorBox: boolean,
    ssOpeningHours: String,
    ssClosingHours: String
  ) {
    // this.WeeklyCalender[index].isDisabled = false;
    this.WeeklyCalender[index].is_close_valid = true;
    if (
      parseInt(lunchStartSplitTime[0]) != 0 ||
      parseInt(lunchStartSplitTime[1]) != 0 ||
      parseInt(lunchEndSplitTime[0]) != 0 ||
      parseInt(lunchEndSplitTime[1]) != 0
    ) {
      // not default value
      if (
        lunchStartTime.getTime() >= ssOpenTime.getTime() &&
        lunchStartTime.getTime() <= ssClosingTime.getTime()
      ) {
        this.WeeklyCalender[index].is_break_start_valid = true;
        if (
          lunchEndTime.getTime() > lunchStartTime.getTime() &&
          lunchEndTime.getTime() <= ssClosingTime.getTime()
        ) {
          // lunch end time valid
          this.WeeklyCalender[index].is_break_end_valid = true;
        } else {
          this.WeeklyCalender[index].is_break_end_valid = false;
        }
      } else {
        this.WeeklyCalender[index].is_break_start_valid = false;
        this.WeeklyCalender[index].is_break_end_valid = false;
        if (showErrorBox) {
          this.messageService.alert(
            this.translate.instant("add-calendar.select-between") +
            " " +
            ssOpeningHours +
            " " +
            this.translate.instant("add-calendar.and") +
            " " +
            ssClosingHours
          );
        }
      }
    } else {
      this.WeeklyCalender[index].is_break_end_valid = true;
      this.WeeklyCalender[index].is_break_start_valid = true;
    }
  }

  private buildTimeFromHours(timeString: string, dt: Date) {
    const newDate = new Date(dt);
    const splittedTimeString: string[] = timeString.split(":");
    newDate.setHours(
      Number(splittedTimeString[0]),
      Number(splittedTimeString[1]),
      Number("00")
    );
    return newDate;
  }

  private daySelect(event: any, index: number) {
    if (!event.checked) {
      this.WeeklyCalender[index].break_begins = "00:00";
      this.WeeklyCalender[index].break_ends = "00:00";
      this.WeeklyCalender[index].start_time = "00:00";
      this.WeeklyCalender[index].end_time = "00:00";
      this.validateTime(index);
    }
  }
}
