<div class="create-establishment-container">
    <div class="header flex-box">
        <div class="pull-left">
            <div class="heading d-flex">
                <span class="title">
                    {{ sliderFormData[0].edit ? ('department-list.edit' | translate) :
                    ('department-list.add' | translate) }}
                </span>
                <div class="divider" *ngIf="sliderFormData[0].edit"></div>
                <span class="userid" *ngIf="sliderFormData[0].edit">{{ sliderFormData[0].DeptData.external_code }}</span>
            </div>
        </div>
        <div class="pull-right">
            <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
                <span class="icon-ic-close"></span>
            </button>
        </div>
    </div>

    <form [formGroup]="DeptForm" #formDirective="ngForm" class="input-min-spacing">
        <div class="createAccountwrapper">
            <mat-form-field>
                <mat-label>{{'department-list.deptname'|translate}}</mat-label>
                <input matInput name="DeptName" id="DeptName" [formControl]="DeptName"
                    formControlName="DeptName" />
                <mat-error
                    *ngIf="DeptForm.get('DeptName').hasError('pattern')">{{'add-establish.invalid-name'|translate}}
                </mat-error>
                <mat-error
                    *ngIf="DeptForm.get('DeptName').hasError('maxlength')">{{'add-establish.invalid-name'|translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field def="Status" *ngIf="sliderFormData[0].edit">
                <mat-label>{{'department-list.status'|translate}}</mat-label>
                <mat-select placeholder="All" formControlName="status" required="true" name="status">
                    <mat-option [value]="true">{{'establishment-list.active'|translate}}</mat-option>
                    <mat-option [value]="false">{{'establishment-list.suspended'|translate}}</mat-option>
                </mat-select>
            </mat-form-field>


        </div>
        <div class="createAccountwrapper">

            <mat-form-field>
                <mat-label>{{'add-establish.code'|translate}}</mat-label>
                <input matInput name="CorrCode" id="CorrCode" [formControl]="CorrCode" formControlName="CorrCode" />
                <!-- <mat-error
                    *ngIf="DeptForm.get('CorrCode').hasError('maxlength')">{{'add-establish.invalid-name'|translate}}
                </mat-error> -->
            </mat-form-field>

            <mat-form-field def="Company">
                <mat-label class="label-float" appearance="none">{{'add-establish.company'|translate}}</mat-label>
                <mat-select formControlName="Company" [disableOptionCentering]="true">
                    <mat-option *ngFor="let company of companies" [value]="company.id">{{company?.org_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="createAccountwrapper">
            <mat-form-field def="Establishment">
                <mat-label class="label-float" appearance="none">{{'department-list.establishment'|translate}}</mat-label>
                <mat-select formControlName="Establishment" [disableOptionCentering]="true">
                    <mat-option *ngFor="let establish of establishments" [value]="establish.id">{{establish.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="actions">
            <div class="inner-btn">
                <button *ngIf="!sliderFormData[0].edit"
                mat-button color="accent" class="ghost button_width mr-20" type="button" (click)="reset()"
                    id="btn_clear">
                    {{'add-establish.clear'|translate}}
                </button>
                <button *ngIf="sliderFormData[0].edit"
                mat-button color="accent" class="ghost button_width mr-20" type="button" (click)="dismiss()"
                    id="btn_clear">
                    {{'department-list.cancel'|translate}}
                </button>
                <button mat-raised-button color="primary" class="button_width" type="submit" id="btn_save"
                    (click)="onSave()">
                    {{'add-establish.save'|translate}}
                </button>                
            </div>
        </div>
    </form>
</div>