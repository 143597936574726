import { CommonModule } from '@angular/common';
import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from './cache/language.service';
import { AppSettingsService } from './config/app-settings.service';
import { ConfigService } from './config/config.service';
import { ErrorHandlerService } from './error-handler/error-handler.service';
import { HttpInterceptorService } from './http-interceptor/http-interceptor.service';
import { HttpService } from './http-interceptor/http.service';
import { LogPublishersService } from './logger/log-publishers.service';
import { LoggerService } from './logger/logger.service';
import { SpinnerService } from './spinner/spinner.service';

export function load_config(configService: ConfigService) {
    return () => configService.getSettings();
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        SpinnerService,
        ConfigService,
        AppSettingsService,
        LoggerService,
        LogPublishersService,
        ErrorHandlerService,
        LanguageService,
        HttpService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: load_config,
            deps: [ConfigService],
            multi: true
        }
    ],
    declarations: []
})
/** Core Module */
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only'
            );
        }
    }
}
