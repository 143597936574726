<div class="document-container">
  <header class="pull-left">
    <h3 class="pull-left">{{'documents.documents'|translate}}</h3>
    <!-- <button mat-button color="accent" class="only-web ghost" (click)="uploadDocument($event)"
      type="button">{{'documents.upload-document'|translate}}</button>
    <button class="only-mobile mat-tbl-icon" mat-icon-button (click)="uploadDocument($event)">
      <span class="icon-ic-upload"></span>
    </button> -->
  </header>
  <mat-tab-group disableRipple [@.disabled]="true" class="tab-app pull-left" animationDuration="1000ms"
    [selectedIndex]="tabSelection">
    <mat-tab label="{{'documents.my-documents'|translate}}">
      <app-my-documents (viewDocPdf)="openImage($event)">
      </app-my-documents>
    </mat-tab>
    <mat-tab label="{{'documents.relative-members-documents'|translate}}">
      <app-relative-member-documents (viewDocPdf)="openImage($event)">
      </app-relative-member-documents>
    </mat-tab>
  </mat-tab-group>
</div>

<app-pdf-viewer *ngIf="isPdfViewerOpened" [blobSrc]="pdfBlob" pdftitle="{{'documents.document'|translate}}"
  (closed)="isPdfViewerOpened = false" [allPages]="true"></app-pdf-viewer>