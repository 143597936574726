<div class="check-prescription-container" [ngClass]="loader.loggedIn ? 'check-prescription-container-loggedin' : 'check-prescription-container-guest'">

    <div class="section-header" [ngClass]="loader.loggedIn ? 'section-header-loggedin' : 'section-header-guest'">
        <circle-progress class="only-web" [options]="loader.optionsG" [percent]="percent" [renderOnClick]="false"
            imageSrc="assets/images/application-logos/svg/ic-sdocument.svg" class="copy">
        </circle-progress>
        <h3 class="desc-title">{{'check-pres.title' | translate}}​</h3>
    </div>
    <div *ngIf="loader.loggedIn && loader.isLoginReceptionist" class="patient-info">
        {{'stepper-flow.bookingFor' | translate}} : 
        <span class="patientname">{{loader.bookingPatientName | translate}}</span>
    </div>
    <div class="pres-selection-section" [ngClass]="{'pres-selection-section-nurse': loader.isLoginReceptionist, 'pres-selection-section-guest': !loader.loggedIn, 'pres-selection-section-patient': loader.loggedIn && !loader.isLoginReceptionist}">

        <div class="options" [ngClass]="havePrescription.value == '0' ? 'option-yes' : 'option-no'" (click)="select(0)">
            <div class="option-description">
                <img src="assets/images/application-logos/svg/ic-pres-yes.svg" alt="pres-yes">
                <div class="option-title">{{'check-pres.yes' | translate}}</div>
            </div>
            <div class="select-button" [ngClass]="havePrescription.value == '0' ? 'selected' : 'select'">
                {{(havePrescription.value == '0' ? 'check-pres.selected' : 'check-pres.select') | translate}}</div>
        </div>
        <div class="options" [ngClass]="havePrescription.value == '1' ? 'option-yes' : 'option-no'" (click)="select(1)">
            <div class="option-description">
                <img src="assets/images/application-logos/svg/ic-pres-no.svg" alt="pres-no">
                <div class="option-title">{{'check-pres.no' | translate}}</div>
            </div>
            <div class="select-button" [ngClass]="havePrescription.value == '1' == true  ? 'selected' : 'select'">
                {{(havePrescription.value == '1' ? 'check-pres.selected' : 'check-pres.select') | translate}}</div>
        </div>
    </div>
    <div class="action-btn" [ngClass]="{'action-btn-loggedin': loader.loggedIn, 'action-btn-guest': !loader.loggedIn, 'row-reverse': hideBack }">
        <button mat-raised-button class="back-btn" *ngIf="!hideBack" type="button" (click)="goToBack()">
            {{'back' | translate}}
        </button>
        <button mat-raised-button class="next-btn" type="button" (click)="goToNext()" [ngClass]="{'full-width':hideBack}">
            {{'next' | translate}}
        </button>
    </div>
</div>