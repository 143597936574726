import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '../../core';


@Injectable({
    providedIn: 'root'
})
export class ResetPasswordServiceService {

    constructor( private httpClient: HttpClient, private readonly appSettings: AppSettingsService) {}
    postAuthforget(inputValue: any) {
        return (this.httpClient.get(this.appSettings.patientBaseUrl + 'mail/password?email=' + inputValue.email));
    }
    postAdminAuthforget(inputValue: any) {
        return this.httpClient.get(this.appSettings.adminBaseUrl + 'mail/password?email=' + inputValue.email);
    }

    putAuthsavePassword(newPassword: any) {
        return (this.httpClient.put((this.appSettings.authBaseUrl + 'password'), newPassword));
    }
}
