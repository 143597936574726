<table class="ecl-table-result" *ngIf="refresh" width="100%" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef class="wp-10"> {{'results.name' | translate}} </th>
    <td mat-cell *matCellDef="let element">
      <span class="test-type-title-blue" *ngIf="element.FirstName" (click)="onNameClicked(element)">{{element.FirstName
        }}{{' '}}{{
        element.BirthName}}{{' '}}{{
        element.LastName}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="gender">
    <th mat-header-cell *matHeaderCellDef class="wp-10"> {{'results.Gender' | translate}} </th>
    <td mat-cell *matCellDef="let element">
      <span class="title" *ngIf="element.Gender">{{element.Gender | translate}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="dob">
    <th mat-header-cell *matHeaderCellDef class="wp-10"> {{'results.dob' | translate}} </th>
    <td mat-cell *matCellDef="let element"><span class="title">
        <span class="icon-ic-date onlyMobile"></span>{{element.DOB | date :'dd/MM/yyyy'}} </span></td>
  </ng-container>

  <ng-container matColumnDef="test">
    <th mat-header-cell *matHeaderCellDef class="wp-15"> {{'results.test' | translate}} </th>
    <td mat-cell *matCellDef="let element" class="flex-td">

      <span class="span-title" *ngIf="element.TestShortName">{{element.TestShortName}}
        <span class="test-type-title-grey"
          *ngIf="!element.IsTestKitRequired">{{loader.TranslateLang(element.TestType)}}</span>

        <span class="test-type-title-blue tt-align"
          *ngIf="element.IsTestKitRequired ">{{loader.TranslateLang(element.TestType)}}<span
            *ngIf="element.Barcode=='' && !checkPastDate(element)" (click)="changeTest(element)"
            class="icon-ic-edit edit-icon"></span> </span>
        <span class="test-type-title-blue" *ngIf="element.IsTestResultRequired && element.KitImagePath"
          (click)="viewSample(element)"> {{'results.view-sample' | translate}} </span>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="testKitCode">
    <th mat-header-cell *matHeaderCellDef class="wp-15"> {{'results.test-kit-code' | translate}} </th>
    <td mat-cell *matCellDef="let element" class="flex-td">
      <span class="title" *ngIf="element.Barcode">{{element.Barcode}}</span>
      <span class="title" *ngIf="!element.Barcode">{{element.SampleId}}</span>

    </td>
  </ng-container>

  <ng-container matColumnDef="dateTime">
    <th mat-header-cell *matHeaderCellDef class="wp-10"> {{'results.date-time' | translate}} </th>
    <td mat-cell *matCellDef="let element">
      <span class="title">
        <span class="icon-ic-date onlyMobile"></span><span *ngIf="element.Barcode">{{element.SamplingTime | dateFormat
          }}</span>
        <span *ngIf="!element.Barcode">{{element.SlotTime | dateFormat}}</span>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef class="wp-10">{{'results.status' | translate}}</th>
    <td mat-cell *matCellDef="let element">
      <app-ecl-steps [steps]="getStatusArray(element.StatusId)"></app-ecl-steps>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef class="wp-10"></th>
    <td mat-cell *matCellDef="let element" class="force-display">
      <div class="mobile-action">
        <button mat-button color="accent" class="ghost" *ngIf="checkifResultIsAvailable(element)"
          (click)="showResult.emit(element)" type="button">{{'results.view-report'| translate}}</button>

        <div [ngClass]="element?.KitResult?.toLowerCase() =='negative' ? '' : 'text-red'" class="text-green">
          {{element.KitResult}}
        </div>
      </div>
      <div class="action-btn">
        <button class="mat-tbl-icon" *ngIf="checkifResultIsAvailable(element)" (click)="showResult.emit(element)"
          mat-icon-button matTooltip="{{'all-results.view-result'|translate}}" matTooltipClass="tooltip-style">
          <span class="icon-ic-view-prescription"></span>
        </button>

        <div [ngClass]="element?.KitResult?.toLowerCase() =='negative' ? '' : 'text-red'" class="text-green">
          {{element.KitResult}}
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="noRecord">
    <td colspan="6" mat-footer-cell *matFooterCellDef>
      <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
      <div class="desc">
        <p>{{'results.empty' | translate}}</p>
        <p>{{'results.search-patient' | translate}}</p>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <ng-template [ngIf]="dataSource && dataSource.length === 0">
    <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
  </ng-template>
</table>