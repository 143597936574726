import { Observable, of } from 'rxjs';
import { LogEntry } from '../models/log-entry.model';
import { LogPublisher } from '../models/log-publishers.model';

/** Class for Logging in Local Storage */
export class LogLocalStorage extends LogPublisher {

    constructor() {
        super();
        this.Location = 'logging';
    }

    /* #region Public Methods */
    /** Method to get log entries from web api */
    public getAll(): Observable<LogEntry[]> {
        return of(JSON.parse(localStorage.getItem(this.Location)) || []);
    }

    /** Method to log an entry in local storage */
    public log(record: LogEntry): Observable<boolean> {
        let ret = false;
        let values: LogEntry[];
        try {
            values = JSON.parse(localStorage.getItem(this.Location)) || [];
            // Add new log entry to the array
            values.push(record);
            // Store the complete array into local storage
            localStorage.setItem(this.Location, JSON.stringify(values));
            ret = true;
        } catch (ex) {
            console.log(ex);
        }
        return of(ret);
    }

    /** Method to clear a log from local storage */
    public clear(): Observable<boolean> {
        localStorage.removeItem(this.Location);
        return of(true);
    }
    /* #endregion */
}
