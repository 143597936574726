import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phonenoFormate',
  // standalone: true
})
export class PhonenoFormatePipe implements PipeTransform {

  transform(phone: string): string {
    if (!phone) return phone; {
      phone = phone.replace(/\D/g, '');
    }
    return phone.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5 ');
  }

}
