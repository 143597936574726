<div class="directions-container">
    <div class="header">
      <div class="pull-left">
        <app-section-title title="{{'get-directions.title'|translate}}"></app-section-title>
      </div>
      <div class="pull-right">
        <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
          <span class="icon-ic-close"></span>
        </button>
      </div>
    </div>
    <div class="content">
        <div class="patient-info">
          <div class="initial">
            <span class="icon-ic-location"></span>
          </div>
          <div class="p-details">
            <div class="cover">
                <p>{{SamplingSationname |translate}}</p>
                <p><span class="type">{{Addresss|translate}}</span></p>
                <p><span class="type">{{'get-directions.distance'|translate}} : {{totalDistance}}</span></p>
                <p><span class="type">{{'get-directions.duration'|translate}} : {{totalTime}}</span></p>
            </div>
            <div class="icons">
                <span class="icon-ic-print"></span>
                <span class="icon-ic-share"></span>
                <span class="icon-ic-mail"></span>
            </div>
          </div>
        </div>
        <div id ="map"class="map-info">
        </div>
    </div>
</div>
