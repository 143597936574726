<div class="Container">
    <div class="d-flex justify-content-between search-mr">
        <div class="d-flex Search">
            <div id="inputsearch" class="">
                <mat-form-field>
                    <mat-label>{{'user.id-name' | translate}}</mat-label>
                    <input [(ngModel)]="searchinput" matInput (keydown.enter)="onSearch()"
                        (keydown.space)="keyDownHandler($event)" />
                </mat-form-field>
            </div>
            <div id="searchbtn" class="">
                <button (click)="onSearch()" id="btn_search" style="margin-left: 15%" mat-raised-button color="primary">
                    {{'user.search' | translate}}
                </button>
            </div>
            <div id="resetbtn" class="">
                <button (click)="reset()" id="btn_reset" style="text-align: center; margin-left: 25%;" mat-button
                    color="accent" class="ghost">
                    {{'user.reset' | translate}}
                </button>
            </div>
        </div>
        <div class="d-flex Button">
            <div id="statusfilter" class="mr-4">
                <mat-form-field floatLabel="never" class="dropdown-filter">
                    <mat-select placeholder="{{'admin.status' | translate}}" disableOptionCentering
                        [(ngModel)]="status">
                        <mat-option *ngFor="let stepOption of sampleData | keyvalue: unsorted" (click)="loadPatients()"
                            [value]="stepOption.key">
                            {{ stepOption.value | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div id="adduserbtn" (click)="addPatient()" class="mx-4">
                <button mat-raised-button color="primary" id="btn_add">{{'user.add-patient' | translate}}</button>
            </div>
            <!-- <div id="exportuserbtn" style="text-align: right" class="ml-4">
                <button [matMenuTriggerFor]="menu" mat-raised-button color="primary">
                    {{'user.export' | translate}}
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="exporter.exportTable('csv', { fileName: 'Users' })">
                        .csv
                    </button>
                    <button mat-menu-item (click)="exporter.exportTable('xlsx', { fileName: 'Users' })">
                        .xlsx
                    </button>
                </mat-menu>
            </div> -->
        </div>
    </div>
    <div class="main-container main-container-res">
        <mat-table [dataSource]="dataSource" class="table-height input-min-spacing table-flex" id="EntityListingTable">
            <ng-container matColumnDef="Icon" class="mat-column-id">
                <mat-header-cell *matHeaderCellDef class="mat-column-id">
                </mat-header-cell>
                <mat-cell class="more-option mat-column-id" *matCellDef="let entity; let i = index">
                    <mat-icon class="expand" (click)="expandRow(i)">
                        {{ selectedIndex !== i ? 'expand_more' : 'expand_less'}}
                    </mat-icon>
                </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="ID">
                <mat-header-cell *matHeaderCellDef id="id">
                    <span>{{'user.id' | translate}}</span>
                </mat-header-cell>
                <mat-cell class="more-option" *matCellDef="let entity; let i = index">
                    {{ entity.id }}
                </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="first_name">
                <mat-header-cell *matHeaderCellDef id="first_name">
                    <span>{{'createAccount.first-name' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity.first_name}}">
                    {{ entity.first_name }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="last_name">
                <mat-header-cell *matHeaderCellDef id="last_name">
                    <span>{{'createAccount.last-name' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity.last_name}}">
                    {{ entity.last_name }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="birth_name">
                <mat-header-cell *matHeaderCellDef id="birth_name">
                    <span>{{'createAccount.birth-name' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity.birth_name}}">
                    {{ entity.birth_name }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="dob">
                <mat-header-cell *matHeaderCellDef id="dob">
                    <span>{{'createAccount.dob' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity.dob  | date: 'dd/MM/yyyy'}}">
                    {{ entity.dob | date: 'dd/MM/yyyy'}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="gender">
                <mat-header-cell *matHeaderCellDef id="gender">
                    <span>{{'createAccount.gender' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{entity.gender | translate}}">
                    {{entity.gender | translate}}

                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="nationality_name">
                <mat-header-cell *matHeaderCellDef id="nationality_name">
                    <span>{{'createAccount.nationality' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity.nationality_name}}">
                    {{ entity.nationality_name }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ssn">
                <mat-header-cell *matHeaderCellDef id="ssn">
                    <span>{{'createAccount.ssn' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity.ssn}}">
                    {{ entity.ssn }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef id="email">
                    <span>{{'createAccount.email' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity.email}}">
                    {{ entity.email }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="phone_number">
                <mat-header-cell *matHeaderCellDef id="phone_number">
                    <span>{{'createAccount.phone' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity.phone_number}}">
                    {{ entity.phone_number }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="address">
                <mat-header-cell *matHeaderCellDef id="address">
                    <span>{{'createAccount.address' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity.address}}">
                    {{ entity.address }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Status">
                <mat-header-cell *matHeaderCellDef>
                    <span>{{'admin.status' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity"
                    matTooltip="{{entity.is_active?('admin.active'|translate):('admin.suspended'|translate)}}">
                    {{entity.is_active?('admin.active'|translate):('admin.suspended'|translate)}}
                </mat-cell>
            </ng-container>



            <ng-container matColumnDef="icon-column">
                <mat-header-cell *matHeaderCellDef>{{'user.options' | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let entity" style="justify-content: flex-end;padding-bottom: 0 !important;
                        padding-top: 12px !important;">
                    <button rippleOnHover class="mat-tbl-icon editEntity_btn mr-8" *ngIf="entity.ID == hoverindex"
                        mat-icon-button (click)="editPatient(entity)">
                        <span class="icon-ic-edit" matTooltip="{{'user.edit' | translate}}"
                            matTooltipClass="tooltip-style"></span>
                    </button>
                    <button rippleOnHover class="mat-tbl-icon deleteEntity_btn mr-8" *ngIf="entity.ID == hoverindex"
                        mat-icon-button (click)="deleteUser(entity?.id)">
                        <span class="icon-ic-delete" matTooltip="{{'user.delete' | translate}}"
                            matTooltipClass="tooltip-style"></span>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnList; sticky: true"></mat-header-row>
            <mat-row [class.isExpand]="selectedIndex == i" *matRowDef="let row; columns: columnList; let i = index"
                class="element-grid-row" (mouseover)="rowMouseOver(row)">
            </mat-row>
        </mat-table>

        <!-- <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons="true">
        </mat-paginator> -->

        <mat-paginator [length]="totalRecords" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20, 50]"
            showFirstLastButtons="true" (page)="onPageChange($event)">
        </mat-paginator>
    </div>
</div>