import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalendarComponent } from '../shared/calendar/calendar.component';
import { FaqComponent } from '../shared/faq/faq.component';
import { AddSamplingComponent } from './add-sampling/add-sampling.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AdminComponent } from './admin.component';
import { BookingSetupComponent } from './booking/booking-setup/booking-setup.component';
import { BookingCalendarComponent } from './booking-calendar/booking-calendar.component';
import { CompanyComponent } from './company/company.component';
import { PatientsComponent } from './patients/patients.component';
import { SamplingStationComponent } from './sampling-station/sampling-station.component';
import { TestComponent } from './test/test.component';
import { TestHistoryComponent } from './test-history/test-history.component';
import { TestTypeComponent } from './test-type/test-type.component';
import { UserComponent } from './user/user.component';
import { LandingPageConfigComponent } from './landing-page-config/landing-page-config.component';
import { PowerBiReportComponent } from './power-bi-report/power-bi-report.component';
import { ManageHolidayComponent } from './manage-holiday/manage-holiday.component';
import { SsSlotConfigurationComponent } from './ss-slot-configuration/ss-slot-configuration.component';
import { HelpManualComponent } from '../shared/help-manual/help-manual.component';


const routes: Routes = [
    {
        path: '',
        component: AdminComponent,
        children: [
            { path: 'company', component: CompanyComponent },
            { path: 'user', component: UserComponent },
            { path: 'testType', component: TestTypeComponent },
            { path: 'test', component: TestComponent },
            { path: 'bookingcalendar', component: BookingCalendarComponent },
            { path: 'adduser', component: AddUserComponent },
            { path: 'bookingsetup', component: BookingSetupComponent },
            { path: 'calendar', component: CalendarComponent },
            { path: 'addsampling', component: AddSamplingComponent },
            { path: 'samplingstation', component: SamplingStationComponent },
            { path: 'patients', component: PatientsComponent },
            { path: 'landing-page-config/:tabIndex', component: LandingPageConfigComponent },
            { path: 'test/history', component: TestHistoryComponent },
            { path: 'faq', component: FaqComponent },
            { path: 'report', component: PowerBiReportComponent },
            { path: 'manage-holiday', component: ManageHolidayComponent },
            { path: 'slot-management', component: SsSlotConfigurationComponent },
            { path: 'help-manual', component: HelpManualComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }
