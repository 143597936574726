<div>
  <div class="Container">
    <div class="d-flex mb-20 ml-40 mt-20">
      <label class="customwidth">{{'booking-setup.company' | translate}}<span style="color:red;">*</span> : </label>
      <mat-form-field floatLabel="never" class="dropdown-filter">
        <mat-label>{{'booking-setup.company-name' | translate}}</mat-label>
        <mat-select name="company" disableOptionCentering (selectionChange)="onOrgSelect($event)">
          <mat-option *ngFor="let stepOption of Companieslist" [value]="stepOption.id">
            {{ stepOption.org_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div></div>
    <div class="d-flex ml-40 mt-20 ">
      <label class="customwidth">{{'booking-setup.sampling-station' | translate}}<span style="color:red;">*</span> :
      </label>

      <mat-form-field def="samplingStaton" class="dropdown-filter mb-20">
        <mat-label>{{'booking-setup.sampling-station' | translate}}</mat-label>
        <input type="text" #searchData matInput (click)="samplingStaton=''" [formControl]="myControl"
          [(ngModel)]="samplingStaton" [matAutocomplete]="auto">
        <i class="material-icons align-arrow-right">arrow_drop_down</i>

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="samplingStationChange($event)"
          [displayWith]="displayFn.bind(this)">
          <mat-option *ngFor="let ss of filteredOptions | async" [value]="ss">
            {{ss.sampling_station_name}}
          </mat-option>
        </mat-autocomplete>

      </mat-form-field>
      <div class="mx-4 ml-60 mb-20" style="margin-right: 20%">
        <button mat-raised-button color="primary" [disabled]="isDisable" id="btn_calendar" class="calendar-btn"
          (click)="calendarEnable()">
          {{'booking-setup.add-calendar' | translate}}
        </button>
      </div>

    </div>
    <p *ngIf="display" text-align="center" class="ml-40 mt-20">{{'booking-setup.no-calendar' | translate}}</p>
  </div>
  <div *ngIf="isSpecialDates == true">
    <app-special-dates [samplingStationId]="selectedSSId" [orgId]="orgId"></app-special-dates>
  </div>
  <div *ngIf="specialDates == true">
    <app-special-dates [orgId]="orgId"></app-special-dates>
  </div>
  <div *ngIf="isCalendar == true">
    <app-add-calendar (updateCalendarData)="getCalendar(selectedSSId);this.isCalendar = false;"
      [samplingStationId]="selectedSSId" [testList]="testList" [isCalendarAvailable]="isCalendarAvailable"
      [calendar]="calendar" [openingAndClosingData]="openingAndClosingData"></app-add-calendar>
  </div>
</div>