import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../../app.locale.service';
import { MessageService } from '../../../../main';
import { CommonApiService } from '../../../services/common-api.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfiguredData, MasterData } from '../ss-slot-configuration.model';
import { AddUnavailabilityComponent } from '../add-unavailability/add-unavailability.component';
import { AddSlotConfigurationComponent } from '../add-slot-configuration/add-slot-configuration.component';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { LoginServiceService } from '../../../services/login-service.service';
import { SpinnerService } from '../../../../core';

@Component({
  selector: 'app-preview-configuration',
  templateUrl: './preview-configuration.component.html',
  styleUrls: ['./preview-configuration.component.scss']
})

export class PreviewConfigurationComponent implements OnInit {
  public previewData: ConfiguredData;
  public editSlotGroupMode: boolean = true;
  public currTime = new Date();
  public savealert = {
    edit: "admin.save-alert.edit",
    add: "admin.save-alert.add",
  };
  public is_sequence = new FormControl("0");
  public occurences: Array<MasterData>;
  previewDate: Date;
  deleteMessage: string;
  constructor(private readonly dialogRef: MatDialogRef<PreviewConfigurationComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private dialog: MatDialog,
    private localeService: LocaleService,
    private messageService: MessageService,
    public commonAPi: CommonApiService,
    private loginService: LoginServiceService,
    public spinner: SpinnerService
  ) {
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
    this.occurences = new Array<MasterData>();
  }

  ngOnInit(): void {
    this.getOccurence();
    this.previewData = this.data?.ConfiguredData;
    let currDateTime = new Date();
    let currdate = new Date(currDateTime.getFullYear(), currDateTime.getMonth(), currDateTime.getDate())
    let sg_start_date = new Date(this.data.ConfiguredData.start_date.getFullYear(), this.data.ConfiguredData.start_date.getMonth(), this.data.ConfiguredData.start_date.getDate())
    if (new Date(sg_start_date).getTime() >= currdate.getTime()) {
      let hh = this.data?.segmentTime?.event?.start.getHours();
      let mm = this.data?.segmentTime?.event?.start.getMinutes();
      sg_start_date.setHours(hh, mm, 0);
      this.previewDate = new Date(sg_start_date);
      //this.StartDate.setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    }
    else {
      this.previewDate = this.data?.segmentTime?.event?.start;
    }


  }

  public getOccurence() {
    this.spinner.show(true);
    this.loginService.getOccurenceMasters().subscribe((data: Array<any>) => {
      this.occurences = data.sort((a, b) => a.KeyOrder - b.KeyOrder);
      this.spinner.show(false);
    });
  }

  public confirmDeleteConfiguration() {
    if (this.previewData.is_unavilability == true) {
      this.deleteUnavailability();
    }
    else {
      let dnrOccurence = this.occurences.find((i: any) => i.Key.toLowerCase() == "doesnotrepeat");
      if (this.previewData?.occurance == dnrOccurence.Id) {
        this.deleteSlotGroup();
      } else {
        this.editSlotGroupMode = false;
        const datePipe = new DatePipe('en-US');
        let sDate = datePipe.transform(this.previewData?.start_time, 'dd/MM/yyyy');
        this.deleteMessage = this.translate.instant("add-unavailability.delete-desc1-part1") + sDate + this.translate.instant("add-unavailability.delete-desc1-part2");

      }
    }
  }

  changeSequence() {
    const datePipe = new DatePipe('en-US');
    let sDate = datePipe.transform(this.previewData?.start_time, 'dd/MM/yyyy');
    //let fromDate = new Date()
    if (this.is_sequence.value == "0") {
      this.deleteMessage = this.translate.instant("add-unavailability.delete-desc1-part1") + sDate + this.translate.instant("add-unavailability.delete-desc1-part2");
    } else {
      sDate = datePipe.transform(this.previewData?.start_time, 'dd/MM/yyyy');
      let tDate = datePipe.transform(this.data?.samplingStation?.open_until, 'dd/MM/yyyy');
      if (this.previewData?.end_date.getFullYear().toString() == "1") {


        this.deleteMessage = this.translate.instant("add-unavailability.delete-desc2-part1") + sDate + this.translate.instant("add-unavailability.delete-desc2-part2") + tDate + ".";
      }
      else {

        tDate = datePipe.transform(this.previewData?.end_date, 'dd/MM/yyyy');
        if (sDate == tDate) {
          this.deleteMessage = this.translate.instant("add-unavailability.delete-desc1-part1") + sDate + this.translate.instant("add-unavailability.delete-desc1-part2");
        }
        else
          this.deleteMessage = this.translate.instant("add-unavailability.delete-desc2-part1") + sDate + this.translate.instant("add-unavailability.delete-desc2-part2") + tDate + ".";
      }
    }
  }

  public deleteSlotGroup() {
    let popupRef = this.messageService
      .confirm(
        this.deleteMessage,
        this.translate.instant('add-unavailability.delete-title'),
        this.translate.instant('add-unavailability.yes'),
        this.translate.instant('add-unavailability.no')
      );

    popupRef.subscribe((actionResult) => {
      if (actionResult) {
        this.deleteConfiguration(this.previewData.id);
      }
    });
  }

  public deleteUnavailability() {
    let popupRef = this.messageService
      .confirm(
        this.translate.instant('add-unavailability.delete-confirm-description'),
        this.translate.instant('add-unavailability.delete-title'),
        this.translate.instant('add-unavailability.yes'),
        this.translate.instant('add-unavailability.no')
      );

    popupRef.subscribe((actionResult) => {
      if (actionResult) {
        this.deleteConfiguration(this.previewData.id);
      }
    });
  }

  public deleteConfiguration(id: number) {
    let deleted = null;
    if (this.previewData?.is_unavilability == true) {
      deleted = this.commonAPi.deleteUnavailability(id);
    } else {
      const datePipe = new DatePipe('en-US');
      let sDate = datePipe.transform(this.previewData?.start_time, 'yyyy-MM-dd');
      deleted = this.commonAPi.deleteSlotGroup(this.previewData.id, sDate, this.is_sequence.value == "0" ? false : true);
    }
    deleted.subscribe(
      (data: any) => {
        data;
        this.messageService.success(
          this.translate.instant('admin.save-alert.delete')
        );
        this.dialogRef.close();

      },
      (errorResponse: any) => {
        if (errorResponse.error.internal_code === 3007) {
          this.messageService.alert(
            this.translate.instant("add-unavailability.error.3007")
          );
        }
        else if (errorResponse.error.internal_code === 3008) {
          this.messageService.alert(
            this.translate.instant("add-unavailability.error.3008")
          );
        }
        else if (errorResponse.error.internal_code === 3009) {
          this.messageService.alert(
            this.translate.instant("add-unavailability.error.3009")
          );
        }
        else if (errorResponse.error.internal_code === 3010) {
          this.messageService.alert(
            this.translate.instant("add-unavailability.error.3010")
          );
        }
        else {
          this.messageService.alert(
            this.translate.instant("add-unavailability.error.3007")
          );
        }
      }
    );
  }

  editConfiguration() {
    let dialogRef = null;
    if (this.data?.ConfiguredData.is_unavilability == true) {
      dialogRef = this.dialog.open(AddUnavailabilityComponent, {
        width: "800px",
        maxHeight: '100vh',
        disableClose: true,
        autoFocus: false,
        restoreFocus: false,
        data: { action: "edit", edit: true, savealert: this.savealert, ConfiguredData: this.data?.ConfiguredData, samplingStation: this.data.samplingStation, tests: this.data.tests, selectedDateTime: this.data.selectedDateTime, selectedEndDateTime: this.data?.selectedEndDateTime },
      });
    }
    else {
      let segmentTime = this.previewDate;
      let eventEndTime = this.data?.ConfiguredData.end_time;
      let startTime = `${segmentTime.getHours().toString().padStart(2, '0')}:${segmentTime.getMinutes().toString().padStart(2, '0')}:00`;
      let endTime = `${eventEndTime.getHours().toString().padStart(2, '0')}:${eventEndTime.getMinutes().toString().padStart(2, '0')}:00`;
      dialogRef = this.dialog.open(AddSlotConfigurationComponent, {
        width: "800px",
        maxHeight: '100vh',
        disableClose: true,
        autoFocus: false,
        restoreFocus: false,
        data: { action: "edit", edit: true, savealert: this.savealert, ConfiguredData: this.data?.ConfiguredData, samplingStation: this.data.samplingStation, tests: this.data.tests, selectedDateTime: this.data.selectedDateTime, selectedEndDateTime: this.data?.selectedEndDateTime, startDate: this.data?.segmentTime, endTime: endTime, startTime: startTime, ads: this.data.ads },
      });
    }

    dialogRef.afterClosed().subscribe(() => {
      this.dialogRef.close();
    });
  }
}
