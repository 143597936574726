import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { QRCodeModule } from "angularx-qrcode";
import { NgOtpInputModule } from "ng-otp-input";
import {
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
  RECAPTCHA_SETTINGS,
} from "ng-recaptcha";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { NgxMatIntlTelInputModule } from "ngx-mat-intl-tel-input";
import { WebcamModule } from "ngx-webcam";
import { MaterialModule, SharedModule } from "../../shared";
import { MainPipe } from "../../shared/MainPipe.module";
import { CalendarComponent } from "./calendar/calendar.component";
import { ConBookingComponent } from "./con-booking/con-booking.component";
import { EclCalendarComponent } from "./ecl-calendar/ecl-calendar.component";
import { EclStepsComponent } from "./ecl-steps/ecl-steps.component";
import { EditPatientAppointmentComponent } from "./edit-patient-appointment/edit-patient-appointment.component";
import { EditPatientinfoComponent } from "./edit-patientinfo/edit-patientinfo.component";
import { FaqComponent } from "./faq/faq.component";
import { HelpManualComponent } from "./help-manual/help-manual.component";
import { ImageViewerComponent } from "./image-viewer/image-viewer.component";
import { ConfirmationModalComponent } from "./lab-google/confirmation-modal/confirmation-modal.component";
import { LabGoogleComponent } from "./lab-google/lab-google.component";
import { LocationConsentComponent } from "./location-consent/location-consent.component";
import { LoginPrebookComponent } from "./login-prebook/login-prebook.component";
import { PasswordPolicyComponent } from "./password-policy/password-policy.component";
import { PdfViewerComponent } from "./pdf-viewer/pdf-viewer.component";
import { PhoneFlagComponent } from "./phone-flag/phone-flag.component";
import { QrCodeComponent } from "./qr-code/qr-code.component";
import { RegisterComponent } from "./register/register.component";
import { SelectCheckAllComponent } from "./select-check-all/select-check-all.component";
import { CountryService } from "./services/country.service";
import { StepperFlowComponent } from "./stepper-flow/stepper-flow.component";
import { TestSelectionComponent } from "./test-selection/test-selection.component";
import { UploadPicComponent } from "./upload-pic/upload-pic.component";
import { UploadPrescriptionComponent } from "./upload-prescription/upload-prescription.component";
import { EclVideoComponent } from "./ecl-video/ecl-video.component";
import { AppointmentPrescriptionComponent } from './appointment-prescription/appointment-prescription.component';
import { ViewPrescriptionComponent } from './view-prescription/view-prescription.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { DateSlotComponent } from './date-slot/date-slot.component';
import { NgCircleProgressModule } from "ng-circle-progress";
import { PreviewFileComponent } from './preview-file/preview-file.component';
import { FindMyAccountComponent } from './find-my-account/find-my-account.component';
import { VerifyMyAccountComponent } from './verify-my-account/verify-my-account.component';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { DocUploadComponent } from './register/doc-upload/doc-upload.component';
import { EditEmailComponent } from "./edit-email/edit-email.component";
import { AddMemberComponent } from "../patient/relative-members/add-member/add-member.component";
import { FindMemberComponent } from "../patient/relative-members/find-member/find-member.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BookingFlowComponent } from './booking-flow/booking-flow.component';
import { DocumentInfoComponent } from './booking-flow/document-info/document-info.component';
import { CheckRequestnumberComponent } from './booking-flow/check-requestnumber/check-requestnumber.component';
import { CheckPrescriptionComponent } from './booking-flow/check-prescription/check-prescription.component';
import { CheckFileReadyComponent } from './booking-flow/check-file-ready/check-file-ready.component';
import { TokenDetailComponent } from './booking-flow/token-detail/token-detail.component';
import { EnterTokenComponent } from './booking-flow/enter-token/enter-token.component';
import { PrescriptionUploadComponent } from "./booking-flow/prescription-upload/prescription-upload.component";
import { ForgotPasswordComponent } from "./booking-flow/forgot-password/forgot-password.component";
import { TestAndTypeComponent } from "./booking-flow/test-and-type/test-and-type.component";
import { CompleteBookingComponent } from "./complete-booking/complete-booking.component";
import { AutoScrollTopDirective } from "./directive/auto-scroll-top.directive";

@NgModule({
  declarations: [
    StepperFlowComponent,
    FaqComponent,
    TestSelectionComponent,
    LabGoogleComponent,
    EclCalendarComponent,
    UploadPicComponent,
    ConBookingComponent,
    LoginPrebookComponent,
    RegisterComponent,
    PdfViewerComponent,
    QrCodeComponent,
    ImageViewerComponent,
    EclStepsComponent,
    CalendarComponent,
    PhoneFlagComponent,
    EditPatientinfoComponent,
    UploadPrescriptionComponent,
    EditPatientAppointmentComponent,
    PasswordPolicyComponent,
    LocationConsentComponent,
    ConfirmationModalComponent,
    HelpManualComponent,
    SelectCheckAllComponent,
    EclVideoComponent,
    AppointmentPrescriptionComponent,
    ViewPrescriptionComponent,
    NotificationsComponent,
    DateSlotComponent,
    PreviewFileComponent,
    FindMyAccountComponent,
    VerifyMyAccountComponent,
    VerifyOtpComponent,
    DocUploadComponent,
    EditEmailComponent,
    AddMemberComponent,
    FindMemberComponent,
    BookingFlowComponent,
    DocumentInfoComponent,
    CheckRequestnumberComponent,
    CheckPrescriptionComponent,
    CheckFileReadyComponent,
    TokenDetailComponent,
    EnterTokenComponent,
    PrescriptionUploadComponent,
    ForgotPasswordComponent,
    TestAndTypeComponent,
    CompleteBookingComponent,
    AutoScrollTopDirective,
  ],
  exports: [
    StepperFlowComponent,
    FaqComponent,
    TestSelectionComponent,
    LabGoogleComponent,
    EclCalendarComponent,
    UploadPicComponent,
    ConBookingComponent,
    LoginPrebookComponent,
    RegisterComponent,
    PdfViewerComponent,
    PdfViewerModule,
    QRCodeModule,
    QrCodeComponent,
    ImageViewerComponent,
    EclStepsComponent,
    EditPatientinfoComponent,
    UploadPrescriptionComponent,
    CalendarComponent,
    PasswordPolicyComponent,
    SelectCheckAllComponent,
    EclVideoComponent,
    AppointmentPrescriptionComponent, AddMemberComponent,
    FindMemberComponent,
    TokenDetailComponent,
    ForgotPasswordComponent,
    TestAndTypeComponent,
    AutoScrollTopDirective,
  ],
  imports: [
    RouterModule,
    NgOtpInputModule,
    CommonModule,
    FormsModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    QRCodeModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    PdfViewerModule,
    TranslateModule,
    ScrollingModule,
    NgxMatIntlTelInputModule,
    WebcamModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MainPipe,
    NgCircleProgressModule.forRoot({
      radius: 20,
      outerStrokeWidth: 4.5,
      innerStrokeWidth: 4.5,
      outerStrokeColor: "#24D156",
      innerStrokeColor: "#33333314",
      titleColor: "#FFFFFF",
      backgroundPadding: -9,
      backgroundColor: "#576EAF",
      space: -4.5,
      animation: false,
      outerStrokeLinecap: "square",
      maxPercent: 100
    }),
    MatToolbarModule,

  ],
  providers: [
    CountryService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: "6Ldbi_ciAAAAAHO6PjUxLLtQWnAkK0dGnbwig9Xw",
      } as RecaptchaSettings,
    },
  ],
})
export class EClinicalSharedModule { }
