import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../../app.locale.service';
import { LoaderService, MessageService } from '../../../../main';
import { TestService } from '../../../services/add-test-service.service';
import { PatientService } from '../../../services/patient.service';
import { AntigenPCRTest } from '../../register-test-kit/register-test-kit.model';

@Component({
    selector: 'app-change-test',
    templateUrl: './change-test.component.html',
    styleUrls: ['./change-test.component.scss']
})
export class ChangeTestComponent implements OnInit {
    public test_id = new FormControl('', [Validators.required]);
    tests: Array<AntigenPCRTest>;
    appointmentData: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        public testService: TestService,
        public patientService: PatientService,
        private messageService: MessageService,
        private translate: TranslateService,
        private localeService: LocaleService,
        public loader: LoaderService
    ) {
        this.tests = new Array<AntigenPCRTest>();
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    ngOnInit(): void {
        this.appointmentData = this.data.rowData;
        this.getAntigenPCRTests();

    }

    getAntigenPCRTests() {
        this.testService.getAntigenPCRTests(this.loader.ss_id).subscribe(
            (response: any) => {
                console.log(response);
                this.tests = response;
                const selectedTest = this.tests.find(i => i.id == this.appointmentData.TestId);
                this.test_id.setValue(selectedTest.id.toString());
            },
            (error) => {
                // this.reset();
                console.log(error);
            }
        );
    }

    testChange(evt: any) {
        this.test_id.setValue(evt.source.value);
    }

    confirmed() {
        const payload = {
            calendar_id: this.appointmentData.CalendarId,
            slot_time: this.appointmentData.SlotTime,
            capacity: this.appointmentData.Capacity,
            tests: [parseInt(this.test_id.value, 10)],
            sampling_station_id: this.appointmentData.SamplingStationId,
            patient_id: this.appointmentData.PatientID,
            id: this.appointmentData.AppointmentId
        };


        this.patientService.updateAppointment(payload).subscribe(
            (response: any[]) => {
                response;
                this.messageService.success(
                    this.translate.instant('view-booking.booking-saved'),
                    5000
                );
                this.dialog.closeAll();
            },

            (error) => {
                if (error.status === 423) {
                    this.messageService.alert(error.error.message, 5000);
                } else {
                    this.messageService.alert(error['message'], 5000);
                }
            }
        );

    }

    dismiss() {
        this.dialog.closeAll();
    }
}
