<div class="enter-token-container">
    <div class="section-header">
        <circle-progress class="only-web" [options]="loader.optionsG" [percent]="percent" [renderOnClick]="false"
            imageSrc="assets/images/application-logos/svg/ic-sflag.svg" class="copy">
        </circle-progress>
        <h3 class="desc-title">{{ 'enter-token.title' | translate }}​</h3>
    </div>
    <div class="reqnumber-section">
        <img src="assets/images/application-logos/svg/ic-woappointment.svg">
        <div class="section-description">{{ 'enter-token.description' | translate }}</div>
        <ng-otp-input #ngOtpInput (onInputChange)="onReqNoChange($event)" [config]="config"></ng-otp-input>
    </div>
    <div class="action-btn">
        <button mat-raised-button class="back-btn" type="button" (click)="goToBack()">
            {{ 'back' | translate }}
        </button>
        <button mat-raised-button class="next-btn" type="button" (click)="verifyReqNo()">
            {{ 'enter-token.confirm' | translate }}
        </button>
    </div>
</div>