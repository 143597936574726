import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SpinnerService } from './core';
import { LoaderService } from './main';
import { TranslateService } from "@ngx-translate/core";
import { LocaleService } from './app.locale.service';
import { environment } from '../environments/environment';
declare const statistic: any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    /* #region Fields */
    public showSpinner = false;
    public subscription: Subscription;
    public hasErrorStatusCode: string;
    header: any;
    /* #endregion */

    constructor(private readonly spinnerService: SpinnerService, private router: Router,
        public loader: LoaderService,
        private translate: TranslateService,
        private localeService: LocaleService
    ) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {

                const root = this.router.routerState.snapshot.url;
                var menutext = "";
                if (this.loader.navNodes != null && this.loader.navNodes.length > 0) {
                    this.header = this.loader.navNodes[0].Children.filter(x => root.toLowerCase().indexOf(x.Route.toLowerCase()) > 0)[0]

                    if (this.header != undefined) {
                        menutext = " - " + this.translate.instant(this.header?.TranslationLabel)
                    } else {
                        menutext = "";
                    }
                }
                if (environment.matomoSiteId != 0) {
                    this.loader.deleteMatomoCookie();
                    statistic(root + menutext, environment.matomoSiteId);
                    this.loader.deleteMatomoCookie();
                    // this.matomoTracker.setUserId('UserId');
                    //     this.matomoTracker.setDocumentTitle(menutext);
                    //     this.matomoTracker.trackPageView(root+ menutext);
                }


            }
        });
    }

    /* #region Public Methods */
    public ngOnInit(): void {
        this.subscription = this.spinnerService.getMessage()
            .subscribe(show => this.displaySpinner(show));
    }

    /** This method is being used to toggle Global Error page at root level */
    public onToggleGlobalErrorPage(errorStatusCode: string): void {
        this.hasErrorStatusCode = errorStatusCode;
    }
    /* #endregion */

    /* #region Private Methods */
    private displaySpinner(show: boolean): void {
        this.showSpinner = show;
    }
    /* #endregion */
}
