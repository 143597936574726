<div class="Container">

    <div id="bookingcalendar_form" class="form-layout search-mr">
        <mat-form-field def="company">
            <mat-label>{{'booking-calendar.company' | translate}}</mat-label>
            <mat-select name="company" required [disableOptionCentering]="true">
                <mat-option *ngFor="let company of companies" [value]="company.id"
                    (onSelectionChange)="companyChange(company.id,$event)">{{company.org_name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field def="samplingStaton">
            <mat-label>{{'booking-calendar.sampling-station' | translate}}</mat-label>
            <input type="text" #searchData matInput [formControl]="myControl"
                (click)="myControl.setValue('')" [matAutocomplete]="auto">
            <i class="material-icons align-arrow-right">arrow_drop_down</i>

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="samplingStationChange($event)">

                <mat-option *ngFor="let ss of filteredOptions | async" [value]="ss">
                    {{ss.sampling_station_name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field def="calendar" *ngIf="hasCalendar">
            <mat-label>{{'booking-calendar.calendar' | translate}}</mat-label>
            <mat-select name="calendar" required [disableOptionCentering]="true">
                <mat-option *ngFor="let calendar of calendars" [value]="calendar.id"
                    (onSelectionChange)="calendarChange(calendar.id,$event)">{{calendar.calendar_name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field def="week" *ngIf="hasCalendarSelectd && hasCalendar">
            <mat-label>{{'booking-calendar.week' | translate}}</mat-label>
            <mat-select name="week" [value]="weeks[0].val" required disableOptionCentering>
                <mat-option *ngFor="let week of weeks" [value]="week.val"
                    (onSelectionChange)="weekChange(week.val,$event)">{{'booking-calendar.w' | translate}}{{week.weekname | number:'2.'}} : {{week.val|date:'dd/MM/yyyy'}}</mat-option>

            </mat-select>
        </mat-form-field>

    </div>
</div>
<div class="error-container" *ngIf="hasErrorCode">
    <div class="error-container-details">
        <p>{{'booking-calendar.no-calendars' | translate}}</p>
    </div>
</div>
<app-calendar *ngIf="weekSelected" [bookedSlotsCount]="bookedStotsCount" [calendarSlots]="slots" [calendar]="calender"
    [ss_id]="s_id" [selectedWeek]="selectedWeek" [showCalendar]="hasCalendar" [localeLang]="localeService.localeLang"  (viewDateChange)="weekChange($event,null)"></app-calendar>