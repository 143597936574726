import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Company, SamplingStationObj } from '../../../models/common.model';
import { CommonApiService } from '../../../services/common-api.service';
import { TestService } from './../../../services/add-test-service.service';

@Component({
    selector: 'app-booking-setup',
    templateUrl: './booking-setup.component.html',
    styleUrls: ['./booking-setup.component.scss']
})
export class BookingSetupComponent implements OnInit, OnDestroy {
    isCalendarAvailable = false;
    isDisabled = false;
    isCompany = true;
    isDisabledd = false;
    isDisable = false;
    isSpecialDates = false;
    specialDates = false;
    isCalendar = false;
    selectedSSId: any;
    orgId: any;
    Companieslist: Company[] = [];
    SSlist: SamplingStation[] = [];
    CompanyId: number;
    calendar: any[];
    openingAndClosingData: any[];
    samplingStations: any[];
    Companylist = new FormControl('', Validators.required);
    sslist = new FormControl('', Validators.required);
    display = false;
    public filteredOptions: Observable<any[]>;
    public myControl = new FormControl();
    samplingStaton: any = '';
    testList: any[] = [];
    constructor(
        private data: CommonApiService,
        private testService: TestService
    ) {}

    ngOnInit(): void {
        this.data.GetCompany().subscribe((item: any) => {
            this.Companieslist = item;

            // this.Companieslist.sort((a, b) => (a.org_name < b.org_name ? -1 : 1));
            this.Companieslist.sort((a, b) => {
                const nameA = a.org_name.toLowerCase();
                const nameB = b.org_name.toLowerCase();
                if (nameA < nameB)
                // sort string ascending
                {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0; // default return value (no sorting)
            });
        });
        if (this.Companylist.value == '' || this.sslist.value == '') {
            this.isDisabled = true;
            this.isDisable = true;
        }
        if (this.Companylist.value == '') {
            this.isDisabledd = true;
        }
    }

    getCalendarInfo() {}
    displayFn(option?: any): string {
        console.log(option);
        return option?.sampling_station_name;
    }
    onOrgSelect(event: any) {
        this.orgId = event.value;
        this.samplingStaton = null;

        this.data.GetSamplingStation(event.value).subscribe((x: SamplingStationObj) => {
        // this.samplingStationssub.next(x.sampling_stations);

            this.samplingStations = x.sampling_stations;
            // this.samplingStations.sort((a, b) =>
            //   a.sampling_station_name < b.sampling_station_name ? -1 : 1
            // );
            this.SSlist = this.samplingStations;

            this.filteredOptions = this.myControl.valueChanges.pipe(
                startWith(''),
                map((value) => this._filter(value))
            );
        });

        this.isSpecialDates = false;
        this.specialDates = false;
        this.isCalendar = false;
        this.isDisabled = true;
        this.isDisabledd = false;
        this.isDisable = true;
        this.display = false;
    }
    private _filter(value?: any): any[] {
        if (this.samplingStations != null) {
            console.log(value);
            if (typeof value == 'string') {
                const filterval = value?.toLowerCase();
                return this.samplingStations.filter((option) =>
                    option.sampling_station_name.toLowerCase().includes(filterval)
                );
            } else {
                const filterval = value?.sampling_station_name?.toLowerCase();
                return this.samplingStations.filter((option) =>
                    option.sampling_station_name.toLowerCase().includes(filterval)
                );
            }
        }
        return null;
    }

    public samplingStationChange(event: any): void {
        this.specialDates = false;
        this.isDisabledd = true;
        this.getTestBySamplingStation(event.option.value.id);
    }

    getTestBySamplingStation(id: any) {
        sessionStorage.setItem('uci', '0');
        this.isCalendar = false;
        this.testService.getTestBySamplingStation(id, 0).subscribe((item: any) => {
            const tests = item;
            this.testList = [];
            tests.forEach((t: any) => {
                this.testList.push({
                    id: t.id,
                    displayText: t.short_name,
                    active: true
                });
            });
            this.selectedSSId = id;
            this.getCalendar(id);
        });
    }

    private getCalendar(id: any) {
        this.data.GetCalendar(id).subscribe((item: any) => {
            this.calendar = item.sampling_station_slot;
            this.openingAndClosingData = item.opening_and_closing_details;
            if (this.calendar == null) {
                this.display = true;
                this.isDisabled = true;
                this.isDisable = false;
                this.specialDates = false;
                this.isDisabledd = true;
            } else {
                this.isCalendarAvailable = true;
                // this.calendarEnable();
                this.isCalendar = true;
                this.isSpecialDates = false;
                this.isDisabledd = true;
                this.display = false;
                this.isDisabled = false;
                this.isDisable = false;
            }
        });
    }

    SpecialDateEnable() {
        this.isSpecialDates = true;
        this.isCalendar = false;
        this.specialDates = false;
    }
    SpecialDatesEnable() {
        this.specialDates = true;
    }
    calendarEnable() {
        this.isCalendarAvailable = false;
        this.isCalendar = true;
        this.isSpecialDates = false;
        this.display = false;

        // this.getTestBySamplingStation(this.selectedSSId);
        this.testService
            .getTestBySamplingStation(this.selectedSSId, 0)
            .subscribe((item: any) => {
                const tests = item;
                this.testList = [];
                tests.forEach((t: any) => {
                    this.testList.push({
                        id: t.id,
                        displayText: t.short_name,
                        active: true
                    });
                });
            });
    }

    ngOnDestroy() {
        sessionStorage.setItem('uci', '0');
    }
}
export class SamplingStation {
    id: number;
    name: string;
    org_id: number;
}
