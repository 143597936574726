<button class="close" mat-dialog-close mat-button id="btn_close">X</button>
<div class="slider-data-container">
    <div class="header">
        <div style="text-align: center;">
            <app-section-title title="{{title | translate}}"></app-section-title>
        </div>
    </div>
    <form class="form-layout" [formGroup]="testForm" [appAutoScroll]="testForm">
        <div id="add_test" class="form-layout pt-30 input-min-spacing">
            <div [ngClass]="this.data.action!='edit' ? 'invisible' : 'form-layout'">
                <mat-form-field>
                    <mat-label class="label-float">{{'add-test.id' | translate}}</mat-label>
                    <input matInput name="Id" id="Id" [value]="data?.obj?.Id" disabled />
                </mat-form-field>
            </div>
            <div></div>
            <mat-form-field class="md 40">
                <mat-label class="label-float">{{'add-test-type.name' | translate}}</mat-label>
                <input matInput formControlName="Name" maxlength="100" autocomplete="off">
            </mat-form-field>

            <mat-form-field class="md 40">
                <mat-label class="label-float">{{'add-test-type.name-fr' | translate}}</mat-label>
                <input matInput formControlName="NameFrench" maxlength="100" autocomplete="off">
            </mat-form-field>
        </div>
        <div class="form-layout input-min-spacing">
            <mat-form-field>
                <mat-label>{{'add-test-type.description' | translate}}</mat-label>
                <textarea matInput formControlName="Description" cols="30" rows="5"></textarea>
                <mat-error *ngIf="testForm.get('Description').hasError('maxlength')">{{'add-test.valid-input' |
                    translate}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'add-test-type.description-fr' | translate}}</mat-label>
                <textarea matInput formControlName="DescriptionFrench" cols="30" rows="5"></textarea>
                <mat-error *ngIf="testForm.get('DescriptionFrench').hasError('maxlength')">{{'add-test.valid-input' |
                    translate}}</mat-error>
            </mat-form-field>
        </div>
        <div id="add_test" class="form-layout pt-30 input-min-spacing">
            <div>
                <mat-form-field (click)="fileInputs.click()" class="pointCursor browse-input mt-remove">
                    <input formControlName="FileName" *ngIf="fileName == ''" matInput
                        placeholder="{{'add-test.upload-file' | translate}}" readonly />
                    <input formControlName="FileName" *ngIf="fileName != ''" class="imgUploaded" matInput
                        placeholder="{{fileName}}" readonly />
                    <button class="buttonInsideField upload-image">
                        {{'add-test.browse' | translate}} *
                    </button>
                </mat-form-field>
                <mat-hint align="start" class="browse-hint">{{'add-test.upload-description' | translate}}</mat-hint>
                <!-- {{fileName}} -->
                <!-- </mat-form-field> -->
                <input hidden type="file" accept=".png, .jpeg, .jpg, .svg" (change)="inputChange($event)" #fileInputs>
                <mat-error *ngIf="isInvalidFileSize">{{'add-test.size-error' | translate}}</mat-error>
                <mat-error *ngIf="isIncorrectFileFormat">{{'add-test.type-error' | translate}}</mat-error>
            </div>
            <mat-form-field def="Status">
                <mat-label class="label-float">{{'admin.status' | translate}}</mat-label>
                <mat-select formControlName="Status" [disableOptionCentering]="true">
                    <mat-option [value]="true">{{'admin.active' | translate}}</mat-option>
                    <mat-option [value]="false">{{'admin.suspended' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="actions" inline>
            <!-- <button mat-button color="accent" mat-dialog-close="true" class="ghost button_width" type="submit" id="back_btn">Back</button>
        <button mat-button color="accent" (click)="reset()" class="ghost button_width" type="submit" id="clear_btn">Clear</button>
        <button mat-button color="accent" (click)="save()" class="ghost button_width" type="submit" id="save_btn">Save</button> -->
            <button mat-button color="accent" class="ghost button_width" *ngIf="adminRole" type="button" id="btn_clear"
                (click)="clear()">{{'admin.clear'
                | translate}}</button>
            <button mat-raised-button class="ghost button_width" color="primary" id="btn_save" type="submit"
                *ngIf="adminRole" [disabled]="this.data.action!='edit' && this.imageUrl == ''"
                (click)="SaveButton()">{{'admin.save' |
                translate}}</button>
        </div>
    </form>
</div>