import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { WebcamModule } from 'ngx-webcam';
import { SharedModule } from '../../shared';
import { MainPipe } from '../../shared/MainPipe.module';
import { MaterialModule } from '../../shared/material.module';
import { EClinicalSharedModule } from '../shared/shared.module';
import { AppointmentsComponent } from './appointments/appointments.component';
import { AddEventComponent } from './booking-calendar/add-event/add-event.component';
import { BookedListComponent } from './booking-calendar/booked-list/booked-list.component';
import { BookingCalendarComponent } from './booking-calendar/booking-calendar.component';
import { EditAppointmentComponent } from './booking-calendar/edit-appointment/edit-appointment.component';
import { FindPatientComponent } from './find-patient/find-patient.component';
import { ScanQrComponent } from './find-patient/scan-qr/scan-qr.component';
import { PatientInformationComponent } from './patient-information/patient-information.component';
import { RegsiterToolkitComponent } from './patient-information/regsiter-toolkit/regsiter-toolkit.component';
import { ResetPasswordComponent } from './patient-information/reset-password/reset-password.component';
import { ReceptionistRoutingModule } from './receptionist-routing.module';
import { ReceptionistComponent } from './receptionist.component';
import { ConfirmResultComponent } from './register-result/confirm-result/confirm-result.component';
import { RegisterResultComponent } from './register-result/register-result.component';
import { RegisterTestKitComponent } from './register-test-kit/register-test-kit.component';
import { AllResultsComponent } from './results/all-results/all-results.component';
import { ChangeTestComponent } from './results/change-test/change-test.component';
import { ResultsReadyComponent } from './results/results-ready/results-ready.component';
import { ResultsComponent } from './results/results.component';
import { WaitingResultsComponent } from './results/waiting-results/waiting-results.component';
import { TestkitsWithoutResultComponent } from './testkits-without-result/testkits-without-result.component';
import { ViewAppointmentsComponent } from './patient-information/view-appointments/view-appointments.component';
import { CreateAccountComponent } from './patient-information/create-account/create-account.component';
import { NgOtpInputModule } from 'ng-otp-input';

@NgModule({
    declarations: [
        ReceptionistComponent,
        FindPatientComponent,
        PatientInformationComponent,
        ResetPasswordComponent,
        AppointmentsComponent,
        ResultsComponent,
        AllResultsComponent,
        WaitingResultsComponent,
        ResultsReadyComponent,
        BookingCalendarComponent,
        AddEventComponent,
        BookedListComponent,
        EditAppointmentComponent,
        RegsiterToolkitComponent,
        ScanQrComponent,
        RegisterResultComponent,
        ConfirmResultComponent,
        RegisterTestKitComponent,
        TestkitsWithoutResultComponent,
        ChangeTestComponent,
        ViewAppointmentsComponent,
        CreateAccountComponent
    ],
    imports: [
        CommonModule,
        ReceptionistRoutingModule,
        FormsModule,
        MaterialModule,
        RouterModule,
        ReactiveFormsModule,
        DragDropModule,
        TranslateModule,
        SharedModule,
        EClinicalSharedModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        NgxMatIntlTelInputModule,
        ZXingScannerModule,
        WebcamModule, MainPipe,
        NgOtpInputModule
    ]
})
export class ReceptionistModule { }
