import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';

@Component({
    selector: 'app-select-check-all',
    templateUrl: './select-check-all.component.html',
    styleUrls: ['./select-check-all.component.scss']
})
export class SelectCheckAllComponent implements OnInit {
    constructor(
        protected translate: TranslateService,
        private localeService: LocaleService
    ) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    @Output() allChecked = new EventEmitter<any>();
    @Input() model: FormControl;
    @Input() values = Array<any>;
    @Input() text = this.translate.instant('add-sampling.select-all');

    ngOnInit(): void {
    }
    isChecked(): boolean {
        return this.model.value && this.values?.length
            && this.model.value.length === this.values?.length;
    }

    isIndeterminate(): boolean {
        return this.model.value && this.values?.length && this.model.value.length
            && this.model.value.length < this.values?.length;
    }

    toggleSelection(change: MatCheckboxChange): void {
        this.allChecked.emit(change.checked);
        if (change.checked) {
            this.model.setValue(this.values);
        } else {
            this.model.setValue([]);
        }
    }
}
