import { Pipe, PipeTransform } from '@angular/core';

export class OrgDetails {
    id: Number;
    name: string;
}

@Pipe({
    name: 'orgName'
})
export class OrgNamePipe implements PipeTransform {

    transform(value: OrgDetails[]): string {
        let orgNames = '';
        value.forEach(org => {
            if (org.name !== '') {
                orgNames += (', ' + org.name) ;
            }
        });
        return orgNames.slice(2);
    }

}
