<div id="login_mainContainer">
  <div class="login-header">
    <div class="login-cover">
      <div [hidden]=" loader.mobileLogin" id="mobile-home-container">
        <button mat-raised-button color="primary" type="submit" class="btn-login base-background"
          [ngClass]="!showAbout ? 'base-background' : 'base-background-clicked'" (click)="loader.mobileLogin = true">
          {{'login.signin' | translate}}
        </button>
        <button mat-raised-button type="submit" class="btn-login white-background" (click)="redirectBooking()">
          {{'login.book-appointment' | translate}}
        </button>
        <button mat-raised-button type="submit" class="btn-login white-background" (click)="redirectLab()">
          {{'login.find-lab' | translate}}
        </button>
        <hr>
        <button *ngIf="!showAbout" mat-button color="primary" type="submit" class="btn-login transparent-background"
          (click)="showAbout = !showAbout;">
          {{'login.about-e-clinical' | translate}}
        </button>
        <div *ngIf="showAbout" class="Bannertext_container_2">
          <div class="mobile-container">
            <div class="video-container">
              <video width="100%" height="auto" controls controlslist="nodownload noplaybackrate"
                disablepictureinpicture>
                <source src="../../../assets/e_Clinical.mp4#t=2" type="video/mp4">
                <source src="../../../assets/e_Clinical.mp4#t=2" type="video/ogg">
              </video>

            </div>
            <div class="mobile-container-header">
              <p>{{'login.banner1' | translate}}</p>
            </div>
            <ul>
              <div class="list-icons">
                <div class="appmnt-banner"><span class="icon-icon-my-appointment icon"></span>
                  <li>{{'login.banner3' | translate}}</li>
                </div>
                <div class="doc-banner"><span class="icon-icon-documents icon"></span>
                  <li>{{'login.banner4' | translate}}</li>
                </div>
                <div class="clock-banner"><span class="icon-ic-clock icon"></span>
                  <li>{{'login.banner2' | translate}}</li>
                </div>
                <div class="result-banner"><span class="icon-icon-results icon"></span>
                  <li>{{'login.banner5' | translate}}</li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div [hidden]="!loader.mobileLogin" id="login_divContainer" #loginContainer>
        <div id="login-wrapper" *ngIf="loader.uiMode==1">
          <h4>{{'login.sign-in' | translate}}</h4>
          <form [formGroup]="form" #formReference class="input-min-spacing">
            <mat-form-field>
              <mat-label>{{'login.username' | translate}}</mat-label>
              <input matInput #loginEmail id="loginName" [formControl]="loginName" (focusout)="clearPassword()"
                (keydown.space)="disableSpace($event)" />
              <mat-error *ngIf="loginName.errors?.required">{{'login.username-mandatory' | translate}}</mat-error>
              <mat-error *ngIf="loginName.invalid && !loginName.errors?.required">{{'login.email-invalid' |
                translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'login.password' | translate}}</mat-label>
              <input matInput name="password" id="password" [type]="hide ? 'password' : 'text'" [formControl]="password"
                (keydown.space)="disableSpace($event)" />
              <mat-icon matSuffix class="password_icon" (click)="hide = !hide">{{
                hide ? "visibility_off" : "visibility"
                }}</mat-icon>
              <mat-error *ngIf="password.errors?.required">{{'login.password-mandatory' | translate}}</mat-error>
              <mat-error
                *ngIf="password.invalid && !password.errors?.required && !isAccountLocked && !isAccountDeActive">
                {{'login.password-invalid' |
                translate}}
              </mat-error>
              <mat-error *ngIf="isAccountLocked">{{'login.password-account-blocked' | translate}}
              </mat-error>
              <mat-error *ngIf="isAccountDeActive">{{'login.account-deactive' | translate}}
              </mat-error>
            </mat-form-field>
            <div class="remember-cover">
              <div>
                <mat-checkbox type="checkbox" formControlName="rememberMe" [checked]="rememberMe.value"
                  (change)="rememberMeCheck($event)" name="rememberMe">{{'login.remember-me' | translate}}
                </mat-checkbox>
                <span class="emoji" (click)="isOpen = !isOpen" matTooltip="{{'login.more-info' | translate}}">i</span>
              </div>
              <div>
                <a class="linkurl" (click)="loader.uiMode=2;registeredEmail.reset()">{{'login.forgot-password' |
                  translate}}</a>
              </div>
            </div>
          </form>
          <div class="container-fluid py-3 captcha center" *ngIf="loader.uiMode==1">
            <div class="row mt-3">
              <div class="col-sm-12 mb-2">
                <re-captcha
                  [hidden]="automationEmail.split(';').includes(this.form.controls['loginName'].value.toLowerCase())"
                  (resolved)="captchaResolved($event)" (reset)="recaptcha.reset()" id="recaptcha" name="recaptcha"
                  #recaptcha="ngModel" [(ngModel)]="token" required
                  [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
                <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">
                  <div *ngIf="recaptcha.errors?.['required']">{{'login.captcha-required' | translate}}</div>
                </div>
                <mat-error *ngIf="showCapchaError">{{'login.capcha-required' | translate}}
                </mat-error>
              </div>
            </div>
          </div>

          <button mat-raised-button color="primary" type="submit" class="btn-login  mb-10" id="btn_login"
            (click)="logIn()">
            {{'login.login' | translate}}
          </button>
          <button mat-button color="accent" class="ghost button_width horizontal-context overlay w-100"
            id="btn_create_account" type="submit" (click)="redirectRegistration()">
            {{'login.create-account' | translate}}
          </button>
          <div class="separator"></div>
          <div class="mt-20 loginButtonSection">
            <button mat-button color="accent" id="btn_book_appointment_login"
              class="ghost button_width horizontal-context margin-btn overlay" type="submit"
              (click)="redirectBooking()">
              {{'login.book-appointment' | translate}}
            </button>
            <button mat-button color="accent" id="btn_find_lab_login"
              class="ghost button_width horizontal-context overlay" type="submit" (click)="redirectLab()">
              {{'login.find-lab' | translate}}
            </button>
          </div>
        </div>

        <div *ngIf="loader.uiMode==2">
          <div id="forgetPassword_container" [formGroup]="sendEmailForm">
            <h4>{{'login.trouble' | translate}}</h4>
            <span>{{'login.enter-mail' | translate}}</span>
            <mat-form-field class="mb-20 mt-20">
              <mat-label class="label-float">{{'login.mail' | translate}}</mat-label>
              <input matInput class="email-input" name="RegisteredEmail" #RegisteredEmail [isRequired]="true"
                (keyup)="isValid()" [formControl]="registeredEmail" />
              <mat-error *ngIf="registeredEmail.invalid  && !registeredEmail.hasError('notExist')">{{'login.valid-mail'
                | translate}}</mat-error>
              <mat-error *ngIf="registeredEmail.invalid && registeredEmail.hasError('notExist')">
                {{'login.email-not-exist-error' | translate}}</mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" id="btn_send" type="submit" class="mb-20"
              (click)="onSend($event)">
              {{'login.send' | translate}}
            </button>
            <span>{{'login.back' | translate}}<a class="linkurl" (click)="openLogin()">{{'login.Login' |
                translate}}</a></span>
          </div>
        </div>
        <div id="checkmail_container" *ngIf="loader.uiMode==3">
          <img src="./assets/images/application-logos/svg/mail-sent-ic.svg" />
          <h4>{{'login.check-mail' | translate}}</h4>
          <p>
            <span> {{'login.mail-instructions-1' | translate}}</span>
            <span id="email">{{username}},</span>
            <span> {{'login.mail-instructions-2' | translate}}</span>
          </p>
          <p>
            {{'login.mail-instructions-3' | translate}}
          </p>
          <p>{{'login.note' | translate}}</p>
          <p>
            {{'login.no-account' | translate}}
            <a class="linkurl" (click)="redirectRegistration()">{{'login.create-account' | translate}}</a>
          </p>
          <span>{{'login.back' | translate}}<a class="linkurl" (click)="openLogin()">{{'login.Login' |
              translate}}</a></span>
        </div>

        <div id="createNewPassword_container" [formGroup]="forgetPasswordForm"
          *ngIf="loader.uiMode==4||loader.uiMode==5" style="width: 360px; margin: 0 auto;">
          <ng-container *ngIf="loader.uiMode==4">
            <ng-container *ngIf="loader.passwordExpired">
              <h4>{{'login.password-expired' | translate}}</h4>
            </ng-container>
            <ng-container *ngIf="!loader.passwordExpired">
              <h4>{{'login.modify-password' | translate}}</h4>
            </ng-container>
            <p>{{'login.modify-password-instructions' | translate}}</p>
          </ng-container>

          <ng-container *ngIf="loader.uiMode==5">
            <h4>{{'login.create-password' | translate}}</h4>
            <p>{{'login.password-instructions' | translate}}</p>
          </ng-container>

          <mat-form-field class="mb-20">
            <mat-label>{{'login.password' | translate}}</mat-label>
            <input matInput name="password" (blur)="hideErrorBox()" (focus)="showErrorBox()" (keyup)="showErrorBox()"
              #newpassword [formControl]="resetpassword" (change)="passwordMatch()" id="password"
              [type]="hide ? 'password' : 'text'" (keydown.space)="disableSpace($event)" />
            <mat-error *ngIf="resetpassword.invalid && resetpassword.value && resetpassword.hasError('whitespace')">
              {{'createAccount.space-error'
              | translate}}</mat-error>
            <mat-error *ngIf="resetpassword.invalid && resetpassword.value && !resetpassword.hasError('whitespace')">
              {{'login.valid-password'
              | translate}}</mat-error>
            <mat-error *ngIf="resetpassword.invalid && !resetpassword.value && resetpassword.hasError('whitespace')">
              {{'login.valid-password'
              | translate}}</mat-error>
            <mat-icon matSuffix class="password_icon" (click)="hide = !hide">{{hide ? "visibility_off" :
              "visibility"}}</mat-icon>

            <app-password-policy (isValid)="checkIsValid($event)"></app-password-policy>

          </mat-form-field>
          <mat-form-field id="repeatpassword" class="mt-20">
            <mat-label>{{'login.repeat-password' | translate}}</mat-label>
            <input matInput name="repeatpassword" [formControl]="repeatresetpassword" (change)="passwordMatch()"
              type="password" [type]="isResetPasswordHide ? 'password' : 'text'"
              (keydown.space)="disableSpace($event)" />
            <mat-icon matSuffix class="password_icon" (click)="isResetPasswordHide = !isResetPasswordHide">{{
              isResetPasswordHide ? "visibility_off" : "visibility"
              }}</mat-icon>
          </mat-form-field>
          <div class="decleration mt-30" *ngIf="loader.uiMode==5 && !isUser && !isNurse">
            <mat-checkbox [formControl]="privacy"><a class="termspolicy"
                href="{{'login.privacy-policy-link' | translate}}" target="_blank">{{'login.privacy-policy' |
                translate}}</a></mat-checkbox>
            <mat-checkbox [formControl]="terms"><a class="termspolicy" href="{{'login.terms-of-use-link' | translate}}"
                target="_blank">{{'login.terms-of-use' |
                translate}}</a></mat-checkbox>
          </div>
          <div *ngIf="passwordsMatch && resetpassword.valid" id="passwordMatcherror">{{'login.password-match-error' |
            translate}}</div>
          <button *ngIf="loader.uiMode==4" mat-raised-button color="primary" id="resetBtn" type="submit"
            (click)="onResetPassword()" style="width: 100%" class="mt-40" [disabled]="isDisable">
            {{'login.reset-password' | translate}}
          </button>
          <button *ngIf="loader.uiMode==5 && !isUser && !isNurse" mat-raised-button color="primary" id="resetBtn"
            type="submit" (click)="onResetPassword()" style="width: 100%" class="mt-40" [disabled]="enableButton()">
            {{'login.set-password' | translate}}
          </button>
          <button *ngIf="loader.uiMode==5 && (isUser || isNurse)" mat-raised-button color="primary" id="resetBtn"
            type="submit" (click)="onResetPassword()" style="width: 100%" class="mt-40" [disabled]="isDisable">
            {{'login.set-password' | translate}}

          </button>
        </div>

        <div id="checkmail_container" *ngIf="loader.uiMode==7">
          <img src="./assets/images/application-logos/svg/mail-sent-ic.svg" />
          <h4>{{'login.account-creation-confirmation' | translate}}</h4>
          <!-- <p>
            {{'login.mail-confirmation-instructions' | translate}} {{userEmail}}
            <span id="email">{{username}}</span>
          </p>
          <p>{{'login.note' | translate}}</p>
          <p>{{'login.activate-instruction' | translate}}</p>
          <p>
            {{'login.no-link' | translate}}
            <a class="linkurl" role="button" (click)="loader.sendPasswordLink(userEmail)">{{'login.resend-link' |
              translate}}</a>
          </p> -->
          <span>{{'login.back' | translate}}<a class="linkurl" (click)="loader.uiMode=1">{{'login.Login' |
              translate}}</a></span>
        </div>

        <div id="validateemail_container" *ngIf="loader.uiMode==6">
          <div id="forgetPassword_container">
            <h4>{{'login.activation-successful' | translate}}</h4>
            <p>
              <span>{{'login.activated-login' | translate}}</span>
            </p>
            <p><span>{{'login.click-here' | translate }}</span>
              <span><a class="linkurl" (click)="loader.uiMode=1">{{'login.Login' |
                  translate}}</a></span>
            </p>
          </div>
        </div>


        <div id="invalidlink_container" *ngIf="loader.uiMode==8">
          <div id="expiredlink_container">

            <p>
              <span>{{'login.activation-unsuccessful' | translate}}</span>
            </p>
          </div>
        </div>

        <div id="otp_container" *ngIf="loader.uiMode==9">
          <div id="otpinner_container">

            <h3 class="otp-header">{{'login.otp-entermessage' | translate}}</h3>
            <mat-hint class="otp-hint">{{'login.otp-hint' | translate}}</mat-hint>
            <br>
            <ng-otp-input clas="otp" #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
            <div class="otp-counter">
              <h5>{{'login.otp-time-remaining' | translate}} : {{display}}</h5>
            </div>
            <div class="otp-buttons">
              <button mat-raised-button *ngIf="btndisabled" color="primary" type="submit" class="btn-login"
                (click)="verifylogIn()">
                {{'login.otp-verify' | translate}} </button>
              <button mat-raised-button color="primary" *ngIf="!btndisabled" type="submit" class="btn-login"
                (click)="resetOTP()">
                {{'login.otp-resend' | translate}} </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>