import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
import { LoaderService, MessageService } from '../../../main';
import { constants } from '../constant';
import { RelativeMemberService } from '../../services/relative-member.service';
import { RMData } from '../../patient/relative-members/relative-member-data.model';
import { SpinnerService } from '../../../core';
import { FormControl } from '@angular/forms';
import { FindMemberComponent } from '../../patient/relative-members/find-member/find-member.component';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-con-booking',
    templateUrl: './con-booking.component.html',
    styleUrls: ['./con-booking.component.scss']
})
export class ConBookingComponent implements OnInit, OnChanges, OnDestroy {
    public optionChosen = new FormControl("");

    public step: number = 1;
    @Output() showLoginScreen = new EventEmitter<any>();
    @Output() bookAnotherTest = new EventEmitter<any>();
    @Output() goToBack = new EventEmitter<any>();

    @Input() isConfirmed = false;
    @Input() fromRegister = false;
    @Input() fromAppointmentLogin = false;
    @Input() showConfirmBooking = false;
    @Input() prescription_url: string;
    @Input() email: string;
    @Input() start_time: Date;
    @Input() user_name: string;
    @Input() dob: Date;
    @Input() end_time: Date;
    @Input() selectedLab: any;
    @Input() selectedtest: any;
    @Input() calendar: any;
    public patient_name: string;
    public patient_dob: Date;
    public selectedPatient = new FormControl("");
    user_age: number;
    userDetails: any;
    isLoginReceptionist: boolean;
    public adminRole = constants.adminRole;
    public supervisorRole = constants.supervisorRole;
    public patientRole = constants.patientRole;
    public nurseRole = constants.nurseRole;
    @ViewChild(FindMemberComponent) findMemberComponent!: FindMemberComponent;

    public isLoginPatient: boolean;
    public selectedDetail: string = null;
    public bookingComment: string;
    public appointmentCode: string;
    public RM_DATA: RMData[] = [];
    public loggedInId: any;
    readonly constants = constants;
    public bookAppointment: boolean = false;
    showBackBtn: boolean = true;
    constructor(private readonly translateService: TranslateService, public localeService: LocaleService, public loader: LoaderService, public rmService: RelativeMemberService,
        public spinner: SpinnerService, public cdr: ChangeDetectorRef,
        public messageService: MessageService, public route: ActivatedRoute) {
        this.translateService.setDefaultLang(this.localeService.localeLang);
        this.translateService.use(this.localeService.localeLang);

    }

    ngOnInit(): void {
        console.log(this.loader.ppaInfo);
        let resp = JSON.parse(sessionStorage.getItem('userDetails'));
        this.loggedInId = resp?.id;

        if (window.location.href.includes("patient/complete-appointment") && this.loggedInId) {
            let is_prescription = this.loader.toBoolean(this.route.snapshot.params["is_prescription"]);
            let is_active = this.loader.toBoolean(this.route.snapshot.params["is_active"]);
            let doc_id = this.route.snapshot.params["doc_id"];
            if (is_active && doc_id == "0" && !is_prescription) {
                this.showBackBtn = false;
            }
            else if (!is_active && !is_prescription) {
                this.showBackBtn = false;
            }
            else {
                this.showBackBtn = true;

            }

        }

        sessionStorage.setItem('popupShown', 'true');
        const access_token = JSON.parse(sessionStorage.getItem('access_token'));
        this.isLoginReceptionist = access_token?.role == constants.nurseRole;
        this.step = this.isLoginReceptionist ? 3 : this.loader.flow_detail == constants.complete_booking_flow ? 3 : 1;
        if (resp?.id == this.loader.id && access_token?.role == constants.patientRole) {
            this.loader.user_name = resp?.first_name + " " + resp?.birth_name;
            this.loader.dob = resp?.dob;
            this.loader.id = resp?.id;
        }
        else {
            if (this.loader?.id) {
                this.selectedPatient.setValue(this.loader?.id);
            }
        }
        this.user_age = this.loader.calculateAge(this.dob);
        this.bookingComment = JSON.parse(sessionStorage.getItem('a_data'))?.test_comments;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        //this.isLoginReceptionist = JSON.parse(sessionStorage.getItem('access_token'))?.role == constants.nurseRole;
        if (this.isLoginReceptionist) {
            this.step = 3;
        }
        else {

            if (this.isConfirmed) {
                this.step = 3;
            } else if (this.loader.rmMode) {
                this.loader.forSelf = false;
                this.getRelativeMemberData();
            }
        }
        if (!this.isLoginReceptionist && this.loggedInId && this.selectedLab.is_favourite && !this.isConfirmed) {
            if (this.loader.rmMode) {
                this.selectAppointmentFor(false);
            } else if (this.loader.favLabMode) {
                this.selectAppointmentFor(true);
            } else {
                this.step = 1;
            }
        }
        if ((window.location.href.includes("patient/book-appointment") || window.location.href.includes("patient/complete-appointment")) && screen.width < 962) {
            this.bookAppointment = true;
        }
    }

    getRelativeMemberData() {
        this.selectedPatient.setValue(null);
        let resp = JSON.parse(sessionStorage.getItem('userDetails'))
        if (resp) {
            this.spinner.show(true);
            this.rmService.getRmData(resp?.id).subscribe((data: RMData[]) => {
                let resp = JSON.parse(sessionStorage.getItem('userDetails'));
                this.loggedInId = resp?.id;
                if (this.loader.rmMode) {
                    this.RM_DATA = data;
                    this.selectedPatient.setValue(this.loader?.rm_id);
                    this.patientSelectionChange();

                } else {
                    if (data) {
                        if (this.loader?.rm_id) {
                            this.selectedPatient.setValue(this.loader?.rm_id);
                            this.patientSelectionChange();
                        }
                    }
                }

                if (data && this.loader.forSelf == false) {
                    this.RM_DATA = data;
                    this.step = 4;

                } else {
                    this.RM_DATA = [];
                    this.step = 2;
                }
                this.spinner.show(false);

            }, () => {
                this.spinner.show(false);
            });
        }
    }

    ngOnChanges() {
        if (this.loader.ppaInfo) {
            this.loader.user_name = this.loader.ppaInfo.first_name + " " + this.loader.ppaInfo.birth_name;
            this.loader.dob = this.loader.ppaInfo.dob;
            this.selectedDetail = this.selectedtest.comment;
            this.loader.relativeMember = { user_name: this.loader.user_name, dob: this.loader.dob };
            if (window.location.href.includes("patient/complete-appointment") && this.loggedInId) {
                let is_prescription = this.loader.toBoolean(this.route.snapshot.params["is_prescription"]);
                let is_active = this.loader.toBoolean(this.route.snapshot.params["is_active"]);
                let doc_id = this.route.snapshot.params["doc_id"];
                if (is_active && doc_id == "0" && !is_prescription) {
                    this.showBackBtn = false;
                }
                else if (!is_active && !is_prescription) {
                    this.showBackBtn = false;
                }
                else {
                    this.showBackBtn = true;

                }

            }
        } else {
            let resp = JSON.parse(sessionStorage.getItem('userDetails'));
            if (resp?.first_name) {
                this.loader.user_name = resp.first_name + " " + resp.birth_name;
                this.loader.dob = resp.dob;
            }
        }


        this.user_age = this.loader.calculateAge(this.dob);
        this.isLoginReceptionist = JSON.parse(sessionStorage.getItem('access_token'))?.role == constants.nurseRole;

        this.bookingComment = JSON.parse(sessionStorage.getItem('a_data'))?.test_comments;
        this.appointmentCode = JSON.parse(sessionStorage.getItem('a_code'));
        if ((window.location.href.includes("patient/book-appointment") || window.location.href.includes("patient/complete-appointment")) && screen.width < 962) {
            this.bookAppointment = true;
        }
    }

    ngOnDestroy() {
        this.loader.favLabMode = false;
    }

    redirectBooking() {
        sessionStorage.removeItem('a_data');
        const access_token = JSON.parse(sessionStorage.getItem('access_token'));
        if (access_token && access_token?.role == 1) {
            // receptionist is booking appointment for customer
            this.loader.bookingPatientId = '';
            // this.loader.user_name = '';
            //this.loader.dob = '';
            this.loader.highlightLeftMenu(['/receptionist/book-appointment/' + this.loader.ss_external_id]);
        } else {
            this.bookAnotherTest.emit(true);
        }
    }
    redirectDocument() {
        sessionStorage.removeItem('a_data');
        this.loader.highlightLeftMenu(['/patient/documents']);
    }

    checkUserLogin() {
        const access_token = JSON.parse(sessionStorage.getItem('access_token'));
        if (access_token?.role == 1) {
            // Receptionist is booking appointment on behalf of customer
            if (this.loader.bookingPatientId && this.loader.bookingPatientId != '') {
                this.showLoginScreen.emit({
                    isConfirmed: true,
                    showConfirmBooking: true,
                    showLogin: false,
                    showRegister: false,
                    bookingComment: this.selectedDetail
                });
            } else {
                this.showLoginScreen.emit({
                    isConfirmed: false,
                    showConfirmBooking: false,
                    showLogin: false,
                    showRegister: true,//if receptionist radio button should not be there
                    bookingComment: this.selectedDetail
                });
            }
        } else {
            this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
            if (this.userDetails) {
                this.showLoginScreen.emit({
                    isConfirmed: true,
                    showConfirmBooking: true,
                    showLogin: false,
                    showRegister: false,
                    bookingComment: this.selectedDetail
                });
            } else {
                this.showLoginScreen.emit({
                    isConfirmed: false,
                    showConfirmBooking: false,
                    showLogin: true,
                    showRegister: false,
                    bookingComment: this.selectedDetail
                });
            }
        }
    }


    public patientSelectionChange() {
        let p_id = this.selectedPatient.value;
        let resp = JSON.parse(sessionStorage.getItem('userDetails'))

        if (resp?.id == p_id) {
            //self
            this.loader.user_name = resp?.first_name + " " + resp?.last_name;
            this.loader.dob = resp.dob;
            this.loader.id = resp?.id;

        } else {
            if (this.RM_DATA?.length > 0) {
                if (p_id) {
                    let p_index = this.RM_DATA.findIndex(i => i.rm_id == p_id);
                    let rm = this.RM_DATA[p_index];
                    this.loader.relativeMember = { user_name: rm.rm_fname + " " + rm.rm_lname, dob: rm.dob };
                    this.loader.id = rm.rm_id;
                    this.loader.rm_id = rm.rm_id;
                }
                else {
                    this.loader.relativeMember = { user_name: "", dob: "" }
                    this.loader.id = "";
                    this.loader.rm_id = "";
                }
            }
        }
    }

    public selectAppointmentFor(forSelf: boolean) {

        this.loader.forSelf = forSelf;
        this.loader.isRMAdded = !forSelf;

        let resp = JSON.parse(sessionStorage.getItem('userDetails'));
        if (resp) {
            if (forSelf) {
                let userData: RMData = {
                    rm_id: resp?.id,
                    dob: resp?.dob,
                    gender: resp?.gender.toString(),
                    ageYears: 0,
                    ageMonths: 0,
                    isPrimary: true,
                    email: resp?.email,
                    phoneNumber: resp?.phoneNumber,
                    idCard: "",
                    insurance: "",
                    isOwnerAccount: "true",
                    rm_fname: resp?.first_name,
                    rm_lname: resp?.last_name,
                    parentId: "",
                    login_id: resp?.login_id,
                    parent_fname: "",
                    parent_lname: "",
                    parent_id: "",
                    sec_email: "",
                    sec_fname: "",
                    sec_id: "",
                    sec_lname: "",
                    birthcertificate: "",
                    areAllDocsUploaded: true
                }
                this.RM_DATA.unshift(userData);
                this.selectedPatient.setValue(this.RM_DATA[0].rm_id);
                this.loader.rm_id = "";
                this.patientSelectionChange();
                this.spinner.show(false);
                this.step = 3;
            }
            else {
                // this.loader.isRMAdded = true;
                // this.selectedPatient.setValue("");
                // this.loader.id = "";
                // this.loader.rm_id = "";
                this.getRelativeMemberData();
            }
        }
        else {
            //user is not logged in
            this.isLoginReceptionist = JSON.parse(sessionStorage.getItem('access_token'))?.role == constants.nurseRole;
            if (!this.isLoginReceptionist) {
                if (forSelf) {
                    let p_data = JSON.parse(sessionStorage.getItem('p_data'));

                    this.loader.user_name = p_data?.FirstName.concat(" ", p_data?.LastName).concat(" ", p_data?.BirthName);
                    this.loader.dob = p_data?.Dob;
                }
                //guest flow signup process is done, show option to add relative member
                this.step = this.loader.forSelf ? 3 : 2;
            }
        }
    }

    public addRelativeMember(payload: any) {
        sessionStorage.setItem("r_data", JSON.stringify(payload));
        this.loader.rm_data = payload;
        this.loader.isRMAdded = true;
        this.loader.user_name = payload?.FirstName.concat(" ", payload?.LastName).concat(" ", payload?.BirthName);
        this.loader.dob = payload?.Dob;
        this.loader.rmMode = true;
        this.getRelativeMemberData();
    }

    public goBack() {
        this.goToBack.emit(true);
        window.scrollTo(0, 0);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    protected select(option: any) {
        if (this.optionChosen.value == "" || this.optionChosen.value != option) {
            //   this.isSelected = !this.isSelected;
            this.optionChosen.setValue(option);
        }
    }

    appointmentFor() {
        if (this.optionChosen.value == "0") {
            this.selectAppointmentFor(true);
        }
        else if (this.optionChosen.value == "1") {

            this.selectAppointmentFor(false);
        }
    }

    findRM() {
        this.findMemberComponent.searchMemberForm.markAllAsTouched();
        this.findMemberComponent.findMember();
    }

    handleBackFromRM() {
        if (this.findMemberComponent.noRecord) {
            this.findMemberComponent.noRecord = false;
        }
        else {
            if (this.RM_DATA.length > 0) {
                this.step = 4;
            } else {
                this.step = 1;
            }
        }
    }

    checkRMSelection() {
        if (this.selectedPatient.value) {
            this.step = 3;
        }
        else {
            this.messageService.alert(
                this.translateService.instant("select-proceed"),
                5000
            );
        }
    }

    back() {
        if (this.isLoginReceptionist || this.loader.flow_detail == constants.complete_booking_flow) {
            this.goToBack.emit(true);

        } else if (!this.loader.forSelf) {
            this.step = 4;
        } else {
            this.step = 1;
        }
    }
}
