<div class="login-container">
  <div class="section-header" *ngIf="activeStep!=2">
    <circle-progress class="only-web" [options]="loader.optionsG" [percent]="percent" [renderOnClick]="false"
      imageSrc="assets/images/application-logos/svg/ic-suser.svg" class="copy">
    </circle-progress>
    <h3 class="desc-title" *ngIf="!isLoginReceptionist">{{'login-prebook.title'|translate}}</h3>
    <h3 class="desc-title" *ngIf="isLoginReceptionist">{{'toolbar.process1-heading'|translate}}</h3>
  </div>
  <div class="section-header" *ngIf="activeStep==2">
    <circle-progress class="only-web" [options]="loader.optionsG" [percent]="percent" [renderOnClick]="false"
      imageSrc="assets/images/application-logos/svg/ic-sdocument.svg" class="copy">
    </circle-progress>
    <h3 class="desc-title">{{'createAccount.upload-document' | translate}}</h3>
  </div>
  <div class="reqnumber-section">
    <div class="prebook-container">
      <ng-container
        *ngIf="(loader.uiMode==1 || loader.uiMode==2) && loader.userRole != nurseRole  && activeStep==1 && showQuestion">
        <div class=" login-radio-button">
          <label style="display: block">{{'login.have-an-account' | translate}}<span style="color:red;">
              *</span></label>
          <mat-radio-group required [formControl]="authType">
            <mat-radio-button value='1' (change)="isVerified=false;noRecordFound=false;">{{'Yes'|translate}}
            </mat-radio-button>
            <mat-radio-button value='2'>{{'No'|translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-container>


      <ng-container *ngIf="authType.value=='1' && loader.userRole != nurseRole">
        <div class=" float-left login-guest">
          <div id="login-wrapper" *ngIf="loader.uiMode==1">
            <form [formGroup]="Loginform" #formReference class="input-min-spacing">
              <mat-form-field class="form-field">
                <mat-label>{{'login-prebook.username' | translate}}</mat-label>
                <input matInput name="loginName" [formControl]="loginName" (focusout)="clearPassword()" />
                <mat-error *ngIf="loginName.errors?.required">{{'login-prebook.username-mandatory' | translate}}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="form-field">
                <mat-label>{{'login-prebook.password' | translate}}</mat-label>
                <input matInput name="password" id="password" [type]="hide ? 'password' : 'text'"
                  [formControl]="password" />
                <mat-icon matSuffix class="password_icon mediumShadelabel" (click)="hide = !hide">{{ hide ?
                  "visibility_off" : "visibility" }}</mat-icon>
                <mat-error *ngIf="password.errors?.required">{{'login-prebook.password-mandatory' | translate}}
                </mat-error>
                <mat-error
                  *ngIf="password.invalid && !password.errors?.required && !isAccountLocked && !isAccountDeActive && !isRecLogin">
                  {{'login.password-invalid'
                  | translate}}
                </mat-error>
                <mat-error *ngIf="isAccountLocked">{{'login.password-account-blocked' | translate}}
                </mat-error>
                <mat-error *ngIf="isAccountDeActive">{{'login.account-deactive' | translate}}
                </mat-error>
                <mat-error *ngIf="isRecLogin">{{'login.rec-account' | translate}}
                </mat-error>
              </mat-form-field>
              <div class="rememberMeContainer">
                <div>
                  <mat-checkbox formControlName="rememberMe" [checked]="rememberMe.value"
                    (change)="rememberMeCheck($event)">{{'login-prebook.rememberme' | translate}}</mat-checkbox>
                  <span class="infoIcon" (click)="isOpen = !isOpen" cdkOverlayOrigin
                    #trigger="cdkOverlayOrigin">i</span>
                  <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
                    [cdkConnectedOverlayOpen]="isOpen">
                    <div class="more-info">
                      {{'login-prebook.moreinfo' | translate}}
                    </div>
                  </ng-template>
                </div>
                <div>
                  <a class="linkurl fontclass-medium pull-right"
                    (click)="forgetPasswordClicked.emit(percent)">{{'login-prebook.forgot-password' | translate}}</a>
                </div>
              </div>
            </form>
            <div class="container-fluid py-3 captcha center">
              <div class="row mt-3">
                <div class="col-sm-12 mb-2">
                  <re-captcha
                    [hidden]="automationEmail.split(';').includes(this.Loginform.controls['loginName'].value.toLowerCase())"
                    (resolved)="captchaResolved($event)" id="recaptcha" name="recaptcha" ngDefaultControl
                    #recaptcha="ngModel" [(ngModel)]="token" required
                    [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)">
                  </re-captcha>
                  <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">
                    <div *ngIf="recaptcha.errors?.['required']">{{'login.captcha-required' | translate}}</div>
                  </div>
                </div>
                <mat-error *ngIf="showCapchaError">{{'login.capcha-required' | translate}}
                </mat-error>
              </div>
            </div>
          </div>

          <div id="login-wrapper" [formGroup]="forgetPasswordForm" class="input-min-spacing" style="width: 360px; "
            *ngIf="loader.uiMode==4">

            <ng-container *ngIf="loader.passwordExpired">
              <h4>{{'login.password-expired' | translate}}</h4>
            </ng-container>
            <ng-container *ngIf="!loader.passwordExpired">
              <h4>{{'login.modify-password' | translate}}</h4>
            </ng-container>
            <p>{{'login.modify-password-instructions' | translate}}</p>

            <mat-form-field class="mb-20">
              <mat-label>{{'login.password' | translate}}</mat-label>
              <input matInput name="password" (blur)="hideErrorBox()" (focus)="showErrorBox()" #newpassword
                [formControl]="resetpassword" (keyup)="passwordMatch()" id="password"
                [type]="hide ? 'password' : 'text'" (keydown.space)="disableSpace($event)" />
              <mat-error *ngIf="resetpassword.invalid">{{'login.valid-password' | translate}}</mat-error>
              <mat-icon matSuffix class="password_icon" (click)="hide = !hide">{{
                hide ? "visibility_off" : "visibility"
                }}</mat-icon>

              <app-password-policy (isValid)="checkIsValid($event)"></app-password-policy>

            </mat-form-field>
            <mat-form-field id="repeatpassword" class="mt-20">
              <mat-label>{{'login.repeat-password' | translate}}</mat-label>
              <input matInput name="repeatpassword" [formControl]="repeatresetpassword" (keyup)="passwordMatch()"
                type="password" [type]="isResetPasswordHide ? 'password' : 'text'"
                (keydown.space)="disableSpace($event)" (change)="passwordMatch()" />
              <mat-icon matSuffix class="password_icon" (click)="isResetPasswordHide = !isResetPasswordHide">{{
                isResetPasswordHide ? "visibility_off" : "visibility"
                }}</mat-icon>
              <mat-error *ngIf="repeatresetpassword.invalid">{{'login.password-match-error' | translate}}</mat-error>
            </mat-form-field>
            <div *ngIf="passwordsMatch && resetpassword.valid" id="passwordMatcherror">{{'login.password-match-error' |
              translate}}</div>
            <button mat-raised-button color="primary" id="resetBtn" type="submit" (click)="onResetPassword()"
              style="width: 100%" class="mt-40" [disabled]="isDisable">
              {{'login.reset-password' | translate}}
            </button>

          </div>

          <div id="login-wrapper" *ngIf="loader.uiMode==9" class="input-min-spacing">
            <div id="otpinner_container">
              <h3 class="otp-header">{{'login.otp-entermessage' | translate}}</h3>
              <mat-hint class="otp-hint">{{'login.otp-hint' | translate}}</mat-hint>
              <br>
              <ng-otp-input clas="otp" #ngOtpInput (onInputChange)="onOtpChange($event)"
                [config]="config"></ng-otp-input>
              <div class="otp-counter">
                <h5>{{'login.otp-time-remaining' | translate}} : {{display}}</h5>
                <button [disabled]="btndisabled" color="primary" type="submit" class="btn-login" (click)="resetOTP()">
                  {{'login.otp-resend' | translate}} </button>
              </div>
              <!-- <div class="otp-buttons">
                <button mat-raised-button color="primary" type="submit" class="btn-login" (click)="verifylogIn()">
                  {{'login.otp-verify' | translate}} </button>
              </div> -->
            </div>
          </div>
          <div id="checkmail_container" *ngIf="loader.uiMode==7">
            <img src="./assets/images/application-logos/svg/mail-sent-ic.svg" />
            <h4>{{'login.account-creation-confirmation' | translate}}</h4>
            <p>
              {{'login.mail-confirmation-instructions' | translate}} {{userEmail}}
              <span id="email">{{username}}</span>
            </p>
            <p>{{'login.note' | translate}}</p>
            <p>{{'login.activate-instruction' | translate}}</p>
            <p>
              {{'login.no-link' | translate}}
              <a class="linkurl" role="button" (click)="loader.sendactivationLink(userEmail)">{{'login.resend-link' |
                translate}}</a>
            </p>
            <span>{{'login.back' | translate}}<a class="linkurl" (click)="loader.uiMode=1">{{'login.Login' |
                translate}}</a></span>
          </div>
        </div>
      </ng-container>
      <ng-container class="float-left" *ngIf="(authType.value=='2') && loader.userRole == nurseRole">

        <ng-container *ngIf="!noRecordFound">
          <app-find-my-account (isVerified)="verified($event)" [fromAppointment]="true" *ngIf="!isVerified"
            [displayMap]="displayMap" (noRecordFound)="noRecordFoundFn($event)" (goToConfirm)="goToConfirm.emit($event)"
            (hideRadio)="HideRadioVal($event)">
          </app-find-my-account>
        </ng-container>
        <ng-conatiner *ngIf="noRecordFound">
          <app-register (Registered)="RegisterSuccessful($event)" [noRecordMode]="true" [searchData]="searchData"
            (activeStep)="getActiveStep($event)" [fromAppointment]="true" [displayMap]="displayMap"
            (backToLogin)="showLogin=true;showRegister=false;" (goToBack)="goBacktoSearch()"
            (hideRadio)="HideRadioVal($event)">
          </app-register>
        </ng-conatiner>
      </ng-container>


      <ng-container class="float-left" *ngIf="(authType.value=='2')  && loader.userRole != nurseRole">
        <ng-container *ngIf="!noRecordFound">
          <app-find-my-account (isVerified)="verified($event)" [fromAppointment]="true" *ngIf="!isVerified"
            [displayMap]="displayMap" (noRecordFound)="noRecordFoundFn($event)"
            (proceedLogin)="noRecordFound=false;proceedLogin()" (hideRadio)="HideRadioVal($event)">
          </app-find-my-account>
        </ng-container>
        <ng-container *ngIf="noRecordFound ==true;else recordFound">
          <app-register (Registered)="RegisterSuccessful($event)" [noRecordMode]="true" [searchData]="searchData"
            (activeStep)="getActiveStep($event)" [fromAppointment]="true" [displayMap]="displayMap"
            (backToLogin)="showLogin=true;showRegister=false;" (goToBack)="goBacktoSearch()"
            (hideRadio)="HideRadioVal($event)">
          </app-register>
        </ng-container>
        <ng-template #recordFound>
          <ng-conatiner>
            <app-register (Registered)="RegisterSuccessful($event)" [prefilMode]=isVerified [unique_id]="unique_id"
              [prefilData]="patientData" [otp]="otp" [verifyID]="verifyID" *ngIf="isVerified" [fromAppointment]="true"
              (activeStep)="getActiveStep($event)" [displayMap]="displayMap"
              (goToBack)="noRecordFound=false;isVerified=false;" (backToLogin)="showLogin=true;showRegister=false;"
              (hideRadio)="HideRadioVal($event)">
            </app-register>
          </ng-conatiner>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div class="action-btn" [ngClass]="{'mobile-booking':bookAppointment, 'justify-content-end':!showBackBtn}"
    *ngIf="!noRecordFound && (!isVerified && !isLoginReceptionist) && loader.flow_detail != constants.complete_booking_flow">
    <button mat-raised-button class="back-btn" *ngIf="showBackBtn" [ngClass]="{'mobile-btn':authType.value=='' }"
      type="button" (click)="goBack()">
      {{'back' | translate}}
    </button>

    <button mat-raised-button class="next-btn" type="button"
      *ngIf="authType.value=='1' && loader.userRole != nurseRole && loader.uiMode==1" (click)="logIn()">
      {{'login-prebook.login' | translate}}
    </button>

    <button mat-raised-button class="next-btn" type="button"
      *ngIf="authType.value=='1' && loader.userRole != nurseRole && loader.uiMode==9" (click)="verifylogIn()">
      {{'login.otp-verify' | translate}}
    </button>

    <button mat-raised-button class="next-btn" type="button"
      *ngIf="(authType.value=='2') && loader.userRole != nurseRole" (click)="findMyAccount()">
      {{'next' | translate}}
    </button>

    <button mat-raised-button class="next-btn" type="button"
      *ngIf="(authType.value=='2') && loader.userRole == nurseRole">
      {{'next' | translate}}
      (receptionist)
    </button>
  </div>

  <div class="action-btn" [ngClass]="{'mobile-booking':bookAppointment, 'justify-content-end':!showBackBtn}"
    *ngIf="loader.userRole == nurseRole && !noRecordFound">
    <button mat-raised-button class="back-btn" [ngClass]="{'mobile-btn':authType.value=='' }" type="button"
      (click)="goBack()">
      {{'back' | translate}}
    </button>


    <button mat-raised-button class="next-btn" type="button" (click)="findMyAccount()"
      *ngIf="(authType.value=='2') && loader.userRole == nurseRole">
      {{'next' | translate}}
    </button>
  </div>

  <div class="action-btn btn-right"
    [ngClass]="{'mobile-booking':bookAppointment,'action-btn-login':loader.loggedIn,'action-btn-guest':!loader.loggedIn}"
    *ngIf="!noRecordFound && (!isVerified && !isLoginReceptionist) && loader.flow_detail == constants.complete_booking_flow">
    <button mat-raised-button class="next-btn " type="button"
      *ngIf="authType.value=='1' && loader.userRole != nurseRole && loader.uiMode==1" (click)="logIn()">
      {{'login-prebook.login' | translate}}
    </button>

    <button mat-raised-button class="next-btn" type="button"
      *ngIf="authType.value=='1' && loader.userRole != nurseRole && loader.uiMode==9" (click)="verifylogIn()">
      {{'login.otp-verify' | translate}}
    </button>


  </div>
</div>