
import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { LoggerService } from './../logger/logger.service';

@Injectable()
/** Service to handle Error */
export class ErrorHandlerService {

    constructor(private readonly logger: LoggerService) { }

    /* #region Public Methods */
    /** Method to handle error and add it in logs */
    public handleError(httpError: any): Observable<HttpEvent<any>> {
        let errorMessage = `An error occurred: ${httpError.status} - ${httpError.statusText}`;
        if (httpError.error) {
            if (httpError.error.ExceptionMessage) {
                errorMessage = `${errorMessage} - ${httpError.error.ExceptionMessage}`;
            } else {
                errorMessage = httpError;
            }
        }
        this.logger.error(errorMessage);
        return observableThrowError(errorMessage);
    }
    /* #endregion */
}
