import { DatePipe, registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { BrowserModule, HammerModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { StorageModule } from "@ngx-pwa/local-storage";
import { TranslateModule } from "@ngx-translate/core";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { NgOtpInputModule } from "ng-otp-input";
import localeFr from '@angular/common/locales/fr';

import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from "ng-recaptcha";
import { CookieModule } from "ngx-cookie";
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from "ngx-toastr";
import { AppRoutingRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LocaleService } from "./app.locale.service";
import { CoreModule } from "./core";
import { AdminModule } from "./eClinical/admin/admin.module";
import { PatientModule } from "./eClinical/patient/patient.module";
import { ReceptionistModule } from "./eClinical/receptionist/receptionist.module";
// import { CalendarComponent } from './eClinical/admin/calendar/calendar.component';
import { EClinicalSharedModule } from "./eClinical/shared/shared.module";
import { CreateAccountComponent } from "./features/login/createAccount/createAccount.component";
import { PrivacyPolicySliderComponent } from "./features/login/privacy-policy-slider/privacy-policy-slider.component";
import { AboutUsComponent } from "./main/about-us/about-us.component";
import { ActivationpopupComponent } from "./main/activation-popup/activation-popup.component";
import { ConfirmPopupComponent } from "./main/confirm-popup/confirm-popup.component";
import { CustomMatPaginatorIntl } from "./main/custom-mat-paginator-intl";
import { AppLauncherComponent } from "./main/layout/app-launcher/app-launcher.component";
import { ThousandConverterPipe } from "./main/layout/app-launcher/thousand-converter.pipe";
import { SideNavComponent } from "./main/layout/side-nav/side-nav.component";
import { ToolBarComponent } from "./main/layout/tool-bar/tool-bar.component";
import { MobileDrawerComponent } from "./main/mobile-drawer/mobile-drawer.component";
import { NotificationPopUpComponent } from "./main/notification/notification-pop-up/notification-pop-up.component";
import { MaterialModule } from "./shared";
import { MainPipe } from "./shared/MainPipe.module";
import { SharedModule } from "./shared/shared.module";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { MatBadgeModule } from '@angular/material/badge';
import { LandingPageComponent } from "./features/landing-page/landing-page.component";
import { AuthModule } from "./eClinical/auth/auth.module";
import { NgxScrollTopModule } from "ngx-scrolltop";

@NgModule({
  declarations: [
    AppComponent,
    ToolBarComponent,
    SideNavComponent,
    AppLauncherComponent,
    ConfirmPopupComponent,
    ActivationpopupComponent,
    ThousandConverterPipe,
    AboutUsComponent,
    NotificationPopUpComponent,
    LandingPageComponent,
    CreateAccountComponent,
    PrivacyPolicySliderComponent,
    MobileDrawerComponent,
    // CalendarComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    AppRoutingRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AdminModule,
    PatientModule,
    MatBadgeModule,
    AuthModule,
    ReceptionistModule,
    CookieModule.withOptions(),
    ToastrModule.forRoot(),
    StorageModule.forRoot({ IDBNoWrap: false }),
    HammerModule,
    EClinicalSharedModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    BrowserModule,
    // ngx-translate and the loader module
    HttpClientModule,
    CoreModule,
    NgOtpInputModule,
    MainPipe,
    YouTubePlayerModule,
    NgxScrollTopModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    CookieService,
    DatePipe,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: "6Ldbi_ciAAAAAHO6PjUxLLtQWnAkK0dGnbwig9Xw",
      } as RecaptchaSettings,
    },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    LocaleService,
  ],
})
export class AppModule {

  constructor() {
    registerLocaleData(localeFr, 'fr');
  }
}

// export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
//   return new TranslateHttpLoader(http);
// }
