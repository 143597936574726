import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { LoaderService } from '../../../main';
import { FindMemberComponent } from './find-member/find-member.component';
import { ManageAccessComponent } from './manage-access/manage-access.component';
import { PatientService } from '../../services/patient.service';
import { RMData } from './relative-member-data.model';
import { LoginServiceService } from '../../services/login-service.service';
import { UploadDocumentComponent } from '../documents/upload-document/upload-document.component';
import { SpinnerService } from '../../../core/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { PatientDocument } from '../documents/patient-document.model';
import { LocaleService } from '../../../app.locale.service';
import { RelativeMemberService } from '../../services/relative-member.service';
import { CommonApiService } from '../../services/common-api.service';

@Component({
  selector: 'app-relative-members',
  templateUrl: './relative-members.component.html',
  styleUrls: ['./relative-members.component.scss']
})
export class RelativeMembersComponent implements OnInit {
  public uploadSliderContainer: any;
  patientId = '';
  public RM_DATA: any[];
  avilableDocumentTypes: any[] = [];
  docs: any;
  uploadedDocs: any;
  refresh = true;
  ELEMENT_DATA: any[] = [];
  selectedDoc: number;
  dataSource: any = [];
  protected patientDocsUploaded = 0;
  optionalMutualCertificateIdentifier = "Mutual certificate(";
  mandatoryMutualCertificateIdentifier = "Mutual certificate";
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
    .pipe(
      tap((value) => console.log(value)),
      distinctUntilChanged()
    );
  sliderFormData: any;
  form: any;
  showExpiry: boolean;
  action: boolean;
  documentTypeList: any;
  constructor(private breakpointObserver: BreakpointObserver,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private rmService: RelativeMemberService, public adminService: CommonApiService,
    public loader: LoaderService,
    public loginService: LoginServiceService,
    private spinner: SpinnerService,
    private translate: TranslateService,
    private localeService: LocaleService,
    public patientService: PatientService) {
    this.breakpoint$.subscribe(() => this.breakpointChanged());
    this.ELEMENT_DATA = new Array<PatientDocument>();
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);

  }

  ngOnInit(): void {
    this.spinner.show(true);
    this.patientId = JSON.parse(sessionStorage.getItem('userDetails')).id;
    this.getRelativeMemberData(this.patientId);
    this.loader.onFormClose().subscribe(() => {
      this.getRelativeMemberData(this.patientId);
    });
    this.getBirthCities();
  }

  getBirthCities() {
    this.adminService.getBirthCities().subscribe(
      (data: any) => {
        this.adminService.birthCitiesList.next(data)
      })
  }

  private breakpointChanged() {
    if (
      this.breakpointObserver.isMatched(
        "(max-width: 1050px) and (min-width: 601px)"
      )
    ) {
      this.uploadSliderContainer = 640;
    } else if (this.breakpointObserver.isMatched("(max-width: 600px)")) {
      this.uploadSliderContainer = "100%";
    } else {
      this.uploadSliderContainer = 664;
    }
  }

  getRelativeMemberData(patientId: string) {
    this.spinner.show(true);
    this.rmService.getRmData(patientId).subscribe((data: RMData[]) => {
      this.RM_DATA = data;
      this.spinner.show(false);

    }, () => {
      this.spinner.show(false);
    })
  }

  addMember() {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(FindMemberComponent);
    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "60%" : "100%",
      { isEdit: false }
    );
  }

  getMemberInfo(record: any) {
    if (!record?.is_primary) {
      this.loginService.getRMDetails(record.login_id, record.rm_id).subscribe((resp) => {
        this.editMember(resp)
      });
    }
  }
  editMember(rmInfo: any) {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(FindMemberComponent);
    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "60%" : "100%",
      { isEdit: true, rmInfo: rmInfo, }
    );
  }

  manageAccess(record: any) {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(ManageAccessComponent);
    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "60%" : "100%",
      { isEdit: false, rmInfo: record, step: 0 }
    );
  }
  getBackgroundClass(isPrimary: boolean) {
    return isPrimary ? 'titleWrapBox cp' : 'titleWrapBox-PA';
  }
  getDocText(insurance: string, optionalInsurance: string, idCard: string, age: number, record: RMData, minorPassport: boolean, birthCirtificate?: string) {
    var count = 0;
    var totalCount = 2;
    if (insurance.length !== 0)
      count++;
    if (idCard.length !== 0)
      count++;
    if (age < 18 && birthCirtificate.length !== 0)
      count++;
    if (age < 18 && minorPassport == false)
      totalCount++;

    (optionalInsurance?.length !== 0 && totalCount == count) ? record.areAllDocsUploaded = true : record.areAllDocsUploaded = false;
    return count + " " + this.translate.instant('list-RM.of') + " " + totalCount
  }
  getAgeText(years: number, months: number, dob: string) {
    if (years == 0 && months != 0) {
      return "(" + months + this.translate.instant('list-RM.months') + ")"
    }
    else if (years == 0 && months == 0) {
      var days = this.calculateDays(dob)
      return "(" + days + this.translate.instant('list-RM.days') + ")"
    }
    else {
      return "(" + years + this.translate.instant('list-RM.years') + ")"
    }
  }

  calculateDays(dob: any) {
    var date = new Date(dob) as Date;
    const currentDate = new Date();
    const diffTime = Math.abs(date.getTime() - currentDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }
  redirectToBookAppointment(rm_id: any) {
    this.loader.isPreLoginFLow = true;
    this.loader.rmMode = true;
    this.loader.rm_id = rm_id;
    this.loader.id = rm_id;
    this.loader.highlightLeftMenu(['patient/book-appointment']);
  }
  redirectToMyAppointments() {
    this.loader.isPreLoginFLow = true;
    this.loader.redirectRelMem = true;
    this.loader.highlightLeftMenu(['patient/my-appointment']);
  }
  redirectToDocuments() {
    this.loader.isPreLoginFLow = true;
    this.loader.redirectRelMem = true;
    this.loader.highlightLeftMenu(['patient/documents']);
  }
  uploadDocument(patientId?: any, loginId?: any, data?: any, docType?: any,) {
    this.getPatientDocument(patientId, loginId, docType, data)
  }
  public getPatientDocument(patientid: any, loginId: any, docType: any, data: any) {
    this.refresh = false;
    this.spinner.show(true);
    this.patientService
      .getPatientDocuments(patientid)
      .subscribe((documents: any) => {
        if (documents != null) {
          this.ELEMENT_DATA = documents;
          this.patientDocsUploaded = documents.length;
        } else {
          this.ELEMENT_DATA = [];
        }
        this.dataSource = [];
        this.dataSource = this.ELEMENT_DATA;
        this.patientService
          .getRequiredDocumentByPatientId(patientid, loginId, true)
          .subscribe((documents: any) => {
            documents = documents.documents;
            if (documents != null) {
              this.ELEMENT_DATA = documents.filter((item: any) => item["eng_key_name"].indexOf(this.optionalMutualCertificateIdentifier) < 0);
              for (let key of documents) {
                key["document_type"] = key["key_name"];
              }
            } else {
              this.ELEMENT_DATA = [];
            }
            this.docs = documents;
            this.uploadedDocs = this.dataSource;
            if (!this.dataSource.some((item: any) => item["document_path"] && item["document_type"].indexOf(this.mandatoryMutualCertificateIdentifier) >= 0)) {
              this.docs = this.docs.filter((item: any) => item["eng_key_name"].indexOf(this.optionalMutualCertificateIdentifier) < 0);
            }
            this.dataSource = [...this.dataSource, ...this.ELEMENT_DATA];
            this.dataSource.sort((a: any, b: any) =>
              this.translate
                .instant(a.document_type)
                .localeCompare(this.translate.instant(b.document_type))
            );
            if (this.loader.redirectDocuments) {
              const index = this.docs.findIndex((item: any) => item.id == this.loader.redirectDocuments);
              if (index >= 0) {
                this.selectDocument(this.docs[index]);
              }
              this.loader.redirectDocuments = null;
            }
            this.spinner.show(false);
            this.refresh = true;
            this.avilableDocumentTypes = this.docs;
            if (docType) {
              this.avilableDocumentTypes = docType;
            } else {
              data.patient_id = patientid;
            }
            data.EditAction = 0;
            data.avilableDocumentTypes = this.avilableDocumentTypes;
            data.uploadedDocs = this.uploadedDocs;
            const componentFactory =
              this.componentFactoryResolver.resolveComponentFactory(
                UploadDocumentComponent
              );
            this.loader.addDynamicComponent(
              componentFactory,
              this.uploadSliderContainer,
              data
            );
          });

      });
  }

  selectDocument(data?: any) {
    data.Action = 1;
    data.EditAction = 0;
    data.patient_id = this.patientId;
    data.avilableDocumentTypes = this.docs;
    data.uploadedDocs = this.uploadedDocs;
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        UploadDocumentComponent
      );
    this.loader.addDynamicComponent(
      componentFactory,
      this.uploadSliderContainer,
      data
    );
  }

  removeAccess(record: any) {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(ManageAccessComponent);
    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "60%" : "100%",
      { isEdit: false, rmInfo: record, step: 3 }
    );
  }
}

