<div class="find-patient-container">
  <header class="pull-left">
    <h3 class="pull-left">{{'appointments.find-patient' | translate}}</h3>
    <!-- <mat-form-field class="search-input">
      <input matInput #searchInput [formControl]="seacrchInput"
        placeholder="{{'appointments.qr-code-search' | translate}}" name="search" id="search"
        (keypress)="filterPatient($event)" />
      <div *ngIf="!searchInput.value" (click)="scanQR()" class="bar-code-icon"><span
          class="icon-ic-scan-barcode"></span></div>
      <div *ngIf="searchInput.value" (click)="clearSearch(); reset()" class="clear">
        <p>{{'appointments.clear-search' | translate}}</p>
      </div>
    </mat-form-field> -->
    <div class="searchIcon">
      <a (click)="openSearch()"><span class="icon-ic-Search"></span></a>
      <a (click)="clearSearch()">Clear Search</a>

    </div>
  </header>

  <div class="container advancedSearchContent" *ngIf="openSearchFilter">
    <div class="row controls-row">
      <div class="control"> <mat-form-field class="form-field">
          <mat-label class="label-float">{{'appointments.first-name' | translate}}</mat-label>
          <input matInput [formControl]="firstname" (keydown.enter)="findPatientList()"
            placeholder="{{'appointments.first-name' | translate}}" name="fnsearch" id="fnsearch" />
        </mat-form-field></div>
      <div class="control"> <mat-form-field class="form-field">
          <mat-label class="label-float">{{'appointments.last-name' | translate}}</mat-label>
          <input matInput [formControl]="lastname" (keydown.enter)="findPatientList()"
            placeholder="{{'appointments.last-name' | translate}}" name="lnsearch" id="lnsearch" />
        </mat-form-field></div>
      <div class="control"> <mat-form-field class="form-field">
          <mat-label class="label-float">{{'appointments.birth-name' | translate}}</mat-label>
          <input matInput [formControl]="birthname" (keydown.enter)="findPatientList()"
            placeholder="{{'appointments.birth-name' | translate}}" name="bnsearch" id="bnsearch" />
        </mat-form-field></div>
      <div class="control"> <app-datepicker class="bg-white" id="dateofBirth" name="dateofBirth"
          labelText="{{'createAccount.dob' | translate}}" (keydown.enter)="findPatientList()" [dateInput]="dateOfBirth"
          [maxDate]="maxDate">
        </app-datepicker></div>
      <div class="control"> <mat-form-field class="form-field">
          <mat-label class="label-float">{{'appointments.ssn' | translate}}</mat-label>
          <input matInput [formControl]="ssn" placeholder="{{'appointments.ssn' | translate}}"
            (keydown.enter)="findPatientList()" name="ssnsearch" id="ssnsearch" />
        </mat-form-field></div>
      <div class="control">
        <mat-form-field class="form-field">
          <mat-label>{{'createAccount.email' | translate}}</mat-label>
          <input type="email" matInput name="Email" name="search-email" (keydown.enter)="findPatientList()"
            id="search-email" maxlength="60" id="Email" [formControl]="email" />
        </mat-form-field>
      </div>
      <div class="control">
        <mat-form-field class="form-field">
          <mat-label>{{'createAccount.phone' | translate}}</mat-label>
          <input type="text" matInput name="phone" inputmode="numeric" name="search-phone"
            (keypress)="keyPressNumbers($event)" id="search-phone" (keydown.enter)="findPatientList()" maxlength="15"
            id="phone" [formControl]="phoneNumber" />

        </mat-form-field>
      </div>
      <div class="control">
        <mat-form-field class="form-field">
          <mat-label>{{'appointments.qr-code-search' | translate}}</mat-label>
          <input matInput #searchInput [formControl]="seacrchInput" (keydown.enter)="findPatientList()" name="search"
            id="search" />
          <div *ngIf="!searchInput.value" (click)="scanQR()" matSuffix class="bar-code-icon"><span
              class="icon-ic-scan-barcode"></span></div>
        </mat-form-field>
      </div>


    </div>

    <div class="row">
      <div class="control">
        <button mat-button class="ghost searchButton" (click)="findPatientList()">{{'appointments.btn-search'
          | translate}}</button>
      </div>
    </div>
  </div>


  <!-- <button mat-button color="accent" class="ghost advanceSearchCloseButton onlyWebView"><span
        class="icon-ic_arrow_collapse"></span></button>
    <button mat-button color="accent" class="ghost searchButton">{{'appointments.btn-search'
      | translate}}</button> -->
  <table *ngIf="refresh" class="ecl-table" width="100%" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef> {{'appointments.first-name' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="flex-td">
        <div class="avatar onlyMobile"><span class="titleWrapBox">{{element.first_name.charAt(0)}}</span></div>
        <span class="title onlyDesktop" *ngIf="element.first_name">{{element.first_name}}</span>
        <div class="mobile-td">
          <span class="title" *ngIf="element.first_name"><span>{{element.first_name}} {{element.last_name}}
              {{element.birth_name}}</span></span>
          <span class="subText">SSN: {{element.ssn}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef> {{'appointments.last-name' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="hide-column"><span>{{element.last_name}} </span></td>
    </ng-container>
    <ng-container matColumnDef="birthName">
      <th mat-header-cell *matHeaderCellDef> {{'appointments.birth-name' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="hide-column"><span>{{element.birth_name}} </span></td>
    </ng-container>
    <ng-container matColumnDef="dateOfBirth">
      <th mat-header-cell *matHeaderCellDef> {{'appointments.dob' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="flex2-row">
        <div class="row-flex">
          <span class="colT"><span class="icon-ic-date onlyMobile"></span>{{element.dob | date:'dd/MM/yyyy'}}</span>
          <span class="colT onlyMobile"><span class="icon-ic-gender"></span>{{element.gender| translate}}</span>
        </div>
        <!-- <div class="mobile-action">
          <button mat-button color="accent" class="ghost" type="button">Confirm Arrival</button>
        </div> -->
      </td>
    </ng-container>
    <ng-container matColumnDef="ssn">
      <th mat-header-cell *matHeaderCellDef> {{'appointments.ssn' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="hide-column"><span>{{element.ssn}} </span></td>
    </ng-container>
    <ng-container matColumnDef="gender">
      <th mat-header-cell *matHeaderCellDef> {{'appointments.gender' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="hide-column"> <span>{{element.gender | translate}}</span></td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="wp-10"></th>
      <td mat-cell *matCellDef="let element" class="force-display">
        <div class="onlyDesktop">
          <button class="mat-tbl-icon deleteEntity_btn ml-5" mat-icon-button
            (click)="redirectReceiptionistBooking(element)">
            <span class="icon-icon-my-appointment book-icon"
              matTooltip="{{'patient-info.book-new-appointment' | translate}}" matTooltipClass="tooltip-style"></span>
          </button>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="noRecord">
      <td colspan="6" mat-footer-cell *matFooterCellDef>
        <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
        <div class="desc">
          <p>{{'appointments.empty' | translate}}</p>
          <p>{{'appointments.search-patient' | translate}}</p>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row (click)="rowClicked(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
    <ng-template [ngIf]="dataSource && dataSource.length === 0">
      <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
    </ng-template>
  </table>

  <mat-paginator *ngIf="totalRecords>0" [length]="totalRecords" [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons="true" (page)="onPageChange($event)">
  </mat-paginator>
</div>