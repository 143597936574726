import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HeroBannerWrapper, RequestData } from '../landing-page-config.model';
import { MessageService } from '../../../../main';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../../../../core';

@Component({
  selector: 'app-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss']
})
export class HeroBannerComponent implements OnInit {
  @Input() heroBanner: HeroBannerWrapper;
  @Output() cancelClicked = new EventEmitter();
  @Output() updateData = new EventEmitter<RequestData>();
  public selectedLangTabIndex: number = 0;
  public field1MaxLength: number = 32;
  public field2MaxLength: number = 350;
  public field3MaxLength: number = 350;
  public field4MaxLength: number = 11;
  constructor(private fb: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private spinner: SpinnerService) { }


  public enForm: FormGroup = this.fb.group({
    top_blue_bar_message: ['', [Validators.required, Validators.maxLength(this.field1MaxLength)]],
    title: ['', [Validators.required, Validators.maxLength(this.field2MaxLength)]],
    sub_title: ['', [Validators.required, Validators.maxLength(this.field3MaxLength)]],
    video_url: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_-]{11}$/)]]
  });

  public frForm: FormGroup = this.fb.group({
    top_blue_bar_message: ['', [Validators.required, Validators.maxLength(this.field1MaxLength)]],
    title: ['', [Validators.required, Validators.maxLength(this.field2MaxLength)]],
    sub_title: ['', [Validators.required, Validators.maxLength(this.field3MaxLength)]],
    video_url: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_-]{11}$/)]]
  });

  ngOnInit(): void {
    this.bindData();
  }

  ngOnChanges() {
    this.bindData();
  }

  public bindData() {
    const enFormData = {
      top_blue_bar_message: this.heroBanner.en.top_blue_bar_message,
      title: this.heroBanner.en.title,
      sub_title: this.heroBanner.en.sub_title,
      video_url: this.heroBanner.en.video_url,
    };
    this.enForm.patchValue(enFormData);

    const frFormData = {
      top_blue_bar_message: this.heroBanner.fr.top_blue_bar_message,
      title: this.heroBanner.fr.title,
      sub_title: this.heroBanner.fr.sub_title,
      video_url: this.heroBanner.fr.video_url,
    };
    this.frForm.patchValue(frFormData);
  }

  save() {
    this.enForm.markAllAsTouched();
    this.frForm.markAllAsTouched();
    if (this.enForm.valid && this.frForm.valid) {
      this.openPopup();
    }
    else {
      if (this.selectedLangTabIndex == 0 && this.enForm.valid && !this.frForm.valid) {
        this.selectedLangTabIndex = 1;
      }
      else if (this.selectedLangTabIndex == 1 && !this.enForm.valid && this.frForm.valid) {
        this.selectedLangTabIndex = 0;
      }
    }
  }

  public onLangTabChange(index: number) {
    this.selectedLangTabIndex = index;
  }

  public openPopup() {
    let popupRef = this.messageService
      .confirm(
        this.translateService.instant('landing-page-config.save-confirm-description'),
        this.translateService.instant('landing-page-config.save-title'),
        this.translateService.instant('landing-page-config.yes'),
        this.translateService.instant('landing-page-config.no')
      );

    popupRef.subscribe((actionResult) => {
      if (actionResult) {
        this.spinner.show(true);
        let payload = {
          "tab_name": "herobanner",
          "page_data": JSON.stringify({
            "en": this.enForm.value,
            "fr": this.frForm.value
          }),
          "image_data": [
            {
              "image_url": "",
              "image_data": ""
            }
          ]
        }
        this.updateData.emit(payload);
      }
    });
  }
  syncWithFrValue() {
    this.frForm.controls["video_url"].setValue(this.enForm.controls['video_url'].value);
  }

  syncWithEnValue() {
    this.enForm.controls["video_url"].setValue(this.frForm.controls['video_url'].value);
  }
}
