import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../app.locale.service';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    constructor(private readonly translateService: TranslateService,
        public localeService: LocaleService) {
        super();
        this.getAndInitTranslations();
        this.translateService.use(this.localeService.localeLang);
        this.translateService.setDefaultLang(this.localeService.localeLang);
    }

    getAndInitTranslations(): void {
        const paginatorIntl = new MatPaginatorIntl();
        paginatorIntl.itemsPerPageLabel = this.translateService.instant(' ');
        paginatorIntl.firstPageLabel = this.translateService.instant('Common.Paginator.FirstPageLabel');
        paginatorIntl.lastPageLabel = this.translateService.instant('Common.Paginator.LastPageLabel');
        paginatorIntl.previousPageLabel = this.translateService.instant('Common.Paginator.PreviousPageLabel');
        paginatorIntl.nextPageLabel = this.translateService.instant('Common.Paginator.NextPageLabel');
        paginatorIntl.changes.next();
    }

    /** Overriding the property of MatPaginatorIntl to update range label format */
    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 / ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} / ${length}`;
    };
}
