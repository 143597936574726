import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LanguageService } from '../../core';
const DATE_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY'
    },
    display: {
        dateInput: 'L',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'L',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
    ]
})
export class TimePickerComponent implements OnInit {
    /* #region Fields */
    public hourControl = new FormControl();
    public minuteControl = new FormControl();
    public filteredHours: Observable<string[]>;
    public filteredMinutes: Observable<string[]>;
    public highlighted: boolean;
    public dateTimeValue = new FormControl(moment());

    @Input() isDisable = false;
    @Input() public datePlaceholder = 'Common.DateTimePicker.DateSearchPlaceholder';
    @Input() public hourPlaceholder = 'Common.DateTimePicker.HourSearchPlaceholder';
    @Input() public minutePlaceholder = 'Common.DateTimePicker.MinuteSearchPlaceholder';
    @Input() public isDisplayReset = true;
    @Input() public dateTimeValueInput = new FormControl(moment());
    @Input() public min: Date;
    @Input() public max: Date;
    @Output() public dateTimeSelected = new EventEmitter();

    @ViewChild('hourAutoInput') hourAutoInputElement: ElementRef;
    @ViewChild('minuteAutoInput') minuteAutoInputElement: ElementRef;
    @ViewChild('hourAutoInput', { read: MatAutocompleteTrigger }) hourAutoElement: MatAutocompleteTrigger;
    @ViewChild('minuteAutoInput', { read: MatAutocompleteTrigger }) minuteAutoElement: MatAutocompleteTrigger;
    @ViewChild('dateInput') dateInputElement: ElementRef;
    @ViewChild('picker') datePicker: MatDatepicker<Date>;

    private readonly hours: Array<string>;
    private readonly minutes: Array<string>;
    /* #endregion */

    constructor(private readonly languageService: LanguageService, private readonly _adapter: DateAdapter<any>) {
        this.hours = Array.from({ length: 24 }, (_v, k) => (k > 9 ? `${k}` : `0${k}`));
        this.minutes = Array.from({ length: 60 }, (_v, k) => (k > 9 ? `${k}` : `0${k}`));
        // MomentDateAdapter is recommended by Angular to handle localization in Date picker
        // But Locale 'en-150' is not available in Moment js (https://github.com/moment/moment/tree/develop/src/locale)
        // To handle 'en-150'(english european) Locale, we set here with 'en-ie'(english ireland) which has same pattern as 'en-150'
        const locale = this.languageService.getLanguage().Locale;
        if (locale && locale.toLowerCase() === 'en-150') {
            this._adapter.setLocale('en-ie');
        }
    }

    /* #region Public Methods */
    public ngOnInit(): void {
        if (this.dateTimeValueInput && this.dateTimeValueInput.value !== undefined && this.dateTimeValueInput.value !== null) {
            this.setDateValue(false);
        } else {
            this.setDateValue(true);
        }

        this.dateTimeValueInput.valueChanges.subscribe(() => {
            this.setDateValue(false);
        });
    }

    /** Method to update DateTime when hours has been selected from default existing options */
    public onHourSelected(): void {
        setTimeout(() => {
            this.updateDateTimeValue();
            this.minuteAutoInputElement.nativeElement.focus();
        });
    }

    /** Method to update DateTime when hours has been changed through autocomplete in UI */
    public onHourChanged(): void {
        setTimeout(() => {
            this.updateDateTimeValue();
            this.minuteAutoInputElement.nativeElement.focus();
            this.hourAutoElement.closePanel();
            this.minuteAutoElement.openPanel();
        });
    }

    /** Method to update DateTime when minutes has been selected from default existing options */
    public onMinuteSelected(): void {
        this.updateDateTimeValue();
        this.minuteAutoElement.closePanel();
    }

    /** Method to update DateTime when minutes has been changed through autocomplete in UI */
    public onMinuteChanged(): void {
        this.updateDateTimeValue();
        this.minuteAutoElement.closePanel();
    }

    /** Method to update DateTime and focus hours field after changing the Date */
    public onDatePicked(event: MatDatepickerInputEvent<Date>): void {
        if (event.value != null) {
            setTimeout(() => {
                this.hourAutoInputElement.nativeElement.focus();
            });
        }
        this.updateDateTimeValue();
    }

    /** Method to open date picker on focusing date input field */
    public onDateInputFocused(): void {
        this.datePicker.open();
        setTimeout(() => {
            this.highlighted = true;
        });
    }

    /** Method to open date picker on clicking date input field */
    public onDateFieldClicked(): void {
        this.datePicker.open();
        this.highlighted = true;
    }

    /** Method to emit output to parent component on selecting hours/minutes */
    public onAutoCompleteClose(): void {
        setTimeout(() => {
            this.emitSelecetdValue();
        });
    }

    /** Method to set or reset the values of date, hours and minutes in the field based on the input passed to component */
    public setDateValue(reset: boolean): void {
        if (this.dateTimeValueInput && this.dateTimeValueInput.value !== undefined
      && this.dateTimeValueInput.value !== null ) {
            this.dateTimeValueInput.value.set('second', 0);
        }
        this.dateTimeValue.setValue(this.dateTimeValueInput.value);

        if (reset === true) {
            this.dateTimeValueInput.setValue(moment().set('second', 0));
            this.dateTimeValue.setValue(this.dateTimeValueInput.value);
            this.emitSelecetdValue();
        }

        this.setHourMinuteValue();
        this.setHourFilter();
        this.setMinuteFilter();
    }
    /* #endregion */

    /* #region Private Methods */
    /** Method to update DateTime value as per the specific format */
    private updateDateTimeValue(): void {
        const regexpHour = /^(2[0-3]|[01]?[0-9])$/g;
        const regexpMinute = /^[0-5]?[0-9]$/g;
        const date = this.dateTimeValue.value;
        let hour = this.hourControl.value;
        let minute = this.minuteControl.value;

        if ((hour !== null && !regexpHour.test(hour)) || hour == null) {
            hour = '00';
            this.hourControl.setValue('00');
        }

        if ((minute !== null && !regexpMinute.test(minute)) || minute == null) {
            minute = '00';
            this.minuteControl.setValue('00');
        }

        if (date != null) {
            const val = moment(date).set('hour', +hour).set('minute', +minute).set('second', 0);
            this.dateTimeValue.setValue(val);
            this.dateTimeValueInput.setValue(this.dateTimeValue.value);
        } else {
            const val = moment().set('hour', +0).set('minute', +0).set('second', 0);

            this.dateTimeValue.setValue(val);
            this.dateTimeValueInput.setValue(this.dateTimeValue.value);
        }
    }

    /** Method to set Hours and Minutes in specific format - two digit fields */
    private setHourMinuteValue(): void {
        let hour = 0;
        let min = 0;

        if (this.dateTimeValueInput && this.dateTimeValueInput.value !== undefined && this.dateTimeValueInput.value !== null
       && this.dateTimeValueInput.value.isValid()) {
            hour = +this.dateTimeValueInput.value.get('hour');
            min = +this.dateTimeValueInput.value.get('minute');
            this.hourControl.setValue((hour > 9 ? `${hour}` : `0${hour}`));
            this.minuteControl.setValue((min > 9 ? `${min}` : `0${min}`));
        } else {
            this.hourControl.setValue('');
            this.minuteControl.setValue('');
        }
    }

    /** Method to filter hours based on autocomplete search input */
    private hourFilter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.hours.filter(option => option.toLowerCase().includes(filterValue));
    }

    /** Method to filter minutes based on autocomplete search input */
    private minuteFilter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.minutes.filter(option => option.toLowerCase().includes(filterValue));
    }

    /** Listener method on hours input field to filter hours when values changes */
    private setHourFilter(): void {
        this.filteredHours = this.hourControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this.hourFilter(value))
            );
    }

    /** Listener method on hours input field to filter hours when values changes */
    private setMinuteFilter(): void {
        this.filteredMinutes = this.minuteControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this.minuteFilter(value))
            );
    }

    /** Method to emit output after selection of fields */
    private emitSelecetdValue(): void {
        if (this.hourAutoElement.panelOpen !== undefined && this.minuteAutoElement.panelOpen !== undefined
      && !this.hourAutoElement.panelOpen && !this.minuteAutoElement.panelOpen) {
            this.dateTimeSelected.emit(this.dateTimeValueInput.value);
        }
    }
    /* #endregion */
}
