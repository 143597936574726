<div class="register-result-container">
  <header class="pull-left">
    <h3 class="pull-left">
      <span class="icon-ic-back" (click)="selectedPageIndex = 0" *ngIf="selectedPageIndex > 0"></span>
      {{'register-result.register-result' | translate}}
    </h3>
  </header>
  <div class="form-cover" *ngIf="selectedPageIndex === 0 else result">
    <div class="scan-cover mb-20">
      <label>{{'register-result.scan-test-kit-code' | translate}}</label>
      <div (click)="scanQR()" class="image-cover">
        <span class="icon icon-ic-scan-barcode"></span>
      </div>
    </div>
    <mat-form-field>
      <mat-label class="label-float">{{'register-result.scan-test-kit-code' | translate}}</mat-label>
      <input matInput name="testKitId" id="testKitId" [(ngModel)]="testKitId" />
      <mat-hint class="no-kit-id-error"
        *ngIf="testKitId?.length == 0 && testKitScanerror">{{'register-result.barcode-error' | translate}}</mat-hint>
    </mat-form-field>
    <div class="actions mt-30 submit">
      <button mat-raised-button color="primary" type="submit" (click)="submitTestKitId()">{{'register-result.submit' |
        translate}}</button>
    </div>
  </div>
  <ng-template #result>
    <div class="main-container">
      <mat-form-field>
        <mat-label class="label-float">{{'register-result.scan-test-kit-code' | translate}}</mat-label>
        <input matInput name="testKitId" id="testKitId" [(ngModel)]="testKitId" readonly />
      </mat-form-field>
      <ng-template [ngIf]="selectedPageIndex === 1">
        <div class="selected-value">
          <div class="flex-td">
            <div class="avatar onlyMobile"><span
                class="titleWrapBox">{{selectedPatient.first_name.charAt(0).toUpperCase()}}</span></div>
            <div class="mobile-td">
              <span class="title"><span>{{selectedPatient.first_name + " " + selectedPatient.last_name}}</span></span>
              <span class="subText">{{'register-result.ssn' | translate}}<span class="light pl-0">:
                  {{selectedPatient.ssn}}</span></span>
            </div>
          </div>
          <div class="flex2-row">
            <div class="row-flex">
              <span class="colT"><span class="icon-ic-date onlyMobile"></span>{{selectedPatient.dob | date :
                "dd/MM/yyyy"}}</span>
              <span class="colT onlyMobile"><span class="icon-ic-gender"></span>{{selectedPatient.gender_en |
                translate}}</span>
            </div>
          </div>
        </div>
      </ng-template>
      <div class="info-cover">
        <h3>{{'register-result.registered-test-kit-info' | translate}}</h3>
        <div class="twoColumnGrid single gap">
          <div class="infoTabs pl-30">
            <span class="icon-ic-date font-l"></span>
            <p class="form-label">{{'register-result.registration-time' | translate}}</p>
            <div class="form-value">{{selectedPatient.slot_time | date : "dd/MM/yyyy"}} | {{selectedPatient.slot_time |
              date : "HH:mm"}}</div>
          </div>
          <div class="infoTabs pl-30">
            <span class="icon-ic-test font-l"></span>
            <p class="form-label">{{'register-result.test-details' | translate}}</p>
            <div class="form-value">{{selectedPatient.test_long_name}}</div>
          </div>
        </div>
      </div>
      <div class="scan-cover mt-20 form-layout">
        <label>{{'register-result.take-sample-photo' | translate}}</label>
        <div *ngIf="!imageCapture" (click)="captureImage()" class="pointCursor dropzone only-mobile" appFileDrop
          (filesDropped)="handleFilteredFileInput($event)" #dropZone class="image-cover">
          <span *ngIf="!imageCapture" class="icon icon-ic-click-picture"></span>
        </div>
        <webcam [height]="300" [width]="300" *ngIf="imageCapture && !imageCaptured" class="only-mobile camera-container"
          [trigger]="triggerObservable" (imageCapture)="handleImage($event)" (initError)="handleInitError($event)"
          [imageQuality]="1"></webcam>
        <img class="only-mobile" *ngIf="imageCapture && imageCaptured" [src]="prescriptionData" />
      </div>
      <div *ngIf="imageCapture" class="actions mb-10 capture-container">
        <button *ngIf="!imageCaptured" mat-button color="accent" (click)="triggerSnapshot()" class="ghost"
          type="submit">{{'upload-pic.capture' | translate}}</button>
        <button *ngIf="imageCaptured" mat-button color="accent" (click)="retakeSnapshot()" class="ghost"
          type="submit">{{'upload-pic.retake' | translate}}</button>
      </div>
      <div class="actions">
        <button class="positive" (click)="openConfirmation('Negative')" mat-raised-button color="primary"
          type="submit"><span class="icon-ic-add"></span>{{'register-result.negative' | translate}}</button>
        <button class="negative" (click)="openConfirmation('Positive')" mat-raised-button color="primary"
          type="submit"><span class="icon-ic-add"></span>{{'register-result.positive' | translate}}</button>
      </div>
      <div class="actions mt-20">
        <button mat-button color="accent" (click)="onConfirm('Invalid')" class="ghost"
          type="submit">{{'register-result.invalid' | translate}}</button>
      </div>
    </div>
  </ng-template>

</div>