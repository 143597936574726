
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '../../core';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService {

    constructor(private http: HttpClient, private readonly appSettings: AppSettingsService) { }

    getVerifyPassword(inputValue: any) {
        return this.http.post(this.appSettings.authBaseUrl + 'password/verify', JSON.stringify(inputValue));
    }
    putUpdatePassword(inputValue: any) {
        return this.http.put(this.appSettings.authBaseUrl + 'password', JSON.stringify(inputValue));
    }

}
