import { DatePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MapGeocoder } from "@angular/google-maps";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subscription, Observable } from "rxjs";
import { LocaleService } from "../../../app.locale.service";
import { AppSettingsService, SpinnerService } from "../../../core";
import { LoaderService, MessageService } from "../../../main";
import { BookingAppointmentGuestService } from "../../services/booking-appointment-guest.service";
import { PatientService } from "../../services/patient.service";
import { LocationConsentComponent } from "../location-consent/location-consent.component";
import { ConfirmationModalComponent } from "./confirmation-modal/confirmation-modal.component";
import { GoogleMapService } from "./google-map.service";
declare var woosmap: any;

@Component({
  selector: "app-lab-google",
  templateUrl: "./lab-google.component.html",
  styleUrls: ["./lab-google.component.scss"],
})
export class LabGoogleComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild("search")
  public searchElementRef: ElementRef;
  @ViewChild("map")
  public mapElementRef: ElementRef;
  @Input() testType: any;
  @Input() pre_selected_lab_id: string;
  @Input() pre_selected_test_id: string;
  @Input() testTypes: any;
  @Input() stepperSelectedIndex: number;
  @Input() fav_lab_id: string;
  hideBack: boolean = false;
  @Output() selectedLocation = new EventEmitter<any>();
  @Output() selectedFavroitestation = new EventEmitter<any>();
  @Input() samplingStationRes: any;
  @Output() goBack = new EventEmitter<any>();
  @Output() goNext = new EventEmitter();
  public stationSelected: boolean = false;
  public marker: any;
  private markers: any[] = [];
  public entries: any = [];
  public filteredOptions: Observable<any[]>;
  public myControl = new FormControl();
  samplingStaton: any = "";
  mapLoaded = false;
  searchContainer: any;
  public lat: any;
  public lang: any;
  public zoom: any;
  searchPanelContainer: any;
  datePipe = new DatePipe("en-US");
  sampleStationChange = false;
  mapDataLoaded = false;
  selectedStation: any = {
    sampling_station_name: "sampling_station_name",
    address: "address",
    time: "time",
    formatted_phone_number: "formatted_phone_number",
  };
  public samplingStationsTestType: any = [];
  public samplingStations: any[] = [];
  public otherSamplingStations: any[] = [];
  samplingStationId: any;
  public patientId = "";
  public IsActive = true;
  public samplingStationid = true;
  public loginclass = "hidden";
  public favoroteSamplingStationids = new Array();
  public percent: number;
  private subscription: Subscription = new Subscription();
  public isMobile: boolean = false;
  public isIpad: boolean = false;
  public input: any;
  public nearestStations: any;
  public autocomplete;
  public hasSearched: boolean = false;
  public nearestStation: any;
  private map: any;
  public sStationselected: any;
  public infowindow: any;

  constructor(
    public apiService: GoogleMapService,
    private cdr: ChangeDetectorRef,
    public geocoder: MapGeocoder,
    public bookingAppointmentGuestService: BookingAppointmentGuestService,
    private readonly translateService: TranslateService,
    public localeService: LocaleService,
    public spinnerService: SpinnerService,
    public patientservice: PatientService,
    private messageService: MessageService,
    public loaderService: LoaderService,
    public dialog: MatDialog,
    private readonly appSettings: AppSettingsService,
    private zone: NgZone
  ) {
    this.translateService.setDefaultLang(this.localeService.localeLang);
    this.translateService.use(this.localeService.localeLang);

  }
  ngOnInit(): void {

    if (screen.width < 962) { this.isMobile = true; }
    if (screen.width < 1025) { this.isIpad = true; }
    this.lang = this.loaderService.longitude;
    this.lat = this.loaderService.latitude;
    this.zoom = this.loaderService.zoom;
    this.mapLoaded = false;
    this.subscription.add(
      this.loaderService.variable$.subscribe(value => {
        if (value == 0) {
          this.hideBack = true
        } else {
          this.hideBack = false
        }
        this.percent = (value / this.loaderService.flow_arr.length) * 100;
        this.cdr.detectChanges(); // Manually trigger change detection
      })
    );
    this.loaderService.onLangChangeCallback().subscribe(() => {
      this.apiService.api.then(() => {
        this.initMap();
      });
    });

    this.apiService.api.then(() => {
      this.initializeLocalitiesWidget();
    });

    this.samplingStations = this.samplingStationRes.filter(
      (i: any) => i.display_map == true
    );
    this.otherSamplingStations = this.samplingStationRes.filter(
      (i: any) => i.display_map == false
    );
    this.nearestStations = this.findNearestStation(this.samplingStations);
    let allSamplingStation = this.samplingStationRes;
    const selectedLab = allSamplingStation?.find(
      (i: any) => i.external_id == this.pre_selected_lab_id
    );
    if (selectedLab && !selectedLab.display_map) {
      const preSelectedStation = selectedLab;
      if (preSelectedStation) {
        this.selectedLocation.emit(preSelectedStation);
      }
      this.apiService.api.then(() => {
        this.initMap();
      });
    } else if (this.pre_selected_lab_id || this.fav_lab_id) {
      let preSelectedStation = {};
      if (this.fav_lab_id) {
        preSelectedStation = this.samplingStations?.find(
          (i: any) => i.external_id == this.fav_lab_id
        );
      } else {
        preSelectedStation = selectedLab;
      }

      if (preSelectedStation) {
        this.selectedLocation.emit(preSelectedStation);
      } else if (Number(this.pre_selected_lab_id) !== 0) {
        this.messageService.alert(
          this.translateService.instant("lab-google.lab-unavailable")
        );
      }
      this.samplingStationsTestType = this.samplingStations;
      this.apiService.api.then(() => {
        this.initMap();
      });
    }
    if (sessionStorage.getItem("userDetails")) {
      this.patientId = JSON.parse(sessionStorage.getItem("userDetails")).id;
    }
    this.loginclass = "hidden";
  }

  ngAfterViewInit() {
    // Wait for the Woosmap SDKs to load
    this.apiService.api
      .then(() => {
        this.initMap();
        this.initializeLocalitiesWidget();
      })
      .catch(err => {
        console.error('Failed to load Woosmap SDKs:', err);
      });
  }

  public initializeLocalitiesWidget() {
    const options = {
      "components": {
        "country": "fr"
      },
      "types": ['locality', 'postal_code', 'address', 'admin_level', 'country', 'train_station', 'metro_station', 'shopping', 'airport', 'museum', 'zoo', 'amusement_park', 'art_gallery', 'tourist_attraction']
    };
    //@ts-ignore
    const localitiesWidget = new window.woosmap.localities.Autocomplete("autocomplete-input", options);
    localitiesWidget.addListener("selected_suggestion", () => {
      const selectedLocality = localitiesWidget.getSelectedSuggestionDetails();
      if (selectedLocality.geometry) {
        this.lat = selectedLocality.geometry.location.lat;
        this.lang = selectedLocality.geometry.location.lng;
        const location = { lat: this.lat, lng: this.lang };
        this.nearestStations = this.findNearestStation(this.samplingStationRes); // Sort stations by distance
        this.nearestStation = this.nearestStations[0];
        this.map.setCenter(location);
        this.map.setZoom(Number(this.appSettings.mapCenterZoom));
        //this.input.value = selectedLocality.formatted_address;
        this.spinnerService.show(false);

        const nearestLat = parseFloat(this.nearestStation.coordinates.substring(
          this.nearestStation.coordinates.indexOf(":") + 1,
          this.nearestStation.coordinates.indexOf(",")
        ));
        const nearestLng = parseFloat(this.nearestStation.coordinates.substring(
          this.nearestStation.coordinates.lastIndexOf(":") + 1,
          this.nearestStation.coordinates.indexOf("}")
        ));
        const nearestlocation = { lat: nearestLat, lng: nearestLng };
        this.map.setCenter(nearestlocation);
        this.spinnerService.show(false);
        return;
      }
    });
  }

  initMap() {
    const mapElement = document.getElementById('woosmap-map');
    if (!this.searchContainer && !this.searchPanelContainer) {
      this.searchContainer = document.getElementById("search");
      this.searchPanelContainer = document.getElementById("searchResults");
    }

    if (!mapElement) {
      console.error('Map container element with ID "woosmap-map" not found.');
      return;
    }
    // Initialize the map
    const zoomLevel = this.isMobile ? 5 : this.loaderService.zoom;
    this.map = new woosmap.map.Map(mapElement, {
      center: { lat: this.lat, lng: this.lang },
      zoom: zoomLevel,
      draggable: true,
      scrollwheel: true,
    });

    const infoControl = document.querySelector('.mapboxgl-ctrl-bottom-right');
    if (infoControl) {
      const infoControlElement = infoControl as HTMLElement; // Cast to HTMLElement
      infoControlElement.style.zIndex = '1';
    }

    const zoomControl = document.querySelector('.mapboxgl-ctrl-top-right');
    if (zoomControl) {
      const zoomControlElement = zoomControl as HTMLElement; // Cast to HTMLElement
      zoomControlElement.style.paddingBottom = '50px';
      zoomControlElement.style.zIndex = '1';
      zoomControlElement.classList.remove('mapboxgl-ctrl-top-right');
      //zoomControlElement.classList.remove('mapboxgl-ctrl-compass'); 
      zoomControlElement.classList.add('mapboxgl-ctrl-bottom-right');
    }

    const compassControl = document.querySelector('.mapboxgl-ctrl-compass');
    if (compassControl && !this.isMobile) {
      const compassControlElement = compassControl as HTMLElement; // Cast to HTMLElement
      // compassControlElement.classList.remove('mapboxgl-ctrl-compass');
      compassControlElement.style.display = 'none';
    }

    this.map.addListener('click', () => {
      const query = window.matchMedia('(max-width: 780px)');
      if (query.matches) {
        const searchResults = document.getElementById('searchResults');
        if (searchResults) {
          searchResults.style.display = 'none';
        }
      }
    });
    // woosmap.map.event.addListener(this.map, 'store_selected', function (store) {
    //   this.infowindow.setContent(this.getHtmlContent(store));
    //   this.infowindow.open(this.map, {
    //     lat: store.geometry.coordinates[1],
    //     lng: store.geometry.coordinates[0]
    //   });
    // });
    // woosmap.map.event.addListener(this.map, 'store_unselected', function () {
    //   this.infowindow.close();
    // });

    this.map.addListener('zoom_changed', () => {
      this.infowindow?.close();
    })
    this.map.addListener(this.map, 'center_changed', () => { this.infowindow?.close() })
    this.map.addListener('bounds_changed', () => {
      this.infowindow?.close();
      if (this.map.getBounds()) {
        let samplingStationsTestType;
        samplingStationsTestType = this.samplingStationRes.filter(
          (i: any) => i.display_map == true
        );
        samplingStationsTestType = samplingStationsTestType.filter(
          (s: any) => {
            const openDay = s.opening_and_closing_details.filter(
              (x: any) => {
                if (x.day == new Date().getDay()) {
                  return x;
                }
              }
            );
            const start = openDay[0]?.start_time;
            const end = openDay[0]?.end_time;
            const curr = this.datePipe.transform(new Date(), "HH:mm");
            s.isOpen = this.isTimeBetween(start, end, curr);
            const lat = s.coordinates.substring(
              s.coordinates.indexOf(":") + 1,
              s.coordinates.indexOf(",")
            );
            const lng = s.coordinates.substring(
              s.coordinates.lastIndexOf(":") + 1,
              s.coordinates.indexOf("}")
            );
            if (this.map.getBounds()) {
              return this.map
                .getBounds()
                .contains(new woosmap.map.LatLng(Number(lat), Number(lng)));
            }
            return null;
          }
        );
        // Find nearest station if none are within bounds
        if (samplingStationsTestType.length === 0) {
          if (this.nearestStation) {
            samplingStationsTestType = this.nearestStations;
          }
        }
        else {
          samplingStationsTestType = this.findNearestStation(samplingStationsTestType);
        }
        if (!this.loaderService.openNowClicked) {
          this.samplingStationsTestType = samplingStationsTestType;
        } else if (this.loaderService.openNowClicked) {
          samplingStationsTestType = samplingStationsTestType.filter(
            (s: any) => s.isOpen
          );
          this.samplingStationsTestType = samplingStationsTestType;
        }
        if (this.markers.length !== this.samplingStationsTestType.length) {
          this.addMapMarkers();
        }
      }
    });

    this.spinnerService.show(true);
    if (this.samplingStationsTestType.length == 0) {
      this.spinnerService.show(false);
    }
    this.addMapMarkers();
    woosmap.map.event.trigger(this.map, "bounds_changed");
  }

  ngOnChanges(): void {
    this.mapLoaded = false;
    this.samplingStaton = "";
    if (!this.samplingStationId) {
      this.samplingStationsTestType = this.samplingStationRes.filter((i: any) => i.display_map == true);
      if (!this.pre_selected_lab_id) {
        this.apiService.api.then(() => {
          this.initMap();
        });
      }

    }
  }

  public goToBack() {
    this.goBack.emit(true);
  }

  goToNext() {
    if (!this.stationSelected) {
      this.messageService.alert(
        this.translateService.instant("lab-google.select-lab-error"),
        5000
      );
    }
    else {
      this.goNext.emit(true);
    }
  }

  findNearestStation(samplingStationsTestType: any) {

    for (let i = 0; i < samplingStationsTestType.length; i++) {
      const lat = samplingStationsTestType[i].coordinates.substring(
        samplingStationsTestType[i].coordinates.indexOf(":") + 1,
        samplingStationsTestType[i].coordinates.indexOf(",")
      );
      const lng = samplingStationsTestType[i].coordinates.substring(
        samplingStationsTestType[i].coordinates.lastIndexOf(":") + 1,
        samplingStationsTestType[i].coordinates.indexOf("}")
      );
      samplingStationsTestType[i]["distance"] = this.calculateDistance(this.lat, this.lang, lat, lng);
      //console.log("Distance for",samplingStationsTestType[i]["sampling_station_name"],"is",samplingStationsTestType[i]["distance"]);
    }
    samplingStationsTestType = samplingStationsTestType.sort(function (a, b) {
      return a.distance - b.distance;
    });
    return samplingStationsTestType;
  }

  addMapMarkers() {
    for (const i in this.markers) {
      this.markers[i].setMap(null);
    }
    const that = this;

    this.markers = [];
    const defaultIcon = {
      url: "../../../../assets/icons/map-blue.svg",
      scaledSize: { height: 30, width: 30 },
    };
    const selectedIcon = {
      url: "../../../../assets/icons/map-orange.svg",
      scaledSize: { height: 40, width: 40 },
    };
    this.spinnerService.show(false);
    var cnt = 0;
    this.samplingStationsTestType.map((station: any, index: number) => {


      if (station.coordinates) {
        var lat = parseFloat(station.coordinates.substring(
          station.coordinates.indexOf(":") + 1,
          station.coordinates.indexOf(",")
        ));
        var lng = parseFloat(station.coordinates.substring(
          station.coordinates.lastIndexOf(":") + 1,
          station.coordinates.indexOf("}")
        ));

        if (this.sStationselected && this.sStationselected.id === station.id) {
          this.marker = new woosmap.map.Marker({
            position: { lat: lat, lng: lng },
            title: station.sampling_station_name,
            stationId: station.id,
            icon: selectedIcon

          });
        }
        else {
          this.marker = new woosmap.map.Marker({
            position: { lat: lat, lng: lng },
            title: station.sampling_station_name,
            stationId: station.id,
            icon: defaultIcon,
          });
        }

        const infowindow = new woosmap.map.InfoWindow({
          content: "",
        });

        this.infowindow = new woosmap.map.InfoWindow({
          content: "",
        });


        infowindow.setContent(this.getHtmlContent(station));

        if (!this.isIpad) {
          this.marker.addListener("mouseover", function () {
            infowindow.open(this.map, this);
          });

          this.marker.addListener("mouseout", () => {
            infowindow?.close();
          });
        }

        this.marker.addListener("click", () => {
          that.openConfirmationModal(station);
        });

        this.marker.setMap(this.map);
        this.markers.push(this.marker);
      }
      if (index + 1 == this.samplingStationsTestType.length) {
        this.spinnerService.show(false);
        this.mapLoaded = true;
      }
      cnt = cnt++
    });
  }

  isTimeBetween(startTime: any, endTime: any, serverTime: any) {
    const start = moment(startTime, "H:mm");
    const end = moment(endTime, "H:mm");
    const server = moment(serverTime, "H:mm");
    if (end < start) {
      return (
        (server >= start && server <= moment("23:59:59", "h:mm:ss")) ||
        (server >= moment("0:00:00", "h:mm:ss") && server < end)
      );
    }
    return server >= start && server < end;
  }

  openConfirmationModal(station: any) {
    const title = this.translateService.instant(
      "lab-google.confirmation-title"
    );
    const message1 = station.sampling_station_name;
    const message2 = station.address;
    const confirmLable = this.translateService.instant(
      "Common.GlobalMessages.UnSaveDataConfirmTitle"
    );
    const cancelLabel = this.translateService.instant("view-booking.cancel");

    this.zone.run(() => {
      this.dialog
        .open(ConfirmationModalComponent, {
          width: "500px",
          data: { message1, message2, title, confirmLable, cancelLabel, station: station },
          autoFocus: false,
          restoreFocus: false,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((actionResult) => {
          if (actionResult) {
            this.samplingStationselect(station);
          }
        });
    })
  }

  samplingStationChange(event: any) {
    const that = this;
    document.getElementById("information").style.display = "flex";
    document.getElementById("error").style.display = "none";
    document.getElementById("searchResults").style.display = "block";
    if (event.option.value.coordinates) {
      const lat = Number(
        event.option.value.coordinates.substring(
          event.option.value.coordinates.indexOf(":") + 1,
          event.option.value.coordinates.indexOf(",")
        )
      );
      const lng = Number(
        event.option.value.coordinates.substring(
          event.option.value.coordinates.lastIndexOf(":") + 1,
          event.option.value.coordinates.indexOf("}")
        )
      );
      if (this.marker) {
        this.marker.setMap(null);
      }
      this.marker = new woosmap.map.Marker({
        position: new woosmap.map.LatLng(lat, lng),
      });


      this.marker.setMap(this.map);
      this.infowindow = new woosmap.map.InfoWindow({
        content: "",
      });


      this.infowindow.setContent(this.getHtmlContent(event.option.value));
      this.marker.addListener("mouseover", function () {
        this.infowindow.open(this.map, this);
      });
      this.marker.addListener("mouseout", () => {
        this.infowindow.close();
      });
      this.marker.addListener("click", () => {
        that.openConfirmationModal(event);
      });
      this.map.setZoom(6);
    }
    if (event.option.value.google_place_id) {
      const request = {
        placeId: event.option.value.google_place_id,
        fields: ["formatted_phone_number", "opening_hours"],
      };
      const service = new google.maps.places.PlacesService(this.map);
      service.getDetails(request, (place, status1) => {
        if (
          place &&
          place["opening_hours"] &&
          place["formatted_phone_number"] &&
          status1 == google.maps.places.PlacesServiceStatus.OK
        ) {
          event.option.value.formatted_phone_number =
            place["formatted_phone_number"];
          event.option.value.time =
            place["opening_hours"]["periods"][0].open.time +
            " - " +
            place["opening_hours"]["periods"][0].close.time;
        }
      });
    }
    if (
      this.samplingStationsTestType.filter(
        (ss: any) => ss.id == event.option.value.id
      ).length == 0
    ) {
      this.selectedStation = event.option.value;
      document.getElementById("information").style.display = "none";
      document.getElementById("error").style.display = "flex";
    } else {
      this.marker.addListener("click", () => {
        that.openConfirmationModal(event.option.value);
      });
    }
  }

  samplingStationselect(station: any) {
    const selectedIcon = {
      url: "../../../../assets/icons/map-orange.svg",
      scaledSize: { width: 40, height: 40 },
    };
    const defaultIcon = {
      url: "../../../../assets/icons/map-blue.svg",
      scaledSize: { width: 30, height: 30 },
    };
    this.markers.forEach(marker => {
      if (marker.stationId === station.id) {
        const lati = parseFloat(
          station.coordinates.substring(
            station.coordinates.indexOf(":") + 1,
            station.coordinates.indexOf(",")
          )
        );
        const lng = parseFloat(
          station.coordinates.substring(
            station.coordinates.lastIndexOf(":") + 1,
            station.coordinates.indexOf("}")
          )
        );
        this.lat = lati;
        this.lang = lng;
        const stationLatLng = new woosmap.map.LatLng(lati, lng);
        marker.setIcon(selectedIcon);
        this.sStationselected = station;
        this.map.setCenter(stationLatLng);
        this.map.setZoom(11);
      } else {
        marker.setIcon(defaultIcon);
      }
    });
    this.selectedStation = station;
    this.sStationselected = station;
    this.selectedLocation.emit(station);
    this.stationSelected = true;
  }

  openNow() {
    this.loaderService.openNowClicked = !this.loaderService.openNowClicked;
    woosmap.map.event.trigger(this.map, "bounds_changed");
  }

  onSelectedLab(event: any, station: any) {
    event.stopPropagation();
    if (this.patientId == "") {
      if (station.is_favourite) {
        if (this.favoroteSamplingStationids.indexOf(station.id) >= 0) {
          this.favoroteSamplingStationids.splice(
            this.favoroteSamplingStationids.indexOf(station.id),
            1
          );
        }
        this.messageService.success(
          this.translateService.instant("lab-google.unmarked-favorite")
        );
        station.is_favourite = false;
      } else {
        if (this.favoroteSamplingStationids.indexOf(station.id)) {
          this.favoroteSamplingStationids.push(station.id);
        }
        this.messageService.success(
          this.translateService.instant("lab-google.marked-favorite")
        );
        station.is_favourite = true;
      }
    } else {
      const datasaveFavorite = {
        sampling_station_id: station.id,
        patient_id: this.patientId,
        is_active: !station.is_favourite,
      };
      this.patientservice
        .savePatientFavoriteSamplingStations(datasaveFavorite)
        .subscribe(
          (response) => {
            if (response != null && !station.is_favourite) {
              this.messageService.success(
                this.translateService.instant("lab-google.marked-favorite")
              );
            } else {
              this.messageService.success(
                this.translateService.instant("lab-google.unmarked-favorite")
              );
            }
            station.is_favourite = !station.is_favourite;
          },
          (error) => {
            this.messageService.alert(
              this.translateService.instant("lab-google.unmarked-favorite") +
              error.message,
              3000
            );
          }
        );
    }
    this.selectedFavroitestation.emit(this.favoroteSamplingStationids);
  }

  nearMe() {
    this.dialog
      .open(LocationConsentComponent, {
        width: "450px",
        disableClose: true,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                this.map.setCenter(
                  new woosmap.map.LatLng(
                    position.coords.latitude,
                    position.coords.longitude
                  )
                );
                this.map.setZoom(Number(this.appSettings.mapCenterZoom));
                this.samplingStationsTestType =
                  this.samplingStationsTestType.filter((s: any) => {
                    if (s.coordinates) {
                      const lat = s.coordinates.substring(
                        s.coordinates.indexOf(":") + 1,
                        s.coordinates.indexOf(",")
                      );
                      const lng = s.coordinates.substring(
                        s.coordinates.lastIndexOf(":") + 1,
                        s.coordinates.indexOf("}")
                      );
                      const distance = this.calcCrow(
                        lat,
                        lng,
                        position.coords.latitude,
                        position.coords.longitude
                      );
                      return distance <= 11;
                    }
                    return false;
                  });
              },
              (error) => {
                switch (error.code) {
                  case error.PERMISSION_DENIED:
                    this.messageService.alert(
                      this.translateService.instant(
                        "lab-google.permission-denied"
                      )
                    );
                    break;
                  case error.POSITION_UNAVAILABLE:
                    this.messageService.alert(
                      this.translateService.instant(
                        "lab-google.position-unavailable"
                      )
                    );
                    break;
                  case error.TIMEOUT:
                    this.messageService.alert(
                      this.translateService.instant("lab-google.timeout")
                    );
                    break;
                }
              }
            );
          } else {
            this.messageService.alert(
              this.translateService.instant(
                "lab-google.geolocation-notsupported"
              )
            );
          }
        }
      });
  }

  calculateDistance(lat1, lon1, lat2, lon2) {
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    dist = dist * 1.609344
    if (isNaN(dist)) {
      return 0
    }
    return dist
  }

  calcCrow(lat1: any, lng1: any, lat2: any, lng2: any) {
    const R = 6371; // km
    const dLat = this.toRad(lat2 - lat1);
    const dLon = this.toRad(lng2 - lng1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  }

  toRad(Value: any) {
    return (Value * Math.PI) / 180;
  }

  protected getOpeningClosing(station: any) {
    let opening_and_closing_string = "";
    const stationTime = station?.opening_and_closing_details.filter(
      (day: any) => new Date().getDay() === day.day
    );
    if (stationTime.length > 0) {
      const stArr = stationTime[0].start_time.split(":");
      const endArr = stationTime[0].end_time.split(":");
      const startDate = new Date();
      startDate.setHours(stArr[0]);
      startDate.setMinutes(stArr[1]);
      startDate.setSeconds(stArr[2]);
      const endDate = new Date();
      endDate.setHours(endArr[0]);
      endDate.setMinutes(endArr[1]);
      endDate.setSeconds(endArr[2]);
      switch (stationTime[0]?.break_begins) {
        case "":
          opening_and_closing_string =
            this.datePipe.transform(startDate, "HH:mm") +
            " - " +
            this.datePipe.transform(endDate, "HH:mm");
          return opening_and_closing_string;
        case "00:00:00":
          opening_and_closing_string =
            this.datePipe.transform(startDate, "HH:mm") +
            " - " +
            this.datePipe.transform(endDate, "HH:mm");
          return opening_and_closing_string;
        case "00:00":
          opening_and_closing_string =
            this.datePipe.transform(startDate, "HH:mm") +
            " - " +
            this.datePipe.transform(endDate, "HH:mm");
          return opening_and_closing_string;
        default:
          const lunchStartArr = stationTime[0].break_begins.split(":");
          const lunchStartDate = new Date();
          lunchStartDate.setHours(lunchStartArr[0]);
          lunchStartDate.setMinutes(lunchStartArr[1]);
          lunchStartDate.setSeconds(lunchStartArr[2]);
          const lunchEndArr = stationTime[0].break_ends.split(":");
          const lunchEndDate = new Date();
          lunchEndDate.setHours(lunchEndArr[0]);
          lunchEndDate.setMinutes(lunchEndArr[1]);
          lunchEndDate.setSeconds(lunchEndArr[2]);
          opening_and_closing_string =
            this.datePipe.transform(startDate, "HH:mm") +
            " - " +
            this.datePipe.transform(lunchStartDate, "HH:mm") +
            " | " +
            this.datePipe.transform(lunchEndDate, "HH:mm") +
            " - " +
            this.datePipe.transform(endDate, "HH:mm");
          return opening_and_closing_string;
      }
    } else {
      return null;
    }
  }

  getHtmlContent(station: any): string {
    const openingClosingHtml: Array<any> = new Array<any>();

    for (let index = 1; index < 8; index++) {
      const dayString = this.getDay(index);
      const oacds: any[] = [];
      station?.opening_and_closing_details.forEach((element: any) => {
        if (element.day == index) {
          oacds.push(element);
        }
      });
      if (oacds.length > 0) {
        oacds.forEach((detail: any) => {
          const stArr = detail.start_time.split(":");
          const endArr = detail.end_time.split(":");
          const startDate = new Date();
          startDate.setHours(stArr[0]);
          startDate.setMinutes(stArr[1]);
          startDate.setSeconds(stArr[2]);
          const endDate = new Date();
          endDate.setHours(endArr[0]);
          endDate.setMinutes(endArr[1]);
          endDate.setSeconds(endArr[2]);

          let opening_and_closing_string = "";
          switch (detail?.break_begins) {
            case "":
              opening_and_closing_string =
                this.datePipe.transform(startDate, "HH:mm") +
                " - " +
                this.datePipe.transform(endDate, "HH:mm");
              break;
            case "00:00:00":
              opening_and_closing_string =
                this.datePipe.transform(startDate, "HH:mm") +
                " - " +
                this.datePipe.transform(endDate, "HH:mm");
              break;
            case "00:00":
              opening_and_closing_string =
                this.datePipe.transform(startDate, "HH:mm") +
                " - " +
                this.datePipe.transform(endDate, "HH:mm");
              break;
            default:
              const lunchStartArr = detail.break_begins.split(":");
              var lunchStartDate = new Date();
              lunchStartDate.setHours(lunchStartArr[0]);
              lunchStartDate.setMinutes(lunchStartArr[1]);
              lunchStartDate.setSeconds(lunchStartArr[2]);
              const lunchEndArr = detail.break_ends.split(":");
              var lunchEndDate = new Date();
              lunchEndDate.setHours(lunchEndArr[0]);
              lunchEndDate.setMinutes(lunchEndArr[1]);
              lunchEndDate.setSeconds(lunchEndArr[2]);
              opening_and_closing_string =
                this.datePipe.transform(startDate, "HH:mm") +
                " - " +
                this.datePipe.transform(lunchStartDate, "HH:mm") +
                " | " +
                this.datePipe.transform(lunchEndDate, "HH:mm") +
                " - " +
                this.datePipe.transform(endDate, "HH:mm");
              break;
          }

          if (new Date().getDay() == detail?.day) {
            openingClosingHtml.push(
              '<div style="display:flex;justify-content: space-between;font-weight:600"><label style="word-break: break-word;">' +
              this.translateService.instant(dayString) +
              '</label><label style="word-break: break-word;">' +
              opening_and_closing_string +
              "</label></div>"
            );
          } else {
            openingClosingHtml.push(
              '<div style="display:flex;justify-content: space-between;"><label style="word-break: break-word;">' +
              this.translateService.instant(dayString) +
              '</label><label style="word-break: break-word;">' +
              opening_and_closing_string +
              "</label></div>"
            );
          }
        });
      } else {
        if (new Date().getDay() == index) {
          openingClosingHtml.push(
            '<div style="display:flex;justify-content: space-between;font-weight:600;"><label style="word-break: break-word;">' +
            this.translateService.instant(dayString) +
            '</label><label style="word-break: break-word;">' +
            this.translateService.instant("lab-google.closed") +
            "</label></div>"
          );
        } else {
          openingClosingHtml.push(
            '<div style="display:flex;justify-content: space-between;"><label style="word-break: break-word;">' +
            this.translateService.instant(dayString) +
            '</label><label style="word-break: break-word;">' +
            this.translateService.instant("lab-google.closed") +
            "</label></div>"
          );
        }
      }
    }
    return (
      '<div style="display:flex;flex-direction: column;z-index:9999;margin-bottom:20px;margin-top:10px;height:100%">' +
      '<div style="display: flex;">' +
      '<label style="word-break: break-word;"> <b> <strong>' +
      station.sampling_station_name +
      "</strong></b></label>" +
      "</div>" +
      '<div style="display: flex;">' +
      '<label style="word-break: break-word;">' +
      station.address +
      "</label>" +
      "</div>"
      // + '<div style="display: flex;flex-direction: column;margin-bottom:20px;"><b> <strong style="line-height:2rem;">' +
      // this.translateService.instant("lab-google.operation") +
      // "</strong></b>" +
      // openingClosingHtml.join("") +
      // "</div></div>"
    );
  }

  private getDay(detail: any) {
    let dayString = "";
    switch (detail) {
      case 1: {
        dayString = "admin.monday";
        break;
      }
      case 2: {
        dayString = "admin.tuesday";

        break;
      }
      case 3: {
        dayString = "admin.wednesday";

        break;
      }
      case 4: {
        dayString = "admin.thursday";

        break;
      }
      case 5: {
        dayString = "admin.friday";

        break;
      }
      case 6: {
        dayString = "admin.saturday";
        break;
      }
      case 7: {
        dayString = "admin.sunday";
        break;
      }
    }
    return dayString;
  }

  openDirections(station: any) {
    event.stopPropagation();
    const lat = Number(
      station.coordinates.substring(
        station.coordinates.indexOf(":") + 1,
        station.coordinates.indexOf(",")
      )
    ).toString();
    const lng = Number(
      station.coordinates.substring(
        station.coordinates.lastIndexOf(":") + 1,
        station.coordinates.indexOf("}")
      )
    ).toString();
    window.open(
      "https://www.google.com/maps/dir/?api=1&destination=" +
      lat +
      "," +
      lng +
      "&travelmode=driving",
      "_blank"
    );
  }
}
