import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';

@Component({
    selector: 'app-location-consent',
    templateUrl: './location-consent.component.html',
    styleUrls: ['./location-consent.component.scss']
})
export class LocationConsentComponent implements OnInit {

    constructor(
        private readonly dialogRef: MatDialogRef<LocationConsentComponent>,
        private translate: TranslateService,
        private localeService: LocaleService
    ) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    ngOnInit(): void {
    }

    acceptClick() {
        this.dialogRef.close(true);
    }

    denyClick() {
        this.dialogRef.close(false);
    }

}
