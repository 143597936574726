import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { Overlay, ScrollDispatcher } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input, NgZone, Optional, ViewContainerRef } from '@angular/core';
import { MatTooltip, MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS, MAT_TOOLTIP_SCROLL_STRATEGY } from '@angular/material/tooltip';

@Directive({
    selector: '[appMatTooltip]'
})
export class TooltipDisableDirective extends MatTooltip {

    @Input()
    get appMatTooltip() {
        return this.message;
    }
    set appMatTooltip(txt: string) {
        this.message = txt;
    }

    constructor(
        private el: ElementRef,
        _overlay: Overlay,
        _scrollDispatcher: ScrollDispatcher,
        _viewContainerRef: ViewContainerRef,
        _ngZone: NgZone,
        _platform: Platform,
        _ariaDescriber: AriaDescriber,
        _focusMonitor: FocusMonitor,
        @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) _scrollStrategy: any,
        @Optional() _dir: Directionality,
        @Optional() @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS)
        _defaultOptions: MatTooltipDefaultOptions,
        @Inject(DOCUMENT) _document: any) {
        super(
            _overlay,
            el,
            _scrollDispatcher,
            _viewContainerRef,
            _ngZone,
            _platform,
            _ariaDescriber,
            _focusMonitor,
            _scrollStrategy,
            _dir,
            _defaultOptions,
            _document
        );
    }

    @HostListener('mouseenter')
    check(): void {
        this.disabled = !(this.el.nativeElement.scrollWidth > this.el.nativeElement.clientWidth ||
            this.el.nativeElement.scrollHeight > this.el.nativeElement.clientHeight);
    }

}
