import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../../app.locale.service';
import { LoaderService } from '../../../../main';
import { RedirectDialogComponent } from '../redirect-dialog/redirect-dialog.component';

@Component({
    selector: 'app-waiting-results',
    templateUrl: './waiting-results.component.html',
    styleUrls: ['./waiting-results.component.scss']
})
export class WaitingResultsComponent implements OnInit {
    @Input() displayedColumns: any;
    @Input() dataSource: any;

    constructor(
        private translate: TranslateService,
        private localeService: LocaleService,
        private readonly dialog: MatDialog,
        public loader: LoaderService,
    ) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    ngOnInit(): void { }

    /**
   * This method will show the Result status in list dialog.
   * @param entity Selected Row Data
   */
    showResultData(entity: any) {
        if (!entity.resultAvailable) {
            return;
        }

        const dialogRef = this.dialog.open(RedirectDialogComponent, {
            width: '600px',
            height: '500px',
            disableClose: true,
            data: { title: this.translate.instant('history.Result available'), result: entity }
        });
        dialogRef.afterClosed().subscribe(() => {
            // this.loadSamplingStations();
        });
    }

}
