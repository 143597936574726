<div class="hero-banner-container">
    <div class="d-flex flex-column">
        <header class="pull-left">
            <h5 class="pull-left"> {{'key-feature.heading'|translate}}</h5>
        </header>
        <div class="select-lang-title">
            {{'landing-page-config.select-lang'|translate}}
        </div>
        <mat-tab-group [(selectedIndex)]="selectedLangTabIndex" (selectedTabChange)="onLangTabChange($event)"
            class="languageTab mt-10 pull-left" animationDuration="1000ms">
            <mat-tab label="{{'landing-page-config.lang1'|translate}}">
                <form [formGroup]="enForm">
                    <div class="heroBannerWrapper">
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-icon-my-appointment"></span></div>
                                <mat-form-field>
                                    <mat-label class="label-float">{{'key-feature.field1' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField1" id="enField1"
                                        formControlName="key_feature_1" rows="2"
                                        [maxlength]="fieldMaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{enForm.get('key_feature_1')?.value?.length}}/{{fieldMaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-icon-documents"></span></div>
                                <mat-form-field>
                                    <mat-label>{{'key-feature.field2' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField2" id="enField2"
                                        formControlName="key_feature_2" rows="2"
                                        [maxlength]="fieldMaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{enForm.get('key_feature_2')?.value?.length}}/{{fieldMaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-ic-clock"></span></div>
                                <mat-form-field>
                                    <mat-label>{{'key-feature.field3' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField3" id="enField3"
                                        formControlName="key_feature_3" rows="2"
                                        [maxlength]="fieldMaxLength"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="character-count">
                                {{enForm.get('key_feature_3')?.value?.length}}/{{fieldMaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-icon-results"></span></div>
                                <mat-form-field>
                                    <mat-label>{{'key-feature.field4' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField4" id="enField4"
                                        formControlName="key_feature_4" rows="2"
                                        [maxlength]="fieldMaxLength"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="character-count">
                                {{enForm.get('key_feature_4')?.value?.length}}/{{fieldMaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-ic_help"></span></div>
                                <mat-form-field>
                                    <mat-label>{{'key-feature.field5' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField5" id="enField5" formControlName="faq"
                                        rows="2" [maxlength]="fieldMaxLength"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="character-count">
                                {{enForm.get('faq')?.value?.length}}/{{fieldMaxLength}}
                            </div>
                        </div>


                        <div>

                        </div>
                    </div>
                </form>
            </mat-tab>
            <mat-tab label="{{'landing-page-config.lang2'|translate}}">
                <form [formGroup]="frForm">
                    <div class="heroBannerWrapper">
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-icon-my-appointment"></span></div>
                                <mat-form-field>
                                    <mat-label class="label-float">{{'key-feature.field1' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="frField1" id="frField1"
                                        formControlName="key_feature_1" rows="2"
                                        [maxlength]="fieldMaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{frForm.get('key_feature_1')?.value?.length}}/{{fieldMaxLength}}
                            </div>
                        </div>

                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-icon-documents"></span></div>
                                <mat-form-field>
                                    <mat-label>{{'key-feature.field2' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="frField2" id="frField2"
                                        formControlName="key_feature_2" rows="2"
                                        [maxlength]="fieldMaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{frForm.get('key_feature_2')?.value?.length}}/{{fieldMaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-ic-clock"></span></div>
                                <mat-form-field>
                                    <mat-label>{{'key-feature.field3' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="frField3" id="frField3"
                                        formControlName="key_feature_3" rows="2"
                                        [maxlength]="fieldMaxLength"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="character-count">
                                {{frForm.get('key_feature_3')?.value?.length}}/{{fieldMaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-icon-results"></span></div>
                                <mat-form-field>
                                    <mat-label>{{'key-feature.field4' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="frField4" id="frField4"
                                        formControlName="key_feature_4" rows="2"
                                        [maxlength]="fieldMaxLength"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="character-count">
                                {{frForm.get('key_feature_4')?.value?.length}}/{{fieldMaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-ic_help"></span></div>
                                <mat-form-field>
                                    <mat-label>{{'key-feature.field5' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="frField5" id="frField5" formControlName="faq"
                                        rows="2" [maxlength]="fieldMaxLength"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="character-count">
                                {{frForm.get('faq')?.value?.length}}/{{fieldMaxLength}}
                            </div>
                        </div>
                        <div> </div>
                    </div>
                </form>
            </mat-tab>

        </mat-tab-group>
        <div class="actions">
            <button mat-raised-button color="primary" class="ghost submit-click"
                (click)="save()">{{'landing-page-config.save' |
                translate}}</button>
            <button mat-button color="accent" class="ghost"
                (click)="cancelClicked.emit()">{{'landing-page-config.cancel' |
                translate}}</button>
        </div>
    </div>
</div>