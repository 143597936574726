<div>
    <div class="upload-documents-container p-20">
        <div class="header">
            <app-section-title title="{{ 'createAccount.upload-document' | translate}}"></app-section-title>
        </div>
        <div class="formContainer">
            <form [formGroup]="docForm" #formReference>
                <div class="form-layout">
                    <div *ngIf="nationality==1 && minor">
                        <mat-label class="header">{{'createAccount.passport-confirm' | translate}}</mat-label>
                        <br>
                        <mat-radio-group [formControl]="minorPassport" required>
                            <mat-radio-button *ngFor="let val of choices" value="{{val.Id}}">{{val.KeyName|translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="identity-card">
                        <div>
                            <p class="upload-header" *ngIf="nationality!=1 || minorPassport.value==1">
                                {{'createAccount.passport' | translate}}
                            </p>
                            <p class="upload-header" *ngIf="nationality==1 && minor">
                                {{'createAccount.identity-card' | translate}}
                            </p>
                            <p class="upload-header" *ngIf="nationality==1 && minor && minorPassport.value!=1">
                                {{'createAccount.parents-identity-card' | translate}}
                            </p>
                            <p>{{'createAccount.identity-comment' | translate}}</p>

                        </div>
                        <app-appointment-prescription [document]="identityItem"
                            (updateDocument)="getUpdateIdentityDocument($event)"></app-appointment-prescription>
                        <app-datepicker [ngClass]="{'disabled':IdentityFile.value==''}" class="browse-input"
                            id="identityExpiry" labelText="{{'createAccount.expiry' | translate}}"
                            [dateInput]="identityExpiry" [minDate]="maxDate"
                            isRequiredError="{{IdentityFile.value!=''}}" isRequired="{{IdentityFile.value!=''}}">
                        </app-datepicker>
                    </div>
                    <div class="birth-certificate" *ngIf="minorPassport.value==2 && minor">
                        <div>
                            <p class="upload-header only-web">
                                {{'createAccount.certificate' | translate}}
                            </p>
                            <p class="upload-header only-mobile">
                                {{'createAccount.certificate' | translate}}
                            </p>
                            <p>{{'createAccount.birth-comment' | translate}}</p>

                        </div>
                        <app-appointment-prescription [document]="birthItem"
                            (updateDocument)="getUpdateBirthDocument($event)"></app-appointment-prescription>
                    </div>
                    <div class="insurance-card">
                        <p class="upload-header only-web">
                            {{'createAccount.insurance' | translate}}
                        </p>
                        <p class="upload-header only-mobile">
                            {{'createAccount.insurance' | translate}}
                        </p>
                        <label style="display: block">{{'createAccount.private-insurance' | translate}}<span
                                style="color:red;">
                                *</span></label>
                        <mat-radio-group [formControl]="insurance" required>
                            <mat-radio-button *ngFor="let val of choices" value="{{val.Id}}">{{val.KeyName|translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div *ngIf="insurance.value=='1'">
                        <div>
                            <p>{{'createAccount.insurance-comment' | translate}}</p>
                        </div>
                        <app-appointment-prescription [document]="insuranceItem"
                            (updateDocument)="getUpdateInsuranceDocument($event)"></app-appointment-prescription>
                        <app-datepicker [ngClass]="{'disabled':InsuranceFile.value==''}" class="browse-input mb-20"
                            id="insuranceExpiry" labelText="{{'createAccount.expiry' | translate}}"
                            [dateInput]="insuranceExpiry" [minDate]="maxDate"
                            isRequiredError="{{InsuranceFile.value!=''}}" isRequired="{{InsuranceFile.value!=''}}">
                        </app-datepicker>
                    </div>
                </div>
            </form>
        </div>
        <div class="action-wrapper">
            <button mat-button color="accent" class="ghost button_width pull-right btn-gap" type="reset"
                (click)="back()" id="clear_btn">
                {{'createAccount.back' | translate}}
            </button>
            <button mat-raised-button color="primary" class="button_width pull-right " type="submit"
                (click)="SaveDocuments()" id="save_btn">{{'createAccount.register' | translate}} </button>
        </div>
    </div>
</div>