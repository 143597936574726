import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(private http: HttpClient) { }

    Get<T>(uri: string) {
        return this.http.get<T>(uri);
    }

    async AsyncGet<T>(uri: string) {
        return await this.http.get<T>(uri).toPromise();
    }

    Post<T, T1>(uri: string, reqData: T) {
        return this.http.post<T1>(uri, reqData);
    }

    async AsyncPost<T, T1>(uri: string, reqData: T) {
        return await this.http.post<T1>(uri, reqData).toPromise();
    }

    Put<T, T1>(uri: string, reqData: T) {
        return this.http.put<T1>(uri, reqData);
    }

    Delete<T>(uri: string) {
        return this.http.delete<T>(uri);
    }
}
