<div class="test-type-container" [ngClass]="loader.loggedIn ? 'test-type-container-nurse' : 'test-type-container-guest'">
    <div class="section-header" [ngClass]="loader.loggedIn  ? 'section-header-nurse' : 'section-header-guest'">
        <div class="mainSectionWrap">
            <circle-progress class="only-web" [options]="loader.optionsG" [percent]="40" [renderOnClick]="false"
                imageSrc="assets/images/application-logos/svg/ic-stest.svg" class="copy">
            </circle-progress>
            <span class="header-text">
                <h3 *ngIf="!testTypeSelection && selectTest.length>0" class="desc-title">
                    {{'test-selection.test-type-desc'|translate}}
                </h3>
                <h3 *ngIf="testTypeSelection" class="desc-title">
                    {{'test-selection.test-desc'|translate}}
                </h3>
            </span>

        </div>
        <div class="button-group">
            <button mat-button *ngFor="let gender of mfGenders"
                [ngClass]="{'highlight-btn' :gender?.isClicked, 'gender-height-female': gender.Key=='F', ' gender-height-male':gender.Key=='M' }"
                class=" ghost" (click)="filterByGender(gender)">
                <span *ngIf="gender.Key=='M'" class="icon-ic-male icon-height-male"></span>
                <span *ngIf="gender.Key=='F'" class="icon-ic-female icon-height-female"></span>
                <span class="gender-text" [ngClass]="{'highlight-btn' :gender?.isClicked }">
                    {{gender.KeyName| translate}}
                </span>
            </button>
        </div>

    </div>
    <div class="card-section" [ngClass]="loader.loggedIn  ? 'card-section-nurse' : 'card-section-guest'">
        <div *ngIf="loader.loggedIn && loader.isLoginReceptionist" class="patient-info">
            {{'stepper-flow.bookingFor' | translate}} : 
            <span class="patientname">{{loader.bookingPatientName | translate}}</span>
        </div>
        <!-- AllTestType: {{allTestType.length}}
        AllTest: {{selectTest.length}}
        filteredTestType: {{filteredTestType.length}}
        filteredTest: {{filteredTest.length}} -->
        <ng-container *ngIf="!testTypeSelection && selectTest.length>0">
            <div class="testingContent">
                <div class="testItems cp" [ngClass]="selectedTestType?.Id == t.Id  ? 'selected-border' : ''"
                    *ngFor="let t of filteredTestType; let i = index">
                    <div (click)="onTestTypeSelection(t)">
                        <div class="productImage">
                            <img [src]=t.ImageUrl (error)="onTestTypeError(i)" />
                        </div>
                        <div class="product-details">
                            <div class="productName">{{loader.TranslateLang(t.Name)}}
                            </div>
                            <div class="productDescription testTypeDesc only-web"
                                matTooltip="{{loader.TranslateLang(t.Description)}}" matTooltipPosition="above">
                                {{loader.TranslateLang(t.Description) | descriptionShortener:characters}}</div>
                        </div>
                        <div class="select-button" [ngClass]="selectedTestType?.Id == t.Id  ? 'selected' : 'select'">
                            {{(selectedTestType?.Id == t.Id ? 'check-pres.selected' : 'check-pres.select') |
                            translate}}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="testTypeSelection && !noRecordFound && selectTest.length>0">
            <div class="testingContent">
                <div class="testItems cp" [ngClass]="selectedTest1?.id == t.id  ? 'selected-border' : ''"
                    *ngFor="let t of filteredTest; let i = index">
                    <div (click)="onTestSelected(t)">
                        <!-- {{t | json}} -->
                        <div class="productImage">
                            <img [src]=t.url (error)="onError(i)" />
                        </div>
                        <div class="product-details-test">
                            <div class="test-name">{{t.short_name}}</div>
                            <div class="test-description only-web" matTooltip="{{t.description}}"
                                matTooltipPosition="above">
                                {{t.description | descriptionShortener:characters}}</div>
                        </div>
                        <div class="select-button" [ngClass]="selectedTest1?.id == t.id  ? 'selected' : 'select'">
                            {{(selectedTest1?.id == t.id ? 'check-pres.selected' : 'check-pres.select') |
                            translate}}</div>
                    </div>
                </div>
            </div>

        </ng-container>
    </div>
    <div>
        <div class="action-btn" *ngIf="!testTypeSelection && selectTest.length>0">
            <button mat-raised-button class="back-btn" type="button" (click)="goToBack()">
                {{'check-pres.back' | translate}}
            </button>
            <button mat-raised-button class="next-btn" type="button" (click)="onTestTypeNext()">
                {{'check-pres.next' | translate}}
            </button>

        </div>
        <div class="action-btn" *ngIf="testTypeSelection">
            <button mat-raised-button class="back-btn" type="button" (click)="clearSearch()">
                {{'check-pres.back' | translate}}
            </button>
            <button mat-raised-button class="next-btn" type="button" (click)="goToNext()">
                {{'check-pres.next' | translate}}
            </button>
        </div>
    </div>
</div>