import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PatientService } from '../../../services/patient.service';
import { LoaderService, MessageService } from '../../../../main';
import { NgOtpInputConfig } from 'ng-otp-input'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  @Input() sliderFormData: any;
  public elevateEmail = new FormControl("", [
    Validators.required,
    Validators.pattern(this.patientService.emailPattern),
  ]);
  protected display: any;
  protected btndisabled = true;
  protected verifyID: string;
  protected otp: string;
  public step = 1;
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
  };
  @ViewChild("OtpInput", { static: false }) ngOtpInput: any;

  constructor(public patientService: PatientService,
    public loaderService: LoaderService,
    private messageService: MessageService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  protected dismiss() {
    this.loaderService.cancelForm();
  }

  protected elevateAccess() {
    if (this.verifyID) {
      const elevateRMData = {
        sa_patient_id: this.sliderFormData.patient.id,
        new_email: this.elevateEmail.getRawValue(),
        verify_id: this.verifyID,
        otp: this.otp
      };
      this.patientService.elevateRM(elevateRMData).subscribe(
        (response: any) => {
          if (response.message == 'success') {
            this.messageService.success(this.translate.instant('elevate-account.elevate-success'));
            const payload = {
              isElevate: true,
              email: this.elevateEmail.value,
              isSecondary: false,
              isActive: false
            };
            this.loaderService.onFormClose(payload);
            this.dismiss();
          }
        },
        (err: {
          error: { code: any; message: string };
          message: any;
          code: any;
        }) => {
          if (err.error.code == 422) {
            this.messageService.alert(
              this.translate.instant('elevate-account.invalid-otp')
            );
          }
          else {
            this.messageService.alert(this.translate.instant('elevate-account.failure'));
            this.dismiss();
          }
        }
      )
    }
  }

  protected updateEmail() {
    if (this.elevateEmail.valid) {
      const updateEmailData = {
        id: this.sliderFormData.patient.id,
        email: this.sliderFormData.patient.email,
        new_email: this.elevateEmail.getRawValue()
      };
      this.patientService.updateEMail(updateEmailData).subscribe(
        (response: any) => {
          this.verifyID = response.verify_id
          this.timer();
          this.step = 2;
        },
        (err: {
          error: { code: any; message: string };
          message: any;
          code: any;
        }) => {
          if (err.error.code == 422) {
            this.elevateEmail.setErrors({ emailExist: true });
          }
          else {
            this.messageService.alert(err.error.message);
          }
        });
    } else {
      console.log('Data Error');
    }
  }
  protected onOtpChange(otp: string) {
    this.otp = otp;
  }
  protected resetOTP() {
    this.ngOtpInput.setValue("");
    this.timer();
    this.btndisabled = true;
    const updateEmailData = {
      id: this.sliderFormData.patient.id,
      email: this.sliderFormData.patient.email,
      new_email: this.elevateEmail.getRawValue()
    };

    this.patientService.updateEMail(updateEmailData).subscribe(
      (response: any) => {
        this.verifyID = response.verify_id
        this.timer();
        this.step = 2;
      });
  }

  protected timer() {
    const minute = 5;
    let seconds: number = minute * 60;
    let textSec = 0;
    let statSec = 60;
    const prefix = minute < 10 ? "0" : "";
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) {
        statSec--;
      } else {
        statSec = 59;
      }
      if (statSec < 10) {
        textSec = 0 + statSec;
      } else {
        textSec = statSec;
      }
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec
        .toString()
        .padStart(2, "0")}`;
      if (seconds == 0) {
        clearInterval(timer);
        this.btndisabled = false;
      }
    }, 1000);
  }
}
