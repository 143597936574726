import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
import { SpinnerService } from '../../../core';
import { MessageService } from '../../../main';
import { AddCompanyService } from '../../services/add-company.service';
import { CommonApiService } from '../../services/common-api.service';
// import { Company } from "../company-dialog/Company";
import { CompanyDialogComponent } from '../company-dialog/company-dialog.component';

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
    public dataSource: MatTableDataSource<any>;
    public hoverindex = '';
    search: String = '';
    searchinput = '';
    status = 'All';
    filter: string;
    adminRole: boolean;
    public columnList = ['Id', 'ExternalId', 'Name', 'Status', 'icon-column'];
    filterObject = {
        search: '',
        status: ''
    };
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    sampleData: { [key: string]: string } = {
        All: 'company.all',
        Active: 'admin.active',
        Suspended: 'admin.suspended'
    };
    deleteTitle = 'admin.confirm-delete';
    deleteconfirmtext = 'admin.delete-confirm-text';
    savealert = {
        edit: 'admin.save-alert.edit',
        add: 'admin.save-alert.add',
        delete: 'admin.save-alert.delete'
    };
    errorMessage = 'admin.error-message';
    constructor(
        private readonly dialog: MatDialog,
        private commonAPi: CommonApiService,
        private readonly messageService: MessageService,
        private readonly spinner: SpinnerService,
        public addCompanyService: AddCompanyService,
        private translate: TranslateService,
        private localeService: LocaleService
    ) {
        this.dataSource = new MatTableDataSource<any>();
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    ngOnInit(): void {
        this.loadCompany();
        this.dataSource.sortingDataAccessor = (
            data: any,
            sortHeaderId: string
        ): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            }
            return data[sortHeaderId];
        };
        this.adminRole = JSON.parse(sessionStorage.getItem('access_token'))['role'] == 5;
    }
    public loadCompany() {
        this.commonAPi.GetAllCompany().subscribe((companylist) => {
            companylist.sort((a: any, b: any) => b.org_name - a.org_name);

            this.dataSource.data = companylist;

            this.dataSource.filterPredicate = (data, filter) => {
                const filterObj = JSON.parse(filter);
                if (filterObj.search !== '' && filterObj.status == '') {
                    return (
                        String(data.id)
                            .toLowerCase()
                            .includes(filterObj.search.toLowerCase()) ||
            String(data.org_name)
                .toLowerCase()
                .includes(filterObj.search.toLowerCase()) ||
            String(data.external_id)
                .toLowerCase()
                .includes(filterObj.search.toLowerCase())
                    );
                } else if (filterObj.search == '' && filterObj.status !== '') {
                    return (
                        String(data.is_active) === filterObj.status ||
            filterObj.status == 'All'
                    );
                } else {
                    return (
                        (String(data.id)
                            .toLowerCase()
                            .includes(filterObj.search.toLowerCase()) ||
              String(data.org_name)
                  .toLowerCase()
                  .includes(filterObj.search.toLowerCase()) ||
              String(data.external_id)
                  .toLowerCase()
                  .includes(filterObj.search.toLowerCase())) &&
            (String(data.is_active) === filterObj.status ||
              filterObj.status == 'All')
                    );
                }
            };
        });
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    /** Method to identify hovered grid row */
    public rowMouseOver(row: any): void {
        this.hoverindex = row.id;
    }

    /* Method for ADDCompany Button */
    public add(): void {
        const dialogRef = this.dialog.open(CompanyDialogComponent, {
            width: '450px',

            data: {
                action: 'add',
                savealert: this.savealert,
                errMsg: this.errorMessage
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            console.log(result);
            this.loadCompany();
        });
    }
    public edit($event: MouseEvent, item: any): void {
    // const message = `${product.Pid}. '${product.Title}'  Edited Susccessfully`;
        const dialogRef = this.dialog.open(CompanyDialogComponent, {
            width: '450px',

            data: {
                action: 'edit',
                obj: item,
                savealert: this.savealert,
                errMsg: this.errorMessage
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            console.log(result);
            this.loadCompany();
        });
        // this.messageService.primary(message);
        $event.stopPropagation();
    }
    public confirmDeleteWithYesNo(company: any): void {
        this.messageService
            .confirm(
                this.translate.instant(this.deleteconfirmtext),
                this.translate.instant(this.deleteTitle)
            )
            .subscribe((actionResult: boolean) => {
                if (actionResult) {
                    this.deleteCompany(company);
                    // this.spinnerService.show(true);
                    // This set time out is being used for the demo purpose
                    //  setTimeout(() => this.spinnerService.show(false), 3000);
                }
            });
    }
    public displaySpinner(flag: boolean): void {
        this.spinner.show(flag);
    // setTimeout(() => {
    //     this.spinner.show(false);
    // }, 5000);
    }
    public success(message: any): void {
        this.messageService.success(this.translate.instant(message));
    }
    public alert(error: any): void {
        this.messageService.alert(this.translate.instant(error), 10000);
    }

    deleteCompany(company: any) {
    // this.loader.show();
        this.displaySpinner(true);
        this.spinner.show(true);
        company.is_active = false;
        // const company = this.updateCompanyObject();
        this.addCompanyService.postAdminCompany(company, 'delete').subscribe(
            () => {
                this.spinner.show(false);
                this.success(this.savealert['delete']);
                this.loadCompany();
            },
            (errorResponse: HttpErrorResponse) => {
                this.displaySpinner(false);
                this.alert(this.errorMessage);
                console.log(errorResponse);
            }
        );
    }

    /* Method for search button */
    onSearch() {
        this.filterObject.search = this.searchinput;
        this.dataSource.filter = JSON.stringify(this.filterObject);
    }

    /* Method for Reset Button */
    public reset(): void {
        this.dataSource.filter = '';
        this.searchinput = '';
        this.status = 'All';
        this.filterObject.search = '';
        this.filterObject.status = '';
    }

    /* Method for Status Dropdown */
    public statusFilter() {
        if (this.status != 'All') {
            this.filterObject.status = this.status == 'Active' ? 'true' : 'false';
            this.dataSource.filter = JSON.stringify(this.filterObject);
        } else {
            this.filterObject.status = 'All';
            this.dataSource.filter = JSON.stringify(this.filterObject);
        }
    }

    unsorted() {}
    /**
   * This method will execute the when user will try to use space key keyboard and in the search box if the space is first character then
   * it will wont allow space to execute, It will block the space untill user enter a character.
   * @param event
   */
    keyDownHandler(event: Event) {
        if (this.searchinput.length === 0) {
            event.preventDefault();
        }
    }
}
