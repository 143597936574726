import { Component } from '@angular/core';

@Component({
    selector: 'app-features',
    template: '<router-outlet></router-outlet>'
})

export class AdminComponent  {
    constructor() {}
}
