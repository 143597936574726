import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { WebcamUtil } from 'ngx-webcam';
import { SpinnerService } from '../../core';

@Injectable({
  providedIn: 'root'
})
export class WebcamService {
  public videoStream: MediaStream = null;
  public deviceId: string;
  @ViewChild('videoWrapper') videoWrapper: ElementRef;

  constructor(private spinner: SpinnerService) { }

  openCam() {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        if (mediaDevices && mediaDevices.length > 0) {
          this.deviceId = mediaDevices[0].deviceId;
          //this.showWebcam = true;
          this.startWebcam();
        } else {
          console.error('No video input devices found.');
        }
      })
      .catch((error: any) => {
        console.error('Error getting available video inputs: ', error);
      });
  }
  public startWebcam(): void {
    this.spinner.show(true);
    if (!this.videoStream) {
      navigator.mediaDevices.getUserMedia({ video: { deviceId: this.deviceId } })
        .then((stream: MediaStream) => {
          this.videoStream = stream;
          const videoElement: HTMLVideoElement = this.videoWrapper.nativeElement;
          videoElement.srcObject = this.videoStream;
          videoElement.play();
          this.spinner.show(false);
        })
        .catch((error: any) => {
          console.error('Error accessing webcam: ', error);
          this.spinner.show(false);
        });
    }
  }

  public stopWebcam(): void {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach(track => track.stop());
      this.videoStream = null;
    }
  }
}
