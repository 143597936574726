import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettingsService } from '../../../core';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentsService {

  constructor(public httpClient: HttpClient, private readonly appSettings: AppSettingsService) { }

  addEstablishment(EstablishmentData: Object) {
    return this.httpClient.post(
      this.appSettings.adminBaseUrl + 'hospital', EstablishmentData);
  }

  editEstablishment(EstablishmentData: Object) {
    return this.httpClient.put(
      this.appSettings.adminBaseUrl + 'hospital', EstablishmentData);
  }

  addDepartment(DeptData: Object) {
    return this.httpClient.post(
      this.appSettings.adminBaseUrl + 'department', DeptData);
  }

  editDepartment(DeptData: Object) {
    return this.httpClient.put(
      this.appSettings.adminBaseUrl + 'department', DeptData);
  }
  
}

