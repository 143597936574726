<div class="pull-right">
    <button mat-icon-button color="accent" (click)="dismiss()">
        <span class="icon-ic-close"></span>
    </button>
</div>
<div class="dialog-container" [ngClass]="data.result == 'Negative' ? translate.instant('register-result.negative') : translate.instant('register-result.positive')">
    <h5>{{ "register-result.confirm-result" | translate}}</h5>
    <div class="dialog-content">
        <div class="icon-text">
            <div class="icon"><img src="assets/images/seed-images/{{data.result == 'Negative' ? 'positive': 'negative'}}.svg"></div>
            <p>{{data.kitId}}</p>
        </div>
        <p>{{ "register-result.result-confirm" | translate}}<span>{{ (data.result == 'Negative') ? ' Negative' :  ' Positive'}}</span> ?</p>
    </div>
    <div class="actions">
        <button mat-raised-button color="primary" type="submit" class="button_content" (click)="confirmed()">{{ "register-result.confirm" | translate}}</button>
        <button mat-button (click)="dismiss()" color="accent" class="ghost">{{ "register-result.cancel" | translate}}</button>
    </div>
</div>
