<div class="booking-calendar">
    <div class="pull-left header">
        <div class="sub-header">

            <h3 class="pull-left">{{'slot-configuration.heading' | translate}}</h3>

            <button mat-raised-button color="primary" (click)="redirectToManageHoliday()"
                type="button">{{'manage-holiday.title'|translate}}</button>
        </div>
        <div class="fields">
            <div class="inputs w-100">
                <mat-form-field def="company">
                    <mat-label>{{'slot-configuration.company' | translate}}</mat-label>
                    <mat-select name="company" [disableOptionCentering]="true">
                        <mat-option *ngFor="let company of companies" [value]="company.id"
                            (onSelectionChange)="companyChange(company.id,$event)">{{company.org_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field def="samplingStaton" class="ss-field-wrapper">
                    <mat-label>{{'slot-configuration.sampling-station' | translate}}</mat-label>
                    <input type="text" #searchData matInput [formControl]="myControl" (click)="resetFewControls()"
                        [matAutocomplete]="auto">
                    <i class="material-icons align-arrow-right arrow-color">arrow_drop_down</i>

                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="samplingStationChange($event)">

                        <mat-option *ngFor="let ss of filteredOptions | async" [value]="ss" class="twolineText">
                            {{ss.sampling_station_name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div>
        <header *ngIf="selectedSamplingStation && isLoaded">
            <div class="actions-calendar">
                <button class="mat-tbl-icon" mat-icon-button mwlCalendarPreviousView [view]="view"
                    [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()" [disabled]="disablePrevious">
                    <span class="icon-ic-left"></span>
                </button>
                <button class="mat-tbl-icon" mat-icon-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                    [disabled]="disableNext" (viewDateChange)="closeOpenMonthViewDay()">
                    <span class="icon-ic-right"></span>
                </button>
                <button mat-button color="accent" mwlCalendarToday [(viewDate)]="viewDate" class="ghost today"
                    type="submit" (click)="disableNext=false;disablePrevious=false;  setValue()">{{'appointments.today' |
                    translate}}</button>
            </div>
            <div class="date">
                <h2 *ngIf="calendarRenderEvent">{{ calendarRenderEvent.period.start | date: 'dd/MM/yyyy'}} - {{
                    calendarRenderEvent.period.end | date: 'dd/MM/yyyy'}}</h2>
            </div>
            <h2 class="date"></h2>
            <!-- <div class="calendar-controls">
                <button (click)="toggleView(1)" [ngClass]="{'active':view == 'day'}">Day
                    View</button>
                <button (click)="toggleView(2)" [ngClass]="{'active':view == 'week'}">Week
                    View</button>
            </div> -->

        </header>
        <ng-container>
            <mwl-calendar-week-view *ngIf="selectedSamplingStation && view == 'week' && isLoaded"
                [headerTemplate]="headerTemplate" class="bookmain-calendar" [hourSegments]="2" weekStartsOn="1"
                [daysInWeek]="7" [viewDate]="viewDate" [hourSegmentHeight]="35" [events]="events" [refresh]="refresh"
                (eventClicked)="handleEventClick($event)" [dayStartHour]="minStartTime" [dayEndHour]="maxStartTime"
                [hourSegmentFormat]="'HH:mm'" (beforeViewRender)="beforeWeekViewRender($event)">

            </mwl-calendar-week-view>
            <!-- 
            <mwl-calendar-day-view *ngIf="selectedSamplingStation && view == 'day'" [viewDate]="viewDate"
                [events]="events" [refresh]="refresh" [hourSegments]="2" [viewDate]="viewDate" [hourSegmentHeight]="35"
                [events]="events" [refresh]="refresh" (eventClicked)="handleEventClick($event)"
                [dayStartHour]="minStartTime" [dayEndHour]="maxStartTime" (hourSegmentClicked)="cellClicked($event)"
                (beforeViewRender)="beforeWeekViewRender($event)"></mwl-calendar-day-view> -->
        </ng-container>

        <ng-template #headerTemplate let-days="days">
            <div class="cal-day-headers cursor-default">
                <div class="cal-header" *ngFor="let day of days">
                    <div [ngClass]="day.isToday==true ? 'highlight' : 'plain'">
                        <div>
                            {{ day.date | calendarDate:'weekViewColumnHeader':localeService.localeLang }}
                        </div>
                        <div class="day-number">
                            {{ day.date | date :'dd/MM/yyyy' }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

</div>