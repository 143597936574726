<div class="booking-calendar" *ngIf="loaded">
    <div class="pull-left header">
        <div class="sub-header">
            <div class="title-wrapper">
                <span class="icon-ic-back" (click)="redirectCalendarManagement()"></span>
                <h3 class="m-0">{{'manage-holiday.title'|translate}}</h3>
            </div>
            <button mat-raised-button color="primary" type="button"
                (click)="addHoliday()">{{'manage-holiday.add-holiday-btn'|translate}}</button>
        </div>
        <div class="fields" [formGroup]="FilterForm">
            <div class="inputs"
                [ngClass]="{'w-50': selectedLevel?.Key ==countryLevelCode , 'w-100': selectedLevel?.Key !=countryLevelCode}">
                <mat-form-field def="holidayType">
                    <mat-label>{{'manage-holiday.holiday-type'|translate}}</mat-label>
                    <mat-select name="holidayType" [disableOptionCentering]="true" formControlName="HolidayType"
                        (selectionChange)="onLevelChange($event.value)">
                        <mat-option *ngFor="let level of levels" [value]="level" class="twolineText">{{level.KeyName
                            | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field def="Company" *ngIf="selectedLevel?.Key !=countryLevelCode">
                    <mat-label class="label-float">{{'manage-holiday.select-company' | translate}}</mat-label>
                    <mat-select formControlName="Company" [disableOptionCentering]="true" multiple
                        (selectionChange)="ssFilterOnCompany($event.value)">
                        <app-select-check-all [model]="FilterForm.get('Company')" [values]="companies || []"
                            (allChecked)="allChecked($event)">
                        </app-select-check-all>
                        <mat-option *ngFor="let company of companies" [value]="company" class="twolineText">
                            <span [style.font-weight]="company.is_active ? 'bold' : 'normal'">{{company.org_name
                                }}</span></mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="full-width" def="samplingStations" *ngIf="selectedLevel?.Key ==ssLevelCode">

                    <input type="text" #searchData matInput [formControl]="myControl" (click)="resetControl()"
                        [matAutocomplete]="auto">
                    <i class="material-icons align-arrow-right arrow-color">arrow_drop_down</i>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="ssChange($event.option.value)">
                        <mat-option *ngFor="let samplingStation of filteredOptions | async" [value]="samplingStation"
                            class="twolineText">
                            <span
                                [style.font-weight]="samplingStation.is_active ? 'bold' : 'normal'">{{samplingStation.sampling_station_name
                                }} ({{samplingStation.org_name}})</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="table-container">
            <mat-table #table [dataSource]="dataSource" matSort class="table-height input-min-spacing table-flex"
                id="CompanyListingTable">
                <ng-container matColumnDef="org_name"
                    *ngIf="selectedLevel?.Key ==ssLevelCode || selectedLevel?.Key companyLevelCode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="org_name">
                        <span>{{'manage-holiday.company' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let entity">
                        {{ entity.org_name }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sampling_station_name" *ngIf="selectedLevel?.Key ==ssLevelCode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="sampling_station_name">
                        <span>{{'manage-holiday.sampling-station' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let entity">
                        {{ entity.sampling_station_name }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="start_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="start_date">
                        <span>{{'manage-holiday.start-date' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let entity" matTooltip="{{ entity.start_date| date:'dd/MM/yyyy'}}">
                        {{ entity.start_date| date:'dd/MM/yyyy' }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="end_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="end_date">
                        <span>{{'manage-holiday.end-date' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let entity" matTooltip="{{ entity.end_date | date:'dd/MM/yyyy'}}">
                        {{entity.end_date | date:'dd/MM/yyyy'}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="level">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="level">
                        <span>{{'manage-holiday.holiday-type' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let entity" matTooltip="{{ entity.level_name |  translate}}">
                        {{entity.level_name | translate}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="reason">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="reason">
                        {{'manage-holiday.reason' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let entity">
                        {{entity.reason}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Options">
                    <mat-header-cell *matHeaderCellDef>
                        {{'manage-holiday.options' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let entity">
                        <button class="mat-tbl-icon" (click)="editHoliday(entity)" mat-icon-button>
                            <span class="icon-ic-edit" matTooltip="{{'user.edit' | translate}}"></span>
                        </button>
                        <button class="mat-tbl-icon" mat-icon-button>
                            <span class="icon-ic-delete" matTooltip="{{'user.delete' | translate}}"
                                (click)="deleteHoliday(entity)"></span>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="selectedLevel?.Key == ssLevelCode ?type1columnList:selectedLevel?.Key == companyLevelCode?type2columnList:type3columnList; sticky: true"></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: selectedLevel?.Key ==ssLevelCode?type1columnList:selectedLevel?.Key== companyLevelCode?type2columnList:type3columnList; let i = index"
                    class="element-grid-row">
                </mat-row>
            </mat-table>

            <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons="true">
            </mat-paginator>
        </div>
    </div>
</div>