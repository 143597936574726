<form [formGroup]="searchMemberForm" *ngIf="step==1" appAutoScroll (ngSubmit)="submitRM()">
    <div class="searchFormwrapper">
        <div>
            <label style="display: block">{{'add-member.gender' | translate}}<span class="text-red">
                    *</span></label>
            <mat-radio-group [formControl]="gender" required>
                <mat-radio-button *ngFor="let gen of genders" value="{{gen.Id}}">{{gen.KeyName|translate}}
                </mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="gender.value && gender.invalid">{{'add-member.valid-gender' | translate}}
            </mat-error>
        </div>
        <div class="onlyDesktop"> </div>

        <mat-form-field>
            <mat-label>{{'add-member.first-name' | translate}}</mat-label>
            <input matInput name="FirstName" id="FirstName" [formControl]="firstName"
                (keypress)="loader.keyPressAlphabets($event)" (paste)="loader.pasteAlphabets($event)" />
            <mat-error *ngIf="firstName.invalid">{{'add-member.valid-first-name' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'add-member.birth-name' | translate}}</mat-label>
            <input matInput name="BirthName" id="BirthName" [formControl]="birthName" (input)="copyBirthName()"
                (keypress)="loader.keyPressAlphabets($event)" (paste)="loader.pasteAlphabets($event)" />
            <mat-error *ngIf="birthName.invalid">{{'add-member.valid-birth-name' | translate}}</mat-error>
        </mat-form-field>
        <div>
            <label class="d-block">{{'add-member.sameasLastName' | translate}}<span class="text-red">
                    *</span></label>
            <mat-radio-group [formControl]="sameLastName" (change)="onSameLastNameChange()" required>
                <mat-radio-button value="0">{{'add-member.yes'|translate}}
                </mat-radio-button>
                <mat-radio-button value="1">{{'add-member.no'|translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div></div>

        <mat-form-field>
            <mat-label>{{'add-member.last-name' | translate}}</mat-label>
            <input matInput name="LastName" id="LastName" [formControl]="lastName"
                (keypress)="loader.keyPressAlphabets($event)" (paste)="loader.pasteAlphabets($event)" />
            <mat-error *ngIf="lastName.invalid">{{'add-member.valid-last-name' | translate}}</mat-error>
        </mat-form-field>

        <app-datepicker id="dateofBirth" labelText="{{'add-member.dob' | translate}}" [dateInput]="dateOfBirth"
            (selectedDate)="onSSNChange()" isRequired="true" isRequiredError="true" [maxDate]="maxDate">
        </app-datepicker>
        <div *ngIf="primaryAccountHolder?.ssn && age<18">
            <label class="d-block">{{'add-member.useMySSN' | translate}}<span class="text-red">
                    *</span></label>
            <!-- <label class="d-block" *ngIf="primaryAccountHolder?.passport">{{'add-member.useMyPassport' |
                translate}}</label> -->
            <mat-radio-group [formControl]="useMySSN" (change)="onUseMySSNChange()" required>
                <mat-radio-button value="0">{{'add-member.yes'|translate}}
                </mat-radio-button>
                <mat-radio-button value="1">{{'add-member.no'|translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>


        <div *ngIf="useMySSN.value=='0' && primaryAccountHolder?.ssn && age<18">
            <mat-form-field [hidden]="!isSSNRequired" class="mat-form-field-disabled">
                <mat-label>{{'add-member.ssn' | translate}}</mat-label>
                <input matInput name="ssn" id="ssn" class="ssn-disabled" [formControl]="parentSsn" readonly />
            </mat-form-field>
        </div>

        <ng-container *ngIf="useMySSN.value=='1'">
            <div *ngIf="primaryAccountHolder?.ssn && age<18"></div>
            <div>
                <label class="d-block">{{'add-member.haveSSN' | translate}}<span class="text-red">
                        *</span></label>
                <mat-radio-group [formControl]="haveSSN" (change)="onHaveSSNChange()" required>
                    <mat-radio-button value="0">{{'add-member.yes'|translate}}
                    </mat-radio-button>
                    <mat-radio-button value="1">{{'add-member.no'|translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="only-web" *ngIf="!isSSNRequired"></div>
            <mat-form-field [hidden]="!isSSNRequired">
                <mat-label>{{'add-member.ssn' | translate}}</mat-label>
                <input matInput name="ssn" id="ssn" [formControl]="ssn" (blur)="onSSNChange(false)"
                    [required]="isSSNRequired" (input)="restrictSpace($event)" (keydown.space)="$event.preventDefault()"
                    (paste)="restrictSpace($event)" />
                <mat-error
                    *ngIf="ssn.invalid  && !ssn.hasError('ssnExist') && !ssn.hasError('parentSsn')">{{'add-member.valid-ssn'
                    | translate}}
                </mat-error>
                <mat-error
                    *ngIf="ssn.invalid  && !ssn.hasError('ssnExist') && ssn.hasError('parentSsn')">{{'add-member.new-ssn'
                    | translate}}
                </mat-error>
                <mat-error
                    *ngIf="ssn.invalid  && ssn.hasError('ssnExist') && !ssn.hasError('parentSsn')">{{'add-member.invalid-ssn'
                    | translate}}
                </mat-error>

            </mat-form-field>
        </ng-container>
        <mat-form-field id="nationalityFlag">
            <mat-label>{{'add-member.nationality' | translate}}</mat-label>
            <mat-select placeholder="{{'add-member.nationality' | translate}}"
                [ngStyle]="{'background': 'url(/assets/svg-country-flags/svg/'+selectedFlag+'.svg) left / contain no-repeat',' white-space': 'nowrap','display':'inline'}"
                name="Nationality" id="Nationality" [formControl]="nationality"
                (selectionChange)="onSelectNationality($event.value)">
                <mat-option class="country" *ngFor="let nationality of nationalities" [value]="nationality.id">
                    <img src="/assets/svg-country-flags/svg/{{nationality.country_code.toLowerCase()}}.svg" />
                    <span class="countryName">{{nationality.country_name}}</span>
                </mat-option>
            </mat-select>
            <mat-error *ngIf="nationality.invalid">{{'add-member.valid-nationality' | translate}}</mat-error>
        </mat-form-field>
        <div></div>

        <mat-form-field id="birthCountryFlag" class="mobile-mt">
            <mat-label>{{'add-member.birth-country' | translate}}</mat-label>
            <mat-select placeholder="{{'add-member.birth-country' | translate}}"
                [ngStyle]="{'background': 'url(/assets/svg-country-flags/svg/'+selectedBirthCountryFlag+'.svg) left / contain no-repeat',' white-space': 'nowrap','display':'inline'}"
                name="BirthCountry" id="BirthCountry" [formControl]="birthCountry"
                (selectionChange)="onSelectBirthCountry($event.value)">
                <mat-option class="country" *ngFor="let bc of nationalities" [value]="bc.id">
                    <img src="/assets/svg-country-flags/svg/{{bc.country_code.toLowerCase()}}.svg" />
                    <span class="countryName">{{bc.country_name}}</span>
                </mat-option>
            </mat-select>
            <mat-error *ngIf="birthCountry.invalid">{{'add-member.valid-birth-country' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="dropdown-filter" [hidden]="!isBirthCityRequired">
            <mat-label>{{'add-member.birth-city' | translate}}</mat-label>
            <input type="text" #searchBirthCityData matInput (click)="birthCity.setValue('')" name="birthCity"
                [formControl]="birthCity" (blur)="validateSelection()" [matAutocomplete]="auto"
                [required]="isBirthCityRequired" placeholder="{{'add-member.SelectCity'|translate}}">
            <mat-icon class="arrow_drop_down" matSuffix>arrow_drop_down</mat-icon>

            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
                (optionSelected)="setBirthCity($event)">
                <mat-option *ngFor="let ss of filteredBirthCities | async" [value]="ss">
                    {{ss.city_name}}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="birthCity.invalid">{{'add-member.invalid-birth-city' |
                translate}}</mat-error>
        </mat-form-field>
        <div *ngIf="!isBirthCityRequired"></div>

        <div>
            <label class="d-block">{{'add-member.useMyContact' | translate}}<span class="text-red">
                    *</span></label>
            <mat-radio-group [formControl]="useMyContact" (change)="onUseMyContactChange()" required>
                <mat-radio-button value="0">{{'add-member.yes'|translate}}
                </mat-radio-button>
                <mat-radio-button value="1">{{'add-member.no'|translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <ng-container>
            <div></div>
            <mat-form-field floatLabel="always" class="phone-ctrl">
                <mat-label>{{'add-member.phone' | translate}}</mat-label>
                <ngx-mat-intl-tel-input [preferredCountries]="['fr']" [enablePlaceholder]="true" name="phone" #phone
                    [formControl]="phoneNumber" [disabled]="useMyContact.value=='0'">
                </ngx-mat-intl-tel-input>
            </mat-form-field>


            <mat-form-field>
                <mat-label>{{'add-member.email' | translate}}</mat-label>
                <input type="email" matInput name="Email" maxlength="60" id="Email" [formControl]="email" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'add-member.address' | translate}}</mat-label>
                <input matInput type="text" id="autocomplete-input" name="address" autocomplete="off"
                    (focus)="isAutoCompleteFocused = true" [formControl]="address"
                    [disabled]="useMyContact.value=='0'" />
                <mat-error *ngIf="address.invalid">{{'add-member.valid-address' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'add-member.country' | translate}}</mat-label>
                <mat-select name="country" id="country" [formControl]="country" [disabled]="useMyContact.value=='0'">
                    <mat-option *ngFor="let nationality of nationalities" [value]="nationality.id">
                        {{nationality.country_name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="country.invalid">{{'add-member.valid-country' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'add-member.city' | translate}}</mat-label>
                <input matInput name="City" id="City" [formControl]="city" type="text"
                    [disabled]="useMyContact.value=='0'" />
                <mat-error *ngIf="city.invalid">{{'add-member.valid-city' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'add-member.zipcode' | translate}}</mat-label>
                <input matInput name="Zipcode" id="Zipcode" [formControl]="zip" disabled="true" />
                <mat-error *ngIf="zip.invalid">{{'add-member.valid-zipcode' | translate}}</mat-error>
            </mat-form-field>
        </ng-container>
    </div>

    <div class="actions">
        <mat-checkbox required [hidden]="isEdit || rmInfo?.is_rm_privacy_accepted" [formControl]="privacy"
            name="privacy">
            <div class="privacy-policy">{{'add-member.privacy-policy' |
                translate}}</div>
        </mat-checkbox>

        <!-- <div class="inner-btn">
            <button mat-raised-button color="primary" class="button_width" type="submit" id="btn_save">
                {{'add-member.btn-proceed' | translate}}
            </button>
        </div> -->

        <div class="action-btn" [ngClass]="{'mobile-booking':bookAppointment}">
            <button mat-raised-button class="next-btn" [ngClass]="{'edit':isEdit}" type="submit" type="button"
                (click)="submitRM()">
                {{ !isEdit ? ('next' | translate) : ('update' | translate) }}
            </button>
            <button mat-raised-button class="back-btn" type="button" *ngIf="!isEdit " (click)="goToBack.emit(true)">
                {{ 'back' | translate }}
            </button>
        </div>

        <!-- <div class="inner-btn" *ngIf="isEdit">
            <button mat-raised-button color="primary" class="button_width" type="button" id="btn_save" (click)="">
                {{'add-member.btn-update' | translate}}
            </button>
        </div> -->
    </div>
</form>

<ng-container *ngIf="step==2">
    <div class="create-account-container register-align">
        <app-doc-upload [isPatient]="false" [minor]="age<18" [nationality]="nationality.value" (backEmit)="back()"
            (submitEmit)="addMember($event)" [forRM]="true" doc_ids="0" [useMySSN]="useMySSN.value"></app-doc-upload>
    </div>
</ng-container>