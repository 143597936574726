<div class="pull-right">
    <button mat-icon-button color="accent" (click)="dismiss()">
        <span class="icon-ic-close"></span>
    </button>
</div>
<div class="dialog-container">
    <h5>{{ data.title | translate}}</h5>
    <div class="dialog-content">
        <b>{{ data.station.sampling_station_name}}</b>
        <br>
        <span>{{data.station.address}}</span>
    </div>

    <div style="display:flex;flex-direction: column;z-index:9999;height:100%">
        <ng-container *ngFor="let daydet of dayDate;let index">
            <div style="display:flex;justify-content: space-between;"
                [ngStyle]="daydet.isToday?{'font-weight':'600'}:{'font-weight':''}"><label>{{daydet.day }}</label><label
                    style="word-break: break-word;">{{daydet.time }}</label></div>
        </ng-container>
    </div>

    <div class="actions">
        <button mat-button (click)="dismiss()" color="accent" class="ghost" *ngIf="data.cancelLabel">{{ data.cancelLabel
            | translate
            }}</button>
        <button mat-raised-button color="primary" type="submit" class="button_content" (click)="confirmed()">{{
            data.confirmLable | translate}}</button>
    </div>
</div>