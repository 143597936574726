import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PartialResultComponent } from './partial-result/partial-result.component';
import { ResultGridComponent } from './result-grid/result-grid.component';
import { LoginComponent } from '../../features/login/login.component';
import { EstablishementComponent } from './establishement/establishement.component';
import { ListDepartmentComponent } from './list-department/list-department.component';
import { HealthcareProfessionalComponent } from './healthcare-professional/healthcare-professional.component';
import { UserListComponent } from './user-list/user-list.component';

const routes: Routes = [
  { path: 'partial', component: PartialResultComponent },
  { path: 'result', component: ResultGridComponent },
  { path: 'login', component: LoginComponent },
  { path: 'establishment', component: EstablishementComponent },
  { path: 'listdepartment', component: ListDepartmentComponent },
  { path: 'healthcare-professional', component: HealthcareProfessionalComponent },
  { path: 'listuser', component: UserListComponent },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EhpRoutingModule { }
