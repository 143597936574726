import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CommonApiService } from '../../services/common-api.service';
import { LocaleService } from '../../../app.locale.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SamplingStations } from '../user/User';
import { Company } from '../../models/common.model';
import { LoaderService, MessageService } from '../../../main';
import { AddHolidayComponent } from './add-holiday/add-holiday.component';
import { MatDialog } from '@angular/material/dialog';
import { Holiday } from './manage-holiday.model';
import { constants } from '../../shared/constant';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoginServiceService } from '../../services/login-service.service';
import { MasterData } from '../ss-slot-configuration/ss-slot-configuration.model';
import { SpinnerService } from '../../../core';

@Component({
  selector: 'app-manage-holiday',
  templateUrl: './manage-holiday.component.html',
  styleUrls: ['./manage-holiday.component.scss']
})
export class ManageHolidayComponent implements OnInit {
  public ssLevelCode: string = constants.ssLevelCode;
  public companyLevelCode: string = constants.companyLevelCode;
  public countryLevelCode: string = constants.countryLevelCode;
  public companies: Company[] = null;
  public myControl = new FormControl('');
  public s_id: any;
  public savealert = {
    edit: "admin.save-alert.edit",
    add: "admin.save-alert.add",
  };
  public type1columnList = ['org_name', 'sampling_station_name', 'start_date', 'end_date', 'level', 'reason', 'Options'];
  public type2columnList = ['org_name', 'start_date', 'end_date', 'level', 'reason', 'Options'];
  public type3columnList = ['start_date', 'end_date', 'level', 'reason', 'Options'];
  public dataSource: MatTableDataSource<Holiday>;


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public filteredOptions: Observable<any[]>;
  public samplingStations: any[];
  public allSamplingStations: any[];
  public selectedSamplingStation: any[];

  public disableDropDowns = true;
  public Company = new FormControl(['']);
  public HolidayType = new FormControl();
  public SamplingStationsIds = new FormControl(['']);
  public levels: Array<MasterData>;
  selectedLevel: MasterData;
  public loaded: boolean = false;
  public FilterForm = this.fb.group({
    Company: this.Company,
    SamplingStationsIds: this.SamplingStationsIds,
    HolidayType: this.HolidayType,
  });
  public userRole: number;
  constructor(public commonAPi: CommonApiService,
    public localeService: LocaleService, private translate: TranslateService,
    public fb: FormBuilder, public loaderService: LoaderService, private spinner: SpinnerService,
    public messageService: MessageService, private readonly dialog: MatDialog, public loginService: LoginServiceService
  ) {
    this.dataSource = new MatTableDataSource<any>();
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang,);

    this.selectedSamplingStation = new Array<any>();
    this.allSamplingStations = new Array<any>();
    this.samplingStations = new Array<SamplingStations>();
    this.levels = new Array<MasterData>();
    this.selectedLevel = new MasterData();
    registerLocaleData(this.localeService.localeLang == 'fr' ? localeFr : localeEn);
  }

  ngOnInit(): void {
    this.myControl.disable();
    this.userRole = JSON.parse(sessionStorage.getItem("access_token"))?.role;
    console.log(this.userRole);
    this.getLevels();

    this.dataSource.sortingDataAccessor = (
      data: any,
      sortHeaderId: string
    ): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }
      return data[sortHeaderId];
    };

    this.getAllCompany();

  }

  getAllCompany() {
    this.commonAPi.GetAllCompany().subscribe(x => {
      this.companies = x;
      this.companies.sort((a, b) => {
        const nameA = a.org_name.toLowerCase().trim();
        const nameB = b.org_name.toLowerCase().trim();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.getAllSamplingStations();
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public getLevels() {
    this.loginService.getLevelMasters().subscribe((data: Array<any>) => {

      this.levels = data.sort((a, b) => a.KeyOrder - b.KeyOrder);

      let countryLevel = this.levels.find(i => i.Key == this.countryLevelCode);
      let companyLevel = this.levels.find(i => i.Key == this.companyLevelCode);

      let ssLevel = this.levels.find(i => i.Key == this.ssLevelCode);
      if (this.userRole == constants.supervisorRole) {

        this.HolidayType.setValue(companyLevel);
        this.levels.splice(0, 1);
        this.FilterForm = this.fb.group({
          Company: this.Company,
          SamplingStationsIds: this.SamplingStationsIds,
          HolidayType: this.HolidayType,
        });
        this.selectedLevel = companyLevel;
      } else if (this.userRole == constants.nurseRole) {
        this.HolidayType.setValue(ssLevel);
        this.levels.splice(0, 2);
        this.FilterForm = this.fb.group({
          Company: this.Company,
          SamplingStationsIds: this.SamplingStationsIds,
          HolidayType: this.HolidayType,
        });
        this.selectedLevel = ssLevel;
      }
      else {
        this.HolidayType.setValue(countryLevel);
        this.FilterForm = this.fb.group({
          Company: this.Company,
          SamplingStationsIds: this.SamplingStationsIds,
          HolidayType: this.HolidayType,
        });
        this.selectedLevel = countryLevel;
      }
      this.loaded = true;
      this.getHolidays();
    });
  }

  onLevelChange(level: any) {
    this.selectedLevel = level;
    this.HolidayType.setValue(this.selectedLevel);
    this.getHolidays();
  }

  public getHolidays() {

    let selectedSS: any = this.FilterForm.get("SamplingStationsIds").value;
    let selectedCompany: any = this.FilterForm.get("Company").value;
    if (this.selectedLevel.Key == this.countryLevelCode) {
      selectedCompany = "";
      selectedSS = "";
    }
    else if (this.selectedLevel.Key == this.companyLevelCode) {
      selectedSS = "";
    }
    let payload = {
      "holiday_type": this.HolidayType.value.Key,
      "org_ids": selectedCompany ? selectedCompany.map((item: any) => item.id).join(",") : "",
      "ss_ids": selectedSS ? selectedSS.map((item: any) => item.id).join(",") : ""

    }
    this.dataSource = new MatTableDataSource<any>();
    this.commonAPi.getHolidays(payload).subscribe((holidays: Array<Holiday>) => {
      if (holidays) {
        this.dataSource = new MatTableDataSource(holidays);

      } else {
        this.dataSource.data = [];
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  public getAllSamplingStations() {
    this.commonAPi.GetAllSamplingStations(true).subscribe((stationsList: any) => {
      this.allSamplingStations = stationsList.sampling_stations;
      this.samplingStations = stationsList.sampling_stations;
      this.selectedSamplingStation = stationsList.sampling_stations;
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    });
  }

  public _filter(value?: any): any[] {
    if (this.samplingStations != null) {
      if (typeof (value) == 'string') {
        const filterval = value?.toLowerCase();
        return this.samplingStations.filter(option => option.sampling_station_name.toLowerCase().includes(filterval));
      } else {
        const filterval = value?.sampling_station_name?.toLowerCase();
        return this.samplingStations.filter(option => option.sampling_station_name.toLowerCase().includes(filterval));

      }

    }
    return null;
  }

  ssFilterOnCompany(event: any) {
    if (event.length == 0) {
      this.myControl.disable();
      this.FilterForm.controls["SamplingStationsIds"].setValue([]);
      this.myControl.setValue("");
      this.FilterForm.controls["Company"].setValue([]);
      this.disableDropDowns = true;
    }
    else {
      this.disableDropDowns = false;
      this.myControl.enable();

    }

    this.samplingStations = Object.assign([], this.selectedSamplingStation);
    const cArr = event.map((item: any) => item.id);
    const myArrayFiltered = this.samplingStations.filter((el: any) => {
      return cArr.some((f: any) => {
        return f === el.org_id;
      });
    });
    this.samplingStations = myArrayFiltered;

    this.getHolidays();
  }

  allChecked(event: any) {
    if (event) {
      let allCompanies: any[] = [];
      Object.assign(allCompanies, this.companies);
      this.FilterForm.controls['Company'].setValue(allCompanies);
      this.samplingStations = this.allSamplingStations;
      this.disableDropDowns = false;
      this.myControl.enable();

    } else {
      this.FilterForm.controls['SamplingStationsIds'].setValue([]);
      this.myControl.setValue('');
      this.FilterForm.controls['Company'].setValue([]);
      this.samplingStations = [];
      this.disableDropDowns = true;
      this.myControl.disable();

    }
    this.getHolidays();
  }

  deleteHoliday(holiday: any) {
    let popupRef = this.messageService
      .confirm(
        this.translate.instant('manage-holiday.delete-confirm-description'),
        this.translate.instant('manage-holiday.delete-title'),
        this.translate.instant('manage-holiday.yes'),
        this.translate.instant('manage-holiday.no')
      );

    const params = {
      id: holiday.id,
      reference_id: holiday.reference_id,
      org_id: holiday.org_id,
      link_time: holiday.link_time,
      sampling_station_id: holiday.ss_id,
    };

    popupRef.subscribe((actionResult) => {
      if (actionResult) {
        this.commonAPi.deleteHoliday(params).subscribe(
          () => {
            this.messageService.success(
              this.translate.instant('admin.save-alert.delete')
            );
            this.getHolidays();
          },
          () => {
            this.messageService.alert(
              this.translate.instant("add-holiday.error.422")
            );
          }
        );
      }
    });
  }

  addHoliday() {
    let ccLevel = this.levels.find((i: any) => i.Key == this.countryLevelCode);
    const dialogRef = this.dialog.open(AddHolidayComponent, {
      width: "500px",
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
      data: { action: "add", edit: false, savealert: this.savealert, levels: this.levels, selectedLevel: ccLevel },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getHolidays();
    });
  }

  editHoliday(entity: Holiday) {
    this.spinner.show(true);
    const dialogRef = this.dialog.open(AddHolidayComponent, {
      width: "500px",
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
      data: { action: "edit", edit: true, savealert: this.savealert, holiday: entity, levels: this.levels, selectedLevel: this.selectedLevel },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getHolidays();
    });
  }

  public redirectCalendarManagement() {
    this.loaderService.highlightLeftMenu(['admin/slot-management']);
  }

  public ssChange(value: any) {
    this.myControl.setValue(value.sampling_station_name);
    this.FilterForm.controls['SamplingStationsIds'].setValue([value]);
    this.getHolidays();
  }

  public resetControl() {
    this.myControl.setValue('');
    this.FilterForm.controls["SamplingStationsIds"].setValue([]);
    this.getHolidays();
  }
}
