import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ColumnChooserComponent } from './column-chooser/column-chooser.component';
import { ColumnFilterListComponent } from './column-filter/column-filter-list.component';
import { ColumnFilterComponent } from './column-filter/column-filter.component';
import { ColumnListComponent } from './column-list/column-list.component';
import { CopyTextComponent } from './copy-text/copy-text.component';
import { FileDropDirective } from './custom-directives/file-drop.directive';
import { PanDirective } from './custom-directives/pan.directive';
import { TooltipDisableDirective } from './custom-directives/tooltip.directive';
import { SearchPipe } from './custom-pipe/search-callback.pipe';
import { TruncatePipe } from './custom-pipe/truncate.pipe';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DragDropListComponent } from './drag-drop-list/drag-drop-list.component';
import { FooterComponent } from './footer/footer.component';
import { GlobalErrorComponent } from './global-error/global-error.component';
import { MaterialModule } from './material.module';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { ProgressListComponent } from './progress-list/progress-list.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { SectionTitleComponent } from './section-title/section-title.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { ViewMoreComponent } from './view-more/view-more.component';
import { WarningMessageComponent } from './warning-message/warning-message.component';
import { DescriptionShortenerPipe } from '../eClinical/pipes/description-shortener.pipe';
import { PhonenoFormatePipe } from '../eClinical/pipes/phoneno-formate.pipe';
import { RemoveZeroPipe } from '../eClinical/pipes/remove-zero.pipe';
import { AutoScrollDirective } from './custom-directives/auto-scroll.directive';
@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        DragDropModule,
        TranslateModule,

    ],
    declarations: [
        ColumnChooserComponent,
        SearchPipe,
        SectionTitleComponent,
        TruncatePipe,
        SvgIconComponent,
        FooterComponent,
        ColumnListComponent,
        ColumnFilterComponent,
        ColumnFilterListComponent,
        DragDropListComponent,
        GlobalErrorComponent,
        FileDropDirective,
        TimePickerComponent,
        CopyTextComponent,
        ProgressListComponent,
        SearchBoxComponent,
        MultiSelectDropdownComponent,
        WarningMessageComponent,
        PanDirective,
        ViewMoreComponent,
        ColorPickerComponent,
        TooltipDisableDirective,
        DatepickerComponent,
        DescriptionShortenerPipe,
        PhonenoFormatePipe,
        RemoveZeroPipe,
        AutoScrollDirective,
    ],
    exports: [
        ColumnChooserComponent,
        SearchPipe,
        SectionTitleComponent,
        TruncatePipe,
        SvgIconComponent,
        FooterComponent,
        ColumnListComponent,
        ColumnFilterComponent,
        DragDropListComponent,
        FileDropDirective,
        TimePickerComponent,
        CopyTextComponent,
        GlobalErrorComponent,
        SearchBoxComponent,
        MultiSelectDropdownComponent,
        WarningMessageComponent,
        PanDirective,
        TooltipDisableDirective,
        ViewMoreComponent,
        ColorPickerComponent,
        ProgressListComponent,
        DatepickerComponent,
        DescriptionShortenerPipe,
        PhonenoFormatePipe,
        RemoveZeroPipe,
        AutoScrollDirective,
    ]
})
export class SharedModule { }
