import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit, AfterViewInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import * as CryptoJS from "crypto-js";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { SpinnerService } from "../../../core/spinner/spinner.service";
import { MessageService } from "../../../main";
import { LoaderService } from "../../../main/services/loader.service";
import { CommonApiService } from "../../services/common-api.service";
import { LoginServiceService } from "../../services/login-service.service";
import { PatientService } from "../../services/patient.service";
import { constants } from "../constant";
import { AppSettingsService } from "../../../core";
import { GoogleMapService } from "../lab-google/google-map.service";
import { PrescriptionDocument } from "../appointment-prescription/appointment-prescription.model";

import { PasswordPolicyService } from "../password-policy/password-policy.service";
import { NgOtpInputConfig } from "ng-otp-input";
import { AuthenticationService } from "../../services/authentication.service";
import { MatDialog } from "@angular/material/dialog";
import { VerifyOtpComponent } from "../verify-otp/verify-otp.component";
import { PrefillPatientData } from "../find-my-account/find-my-account.model";
import jwtDecode from "jwt-decode";
declare var woosmap: any;
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit, AfterViewInit {
  @Input() sliderFormData: any;
  @Input() fromAppointment: any = false;
  @Input() prefilMode: boolean = false;
  @Input() prefilData: PrefillPatientData;
  @Input() unique_id: string;
  @Input() public isSliderDataEdited = false;
  @Input() public otp: string;
  @Input() public verifyID: string;
  @Input() public noRecordMode: boolean = false;
  @Input() public searchData: any;

  @Output() goToBack = new EventEmitter<any>();
  @Output() activeStep = new EventEmitter<number>();
  @Output() Registered = new EventEmitter<any>();
  @Output() backToLogin = new EventEmitter<any>();
  @Output() hideRadio = new EventEmitter<any>();
  @Input() public appointmentId = 0;
  @Input() displayMap: boolean = true;
  @Output() skipMe = new EventEmitter<any>();
  @Input() otpRequired: boolean = true;
  public showBackBtn: boolean = true;
  public age: any;
  public isUpdate: boolean;
  public isHovering: boolean;
  public isEmailNotificationRequired: boolean;
  public isSaveClicked: boolean;
  public insurance = new FormControl("");
  public choices = [
    { Id: 1, KeyName: "Yes" },
    { Id: 2, KeyName: "No" },
  ];
  maxDate: Date;
  protected minDate = new Date();
  nationalities: any[];
  place: any;
  isLoginReceptionist: boolean;
  selectedFlag: any;
  public birthName = new FormControl("", [
    Validators.required,
    Validators.pattern(".{1,50}$"),
  ]);
  public firstName = new FormControl("", [
    Validators.required,
    Validators.pattern(".{1,50}$"),
  ]);
  public lastName = new FormControl("", [
    Validators.required,
    Validators.pattern(".{1,50}$"),
  ]);
  public phoneNumber = new FormControl("", [Validators.required]);
  public email = new FormControl("", [
    Validators.required,
    Validators.pattern(this.patientService.emailPattern),
  ]);
  public dateOfBirth = new FormControl("", [Validators.required]);
  public address = new FormControl("", [Validators.required]);
  public city = new FormControl("", [
    Validators.pattern("^.{1,50}$"), Validators.required
  ]);
  public haveSSN = new FormControl("0");
  public sameLastName = new FormControl("0");
  isBirthCityRequired = true;
  selectedBirthCountryName = "";
  public filteredBirthCities: Observable<any[]>;
  birthCities: any[];
  public birthCity = new FormControl({ value: '', disabled: true });
  public birthCountry = new FormControl("", [Validators.required]);
  public zip = new FormControl("", [
    Validators.pattern("^[0-9 ]{4,10}$"), Validators.required
  ]);
  public password = new FormControl("", [
    Validators.pattern(constants.passwordValidation),
    this.noWhitespaceValidator,
    Validators.required,
  ]);

  public repeatPassword = new FormControl("");

  public ssn = new FormControl("", [
    Validators.pattern("^.{1,15}$"),
    Validators.pattern(this.patientService.ssnPattern),
  ]);

  public gender = new FormControl("", [Validators.required]);
  public nationality = new FormControl("", [Validators.required]);
  public country = new FormControl("", [Validators.required]);
  public terms = new FormControl(false, [Validators.requiredTrue]);
  public privacy = new FormControl(false, [Validators.requiredTrue]);
  public display: any;
  public btndisabled = true;
  public showOTPScreen: boolean = false;
  public config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
  };
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;

  public form = this.formBuilder.group({
    birthName: this.birthName,
    firstName: this.firstName,
    lastName: this.lastName,
    phoneNumber: this.phoneNumber,
    email: this.email,
    dateOfBirth: this.dateOfBirth,
    address: this.address,
    city: this.city,
    zip: this.zip,
    ssn: this.ssn,
    gender: this.gender,
    nationality: this.nationality,
    country: this.country,
    terms: this.terms,
    privacy: this.privacy,
    birthCountry: this.birthCountry,
    birthCity: this.birthCity,
    password: this.password,
    repeatPassword: this.repeatPassword,
    deviceDetail: ""
  });


  editPatientDetail: any;
  genders: any[];
  isSSNRequired = true;
  countryList: any;
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  editMode: boolean;
  Name: string;
  isPasswordValid: boolean;
  isAutoCompleteFocused: boolean;
  selectedCountryValue = 0;
  selectedBirthCountryFlag: any;
  showHaveSSN = false;
  autocomplete: any;
  public identityDocuments: PrescriptionDocument = {
    Id: 1,
    PrescriptionParts: [],
    IsIncorrectFileFormat: false,
    IsInvalidFileSize: false,
    Expanded: true,
  };
  public birthDocuments: PrescriptionDocument = {
    Id: 2,
    PrescriptionParts: [],
    IsIncorrectFileFormat: false,
    IsInvalidFileSize: false,
    Expanded: true,
  };
  public insuranceDocuments: PrescriptionDocument = {
    Id: 3,
    PrescriptionParts: [],
    IsIncorrectFileFormat: false,
    IsInvalidFileSize: false,
    Expanded: true,
  };
  public identityItem = {
    Id: 1,
    FrontFileName: "",
    BackFileName: "",
    IsIncorrectFrontFileFormat: false,
    IsInvalidFrontFileSize: false,
    IsIncorrectBackFileFormat: false,
    IsInvalidBackFileSize: false,
    PrescriptionParts: ([] = []),
    Expanded: true,
  };
  public birthItem = {
    Id: 2,
    FrontSideData: "",
    BackSideData: "",
    FrontFileName: "",
    BackFileName: "",
    IsIncorrectFrontFileFormat: false,
    IsInvalidFrontFileSize: false,
    IsIncorrectBackFileFormat: false,
    IsInvalidBackFileSize: false,
    PrescriptionParts: ([] = []),
    Expanded: true,
  };
  public insuranceItem = {
    Id: 3,
    FrontSideData: "",
    BackSideData: "",
    FrontFileName: "",
    BackFileName: "",
    IsIncorrectFrontFileFormat: false,
    IsInvalidFrontFileSize: false,
    IsIncorrectBackFileFormat: false,
    IsInvalidBackFileSize: false,
    PrescriptionParts: ([] = []),
    Expanded: true,
  };
  public step1: boolean = true;
  public step: number = 1;
  public otp_verified: boolean = false;
  formSubmitted: boolean = false;
  public uploaded_doc_info: any;
  public tokenResponse: any;
  public deviceId: string;
  protected isRedirection: boolean = false;
  protected redirectGender: string = "";
  protected redirectBirthCity: string = "";
  protected redirectMinorPassport: boolean;
  protected redirectPrivateInsurance: boolean;
  public bookAppointment: boolean = false;
  public showVerified: boolean;
  doc_id: string = '0';
  isMassiveTesting: boolean = false;
  constructor(
    private readonly dialog: MatDialog,
    public router: Router,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private patientService: PatientService,
    public loader: LoaderService,
    private googleMapService: GoogleMapService,
    private formBuilder: FormBuilder,
    public loginService: LoginServiceService,
    public translate: TranslateService,
    private adminService: CommonApiService,
    private authService: AuthenticationService,
    private readonly appSettings: AppSettingsService,
    private passwordPolicy: PasswordPolicyService,
    private route: ActivatedRoute
  ) {
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.genders = new Array<any>();
  }
  ngOnInit(): void {
    if (window.location.href.split("#/")[1].includes('test/massive')) {
      this.isMassiveTesting = true;
    }

    if (window.location.href.includes("elevate") || window.location.href.includes("patient/complete-appointment")) {
      this.unique_id = this.route.snapshot.params["unique_id"];

      if (window.location.href.includes("patient/complete-appointment")) {
        this.doc_id = this.route.snapshot.params["doc_id"];
        this.unique_id = this.route.snapshot.params["u_id"];
        let active = this.loader.toBoolean(this.route.snapshot.params["is_active"]);
        if (!active) {
          this.showBackBtn = false;
        }
      }

      this.patientService.getPatientInfo(this.unique_id).subscribe((response: any) => {
        if (response) {
          this.prefilData = response;
          this.isRedirection = true;
          this.redirectGender = response.gender;
          this.getGenders();
          this.redirectBirthCity = response.birth_city
          this.getBirthCities();
          this.redirectMinorPassport = response.minor_passport
          this.redirectPrivateInsurance = response.private_insurance
          if (response.last_name == response.birth_name) {
            this.sameLastName.patchValue("0");
          } else {
            this.sameLastName.patchValue("1");
            this.form.controls.lastName.enable();
          }
          this.form.patchValue({
            firstName: response.first_name,
            lastName: response.last_name,
            birthName: response.birth_name,
            email: response.email,
            nationality: response.nationality == 0 ? "" : response.nationality.toString(),
            birthCountry: response.birth_country == 0 ? "" : response.birth_country.toString(),
            birthCity: response.birth_city == 0 ? "" : response.birth_city,
            ssn: response.ssn,
            phoneNumber: "+" + response.phone_number,
            address: response.address,
            country: response.country_id == 0 ? "" : response.country_id,
            city: response.city,
            zip: response.zip,
            terms: false,
            privacy: false
          });
          this.form.controls.birthCountry.setValue(
            response.birth_city
          );

          this.haveSSN.setValue("0");
          this.onHaveSSNChange();
          this.isSSNRequired = true;

          if (response.birth_country != constants.nationality) {
            this.isBirthCityRequired = false;
          }

          this.patientService.getCoutryFlags().subscribe(
            (data: any) => {
              this.nationalities = data;
              const frNationIndex = this.nationalities.findIndex((i: any) => i.id == constants.nationality);
              const frNation = this.nationalities[frNationIndex];
              this.nationalities.splice(frNationIndex, 1);
              this.nationalities.sort((i) => i.country_name);
              this.nationalities.unshift(frNation);
              const toSelect = this.nationalities.find(
                (c) => c.id == response.nationality
              );
              const toBCSelect = this.nationalities.find(
                (c) => c.id == response.birth_country
              );
              this.selectedFlag = toSelect?.country_code.toLowerCase();
              this.selectedBirthCountryFlag = toBCSelect?.country_code.toLowerCase();
              this.countryList = data;
              if (response.birth_country != "")
                this.onSelectBirthCountry(response.birth_country);
              this.form.patchValue({
                nationality: response.nationality,
              });
            },
            (error: any) => {
              console.log(error);
            }
          );
          this.birthCountry.setValue(response.birth_country);

          this.dateOfBirth.setValue(response.dob.substring(0, 10));
          this.form.controls["email"].disable();
          if (response.email == '') {
            this.form.controls["phoneNumber"].disable();
          }
        }
      });
    }

    const self = this;
    this.insurance.setValue("1");
    if (this.noRecordMode) {
      this.firstName.setValue(this.searchData.firstName);
      this.birthName.setValue(this.searchData.birthName);
      this.lastName.setValue(this.searchData.birthName);
      this.dateOfBirth.setValue(this.searchData.dob);
    }
    if (this.sliderFormData && this.sliderFormData.isEdit) {
      this.editMode = true;
      this.insurance.setValue(
        this.sliderFormData.patientInfo.private_insurance == "Yes" ||
          this.sliderFormData.patientInfo.private_insurance ? "1" : "2"
      );
    }
    self.getBirthCities();
    document.getElementsByClassName("app-sidenav-content")[0]?.addEventListener(
      "scroll",
      () => {
        if (self.isAutoCompleteFocused) {
          const elements: any =
            document.getElementsByClassName("pac-container");
          for (let i = 0; i < elements.length; ++i) {
            elements[i].style.display = "none";
          }
        }
      },
      false
    );

    this.isLoginReceptionist =
      JSON.parse(sessionStorage.getItem("access_token"))?.role == constants.nurseRole;
    if (this.editMode) {
      this.password = new FormControl("", [
        Validators.pattern(constants.passwordValidation),
      ]);
      this.form.controls["password"] = this.password;
    }

    else if (
      this.isLoginReceptionist ||
      (this.loader.userRole > 0 && this.loader.userRole != constants.patientRole)
    ) {
      this.address = new FormControl("");
      this.city = new FormControl("");
      this.country = new FormControl("");
      this.zip = new FormControl("");
      this.password = new FormControl("", [
        Validators.pattern(constants.passwordValidation),
      ]);

      this.form.controls["address"] = this.address;
      this.form.controls["city"] = this.city;
      this.form.controls["zip"] = this.zip;
      this.form.controls["country"] = this.country;
      this.form.controls["password"] = this.password;
      this.terms = new FormControl(false);
      this.privacy = new FormControl(false);
      this.form.controls["terms"] = this.terms;
      this.form.controls["privacy"] = this.privacy;

    }
    this.addressAutocomplete();
    this.password.valueChanges.subscribe(() => {
      this.showErrorBox();
    });
    //this.getDocumentType();
    if (this.prefilMode && !window.location.href.includes("patient/complete-appointment")) {

      // Check if the phoneNumber is prefilled
      if (this.prefilData.email == "") {
        // Disable phone field and enable email field
        this.form.controls["phoneNumber"].disable();
        this.form.controls["email"].enable();
      } else {
        // Enable phone field and disable email field
        this.form.controls["phoneNumber"].enable();
        this.form.controls["email"].disable();
      }

      this.prefilData.last_name == this.prefilData.birth_name ? this.sameLastName.setValue("0") : this.sameLastName.setValue("1");
      // this.form.controls["lastName"].setValue() ;

      this.form.patchValue({
        firstName: this.prefilData.first_name,
        lastName: this.prefilData.last_name,
        birthName: this.prefilData.birth_name,
        email: this.prefilData.email,
        nationality: this.prefilData.nationality == 0 ? "" : this.prefilData.nationality.toString(),
        birthCountry: this.prefilData.birth_country == 0 ? "" : this.prefilData.birth_country.toString(),
        birthCity: this.prefilData.birth_city == 0 ? "" : this.prefilData.birth_city,
        ssn: this.prefilData.ssn,
        phoneNumber: "+" + this.prefilData.phone_number,
        address: this.prefilData.address,
        country: this.prefilData.country_id == 0 ? "" : this.prefilData.country_id,
        city: this.prefilData.city,
        zip: this.prefilData.zip,
        terms: false,
        privacy: false
      });

      this.haveSSN.setValue("0");

      this.onHaveSSNChange();
      this.isSSNRequired = true;

      if (this.prefilData.birth_country != constants.nationality) {
        this.isBirthCityRequired = this.prefilData.birth_country == 0 ? true : false;
      }

      this.dateOfBirth.setValue(this.prefilData.dob.substring(0, 10));

      this.patientService.getCoutryFlags().subscribe(
        (data: any) => {
          this.countryList = data;
          this.form.patchValue({
            nationality: this.prefilData.nationality.toString(),
            birthCity: this.prefilData.birth_city.toString()
          });
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
    if (this.editMode) {
      this.getGenders();
      this.editPatientDetail = this.sliderFormData.patientInfo;
      this.editPatientDetail.BirthCountry = this.editPatientDetail.BirthCountry == 0 ? "" : this.editPatientDetail.BirthCountry
      this.editPatientDetail.Nationality = this.editPatientDetail.Nationality == 0 ? "" : this.editPatientDetail.Nationality
      this.editPatientDetail.BirthCity = this.editPatientDetail.BirthCity == 0 ? "" : this.editPatientDetail.BirthCity
      this.editPatientDetail.CountryId = this.editPatientDetail.CountryId == 0 ? "" : this.editPatientDetail.CountryId

      this.editPatientDetail.LastName == this.editPatientDetail.BirthName ? this.sameLastName.setValue("0") : this.sameLastName.setValue("1");

      this.form.controls.nationality.setValue(
        this.editPatientDetail.Nationality
      );
      this.form.controls.birthCountry.setValue(
        this.editPatientDetail.BirthCountry
      );

      if (this.editPatientDetail.Ssn != "") {
        this.showHaveSSN = false;
        this.haveSSN.setValue("0");
      } else {
        this.showHaveSSN = true;
        this.haveSSN.setValue("1");
      }

      this.onHaveSSNChange();

      if (this.editPatientDetail.BirthCountry != constants.nationality) {
        this.isBirthCityRequired = false;
      }

      this.form.controls.terms.setValue(
        this.editPatientDetail.is_terms_accepted
      );
      this.form.controls.privacy.setValue(
        this.editPatientDetail.is_privacy_accepted
      );

      if (this.editPatientDetail.Ssn !== "") {
        this.isSSNRequired = true;
      } else {
        this.isSSNRequired = false;
      }

      this.dateOfBirth.setValue(this.editPatientDetail.Dob.substring(0, 10));
      this.Name =
        this.editPatientDetail.FirstName +
        " " +
        this.editPatientDetail.LastName +
        " " +
        this.editPatientDetail.BirthName;
      this.form.patchValue({
        firstName: this.editPatientDetail.FirstName,
        lastName: this.editPatientDetail.LastName,
        birthName: this.editPatientDetail.BirthName,
        email: this.editPatientDetail.Email,
        nationality: this.editPatientDetail.Nationality,
        birthCountry: this.editPatientDetail.BirthCountry,
        birthCity: this.editPatientDetail.BirthCity,
        ssn: this.editPatientDetail.Ssn,
        phoneNumber: "+" + this.editPatientDetail.PhoneNumber,
        address: this.editPatientDetail.Address,
        country: this.editPatientDetail.CountryId,
        city: this.editPatientDetail.City,
        zip: this.editPatientDetail.Zip,
        terms: true,
        privacy: true
      });

      this.terms.setValue(true);
      this.privacy.setValue(true);
      this.form.controls["email"].disable();
      if (this.editPatientDetail.Email == '') {
        this.form.controls["phoneNumber"].disable();
      }

      this.patientService.getCoutryFlags().subscribe(
        (data: any) => {
          this.countryList = data;
          this.form.patchValue({
            nationality: this.editPatientDetail.Nationality,
          });
        },
        (error: any) => {
          console.log(error);
        }
      );
    } else {
      this.getGenders();
    }
    if (this.sameLastName.value == "0") {
      // this.form.controls.birthName.setValue(this.form.controls.lastName.value);
      this.form.controls.lastName.disable();
    }

    this.loginService.getAdminCommonCountries().subscribe((data: any[]) => {
      this.nationalities = data;
      const frNationIndex = this.nationalities.findIndex((i: any) => i.id == constants.nationality);
      const frNation = this.nationalities[frNationIndex];
      this.nationalities.splice(frNationIndex, 1);
      this.nationalities.sort((i) => i.country_name);
      this.nationalities.unshift(frNation);
      if (this.editMode) {
        this.country.setValue(this.editPatientDetail.CountryId);
        this.nationality.setValue(this.editPatientDetail.Nationality);
        if (this.editPatientDetail.Nationality != constants.nationality) {
          this.showHaveSSN = true;
        } else {
          this.showHaveSSN = false;
        }
        this.birthCountry.setValue(this.editPatientDetail.BirthCountry);
        const toSelect = this.nationalities.find(
          (c) => c.id == this.editPatientDetail.Nationality
        );
        const toBCSelect = this.nationalities.find(
          (c) => c.id == this.editPatientDetail.BirthCountry
        );
        this.selectedFlag = toSelect?.country_code.toLowerCase();
        this.selectedBirthCountryFlag = toBCSelect?.country_code.toLowerCase();
        if (this.editPatientDetail.BirthCountry != "")
          this.onSelectBirthCountry(this.editPatientDetail.BirthCountry);
      } else {
        const toSelect = this.nationalities.find((c) => c.id == 1);
        this.selectedFlag = toSelect?.country_code.toLowerCase();
        this.selectedBirthCountryFlag = toSelect?.country_code.toLowerCase();

        this.nationality.setValue(toSelect?.id);
        this.showHaveSSN = true;
        this.birthCountry.setValue(toSelect?.id);
      }
    });
    if (window.location.href.includes("patient/complete-appointment")) {
      this.showVerified = false;
    } else {
      this.showVerified = true;
    }
    if ((window.location.href.includes("patient/book-appointment") || window.location.href.includes("patient/complete-appointment")) && screen.width < 962) {
      this.bookAppointment = true;
    }
  }

  ngAfterViewInit(): void {
    if (typeof woosmap !== 'undefined') {
      //this.initializeMap();
      this.addressAutocomplete();
    } else {
      console.error('Woosmap SDK is not loaded!');
    }
  }
  //this.form.controls.
  public addressAutocomplete() {
    this.googleMapService.api.then(() => {
      const options = {
        "components": {
          country: this.appSettings?.country?.split(";")
        },
        "types": ['locality', 'postal_code', 'address', 'admin_level', 'country', 'train_station', 'metro_station', 'shopping', 'airport', 'museum', 'zoo', 'amusement_park', 'art_gallery', 'tourist_attraction']
      };
      //@ts-ignore
      const localitiesWidget = new window.woosmap.localities.Autocomplete("autocompleteinput", options);
      localitiesWidget.addListener("selected_suggestion", () => {
        this.spinnerService.show(true);
        const selectedLocality = localitiesWidget.getSelectedSuggestionDetails();
        console.log(selectedLocality);
        if (selectedLocality.address_components) {
          console.log("address:", selectedLocality.formatted_address);
          this.form.controls.address.setValue(selectedLocality.formatted_address);
          selectedLocality.address_components.filter((item: any) => {
            if (item.types[0] == "postal_code") {
              console.log("Postal Code", item.long_name)
              if (Array.isArray(item.long_name)) { this.form.controls.zip.setValue(item.long_name[0]); }
              else { this.form.controls.zip.setValue(item.long_name); }
            }
            if (item.types[0] == "country") {
              this.nationalities.forEach((n: any, index: number) => {
                if (n.country_name == item.long_name) {
                  this.spinnerService.show(false);
                  this.form.controls.country.setValue(n.id);
                  document.getElementById("country").focus();
                } else {
                  this.spinnerService.show(false);
                }
                if (index == this.nationalities.length - 1) {
                  this.spinnerService.show(false);
                }
              });
            }
            if (item.types[0] == "locality") {
              console.log("locality:", item.long_name)
              this.form.controls.city.setValue(item.long_name);
            }
            // else{
            //   this.form.controls.city.setValue("");
            // }
            if (item.types[0] == "administrative_area_level_2") {
              console.log("ad2:", item.long_name)
              this.form.controls.city.setValue(item.long_name);
            }
            if (item.types[0] == "administrative_area_level_1") {
              console.log("ad2:", item.long_name)
              this.form.controls.city.setValue(item.long_name);
            }
            if (item.types[0] == "county") {
              console.log("ad2:", item.long_name)
              this.form.controls.city.setValue(item.long_name);
            }
            // else{
            //   this.form.controls.city.setValue("");
            // }
            document.getElementById("Zipcode").focus();
            document.getElementById("autocompleteinput").focus();
          });
        } else {
          this.spinnerService.show(false);
        }
      });
    });
  }

  openChange(event: any) {
    if (event) {
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();
    }
  }


  public dismiss() {
    this.loader.cancelForm();
  }


  public proceed() {

    // window.scrollTo(0, document.body.scrollHeight);
    //this.formSubmitted = true;
    this.form.markAllAsTouched();
    if (this.sameLastName.value == "0") {
      this.form.controls["birthName"].enable();
    }

    if (this.form.valid && this.dateOfBirth.valid) {
      if (this.sameLastName.value == "0") {
        this.form.controls["lastName"].disable();
      }

      this.spinnerService.show(true);
      const datePipe = new DatePipe("en-US");
      const payload = {
        unique_id: this.prefilMode ? this.unique_id : "",
        FirstName: this.firstName.value,
        BirthName: this.birthName.value,
        LastName: this.lastName.value,
        Dob: datePipe.transform(this.dateOfBirth.value, "yyyy-MM-dd"),
        Gender: parseInt(this.gender.value, 10),
        Email: this.email.value,
        Ssn: parseInt(this.haveSSN?.value) == 0 ? this.ssn.value : "",
      };
      if (this.isRedirection) {
        payload.unique_id = this.unique_id
      }
      this.patientService.validatePaient(payload).subscribe(
        (response: any[]) => {
          this.spinnerService.show(false);
          response;
          response["password"] = this.password.value;

          this.age =
            new Date().getFullYear() -
            new Date(this.dateOfBirth.value).getFullYear();
          //this.step1 = false;
          this.hideRadio.emit(true);
          this.insurance.addValidators(Validators.required);

          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          this.registerUser();
        },
        (error) => {
          console.log(error);
          this.spinnerService.show(false);
          if (error.error.internal_code === 423) {
            this.messageService.alert(
              this.translate.instant("createAccount.email-exist"),
              5000
            );
            this.form.controls["email"].setErrors({ emailExist: true });
          } else if (error.error.internal_code === 427) {
            this.ssn.markAsTouched();
            this.messageService.alert(
              this.translate.instant("createAccount.invalid-ssn"),
              5000
            );
            this.form.controls["ssn"].setErrors({ ssnExist: true });
          } else if (error.error.internal_code === 428) {
            this.messageService.alert(
              this.translate.instant("createAccount.duplicate-account"),
              5000
            );
          } else {
            this.messageService.alert(
              "Your request can not be processed at this moment",
              5000
            );
          }
        }
      );
    }
  }


  public registerUser() {
    if (this.isLoginReceptionist) {
      this.payload();
    } else if (window.location.href.includes("elevate")) {
      this.isRedirection = true;
      this.sendOTP();
    }
    else {
      this.activeStep.emit(2);
      this.step = 2;
    }
  }

  public payload() {
    const datePipe = new DatePipe("en-US");
    let password = "";
    const newsalt = CryptoJS.lib.WordArray.random(128 / 20).toString();
    if (this.password.value) {
      password = this.loader.encryptData(this.password.value, newsalt);
    }

    const payload = {
      FirstName: this.firstName.value,
      BirthName: this.birthName.value,
      LastName: this.lastName.value,
      Dob: datePipe.transform(this.dateOfBirth.value, "yyyy-MM-dd"),
      Gender: parseInt(this.gender.value, 10),
      Nationality: this.nationality.value,
      PhoneNumber: this.phoneNumber.value.replace("+", ""),
      Email: this.email.value,
      Password: password,
      salt: this.password.value ? newsalt : "",
      birth_country: this.birthCountry.value,
      birth_city: this.birthCity.value
        ? JSON.parse(JSON.stringify(this.birthCity.value))?.id
        : 0,
    };
    payload["Ssn"] = parseInt(this.haveSSN?.value) == 0 ? this.ssn.value : "";
    payload["is_privacy_accepted"] = this.privacy.value;
    payload["is_terms_accepted"] = this.terms.value;
    sessionStorage.setItem("p_data", JSON.stringify(payload));
    this.loader.user_name =
      payload.FirstName + payload.LastName + payload.BirthName;
    this.loader.dob = payload.Dob;
    this.spinnerService.show(true);

    payload["Address"] = this.address.value;
    payload["City"] = this.city.value;
    payload["CountryId"] = this.country.value == "" ? 1 : this.country.value;
    payload["Zip"] = this.zip.value;
    payload["private_insurance"] = true;
    if (this.uploaded_doc_info) {
      payload["private_insurance"] = this.uploaded_doc_info?.pvt_insurance;
      payload["documents"] = this.uploaded_doc_info?.documents;
      payload["minor_passport"] = this.uploaded_doc_info?.minor_passport;
    }
    payload["otp"] = this.otp;
    payload["verify_id"] = this.verifyID;
    const cookiekey = 'device-unique-id-' + this.loader.encryptData(this.email.value.trim(), this.password.value ? newsalt : "")
    payload["device_detail"] = cookiekey;
    if (this.prefilMode || this.isRedirection) {
      payload["unique_id"] = this.unique_id;
    }
    payload["from_admin"] = this.isLoginReceptionist;
    sessionStorage.setItem("p_data", JSON.stringify(payload));
    payload[
      "from_admin"
    ] = this.isLoginReceptionist;
    this.patientService.createPatient(payload).subscribe(
      (response: any[]) => {

        if (this.fromAppointment) {
          response["password"] = this.password.value;
          this.loader.email = this.email.value;
          this.Registered.emit(response);
        } else {
          this.loader.isPreLoginFLow = true;
          this.loader.mobileLogin = true;
          this.autoLogin(response);
        }
        this.spinnerService.show(false);
      },
      (error) => {
        this.spinnerService.show(false);
        if (error.error.internal_code === 423) {
          this.messageService.alert(
            this.translate.instant("createAccount.email-exist"),
            5000
          );

          this.email.markAsTouched();
          this.form.controls["email"].setErrors({ emailExist: true });
        } else if (error.error.internal_code === 427) {
          this.email.markAsTouched();
          this.messageService.alert(
            this.translate.instant("createAccount.invalid-ssn"),
            5000
          );
          this.form.controls["ssn"].setErrors({ ssnExist: true });
        } else if (error.error.internal_code === 428) {
          this.messageService.alert(
            this.translate.instant("createAccount.duplicate-account"),
            5000
          );
        } else {
          this.messageService.alert(
            "Your request can not be processed at this moment",
            5000
          );
        }
      }
    );
  }

  public autoLogin(response: any) {
    if (response["access_token"]) {
      this.loader.startRefreshTokenTimer(response);
      this.tokenResponse = response;
      const access_token = jwtDecode(this.tokenResponse["access_token"]);
      sessionStorage.setItem("access_token", JSON.stringify(access_token));
      sessionStorage.setItem("header", this.tokenResponse["access_token"]);
      sessionStorage.setItem(
        "refresh_token",
        this.tokenResponse["refresh_token"]
      );
      this.loader.userRole = access_token["role"];

      if (access_token["role"] == constants.patientRole) {
        this.loginService.getPatientDetails().subscribe((resp: any) => {
          sessionStorage.setItem("userDetails", JSON.stringify(resp));
          this.loader.user_name = resp.first_name + " " + resp.birth_name;
          this.loader.dob = resp.dob;
          this.spinnerService.show(false);
          this.loader.handleNavigationNodes();
          this.loader.updateLoginStatus(true);
        });
      }
    }
  }

  async updateUser() {
    this.form.markAsTouched();
    if (this.form.valid && this.dateOfBirth.valid) {
      this.spinnerService.show(true);
      const datePipe = new DatePipe("en-US");
      const payload = {
        Id: this.sliderFormData.patientInfo.Id,
        FirstName: this.firstName.value,
        BirthName: this.birthName.value,
        LastName: this.lastName.value,
        Dob: datePipe.transform(this.dateOfBirth.value, "yyyy-MM-dd"),
        Gender: parseInt(this.gender.value, 10),
        Nationality: this.nationality.value,
        PhoneNumber: this.phoneNumber.value.replace("+", ""),
        Email: this.email.value,
        Address: this.address.value,
        City: this.city.value,
        CountryId: this.country.value == "" ? 1 : this.country.value,
        Zip: this.zip.value,
        Ssn: parseInt(this.haveSSN?.value) == 0 ? this.ssn.value : "",
        is_active: this.editPatientDetail.IsActive,
        birth_country: this.birthCountry.value,
        birth_city: this.birthCity.value
          ? JSON.parse(JSON.stringify(this.birthCity.value))?.id
          : 0,
        private_insurance: this.insurance.value === "1",
        is_terms_accepted: this.editPatientDetail.is_terms_accepted,
        is_privacy_accepted: this.editPatientDetail.is_privacy_accepted,
        parent_id: this.editPatientDetail.ParentId
      };
      this.patientService.updatePatientDetails(payload).subscribe(
        (response: any[]) => {
          const selectedGender = this.genders.find(
            (i) => i.Id == payload.Gender
          );
          payload.Gender = selectedGender.KeyName;
          payload["GenderId"] = selectedGender.Id;
          payload["BirthCountry"] = payload.birth_country;
          payload["BirthCity"] = payload.birth_city;
          payload["IsSecondary"] = payload.parent_id ? true : false;
          payload["IsActive"] = payload.is_active
          payload["ParentId"] = payload.parent_id
          this.loader.onFormClose(payload);
          response;
          this.spinnerService.show(false);

          this.messageService.success(
            this.translate.instant("results.update-successfully"),
            5000
          );
        },
        (error) => {
          this.spinnerService.show(false);

          if (error.error.internal_code === 423) {
            this.messageService.alert(
              this.translate.instant("createAccount.email-exist")
            );
            this.form.controls["email"].setErrors({ emailExist: true });
          }
          if (error.error.internal_code === 427) {
            this.messageService.alert(
              this.translate.instant("createAccount.invalid-ssn")
            );
            this.ssn.setErrors({ ssnExist: true } as ValidationErrors);
          } else if (error.error.internal_code === 428) {
            this.messageService.alert(
              this.translate.instant("createAccount.duplicate-account"),
              5000
            );
          } else {
            this.messageService.alert(
              this.translate.instant("results.unsuccessful-request"),
              5000
            );
          }
        }
      );
    }
  }

  public onSelectNationality(id: any) {
    const selectedValue = this.nationalities.find((i: any) => i.id == id);
    this.selectedFlag = selectedValue.country_code.toLowerCase();
    this.birthCountry.setValue(id);
    this.onSelectBirthCountry(id);
  }

  public onSelectBirthCountry(id: any) {
    const selectedValue = this.nationalities.find((i: any) => i.id == id);
    this.selectedBirthCountryFlag = selectedValue.country_code.toLowerCase();
    this.selectedBirthCountryName = selectedValue.country_name.toLowerCase();
    if (this.selectedBirthCountryName == "france") {
      this.isBirthCityRequired = true;
      this.onSSNChange();
    } else {
      this.isBirthCityRequired = false;
      this.birthCity.setValue("");
    }
  }

  getBirthCities() {
    if (this.editMode || this.isRedirection) {
      this.spinnerService.show(true);
    }

    this.adminService.getBirthCities().subscribe(
      (data: any) => {
        this.birthCities = data;
        this.adminService.birthCitiesList.next(data)
        this.filteredBirthCities = this.birthCity.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
        if (this.editMode) {
          const bc = this.birthCities.find(
            (i) => i.id == this.editPatientDetail.BirthCity
          );
          if (bc) {
            this.form.controls.birthCity.setValue(bc);
          } else {
            this.form.controls.birthCity.setValue("");
          }
        }

        if (this.prefilMode) {
          const bc = this.birthCities.find(
            (i) => i.id == this.prefilData.birth_city
          );
          if (bc) {
            this.form.controls.birthCity.setValue(bc);
          } else {
            this.form.controls.birthCity.setValue("");
          }
        }

        if (this.isRedirection) {
          const bc = this.birthCities.find(
            (i) => i.id == this.redirectBirthCity
          );
          if (bc) {
            this.form.controls.birthCity.setValue(bc);
          } else {
            this.form.controls.birthCity.setValue("");
          }
        }
        this.spinnerService.show(false);
      },
      (error: any) => {
        console.log(error);
        this.spinnerService.show(false);
      }
    );
  }

  displayFn(option?: any): string {
    return option?.city_name;
  }

  public _filter(value?: any): any[] {
    if (this.birthCities != null) {
      if (typeof value == "string") {
        const filterval = value?.toLowerCase();
        return this.birthCities
          .filter((option) =>
            option.city_name.toLowerCase().startsWith(filterval)
          )
          .slice(0, 500);
      } else {
        const filterval = value?.city_name?.toLowerCase();
        return this.birthCities
          .filter((option) =>
            option.city_name.toLowerCase().startsWith(filterval)
          )
          .slice(0, 500);
      }
    }
    return null;
  }

  // public getDocumentType() {
  //   this.loginService.getDocType().subscribe((data: Array<any>) => {
  //     this.documentType = data.sort((a, b) => a.KeyOrder - b.KeyOrder);
  //   });
  // }

  public getGenders() {
    this.loginService.getGenderMasters().subscribe((data: Array<any>) => {
      this.genders = data.sort((a, b) => a.KeyOrder - b.KeyOrder);
      this.gender.setValue(this.genders[0].Id.toString());
      if (this.editMode) {
        this.form.controls.gender.setValue(
          this.editPatientDetail.Gender.toString()
        );
      }
      if (this.noRecordMode) {
        this.gender.setValue(this.searchData.gender.toString());
      }
      if (this.prefilMode) {
        this.form.controls.gender.setValue(
          this.prefilData?.gender.toString()
        );
      }
      if (this.isRedirection) {
        this.form.controls.gender.setValue(
          this.redirectGender.toString()
        );
      }
    });
  }

  public onPasswordChange() {
    if (this.form.get("password").value.indexOf(" ") > -1) {
      this.form.controls["password"].setErrors({ whitespace: true });
    }

    if (
      this.form.get("password").value !== this.form.get("repeatPassword").value
    ) {
      this.form.controls["repeatPassword"].setErrors({ incorrect: true });
    } else {
      this.form.controls["repeatPassword"].setErrors({ incorrect: null });
      this.form.controls["repeatPassword"].updateValueAndValidity();
    }
  }

  public onHaveSSNChange() {
    this.ssn.setValue(this.ssn?.value?.replace(/\s/g, ''));
    if (this.haveSSN.value == "0") {
      this.isSSNRequired = true;
      this.birthCity.disable();
    } else {
      this.isSSNRequired = false;
      this.ssn.setValue("");
      this.birthCity.enable();
      this.form.controls["ssn"].setErrors({ incorrect: null });
      this.form.controls["ssn"].updateValueAndValidity();
    }
  }


  public onSSNChange() {
    let birth_month = "";
    this.ssn.setValue(this.ssn?.value?.replace(/\s/g, ''));
    if (this.dateOfBirth.value && this.ssn.value) {
      switch (new Date(this.dateOfBirth.value).getMonth()) {
        case 0: {
          birth_month = "01";
          break;
        }
        case 1: {
          birth_month = "02";
          break;
        }
        case 2: {
          birth_month = "03";
          break;
        }
        case 3: {
          birth_month = "04";
          break;
        }
        case 4: {
          birth_month = "05";
          break;
        }
        case 5: {
          birth_month = "06";
          break;
        }
        case 6: {
          birth_month = "07";
          break;
        }
        case 7: {
          birth_month = "08";
          break;
        }
        case 8: {
          birth_month = "09";
          break;
        }
        case 9: {
          birth_month = "10";
          break;
        }
        case 10: {
          birth_month = "11";
          break;
        }
        case 11: {
          birth_month = "12";
          break;
        }
      }

      this.form.controls["ssn"].setErrors({ incorrect: null });
      this.form.controls["ssn"].updateValueAndValidity();

      const selectedGender = this.genders
        .find((i) => i.Id == this.gender.value)
        .KeyName.toLowerCase();
      const dobYear = new Date(this.dateOfBirth.value).getFullYear();
      const age = new Date().getFullYear() - dobYear;
      if (age >= 18) {
        // check ssn value string
        if (
          this.ssn.value.substr(1, 4) !==
          dobYear.toString().slice(-2) + birth_month
        ) {
          this.form.controls["ssn"].setErrors({ incorrect: true });
        } else {
          // check for male
          if (selectedGender === "male" && this.ssn.value[0] !== "1") {
            // valid
            this.form.controls["ssn"].setErrors({ incorrect: true });
          }
          // check for female
          else if (selectedGender === "female" && this.ssn.value[0] !== "2") {
            this.form.controls["ssn"].setErrors({ incorrect: true });
          } else if (
            selectedGender === "other" &&
            (parseInt(this.ssn.value[0], 10) >= 3 ||
              parseInt(this.ssn.value[0], 10) <= 0)
          ) {
            this.form.controls["ssn"].setErrors({ incorrect: true });
          }
        }
        this.birthCity.disable();
        if (this.ssn.valid) {
          let inseeValue = this.ssn.value.substr(5, 5);
          let bcs = this.birthCities.filter(i => i.insee_code == inseeValue);
          if (bcs.length > 0) {
            this.birthCity.setValue(bcs[0]);

          }
          else {
            this.form.controls["ssn"].setErrors({ incorrect: true });
          }
        }

      } else {
        this.birthCity.enable();
        if (
          parseInt(this.ssn.value[0], 10) >= 3 ||
          parseInt(this.ssn.value[0], 10) <= 0
        ) {

          this.form.controls["ssn"].setErrors({ incorrect: true });
        }
      }
    }
  }

  resetForm() {
    this.form.reset();
    this.gender.setValue(this.genders[0].Id.toString());
    const toSelect = this.nationalities.find((c) => c.id == 1);
    this.selectedFlag = toSelect.country_code.toLowerCase();
    this.selectedBirthCountryFlag = toSelect.country_code.toLowerCase();
    this.nationality.setValue(toSelect.id);
    this.birthCountry.setValue(toSelect.id);
    this.formSubmitted = false;
    this.hideErrorBox();
  }

  goToLogin() {
    if (this.fromAppointment) {
      this.backToLogin.emit(true);
    } else {
      this.loader.isPreLoginFLow = false;
      this.loader.highlightLeftMenu(["auth/login"]);
    }
  }

  public copyLastName() {
    if (this.sameLastName.value == "0") {
      this.form.controls.lastName.setValue(this.form.controls.birthName.value);
      this.form.controls.lastName.disable();
    }
  }

  public onSameLastNameChange() {
    if (this.sameLastName.value == "1") {
      this.lastName.setValue("");
      this.form.controls.lastName.enable();
    }
    else {
      this.copyLastName();
    }
  }

  validateSelection() {
    if (this.birthCity.value && typeof this.birthCity.value == "string") {
      this.birthCity.setErrors({ incorrect: true });
    }
  }

  setBirthCity(event: any) {
    this.birthCity.setValue(event.option.value);
  }

  restrictSpace(event: any) {
    const testVal = event.target.value.replace(/\s/g, '');
    event.target.value = testVal
  }

  public noWhitespaceValidator(password: FormControl) {
    const isWhitespace = (password.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  showErrorBox() {
    this.passwordPolicy.show(
      this.password.value,
      constants.intMinPwdLength,
      constants.pwdMaxLength
    );
    this.isPasswordValid = this.passwordPolicy.valid(
      this.password.value,
      constants.intMinPwdLength,
      constants.pwdMaxLength
    );
  }
  public hideErrorBox() {
    this.passwordPolicy.hide();
  }

  public back() {
    this.activeStep.emit(1);
    this.step = 1;
    setTimeout(() => {
      this.addressAutocomplete();
    }, 100);
    this.hideRadio.emit(false);
  }

  public uploadDoc(docs: any) {
    this.uploaded_doc_info = docs;
    if (this.prefilMode && this.prefilData.email != "" && !window.location.href.includes("patient/complete-appointment")) {
      this.payload();
    }
    else {
      this.sendOTP()
    }
  }

  public sendOTP() {
    const req = {
      id: "",
      email: this.email.value
    };

    this.authService.sendOTP(req).subscribe(
      (response) => {
        this.verifyID = response.verify_id
        this.openVerifyOTP();
      },
      (err: {
        error: { code: any; message: string };
        message: any;
        code: any;
      }) => {
        if (err.error.code == 422) {
          // this.messageService.alert(
          //   this.translate.instant(this.passwordErrorMessage)
          // );
        }
        else {
          this.messageService.alert(err.error.message);
        }
      });
  }

  public openVerifyOTP() {
    const dialogRef = this.dialog.open(VerifyOtpComponent, {
      width: '450px',
      maxWidth: '90vw',
      disableClose: true,
      data: {
        action: 'verify',
        verify_id: this.verifyID,
        id: this.unique_id,
        email: this.email.value
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.verified) {
        this.otp_verified = result.verified;
        this.verifyID = result.verify_id;
        this.otp = result.otp;
        this.unique_id = result.id;
        this.payload();

      }
    });
  }

  goBack() {
    this.goToBack.emit(true);
  }

  checkIsValid(event: any) {
    if (event) {
      this.hideErrorBox();
    }
  }

}
