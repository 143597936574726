<div id="createAccount_Slider" class="slider-data-container">
    <div class="header">
        <div style="text-align: center; margin-bottom: 10px;">
            <app-section-title [title]="title"></app-section-title>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="input_copy_wrapper">
        <p> {{'redirect-dialog.internet_access_id'|translate}}</p>
        <div class="input_copy" style="display: flex;">
            <span class="txt">{{code}}</span>
            <span class="icon right">
                <button mat-raised-button color="primary" type="submit" class="button_content primary-hover"
                    (click)="copyCodeToClipboard()">
                    {{'redirect-dialog.copy'|translate}}
                </button>
            </span>
        </div>

    </div>

    <div class="input_copy_wrapper">
        <p style="margin: 0px,0px,0px,0px;">{{'redirect-dialog.link_for_access_result'|translate}}</p>
        <div class="input_copy" style="display: flex;">
            <span class="txt">{{resultLink}}</span>
            <span class="redirect_button">
                <button mat-raised-button color="primary" type="submit" class="button_content primary-hover"
                    (click)="redirectUrl()">
                    {{'redirect-dialog.redirect'|translate}}
                </button>
            </span>

        </div>

    </div>

    <div class="actions">
        <button mat-button color="accent" mat-dialog-close="true" class="ghost button_width" type="submit"
            id="return_btn">
            {{'redirect-dialog.close'|translate}}
        </button>

    </div>
</div>