
import {
  Directive,
  ElementRef,
  HostListener,
  Input
} from '@angular/core';
import { FormGroup } from '@angular/forms';
@Directive({
  selector: '[appAutoScroll]'
})
export class AutoScrollDirective {
  @Input() appAutoScroll!: FormGroup;

  constructor(private el: ElementRef) { }

  @HostListener('submit')
  onFormSubmit() {
    if (this.appAutoScroll.invalid) {
      const firstInvalidControl: HTMLElement | null = this.el.nativeElement.querySelector('.ng-invalid');

      if (firstInvalidControl) {
        firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        firstInvalidControl.focus();
      }
    }
  }
}