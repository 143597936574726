import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../../../../main';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../../../../core';
import { OurPresenceWrapper, RequestData } from '../landing-page-config.model';

@Component({
  selector: 'app-our-presence',
  templateUrl: './our-presence.component.html',
  styleUrls: ['./our-presence.component.scss']
})
export class OurPresenceComponent implements OnInit {
  @Input() ourPresence: OurPresenceWrapper;
  @Output() cancelClicked = new EventEmitter();
  @Output() updateData = new EventEmitter<RequestData>();
  public selectedLangTabIndex: number = 0;
  public field1MaxLength: number = 40;
  public field2MaxLength: number = 100;
  public field3MaxLength: number = 120;
  public field4MaxLength: number = 11;
  public isInvalidFileSize: boolean = false;
  public isIncorrectFileFormat: boolean = false;
  public imageUrl: string | ArrayBuffer = '';
  isFileChanged: boolean;
  randomNumber: number;
  constructor(private fb: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private spinner: SpinnerService) {
    this.randomNumber = Math.floor(1000 + Math.random() * 9000);
  }


  public enForm: FormGroup = this.fb.group({
    section_title: ['', [Validators.required, Validators.maxLength(this.field1MaxLength)]],
    section_image_url: ['', [Validators.required]],
    section_image_name: ['',]
  });

  public frForm: FormGroup = this.fb.group({
    section_title: ['', [Validators.required, Validators.maxLength(this.field1MaxLength)]],
    section_image_url: ['', [Validators.required]],
    section_image_name: ['',]
  });

  ngOnInit(): void {
    this.bindData();
  }

  ngOnChanges() {
    this.bindData();
  }

  public bindData() {
    const enFormData = {
      section_title: this.ourPresence.en.section_title,
      section_image_url: this.ourPresence.en.section_image_url,
      section_image_name: this.ourPresence.en.section_image_name,

    };
    this.enForm.patchValue(enFormData);

    const frFormData = {
      section_title: this.ourPresence.fr.section_title,
      section_image_url: this.ourPresence.fr.section_image_url,
      section_image_name: this.ourPresence.fr.section_image_name,

    };
    this.frForm.patchValue(frFormData);
    this.imageUrl = this.ourPresence.en.section_image_url + '?v=' + this.randomNumber;
  }

  save() {
    this.enForm.markAllAsTouched();
    this.frForm.markAllAsTouched();
    if ((this.enForm.valid && this.frForm.valid) && (this.isInvalidFileSize == false && this.isIncorrectFileFormat == false)) {
      this.openPopup();
    }
    else {
      if (this.selectedLangTabIndex == 0 && this.enForm.valid && !this.frForm.valid) {
        this.selectedLangTabIndex = 1;
      }
      else if (this.selectedLangTabIndex == 1 && !this.enForm.valid && this.frForm.valid) {
        this.selectedLangTabIndex = 0;
      }
    }
  }

  public onLangTabChange(index: number) {
    this.selectedLangTabIndex = index;
  }

  public openPopup() {
    let popupRef = this.messageService
      .confirm(
        this.translateService.instant('landing-page-config.save-confirm-description'),
        this.translateService.instant('landing-page-config.save-title'),
        this.translateService.instant('landing-page-config.yes'),
        this.translateService.instant('landing-page-config.no')
      );

    popupRef.subscribe((actionResult) => {
      if (actionResult) {
        this.spinner.show(true);
        let payload = {
          "tab_name": "ourpresence",
          "page_data": JSON.stringify({
            "en": this.enForm.value,
            "fr": this.frForm.value
          }),
          "image_data": [
            {
              "image_url": "",
              "image_data": ""
            }
          ]
        }
        if (this.isFileChanged) {
          payload.image_data = [{ "image_url": this.ourPresence.en.section_image_url, "image_data": this.imageUrl.toString() }]
        }
        this.updateData.emit(payload);
      }
    });
  }

  isValidImage(file: any): boolean {
    let isImageValid = true;
    if (file.size == 0 || file.size > 500 * 1024) {
      this.isInvalidFileSize = true;
      isImageValid = false;
    }
    if (
      file.type != 'image/png' &&
      file.type != 'image/jpg' &&
      file.type != 'image/jpeg'
    ) {
      this.isIncorrectFileFormat = true;
      isImageValid = false;
    }

    if (
      !file.name.toLowerCase().includes('.png') &&
      !file.name.toLowerCase().includes('.jpg') &&
      !file.name.toLowerCase().includes('.jpeg')
    ) {
      this.isIncorrectFileFormat = true;
      isImageValid = false;
    }

    return isImageValid;
  }

  inputChange(event: any) {
    this.isInvalidFileSize = false;
    this.isIncorrectFileFormat = false;

    const reader = new FileReader();
    const file = event.target.files[0];
    if (file) {
      if (!this.isValidImage(file)) {
        this.frForm.controls.section_image_name.setValue(null);
        this.enForm.controls.section_image_name.setValue(null);
      } else {
        this.isFileChanged = true;
        this.enForm.controls["section_image_name"].setValue(file.name);
        this.frForm.controls["section_image_name"].setValue(file.name);
        this.isIncorrectFileFormat = false;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageUrl = reader.result.toString();
        };
      }
    }
  }
}
