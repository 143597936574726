<div class="create-HP-container">
    <div class="header flex-box">
        <div class="pull-left">
            <div class="heading">
                <span class="title">{{sliderFormData[0].edit? ('ehp.editProfessionalsTitle' | translate)
                    :('ehp.addProfessionalsTitle' | translate)}}</span>
                <span class="divider" *ngIf="sliderFormData[0].edit"></span>
                <span class="userid"
                    *ngIf="sliderFormData[0].edit">{{(this.sliderFormData[0].professionalData.external_code)}}</span>
            </div>
        </div>
        <div class="pull-right">
            <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
                <span class="icon-ic-close"></span>
            </button>
        </div>
    </div>

    <form [formGroup]="ProfestionalFrom" id="createAccount_form">
        <div class="createAccountwrapper">
            <mat-form-field>
                <mat-label>{{'ehp.name' | translate}}</mat-label>
                <input matInput formControlName="name" />
            </mat-form-field>
            <div *ngIf="!sliderFormData[0].edit"></div>
            <mat-form-field def="Status" *ngIf="sliderFormData[0].edit">
                <mat-label>{{'ehp.status' | translate}}</mat-label>
                <mat-select placeholder="Status" formControlName="is_active" required="true" name="status">
                    <mat-option *ngFor="let stepOption of sampleData | keyvalue: unsorted" [value]="stepOption.key">
                        {{stepOption.value | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'ehp.ADELI' | translate}}</mat-label>
                <input matInput formControlName="adeli" />
                <mat-error *ngIf="ProfestionalFrom.controls.adeli.hasError('pattern') &&
               ProfestionalFrom.controls.adeli.touched">
                    {{'ehp.inputOnlyNumbers' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'ehp.RPPS' | translate}}</mat-label>
                <input matInput formControlName="rpps" />
                <mat-error *ngIf="ProfestionalFrom.controls.rpps.hasError('pattern') &&
                ProfestionalFrom.controls.rpps.touched">
                    {{'ehp.inputOnlyNumbers' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'ehp.EHP-Code' | translate}}</mat-label>
                <input matInput formControlName="external_code" />
            </mat-form-field>
            <mat-form-field def="Status">
                <mat-label>{{'ehp.EHP-type' | translate}}</mat-label>
                <mat-select placeholder="Status" formControlName="hp_type" required="true">
                    <mat-option *ngFor="let userType of userTypes " [value]="userType.Id">{{userType.KeyName |
                        translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="actions">
            <div class="inner-btn">
                <button mat-button color="accent" class="ghost button_width mr-20" type="button" (click)="reset()"
                    id="btn_clear">
                    {{sliderFormData[0].edit?('ehp.cancel'|translate):('ehp.clear' | translate)}}
                </button>
                <button mat-raised-button color="primary" class="button_width" type="submit" id="btn_save"
                    (click)="onSave()">
                    {{'ehp.save' | translate}}
                </button>
            </div>
        </div>
    </form>
</div>