import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LoaderService } from '../../../../main/services/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-token-detail',
  templateUrl: './token-detail.component.html',
  styleUrl: './token-detail.component.scss'
})
export class TokenDetailComponent implements OnInit {
  @Input() isKioskAvailable: boolean;
  @Input() req_number: string;
  @Input() step: number;
  @Input() appointmentTolerance: boolean;
  private subscription: Subscription = new Subscription();
  percent: number;
  constructor(public loader: LoaderService, private cdr: ChangeDetectorRef,) {
    console.log(this.loader.optionsG)
  }
  ngOnInit(): void {
    this.subscription.add(
      this.loader.variable$.subscribe(value => {
        this.percent = (value / this.loader.flow_arr.length) * 100;
        this.cdr.detectChanges(); // Manually trigger change detection
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
