<div class="find-patient-container">
  <div class="heading-style title-header">
    <h3 class="pull-left">{{'appointments.appointments' | translate}}</h3>
    <span class="icon-ic-refresh" (click)="loadData()"></span>
  </div>


  <mat-tab-group disableRipple [@.disabled]="true" class="tab-app pull-left" animationDuration="1000ms">
    <mat-tab label="{{'appointments.appointments' | translate}} ({{appointmentCount}})">
      <div *ngIf="!isBookingFlow">
        <header class="pull-left">
          <div class="header-style">
            <mat-form-field class="search-input">
              <input matInput #searchInput [formControl]="seacrchInput"
                placeholder="{{'appointments.search-record' | translate}}" name="search" id="search"
                (keyup)="findPatient(true)" />
              <div *ngIf="!searchInput.value" class="bar-code-icon"><span class="icon-ic-scan-barcode"
                  (click)="scanQR()"></span></div>
              <div *ngIf="searchInput.value" (click)="clearSearch(); reset()" class="clear">
                <p>{{'appointments.clear-search' | translate}}</p>
              </div>
            </mat-form-field>
            <mat-form-field class="test">
              <mat-label class="label-float">{{'appointments.test' | translate}}</mat-label>
              <mat-select [formControl]="testFormControl" multiple (selectionChange)="onTestSelected($event)">
                <app-select-check-all [model]="testFormControl" [values]="testList" class="twolineText"
                  (allChecked)="allChecked($event)">
                </app-select-check-all>
                <mat-option *ngFor="let testtype of testList" [value]="testtype"
                  class="twolineText">{{testtype.short_name }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="filter-date-range">
              <mat-label>{{'appointments.test-date' | translate}}</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate [formControl]="searchStartFormControl" readonly (dateChange)="dateFilter()" matInput
                  placeholder="{{'appointments.start-date'|translate}}">
                <input matEndDate [formControl]="searchEndFormControl" readonly (dateChange)="dateFilter()" matInput
                  placeholder="{{'appointments.end-date'|translate}}">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <mat-checkbox id="withoutSlots" [(ngModel)]="isAppointmentsWithoutSlots" (change)="dateFilter()" />
          <label class="lbl-checkbox">
            {{'appointments.show-records-without-booked-slots' | translate}}
          </label>

        </header>

        <app-pdf-viewer *ngIf="isPdfViewerOpened" pdftitle="{{'stepper-flow.prescriptions'|translate}}"
          [blobSrc]="pdfBlob" [allPages]="true" (closed)="isPdfViewerOpened = false"></app-pdf-viewer>
      </div>
      <table *ngIf="refresh" class="ecl-table" width="100%" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="wp-20" mat-sort-header="FirstName">
            {{'all-apointment.patient'|translate}} </th>
          <td mat-cell *matCellDef="let element" class="cp"
            [ngClass]="{'cancelled-column':element.IsDeleted && !element.IsArrived, 'modified-column':element.IsModified}">
            <span class="title" *ngIf="element.FirstName" (click)="rowClicked(element.QrId)"> {{element.FirstName}}
              {{element.LastName}}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="dateOfBirth">
          <th mat-header-cell *matHeaderCellDef class="wp-10" mat-sort-header="DOB"> {{'appointments.dob' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-date onlyMobile">{{element.DOB | date :'dd/MM/yyyy'}} </span>
            <span class="onlyDesktop">{{element.DOB | date :'dd/MM/yyyy'}} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="ssn">
          <th mat-header-cell *matHeaderCellDef class="wp-15" mat-sort-header="SSN"> {{'appointments.ssn' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="title" *ngIf="element.SSN">{{element.SSN}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="recordNo">
          <th mat-header-cell *matHeaderCellDef class="wp-10" mat-sort-header="recordNo"> {{'appointments.record-no' |
            translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="title" *ngIf="element.AppointmentCode">{{element.AppointmentCode}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="test">
          <th mat-header-cell *matHeaderCellDef class="wp-25" mat-sort-header="TestShortName"> {{'appointments.test' |
            translate}} </th>
          <td mat-cell *matCellDef="let element" class="flex-td">
            <div class="avatar onlyMobile"><span class="titleWrapBox">{{element.TestShortName.charAt(0)}}</span></div>
            <span class="title onlyDesktop" *ngIf="element.TestShortName">{{element.TestShortName}}</span>
            <div class="mobile-td onlyMobile">
              <span class="title pr-20" *ngIf="element.TestShortName">{{element.TestShortName}}</span>
            </div>
          </td>
        </ng-container>


        <ng-container matColumnDef="dateTime">
          <th mat-header-cell *matHeaderCellDef class="wp-10 date-align" mat-sort-header="SlotTime">
            {{'appointments.date-time' |
            translate}} </th>
          <td mat-cell *matCellDef="let element">

            <ng-container>
              <div class="aaa">
                <span class="mobile-date onlyMobile">{{element.SlotTime | dateFormat}}</span>
                <span class="onlyDesktop">
                  <div [ngClass]="element.IsSlotAvailable=='true' ? 'slot-display' : 'ml-30'">
                    <button *ngIf=" element.IsSlotAvailable=='true'" class=" mat-tbl-icon"
                      matTooltip="{{'appointments.with-appointment' | translate}}" matTooltipClass="tooltip-style"
                      mat-icon-button>
                      <span class="icon-ic-preappoint"></span>
                    </button>
                    {{element.SlotTime | dateFormat}}
                  </div>
                </span>
                <div class="appointment-info">
                  <div class="isCancelled" *ngIf="element.IsDeleted">{{'appointments.cancelled' | translate}}</div>
                  <div class="isModified" *ngIf="element.IsModified">
                    {{'appointments.modified' | translate}}</div>
                  <div class="history-info" *ngIf="element.IsModified" (click)="getHistory(element)">i</div>
                </div>
                <div class="appointment-info mt-3">
                  <div class="isArrived" *ngIf="element.IsArrived">{{'appointments.arrived' | translate}}</div>
                </div>
                <div class="read-container" *ngIf="element.isOpen && element.history" (click)="getHistory(element)">
                  <div class="sb10"
                    [ngClass]="element.IsSlotAvailable=='true' ? 'container-content-slot' : 'container-content' ">
                    <div class="history-label">{{'appointments.app-shifted' | translate}}</div>
                    <div class="slots" *ngFor="let row of element.history">
                      {{row | dateFormat}} </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="wp-5"></th>
          <td mat-cell *matCellDef="let element" class="force-display action-col">
            <div class="mobile-action onlyMobile">
              <ng-container>
                <div class="prescription-container">
                  <div class="prescription-link" *ngIf="element.PrescriptionUrl"
                    (click)="openPdf(element.PrescriptionUrl)">
                    <span class="icon-ic-view-prescription"></span>
                    {{'view-prescription.prescription'|translate}}.pdf
                  </div>

                </div>
                <div class="d-flex-center my-5"><button mat-button color="accent" class="ghost register-kit-button"
                    type="button" (click)="redirectRegisterTestKit(element)" *ngIf="element.IsTestKitRequired"
                    [disabled]="!element.Barcode == '' || checkDate(element) || element.IsDeleted">
                    {{'appointments.register-test-kit' | translate}}
                  </button></div>
              </ng-container>
            </div>

            <div class="action-btn row-reverse">
              <button *ngIf="!checkDate(element) && !element.IsDeleted && element.StatusId==0" class="mat-tbl-icon"
                (click)="editPatientInfo(element)" matTooltip=" {{'receptionist.edit' | translate}}"
                matTooltipClass="tooltip-style" mat-icon-button>
                <span class="icon-ic-edit"></span>
              </button>
              <button *ngIf="!element.IsDeleted && element.SlotTime == ''" class="mat-tbl-icon"
                (click)="cancelAppointment(element)" matTooltip=" {{'edit-appointment.cancel-appointment' | translate}}"
                matTooltipClass="tooltip-style" mat-icon-button>
                <span class="icon-ic-delete-rec"></span>
              </button>

              <button class="mat-tbl-icon" matTooltip="{{'appointments.view-prescription' | translate}}"
                matTooltipClass="tooltip-style" mat-icon-button (click)="viewPrecription(element)"
                *ngIf="element.PrescriptionUrl != ''">
                <span class="icon-ic-view-prescription"></span>
              </button>


              <button class="mat-tbl-icon show-comment onlyDesktop"
                *ngIf="element.PrescriptionUrl == '' && element.Comments != ''"
                matTooltip="{{element.Comments ? element.Comments: 'receptionist.no-comment'|translate }}"
                matTooltipClass="tooltip-style" mat-icon-button>
                <span class="icon-ic-remarks"></span>
              </button>


              <button class="mat-tbl-icon show-comment onlyMobile"
                *ngIf="element.PrescriptionUrl == '' && element.Comments != ''"
                (click)="element.ShowComments=!element.ShowComments" matTooltipClass="tooltip-style" mat-icon-button>
                <span class="icon-ic-remarks"></span>
              </button>

              <button *ngIf="!element.IsArrived && !element.IsDeleted && checkIsTodayDate(element)"
                (click)="confirmDeleteWithYesNo(element)" class="mat-tbl-icon"
                matTooltip=" {{'receptionist.confirm-arrival' | translate}}" matTooltipClass="tooltip-style"
                mat-icon-button>
                <span class="icon-ic-confirm-arrival"></span>
              </button>
              <button *ngIf="element.IsArrived && element.IsTestKitRequired" (click)="redirectRegisterTestKit(element)"
                class="mat-tbl-icon" matTooltip=" {{'appointments.register-test-kit' | translate}}"
                matTooltipClass="tooltip-style" mat-icon-button>
                <span class="icon-ic-register-testkit"></span>
              </button>
              <!-- <button class="mat-tbl-icon" matTooltip="{{'receptionist.more' | translate}}"
                matTooltipClass="tooltip-style" mat-icon-button [matMenuTriggerFor]="userMenu" #more="matMenuTrigger"
                [disabled]="checkDate(element) || element.StatusId==4 || element.KitImagePath !='' || (element.IsTestResultRequired==false && element.Barcode!='') || (element.StatusId>0 && element.IsTestKitRequired==false) || element.IsDeleted">
                <span class="icon-ic_more"></span>
              </button> -->
            </div>
            <div class="comments" *ngIf="element.ShowComments">{{element.Comments }}</div>
            <mat-menu #userMenu="matMenu" class="table-popover" xPosition="before">
              <div class="arrow-top"></div>
              <div class="main">
                <button mat-menu-item [disabled]="!element.ArrivedTime == ''"
                  (click)="confirmDeleteWithYesNo(element)">{{'receptionist.confirm-arrival' |
                  translate}}</button>
                <button mat-menu-item (click)="redirectRegisterTestKit(element)" *ngIf="element.IsTestKitRequired"
                  [disabled]="!element.Barcode == ''">{{'appointments.register-test-kit' |
                  translate}}</button>
                <button mat-menu-item (click)="editPatientInfo(element)">{{'receptionist.edit' |
                  translate}}</button>
                <button mat-menu-item
                  (click)="redirectReceiptionistBooking(element)">{{'appointments.book-new-appointment' |
                  translate}}</button>

                <button mat-menu-item (click)="cancelAppointment(element)">{{'receptionist.cancel'
                  |translate}}</button>
              </div>
            </mat-menu>
          </td>
        </ng-container>
        <ng-container matColumnDef="noRecord">
          <td colspan="7" mat-footer-cell *matFooterCellDef>
            <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
            <div class="desc">
              <p>{{'appointments.empty' | translate}}</p>
              <p>{{'appointments.search-patient' | translate}}</p>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
          [ngClass]="{'cancelled-row':element.IsDeleted && !element.IsArrived,'modified-row':element.IsModified}">
        </tr>
        <ng-template [ngIf]="dataSource && dataSource.data.length === 0">
          <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
        </ng-template>
      </table>
    </mat-tab>
    <mat-tab *ngIf="patient?.id" label="{{'appointments.other-appointments' | translate}} ({{otherAppointmentCount}})"
      (mwlClick)="setIsOtherAppointmentSelected(true)">
      <table *ngIf="refreshForOtherAppointmentTab" class="ecl-table" width="100%" mat-table
        [dataSource]="otherAppointmentsDataSource" matSort>

        <ng-container matColumnDef="test">
          <th mat-header-cell *matHeaderCellDef class="wp-25" mat-sort-header="TestShortName"> {{'appointments.test' |
            translate}} </th>
          <td mat-cell *matCellDef="let element" class="flex-td"
            [ngClass]="{'cancelled-column':element.IsDeleted && !element.IsArrived, 'modified-column':element.IsModified}">
            <div class="flex-test-name">
              <div class="avatar onlyMobile"><span class="titleWrapBox">{{element.TestShortName.charAt(0)}}</span></div>
              <span class="title onlyDesktop" *ngIf="element.TestShortName">{{element.TestShortName}}</span>
              <div class="mobile-td onlyMobile">
                <span class="title pr-20" *ngIf="element.TestShortName">{{element.TestShortName}}</span>
              </div>
            </div>
          </td>
        </ng-container>


        <ng-container matColumnDef="lab">

          <th mat-header-cell *matHeaderCellDef class="wp-25" mat-sort-header="labName"> {{'appointments.lab' |
            translate}} </th>
          <td mat-cell *matCellDef="let element">
            <div class="comments" *ngIf="element?.ShowComments">{{element.Comments }}</div>
            <span class="title onlyDesktop" *ngIf="element.SamplingStationName">{{element.SamplingStationName}}</span>
            <div class="onlyMobile icon-ic-location ss-name">
              <span class="title pr-20" *ngIf="element.SamplingStationName">{{element.SamplingStationName}}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="labContact">
          <th mat-header-cell *matHeaderCellDef class="wp-25" mat-sort-header="lab_Contact">
            {{'appointments.lab-contact'
            |
            translate}} </th>
          <td mat-cell *matCellDef="let element" class="flex-td">
            <span class="title onlyDesktop" *ngIf="element.PhoneNumber">{{element.PhoneNumber}}</span>
            <div class="onlyMobile icon-ic-mobile ss-name">
              <span class="title pr-20" *ngIf="element.PhoneNumber">{{element.PhoneNumber}}</span>
            </div>
          </td>
        </ng-container>



        <ng-container matColumnDef="dateTime">
          <th mat-header-cell *matHeaderCellDef class="wp-10" mat-sort-header="SlotTime"> {{'appointments.date-time' |
            translate}} </th>
          <td mat-cell *matCellDef="let element">
            <ng-container>
              <div class="aaa">
                <span class="mobile-date onlyMobile">{{element.SlotTime | dateFormat}}</span>
                <span class="onlyDesktop">{{element.SlotTime | dateFormat}}</span>
                <div class="appointment-info">
                  <div class="isCancelled" *ngIf="element.IsDeleted">{{'appointments.cancelled' | translate}}</div>
                  <div class="isModified" *ngIf="element.IsModified">{{'appointments.modified' | translate}}</div>
                  <div class="history-info" *ngIf="element.IsModified" (click)="getHistory(element)">i</div>
                </div>
                <div class="read-container" *ngIf="element.isOpen && element.history" (click)="getHistory(element)">
                  <div class="sb10"
                    [ngClass]="element.IsSlotAvailable=='true' ? 'container-content-slot' : 'container-content' ">
                    <div class="history-label">{{'appointments.app-shifted' | translate}}</div>
                    <div class="slots" *ngFor="let row of element.history">
                      {{row | dateFormat}}</div>
                  </div>
                </div>
              </div>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="wp-10"></th>
          <td mat-cell *matCellDef="let element" class="force-display">
            <div class="onlyDesktop">
              <button class="mat-tbl-icon show-comment " [disabled]="element.Comments == ''"
                matTooltip="{{element.Comments ? element.Comments: 'receptionist.no-comment'|translate }}"
                matTooltipClass="tooltip-style" mat-icon-button>
                <span class="icon-ic-remarks"></span>
              </button>
            </div>

          </td>
        </ng-container>
        <ng-container matColumnDef="noRecord">
          <td colspan="7" mat-footer-cell *matFooterCellDef>
            <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
            <div class="desc">
              <p>{{'appointments.empty' | translate}}</p>
              <p>{{'appointments.search-patient' | translate}}</p>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="otherAppointmentsDisplayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: otherAppointmentsDisplayedColumns;"
          [ngClass]="{'cancelled-row': element.IsDeleted && !element.IsArrived,'modified-row':element.IsModified}">
        </tr>
        <ng-template [ngIf]="otherAppointmentsDataSource && otherAppointmentsDataSource.data.length === 0">
          <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
        </ng-template>
      </table>
    </mat-tab>

  </mat-tab-group>
  <app-stepper-flow *ngIf="isBookingFlow" [isBackEnabled]="true" (backClicked)="isBookingFlow = false">
  </app-stepper-flow>
</div>