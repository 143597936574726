import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SharedModule } from '../../shared';
import { MainPipe } from '../../shared/MainPipe.module';
import { MaterialModule } from '../../shared/material.module';
import { EClinicalSharedModule } from '../shared/shared.module';
import { BookAppointmentComponent } from './appointment-booking/book-appointment/book-appointment.component';
import { ConfirmBookingComponent } from './confirm-booking/confirm-booking.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocumentsComponent } from './documents/documents.component';
import { UploadDocumentComponent } from './documents/upload-document/upload-document.component';
import { FavouriteLabComponent } from './favourite-lab/favourite-lab.component';
import { GetDirectionsComponent } from './favourite-lab/get-directions/get-directions.component';
import { AllApointmentComponent } from './my-apointment/all-apointment/all-apointment.component';
import { MyApointmentComponent } from './my-apointment/my-apointment.component';
import { PastApointmentComponent } from './my-apointment/past-apointment/past-apointment.component';
import { UpcomingApointmentComponent } from './my-apointment/upcoming-apointment/upcoming-apointment.component';
import { PatientInfoComponent } from './patient-info/patient-info.component';
import { PatientRoutingModule } from './patient-routing.module';
import { PatientComponent } from './patient.component';
import { QrCodeCompComponent } from './qr-code-comp/qr-code-comp.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AllResultsComponent } from './results/all-results/all-results.component';
import { RedirectDialogComponent } from './results/redirect-dialog/redirect-dialog.component';
import { ResultsReadyComponent } from './results/results-ready/results-ready.component';
import { ResultsComponent } from './results/results.component';
import { WaitingResultsComponent } from './results/waiting-results/waiting-results.component';
import { WebcamModule } from 'ngx-webcam';
import { DocumentPopupComponent } from './document-popup/document-popup.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { DocumentManagementComponent } from './set-password/document-management/document-management.component';
import { NgOtpInputModule } from "ng-otp-input";
import { RelativeMembersComponent } from './relative-members/relative-members.component';
// import { AddMemberComponent } from './relative-members/add-member/add-member.component';
//import { FindMemberComponent } from './relative-members/find-member/find-member.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { ManageAccessComponent } from './relative-members/manage-access/manage-access.component';
import { RelativeMemberUpcomingApointmentComponent } from './my-apointment/relative-member-upcoming-apointment/relative-member-upcoming-apointment.component';
import { RelativeMemberPastApointmentComponent } from './my-apointment/relative-member-past-apointment/relative-member-past-apointment.component';
import { MyDocumentsComponent } from './documents/my-documents/my-documents.component';
import { RelativeMemberDocumentsComponent } from './documents/relative-member-documents/relative-member-documents.component';

@NgModule({
    declarations: [
        PatientInfoComponent,
        PatientComponent,
        ResetPasswordComponent,
        MyApointmentComponent,
        AllApointmentComponent,
        UpcomingApointmentComponent,
        PastApointmentComponent,
        BookAppointmentComponent,
        DocumentsComponent,
        UploadDocumentComponent,
        ResultsComponent,
        AllResultsComponent,
        WaitingResultsComponent,
        ResultsReadyComponent,
        QrCodeCompComponent,
        DashboardComponent,
        FavouriteLabComponent,
        GetDirectionsComponent,
        RedirectDialogComponent,
        ConfirmBookingComponent,
        DocumentPopupComponent,
        SetPasswordComponent,
        DocumentManagementComponent,
        RelativeMembersComponent,
        //FindMemberComponent,
        ManageAccessComponent,
        RelativeMemberUpcomingApointmentComponent,
        RelativeMemberPastApointmentComponent,
        MyDocumentsComponent,
        RelativeMemberDocumentsComponent,
    ],
    imports: [
        CommonModule,
        PatientRoutingModule,
        FormsModule,
        MaterialModule,
        RouterModule,
        ReactiveFormsModule,
        DragDropModule,
        TranslateModule,
        SharedModule,
        EClinicalSharedModule,
        MainPipe, WebcamModule,
        NgOtpInputModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        NgxMatIntlTelInputModule,
    ]
})
export class PatientModule { }
