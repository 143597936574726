import { Component, ElementRef, OnInit,AfterViewInit, ViewChild } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { LoaderService, MessageService } from "../../../main";
import { PasswordPolicyService } from "../../shared/password-policy/password-policy.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../services/authentication.service";
import { AppSettingsService, SpinnerService } from "../../../core";
import { PatientService } from "../../services/patient.service";
import * as CryptoJS from "crypto-js";
import { ActivatedRoute } from "@angular/router";
import { FileData } from "../../../features/styles/models/file-data";
import { LoginServiceService } from "../../services/login-service.service";
import { PrescriptionDocument } from "../../shared/appointment-prescription/appointment-prescription.model";
import { GoogleMapService } from "../../shared/lab-google/google-map.service";
import { constants } from "../../shared/constant";
import { DatePipe } from "@angular/common";
declare var woosmap: any;
@Component({
  selector: "app-set-password",
  templateUrl: "./set-password.component.html",
  styleUrls: ["./set-password.component.scss"],
})
export class SetPasswordComponent implements OnInit,AfterViewInit {
  @ViewChild("newpassword") newpassword: ElementRef;
  public nationality: any;
  public IdentityFile = new FormControl("");
  public BirthFile = new FormControl("");
  public InsuranceFile = new FormControl("");
  public minorPassport = new FormControl("");
  public insurance = new FormControl("");
  public insuranceExpiry = new FormControl(null);
  value: string;
  step1 = true;
  maxDate: Date;
  documentType: any[];
  public minor: any;
  patientData: any;
  login_id: any;
  public newSalt: string;
  public isUpdate: boolean;
  public identity: FileData = null;
  private birthCertificate: FileData = null;
  private insuranceDoc: FileData = null;
  public isHovering: boolean;
  public isEmailNotificationRequired: boolean;
  public isSaveClicked: boolean;
  public registerUserClickedinsuranceExpiry = false;
  choices = [
    { Id: 1, KeyName: "Yes" },
    { Id: 2, KeyName: "No" },
  ];

  public docForm = this.formBuilder.group({
    insuranceDoc: this.insuranceDoc,
    IdentityFile: this.IdentityFile,
    BirthFile: this.BirthFile,
    InsuranceFile: this.InsuranceFile,
    birthCertificate: this.birthCertificate,
    identity: this.identity,
    insuranceExpiry: this.insuranceExpiry,
    insurance: this.insurance,
  });
  hide = true;
  isUser = false;
  isNurse = false;
  ifFrance = false;
  isPasswordValid: boolean;
  isResetPasswordHide = true;
  passwordsMisMatch = false;
  strAfterPercent: string;
  isDisable = true;
  params: any;
  nationalities: any[];
  isAutoCompleteFocused: boolean;
  autocomplete: any;
  place: any;
  isAddressRequired: boolean;
  isTermsFlagRequired: boolean;
  obj = {
    age: 0,
    nationality: 0,
  };
  paramsObject: any;
  public minDate = new Date();
  public terms = new FormControl(false);
  public privacy = new FormControl(false);
  public resetpassword = new FormControl("", [
    Validators.required,
    Validators.pattern(constants.passwordValidation),
    this.noWhitespaceValidator,
  ]);
  public repeatresetpassword = new FormControl("", [
    Validators.required,
    Validators.pattern(constants.passwordValidation),
    this.noWhitespaceValidator,
  ]);
  public address = new FormControl("", [Validators.required]);
  public country = new FormControl("", [Validators.required]);
  public city = new FormControl("", [
    Validators.required,
    Validators.pattern("^.{1,50}$"),
  ]);
  public zip = new FormControl("", [
    Validators.required,
    Validators.pattern("^[0-9 ]{4,10}$"),
  ]);
  public forgetPasswordForm = new FormGroup({
    resetpassword: this.resetpassword,
    repeatresetpassword: this.repeatresetpassword

  });

  public termsForm = new FormGroup({
    terms: this.terms,
    privacy: this.privacy,
  });
  public identityDocuments: PrescriptionDocument = {
    Id: 1,
    PrescriptionParts: [],
    IsIncorrectFileFormat: false,
    IsInvalidFileSize: false,
    Expanded: true,
  };
  public birthDocuments: PrescriptionDocument = {
    Id: 2,
    PrescriptionParts: [],
    IsIncorrectFileFormat: false,
    IsInvalidFileSize: false,
    Expanded: true,
  };
  public insuranceDocuments: PrescriptionDocument = {
    Id: 3,
    PrescriptionParts: [],
    IsIncorrectFileFormat: false,
    IsInvalidFileSize: false,
    Expanded: true,
  };
  public identityItem = {
    Id: 1,
    FrontFileName: "",
    BackFileName: "",
    IsIncorrectFrontFileFormat: false,
    IsInvalidFrontFileSize: false,
    IsIncorrectBackFileFormat: false,
    IsInvalidBackFileSize: false,
    PrescriptionParts: ([] = []),
    Expanded: true,
  };
  public birthItem = {
    Id: 2,
    FrontSideData: "",
    BackSideData: "",
    FrontFileName: "",
    BackFileName: "",
    IsIncorrectFrontFileFormat: false,
    IsInvalidFrontFileSize: false,
    IsIncorrectBackFileFormat: false,
    IsInvalidBackFileSize: false,
    PrescriptionParts: ([] = []),
    Expanded: true,
  };
  public insuranceItem = {
    Id: 3,
    FrontSideData: "",
    BackSideData: "",
    FrontFileName: "",
    BackFileName: "",
    IsIncorrectFrontFileFormat: false,
    IsInvalidFrontFileSize: false,
    IsIncorrectBackFileFormat: false,
    IsInvalidBackFileSize: false,
    PrescriptionParts: ([] = []),
    Expanded: true,
  };
  constructor(
    public formBuilder: FormBuilder,
    public loginService: LoginServiceService,
    public loader: LoaderService,
    private passwordPolicy: PasswordPolicyService,
    private translate: TranslateService,
    public route: ActivatedRoute,
    public router: Router,
    public authenticationService: AuthenticationService,
    private messageService: MessageService,
    private patientService: PatientService,
    private spinnerService: SpinnerService,
    private activatedroute: ActivatedRoute,
    private googleMapService: GoogleMapService,
    private readonly appSettings: AppSettingsService
  ) {
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.maxDate = new Date();
    this.documentType = new Array<any>();
  }

  ngOnInit(): void {
    this.getDocumentType();
    if (this.route.snapshot.params.action == "bq431") {
      this.minor = false;
      this.nationality = 1;
    } else if (this.route.snapshot.params.action == "q7h3o") {
      this.minor = true;
      this.nationality = 1;
    } else if (this.route.snapshot.params.action == "bz75cr") {
      this.nationality = 2;
    }

    if (this.route.snapshot.params.address == "3465s") {
      this.isAddressRequired = false;
    } else {
      this.isAddressRequired = true;
    }
    if (this.route.snapshot.params.termsflag == "3465s") {
      this.terms.setValue(true);
      this.privacy.setValue(true);
      this.isTermsFlagRequired = false;
    } else {
      this.isTermsFlagRequired = true;
    }

    this.docForm.controls.insurance.setValue("1");
    this.minorPassport.setValue("1");
    const percentIndex = window.location.href.lastIndexOf("%");
    if (percentIndex !== -1) {
      this.strAfterPercent = window.location.href.substring(percentIndex + 1);
    }
    if (window.location.search.includes("set_password")) {
      var x = window.location.href;
      var y = x.slice(x.lastIndexOf("/") + 1);
      const urlParams = new URLSearchParams(y);
      const myParam = urlParams.get("id");
      this.loader.user_id = myParam;
    }
    this.params = this.activatedroute.snapshot.params;
    this.resetpassword.valueChanges.subscribe(() => {
      this.showErrorBox();
    });

    this.loginService.getAdminCommonCountries().subscribe((data: any[]) => {
      this.nationalities = data;
      const frNationIndex = this.nationalities.findIndex((i: any) => i.id == 1);
      const frNation = this.nationalities[frNationIndex];
      this.nationalities.splice(frNationIndex, 1);
      this.nationalities.sort((i) => i.country_name);
      this.nationalities.unshift(frNation);
    });

    this.addressAutocomplete();

    document.getElementsByClassName("app-sidenav-content")[0]?.addEventListener(
      "scroll",
      () => {
        if (this.isAutoCompleteFocused) {
          const elements: any =
            document.getElementsByClassName("pac-container");
          for (let i = 0; i < elements.length; ++i) {
            elements[i].style.display = "none";
          }
        }
      },
      false
    );
  }

  ngAfterViewInit(): void {
    if (typeof woosmap !== 'undefined') {
      //this.initializeMap();
      this.addressAutocomplete();
    } else {
      console.error('Woosmap SDK is not loaded!');
    }
  }

  public noWhitespaceValidator(password: FormControl) {
    const isWhitespace = (password.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  back() {
    this.step1 = true;
  }

  disableSpace(event: Event) {
    event.preventDefault();
  }

  checkIsValid(event: any) {
    if (event) {
      this.hideErrorBox();
    }
  }

  hideErrorBox() {
    this.passwordPolicy.hide();
  }

  showErrorBox() {
    if (this.isUser) {
      // for user role
      this.passwordPolicy.show(
        this.resetpassword.value,
        constants.extMinPwdLength,
        constants.pwdMaxLength
      );
      this.isPasswordValid = this.passwordPolicy.valid(
        this.resetpassword.value,
        constants.extMinPwdLength,
        constants.pwdMaxLength
      );
    } else if (this.isNurse) {
      // for nurse role
      this.passwordPolicy.show(
        this.resetpassword.value,
        constants.nurseMinPwdLength,
        constants.pwdMaxLength
      );
      this.isPasswordValid = this.passwordPolicy.valid(
        this.resetpassword.value,
        constants.nurseMinPwdLength,
        constants.pwdMaxLength
      );
    } else {
      // for patient role
      this.passwordPolicy.show(
        this.resetpassword.value,
        constants.intMinPwdLength,
        constants.pwdMaxLength
      );
      this.isPasswordValid = this.passwordPolicy.valid(
        this.resetpassword.value,
        constants.intMinPwdLength,
        constants.pwdMaxLength
      );
    }
  }

  passwordMatch() {
    if (
      this.repeatresetpassword.value !== "" &&
      this.resetpassword.value !== this.repeatresetpassword.value
    ) {
      this.passwordsMisMatch = true;
      this.repeatresetpassword.setErrors({ pattern: false });
    } else {
      this.passwordsMisMatch = false;
      this.isDisable = false;
    }
  }
  Next() {
    this.hide = true;
    if (
      this.resetpassword.value === this.repeatresetpassword.value &&
      this.resetpassword.value !== "" &&
      this.terms.value &&
      this.privacy.value
    ) {
      this.step1 = false;
      this.passwordsMisMatch = false;
      this.isDisable = false;
      this.insurance.addValidators(Validators.required);
    } else {
      this.passwordsMisMatch = true;
      this.isDisable = true;
    }
  }

  enableButton(): any {
    if (
      !this.forgetPasswordForm.valid ||
      this.terms.value == false ||
      this.privacy.value == false || (this.isAddressRequired && (this.address.invalid || this.country.invalid || this.city.invalid || this.zip.invalid))
    ) {
      return true;
    } else {
      return false;
    }
  }
  public ResetPassword() {
    this.spinnerService.show(true);
    let payload = {

      minor_passport: this.minorPassport.value == "1",
      pvt_insurance: this.insurance.value == "1",
      is_terms_accepted: this.terms.value,
      is_privacy_accepted: this.privacy.value,
    };

    if (this.isAddressRequired) {
      const addressData = {
        unique_id: this.params.id,
        address: this.address.value,
        city: this.city.value,
        countryId: this.country.value,
        zip: this.zip.value,
      };
      this.patientService.updateAddress(addressData).subscribe(
        (data: any) => {
          console.log(data);
          if (this.IdentityFile.value !== "" ||
            this.InsuranceFile.value != "" ||
            this.BirthFile.value !== "") {
            this.UploadFiles();
          }
          else {
            this.UploadDocumentSetPassword(payload);
          }
        },
        (error: any) => {
          console.log(error);
          this.messageService.alert(this.translate.instant("login.password-reset-error"), 5000);
          this.spinnerService.show(false);
        }
      );
    }
    else {
      if (this.IdentityFile.value !== "" ||
        this.InsuranceFile.value != "" ||
        this.BirthFile.value !== "") {
        this.UploadFiles();
      } else {
        this.UploadDocumentSetPassword(payload);
      }
    }

  }
  private UploadDocumentSetPassword(payload: any) {
    const newsalt = CryptoJS.lib.WordArray.random(128 / 20).toString();
    const password = this.loader.encryptData(
      this.resetpassword.value,
      newsalt
    );

    payload.unique_id = this.params.id
    this.loader.password = password;
    payload.Password = password;
    payload["salt"] = newsalt;
    payload.address_flag = this.params.address;

    this.patientService.postuploadDocuments(payload).subscribe(
      () => {
        //this.setPassword();
        this.spinnerService.show(false);
        this.loader.highlightLeftMenu(["/auth/login"]);
        this.messageService.success(this.translate.instant("login.password-set"), 5000);
        // this.messageService.success(
        //   this.translate.instant("login.activated-login"),
        //   5000
        // );
        //this.loader.highlightLeftMenu(["/"]);
      },
      (error: any) => {
        console.log(error);
        //this.messageService.alert(error.error.message, 5000);
        this.messageService.alert(this.translate.instant(
          "backend-errors.500"
        ),
          5000
        );
        this.loader.highlightLeftMenu(["/auth/login"]);
        this.spinnerService.show(false);
      }
    );
  }

  public getDocumentType() {
    this.loginService.getDocType().subscribe((data: Array<any>) => {
      this.documentType = data.sort((a, b) => a.KeyOrder - b.KeyOrder);
    });
  }

  public addressAutocomplete() {
    this.googleMapService.api.then(() => {
      const options = {
        "components": {
          country: this.appSettings?.country?.split(";")
        },
        "types": ['locality', 'postal_code', 'address', 'admin_level', 'country', 'train_station', 'metro_station', 'shopping', 'airport', 'museum', 'zoo', 'amusement_park', 'art_gallery', 'tourist_attraction']
      };
      //@ts-ignore
      const localitiesWidget = new window.woosmap.localities.Autocomplete("autocomplete-input", options);
      localitiesWidget.addListener("selected_suggestion", ()=> {
        this.spinnerService.show(true);
        const selectedLocality = localitiesWidget.getSelectedSuggestionDetails();
        if (selectedLocality.address_components) {
          this.address.setValue(selectedLocality.formatted_address);
          selectedLocality.address_components.filter((item: any) => {
            if (item.types[0] == "postal_code") {
              this.zip.setValue(item.long_name[0]);
            }
            if (item.types[0] == "country") {
              this.nationalities.forEach((n: any, index: number) => {
                if (n.country_name == item.long_name) {
                  this.spinnerService.show(false);
                  this.country.setValue(n.id);
                  document.getElementById("country").focus();
                } else {
                  this.spinnerService.show(false);
                }
                if (index == this.nationalities.length - 1) {
                  this.spinnerService.show(false);
                }
              });
            }
            if (item.types[0] == "locality") {
              this.city.setValue(item.long_name);
            }
            if (item.types[0] == "administrative_area_level_2") {
              this.city.setValue(item.long_name);
            }
            if (item.types[0]=="administrative_area_level_1") {
              this.city.setValue(item.long_name);
            }
            if (item.types[0]=="county") {
              this.city.setValue(item.long_name);
            }
            document.getElementById("Zipcode").focus();
            document.getElementById("autocomplete-input").focus();
          });
        } else {
          this.spinnerService.show(false);
        }
      });
    });
  }

  public proceed() {
    this.minor = true; // testing
    this.step1 = false;
    this.insurance.addValidators(Validators.required);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  public clearForm() {
    this.insuranceExpiry.removeValidators(Validators.required);
    this.removeIdentityFile();
    this.removeBirthFile();
    this.removeInsuranceFile();
    this.insurance.setValue("1");
    this.minorPassport.setValue("1");
  }
  public removeIdentityFile(): void {
    this.identity = null;
    this.docForm.controls.IdentityFile.setValue("");
  }
  public removeIdentityFileOnly(): void {
    this.identity = null;

    this.docForm.controls.IdentityFile.setValue("");
  }
  public removeBirthFile(): void {
    this.birthCertificate = null;
    this.docForm.controls.BirthFile.setValue("");
  }
  public removeInsuranceFile(): void {
    this.insuranceDoc = null;
    this.registerUserClickedinsuranceExpiry = false;
    this.insuranceExpiry.setValue("");
    this.docForm.controls.InsuranceFile.setValue("");
  }

  public removeInsuranceFileOnly(): void {
    this.insuranceDoc = null;
    this.docForm.controls.InsuranceFile.setValue("");
    if (this.insuranceItem.PrescriptionParts.length == 0) {
      this.registerUserClickedinsuranceExpiry = false;
      this.insuranceExpiry.setValue("");
    }
  }

  public getUpdateIdentityDocument(event: any) {
    this.identityDocuments = event;
    this.docForm.controls.IdentityFile.setValue("");
    this.docForm.controls.IdentityFile.markAllAsTouched();
    if (this.identityDocuments) {

      this.docForm.controls.IdentityFile.setValue("Identity-Captured");
      this.docForm.controls.IdentityFile.markAsDirty();
    }
  }

  public getUpdateBirthDocument(event: any) {
    this.birthDocuments = event;
    this.docForm.controls.BirthFile.setValue("");
    this.docForm.controls.BirthFile.markAllAsTouched();
    if (this.birthDocuments) {
      this.docForm.controls.BirthFile.setValue("Birth-Captured");
      this.docForm.controls.BirthFile.markAsDirty();
    }
  }

  public getUpdateInsuranceDocument(event: any) {
    this.insuranceDocuments = event;
    this.docForm.controls.InsuranceFile.setValue("");
    this.docForm.controls.InsuranceFile.markAllAsTouched();
    if (this.insuranceDocuments) {
      this.registerUserClickedinsuranceExpiry = true;
      this.docForm.controls.InsuranceFile.setValue("Identity-Captured");
      this.docForm.controls.InsuranceFile.markAsDirty();
    }
  }

  identityObject() {
    let doc: any;
    if (this.identityDocuments?.OrgFiles?.length == 1 && this.identityDocuments?.OrgFiles[0] != "") {
      doc = {
        prescriptions_pages: this.identityDocuments?.OrgFiles.map(
          (s: any) => s
        ),
      };
    } else if (this.identityDocuments?.PrescriptionParts.length > 0) {
      doc = {
        prescriptions_pages: this.identityDocuments?.PrescriptionParts.map(
          (s: any) => s.prescriptionPartData
        ),
      };
    }
    return {
      document_type: this.documentType.find(
        (t) => t.KeyName == "Identity card / Passport"
      ).Id,
      description: "",
      details: "",
      expiry_date: "0001-01-01",
      document_name: this.identityDocuments?.PrescriptionParts[0]?.PrescriptionPartName,
      document_data: doc,
    };
  }
  birthObject() {
    let doc: any;
    if (this.birthDocuments?.OrgFiles?.length == 1 && this.birthDocuments?.OrgFiles[0] != "") {
      doc = {
        prescriptions_pages: this.birthDocuments?.OrgFiles.map(
          (s: any) => s
        ),
      };
    } else if (this.birthDocuments?.PrescriptionParts.length > 0) {
      doc = {
        prescriptions_pages: this.birthDocuments?.PrescriptionParts?.map(
          (s: any) => s.prescriptionPartData
        ),
      };
    }
    return {
      document_type: this.documentType.find(
        (t) => t.KeyName == "Birth Certificate"
      ).Id,
      description: "",
      details: "",
      expiry_date: "0001-01-01",
      document_name: this.birthDocuments?.PrescriptionParts[0]?.PrescriptionPartName,
      document_data: doc,
    };
  }
  insuranceObject() {
    let doc: any;
    if (this.insuranceDocuments?.OrgFiles?.length == 1 && this.insuranceDocuments?.OrgFiles[0] != "") {
      doc = {
        prescriptions_pages: this.insuranceDocuments?.OrgFiles.map(
          (s: any) => s
        ),
      };
    } else if (this.insuranceDocuments?.PrescriptionParts.length > 0) {
      doc = {
        prescriptions_pages: this.insuranceDocuments?.PrescriptionParts.map(
          (s: any) => s.prescriptionPartData
        ),
      };
    }
    const datePipe = new DatePipe("en-US");
    return {
      document_type: this.documentType.find(
        (t) => t.KeyName == "Mutual certificate"
      ).Id,
      description: "",
      details: "",
      expiry_date: datePipe.transform(this.insuranceExpiry.value, "yyyy-MM-dd"),
      document_name: this.insuranceDocuments?.PrescriptionParts[0]?.PrescriptionPartName,
      document_data: doc,
    };
  }
  public SaveDocuments() {
    this.registerUserClickedinsuranceExpiry = this.InsuranceFile.value !== "";
    let insuranceCheck = this.registerUserClickedinsuranceExpiry
      ? this.insuranceExpiry.value !== null && this.insuranceExpiry.value !== ""
      : true;

    if (this.registerUserClickedinsuranceExpiry) {
      this.insuranceExpiry.markAsTouched();
    }
    if (this.insurance.value === "2") {
      insuranceCheck = true;
      this.removeInsuranceFile();
    }
    if (
      (this.InsuranceFile.value != "" && this.insuranceExpiry.value == "")
    ) {
      this.messageService.alert(
        this.translate.instant("patient.select-expiry"),
        5000
      );
    }
    if (
      this.docForm.valid &&
      insuranceCheck &&
      (this.IdentityFile.value === "" ||
        (this.minorPassport.value === "2" && this.BirthFile.value === "") ||
        (this.insurance.value === "1" && this.InsuranceFile.value === ""))
    ) {
      this.messageService
        .confirm("createAccount.alertText", "createAccount.alerTitle")
        .subscribe((actionResult: boolean) => {
          if (actionResult) {
            if (this.docForm.valid) {
              this.ResetPassword();
            }
          }
        });
    } else if (insuranceCheck && this.docForm.valid) {
      this.ResetPassword();
    }
  }

  public UploadFiles() {
    const docs: {
      document_type: any;
      description: string;
      details: string;
      expiry_date: string;
      document_name: string;
      document_data: any;
    }[] = [];
    if (this.IdentityFile.value != "") {
      docs.push(this.identityObject());
    }
    if (this.BirthFile.value != "" && this.minor) {
      docs.push(this.birthObject());
    }
    if (this.InsuranceFile.value != "") {
      docs.push(this.insuranceObject());
    }
    let payload = {
      minor_passport: this.minorPassport.value == "1",
      pvt_insurance: this.insurance.value == "1",
      documents: docs,
    };
    this.UploadDocumentSetPassword(payload);

  }

  resetBirthField(id: any) {
    if (id == 2) {
      this.birthCertificate = null;
      this.docForm.controls.BirthFile.setValue("");
      this.birthItem.PrescriptionParts = [];
    }
  }

  resetField(id: any) {
    if (id == 2) {
      this.insuranceItem.PrescriptionParts = [];
      this.removeInsuranceFileOnly();
    }
  }
}
