import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { LoaderService } from '../../../main';
import { TestService } from '../../services/add-test-service.service';
import { CommonApiService } from '../../services/common-api.service';
import { PatientAppointmentService } from '../../services/patient-appointment.service';
import { SamplingStations } from '../user/User';

@Component({
    selector: 'app-test-history',
    templateUrl: './test-history.component.html',
    styleUrls: ['./test-history.component.scss']
})
export class TestHistoryComponent implements OnInit {
    totalCount: any;
    range = new FormGroup({
        start: new FormControl<Date | null>(null),
        end: new FormControl<Date | null>(null)
    });
    public hoverindex = '';
    sampleData = [
        { 'id': 1, 'value': 'history.Recieved'},
        {'id': 2, 'value': 'history.In progress'},
        {'id': 3, 'value': 'history.Analysis validation'},
        {'id': 4, 'value': 'history.Result available'}
    ];
    searchinput: any = '';
    samplingStation: SamplingStations[];
    loggedInOrg = JSON.parse(sessionStorage.getItem('access_token'))['org_id'];
    status: any = '';
    samplingSelected: any = 0;
    testnameSelected: any = 0;
    startDateSelected: any = '';
    endDateSelected: any = '';
    isSamplingSelected: boolean;
    isTestSelected: boolean;
    isStatusSelected: boolean;
    // testHistoryData:any;
    TestName = new FormControl();
    testList: any;
    selectedTestValues: any[] = [];
    selectedSamplingValues: any[] = [];
    selectedStatusValues: any[] = [];
    selectedTestStatus: any[] = [];

    public columnList = [
        'ID',
        'Name',
        'TestMadeBy',
        'TestName',
        'TestedUser',
        'TestKitCode',
        'SentToAPI',
        'Status',
        'TestedDate'
    ];
    filterObject = {
        search: ''
    };
    FilterForm = new FormGroup({
        searchinput: new FormControl(),
        samplingSelected: new FormControl(),
        testnameSelected: new FormControl(),
        status: new FormControl(),
        startDateSelected: new FormControl(),
        endDateSelected: new FormControl()

    });

    public dataSource: MatTableDataSource<any>;
    public dataList: MatTableDataSource<any>;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    selectedIndex: number;
    constructor(
        private patientAppointmentService: PatientAppointmentService,
        private commonAPi: CommonApiService,
        private testService: TestService,
        public loaderService: LoaderService
    ) {
        this.dataSource = new MatTableDataSource<any>();
        this.dataList = new MatTableDataSource<any>();
    }

    ngOnInit(): void {
        this.loaderService.onLangChangeCallback().subscribe(() => {
            this.searchAPI();
        });
        this.patientAppointmentService
            .getTestHistory()
            .subscribe((testHistoryList: any) => {
                this.dataSource.data = testHistoryList;
                this.dataList.data = this.dataSource.data;
                this.totalCount = this.dataSource.data.length;
                this.dataSource.filterPredicate = (data, filter) => {
                    const filterObj = JSON.parse(filter);
                    if (filterObj.search !== '') {
                        return (
                            String(data.patient_id)
                                .toLowerCase()
                                .includes(filterObj.search.toLocaleLowerCase()) ||
              String(data.first_name)
                  .toLowerCase()
                  .includes(filterObj.search.toLowerCase()) ||
              String(data.appointment_id)
                  .toLowerCase()
                  .includes(filterObj.search.toLocaleLowerCase()) ||
              String(data.last_name)
                  .toLowerCase()
                  .includes(filterObj.search.toLowerCase()) ||
              String(data.birth_name)
                  .toLowerCase()
                  .includes(filterObj.search.toLowerCase())
                        );
                    } else {
                        const result =
              String(data.patient_id)
                  .toLowerCase()
                  .includes(filterObj.search.toLocaleLowerCase()) ||
              String(data.first_name)
                  .toLowerCase()
                  .includes(filterObj.search.toLowerCase()) ||
              String(data.appointment_id)
                  .toLowerCase()
                  .includes(filterObj.search.toLocaleLowerCase()) ||
              String(data.last_name)
                  .toLowerCase()
                  .includes(filterObj.search.toLowerCase()) ||
              String(data.birth_name)
                  .toLowerCase()
                  .includes(filterObj.search.toLowerCase());
                        return result;
                    }
                };
            });
        this.getSamplingStation();
        this.getTestName();

        this.dataSource.sortingDataAccessor = (
            data: any,
            sortHeaderId: string
        ): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            }

            return data[sortHeaderId];
        };
    }

    expandRow(index: number) {
        this.selectedIndex = index !== this.selectedIndex ? index : null;
    }

    private getSamplingStation() {
        this.commonAPi.GetSamplingStation(this.loggedInOrg).subscribe((x: any) => {
            this.samplingStation = x?.sampling_stations;
        });
    }
    private getTestName() {
        this.testService.getAllAdminTestTypes().subscribe((x: any) => {
            x.sort((a: any, b: any) => a.short_name.localeCompare(b.short_name));
            this.testList = x;

        });
    }

    public onSearch(): void {
        this.filterObject.search = this.FilterForm.controls.searchinput.value;
        this.dataSource.filter = JSON.stringify(this.filterObject);
    }
    public onCheckTest(event: any): void {
        if (event.value.length) {
            this.isTestSelected = true;
            this. selectedTestValues = [];
            for (const data of event.value) {
                this.selectedTestValues.push(data.id);
            }

        } else {
            this.dataSource.data = this.dataList.data;
            this.isTestSelected = false;
        }
        this.searchAPI();

    }
    public onCheckSampling(event: any) {
        if (event.value.length) {
            this.isSamplingSelected = true;
            this.selectedSamplingValues = [];
            for (const data of event.value) {
                this.selectedSamplingValues.push(data.id);
            }
        } else {
            this.isSamplingSelected = false;
            this.dataSource.data = this.dataList.data;
        }

        this.searchAPI();
    }

    onStatusCheck(event: any) {
        if (event.value.length) {
            this.isStatusSelected = true;
            this.selectedStatusValues = [];
            for (const data of event.value) {
                this.selectedStatusValues.push(data.id);
            }
        } else {
            this.isStatusSelected = false;
            this.dataSource.data = this.dataList.data;
        }

        this.searchAPI();
    }
    allCheck() {
        if (this.isSamplingSelected && this.isTestSelected && this.isStatusSelected ) {
            this.dataSource.data = this.dataList.data;
        }
    }

    public reset(): void {
        this.dataSource.filter = '';
        this.FilterForm.controls.searchinput.setValue('');
        this.FilterForm.controls.status.setValue('');
        this.FilterForm.controls.samplingSelected.setValue(null);
        this.FilterForm.controls.testnameSelected.setValue(null);
        this.FilterForm.controls.startDateSelected.setValue('');
        this.FilterForm.controls.endDateSelected.setValue('');
        this.dataSource.data =  this.dataList.data;

    }
    public ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public dateFilter() {
        if (
            this.FilterForm.controls.startDateSelected.value !== '' &&
      this.FilterForm.controls.endDateSelected.value !== '' &&
      this.FilterForm.controls.startDateSelected.value !== null &&
      this.FilterForm.controls.endDateSelected.value !== null
        ) {
            this.searchAPI();
        }
    }

    /** Method to identify   grid row */
    public rowMouseOver(row: any): void {
        this.hoverindex = row.appointment_id;
    }

    public searchAPI() {
        this.dataSource.data = this.dataList.data;
        if (this.isTestSelected ) {
            this.dataSource.data = this.dataSource.data.filter((x: any) => this.selectedTestValues.includes(x.test_id));
        }
        if (this.isSamplingSelected) {
            this.dataSource.data = this.dataSource.data.filter((x: any) => this.selectedSamplingValues.includes(x.sampling_station_id));

        }
        if (this.isStatusSelected) {
            this.dataSource.data = this.dataSource.data.filter((x: any) => this.selectedStatusValues.includes(x.status_id));


        }

        if (!this.isTestSelected && !this.isSamplingSelected && !this.isStatusSelected) {
            this.dataSource.data = this.dataList.data;
        }

        const selectedStartDate = this.FilterForm.controls.startDateSelected.value;
        const selectedEndDate = this.FilterForm.controls.endDateSelected.value;
        if (
            selectedStartDate !== '' &&
      selectedEndDate !== '' &&
      selectedStartDate !== null &&
      selectedEndDate !== null
        ) {
            this.dataSource.data = this.dataSource.data.filter( (x: any) =>
                moment(x.arrived_time).startOf('day').isSameOrAfter(selectedStartDate) && moment(x.arrived_time).endOf('day').isSameOrBefore(selectedEndDate));
        }

    }

    /* Method to view data in the given order */
    unsorted() {}

    /**
   * This method will execute the when user will try to use space key keyboard and in the search box if the space is first character then
   * it will wont allow space to execute, It will block the space untill user enter a character.
   * @param event
   */
    keyDownHandler(event: Event) {
        if (this.searchinput.length === 0) {
            event.preventDefault();
        }
    }
}
