<div class="Container">
    <form class="pt-30" [formGroup]="FilterForm">
        <div class="d-flex justify-content-between search-mr">
            <div class="d-flex Search">
                <div id="inputsearch" class="">
                    <mat-form-field>
                        <mat-label>{{'user.id-name' | translate}}</mat-label>
                        <input (keyup)="onSearch()" formControlName="searchinput" matInput />
                    </mat-form-field>
                </div>
                <div id="samplingstationsearch" style="margin-left:5px">
                    <mat-form-field def="SamplingStation">
                        <mat-label class="label-float">{{'history.sampling-station' | translate}}</mat-label>
                        <mat-select formControlName="samplingSelected" (selectionChange)="onCheckSampling($event)"
                            multiple>
                            <app-select-check-all [model]="FilterForm.get('samplingSelected')"
                                [values]="samplingStation|| []" (allChecked)="allCheck()">
                            </app-select-check-all>
                            <mat-option *ngFor="let samp of samplingStation" [value]="samp" class="twolineText">
                                {{samp.sampling_station_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div id="testnamesearch" style="margin-left: 5px">
                    <mat-form-field def="TestName">
                        <mat-label class="label-float">{{'history.test-name' | translate}}</mat-label>
                        <mat-select formControlName="testnameSelected" (selectionChange)="onCheckTest($event)" multiple>
                            <app-select-check-all [model]="FilterForm.get('testnameSelected')" [values]="testList|| []"
                                (allChecked)="allCheck()">
                            </app-select-check-all>
                            <mat-option *ngFor=" let test of testList" [value]="test"
                                class="twolineText">{{test.short_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div id="statusfilter" style="margin-left: 5px">
                    <mat-form-field floatLabel="never">
                        <mat-select placeholder="{{'history.status' | translate}}"
                            (selectionChange)="onStatusCheck($event)" disableOptionCentering formControlName="status"
                            multiple>
                            <app-select-check-all [model]="FilterForm.get('status')" [values]="sampleData|| []"
                                (allChecked)="allCheck()">
                            </app-select-check-all>
                            <mat-option *ngFor="let stepOption of sampleData" [value]="stepOption">
                                {{stepOption.value | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div id="daterangesearch" style="margin-left:5px">
                    <mat-form-field>
                        <mat-label>{{'history.date-range' | translate}}</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate formControlName="startDateSelected" (dateChange)="dateFilter()" matInput
                                placeholder="{{'history.start-date'|translate}}">
                            <input matEndDate formControlName="endDateSelected" (dateChange)="dateFilter()" matInput
                                placeholder="{{'history.end-date'|translate}}">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div id="resetbtn" style="margin-left:5px" class="">
                    <button (click)="reset()" mat-button color="accent" class="ghost">
                        {{'user.reset' | translate}}
                    </button>
                </div>

                <!-- <div id="exportuserbtn" style="text-align: right" class="ml-4">
                <button [matMenuTriggerFor]="menu" mat-raised-button color="primary">
                    {{'history.export' | translate}}
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="exporter.exportTable('csv', { fileName: 'Test-Result' })">
                        .csv
                    </button>
                    <button mat-menu-item (click)="exporter.exportTable('xlsx', { fileName: 'Test-Result' })">
                        .xlsx
                    </button>
                </mat-menu>
            </div> -->

            </div>
            <!-- <div class="d-flex Button">
            <div id="exportuserbtn" style="text-align: right" class="ml-4">
                <button [matMenuTriggerFor]="menu" mat-raised-button color="primary">
                    {{'history.export' | translate}}
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="exporter.exportTable('csv', { fileName: 'Test-Result' })">
                        .csv
                    </button>
                    <button mat-menu-item (click)="exporter.exportTable('xlsx', { fileName: 'Test-Result' })">
                        .xlsx
                    </button>
                </mat-menu>
            </div>
        </div> -->
        </div>
    </form>
    <div class="main-container main-container-res">
        <mat-table [dataSource]="dataSource" matSort class="table-height input-min-spacing table-flex"
            id="EntityListingTable">
            <ng-container matColumnDef="ID">
                <mat-header-cell *matHeaderCellDef mat-sort-header="appointment_id">
                    <span>{{'history.id' | translate}}</span>
                </mat-header-cell>
                <mat-cell class="more-option" *matCellDef="let entity; let i = index">
                    <!-- <mat-icon class="expand" (click)="expandRow(i)">
                        {{ selectedIndex !== i ? 'expand_more' : 'expand_less'}}
                    </mat-icon> -->
                    {{ entity.appointment_id }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Name">
                <mat-header-cell *matHeaderCellDef mat-sort-header="first_name">
                    <span>{{'history.name' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity">
                    {{ entity.first_name }} {{entity.last_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="TestMadeBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header="sampling_station_name">
                    <span>{{'history.test-made-by' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity?.sampling_station_name}}">
                    {{ entity.sampling_station_name }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="TestName">
                <mat-header-cell *matHeaderCellDef mat-sort-header="short_name">
                    <span>{{'history.test-name' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity">
                    {{entity.short_name}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="TestedUser">
                <mat-header-cell *matHeaderCellDef mat-sort-header="patient_id">
                    <span>{{'history.tested-patient-id' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity">
                    {{ entity.patient_id }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="TestKitCode">
                <mat-header-cell *matHeaderCellDef mat-sort-header="sample_id">
                    <span>{{'history.test-kit-code' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity">
                    {{ entity.sample_id }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="SentToAPI">
                <mat-header-cell *matHeaderCellDef mat-sort-header="etws_processed_time">
                    <span>{{'history.sent-to-etws' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity">
                    {{ entity.etws_processed_time | date:'yyyy-MM-dd HH:mm' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Status">
                <mat-header-cell *matHeaderCellDef mat-sort-header="key_name">
                    <span>{{'history.status' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity">
                    {{ entity.key_name | translate}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="TestedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header="arrived_time">
                    <span>{{'history.tested-date' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity">
                    {{ entity.arrived_time | date:'yyyy-MM-dd HH:mm'}}
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnList; sticky: true"></mat-header-row>
            <mat-row [class.isExpand]="selectedIndex == i" *matRowDef="let row; columns: columnList; let i = index"
                class="element-grid-row" (mouseover)="rowMouseOver(row)" (click)="expandRow(i)">
            </mat-row>
        </mat-table>

        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons="true">
        </mat-paginator>
    </div>
</div>