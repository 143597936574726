<div class="hero-banner-container">
    <div class="d-flex flex-column">
        <header class="pull-left">
            <h5 class="pull-left"> {{'our-presence.heading'|translate}}</h5>
        </header>
        <div class="select-lang-title">
            {{'landing-page-config.select-lang'|translate}}
        </div>
        <mat-tab-group class="pull-left languageTab mt-10" [(selectedIndex)]="selectedLangTabIndex"
            (selectedTabChange)="onLangTabChange($event.index)" animationDuration="1000ms">
            <mat-tab label="{{'landing-page-config.lang1'|translate}}">
                <form [formGroup]="enForm">
                    <div class="heroBannerWrapper">
                        <div class="field-wrapper">
                            <mat-form-field>
                                <mat-label class="label-float">{{'our-presence.field1' | translate}}</mat-label>
                                <input matInput formControlName="section_title" name="enField1" id="enField1"
                                    max="{{field1MaxLength}}" maxlength="{{field1MaxLength}}" />
                            </mat-form-field>
                            <div class="character-count">
                                {{enForm.get('section_title')?.value?.length}}/{{field1MaxLength}}
                            </div>
                        </div>
                        <div> </div>

                        <div class="section-title">
                            {{'our-presence.section-image-title'|translate}}
                        </div>
                        <div></div>
                        <div class="field-row-wrapper">
                            <img [src]="imageUrl">
                            <div class="field-wrapper w-100">
                                <mat-form-field class="pointCursor browse-input" (click)="fileInput.click()">
                                    <mat-label class="form-label"
                                        i18n="@@Common.Component.BrowseFile">{{'landing-page-config.browse-image' |
                                        translate}}</mat-label>

                                    <input matInput formControlName="section_image_name"
                                        *ngIf="enForm.get('section_image_name').value == ''" id="uploadFileName"
                                        readonly />
                                    <input matInput formControlName="section_image_name"
                                        *ngIf="enForm.get('section_image_name').value != ''"
                                        placeholder="{{enForm.get('section_image_name').value}}" id="uploadFileName"
                                        readonly />
                                    <button class="mat-tbl-icon buttonInsideField" id="uploadFile">
                                        {{'upload-pic.browse' | translate}}
                                    </button>
                                </mat-form-field>
                                <input hidden type="file" accept=".png, .jpeg, .jpg" #fileInput
                                    (change)="inputChange($event)" />

                                <div class="character-count"
                                    [ngClass]="(isIncorrectFileFormat==true || isInvalidFileSize==true)?'color-error':''">
                                    {{'our-presence.field2-info'|translate}}
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </form>
            </mat-tab>
            <mat-tab label="{{'landing-page-config.lang2'|translate}}">
                <form [formGroup]="frForm">
                    <div class="heroBannerWrapper">
                        <div class="field-wrapper">
                            <mat-form-field>
                                <mat-label class="label-float">{{'our-presence.field1' | translate}}</mat-label>
                                <input matInput formControlName="section_title" name="frField1" id="frField1"
                                    max="{{field1MaxLength}}" maxlength="{{field1MaxLength}}" />
                            </mat-form-field>
                            <div class="character-count">
                                {{enForm.get('section_title')?.value?.length}}/{{field1MaxLength}}
                            </div>
                        </div>
                        <div> </div>

                        <div>
                            {{'our-presence.section-image-title'|translate}}
                        </div>
                        <div></div>
                        <div class="field-row-wrapper">
                            <img [src]="imageUrl">
                            <div class="field-wrapper w-100">
                                <mat-form-field class="pointCursor browse-input" (click)="fileInput.click()">
                                    <mat-label class="form-label"
                                        i18n="@@Common.Component.BrowseFile">{{'landing-page-config.browse-image' |
                                        translate}}</mat-label>

                                    <input matInput formControlName="section_image_name"
                                        *ngIf="frForm.get('section_image_name').value == ''" id="uploadFileName"
                                        readonly />
                                    <input matInput formControlName="section_image_name"
                                        *ngIf="frForm.get('section_image_name').value != ''"
                                        placeholder="{{frForm.get('section_image_name').value}}" id="uploadFileName"
                                        readonly />
                                    <button class="mat-tbl-icon buttonInsideField" id="uploadFile">
                                        {{'upload-pic.browse' | translate}}
                                    </button>
                                </mat-form-field>
                                <input type="file" hidden accept=".png, .jpeg, .jpg" #fileInput
                                    (change)="inputChange($event)" />

                                <div class="character-count"
                                    [ngClass]="(isIncorrectFileFormat==true || isInvalidFileSize==true)?'color-error':''">
                                    {{'our-presence.field2-info'|translate}}
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </form>
            </mat-tab>
        </mat-tab-group>
        <div class="actions">
            <button mat-raised-button color="primary" class="ghost submit-click"
                (click)="save()">{{'landing-page-config.save' |
                translate}}</button>
            <button mat-button color="accent" class="ghost"
                (click)="cancelClicked.emit()">{{'landing-page-config.cancel' |
                translate}}</button>
        </div>
    </div>
</div>