import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '../../core';

import { HttpService } from '../../core/http-interceptor/http.service';
import { EndPoints } from '../shared/EndPoints';
import { GetAuthLoginRequest } from './Models/getAuthAccessTokenRequestModel';

@Injectable({
    providedIn: 'root'
})
export class LoginServiceService {

    constructor(
        private httpService: HttpService,
        private http: HttpClient, private readonly appSettings: AppSettingsService) { }

    getAdminCommonCountries() {
        return this.httpService.Get(this.appSettings.adminBaseUrl + EndPoints.GET_ADMIN_COMMON_COUNTRIES);
    }
    getAdminCommonStates(countryId: string) {
        return this.http.get(this.appSettings.adminBaseUrl + 'common/states?country_id=' + countryId);
    }
    getAuthLogin(loginRequest: GetAuthLoginRequest) {
        return this.http.post(this.appSettings.authBaseUrl + 'login', JSON.stringify(loginRequest));
    }

    getPatientDetails() {
        return this.http.get(this.appSettings.patientBaseUrl + 'details?login_id=' + JSON.parse(sessionStorage.getItem('access_token'))['user_id']);
    }

    getRMDetails(loginId: any, p_id: any) {
        return this.http.get(this.appSettings.patientBaseUrl + 'details?login_id=' + loginId + "&p_id=" + p_id);
    }

    getGenderMasters() {
        return this.http.get(this.appSettings.adminBaseUrl + 'master?key=gender');
    }

    getOffSets() {
        return this.http.get(this.appSettings.adminBaseUrl + 'master?key=zoom');
    }

    getDocType() {
        return this.http.get(this.appSettings.adminBaseUrl + 'master?key=documenttype');
    }

    getOccurenceMasters() {
        return this.http.get(this.appSettings.adminBaseUrl + 'master?key=occurence');
    }

    getLevelMasters() {
        return this.http.get(this.appSettings.adminBaseUrl + 'master?key=holidays_level');
    }

}
