<form #updateForm="ngForm" novalidate>
  <div class="edit-container">
    <div class="header">
      <div class="pull-left">
        <app-section-title title="{{'edit-patient.edit-appointment' | translate}}"></app-section-title>
      </div>
      <div class="pull-right">
        <button class="slider_closebtn" mat-button color="accent" type="button" (click)="dismiss()">
          <span class="icon-ic-close"></span>
        </button>
      </div>
    </div>
    <div class="content">
      <h5>{{'edit-patient.patient-info' | translate}}</h5>
      <div class="patient-info">
        <div class="initial">
          <span>{{sliderFormData?.appointment_detail?.FirstName[0].toUpperCase()}}</span>
        </div>
        <div class="p-details">
          <p>{{sliderFormData?.appointment_detail?.FirstName}} {{sliderFormData?.appointment_detail?.LastName}}
            {{sliderFormData?.appointment_detail?.BirthName}}</p>
          <p *ngIf="sliderFormData?.appointment_detail?.SSN"> <span class="type">{{'edit-patient.ssn' | translate}} :
            </span><span class="desc">{{sliderFormData?.appointment_detail?.SSN}} </span></p>
        </div>
      </div>
      <div class="patient-personal">
        <h5>{{'edit-patient.booking-information' | translate}}</h5>
        <div class="p-details">
          <div class="infoTabs pl-30">
            <span class="icon-icon-my-appointment font-l span-class"></span>
            <p class="form-label mb-10">{{'edit-patient.booked-date' | translate}}</p>
            <div class="form-value">{{sliderFormData?.appointment_detail?.SlotTime | dateFormat}} </div>
          </div>
          <div class="infoTabs pl-30">
            <span class="icon-ic-test font-l span-class"></span>
            <p class="form-label mb-10">{{'edit-patient.test' | translate}}</p>
            <div class="form-value">{{sliderFormData?.appointment_detail?.TestShortName}}</div>
          </div>
        </div>
      </div>
      <div class="update-form" [class.red]="isUploaded == true" *ngIf="showCalendar">
        <app-date-slot [calendarDates]="slotData?.slots" (fromModalEvent)="selectedSlot($event)"
          [s_id]="a_data?.SamplingStationId" [test_id]="a_data?.TestId"></app-date-slot>
      </div>
      <div class="update-form  mt-30  pt-30 input-min-spacing"
        *ngIf="slotData.calendar_id == 0  && !sliderFormData?.appointment_detail?.Barcode">
        <div class="datepicker-container">
          <mat-form-field class="example-full-width">
            <input id="slotTime" matInput [matDatepickerFilter]="myFilter" [formControl]="slotTime" [min]="minDate"
              [max]="maxDate" [matDatepicker]="picker" [dateInput]="slotTime"
              (dateInput)="onSelectAppointmentDate($event)" (dateChange)="onSelectAppointmentDate($event)"
              placeholder="{{'stepper-flow.choose-date' | translate}}">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-error class="color-error" *ngIf="slotTime.value!=undefined">
            <span *ngIf="!slotTime.valid && isFuture">{{'stepper-flow.future-date' | translate}}</span>
            <span *ngIf="!slotTime.valid && isNonWorkingDay">{{'stepper-flow.non-working-day' | translate}}</span>
            <span *ngIf="!slotTime.valid && isPastDate">{{'stepper-flow.past-date' | translate}}</span>
          </mat-error>
        </div>
      </div>
      <div class="actions">
        <button mat-button color="accent" class="ghost" type="button" (click)="deleteAppointment()"
          [disabled]="sliderFormData.appointment_detail.StatusId==4" id="clear_btn">{{'edit-patient.cancel-booking' |
          translate}}</button>
        <button mat-raised-button color="primary" class="mr-20" type="submit"
          (click)="updateAppointment()">{{'edit-patient.update' |
          translate}}</button>
      </div>
    </div>
  </div>
</form>