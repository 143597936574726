import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgOtpInputConfig } from 'ng-otp-input';
import { LoaderService } from '../../../../main/services/loader.service';
import { PatientService } from '../../../services/patient.service';
import { ReqNoVerifyResponse } from './enter-token.model';
import { MessageService } from '../../../../main';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-enter-token',
  templateUrl: './enter-token.component.html',
  styleUrl: './enter-token.component.scss'
})
export class EnterTokenComponent implements OnInit {
  @Input() ss_id: string;
  @Output() goBack = new EventEmitter<any>();
  @Output() goNext = new EventEmitter<{ "isKioskAvailable": boolean, "tokenNumber": string, "appointmentTolerance": boolean }>();
  goToPrevious() {
    this.goBack.emit(true);
  }
  verifyResponse: ReqNoVerifyResponse;
  protected reqNumber: string;
  config: NgOtpInputConfig = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    letterCase: 'Upper'
  };
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  private subscription: Subscription = new Subscription();
  percent: number;

  constructor(readonly loader: LoaderService, private cdr: ChangeDetectorRef,
    public pService: PatientService,
    public messageService: MessageService,
    public translate: TranslateService) {
    console.log(this.loader.optionsG)
    this.verifyResponse = new ReqNoVerifyResponse();
  }
  ngOnInit(): void {
    this.subscription.add(
      this.loader.variable$.subscribe(value => {
        this.percent = (value / this.loader.flow_arr.length) * 100;
        this.cdr.detectChanges(); // Manually trigger change detection
      })
    );
  }

  protected onReqNoChange(reqNumber: string) {
    this.reqNumber = reqNumber;
  }


  goToBack() {
    this.goBack.emit(true);
  }

  public verifyReqNo() {
    if (this.reqNumber && this.reqNumber?.length == 6) {
      this.pService.verifyReqNo(this.reqNumber, this.ss_id).subscribe((res: ReqNoVerifyResponse) => {
        this.verifyResponse = res;
        if (res.is_processed) {
          this.messageService.alert(
            this.translate.instant("booking-flow.already-processed")
          );
        }
        else {
          if (res.turn != "") {
            this.goNext.emit({ "isKioskAvailable": true, "tokenNumber": res.turn, "appointmentTolerance": res.appointment_tolerance });
          }
          else {
            this.goNext.emit({ "isKioskAvailable": false, "tokenNumber": this.reqNumber, "appointmentTolerance": res.appointment_tolerance });
          }
        }
      }, (err: any) => {
        if (err.error.code == 400 && err.error.internal_code == 4001) {
          this.messageService.alert(
            this.translate.instant("booking-flow.invalid-ss-appointment")
          );
        }
        else if (err.error.code == 400 && err.error.internal_code == 4002) {
          this.messageService.alert(
            this.translate.instant("booking-flow.invalid-ss")
          );
        }
        else if (err.error.code == 400 && err.error.internal_code == 4003) {
          this.messageService.alert(
            this.translate.instant("booking-flow.invalid-request-number")
          );
        }
        else if (err.error.code == 400 && err.error.internal_code == 4004) {
          this.messageService.alert(
            this.translate.instant("booking-flow.invalid-ss")
          );
        }
        else if (err.error.code == 400 && err.error.internal_code == 4006) {
          this.messageService.alert(
            this.translate.instant("booking-flow.something-went-wrong")
          );
        }
        else if (err.error.code == 400 && err.error.internal_code == 4010) {
          this.messageService.alert(
            this.translate.instant("booking-flow.invalid-request")
          );
        }
        else if (err.error.code == 500 && err.error.internal_code == 5100) {
          this.messageService.alert(
            this.translate.instant("booking-flow.db-error")
          );
        }
        else {
          this.messageService.alert(
            this.translate.instant("booking-flow.db-error")
          );
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
