import { Component, Input, OnInit } from '@angular/core';
import { DrawerComponent } from '../../../core';
import { LoaderService } from '../../../main';

@Component({
    selector: 'app-privacy-policy-slider',
    templateUrl: './privacy-policy-slider.component.html',
    styleUrls: ['./privacy-policy-slider.component.scss']
})
export class PrivacyPolicySliderComponent implements OnInit, DrawerComponent {

    @Input() sliderFormData: any;
    @Input() public isSliderDataEdited = false;

    constructor(private loaderService: LoaderService) { }

    ngOnInit(): void {
    }

    public dismiss() {
        this.loaderService.cancelForm();
    }

}
