import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonApiService } from '../../eClinical/services/common-api.service';
import { MessageService } from '../services/message.service';

@Component({
    selector: 'app-activation-popup',
    templateUrl: './activation-popup.component.html',
    styleUrls: ['./activation-popup.component.scss']
})
export class ActivationpopupComponent {
    username: any;
    userEmail: any;
    activationLink: any;
    constructor(private readonly dialogRef: MatDialogRef<ActivationpopupComponent>,
        private readonly translateService: TranslateService,
        private readonly messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: any, public commonAPi: CommonApiService) {

        this.username = data.username;
        this.userEmail = data.username;
        this.activationLink = data.activationLink;
    }

    public sendactivationLink() {
        const email = {
            Email: this.username
        };
        this.commonAPi.SendActivationLink(email).subscribe(() => {
            this.messageService.success(this.translateService.instant('login.activated-mail-success'));
        });
    }

    /* #region Events and Methods */
    /** This method is to close the pop up */
    public dismiss(): void {
        this.dialogRef.close(false);
    }

    /** This method is to close the pop up and to pass confirmation action */
    public confirmed(): void {
        this.dialogRef.close(true);
    }
    /* #endregion */
}
