import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../app.locale.service';

@Component({
    selector: 'app-global-error',
    templateUrl: './global-error.component.html',
    styleUrls: ['./global-error.component.scss']
})
export class GlobalErrorComponent implements OnInit {
    /* #region Fields */
    @Input() public errorStatusCode: string;
    @Input() public isNavigateBackRequired = true;
    @Output() public navigatedBack: EventEmitter<void> = new EventEmitter<void>();
    public errorTitle: string;
    public errorMessage: string;
    public errorIcon: string;
    /* #endregion */

    constructor( private translate: TranslateService,
        private localeService: LocaleService, ) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    /* #region Events and Methods */
    public ngOnInit(): void {
        this.loadErrorDetailsByStatusCode();
    }

    /** This method is used to navigate the user to previouse page */
    public navigateBack(): void {
        this.navigatedBack.emit();
    }
    /* #endregion */

    /* #region Private Methods */
    /** This method is being used to load error details by error status code */
    private loadErrorDetailsByStatusCode(): void {
        switch (this.errorStatusCode) {
            case '401':
                this.errorIcon = 'icon-Unauthorized';
                break;
            case '403':
                this.errorIcon = 'icon-Forbidden';
                break;
            case '404':
                this.errorIcon = 'icon-404';
                break;
            default:
                this.errorStatusCode = '500';
                this.errorIcon = 'icon-Internal-Server-Error';
                break;
        }
    }
    /* #endregion */

}
