import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { SpinnerService } from '../../../core';
import { MessageService } from '../../../main';
import { LoaderService } from '../../../main/services/loader.service';
import { SpecialDate } from '../../admin/booking/special-dates/special-date';
import { SamplingStationSlot } from '../../models/common.model';
import { CommonApiService } from '../../services/common-api.service';
import { PatientAppointmentService } from '../../services/patient-appointment.service';
import { EditPatientAppointmentComponent } from '../../shared/edit-patient-appointment/edit-patient-appointment.component';
import { PatientAppointment } from './my-appointment.model';
import { ViewPrescriptionComponent } from '../../shared/view-prescription/view-prescription.component';
import { DateSlot } from '../../shared/date-slot/date-slot.model';
import { PrescriptionDocument } from '../../shared/appointment-prescription/appointment-prescription.model';
import { constants } from '../../shared/constant';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
export interface PeriodicElement {
    PatientID: string;
    KeyName: string;
    Category: string;
    SlotTime: string;
    PhoneNumber: number;
    SamplingStationName: string;
    AddressDetails: string;
}

@Component({
    selector: 'app-my-apointment',
    templateUrl: './my-apointment.component.html',
    styleUrls: ['./my-apointment.component.scss']
})
export class MyApointmentComponent implements OnInit {
    isPdfViewerOpened: boolean;
    pdfBlob: any;
    totalCnt: any = 0;
    totalRCnt: any = 0;
    appointmentData = true;
    ELEMENT_DATA: PatientAppointment[] = [];
    refresh = true;
    specialDates: SpecialDate[];
    calendars: SamplingStationSlot[];
    isHistoryData = false
    readonly breakpoint$ = this.breakpointObserver
        .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
        .pipe(
            tap(value => console.log(value)),
            distinctUntilChanged()
        );

    public patientRole = constants.patientRole;
    displayedColumns: string[] = ['patient', 'requestNo', 'test', 'lab', 'labContact', 'dateTime', 'actions'];
    displayedColumnsPast: string[] = ['patient', 'requestNo', 'test', 'lab', 'labContact', 'dateTime', 'prescrip'];
    dataSource: any = [];
    dataSourcePast: any = [];
    dataSourceFuture: any = [];
    // dataSourceMySelfUpcoming: any = [];
    // dataSourceMySelfPast: any = [];
    // dataSourceRelativeUpcoming: any = [];
    // dataSourceRelativePastUpcoming: any = [];
    editSliderContainer: any;
    uploadPrescriptionSlider: any;
    sub: any;
    patientPrescription: any;
    confirmDelTitle = 'receptionist.confirm-del-title';
    confirmDelContent = 'receptionist.confirm-del-content';
    protected replacePrescription = false;
    protected documents: PrescriptionDocument;
    private selectedappointmet: any;
    protected roleId = JSON.parse(sessionStorage.getItem('access_token')).role;
    protected item = { Id: 1, FrontSideData: "", BackSideData: "", FrontFileName: "", BackFileName: "", IsIncorrectFrontFileFormat: false, IsInvalidFrontFileSize: false, IsIncorrectBackFileFormat: false, IsInvalidBackFileSize: false, Expanded: true };
    protected slotData: DateSlot;
    protected isClicked: boolean = false;
    replaceEnable: boolean;
    protected uploadStatus: boolean = false;
    protected tabSelection: number = 0;
    protected navigationDetails: any;
    public patientComments: string;

    constructor(
        private breakpointObserver: BreakpointObserver,
        public router: Router,
        private readonly messageService: MessageService,
        private translate: TranslateService,
        private componentFactoryResolver: ComponentFactoryResolver,
        public loader: LoaderService, private commonAPi: CommonApiService,
        public toastr: ToastrService,
        private patientAppointmentService: PatientAppointmentService,
        private location: Location,
        public sanitizer: DomSanitizer,
        private readonly spinner: SpinnerService) {
        this.slotData = new DateSlot();
        this.breakpoint$.subscribe(() =>
            this.breakpointChanged()
        );
        this.navigationDetails = this.location.getState();
        if (this.navigationDetails && this.navigationDetails?.prescription) {
            this.patientPrescription = this.navigationDetails?.prescription;
            this.uploadPrecription(this.patientPrescription);
        }

        this.specialDates = new Array<SpecialDate>();
        this.calendars = new Array<SamplingStationSlot>();
        this.ELEMENT_DATA = new Array<PatientAppointment>();
        this.documents = new PrescriptionDocument();
    }

    ngOnInit(): void {
        if (this.loader.redirectRelMem) {
            this.tabSelection = 1;
            this.loader.redirectRelMem = false;
        }
    }

    private breakpointChanged() {
        if (this.breakpointObserver.isMatched('(max-width: 1050px) and (min-width: 601px)')) {
            this.editSliderContainer = 726;
            this.uploadPrescriptionSlider = 750;
        } else if (this.breakpointObserver.isMatched('(max-width: 600px)')) {
            this.editSliderContainer = '100%';
            this.uploadPrescriptionSlider = '100%';
        } else {
            this.editSliderContainer = 664;
            this.uploadPrescriptionSlider = 750;
        }
    }

    private getSlots(ss_id: any, t_id: any, selectedRowData: any) {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so adding 1
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        this.spinner.show(true);
        this.commonAPi.GetSlots(ss_id, t_id, 7, formattedDate)
            .subscribe((x) => {
                this.slotData = x;
                this.spinner.show(false);
                const componentFactory =
                    this.componentFactoryResolver.resolveComponentFactory(
                        EditPatientAppointmentComponent
                    );
                const payload = {
                    fromRecpectionist: false,
                    appointment_detail: selectedRowData,
                    slotData: this.slotData,
                };
                this.loader.addDynamicComponent(
                    componentFactory,
                    this.editSliderContainer,
                    payload
                );
            });
    }

    editPatientInfo(event: any) {
        if (this.loader.redirectRelMem) { this.tabSelection = 1; this.loader.redirectRelMem = false; }
        this.getSlots(event.row_data.SamplingStationId, event.row_data.TestId, event.row_data);
    }

    protected deletePrescription(obj: any) {
        this.messageService
            .confirm(
                "",
                this.translate.instant('view-prescription.delete-prescription-title')
            )
            .subscribe((actionResult: boolean) => {
                if (actionResult) {
                    this.spinner.show(true);
                    this.patientAppointmentService.deletePatientPrescription(obj.row_data.AppointmentId, obj.url.substring(obj.url.lastIndexOf('/'))).subscribe(() => {
                        this.messageService.success(this.translate.instant('view-prescription.prescription-delete-success'), 5000);
                        this.spinner.show(false);
                        //this.ngOnInit();

                        if (this.loader) {
                            this.loader.cancelForm();
                        }
                    },
                        (error: any) => {
                            this.messageService.warning(this.translate.instant(error['message']), 5000);
                            this.spinner.show(false);
                        });
                }
            });
    }

    cancelButtonClicked(event: any) {
        this.messageService
            .confirm(
                this.translate.instant(this.confirmDelContent),
                this.translate.instant(this.confirmDelTitle)
            )
            .subscribe((actionResult: boolean) => {
                if (actionResult) {
                    const patientArrivalData = {
                        appointment_id: event.row_data.AppointmentId,
                        test_id: event.row_data.TestId,
                        barCode: event.row_data.Barcode,
                        patientId: event.row_data.PatientID
                    };

                    this.patientAppointmentService.deletePatientAppointment(patientArrivalData).subscribe(data => {
                        if (data['message'] == 'appointment deleted successfully') {
                            this.messageService.success(this.translate.instant('edit-appointment.appointment-delete-success'), 5000);
                            this.refresh = true;
                            this.loader.refreshDialog.next(this.refresh);
                        } else {
                            this.messageService.warning(this.translate.instant(JSON.stringify(data)['message']), 5000);
                        }

                    },
                        (error: any) => {
                            this.messageService.warning(this.translate.instant(error['message']), 5000);
                        });
                }
            });

    }

    uploadPrecription(event: any) {
        this.replacePrescription = true;
        this.selectedappointmet = event;
        this.documents = new PrescriptionDocument();
        this.documents.PrescriptionParts = [];
    }

    viewPrecription(event: any) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ViewPrescriptionComponent);
        this.loader.addDynamicComponent(componentFactory, this.uploadPrescriptionSlider, event);
    }

    uploadPrecriptionMobile() {
        this.isPdfClosed();
        this.replacePrescription = true;
        this.documents = new PrescriptionDocument();
        this.documents.PrescriptionParts = [];

    }
    checkDate(element: any) {
        const st = new Date(element.SlotTime);
        if (st.getHours() > 0 || st.getMinutes() > 0) {
            return moment(element.SlotTime)
                .startOf('second')
                .isBefore(moment().startOf('second'));
        } else {
            return moment(element.SlotTime)
                .startOf('date')
                .isSameOrBefore(moment().startOf('date'));
        }
    }
    openPdf(path: any) {
        this.patientComments = path.row_data?.Comments
        this.selectedappointmet = path;
        this.spinner.show(true);
        const prescriptionName = path.url.substring(path.url.lastIndexOf('/') + 1);
        this.patientAppointmentService.getPrescription(prescriptionName).subscribe((res: any) => {
            const byteArray = new Uint8Array(atob(res).split('').map(char => char.charCodeAt(0)));
            this.replaceEnable = !this.checkDate(path.row_data);
            const file = new Blob([byteArray], { type: 'application/octet-stream' });
            this.pdfBlob = URL.createObjectURL(file);
            this.spinner.show(false);
            this.isPdfViewerOpened = true;
            document.querySelector('body').classList.add('hideScroll');
        });
    }

    isPdfClosed() {
        this.isPdfViewerOpened = false;
        document.querySelector('body').classList.remove('hideScroll');
    }

    protected dismiss() {
        this.loader.cancelForm();
    }

    protected goBack() {
        this.replacePrescription = false;
        this.loader.redirectRelMem = false;
    }

    protected getUpdateDocument(event: any) {
        this.documents = event;
        this.uploadStatus = false;
    }
    protected getClickImageStatus(event: any) {
        this.isClicked = event;
        this.uploadStatus = false;
    }
    protected fileUploaded() {
        if (this.documents.PrescriptionParts.length > 0) {
            this.spinner.show(true);
            this.patientAppointmentService.updateReplacePrecription(this.createReplaceObject()).subscribe(
                () => {
                    this.replacePrescription = false;
                    this.messageService.success(this.translate.instant('view-prescription.prescription-update-success'), 5000);
                    this.spinner.show(false);
                    this.ngOnInit();
                },
                () => {
                    this.replacePrescription = false;
                    this.messageService.alert(this.translate.instant('view-prescription.prescription-update-error'), 5000);
                    this.spinner.show(false);
                }
            );
        }
        else {
            this.uploadStatus = true;
            this.toastr.warning(this.translate.instant("upload-pic.no-prescription"));

        }
    }

    private createReplaceObject() {
        return {
            appointment_id: this.selectedappointmet.row_data.AppointmentId,
            azure_id: this.selectedappointmet.url,
            grey_scale: true,
            prescriptions_pages: this.documents.PrescriptionParts.map((s: any) => s.prescriptionPartData)
        };
    }
    updateRTotalCnt(event: any) {

        this.totalRCnt = event > 0 ? event : 0;
    }
    updateTotalCnt(event: any) {
        this.totalCnt = event > 0 ? event : 0;

    }
}
