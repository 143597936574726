import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NavNode } from '../models/nav-node.model';

@Injectable({
    providedIn: 'root'
})
/** Service for Global Repository */
export class GlobalRepositoryService {
    /* #region Fields */
    private environmentName: string;
    private shortNameOfApplication: string;
    private serverVersion: string;
    private labSiteName: string;
    private navigationNodes: NavNode[];
    private userPuid: string;
    private readonly subject = new Subject();
    private readonly authNodeSubject = new Subject();

    public dataCheckSubscriber$ = this.subject.asObservable();
    public authorizedNodesSubscriber$ = this.authNodeSubject.asObservable();
    /* #endregion */

    constructor() { }

    /* #region Public Properties */
    /** This property is being used to get application environment name */
    public get appEnvironmentName(): string {
        return this.environmentName;
    }

    /** This property is being used to get application short name */
    public get appShortName(): string {
        return this.shortNameOfApplication;
    }

    /** This property is being used to get application server version */
    public get appServerVersion(): string {
        return this.serverVersion;
    }

    /** This property is being used to get application labsite name */
    public get appLabSiteName(): string {
        return this.labSiteName;
    }

    /** This property is being used to get authorized nodes */
    public get authorizedNavNodes(): NavNode[] {
        return this.navigationNodes;
    }

    /** This property is being used to set authorized nodes */
    public set authorizedNavNodes(nodes: NavNode[]) {
        this.navigationNodes = nodes;
        this.authNodeSubject.next();
    }

    /** This property is being used to get user puid */
    public get puid(): string {
        return this.userPuid;
    }

    /** This property is being used to set user puid */
    public set puid(puid: string) {
        this.userPuid = puid;
    }
    /* #endregion */

    /* #region Public Methods */
    /** This method is being used to set application informations */
    public setApplicationInformations(envName: string, shortName: string, version: string, siteName: string): void {
        this.environmentName = envName;
        this.shortNameOfApplication = shortName;
        this.serverVersion = version;
        this.labSiteName = siteName;
        this.subject.next();
    }
    /* #endregion */
}
