import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { SpinnerService } from '../../../../core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../../shared';
import { MatButtonModule } from '@angular/material/button';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';


@Component({
  standalone: true,
  imports: [ImageCropperComponent, TranslateModule, SharedModule, MatButtonModule],
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent implements OnInit {
  aspectRatio: number;
  croppedBlob: Blob | null = null;
  croppedImage: SafeUrl = '';
  // aspectRatio: number;
  transform: ImageTransform = {};
  canvasRotation = 0;
  imageChangedEvent: any = ''
  scale = 1;
  rotation = 0;
  isPreviewMode: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<ImageCropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: SpinnerService,
    private sanitizer: DomSanitizer
  ) {
  }
  ngOnInit() {
    // setTimeout(() => {
    //   this.rotateRight();
    //   console.log('INIT right call')
    // })
    // this.delayFunc();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.spinner.show(false);
    this.croppedBlob = event.blob;
    this.spinner.show(false);
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
  }
  imageLoaded(dimensions: any) {
    this.aspectRatio = dimensions.original.size.width / dimensions.original.size.height
  }
  cropperReady() {
    this.spinner.show(false);
  }

  onSave(): void {
    if (this.croppedBlob) {
      const file = new File([this.croppedBlob], this.data.name, { type: this.croppedBlob.type });
      this.dialogRef.close(file);
    } else {
      this.dialogRef.close();
    }
  }
  onClose(): void {
    this.dialogRef.close();
  }
  rotateLeft() {
    this.spinner.show(true);
    setTimeout(() => {
      this.canvasRotation--;
      this.flipAfterRotate();
    })

  }

  rotateRight() {
    this.spinner.show(true);
    setTimeout(() => {
      this.canvasRotation++;
      this.flipAfterRotate();

      console.log('right')
    })
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }
  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }
  previewImage() {
    this.isPreviewMode = true;
  }
  backToImage() {
    this.isPreviewMode = false;
  }
  delayFunc() {
    setTimeout(() => {
      this.rotateLeft();
      console.log('funcdelay call')
    }, 3000);
  }
  // async executeTask() {
  //   const spinnerPromise = this.showSpinner();
  //   const taskPromise = this.runTask();
  //   await spinnerPromise;
  // }

}