import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LocaleService } from "../../../app.locale.service";
import { DrawerComponent } from "../../../core";
import { LoaderService } from "../../../main";
import { BookingAppointmentGuestService } from "../../services/booking-appointment-guest.service";
import { int } from "@zxing/library/esm/customTypings";

@Component({
  selector: "app-test-selection",
  templateUrl: "./test-selection.component.html",
  styleUrls: ["./test-selection.component.scss"],
})
export class TestSelectionComponent implements OnInit, DrawerComponent {
  @Input() allTestType: any[] = [];
  searchinput = "";
  @Input() pre_selected_lab_id: string;
  @Input() pre_selected_test_id: string;
  @Input() pre_selected_test_type_id: number;
  @Output() goBack = new EventEmitter<any>();

  filterObject: any;
  dataSource: any;
  enteredSearchValue = "";
  searchText = "";
  searchArray: Array<string>;
  filtered: any;
  length: any;
  testTypeName: any;
  testTypeSelection = false;
  @Input() testArray: any;
  @Input() selectTest: any[] = [];
  selectTestGender: any[] = [];
  public retrieveTest: string[] = [];
  lab_id = 0;
  noRecordFound = false;
  testType: any[] = [];
  testType1: any[] = [];
  characters: number;


  constructor(
    public bookingAppointmentService: BookingAppointmentGuestService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private localeService: LocaleService,
    public loader: LoaderService
  ) {
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
    this.lab_id = this.route.snapshot.params["l_id"];
    this.testType = new Array<any>();
  }
  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    if (window.innerWidth < 420) {
      this.characters = 32;
    } else this.characters = 47;
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.testArray.forEach(() => {
      this.testType = this.allTestType;
      this.testType = this.testType.map(test => { return { ...test, isOpen: false } });
    });
    if (this.pre_selected_test_id && this.testArray.length > 0) {
      let pre_selected_test = this.testArray?.find(
        (i: any) => i.external_code == this.pre_selected_test_id
      );
      let pre_selected_test_type = this.testType?.find(
        (i) => i.Id == pre_selected_test.test_type_id
      );
      this.onTestTypeSelected(pre_selected_test_type);
      this.selectedTest.emit(pre_selected_test);
    }
  }
  ngOnChanges(): void {
    this.selectTestGender = []
    this.getScreenSize();
    this.testArray.forEach(() => {
      this.testType = this.allTestType;
    });
    if (this.pre_selected_test_id && this.testArray.length > 0) {
      let pre_selected_test = this.testArray?.find(
        (i: any) => i.external_code == this.pre_selected_test_id
      );
      let pre_selected_test_type = this.testType?.find(
        (i) => i.Id == pre_selected_test.test_type_id
      );
      this.onTestTypeSelected(pre_selected_test_type);
      this.selectedTest.emit(pre_selected_test);
    }
  }
  sliderFormData: any;
  isSliderDataEdited?: boolean;

  @Output() selectedTest = new EventEmitter();

  onTestSelected(event: any) {

    this.selectedTest.emit(event);
  }

  onSelectEvent(value: any) {
    this.selectTest = [];
    this.testTypeName = this.loader.TranslateLang(value.Name);
    for (let i = 0; i < this.testArray.length; i++) {
      if (this.testArray[i]["test_type_id"] === value.Id) {
        this.selectTest.push(this.testArray[i]);
      }
    }
    this.selectTest = this.selectTest.map(test => { return { ...test, isOpen: false } });
    this.enteredSearchValue = "";
    this.retrieveTest = this.selectTest;
    this.filteredTests(this.enteredSearchValue, true);
  }

  filteredTests(value: any, testTypeSelected = false) {
    this.testTypeSelection = testTypeSelected;
    if (!this.testTypeSelection && !value) {
      this.clearSearch();
      return;
    }
    const searchTest: string[] = [];
    if (!this.testTypeSelection) {
      this.testTypeSelection = true;
      for (var i = 0; i < this.testArray.length; i++) {
        if (
          this.testArray[i]["short_name"]
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          searchTest.push(this.testArray[i]);
        }
      }
    } else {
      if (this.retrieveTest.length == 0) {
        for (var i = 0; i < this.testArray.length; i++) {
          if (
            this.testArray[i]["short_name"]
              .toLowerCase()
              .includes(value.toLowerCase())
          ) {
            searchTest.push(this.testArray[i]);
          }
        }
      } else {
        for (var i = 0; i < this.retrieveTest.length; i++) {
          if (
            this.retrieveTest[i]["short_name"]
              .toLowerCase()
              .startsWith(value.toLowerCase())
          ) {
            searchTest.push(this.retrieveTest[i]);
          }
        }
      }
    }
    this.selectTest = searchTest;

    if (searchTest.length == 0) {
      this.noRecordFound = true;
    } else {
      this.noRecordFound = false;
    }
    this.retrieveTest = this.testArray;
  }

  onError(index: any) {
    this.selectTest[index].url =
      "https://ecldevproductimage.blob.core.windows.net/productimage/20220927-3ccec799-af06-48b1-9d50-45381998d081.jpg";
  }

  onTestTypeError(index: any) {
    this.testType[index].ImageUrl =
      "https://ecldevproductimage.blob.core.windows.net/productimage/20220927-3ccec799-af06-48b1-9d50-45381998d081.jpg";
  }

  public onTestTypeSelected(t: any) {
    this.selectTestGender = []
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.testTypeSelection = true;
    this.onSelectEvent(t);
    this.selectTestGender = this.selectTest
  }

  public clearSearch() {
    this.testTypeSelection = false;
    this.enteredSearchValue = "";
    this.noRecordFound = false;
    this.selectTest = this.testArray;
  }

  openCurrent(index: number) {
    this.testType.forEach((element: any) => {
      element.isOpen = false;
    });
    this.testType[index].isOpen = true;
  }

  openCurrentTest(index: number) {
    this.selectTest.forEach((element: any) => {
      element.isOpen = false;
    });
    this.selectTest[index].isOpen = true;
  }

  goToBack() {
    this.goBack.emit(true);
  }

  filterByGender(gender: int) {
    // this.testType1 = this.testArray.filter(t => t.gender === gender)
    this.selectTestGender = []
    for (let i = 0; i < this.selectTest.length; i++) {
      if (this.selectTest[i]["gender"] != gender) {
        this.selectTestGender.push(this.selectTest[i])
      }
    }
  }
}
