<ng-container *ngIf="editMode">
    <div>
        <button class="close" mat-dialog-close mat-button id="btn_close_dialog">X</button>
    </div>
    <div class="header">
        <app-section-title title="{{title | translate}}"></app-section-title>
    </div>
</ng-container>
<form class="form-container" [formGroup]="UnavailabilityForm">
    <div class="field-wrapper">
        <mat-form-field>
            <mat-label class="label-float">{{'add-unavailability.slot-title'| translate}}</mat-label>
            <input matInput formControlName="SlotTitle" max="{{titleMaxLength}}" maxlength="{{titleMaxLength}}"
                name="slot_title" id="slot_title" />
        </mat-form-field>
        <mat-form-field class="full-width" def="samplingStationTests">
            <mat-label class="label-float">{{'add-unavailability.select-tests'| translate}}
            </mat-label>
            <mat-select formControlName="TestIds" multiple required>
                <app-select-check-all [model]="UnavailabilityForm.get('TestIds')" [values]="tests || []">
                </app-select-check-all>
                <mat-option *ngFor="let test of tests" [value]="test" matTooltip="{{test.short_name}}">
                    <span>{{test.short_name }} </span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <h4 class="label-float">{{'add-unavailability.slot-duration'| translate}}</h4>
    <div class="field-wrapper">
        <div class="date-container">
            <app-datepicker id="startDate" labelText="{{'add-unavailability.date'| translate}}" [dateInput]="StartDate"
                isRequired="true" [minDate]="fromMinDate" (selectedDate)="onDateChange()" isDisabled="true"
                isReadOnly="true">
            </app-datepicker>
            <div class="time-field-container">
                <mat-form-field class="timeInputField">
                    <mat-label>{{'add-unavailability.start-time'| translate}}</mat-label>
                    <input matInput type="time" [min]="selectedDow?.start_time" [max]="selectedDow?.end_time"
                        name="start_time" id="start_time" (blur)="validateStartTimeRange()" formControlName="StartTime"
                        required>
                </mat-form-field>
                <mat-form-field class="timeInputField">
                    <mat-label>{{'add-unavailability.end-time'| translate}}</mat-label>
                    <input matInput type="time" placeholder="{{'add-sampling.time-placeholder' | translate}}"
                        name="end_time" id="end_time" name="end_time" formControlName="EndTime" [min]="StartTime"
                        [max]="selectedDow?.end_time" (blur)="validateStartTimeRange()" required>
                </mat-form-field>
            </div>
        </div>
    </div>

    <mat-label class="note">{{'add-unavailability.note'| translate}}</mat-label>
    <mat-form-field>
        <mat-label>{{'add-unavailability.reason'| translate}}</mat-label>
        <textarea matInput cols="30" formControlName="Comment" rows="5"></textarea>
    </mat-form-field>


    <div class="actions">
        <button mat-button color="accent" class="ghost button_width" (click)="clearUnavailaibilityForm()" type="button"
            id="btn_clear">
            {{'admin.clear' | translate}}
        </button>
        <button mat-button color="accent" class="ghost button_width" type="submit" (click)="onSaveClick()"
            id="btn_save_unavailability">
            {{'admin.save' | translate}}
        </button>
    </div>
</form>