export class PasswordPolicy {
    public static INT_LENGTH_REQUIREMENT = 'password-policy.INT_LENGTH_REQUIREMENT';
    public static PRI_LENGTH_REQUIREMENT = 'password-policy.PRI_LENGTH_REQUIREMENT';
    public static NURSE_LENGTH_REQUIREMENT = 'password-policy.NURSE_LENGTH_REQUIREMENT';
    public static UPPERCASE_REQUIREMENT = 'password-policy.UPPERCASE_REQUIREMENT';
    public static LOWERCASE_REQUIREMENT = 'password-policy.LOWERCASE_REQUIREMENT';
    public static NUMERIC_REQUIREMENT = 'password-policy.NUMERIC_REQUIREMENT';
    public static SPECIAL_CHARACTER_REQUIREMENT =
        'password-policy.SPECIAL_CHARACTER_REQUIREMENT';
    public static CHARACTER_LIST_1 = '! @ # $ % ^ & * - _ + = [ ] { } |';
    public static CHARACTER_LIST_2 = ' \\ : ‘ , . ? / ` ~ “ ( ) ;';
}
