import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalRepositoryService, NavNode } from '../../../core';
import { LoaderService } from '../loader.service';
import { MessageService } from './../message.service';

@Injectable({
    providedIn: 'root'
})
/** Auth Guard Service */
export class GuardService implements CanActivateChild {

    /* #region Fields */
    private canUserAccessRoute: boolean;
    protected navigationDetails: any
    /* #endregion */

    constructor(private readonly globalRepositoryService: GlobalRepositoryService,
        private readonly router: Router,
        private readonly loader: LoaderService,
        private readonly messageService: MessageService,
        private readonly translateService: TranslateService,
        private readonly location: Location) {
        this.globalRepositoryService.authorizedNodesSubscriber$.subscribe(() => {
            this.validateUrl(document.location.hash.substring(1));
        });
    }
    
    /* #region Public Methods */
    /** This method is to check if slider is active before navigation to other page */
    public canActivateChild(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        if (this.loader.isSliderEdited()) {

            return this.messageService.confirm(this.translateService.instant('Common.GlobalMessages.UnSaveDataConfirmText'),
                this.translateService.instant('Common.GlobalMessages.UnSaveDataConfirmTitle'),
                this.translateService.instant('Common.Button.Yes'),
                this.translateService.instant('Common.Button.No')).toPromise().then(result => {
                if (result) {
                    console.log(this.router.getCurrentNavigation().trigger);
                    if (this.router.getCurrentNavigation().trigger === 'popstate') {
                        this.location.go(state.url);
                    }
                    this.loader.openSlider();
                    return false;
                } else {
                    this.loader.cancelForm();
                    return this.validateUrl(state.url);
                }
            });
        } else {

            return this.validateUrl(state.url);
        }
    }
    /* #endregion */

    /* #region Private Methods */
    /** Method to validate route URL's */
    private validateUrl(url: string): boolean {
        const navigationNodes: NavNode[] = this.globalRepositoryService.authorizedNavNodes;
        if (url !== '/' && navigationNodes && navigationNodes.length > 0) {
            this.canUserAccessRoute = false;
            this.checkExistanceOfAuthorizedNode(navigationNodes, url.substring(1).toLocaleLowerCase().trim());
            if (!this.canUserAccessRoute) {
                console.log(url);
                console.log(navigationNodes);
                this.loader.highlightLeftMenu(['/seed/error', '401']);
            }
            return this.canUserAccessRoute;
        }
        return true;
    }

    /** This method is being used to check the existance of user permitted node */
    private checkExistanceOfAuthorizedNode(navigationNodes: NavNode[], currentRouteURL: string): void {
        for (const node of navigationNodes) {
            if (node && node.Route && currentRouteURL.startsWith(node.Route.toLocaleLowerCase().trim().split(':')[0])) {
                this.canUserAccessRoute = true;
                break;
            }
            if (!this.canUserAccessRoute && node.Children && node.Children.length > 0) {
                this.checkExistanceOfAuthorizedNode(node.Children, currentRouteURL);
            }
        }
    }
    /* #endregion */

}
