import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../main';


@Component({
    selector: 'app-confirm-booking',
    templateUrl: './confirm-booking.component.html',
    styleUrls: ['./confirm-booking.component.scss']
})
export class ConfirmBookingComponent implements OnInit {
    appointmentInfo: any;
    constructor(private loader: LoaderService,) {
        this.appointmentInfo = JSON.parse(sessionStorage.getItem('a_data'));
    }
    redirectBooking() {
        this.loader.highlightLeftMenu(['patient/book-appointment']);
    }
    ngOnInit(): void {

    }

}
