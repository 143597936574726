<div class="upload-pic-container p-20" >
    <div class="header">
      <div>
        <app-section-title title="{{'notifications' | translate}}"></app-section-title>
      </div>
      <div class="slider-actions">
        <div (click)="markAllNotificationRead()" class="notifications-header-link">{{'mark-all-as-read' | translate}}</div>
        <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
          <span class="icon-ic-close"></span>
        </button>
      </div>
    </div>
    <div class="mobile-header">
        <div class="header-logo">
            <div class="toolbar-logo">
                <a class="toolbar-logo-margin" [routerLink]="">
                  <img src="./../../../../assets/images/logo-eclinical.svg" />
                </a>
            </div>
            <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
                <span class="icon-ic-close"></span>
            </button>
        </div>
        <div class="slider-actions">
          <app-section-title title="{{'notifications' | translate}}"></app-section-title>
          <div (click)="markAllNotificationRead()" class="notifications-header-link">{{'mark-all-as-read' | translate}}</div>
        </div>
    </div>
    <div class="notifications-border" [ngClass]="notification.IsRead ? '' : 'notiffication-background'" *ngFor="let notification of sliderFormData">
        <div class="notification-icon">
            <div class="icon-outer"><div class="icon-inner"><span>!</span></div></div>
        </div>
        <div class="notifications">
          <div class="notifications-header">
            <div class="notifications-header-text">{{notification.NotificationType.split('|')[0] + '.header-text' | translate }}</div>
            <div *ngIf="!notification.IsRead" (click) = "markNotificationRead(notification)" class="notifications-header-link">{{'mark-as-read' | translate}}</div>
          </div>
          <ng-template #notification_title>{{notification.NotificationType.split('|')[0] + '.title' | translate : { data: translate.instant(notification.NotificationType.split('|')[1]) } }}</ng-template>
          <div class="notifications-title" *ngIf="notification.NotificationType.split('|')[0] === 'PASSWORD_EXPIRE_NOTIFICATION' && (notification.NotificationType.split('|')[1] === '0' || notification.NotificationType.split('|')[1].substring(0,1) === '-') else notification_title" class="notifications-title">{{ 'password-expired' | translate }}</div>
          <div class="notifications-time">{{notification.CreateTime | date: 'dd/MM/yyyy | H:mm'}}</div>
          <div (click)="routeLink(notification)" class="notifications-link">{{notification.NotificationType.split('|')[0] + '.link' | translate }}</div>
        </div>
    </div>
</div>
<div class="no-notifications" *ngIf="sliderFormData.length === 0">
    <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
    <div class="desc">
        <p>{{'no-notifications'|translate}}</p>
    </div>
</div>
