import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyInfo, CompanyInfoWrapper, RequestData } from '../landing-page-config.model';
import { MessageService } from '../../../../main';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../../../../core';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent implements OnInit {

  @Input() companyInfo: CompanyInfoWrapper;
  @Output() cancelClicked = new EventEmitter();
  @Output() updateData = new EventEmitter<RequestData>();
  public type1MaxLength: number = 80;
  public type2MaxLength: number = 80;
  public type3MaxLength: number = 10;
  public selectedLangTabIndex: number = 0;

  public enForm: FormGroup = this.fb.group({
    section_title: ['', [Validators.required, Validators.maxLength(this.type1MaxLength)]],
    field_one_name: ['', [Validators.required, Validators.maxLength(this.type2MaxLength)]],
    field_two_name: ['', [Validators.required, Validators.maxLength(this.type2MaxLength)]],
    field_one_value: ['', [Validators.required, Validators.maxLength(this.type3MaxLength)]],
    field_two_value: ['', [Validators.required, Validators.maxLength(this.type3MaxLength)]],
    field_three_name: ['', [Validators.required, Validators.maxLength(this.type2MaxLength)]],
    field_three_value: ['', [Validators.required, Validators.maxLength(this.type3MaxLength)]],
    website_link_field_name: ['', [Validators.required, Validators.maxLength(this.type2MaxLength)]],
    website_link_field_value: ['', [Validators.required, Validators.maxLength(this.type2MaxLength), Validators.pattern(/^(http:\/\/|https:\/\/)/)]]
  });
  public frForm: FormGroup = this.fb.group({
    section_title: ['', [Validators.required, Validators.maxLength(this.type1MaxLength)]],
    field_one_name: ['', [Validators.required, Validators.maxLength(this.type2MaxLength)]],
    field_two_name: ['', [Validators.required, Validators.maxLength(this.type2MaxLength)]],
    field_one_value: ['', [Validators.required, Validators.maxLength(this.type3MaxLength)]],
    field_two_value: ['', [Validators.required, Validators.maxLength(this.type3MaxLength)]],
    field_three_name: ['', [Validators.required, Validators.maxLength(this.type2MaxLength)]],
    field_three_value: ['', [Validators.required, Validators.maxLength(this.type3MaxLength)]],
    website_link_field_name: ['', [Validators.required, Validators.maxLength(this.type2MaxLength)]],
    website_link_field_value: ['', [Validators.required, Validators.maxLength(this.type2MaxLength), Validators.pattern(/^(http:\/\/|https:\/\/)/)]]
  });

  constructor(private fb: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private spinner: SpinnerService) { }

  ngOnInit(): void {
    this.bindData();
  }

  ngOnChanges() {
    this.bindData();
  }

  bindData() {
    const enFormData: CompanyInfo = {
      section_title: this.companyInfo.en.section_title,
      field_one_name: this.companyInfo.en.field_one_name,
      field_one_value: this.companyInfo.en.field_one_value,
      field_two_name: this.companyInfo.en.field_two_name,
      field_two_value: this.companyInfo.en.field_two_value,
      field_three_name: this.companyInfo.en.field_three_name,
      field_three_value: this.companyInfo.en.field_three_value,
      website_link_field_name: this.companyInfo.en.website_link_field_name,
      website_link_field_value: this.companyInfo.en.website_link_field_value
    };

    this.enForm.patchValue(enFormData);

    const frFormData: CompanyInfo = {
      section_title: this.companyInfo.fr.section_title,
      field_one_name: this.companyInfo.fr.field_one_name,
      field_one_value: this.companyInfo.fr.field_one_value,
      field_two_name: this.companyInfo.fr.field_two_name,
      field_two_value: this.companyInfo.fr.field_two_value,
      field_three_name: this.companyInfo.fr.field_three_name,
      field_three_value: this.companyInfo.fr.field_three_value,
      website_link_field_name: this.companyInfo.fr.website_link_field_name,
      website_link_field_value: this.companyInfo.fr.website_link_field_value
    };

    this.frForm.patchValue(frFormData);
  }

  save() {
    this.enForm.markAllAsTouched();
    this.frForm.markAllAsTouched();
    if (this.enForm.valid && this.frForm.valid) {
      this.messageService
        .confirm(
          this.translateService.instant('landing-page-config.save-confirm-description'),
          this.translateService.instant('landing-page-config.save-title'),
          this.translateService.instant('landing-page-config.yes'),
          this.translateService.instant('landing-page-config.no')
        )
        .subscribe((actionResult) => {
          if (actionResult) {
            this.spinner.show(true);
            let payload = {
              "tab_name": "companyinfo",
              "page_data": JSON.stringify({
                "en": this.enForm.value,
                "fr": this.frForm.value
              }),
              "image_data": [
                {
                  "image_url": "",
                  "image_data": ""
                }
              ]
            }
            this.updateData.emit(payload);
          }
        });
    }
    else {
      if (this.selectedLangTabIndex == 0 && this.enForm.valid && !this.frForm.valid) {
        this.selectedLangTabIndex = 1;
      }
      else if (this.selectedLangTabIndex == 1 && !this.enForm.valid && this.frForm.valid) {
        this.selectedLangTabIndex = 0;
      }
    }
  }

  public onLangTabChange(event: any) {
    this.selectedLangTabIndex = event.index;
  }

  syncWithFrValue() {
    this.frForm.controls["field_one_value"].setValue(this.enForm.controls['field_one_value'].value);
    this.frForm.controls["field_two_value"].setValue(this.enForm.controls['field_two_value'].value);
    this.frForm.controls["field_three_value"].setValue(this.enForm.controls['field_three_value'].value);
  }

  syncWithEnValue() {
    this.enForm.controls["field_one_value"].setValue(this.frForm.controls['field_one_value'].value);
    this.enForm.controls["field_two_value"].setValue(this.frForm.controls['field_two_value'].value);
    this.enForm.controls["field_three_value"].setValue(this.frForm.controls['field_three_value'].value);
  }
}
