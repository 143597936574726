export class PrescriptionDocument {
    public Id: number;
    public PrescriptionParts: PrescriptionPart[] = [];
    public IsInvalidFileSize: boolean;
    public IsIncorrectFileFormat: boolean;
    public Expanded: boolean;
    public OrgFiles?: string[];
}

export class PrescriptionPart {
    prescriptionPartData: string | ArrayBuffer;
    PrescriptionPartName: string;
    prescriptionPartUrl: string;
    SizeInBytes: number;
    type: string;
}
