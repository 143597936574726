import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { FileData } from "../../../../features/styles/models/file-data";
import { LoginServiceService } from "../../../services/login-service.service";
import { PatientService } from "../../../services/patient.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LoaderService, MessageService } from "../../../../main";
import { TranslateService } from "@ngx-translate/core";
import { PrescriptionDocument } from "../../../shared/appointment-prescription/appointment-prescription.model";

import { DatePipe } from "@angular/common";
@Component({
  selector: "app-document-management",
  templateUrl: "./document-management.component.html",
  styleUrls: ["./document-management.component.scss"],
})
export class DocumentManagementComponent implements OnInit {
  public nationality: any;
  public IdentityFile = new FormControl("");
  public BirthFile = new FormControl("");
  public InsuranceFile = new FormControl("");
  public minorPassport = new FormControl<number | null>(2);
  public insurance = new FormControl("");
  public insuranceExpiry = new FormControl("");
  public identityExpiry = new FormControl("");
  value: string;
  step1 = true;
  maxDate: Date;
  documentType: any[];
  public minor: any;
  patientData: any;
  login_id: any;
  public isUpdate: boolean;
  public identity: FileData = null;
  private birthCertificate: FileData = null;
  private insuranceDoc: FileData = null;
  public isInvalidFileSize: boolean;
  public isHovering: boolean;
  public isEmailNotificationRequired: boolean;
  public isSaveClicked: boolean;
  public identityData: string | ArrayBuffer;
  public birthData: string | ArrayBuffer;
  public insuranceData: string | ArrayBuffer;
  choices = [
    { Id: 1, KeyName: "Yes" },
    { Id: 2, KeyName: "No" },
  ];

  public docForm = this.formBuilder.group({
    insuranceDoc: this.insuranceDoc,
    IdentityFile: this.IdentityFile,
    BirthFile: this.BirthFile,
    InsuranceFile: this.InsuranceFile,
    birthCertificate: this.birthCertificate,
    identity: this.identity,
    identityExpiry: this.identityExpiry,
    insuranceExpiry: this.insuranceExpiry,
    insurance: this.insurance,
  });
  protected identityDocuments: PrescriptionDocument = {
    Id: 1,
    PrescriptionParts: [],
    IsIncorrectFileFormat: false,
    IsInvalidFileSize: false,
    Expanded: true,
  };
  protected birthDocuments: PrescriptionDocument = {
    Id: 2,
    PrescriptionParts: [],
    IsIncorrectFileFormat: false,
    IsInvalidFileSize: false,
    Expanded: true,
  };
  protected insuranceDocuments: PrescriptionDocument = {
    Id: 3,
    PrescriptionParts: [],
    IsIncorrectFileFormat: false,
    IsInvalidFileSize: false,
    Expanded: true,
  };
  protected identityItem = {
    Id: 1,
    FrontFileName: "",
    BackFileName: "",
    IsIncorrectFrontFileFormat: false,
    IsInvalidFrontFileSize: false,
    IsIncorrectBackFileFormat: false,
    IsInvalidBackFileSize: false,
    Expanded: true,
  };
  protected birthItem = {
    Id: 2,
    FrontSideData: "",
    BackSideData: "",
    FrontFileName: "",
    BackFileName: "",
    IsIncorrectFrontFileFormat: false,
    IsInvalidFrontFileSize: false,
    IsIncorrectBackFileFormat: false,
    IsInvalidBackFileSize: false,
    Expanded: true,
  };
  protected insuranceItem = {
    Id: 3,
    FrontSideData: "",
    BackSideData: "",
    FrontFileName: "",
    BackFileName: "",
    IsIncorrectFrontFileFormat: false,
    IsInvalidFrontFileSize: false,
    IsIncorrectBackFileFormat: false,
    IsInvalidBackFileSize: false,
    Expanded: true,
  };
  constructor(
    private formBuilder: FormBuilder,
    public loginService: LoginServiceService,
    private route: ActivatedRoute,
    private patientService: PatientService,
    public loader: LoaderService,
    private messageService: MessageService,
    private translate: TranslateService,
    public router: Router
  ) {
    this.maxDate = new Date();
    this.documentType = new Array<any>();
  }

  ngOnInit(): void {
    this.getDocumentType();
    if (this.route.snapshot.params.action == "3Dbq431") {
      this.minor = false;
      this.nationality = 1;
    } else if (this.route.snapshot.params.action == "3Dq7h3o") {
      this.minor = true;
      this.nationality = 1;
    } else this.nationality = 2;
    this.route.snapshot.params;
    this.docForm.controls.insurance.setValue("2");
  }

  public getDocumentType() {
    this.loginService.getDocType().subscribe((data: Array<any>) => {
      this.documentType = data.sort((a, b) => a.KeyOrder - b.KeyOrder);
      console.log(this.documentType);
    });
  }

  public getPatientId(event: any) {
    console.log(event);
  }
  public back() {
    this.router.navigate(["patient/set-password"]);
    // this.insurance.removeValidators(Validators.required);
    // this.identityExpiry.clearValidators();
    // this.insuranceExpiry.removeValidators(Validators.required);
    // this.identityExpiry.removeValidators(Validators.required)
  }

  public proceed() {
    this.minor = true; // testing
    this.step1 = false;
    this.insurance.addValidators(Validators.required);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  public clearForm() {
    this.identityExpiry.removeValidators(Validators.required);
    this.insuranceExpiry.removeValidators(Validators.required);
    this.removeIdentityFile();
    this.removeBirthFile();
    this.removeInsuranceFile();
    this.insurance.setValue("2");
    this.minorPassport = null;
  }
  public removeIdentityFile(): void {
    if (this.identityDocuments.PrescriptionParts.length == 0) {
      this.identity = null;
      this.identityExpiry.setValue("");
      this.docForm.controls.IdentityFile.setValue("");
    }
  }
  public removeBirthFile(): void {
    if (this.birthDocuments.PrescriptionParts.length == 0) {
      this.birthCertificate = null;
      this.docForm.controls.BirthFile.setValue("");
    }

  }
  public removeInsuranceFile(): void {
    if (this.insuranceDocuments.PrescriptionParts.length == 0) {
      this.insuranceDoc = null;
      this.insuranceExpiry.setValue("");
      this.docForm.controls.InsuranceFile.setValue("");
    }
  }
  public calculateQueality(size: any) {
    if (size < 1) {
      return 75;
    } else if (size >= 1 && size < 2) {
      return 50;
    } else {
      return 22;
    }
  }

  public dropzoneState(event: any) {
    this.isHovering = event;
  }

  protected getUpdateIdentityDocument(event: any) {
    this.identityDocuments = event;
    this.docForm.controls.IdentityFile.setValue("");
    this.docForm.controls.IdentityFile.markAllAsTouched();
    if (this.identityDocuments) {
      this.docForm.controls.IdentityFile.setValue("Identity-Captured");
      this.docForm.controls.IdentityFile.markAsDirty();
    }
  }

  protected getUpdateBirthDocument(event: any) {
    this.birthDocuments = event;
    this.docForm.controls.BirthFile.setValue("");
    this.docForm.controls.BirthFile.markAllAsTouched();
    if (this.birthDocuments) {
      this.docForm.controls.BirthFile.setValue("Birth-Captured");
      this.docForm.controls.BirthFile.markAsDirty();
    }
  }

  protected getUpdateInsuranceDocument(event: any) {
    this.insuranceDocuments = event;
    this.docForm.controls.InsuranceFile.setValue("");
    this.docForm.controls.InsuranceFile.markAllAsTouched();
    if (this.insuranceDocuments) {
      this.docForm.controls.InsuranceFile.setValue("Identity-Captured");
      this.docForm.controls.InsuranceFile.markAsDirty();
    }
  }

  identityObject() {
    let doc: any;
    if (this.identityDocuments.OrgFiles?.length == 1 && this.identityDocuments.OrgFiles[0] != "") {
      doc = {
        prescriptions_pages: this.identityDocuments.OrgFiles.map(
          (s: any) => s
        ),
      };
    } else if (this.identityDocuments.PrescriptionParts.length > 0) {
      doc = {
        prescriptions_pages: this.identityDocuments.PrescriptionParts.map(
          (s: any) => s.prescriptionPartData
        ),
      };
      //  docs.push(doc);
    }
    const datePipe = new DatePipe("en-US");
    return {
      document_type: this.documentType.find(
        (t) => t.KeyName == "Identity card / Passport"
      ).Id,
      description: "",
      details: "",
      expiry_date: datePipe.transform(this.identityExpiry.value, "yyyy-MM-dd"),
      document_name: this.identityDocuments.PrescriptionParts[0].PrescriptionPartName,
      patient_id: this.route.snapshot.params.id,
      document_data: doc,
    };
  }
  birthObject() {
    let doc: any;
    if (this.identityDocuments.OrgFiles?.length == 1 && this.identityDocuments.OrgFiles[0] != "") {
      doc = {
        prescriptions_pages: this.identityDocuments.OrgFiles.map(
          (s: any) => s
        ),
      };
    } else if (this.identityDocuments.PrescriptionParts.length > 0) {
      doc = {
        prescriptions_pages: this.identityDocuments.PrescriptionParts.map(
          (s: any) => s.prescriptionPartData
        ),
      };
    }
    return {
      document_type: this.documentType.find(
        (t) => t.KeyName == "Birth Certificate"
      ).Id,
      description: "",
      details: "",
      expiry_date: "0001-01-01",
      document_name: this.identityDocuments.PrescriptionParts[0].PrescriptionPartName,
      patient_id: this.route.snapshot.params.id,
      document_data: doc,
    };
  }
  insuranceObject() {
    let doc: any;
    if (this.identityDocuments.OrgFiles?.length == 1 && this.identityDocuments.OrgFiles[0] != "") {
      doc = {
        prescriptions_pages: this.identityDocuments.OrgFiles.map(
          (s: any) => s
        ),
      };
    } else if (this.identityDocuments.PrescriptionParts.length > 0) {
      doc = {
        prescriptions_pages: this.identityDocuments.PrescriptionParts.map(
          (s: any) => s.prescriptionPartData
        ),
      };
    }
    const datePipe = new DatePipe("en-US");
    return {
      document_type: this.documentType.find(
        (t) => t.KeyName == "Mutual certificate"
      ).Id,
      description: "",
      details: "",
      expiry_date: datePipe.transform(this.insuranceExpiry.value, "yyyy-MM-dd"),
      document_name: this.identityDocuments.PrescriptionParts[0].PrescriptionPartName,
      patient_id: this.route.snapshot.params.id,
      document_data: doc,
    };
  }
  public SaveDocuments() {
    const docs: {
      document_type: any;
      description: string;
      details: string;
      expiry_date: string;
      document_name: string;
      document_data: any;
    }[] = [];
    if (this.IdentityFile.value != "") {
      docs.push(this.identityObject());
    }
    if (this.BirthFile.value != "" && this.minor) {
      docs.push(this.birthObject());
    }
    if (this.InsuranceFile.value != "") {
      docs.push(this.insuranceObject());
    }
    this.patientService.postuploadDocuments(docs).subscribe(
      (data: any) => {
        console.log(data);
        this.messageService.success(
          this.translate.instant("login.password-set"),
          5000
        );
        this.messageService.confirm(
          "createAccount.alertText",
          "createAccount.alerTitle"
        );
        this.router.navigate(["/"]);
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
}
