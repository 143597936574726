import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    Inject,
    Input,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatFormField } from '@angular/material/form-field';
// import { Test } from './Test';
// import { CompanyService } from '../service/company.service';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
import { DrawerComponent, SpinnerService } from '../../../core';
import { MessageService } from '../../../main';
import { TestService } from '../../services/add-test-service.service';
import { AddTestComponent } from '../add-test/add-test.component';
import { CommonApiService } from '../../services/common-api.service';
@Component({
  selector: 'app-add-test-type',
  templateUrl: './add-test-type.component.html',
  styleUrls: ['./add-test-type.component.scss']
})
export class AddTestTypeComponent implements OnInit,DrawerComponent  {
  @Input() sliderFormData: any;
  @Input() public isSliderDataEdited = false;
  Tests: any;
  // ShortName = new FormControl('', [Validators.required, Validators.pattern('^.{1,50}$'), Validators.pattern('^[a-zA-Z0-9 ]+$')]);
  // LongName = new FormControl('', [Validators.required, Validators.pattern('^.{1,50}$'), Validators.pattern('^[a-zA-Z0-9 ]+$')]);
  // Description = new FormControl('', [Validators.pattern('^.{1,1000}$'), Validators.pattern('^[a-zA-Z0-9 ]+$')]);
  // Status = new FormControl(true, [Validators.required]);
  // Type = new FormControl(0, Validators.required);
  // ExternalId = new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+$'), Validators.pattern('^.{1,15}$')]);
  // Appointment = new FormControl(false,);
  // Prescription = new FormControl(false,);
  testForm: FormGroup;

  title: string;
  isInvalidFileSize: boolean;           
  isIncorrectFileFormat: boolean;
  fileName: string;
  imageUrl: string | ArrayBuffer = '';
  saveData: {};
  adminRole: boolean;
  testTypes: any[] = null;
  deleteTitle = 'admin.confirm-delete';
  deleteconfirmtext = 'admin.delete-confirm-text';
  savealert = {
      edit: 'admin.save-alert.edit',
      add: 'admin.save-alert.add',
      delete: 'admin.save-alert.delete'
  };
  errorMessage = 'admin.error-message';

  constructor(
      private readonly dialogRef: MatDialogRef<AddTestComponent>,

      @Inject(MAT_DIALOG_DATA) public data: any,

      public testService: TestService,
      private formBuilder: FormBuilder,
      private readonly spinner: SpinnerService,
      private readonly messageService: MessageService,
      private translate: TranslateService,
      private localeService: LocaleService,
      public commonService: CommonApiService,
  ) {
      this.title =
    this.data.action == 'edit' ? 'add-test-type.edit-test-type' : 'add-test-type.add-test-type';
      this.savealert = this.data.savealert;
      this.errorMessage = this.data.errMsg;
      this.testTypes = this.data.testtype;
      this.isIncorrectFileFormat = false;
      this.isInvalidFileSize = false;
      this.fileName = '';
      this.initializetesttForm();
      this.translate.setDefaultLang(this.localeService.localeLang);
      this.translate.use(this.localeService.localeLang);
  }
  initializetesttForm() {
      this.testForm = this.formBuilder.group({
          Id: new FormControl(0),
          Name: new FormControl(null, [
              Validators.required,
              Validators.pattern('^.{1,100}$')
          ]),
          NameFrench: new FormControl(null, [
              Validators.required,
              Validators.pattern('^.{1,100}$')
          ]),
          Description: new FormControl(null, [Validators.maxLength(1000)]),
          DescriptionFrench: new FormControl(null, [Validators.maxLength(1000)]),
          Status: new FormControl(true, [Validators.required]),
          FileName: new FormControl('', [
              Validators.pattern('^.*.(jpg|JPG|jpeg|JPEG|png|PNG|svg|SVG)$')
          ])
      });
  }

  ngOnInit(): void {
    console.log(this.data)
      this.testTypes = this.data.testtype;
      this.adminRole = JSON.parse(sessionStorage.getItem('access_token'))['role'] == 5;
      if (this.data.obj != null && this.testTypes != null) {
          this.bindtestObject(this.data.obj);
      }
  }

  @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

  ngAfterViewInit(): void {
      setTimeout(
          () => this.formFields.forEach((ff) => ff.updateOutlineGap()),
          100
      );
  }

  renderTest() {
  // this.companyService.getTestTypes();
  }

  back() {
      this.dialogRef.close(null);
  }
  updateTestObject() {
    const name ={
        "en":this.testForm.controls.Name.value,
        "fr":this.testForm.controls.NameFrench.value
    } 
    const description = {
       "en":this.testForm.controls.Description.value,
        "fr":this.testForm.controls.DescriptionFrench.value,
    }
      return {
          id: this.testForm.controls.Id.value,
          name:name,
          description: description,
          is_active: this.testForm.controls.Status.value,
          file_name: this.fileName,
          url: this.imageUrl
      };
  }

  bindtestObject(testdetail: any) {
      this.fileName = testdetail.FileName;
      this.testForm.patchValue({
          Id: testdetail?.Id,
          Name: JSON.parse(testdetail?.Name)['en'],
          NameFrench: JSON.parse(testdetail?.Name)['fr'],
          Description: JSON.parse(testdetail?.Description)['en'],
          DescriptionFrench: JSON.parse(testdetail?.Description)['fr'],
          Status: testdetail?.IsActive,
          FileName: testdetail?.FileName
      });
  }
  saveTest() {
  //   if(!this.testForm.valid){
  //     this.dialog.open(PopupWindowComponent, {
  //     width: '500px',
  //     height: '340px',
  //     data: {header:"Error!",body:"Fill the required details"},
  //     autoFocus: false,
  //     disableClose: true

      // });
      // }
      this.testForm.controls.FileName.setValue(this.fileName);
      if (this.testForm.valid && this.fileName !== ''&& this.fileName!==null) {
          // this.loader.show();

          const test = this.updateTestObject();
          this.spinner.show(true);

          this.testService.postAdminTestTypesCreate(test).subscribe(
              () => {
                  this.spinner.show(false);
                  this.messageService.success(
                      this.translate.instant(this.savealert[this.data.action])
                  );
                  this.dialogRef.close(null);
              },
              (errorResponse: HttpErrorResponse) => {
                  this.spinner.show(false);
                  if (errorResponse.error.code == 423) {
                      this.testForm.controls.ExternalId.setErrors([{ existingId: true }]);
                  } else {
                      this.messageService.alert(
                          this.translate.instant(this.errorMessage)
                      );
                      console.log(errorResponse);
                      this.dialogRef.close(null);
                      // if (errorResponse.status !== 400) {
                      //   this.notification.warn(this.ValidationMessages.GENERAL_FAILURE);
                      // }
                  }
              }
          );
      } else if (this.fileName === '') {
          // this.isInvalidFileSize = true;
          // this.isIncorrectFileFormat = true;
      }
  }

SaveButton(){
if(this.data.action=='edit'){
    this.UpdateTestType();
    //this.GetTestType();
}

else {
    this.saveTest();
    //this.GetTestType();
}
}
GetTestType(){
    this.commonService.GetTestType().subscribe((testypes) => {
        this.testTypes = testypes;
    });
}
  UpdateTestType(){
    const test = this.updateTestObject();
    if (this.testForm.valid && this.fileName !== ''){
     this.testService.putAdminTestTypesUpdate(test).subscribe(()=>{
        this.spinner.show(false);
        this.messageService.success(
            this.translate.instant(this.savealert[this.data.action])
        );
        this.dialogRef.close(null);
     },
     (errorResponse: HttpErrorResponse) => {
        this.spinner.show(false);
        if (errorResponse.error.code == 423) {
            this.testForm.controls.ExternalId.setErrors([{ existingId: true }]);
        } else {
            this.messageService.alert(
                this.translate.instant(this.errorMessage)
            );
            console.log(errorResponse);
            this.dialogRef.close(null);
            // if (errorResponse.status !== 400) {
            //   this.notification.warn(this.ValidationMessages.GENERAL_FAILURE);
            // }
        }
    })
}

  }
  clear() {
      this.testForm.controls.Name.setValue(null);
      this.testForm.controls.NameFrench.setValue(null);
      this.testForm.controls.Description.setValue(null);
      this.testForm.controls.DescriptionFrench.setValue(null);
      this.testForm.controls.Status.setValue(true);
      this.fileName = '';
      this.testForm.controls.FileName.setValue(null);
      this.testForm.markAsUntouched();
      this.testForm.controls.refreshState;
  }

  public dismiss() {
      this.dialogRef.close();
  }

  selectCountry() {}

  isValidImage(file: any): boolean {
      let isImageValid = true;
      if (file.size == 0 || file.size > 500 * 1024) {
          this.isInvalidFileSize = true;
          isImageValid = false;
      }
      if (
          file.type != 'image/png' &&
    file.type != 'image/jpg' &&
    file.type != 'image/jpeg' &&
    file.type != 'image/svg+xml'
      ) {
          this.isIncorrectFileFormat = true;
          isImageValid = false;
      }

      if (
          !file.name.toLowerCase().includes('.png') &&
    !file.name.toLowerCase().includes('.jpg') &&
    !file.name.toLowerCase().includes('.jpeg') &&
    !file.name.toLowerCase().includes('.svg')
      ) {
          this.isIncorrectFileFormat = true;
          isImageValid = false;
      }

      return isImageValid;
  }
  inputChange(event: any) {
      this.isInvalidFileSize = false;
      this.isIncorrectFileFormat = false;

      const reader = new FileReader();
      const file = event.target.files[0];
      if (file) {
          this.fileName = file.name;
          if (!this.isValidImage(file)) {
              this.testForm.controls.FileName.setValue(null);
              this.imageUrl = '';
          } else {
              this.isIncorrectFileFormat = false;
              reader.readAsDataURL(file);
              this.testForm.controls.FileName.setValue(this.fileName);
              reader.onload = () => {
                  const s = reader.result.toString().split(',');
                  this.imageUrl = s[1];
              };
          }
      }
  }
}

