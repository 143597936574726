<div class="hero-banner-container">
    <div class="d-flex flex-column">
        <header class="pull-left">
            <h5 class="pull-left"> {{'company-info.heading'|translate}}</h5>
        </header>
        <div class="select-lang-title">
            {{'landing-page-config.select-lang'|translate}}
        </div>
        <mat-tab-group [(selectedIndex)]="selectedLangTabIndex" (selectedTabChange)="onLangTabChange($event)"
            class="languageTab mt-10 pull-left" animationDuration="1000ms">
            <mat-tab label="{{'landing-page-config.lang1'|translate}}">
                <form [formGroup]="enForm">
                    <div class="heroBannerWrapper">
                        <div class="field-wrapper">
                            <mat-form-field>
                                <mat-label class="label-float">{{'company-info.section_title' | translate}}</mat-label>
                                <input matInput formControlName="section_title" name="enSectionTitle"
                                    id="enSectionTitle" max="{{type1MaxLength}}" maxlength="{{type1MaxLength}}" />
                            </mat-form-field>
                            <div class="character-count">
                                {{enForm.get('section_title')?.value?.length}}/{{type1MaxLength}}
                            </div>
                        </div>
                        <div> </div>
                        <div class="section-title">
                            {{'company-info.field1'|translate}}
                        </div>
                        <div> </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-ic-location"></span></div>
                                <mat-form-field>
                                    <mat-label class="label-float">{{'company-info.field_name' |
                                        translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField1Name" id="enField1Name"
                                        formControlName="field_one_name" rows="2"
                                        [maxlength]="type2MaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{enForm.get('field_one_name')?.value?.length}}/{{type2MaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <mat-form-field>
                                    <mat-label>{{'company-info.field_value' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField1Value" id="enField1Value"
                                        formControlName="field_one_value" rows="2" [maxlength]="type3MaxLength"
                                        (blur)="syncWithFrValue()"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{enForm.get('field_one_value')?.value?.length}}/{{type3MaxLength}}
                            </div>
                        </div>
                        <div class="section-title">
                            {{'company-info.field2'|translate}}
                        </div>
                        <div> </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-ic-employee"></span></div>
                                <mat-form-field>
                                    <mat-label class="label-float">{{'company-info.field_name' |
                                        translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField2Name" id="enField2Name"
                                        formControlName="field_two_name" rows="2"
                                        [maxlength]="type2MaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{enForm.get('field_two_name')?.value?.length}}/{{type2MaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <mat-form-field>
                                    <mat-label>{{'company-info.field_value' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField2Value" id="enField2Value"
                                        formControlName="field_two_value" rows="2" [maxlength]="type3MaxLength"
                                        (blur)="syncWithFrValue()"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{enForm.get('field_two_value')?.value?.length}}/{{type3MaxLength}}
                            </div>
                        </div>
                        <div class="section-title">
                            {{'company-info.field3'|translate}}
                        </div>
                        <div> </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-ic-gender"></span></div>
                                <mat-form-field>
                                    <mat-label class="label-float">{{'company-info.field_name' |
                                        translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField3Name" id="enField3Name"
                                        formControlName="field_three_name" rows="2"
                                        [maxlength]="type2MaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{enForm.get('field_three_name')?.value?.length}}/{{type2MaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <mat-form-field>
                                    <mat-label>{{'company-info.field_value' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField3Value" id="enField3Value"
                                        formControlName="field_three_value" rows="2" (blur)="syncWithFrValue()"
                                        [maxlength]="type3MaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{enForm.get('field_three_value')?.value?.length}}/{{type3MaxLength}}
                            </div>
                        </div>
                        <div class="section-title">
                            {{'company-info.website_field'|translate}}
                        </div>
                        <div> </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <!-- <div class="iconBackground"><span class="icon-icon-my-appointment"></span></div> -->
                                <mat-form-field>
                                    <mat-label class="label-float">{{'company-info.field_name' |
                                        translate}}</mat-label>
                                    <textarea matInput cols="30" name="enWebsiteLinkName" id="enWebsiteLinkName"
                                        formControlName="website_link_field_name" rows="2"
                                        [maxlength]="type2MaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{enForm.get('website_link_field_name')?.value?.length}}/{{type2MaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <mat-form-field>
                                    <mat-label>{{'company-info.field_value' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="enWebsiteLinkValue" id="enWebsiteLinkValue"
                                        (blur)="syncWithFrValue()" formControlName="website_link_field_value" rows="2"
                                        [maxlength]="type2MaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{enForm.get('website_link_field_value')?.value?.length}}/{{type2MaxLength}}
                            </div>
                        </div>

                    </div>
                </form>
            </mat-tab>
            <mat-tab label="{{'landing-page-config.lang2'|translate}}">
                <form [formGroup]="frForm">
                    <div class="heroBannerWrapper">
                        <div class="field-wrapper">
                            <mat-form-field>
                                <mat-label class="label-float">{{'company-info.section_title' | translate}}</mat-label>
                                <input matInput formControlName="section_title" name="enSectionTitle"
                                    id="enSectionTitle" max="{{type1MaxLength}}" maxlength="{{type1MaxLength}}" />
                            </mat-form-field>
                            <div class="character-count">
                                {{frForm.get('section_title')?.value?.length}}/{{type1MaxLength}}
                            </div>
                        </div>
                        <div> </div>
                        <div class="section-title">
                            {{'company-info.field1'|translate}}
                        </div>
                        <div> </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-icon-my-appointment"></span></div>
                                <mat-form-field>
                                    <mat-label class="label-float">{{'company-info.field_name' |
                                        translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField1Name" id="enField1Name"
                                        formControlName="field_one_name" rows="2"
                                        [maxlength]="type2MaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{frForm.get('field_one_name')?.value?.length}}/{{type2MaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <mat-form-field>
                                    <mat-label>{{'company-info.field_value' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField1Value" id="enField1Value"
                                        (blur)="syncWithEnValue()" formControlName="field_one_value" rows="2"
                                        [maxlength]="type3MaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{frForm.get('field_one_value')?.value?.length}}/{{type3MaxLength}}
                            </div>
                        </div>
                        <div class="section-title">
                            {{'company-info.field2'|translate}}
                        </div>
                        <div> </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-icon-my-appointment"></span></div>
                                <mat-form-field>
                                    <mat-label class="label-float">{{'company-info.field_name' |
                                        translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField2Name" id="enField2Name"
                                        formControlName="field_two_name" rows="2"
                                        [maxlength]="type2MaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{frForm.get('field_two_name')?.value?.length}}/{{type2MaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <mat-form-field>
                                    <mat-label>{{'company-info.field_value' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField2Value" id="enField2Value"
                                        (blur)="syncWithEnValue()" formControlName="field_two_value" rows="2"
                                        [maxlength]="type3MaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{frForm.get('field_two_value')?.value?.length}}/{{type3MaxLength}}
                            </div>
                        </div>
                        <div class="section-title">
                            {{'company-info.field3'|translate}}
                        </div>
                        <div> </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <div class="iconBackground"><span class="icon-icon-my-appointment"></span></div>
                                <mat-form-field>
                                    <mat-label class="label-float">{{'company-info.field_name' |
                                        translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField3Name" id="enField3Name"
                                        formControlName="field_three_name" rows="2"
                                        [maxlength]="type2MaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{frForm.get('field_three_name')?.value?.length}}/{{type2MaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <mat-form-field>
                                    <mat-label>{{'company-info.field_value' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="enField3Value" id="enField3Value"
                                        (blur)="syncWithEnValue()" formControlName="field_three_value" rows="2"
                                        [maxlength]="type3MaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{frForm.get('field_three_value')?.value?.length}}/{{type3MaxLength}}
                            </div>
                        </div>
                        <div class="section-title">
                            {{'company-info.website_field'|translate}}
                        </div>
                        <div> </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <mat-form-field>
                                    <mat-label class="label-float">{{'company-info.field_name' |
                                        translate}}</mat-label>
                                    <textarea matInput cols="30" name="enWebsiteLinkName" id="enWebsiteLinkName"
                                        formControlName="website_link_field_name" rows="2"
                                        [maxlength]="type2MaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{frForm.get('website_link_field_name')?.value?.length}}/{{type2MaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <div class="field-container">
                                <mat-form-field>
                                    <mat-label>{{'company-info.field_value' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="enWebsiteLinkValue" id="enWebsiteLinkValue"
                                        (blur)="syncWithEnValue()" formControlName="website_link_field_value" rows="2"
                                        [maxlength]="type2MaxLength"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="character-count">
                                {{frForm.get('website_link_field_value')?.value?.length}}/{{type2MaxLength}}
                            </div>
                        </div>

                    </div>
                </form>
            </mat-tab>

        </mat-tab-group>
        <div class="actions">
            <button mat-raised-button color="primary" class="ghost submit-click"
                (click)="save()">{{'landing-page-config.save' |
                translate}}</button>
            <button mat-button color="accent" class="ghost"
                (click)="cancelClicked.emit()">{{'landing-page-config.cancel' |
                translate}}</button>
        </div>
    </div>
</div>