import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../../../main/services/message.service';

@Component({
    selector: 'app-redirect-dialog',
    templateUrl: './redirect-dialog.component.html',
    styleUrls: ['./redirect-dialog.component.scss']
})
export class RedirectDialogComponent implements OnInit {
    title: string;

    code = '';

    resultLink = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private clipboard: Clipboard,
        private readonly messageService: MessageService,
        private translate: TranslateService
    ) {
        if (data.isFromPatient) {
            this.title = this.data.title;
            this.resultLink = this.data.result.resultLink;
            this.code = this.data.result.resultAccessCode;
        } else {
            this.title = this.data.title;
            this.resultLink = this.data.result.TestResult;
            this.code = this.data.result.InternetAccessId;
        }

        console.log(this.data.resultLink);
    }

    ngOnInit(): void {}

    redirectUrl() {
        window.open(this.resultLink);
    }

    copyCodeToClipboard() {
        this.clipboard.copy(this.code);
        this.messageService.success(this.translate.instant('Common.GlobalMessages.Copied'));
    }
}
