import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    /* #region Fields */
    public currentYear: number;
    /* #endregion */

    constructor() {
        this.getFullYear();
    }

    /* #region Public Methods */
    private getFullYear() {
        this.currentYear = new Date().getFullYear();
    }
    /* #endregion */
}
