import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PatientService } from '../../../services/patient.service';
import { ResetPasswordServiceService } from '../../../services/reset-password-service.service';
import { LoaderService } from '../../../../main';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  public username: any;
  public registeredEmail = new FormControl("", [
    Validators.required,
    Validators.pattern(this.patientService.emailPattern),
  ]);

  @Input() percent: number;
  @Output() openLogin = new EventEmitter<any>();
  @Output() openCreateAccount = new EventEmitter<any>();

  @ViewChild("RegisteredEmail") RegisteredEmail: ElementRef;
  public sendEmailForm = new FormGroup({
    RegisteredEmail: this.registeredEmail,
  });
  forgetPasswordMailSent: boolean;
  forgotPassword: boolean = true;

  constructor(public patientService: PatientService,
    public resetPasswordService: ResetPasswordServiceService,
    public loader: LoaderService) {

  }

  onSend() {
    this.registeredEmail.markAllAsTouched();
    if (!this.registeredEmail.invalid) {
      const inputValue = {
        email: this.RegisteredEmail.nativeElement.value,
      };
      this.username = inputValue.email;
      this.resetPasswordService.postAuthforget(inputValue).subscribe(
        (inputValue) => {
          console.log(inputValue);
          this.forgetPasswordMailSent = true;
          this.forgotPassword = false;
        },
        (error) => {
          console.log(error);
          this.forgetPasswordMailSent = true;
          this.forgotPassword = false;
        }
      );
    }
  }
}
