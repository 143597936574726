export class GetAuthLoginRequest {
    username: string;
    password: string;
    device_detail: string;
    from_appointment: boolean;
}

export class GetAuthOtpRequest {
    username: string;
    password: string;
    otp: string;
    _flow_id: string;
    device_detail: string;
    
}
export class SaltRequest {
    user_name: string;
    id: string;
}
