import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettingsService } from '../core';
// import { environment } from '../../../../environments/environment';
// import { catchError } from 'rxjs/internal/operators/catchError';
// import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BookingSetupService {
    get_companyurl = 'company/specialdate?';
    get_url = 'calender/specialdates?';
    post_companyurl = 'company/specialdate';

    post_url = 'calender/specialdate';

    get_test_url = 'product/tests?';

    post_calendar_url = 'company/stations/slot';

    delete_special_date_url = 'company/specialdate/delete?id=';

    HttpHeaders: any;
    action: any;

    constructor(
        private httpClient: HttpClient,
        private readonly appSettings: AppSettingsService
    ) {}

    companySpecialDateGetRequest(orgId: any) {
        return this.httpClient.get(
            this.appSettings.adminBaseUrl + this.get_companyurl + 'org_id=' + orgId
        );
    }

    addSpecialDatePostRequest(postData: any): Observable<any> {
        const body = JSON.stringify(postData);

        return this.httpClient.post<any>(
            this.appSettings.adminBaseUrl + this.post_url,
            body
        );
    }

    specialDateGetRequest(ssid: any): Observable<any[]> {
        return this.httpClient.get<any[]>(
            this.appSettings.adminBaseUrl + this.get_url + 'ss_id=' + ssid
        );
    }
    // getTests():Observable<any[]>{
    //   return this.httpClient.get<any[]>(this.appSettings.adminBaseUrl+this.get_test_url)
    // }
    getTests(ssid: any) {
        return this.httpClient.get(
            this.appSettings.adminBaseUrl +
        this.get_test_url +
        'sampling_station_id=' +
        ssid
        );
    }
    addCalendarPostRequest(ssid: any, postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        console.log(ssid);
        return this.httpClient.post<any>(
            this.appSettings.adminBaseUrl + this.post_calendar_url,
            body
        );
    }
    companySpecialDatePostRequest(postData: any): Observable<any> {
        const body = JSON.stringify(postData);

        return this.httpClient.post<any>(
            this.appSettings.adminBaseUrl + this.post_companyurl,
            body
        );
    }
    updateCalendarPutRequest(ssid: any, postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        console.log(ssid);
        return this.httpClient.put<any>(
            this.appSettings.adminBaseUrl + this.post_calendar_url,
            body
        );
    }

    deleteCalendar(c_id: any, ss_id: any): Observable<any> {
        return this.httpClient.delete<any>(
            this.appSettings.adminBaseUrl +
        this.post_calendar_url +
        '?calendar_id=' +
        c_id +
        '&sampling_station_id=' +
        ss_id
        );
    }

    deleteSpecialDate(id: any): Observable<any> {
        return this.httpClient.delete<any>(
            this.appSettings.adminBaseUrl + this.delete_special_date_url + id
        );
    }
}
