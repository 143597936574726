import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoaderService } from '../../../../main/services/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-document-info',
  templateUrl: './document-info.component.html',
  styleUrl: './document-info.component.scss'
})
export class DocumentInfoComponent implements OnInit {
  @Output() viewedDocInfo = new EventEmitter<any>();
  @Output() goBack = new EventEmitter<any>();
  @Input() hideBack: boolean = false;
  @Input() selectedLab: any;
  private subscription: Subscription = new Subscription();
  percent: number;
  isStandy: boolean

  constructor(readonly loader: LoaderService, private cdr: ChangeDetectorRef) {
  }
  ngOnInit(): void {
    this.subscription.add(
      this.loader.variable$.subscribe(value => {
        this.percent = (value / this.loader.flow_arr.length) * 100;
        this.cdr.detectChanges(); // Manually trigger change detection
      })
    );
    window.location.href.includes("scan") ? this.isStandy = true : this.isStandy = false;
  }

  moveToNext() {
    this.viewedDocInfo.emit(true);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  goToBack() {
    this.goBack.emit(true);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
