import { Component, ComponentFactoryResolver, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LoaderService, MessageService } from '../../../../main';
import { TranslateService } from '@ngx-translate/core';
import { ViewPrescriptionComponent } from '../../../shared/view-prescription/view-prescription.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-view-appointments',
  templateUrl: './view-appointments.component.html',
  styleUrls: ['./view-appointments.component.scss']
})
export class ViewAppointmentsComponent implements OnInit {
  @Input() appointments: any[];
  @Input() isFuture: boolean;
  @Output() confirmArrival = new EventEmitter();
  public sliderContainer: any;
  displayedColumns: string[] = [
    "recordNo",
    "bookedSlot",
    "testDetails",
    "lab",
    "actions"
  ];
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
    .pipe(
      tap((value) => console.log(value)),
      distinctUntilChanged()
    );
  constructor(public loader: LoaderService,
    public messageService: MessageService,
    public translate: TranslateService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private breakpointObserver: BreakpointObserver,) {
    this.breakpoint$.subscribe(() => this.breakpointChanged());
  }

  ngOnInit(): void {
    if (!this.isFuture) {
      this.displayedColumns = [
        "recordNo",
        "bookedSlot",
        "testDetails",
        "lab"
      ];
    }
  }

  private breakpointChanged() {
    if (
      this.breakpointObserver.isMatched(
        "(max-width: 1050px) and (min-width: 601px)"
      )
    ) {
      this.sliderContainer = 726;
    } else if (this.breakpointObserver.isMatched("(max-width: 600px)")) {
      this.sliderContainer = "100%";
    } else {
      this.sliderContainer = 664;
    }
  }
  redirectRegisterTestKit(rowData: any) {
    if (rowData.Barcode == "") {
      this.loader.highlightLeftMenu(["receptionist/register-test-kit"], {
        state: {
          patientId: rowData.PatientID,
          patientQrId: rowData.QrId,
          appointmentId: rowData.AppointmentId,
        },
      });
    } else {
      this.messageService.alert(
        this.translate.instant("patient-info.already-registered"),
        5000
      );
    }
  }

  checkIsTodayDate(element: any) {
    if (element.SlotTime == "") {
      return true;
    }
    const st = new Date(element.SlotTime);
    const myDate = new Date();
    const datePipe = new DatePipe('en-US');
    const slotDate = datePipe.transform(st, 'yyyy-MM-dd');
    const todayDate = datePipe.transform(myDate, 'yyyy-MM-dd');
    if (todayDate == slotDate) {
      return true;
    }
    return false;
  }

  protected viewPrecription(event: any) {

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        ViewPrescriptionComponent
      );
    this.loader.addDynamicComponent(
      componentFactory,
      this.sliderContainer,
      event
    );
  }
}
