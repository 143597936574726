<div class="Container" [formGroup]="SpecialDatesForm">
  <div class="d-flex ml-40 mt-20">
    <label class="customwidth">{{'special-dates.year' | translate}} : </label>
    <mat-form-field floatLabel="never" class="dropdown-filter">
      <mat-label> {{'special-dates.year' | translate}}</mat-label>
      <mat-select disableOptionCentering formControlName="y" (selectionChange)="selectYear($event.value)">
        <mat-option *ngFor="let y of year" [value]="y">
          {{ y }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="d-flex ml-40 mt-20">
    <label class="customwidth">{{'special-dates.range' | translate}} : </label>
    <mat-form-field floatLabel="never" class="dropdown-filter ">
      <mat-label> {{'special-dates.range' | translate}}</mat-label>
      <mat-select disableOptionCentering formControlName="ranges" id="range" [(value)]="ranges">
        <mat-option *ngFor="let stepOption of range | keyvalue" [value]="stepOption.key">
          {{ stepOption.value | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="d-flex ml-40 mt-20 mb-20">
    <label class="customwidth">{{'special-dates.comment' | translate}} : </label>
    <mat-form-field style="width:300px">
      <mat-label>{{'special-dates.comment' | translate}}</mat-label>
      <input id="comments" formControlName="comment" matInput matTooltipClass="tooltip-style" matTooltipPosition="above"
        appMatTooltip="{{ SpecialDatesForm.get('comment').value }}" />
    </mat-form-field>
  </div>
  <div class="d-flex ml-40 mt-20 input-min-spacing" *ngIf="ranges=='Period'">

    <label class="customwidth">{{'special-dates.from' | translate}}: </label>
    <app-datepicker id="dateFrom" labelText="{{'special-dates.validated-from' | translate}}" [dateInput]="dateFrom"
      isRequired="false" [minDate]="fromDateMin" (selectedDate)="onSelectedDate($event)" style="width:300px">
    </app-datepicker>
    <label class="ml-30">{{'special-dates.to' | translate}}:</label>
    <app-datepicker id="dateTo" labelText="{{'special-dates.validated-to' | translate}}" [dateInput]="dateTo"
      isRequired="false" style="width:300px" class="ml-30" [minDate]="toDateMin"
      minDateViolationMessage="{{'special-dates.date-violation' | translate}}"></app-datepicker>

    <button mat-raised-button color="primary" id="btn_add_periods" class="specialdates-btn ml-30"
      (click)="addPeriods()">
      {{'special-dates.add-period' | translate}}
    </button>

    <button mat-raised-button color="primary" id="btn_clear" class="ml-30" style="align-items:center "
      (click)="reset()">{{'admin.clear' | translate}}</button>

  </div>

  <div class="d-flex ml-40 mt-20 input-min-spacing" *ngIf="ranges=='Day'">

    <label class="customwidth">{{'special-dates.date' | translate}}:</label>

    <app-datepicker id="dateFro" labelText="{{'special-dates.pick-a-date' | translate}}" [dateInput]="dateFro"
      isRequired="false" style="width:300px" [minDate]="fromDateMin"></app-datepicker>


    <div class="ml-40">

      <button mat-raised-button color="primary" class="specialdates-btn" id="btn_add_day" (click)="addDate()">

        {{'special-dates.add-day' | translate}}

      </button>

      <button mat-raised-button color="primary" style="align-items:center " id="btn_clear" class="ml-30"
        (click)="reset()">{{'admin.clear' | translate}}</button>

    </div>

  </div>

  <div>

    <mat-table #table [dataSource]="periods" matSort #customSort="matSort"
      class="table-height input-min-spacing table-flex" id="HolidayListingTable"
      [ngStyle]="ranges==='Period'?{'display':'block'}:{'display':'none'}">
      <ng-container matColumnDef="Id">
        <mat-header-cell *matHeaderCellDef mat-sort-header="id">
          <span>{{'special-dates.id' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity.id }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="InitialDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="start_date">
          <span>{{'special-dates.initial-date' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity.start_date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EndDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="end_date">
          <span>{{'special-dates.end-date' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity.end_date }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Days">
        <mat-header-cell *matHeaderCellDef mat-sort-header="numofdays">
          <span>{{'special-dates.days' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity.numofdays }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Comment">
        <mat-header-cell *matHeaderCellDef mat-sort-header="comment">
          <span>{{'special-dates.comment' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity.comment }}
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="Options">
        <mat-header-cell *matHeaderCellDef style="justify-content: flex-middle;">
          <span>{{'special-dates.options' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity" style="
            justify-content: flex-middle;
            padding-bottom: 0 !important;
            padding-top: 12px !important;

          ">
          <!-- <button rippleOnHover class="mat-tbl-icon editEntity_btn mr-8" *ngIf="entity.Id == hoverindex"
            mat-icon-button>
            <span class="icon-ic-edit" matTooltip="{{'special-dates.edit' | translate}}"
              matTooltipClass="tooltip-style"></span>
          </button> -->
          <button class="mat-tbl-icon deleteEntity_btn mr-8" *ngIf="entity.Id == hoverindex"
            (click)="onDeleteItemCkick(entity)" id="btn_delete" mat-icon-button>
            <span class="icon-ic-delete" matTooltip="{{'special-dates.delete' | translate}}"
              matTooltipClass="tooltip-style"></span>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnList; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnList; let i = index" class="element-grid-row"
        (mouseover)="rowMouseOver(row)">
      </mat-row>
    </mat-table>
    <mat-paginator #MatPaginator1 [hidden]="ranges==='Day'"
      [ngStyle]="ranges==='Period'?{'display':'block'}:{'display':'none'}" [pageSize]="10" style="align-items:center"
      [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons="true">
    </mat-paginator>


  </div>
  <div>

    <mat-table #table1 [dataSource]="daysList" matSort #customSort2="matSort"
      class="table-height input-min-spacing table-flex" id="HolidayListingTable1"
      [ngStyle]="ranges==='Day'?{'display':'block'}:{'display':'none'}">
      <ng-container matColumnDef="Id">
        <mat-header-cell *matHeaderCellDef mat-sort-header="id">
          <span>{{'special-dates.id' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity.id }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Date">
        <mat-header-cell *matHeaderCellDef mat-sort-header="start_date">
          <span>{{'special-dates.date' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity.start_date | dateFormat }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Comment">
        <mat-header-cell *matHeaderCellDef mat-sort-header="comment">
          <span>{{'special-dates.comment' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity.comment }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Options">
        <mat-header-cell *matHeaderCellDef style="justify-content: flex-middle;">
          <span>{{'special-dates.options' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity" style="
          justify-content: flex-middle;
          padding-bottom: 0 !important;
          padding-top: 12px !important;

        ">
          <!-- <button rippleOnHover class="mat-tbl-icon editEntity_btn mr-8" *ngIf="entity.Id == hoverindex"
            mat-icon-button>
            <span class="icon-ic-edit" matTooltip="{{'special-dates.edit' | translate}}"
              matTooltipClass="tooltip-style"></span>
          </button> -->
          <button rippleOnHover class="mat-tbl-icon deleteEntity_btn mr-8" *ngIf="entity.Id == hoverindex"
            mat-icon-button id="btn_delete_periods" (click)="onDeleteItemCkick(entity)">
            <span class="icon-ic-delete" matTooltip="{{'special-dates.delete' | translate}}"
              matTooltipClass="tooltip-style"></span>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="dayColumnList; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dayColumnList; let i = index" class="element-grid-row"
        (mouseover)="rowMouseOver(row)">
      </mat-row>
    </mat-table>
    <mat-paginator #MatPaginator2 [hidden]="ranges==='Period'"
      [ngStyle]="ranges==='Day'?{'display':'block'}:{'display':'none'}" [pageSize]="10" style="align-items:center"
      [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons="true">
    </mat-paginator>


  </div>


</div>