import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../app.locale.service';

@Pipe({
    name: 'dateFormat'
})
export class DateSkipTimeIfEmpty extends DatePipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService,
        public localeService: LocaleService) {
        super(localeService.localeLang);
        this.translateService.use(this.localeService.localeLang);
        this.translateService.setDefaultLang(this.localeService.localeLang);
    }

    static readonly DATE_FMT = 'dd/MM/yyyy';
    static readonly DATE_TIME_FMT = 'dd/MM/yyyy | HH:mm';
    static readonly TIME_FORMAT = 'HH:mm';
    transform(value: any): any {
        if (value == null || value == "" || new Date(value).getFullYear() == 1) {
            return this.translateService.instant("appointments.walk-in")
        }
        const timeval = super.transform(value, DateSkipTimeIfEmpty.TIME_FORMAT);
        if (timeval == '00:00') {
            return super.transform(value, DateSkipTimeIfEmpty.DATE_FMT);
        } else {
            return super.transform(value, DateSkipTimeIfEmpty.DATE_TIME_FMT);
        }
    }

}
