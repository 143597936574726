import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
/** Service to handle Spinner or page/data loading in application */
export class SpinnerService {

    /* #region Fields */
    private readonly subject: Subject<boolean> = new Subject<boolean>();
    /* #endregion */

    constructor() { }

    /* #region Public Methods */
    /** Method to control/toggle spinner display */
    public show(show: boolean): void {
        this.subject.next(show);
        if (show) {
            document.getElementById('mat-sidenav-container-root')?.classList.add('backdrop-blur');
        } else {
            document.getElementById('mat-sidenav-container-root')?.classList.remove('backdrop-blur');
        }
    }

    /** Method to subscribe to spinner service to display spinner by status */
    public getMessage(): Observable<boolean> {
        return this.subject.asObservable();
    }
    /* #endregion */
}
