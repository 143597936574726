import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../../app.locale.service';
import { MessageService } from '../../../../main/services/message.service';
import { BookingSetupService } from '../../../../services/booking-setup.service';

@Component({
    selector: 'app-special-dates',
    templateUrl: './special-dates.component.html',
    styleUrls: ['./special-dates.component.scss']
})
export class SpecialDatesComponent implements OnInit {
    @Input() samplingStationId: SpecialDatesComponent;
    @Input() orgId: SpecialDatesComponent;
    year: any = [];
    cyear: any;
    today = new Date();
    @ViewChild('customSort', { static: false }) sort: MatSort;
    @ViewChild('customSort2', { static: false }) sort2: MatSort;
    @ViewChild('MatPaginator1', { static: false }) MatPaginator1: MatPaginator;
    @ViewChild('MatPaginator2', { static: false }) MatPaginator2: MatPaginator;
    range: { [key: string]: string } = {
        Day: 'special-dates.day',
        Period: 'special-dates.period'
    };
    validatedFrom = new FormControl();
    validatedTo = new FormControl();
    public hoverindex = '';
    filter: string;
    public columnList = [
        'Id',
        'InitialDate',
        'EndDate',
        'Days',
        'Comment',
        'Options'
    ];
    public dayColumnList = ['Id', 'Date', 'Comment', 'Options'];
    SpecialDatesForm: FormGroup;
    sd: any;
    periods: MatTableDataSource<any>;
    allPeriods: MatTableDataSource<any>;
    ranges = '';

    Active: any;
    daysList: MatTableDataSource<any>;
    Comment: any;
    start_date: any;
    end_date: any;
    dateFrom = new FormControl();
    dateTo = new FormControl();
    dateFro = new FormControl();
    fromDateMin: Date;
    toDateMin: Date;
    selectedDate: Date;

    savealert = {
        edit: 'admin.save-alert.edit',
        add: 'admin.save-alert.add',
        delete: 'admin.save-alert.delete'
    };
    errorMessage = '';
    data: any;

    constructor(
        private formBuilder: FormBuilder,
        private service: BookingSetupService,
        private datepipe: DatePipe,
        private messageService: MessageService,
        private translate: TranslateService,
        public localeService: LocaleService
    ) {
        this.periods = new MatTableDataSource<any>();
        this.daysList = new MatTableDataSource<any>();
        this.allPeriods = new MatTableDataSource<any>();
        this.fromDateMin = new Date();
        this.toDateMin = new Date();
        this.toDateMin.setDate(this.fromDateMin.getDate() + 1);
        translate.setDefaultLang(this.localeService.localeLang);
        translate.use(this.localeService.localeLang);
    }

    ngOnInit(): void {
        this.cyear = new Date().getFullYear();
        for (let i = this.cyear; i < this.cyear + 10; i++) {
            this.year.push(i);
        }
        this.SpecialDatesForm = this.formBuilder.group({
            y: new FormControl(''),
            ranges: new FormControl(''),
            id: new FormControl(''),
            comment: new FormControl('', Validators.required),
            start_date: new FormControl('', Validators.required),
            end_date: new FormControl('', Validators.required),
            org_id: [this.orgId],
            sampling_station_id: [this.samplingStationId],
            numofdays: new FormControl('')
        });
        if (this.orgId != null && this.samplingStationId != null) {
            this.getGridData();
        }
        if (this.orgId != null && this.samplingStationId == null) {
            this.companyGridData();
        }
    }

    public companyGridData() {
        this.service.companySpecialDateGetRequest(this.orgId).subscribe(
            (data: any[]) => {
                this.Active = true;
                this.periods.data = data;
                this.periods.sort = this.sort;
                this.periods.paginator = this.MatPaginator1;
                this.periods.data = data.filter(
                    (x) =>
                        x.numofdays > 1 &&
            x.start_date.substring(x.start_date.length - 4) == this.cyear
                );
                this.daysList.data = data.filter(
                    (x) =>
                        x.numofdays == 1 &&
            x.start_date.substring(x.start_date.length - 4) == this.cyear
                );
                this.allPeriods.data = data;
            },

            (err: { message: any }) => err.message
        );
    }
    public getGridData() {
        this.service.specialDateGetRequest(this.samplingStationId).subscribe(
            (data: any[]) => {
                this.periods.data = data;
                this.periods.sort = this.sort;
                this.periods.paginator = this.MatPaginator1;
                this.periods.data = data.filter(
                    (x) =>
                        x.numofdays > 1 &&
            x.start_date.substring(x.start_date.length - 4) == this.cyear
                );
                this.daysList.data = data.filter(
                    (x) =>
                        x.numofdays == 1 &&
            x.start_date.substring(x.start_date.length - 4) == this.cyear
                );
                this.allPeriods.data = data;
            },

            (err) => err.message
        );
    }
    onSelectedDate(e: any) {
        this.changeMinDateInDatePicker(e);
    }

    changeMinDateInDatePicker(inputData: any) {
        this.selectedDate = inputData;
        const datePipe = new DatePipe('en-US');

        const formattedDate = datePipe.transform(this.selectedDate, 'dd-MM-yyyy');
        if (formattedDate == null) {
            return;
        }

        const splittedDateArray = formattedDate.split('-');

        const year = Number(splittedDateArray[2]);
        const month = Number(splittedDateArray[1]) - 1;
        const date = Number(splittedDateArray[0]) + 1;

        this.toDateMin = new Date(year, month, date);
    }
    public ngAfterViewInit(): void {
        this.periods.sort = this.sort;
        this.daysList.sort = this.sort2;
        this.periods.paginator = this.MatPaginator1;
        this.daysList.paginator = this.MatPaginator2;
    }

    addPeriods() {
        if (!this.dateFrom.value) {
            this.dateFrom.markAsTouched();
            return;
        }
        if (!this.dateTo.value) {
            this.dateTo.markAsTouched();
            return;
        }
        const periodData = this.periods.data;
        const daysData = this.daysList.data;
        const newPeriodObj: any = {
            org_id: this.orgId,
            sampling_station_id: this.samplingStationId,
            start_date: this.datepipe.transform(this.dateFrom.value, 'yyyy-MM-dd'),
            end_date: this.datepipe.transform(this.dateTo.value, 'yyyy-MM-dd'),
            numofdays: this.getDifferenceInDays(
                new Date(this.dateFrom.value),
                new Date(this.dateTo.value)
            ),
            comment: this.SpecialDatesForm.value.comment
        };
        if (this.samplingStationId != null) {
            this.service.addSpecialDatePostRequest(newPeriodObj).subscribe(
                (data: any) => {
                    data;
                    this.getGridData();
                    this.periods.data = periodData;
                    this.daysList.data = daysData;
                    this.periods.sort = this.sort;
                    this.periods.paginator = this.MatPaginator1;
                    this.messageService.success(
                        this.translate.instant(this.savealert['add'])
                    );
                },
                (err) => {
                    this.messageService.alert(err.error.message);
                }
            );
        }
        if (this.samplingStationId == null) {
            this.service.companySpecialDatePostRequest(newPeriodObj).subscribe(
                (data: any) => {
                    data;
                    this.companyGridData();
                    this.periods.data = periodData;
                    this.daysList.data = daysData;
                    this.periods.sort = this.sort;
                    this.periods.paginator = this.MatPaginator1;
                    this.messageService.success(
                        this.translate.instant(this.savealert['add'])
                    );
                },
                (err) => {
                    this.messageService.alert(err.error.message);
                }
            );
        }
    }
    getDifferenceInDays(date1: any, date2: any) {
        const diffInMs = Math.abs(date2 - date1);
        return diffInMs / (1000 * 60 * 60 * 24) + 1;
    }

    addDate() {
        if (!this.dateFro.value) {
            this.dateFro.markAsTouched();
            return;
        }
        const newDateObj: any = {
            org_id: this.orgId,
            sampling_station_id: this.samplingStationId,
            start_date: this.datepipe.transform(this.dateFro.value, 'yyyy-MM-dd'),
            end_date: this.datepipe.transform(this.dateFro.value, 'yyyy-MM-dd'),
            numofdays: this.getDifferenceInDays(
                new Date(this.dateFro.value),
                new Date(this.dateFro.value)
            ),
            comment: this.SpecialDatesForm.value.comment
        };
        if (this.samplingStationId != null) {
            this.service.addSpecialDatePostRequest(newDateObj).subscribe(
                (data: any) => {
                    data;
                    this.getGridData();
                    this.messageService.success(
                        this.translate.instant(this.savealert['add'])
                    );
                },
                (err) => {
                    if (err.statusText == 'Bad Request') {
                        this.messageService.alert(this.translate.instant('admin.empty-comment'));
                    } else {
                        this.messageService.alert(err.error.message);
                    }
                }
            );
        }
        if (this.samplingStationId == null) {
            this.service.companySpecialDatePostRequest(newDateObj).subscribe(
                (data: any) => {
                    data;
                    this.companyGridData();
                    this.messageService.success(
                        this.translate.instant(this.savealert['add'])
                    );
                },
                (err) => {
                    if (err.statusText == 'Bad Request') {
                        this.messageService.alert(this.translate.instant('admin.empty-comment'));
                    } else {
                        this.messageService.alert(err.error.message);
                    }
                }
            );
        }
    }

    reset() {
        this.SpecialDatesForm.reset();
        this.dateFrom.setValue(null);

        this.dateTo.setValue(null);
        this.dateFro.setValue(null);
    }

    /** Method to identify hovered grid row */
    public rowMouseOver(row: any): void {
        this.hoverindex = row.Id;
    }
    save() {
        alert(this.translate.instant('special-dates.saved'));
    }

    selectYear(value: any) {
        this.periods = new MatTableDataSource<any>();
        this.cyear = value;
        if (this.orgId != null && this.samplingStationId != null) {
            this.getGridData();
        }
        if (this.orgId != null && this.samplingStationId == null) {
            this.companyGridData();
        }
    }

    onDeleteItemCkick(itemData: any) {
        console.log('on delete Item click ' + JSON.stringify(itemData));

        this.messageService
            .confirm(this.translate.instant('admin.delete-confirm-text'), '')
            .subscribe((actionResult: boolean) => {
                if (actionResult) {
                    this.callDeleteSpecailDateApi(itemData.id);
                }
            });
    }

    callDeleteSpecailDateApi(id: any) {
        this.service.deleteSpecialDate(id).subscribe(
            (data: any) => {
                console.log(data);
                if (!data.is_active) {
                    this.messageService.success(
                        this.translate.instant('admin.save-alert.delete')
                    );
                    if (this.orgId != null && this.samplingStationId != null) {
                        this.getGridData();
                    }
                    if (this.orgId != null && this.samplingStationId == null) {
                        this.companyGridData();
                    }
                }
            },
            (err) => {
                this.messageService.alert(err.error.message);
            }
        );
    }
}
