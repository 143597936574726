<div class="create-account-container" [ngClass]="{'register-align':!editMode}" *ngIf="step==1">
    <div class="header flex-box" *ngIf="editMode">
        <div class="pull-left">
            <app-section-title title="{{'patient-information.edit-persional-info'|translate}}"></app-section-title>
        </div>
        <div class="pull-right">
            <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
                <span class="icon-ic-close"></span>
            </button>
        </div>
    </div>

    <form [formGroup]="form" id="createAccount_form" appAutoScroll [appAutoScroll]="form" class="input-min-spacing">
        <div class="info-wrapper" *ngIf="!editMode">
            <label class="mediumShadelabel"
                [ngClass]="{'no-pad':noRecordMode==true , 'p-0': (showVerified==true && prefilMode==true)}">
                {{'createAccount.personal-information' | translate}}</label>
            <div class="verified-text" *ngIf="prefilMode && showVerified && !isLoginReceptionist">
                {{'createAccount.verified' | translate}}</div>
        </div>
        <p class="mediumShadelabelDesc" *ngIf="!editMode && !isLoginReceptionist ">
            {{'createAccount.personal-information-desc' | translate}}</p>

        <div class="content marginhead" *ngIf="editMode">
            <div class="patient-info">
                <div class="initial">
                    <span>{{Name?.charAt(0)}}</span>
                </div>
                <div class="p-details">

                    <p>{{editPatientDetail.FirstName}}
                        {{editPatientDetail.LastName}}
                        <strong>{{'patient-information.born'|translate}}</strong>{{editPatientDetail.BirthName}}
                    </p>
                    <!-- <p>{{Name}}</p> -->
                    <p *ngIf="ssn?.value"><span class="type">{{'createAccount.ssn' | translate}} :</span><span
                            class="desc">{{ssn?.value}}</span>
                    </p>
                    <p *ngIf="passport?.value"><span class="type">{{'createAccount.passport' | translate}} :</span><span
                            class="desc">{{passport?.value}}</span>
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="editMode">
            <label class="mediumShadelabel"
                [ngClass]="{'no-pad':noRecordMode==true , 'p-0': (showVerified==true && prefilMode==true)}">
                {{'createAccount.personal-information' | translate}}</label>
        </div>

        <div class="createAccountwrapper">
            <div class="mb-24">
                <label style="display: block">{{'createAccount.gender' | translate}}<span style="color:red;">
                        *</span></label>
                <mat-radio-group [formControl]="gender" (change)="onSSNChange()" required>
                    <mat-radio-button class="marginbtm" *ngFor="let gen of genders"
                        value="{{gen.Id}}">{{gen.KeyName|translate}}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="gender.value && gender.invalid">{{'createAccount.valid-gender' | translate}}
                </mat-error>
            </div>
            <div *ngIf="editMode" class="mb-24">
                <label style="display: block">{{'createAccount.private-insurance' | translate}}<span style="color:red;">
                        *</span></label>
                <mat-radio-group [formControl]="insurance" required>
                    <mat-radio-button *ngFor="let val of choices" value="{{val.Id}}">{{val.KeyName|translate}}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="insurance.value && insurance.invalid">{{'createAccount.valid-gender' | translate}}
                </mat-error>
            </div>
            <div *ngIf="!editMode"></div>
            <mat-form-field>
                <mat-label>{{'createAccount.first-name' | translate}}</mat-label>
                <input matInput name="FirstName" id="FirstName" [formControl]="firstName" formControlName="firstName"
                    (input)="loader.pasteAlphabets($event)" />
                <mat-error
                    *ngIf="!form.get('firstName')?.hasError('invalidChars') && firstName.invalid">{{'createAccount.valid-first-name'
                    | translate}}</mat-error>
                <mat-error *ngIf="form.get('firstName')?.hasError('invalidChars') && firstName.invalid">
                    {{'createAccount.invalid-char'| translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'createAccount.birth-name' | translate}}</mat-label>
                <input matInput name="BirthName" id="BirthName" [formControl]="birthName" (input)="copyLastName()" />
                <mat-error
                    *ngIf="!form.get('birthName')?.hasError('invalidChars') && birthName.invalid">{{'createAccount.valid-birth-name'
                    | translate}}</mat-error>
                <mat-error *ngIf="form.get('birthName')?.hasError('invalidChars') && birthName.invalid">
                    {{'createAccount.invalid-char'| translate}}
                </mat-error>
            </mat-form-field>
            <div class="mb-24">
                <label class="d-block">{{'createAccount.sameasLastName' | translate}}</label>
                <mat-radio-group [formControl]="sameLastName" (change)="onSameLastNameChange()" required>
                    <mat-radio-button value="0">{{'createAccount.yes'|translate}}
                    </mat-radio-button>
                    <mat-radio-button value="1">{{'createAccount.no'|translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="only-web">

            </div>
            <mat-form-field>
                <mat-label>{{'createAccount.last-name' | translate}}</mat-label>
                <input matInput name="LastName" id="LastName" [formControl]="lastName"
                    [readonly]='sameLastName.value=="0"' [disabled]='sameLastName.value=="0"' />
                <!-- <mat-error *ngIf="lastName.invalid">{{'createAccount.valid-last-name' | translate}}</mat-error> -->
                <mat-error
                    *ngIf="!form.get('lastName')?.hasError('invalidChars') && lastName.invalid">{{'createAccount.valid-last-name'
                    | translate}}</mat-error>
                <mat-error *ngIf="form.get('lastName')?.hasError('invalidChars') && lastName.invalid">
                    {{'createAccount.invalid-char'| translate}}
                </mat-error>
            </mat-form-field>
            <app-datepicker id="dateofBirth" labelText="{{'createAccount.dob' | translate}}" [dateInput]="dateOfBirth"
                (selectedDate)="onSSNChange()" isRequired="true" isRequiredError="true" [maxDate]="maxDate">
            </app-datepicker>
            <ng-container *ngIf="editMode || (!isLoginReceptionist )">
                <div class="mb-24">
                    <label class="d-block">{{'createAccount.haveSSN' | translate}}</label>
                    <mat-radio-group [formControl]="haveSSN" (change)="onHaveSSNChange()" required>
                        <mat-radio-button value="0">{{'createAccount.yes'|translate}}
                        </mat-radio-button>
                        <mat-radio-button value="1">{{'createAccount.no'|translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="!isSSNRequired" class="only-web"></div>
                <mat-form-field [hidden]="!isSSNRequired">
                    <mat-label>{{'createAccount.ssn' | translate}}</mat-label>
                    <input matInput name="ssn" id="ssn" [formControl]="ssn" (blur)="onSSNChange()"
                        (keydown.enter)="onSSNChange()" [required]="isSSNRequired" (input)="restrictSpace($event)"
                        (keydown.space)="$event.preventDefault()" (paste)="restrictSpace($event)" />
                    <mat-error *ngIf="ssn.invalid  && !ssn.hasError('ssnExist')">{{'createAccount.valid-ssn' |
                        translate}}
                    </mat-error>
                    <mat-error *ngIf="ssn.invalid  && ssn.hasError('ssnExist')">{{'createAccount.invalid-ssn' |
                        translate}}
                    </mat-error>

                </mat-form-field>

                <mat-form-field id="nationalityFlag">
                    <mat-label>{{'createAccount.nationality' | translate}}</mat-label>
                    <mat-select placeholder="{{'createAccount.nationality' | translate}}"
                        [ngStyle]="{'background': 'url(/assets/svg-country-flags/svg/'+selectedFlag+'.svg) left / contain no-repeat',' white-space': 'nowrap','display':'inline'}"
                        name="Nationality" id="Nationality" [formControl]="nationality"
                        (selectionChange)="onSelectNationality($event.value)">
                        <mat-option class="country" *ngFor="let nationality of nationalities" [value]="nationality.id">
                            <img src="/assets/svg-country-flags/svg/{{nationality.country_code.toLowerCase()}}.svg" />
                            <span class="countryName">{{nationality.country_name}}</span>
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="nationality.invalid">{{'createAccount.valid-nationality' | translate}}</mat-error>
                </mat-form-field>
                <div></div>
                <mat-form-field id="birthCountryFlag" class="mobile-mt">
                    <mat-label>{{'createAccount.birth-country' | translate}}</mat-label>
                    <mat-select placeholder="{{'createAccount.birth-country' | translate}}"
                        [ngStyle]="{'background': 'url(/assets/svg-country-flags/svg/'+selectedBirthCountryFlag+'.svg) left / contain no-repeat',' white-space': 'nowrap','display':'inline'}"
                        name="BirthCountry" id="BirthCountry" [formControl]="birthCountry"
                        (selectionChange)="onSelectBirthCountry($event.value)">
                        <mat-option class="country" *ngFor="let bc of filteredBirthCountries" [value]="bc.id">
                            <img src="/assets/svg-country-flags/svg/{{bc.country_code.toLowerCase()}}.svg" />
                            <span class="countryName">{{bc.country_name}} </span>
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="birthCountry.invalid">{{'createAccount.valid-birth-country' |
                        translate}}</mat-error>
                </mat-form-field>

                <mat-form-field class="dropdown-filter" [hidden]="!isBirthCityRequired">
                    <mat-label>{{'createAccount.birth-city' | translate}}</mat-label>
                    <input type="text" #searchBirthCityData matInput (click)="birthCity.setValue('')" name="birthCity"
                        [formControl]="birthCity" (blur)="validateSelection()" [matAutocomplete]="auto"
                        [required]="isBirthCityRequired" placeholder="{{'createAccount.SelectCity'|translate}}">
                    <mat-icon class="arrow_drop_down" matSuffix>arrow_drop_down</mat-icon>

                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
                        (optionSelected)="setBirthCity($event)">
                        <mat-option *ngFor="let ss of filteredBirthCities | async" [value]="ss">
                            {{ss.city_name}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="birthCity.invalid">{{'createAccount.invalid-birth-city' |
                        translate}}</mat-error>
                </mat-form-field>
            </ng-container>
        </div>
        <p class="mediumShadelabel">{{'createAccount.account' | translate}}</p>
        <div class="createAccountwrapper">
            <mat-form-field floatLabel="always" class="phone-ctrl">
                <mat-label>{{'createAccount.phone' | translate}}</mat-label>
                <ngx-mat-intl-tel-input [preferredCountries]="['fr']" [enablePlaceholder]="false" name="phone" #phone
                    [formControl]="phoneNumber" required>
                </ngx-mat-intl-tel-input>
                <mat-error *ngIf="phone?.errors?.required">{{'createAccount.valid-phone' | translate}}</mat-error>
                <mat-error *ngIf="phone?.errors?.validatePhoneNumber">{{'createAccount.valid-phone' | translate}}
                </mat-error>

            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'createAccount.email' | translate}}</mat-label>
                <input type="email" matInput name="Email" maxlength="60" id="Email" [formControl]="email" />
                <mat-error *ngIf="!email.valid  && !email.hasError('emailExist')">{{'createAccount.valid-email' |
                    translate}}</mat-error>
                <mat-error *ngIf="!email.valid && email.hasError('emailExist')">{{'createAccount.email-exist' |
                    translate}}</mat-error>

            </mat-form-field>
            <mat-form-field *ngIf="!editMode && !isLoginReceptionist ">
                <mat-label>{{'createAccount.password' | translate}}</mat-label>
                <input name="Password" (blur)="hideErrorBox()" (focus)="showErrorBox();" (change)="onPasswordChange()"
                    matInput [formControl]="password" autocomplete="off" (keydown.space)="loader.disableSpace($event)"
                    [type]="passwordHide ? 'text' : 'password'" required>
                <mat-error
                    *ngIf="password.invalid && password.value && password.hasError('whitespace')">{{'createAccount.space-error'
                    |
                    translate}}</mat-error>
                <mat-error
                    *ngIf="password.invalid && password.value && !password.hasError('whitespace')">{{'createAccount.valid-password'
                    |
                    translate}}</mat-error>
                <mat-error
                    *ngIf="password.invalid && !password.value && password.hasError('whitespace')">{{'createAccount.valid-password'
                    |
                    translate}}</mat-error>
                <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{ passwordHide ? "visibility" :
                    "visibility_off"
                    }}</mat-icon>
                <app-password-policy (isValid)="checkIsValid($event)"></app-password-policy>
            </mat-form-field>
            <mat-form-field *ngIf="!editMode && !isLoginReceptionist">
                <mat-label>{{'createAccount.repeat-password' | translate}}</mat-label>
                <input matInput name="RepeatPassword" id="RepeatPassword" [formControl]="repeatPassword"
                    [type]="rPasswordHide ? 'text' : 'password'" (change)="onPasswordChange()"
                    (keydown.space)="loader.disableSpace($event)" required />
                <mat-icon matSuffix (click)="rPasswordHide = !rPasswordHide">{{ rPasswordHide ? "visibility" :
                    "visibility_off"
                    }}</mat-icon>
                <mat-error *ngIf="repeatPassword.invalid">{{'createAccount.match-password' | translate}}</mat-error>
            </mat-form-field>
        </div>
        <p class="mediumShadelabel" *ngIf="editMode">{{'createAccount.address-heading' | translate}}</p>
        <div class="createAccountwrapper" *ngIf="editMode || (!isLoginReceptionist)">
            <mat-form-field>
                <mat-label>{{'createAccount.address' | translate}}</mat-label>
                <input matInput type="text" id="autocompleteinput" name="address"
                    placeholder="{{'createAccount.placeholder-address' | translate}}" autocomplete="off"
                    (focus)="isAutoCompleteFocused = true" [formControl]="address" />
                <!-- <mat-error *ngIf="address.invalid">{{'createAccount.valid-address' | translate}}</mat-error> -->
                <mat-error
                    *ngIf="!form.get('address')?.hasError('invalidChars') && address.invalid">{{'createAccount.valid-address'
                    | translate}}</mat-error>
                <mat-error *ngIf="form.get('address')?.hasError('invalidChars') && address.invalid">
                    {{'createAccount.invalid-char'| translate}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'createAccount.country' | translate}}</mat-label>
                <mat-select name="country" id="country" [formControl]="country">
                    <mat-option *ngFor="let nationality of nationalities" [value]="nationality.id">
                        {{nationality.country_name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="country.invalid">{{'createAccount.valid-country' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'createAccount.city' | translate}}</mat-label>
                <input matInput name="City" id="City" [formControl]="city" />
                <mat-error *ngIf="city.invalid">{{'createAccount.valid-city' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'createAccount.zipcode' | translate}}</mat-label>
                <input matInput name="Zipcode" id="Zipcode" [formControl]="zip" />
                <mat-error *ngIf="zip.invalid">{{'createAccount.valid-zipcode' | translate}}</mat-error>
            </mat-form-field>
        </div>

        <div class="actions" *ngIf="!editMode">
            <div class="decleration" *ngIf="!isLoginReceptionist">
                <mat-checkbox id="privacy" [formControl]="privacy" required><a
                        href="{{'login.privacy-policy-link' | translate}}" target="_blank">{{'login.privacy-policy' |
                        translate}}</a></mat-checkbox>
                <mat-checkbox [formControl]="terms" required><a href="{{'login.terms-of-use-link' | translate}}"
                        target="_blank">{{'login.terms-of-use' | translate}}</a></mat-checkbox>

            </div>
            <div class="inner-btn" [ngClass]="{'mobile-booking':bookAppointment, 'justify-content-end': !showBackBtn}">
                <button mat-button color="accent" class="ghost button_width mr-20" type="button"
                    *ngIf="!fromAppointment" (click)="resetForm()" id="btn_clear">
                    {{'createAccount.clear' | translate}}
                </button>
                <button mat-button color="accent" class="ghost button_width mr-20" type="button"
                    *ngIf="fromAppointment && showBackBtn" (click)="goBack()" id="btn_clear">
                    {{'back' | translate}}
                </button>
                <button mat-raised-button color="primary" class="button_width " type="submit" id="btn_save"
                    (click)="proceed()" *ngIf="!isLoginReceptionist || !unique_id">
                    {{'createAccount.register' | translate}}
                </button>
                <button mat-raised-button color="primary" class="button_width "
                    *ngIf="isLoginReceptionist && fromAppointment==true && unique_id " type="submit" id="btn_save"
                    (click)="updatePatientFromReceptionist()">
                    {{'createAccount.update' | translate}}
                </button>
            </div>
            <a color="accent" class="ghost  cp" id="btn_back" *ngIf="!isLoginReceptionist && fromAppointment==false"
                (click)="goToLogin()">{{'createAccount.login' |
                translate}}</a>

        </div>
        <div class="actions" *ngIf="editMode">
            <div class="inner-btn">
                <button mat-button color="accent" class="ghost button_width mr-20" type="button" (click)="dismiss()"
                    id="btn_clear">
                    {{'createAccount.cancel' | translate}}
                </button>
                <button mat-raised-button color="primary" class="button_width" type="submit" id="btn_save"
                    (click)="updateUser()">
                    {{'createAccount.update' | translate}}
                </button>

            </div>
        </div>
    </form>
</div>

<ng-container *ngIf="step==2">
    <div class="create-account-container register-align" *ngIf="!editMode && !isLoginReceptionist ">
        <app-doc-upload [minor]="age<18" [nationality]="nationality.value" (backEmit)="back()" [doc_ids]="doc_id"
            (submitEmit)="uploadDoc($event)" [fromAppointment]="fromAppointment"></app-doc-upload>
    </div>
</ng-container>