<div class="create-establishment-container">
    <div class="header flex-box">
        <div class="pull-left">
            <div class="heading d-flex">
                <span class="title">
                    {{ sliderFormData[0].edit ? ('ehp-user.edit-heading' | translate) :
                    ('ehp-user.add-heading' | translate) }}
                </span>
            </div>
        </div>
        <div class="pull-right">
            <button *ngIf="sliderFormData[0].edit"
                mat-button color="accent" class="ghost button_width mr-20" type="button" (click)="dismiss()"
                    id="btn_clear">
                    {{'ehp-user.renew'|translate}}
                </button>
            <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
                <span class="icon-ic-close"></span>
            </button>
        </div>
    </div>

    <div class="info-header" *ngIf="sliderFormData[0].edit">
        <div class="d-flex flex-column">
            <span class="hed">{{'ehp-user.userid'|translate}}</span>
            <span class="bod">d4dbf0f6-7f8f-47f0-8c5a-0f74b796bc81</span>
        </div>
        <div class="d-flex flex-column">
            <span class="hed">{{'ehp-user.email'|translate}}</span>
            <span class="bod">d4dbf0f6-7f8f-47f0-8c5a-0f74b796bc81</span>
        </div>
    </div>

    <form [formGroup]="EhpuserForm" #formDirective="ngForm" class="input-min-spacing">
        <div class="createAccountwrapper">
            <mat-form-field def="Status" *ngIf="sliderFormData[0].edit">
                <mat-label>{{'ehp-user.status'|translate}}</mat-label>
                <mat-select placeholder="All" formControlName="status" required="true" name="status">
                    <mat-option [value]="true">{{'establishment-list.active'|translate}}</mat-option>
                    <mat-option [value]="false">{{'establishment-list.suspended'|translate}}</mat-option>
                </mat-select>
            </mat-form-field>        
        </div>

        <div class="createAccountwrapper">
            <mat-form-field>
                <mat-label class="label-float">{{'add-user.first-name' | translate}}</mat-label>
                <input matInput formControlName="FirstName" maxlength="50" autocomplete="off" required>
                <mat-error
                    *ngIf="!EhpuserForm.get('FirstName')?.hasError('invalidChars') && EhpuserForm.get('FirstName')?.invalid">{{'createAccount.valid-first-name'
                    | translate}}</mat-error>
                <mat-error
                    *ngIf="EhpuserForm.get('FirstName')?.hasError('invalidChars') && EhpuserForm.get('FirstName')?.invalid">
                    {{'createAccount.invalid-char'| translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label class=" label-float">{{'add-user.family-name' | translate}}</mat-label>
                <input matInput formControlName="FamilyName" maxlength="50" autocomplete="off" required>
                <mat-error
                    *ngIf="!EhpuserForm.get('FamilyName')?.hasError('invalidChars') && EhpuserForm.get('FamilyName')?.invalid">{{'createAccount.valid-first-name'
                    | translate}}</mat-error>
                <mat-error
                    *ngIf="EhpuserForm.get('FamilyName')?.hasError('invalidChars') && EhpuserForm.get('FamilyName')?.invalid">
                    {{'createAccount.invalid-char'| translate}}
                </mat-error>
            </mat-form-field>        
        </div>

        <div class="createAccountwrapper">
            <mat-form-field *ngIf="!sliderFormData[0].edit">
                <mat-label class="label-float">{{'add-user.email' | translate}}</mat-label>
                <input matInput type="email" maxlength="60" formControlName="Email" autocomplete="off" required>
            </mat-form-field>

            <mat-form-field floatLabel="always" class="phone-ctrl">
                <mat-label>{{'createAccount.phone' | translate}}</mat-label>
                <ngx-mat-intl-tel-input [preferredCountries]="['fr']" [enablePlaceholder]="false" name="phone" #phone
                    [formControl]="phoneNumber">
                </ngx-mat-intl-tel-input>
                <mat-error *ngIf="phone?.errors?.required">{{'createAccount.valid-phone' | translate}}</mat-error>
                <mat-error *ngIf="phone?.errors?.validatePhoneNumber">{{'createAccount.valid-phone' | translate}}
                </mat-error>
            </mat-form-field>        
        </div>

        <div class="createAccountwrapper">
            <mat-form-field def="Role">
                <mat-label>{{'ehp-user.role'|translate}}</mat-label>
                <mat-select placeholder="EHP User" formControlName="status" required="true" name="status">
                    <mat-option [value]="true">{{'establishment-list.active'|translate}}</mat-option>
                    <mat-option [value]="false">{{'establishment-list.suspended'|translate}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field def="HCP">
                <mat-label class="label-float" appearance="none">{{'ehp-user.hcp'|translate}}</mat-label>
                <mat-select formControlName="HCP" [disableOptionCentering]="true">
                    <mat-option *ngFor="let hcp of healthcareprofs" [value]="hcp.id">{{hcp?.external_code}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Esatblishments -->
            <mat-form-field class="full-width">
                <mat-label class="label-float" appearance="none">
                  {{ 'ehp-user.establishment' | translate }}
                </mat-label>
                
                <mat-select formControlName="Establishment" [disableOptionCentering]="true" multiple>
                    <mat-select-trigger>
                        <ng-container *ngIf="(EhpuserForm.get('Establishment')?.value || [])?.length > 0; else placeholder">
                          <span class="selected-chip" 
                                *ngFor="let EstablishmentId of (EhpuserForm.get('Establishment')?.value || []).slice(0, 2)">
                            {{ getEstablishName(EstablishmentId) }}
                          </span>
                          <span *ngIf="(EhpuserForm.get('Establishment')?.value || []).length > 2" 
                            class="more-text" 
                            [matMenuTriggerFor]="menu" 
                            (click)="$event.stopPropagation()">
                            +{{ (EhpuserForm.get('Establishment')?.value || []).length - 2 }} more
                          </span>
                        </ng-container>
                        <ng-template #placeholder>Select Establishments</ng-template>
                    </mat-select-trigger>

                    <mat-option *ngFor="let establishment of establishments" [value]="establishment.id">
                      {{ establishment.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-menu #menu="matMenu" class="establish-popover">
                <div class="arrow-top"></div>
                <ng-container *ngFor="let EstablishmentId of (EhpuserForm.get('Establishment')?.value || []).slice(2)">
                    <button mat-menu-item>{{ getEstablishName(EstablishmentId) }}</button>
                </ng-container>
            </mat-menu>

            <!-- Departments -->
            <mat-form-field class="full-width">
                <mat-label class="label-float" appearance="none">
                  {{ 'establishment-list.department' | translate }}
                </mat-label>
                
                <mat-select formControlName="Dept" [disableOptionCentering]="true" multiple>
                    <mat-select-trigger>
                        <ng-container *ngIf="(EhpuserForm.get('Dept')?.value || [])?.length > 0; else placeholder">
                          <span class="selected-chip" 
                                *ngFor="let deptId of (EhpuserForm.get('Dept')?.value || []).slice(0, 2)">
                            {{ getDepartmentName(deptId) }}
                          </span>
                          <span *ngIf="(EhpuserForm.get('Dept')?.value || []).length > 2" 
                            class="more-text" 
                            [matMenuTriggerFor]="menu" 
                            (click)="$event.stopPropagation()">
                            +{{ (EhpuserForm.get('Dept')?.value || []).length - 2 }} more
                          </span>
                        </ng-container>
                        <ng-template #placeholder>Select Departments</ng-template>
                    </mat-select-trigger>

                    <mat-option *ngFor="let dept of departments" [value]="dept.id">
                      {{ dept.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-menu #menu="matMenu" class="establish-popover">
                <div class="arrow-top"></div>
                <ng-container *ngFor="let deptId of (EhpuserForm.get('Dept')?.value || []).slice(2)">
                    <button mat-menu-item>{{ getDepartmentName(deptId) }}</button>
                </ng-container>
            </mat-menu>

            <app-datepicker id="openFrom" labelText="{{'add-sampling.open-from' | translate}}" [dateInput]="openFrom"
                isRequired="true" [minDate]="fromDateMin" (selectedDate)="onSelectedDate($event)">
            </app-datepicker>

            <app-datepicker id="openTo" labelText="{{'add-sampling.open-to' | translate}}" [dateInput]="openTo" isRequired="true"
                [minDate]="toDateMin">
            </app-datepicker>


        </div>

        <div class="actions">
            <div class="inner-btn">
                <button *ngIf="!sliderFormData[0].edit"
                mat-button color="accent" class="ghost button_width mr-20" type="button" (click)="reset()"
                    id="btn_clear">
                    {{'add-establish.clear'|translate}}
                </button>
                <button *ngIf="sliderFormData[0].edit"
                mat-button color="accent" class="ghost button_width mr-20" type="button" (click)="dismiss()"
                    id="btn_clear">
                    {{'ehp-user.cancel'|translate}}
                </button>
                <button mat-raised-button color="primary" class="button_width" type="submit" id="btn_save"
                    (click)="onSave()">
                    {{'add-establish.save'|translate}}
                </button>                
            </div>
        </div>
    </form>
</div>