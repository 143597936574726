import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingsService, GlobalRepositoryService } from '../../core';
import { ApplicationInformation } from '../models/application-information.model';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

    /* #region Fields */
    public applicationInformation: ApplicationInformation = new ApplicationInformation();
    public applicationFullName: string;
    public applicationClientVersion: string;
    /* #endregion */

    constructor(private readonly dialogRef: MatDialogRef<AboutUsComponent>,
        private readonly translateService: TranslateService,
        private readonly globalRepositoryService: GlobalRepositoryService,
        private readonly appSettingsService: AppSettingsService,
        @Inject(MAT_DIALOG_DATA) public data: string) { }

    /* #region Events and Methods */
    public ngOnInit(): void {
        this.applicationClientVersion = this.appSettingsService.version;
        this.applicationFullName = this.translateService.instant(`Common.Application.${this.globalRepositoryService.appShortName}`);
        this.applicationInformation.EnvironmentName = this.globalRepositoryService.appEnvironmentName;
        this.applicationInformation.ApplicationShortName = this.globalRepositoryService.appShortName;
        this.applicationInformation.Version = this.globalRepositoryService.appServerVersion;
        this.applicationInformation.LabSiteName = this.globalRepositoryService.appLabSiteName;
    }

    /** This method is to close the about-us pop up */
    public dismiss(): void {
        this.dialogRef.close(null);
    }
    /* #endregion */

}
