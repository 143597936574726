import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LocaleService } from '../../../app.locale.service';
import { LoaderService } from '../../../main';
import { CommonApiService } from '../../services/common-api.service';
import { PatientAppointmentService } from '../../services/patient-appointment.service';
import { PatientService } from '../../services/patient.service';
import { PatientDocument } from '../documents/patient-document.model';
import { ResultStatus } from '../results/results.model';
import { DocumentPopupComponent } from '../document-popup/document-popup.component';
import { RedirectDialogComponent } from "../results/redirect-dialog/redirect-dialog.component";
import { SpinnerService } from "../../../core/spinner/spinner.service";
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

export interface PeriodicElement {
  test: Object;
  lab: Object;
  dateTime: string;
  status: Array<{ title: string; status: string }>;
  resultLink: string;
  resultAccessCode: string;
  resultAvailable: boolean;
  kitResult: string;
}
export interface results {
  test: Object;
  lab: Object;
  dateTime: string;
  status: Array<{ title: string; status: string }>;
}

export interface documents {
  id: Number;
  patient_id: string;
  document_type: string;
  path: string;
  expiry: boolean,
  status: string;
}

const UPLOAD_DATA: documents[] = [];

const RESULTS_DATA: results[] = [
  {
    test: { title: 'COV2PCRPFL', subText: 'COVID 19 | PCR' },
    lab: { title: 'My Fav City lab 2' },
    dateTime: '11/06/2020',
    status: [
      { title: 'results.recieved', status: 'Completed' },
      { title: 'results.in-progress', status: 'Completed' },
      { title: 'results.analysis-validation', status: 'Completed' },
      { title: 'results.result-available', status: 'Completed' }
    ]
  },
  {
    test: { title: 'COV2PCRPFL', subText: 'COVID 19 | PCR' },
    lab: { title: 'My Fav City lab 2' },
    dateTime: '11/06/2020',
    status: [
      { title: 'results.recieved', status: 'Completed' },
      { title: 'results.in-progress', status: 'InProgress' },
      { title: 'results.analysis-validation', status: 'InProgress' },
      { title: 'results.result-available', status: 'InProgress' }
    ]
  }
];

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  displayedColumns: string[] = ['test', 'lab', 'dateTime', 'status', 'actions'];
  allResultsdataSource: any = [];
  dataSource = RESULTS_DATA;
  dataSource1: any;
  dataSourceFuture: any;
  count = 0;
  ELEMENT_DATA: any[] = [];
  avilableDocumentTypes: any[] = [];
  allDocumentTypes: any[] = [];
  typeOfDoc: any;
  documentIds: any[] = [];
  watitingResultsdataSource: any = [];
  readyResultsdataSource: any = [];
  refresh: boolean;
  resultDataLoaded: boolean;
  uploadedDocs: any[] = [];
  futureAppointment: any;
  availableAppointment = false;
  displayedColumnsUpload: string[] = ['name', 'status', 'actions'];
  dataSourceDocument = UPLOAD_DATA;
  patientId = '';
  private loginId: number;
  row_data: any;
  sub: any;
  name: any;
  qr_id: any;
  resultStatusList: ResultStatus[];
  FAVORAITE_LAB_DATA: any[] = [];
  pdfBlob: any;
  isPdfViewerOpened: boolean;
  resultId: any;
  isMobileView: boolean = false;
  optionalMutualCertificateIdentifier = "Mutual certificate(";

  constructor(
    private translate: TranslateService,
    private localeService: LocaleService,
    public loader: LoaderService,
    private patientService: PatientService,
    private patientAppointmentService: PatientAppointmentService,
    private commonAPi: CommonApiService,
    private readonly spinner: SpinnerService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
    this.ELEMENT_DATA = new Array<PatientDocument>();
  }

  ngOnInit(): void {
    this.getMasterResultStatusList();
    this.patientId = JSON.parse(sessionStorage.getItem('userDetails')).id;
    this.loginId = JSON.parse(sessionStorage.getItem("userDetails")).login_id;
    this.loadDocumentTypes();
    this.getPatientfavorite();
    this.name =
      JSON.parse(sessionStorage.getItem('userDetails')).first_name +
      ' ' +
      JSON.parse(sessionStorage.getItem('userDetails')).last_name +
      ' ' +
      JSON.parse(sessionStorage.getItem('userDetails')).birth_name;
    this.qr_id = JSON.parse(sessionStorage.getItem('userDetails')).qr_id;
    this.patientAppointmentService
      .getAppointmentDetails(
        JSON.parse(sessionStorage.getItem('userDetails')).id,
        true,
        0,
        true
      )
      .subscribe(
        (appointmentDetails: any) => {
          appointmentDetails = appointmentDetails?.filter
            ((d: any) => d.IsDeleted == false)
          if (
            appointmentDetails != null && appointmentDetails.length != 0 &&
            !this.checkDate(appointmentDetails[0]?.SlotTime)
          ) {
            this.availableAppointment = true;
            this.futureAppointment = appointmentDetails[0];
          } else {
            this.availableAppointment = false;
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobileView = result.matches;
      });
  }
  checkDate(element: any) {
    const st = new Date(element.SlotTime);
    if (st.getHours() > 0 || st.getMinutes() > 0) {
      return moment(element.SlotTime)
        .startOf('second')
        .isBefore(moment().startOf('second'));
    } else {
      return moment(element.SlotTime)
        .startOf('date')
        .isBefore(moment().startOf('date'));
    }

  }
  getMasterResultStatusList() {
    // Getting Result Status List
    this.commonAPi.getResultStatusList().subscribe((resultStatus) => {
      resultStatus.sort((a, b) => a.KeyOrder - b.KeyOrder);
      this.resultStatusList = resultStatus;
      this.getAppointment();
    });
  }

  private loadDocumentTypes() {
    this.dataSourceDocument = [];
    this.patientService.getRequiredDocumentByPatientId(this.patientId, this.loginId, true).subscribe((requiredDocuments: Array<any>) => {
      if (!requiredDocuments['documents']) {
        this.patientService
          .getPatientDocuments(this.patientId)
          .subscribe((documents: any) => {
            documents = documents?.filter
              ((d: any) => d.document_type_en.indexOf(this.optionalMutualCertificateIdentifier) < 0)
            documents.forEach((item: any, index: number) => {
              this.dataSourceDocument.push({
                id: item.id,
                patient_id: this.patientId,
                document_type: item.document_type,
                path: item.document_path,
                expiry: false,
                status: 'true'
              });
              if (index === documents.length - 1) {
                this.dataSourceDocument.sort((a: any, b: any) =>
                  this.translate
                    .instant(a.document_type)
                    .localeCompare(this.translate.instant(b.document_type))
                );
                this.dataSourceDocument = this.dataSourceDocument.slice(0, 2);
              }
            });
          });
        this.uploadDocsIfNotUploaded();
      } else {
        requiredDocuments['documents'] = requiredDocuments['documents'].filter
          ((d: any) => d.eng_key_name.indexOf(this.optionalMutualCertificateIdentifier) < 0)
        requiredDocuments['documents'].forEach((item: any) => {
          this.dataSourceDocument.push({
            id: item.id,
            patient_id: this.patientId,
            document_type: item.key_name,
            path: '',
            expiry: item.expiry,
            status: 'false'
          });
        });
        this.patientService
          .getPatientDocuments(this.patientId)
          .subscribe((documents: any) => {
            documents = documents?.filter
              ((d: any) => d.document_type_en.indexOf(this.optionalMutualCertificateIdentifier) < 0)
            documents?.forEach((item: any, index: number) => {
              this.dataSourceDocument.push({
                id: item.id,
                patient_id: this.patientId,
                document_type: item.document_type,
                path: item.document_path,
                expiry: false,
                status: 'true'
              });
              if (index === documents.length - 1) {
                this.dataSourceDocument.sort((a: any, b: any) =>
                  this.translate
                    .instant(a.document_type)
                    .localeCompare(this.translate.instant(b.document_type))
                );
                this.dataSourceDocument = this.dataSourceDocument.slice(0, 2);
                this.typeOfDoc = this.dataSourceDocument.filter(
                  (doc) => doc.status == 'false'
                );
                this.uploadDocsIfNotUploaded();
              }
            });
            if (!documents) {
              this.dataSourceDocument.sort((a: any, b: any) =>
                this.translate
                  .instant(a.document_type)
                  .localeCompare(this.translate.instant(b.document_type))
              );
              this.dataSourceDocument = this.dataSourceDocument.slice(0, 2);
              this.typeOfDoc = this.dataSourceDocument.filter(
                (doc) => doc.status == 'false'
              );
              this.uploadDocsIfNotUploaded();
            }
          });
      }
    });
  }
  openDirections(station: any) {
    event.stopPropagation();
    const lat = Number(
      station.Coordinates.substring(
        station.Coordinates.indexOf(":") + 1,
        station.Coordinates.indexOf(",")
      )
    ).toString();
    const lng = Number(
      station.Coordinates.substring(
        station.Coordinates.lastIndexOf(":") + 1,
        station.Coordinates.indexOf("}")
      )
    ).toString();
    window.open(
      "https://www.google.com/maps/dir/?api=1&destination=" +
      lat +
      "," +
      lng +
      "&travelmode=driving",
      "_blank"
    );
  }
  openDirectionss(station: any) {
    event.stopPropagation();
    const lat = Number(
      station.coordinates.substring(
        station.coordinates.indexOf(":") + 1,
        station.coordinates.indexOf(",")
      )
    ).toString();
    const lng = Number(
      station.coordinates.substring(
        station.coordinates.lastIndexOf(":") + 1,
        station.coordinates.indexOf("}")
      )
    ).toString();
    window.open(
      "https://www.google.com/maps/dir/?api=1&destination=" +
      lat +
      "," +
      lng +
      "&travelmode=driving",
      "_blank"
    );
  }

  public uploadPrecription(details: any) {
    this.row_data = {
      row_data: details
    };
    this.loader.highlightLeftMenu(['patient/my-appointment'], {
      state: { prescription: this.row_data }
    });
  }

  redirectBooking() {
    this.loader.highlightLeftMenu(['patient/book-appointment']);
  }

  documentViewAll(element?: any, documentTypes?: any) {
    if (element?.status == 'false') {
      this.loader.highlightLeftMenu(['patient/documents'], {
        state: { document: element, doctype: documentTypes }
      });
    }
    if (!element) {
      this.loader.highlightLeftMenu(['patient/documents']);
    }
  }
  appointmentViewAll() {
    this.loader.highlightLeftMenu(['patient/my-appointment']);
  }
  labViewAll() {
    this.loader.highlightLeftMenu(['patient/favourite-lab']);
  }
  resultsViewAll() {
    this.loader.highlightLeftMenu(['patient/results']);
  }
  public getPatientfavorite() {
    this.refresh = false;
    this.patientService
      .getsavePatientFavoriteSamplingStations(this.patientId)
      .subscribe((documents: any) => {
        if (documents != null) {
          this.FAVORAITE_LAB_DATA = documents;
        } else {
          this.FAVORAITE_LAB_DATA = [];
        }

        if (this.FAVORAITE_LAB_DATA.length > 2) {
          this.FAVORAITE_LAB_DATA = this.FAVORAITE_LAB_DATA.splice(0, 2);
        }

        this.refresh = true;
      });
  }
  viewDocument(element: any) {
    this.loader.highlightLeftMenu(['patient/documents'], {
      state: { path: element }
    });
  }
  downloadDocument(element: any) {
    this.patientService.getPatientDocument(element.substring(element.lastIndexOf('/') + 1)).subscribe((res: any) => {
      const byteArray = new Uint8Array(
        atob(res)
          .split('')
          .map((char) => char.charCodeAt(0))
      );
      const file = new Blob([byteArray], { type: 'application/pdf' });
      const url = URL.createObjectURL(file);
      this.downloadPres(url);
    });
  }
  downloadPres(blobUrl: any) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = blobUrl;
    a.download = 'Documents' + '.pdf';
    a.click();
    window.URL.revokeObjectURL(blobUrl);
    a.remove();
  }
  getDirection(lab: any) {
    this.loader.highlightLeftMenu(['patient/favourite-lab'], {
      state: { direction: lab }
    });
  }
  getAppointment() {
    this.resultDataLoaded = false;
    this.patientAppointmentService
      .getAppointmentDetails(
        JSON.parse(sessionStorage.getItem('userDetails')).id,
        false,
        1, false
      )
      .subscribe((appointmentDetails: any) => {
        const allData: PeriodicElement[] = [];
        if (appointmentDetails) {
          for (const item of appointmentDetails) {
            const resultStatus = this.checkifResultIsAvailable(item);

            const statusData: any = this.getStatusArray(item.StatusId);
            var test: any;
            test = {
              test: {
                title: item.TestShortName,
                subText: this.loader.TranslateLang(item.TestType)
              },
              lab: {
                title: item.SamplingStationName,
                subText: item.AddressDetails,
              },
              dateTime: item.SlotTime,
              status: statusData,
              resultLink: item.TestResult,
              resultAccessCode: item.InternetAccessId,
              resultAvailable: resultStatus,
              kitResult: item.KitResult,
              DiagnosisReportUrl: item.DiagnosisReportUrl,
              Barcode: item.Barcode
            };
            allData.push(test);

            if (item.StatusId !== this.resultStatusList[3].Id) {
              this.watitingResultsdataSource.push(test);
            }

            if (item.StatusId === this.resultStatusList[3].Id) {
              this.readyResultsdataSource.push(test);
            }
          }
        }

        this.allResultsdataSource = allData;
        this.resultDataLoaded = true;
      });
  }
  showResultData(entity: any) {
    if (!entity.resultAvailable) return;
    if (entity.DiagnosisReportUrl != "") {
      this.openPdf(entity);
    }
    else {
      const dialogRef = this.dialog.open(RedirectDialogComponent, {
        width: "600px",
        height: "500px",
        disableClose: true,
        data: { title: this.translate.instant("history.Result available"), result: entity, isFromPatient: true },
      });
      dialogRef.afterClosed().subscribe(() => {
      });
    }
  }
  openPdf(selectedRowData: any) {
    this.spinner.show(true);
    var resultCertificateName = selectedRowData.DiagnosisReportUrl.substring(selectedRowData.DiagnosisReportUrl.lastIndexOf("/") + 1);
    this.patientAppointmentService
      .getResultCertificate(resultCertificateName)
      .subscribe((res: any) => {
        const byteArray = new Uint8Array(
          atob(res)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        const file = new Blob([byteArray], { type: "application/octet-stream" });
        this.pdfBlob = URL.createObjectURL(file);
        this.spinner.show(false);
        this.resultId = selectedRowData.Barcode;
        this.isPdfViewerOpened = true;
      });
  }
  checkifResultIsAvailable(item: any) {
    if (
      item.StatusId < this.resultStatusList[this.resultStatusList.length - 1].Id
    ) {
      return false;
    }

    if (item.TestResult === undefined) {
      return false;
    }

    if (item.InternetAccessId === undefined) {
      return false;
    }

    if (item.DiagnosisReportUrl != "" || (item.TestResult.lenght !== 0 && item.InternetAccessId.lenght !== 0)) {
      return true;
    }

    return false;
  }
  getStatusArray(statusId: number) {
    if (this.resultStatusList[0].Id === statusId) {
      return [
        { title: 'results.recieved', status: 'Completed' },
        { title: 'results.in-progress', status: 'InProgress' },
        { title: 'results.analysis-validation', status: 'InProgress' },
        { title: 'results.result-available', status: 'InProgress' }
      ];
    } else if (this.resultStatusList[1].Id === statusId) {
      return [
        { title: 'results.recieved', status: 'Completed' },
        { title: 'results.in-progress', status: 'Completed' },
        { title: 'results.analysis-validation', status: 'InProgress' },
        { title: 'results.result-available', status: 'InProgress' }
      ];
    } else if (this.resultStatusList[2].Id === statusId) {
      return [
        { title: 'results.recieved', status: 'Completed' },
        { title: 'results.in-progress', status: 'Completed' },
        { title: 'results.analysis-validation', status: 'Completed' },
        { title: 'results.result-available', status: 'InProgress' }
      ];
    } else if (this.resultStatusList[3].Id === statusId) {
      return [
        { title: 'results.recieved', status: 'Completed' },
        { title: 'results.in-progress', status: 'Completed' },
        { title: 'results.analysis-validation', status: 'Completed' },
        { title: 'results.result-available', status: 'Completed' }
      ];
    } else {
      return [
        { title: 'results.recieved', status: 'InProgress' },
        { title: 'results.in-progress', status: 'InProgress' },
        { title: 'results.analysis-validation', status: 'InProgress' },
        { title: 'results.result-available', status: 'InProgress' }
      ];
    }
  }
  private uploadDocsIfNotUploaded() {
    if (this.loader.docReq && !sessionStorage.getItem('popupShown')) {
      const dialogRef = this.dialog.open(DocumentPopupComponent);
      dialogRef.afterClosed().subscribe(() => {
      });
    }
    sessionStorage.setItem('popupShown', 'true');
  }
  redirectFindALab() {
    this.loader.isPreLoginFLow = true;
    this.loader.highlightLeftMenu(['patient/book-appointment/0']);
  }
}
