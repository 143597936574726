import { Injectable } from '@angular/core';
import { LocalStorageCacheService } from './local-storage-cache.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    /* #region Fields */
    private currentLanguage = { Name: 'en', Locale: 'en-150' };
    /* #endregion */

    constructor(private readonly localeCache: LocalStorageCacheService) {
        this.setLanguage();
    }

    /* #region Public Methods */
    /** Method to get current language */
    public getLanguage(): any {
        return this.currentLanguage;
    }
    /* #endregion */

    /* #region Private Methods */
    /** Method to set current language based on browser url */
    private setLanguage(): void {
        const path = window.location.pathname;
        const locale = path.slice(0, path.lastIndexOf('/')).split('/').pop();
        const lang = locale.split('-')[0].toLowerCase();
        if (lang != null && lang.length === 2) {
            const language = { Name: lang, Locale: locale };
            this.localeCache.setItem('current-language', language).subscribe(() => { });
            this.currentLanguage = language;
        }
    }
    /* #endregion */
}
