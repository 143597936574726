import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import 'moment/locale/fr';
import { LocaleService } from '../../app.locale.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD-MM-YYYY'
                },
                display: {
                    dateInput: 'DD-MM-YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                }
            }
        }
    ]
})
export class DatepickerComponent implements OnInit, OnChanges {
    @Input() labelText = '';
    @Input() elementClass = '';
    @Input() isDisabled = false;
    @Input() isRequired = false;
    @Input() isReadOnly = false;
    @Input() slotTime:any;
    @Input() dateInput: FormControl;
    @Input() elementId?: string = '';
    @Input() isRequiredError = false;
    dateValue: FormControl;
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() maxDateViolationMessage = '';
    @Input() minDateViolationMessage = '';

    @Output() selectedDate = new EventEmitter<Date>();

    @Input() disabledDates: any[] = [];
    @Input() holidayList: any[] = [];
    myFilter = (d: Date): boolean => {
        const date = d || new Date();
        const day = new Date(d);
        const formattedDate = this.datepipe.transform(date, 'dd-MM-yyyy');
        if (this.holidayList.some((x) => x === formattedDate)) {
            return false;
        } else if (this.disabledDates.some((x) => x === day.getDay())) {
            return false;
        } else {
            return true;
        }
    };

    constructor(
        private dateAdapter: DateAdapter<Date>,
        private changeDetection: ChangeDetectorRef,
        private localeService: LocaleService,
        private translate: TranslateService,
        private datepipe: DatePipe
    ) {
        this.dateAdapter.setLocale(this.localeService.localeLang);
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    ngOnInit(): void {
        this.dateValue = this.dateInput;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            !!changes &&
      !!changes.minDate &&
      !!changes.minDate.currentValue &&
      !changes.minDate.firstChange
        ) {
            this.changeDetection.detectChanges();
        }
    }

    changeDateLocale() {
        this.dateAdapter.setLocale('pt');
    }

    onDateValueChange(event: MatDatepickerInputEvent<Moment>) {
        this.dateValue.setValue(event.value?.toDate());
        this.selectedDate.emit(event.value?.toDate());
    }

    onDateEditChange(event: MatDatepickerInputEvent<Moment>) {
        this.selectedDate.emit(event.value?.toDate());
    }
}
