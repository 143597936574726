<ngx-scrolltop [size]="50" backgroundColor="#0197F4" symbolColor="#fff">
    <img src="../../../assets/images/landing-page/ic-up-btn.svg">
</ngx-scrolltop>
<div id="landingPageContainer">
    <div class="eClinicalDescription mainLayout">
        <div class="videoContainer">
            <iframe data-ot-ignore width="100%" height="auto" [src]="videoUrl"
                class="optanon-category-C0004 ot-vscat-C0004 borderR-12 player-height" allowfullscreen>
            </iframe>
        </div>
        <div class="textContainer">
            <span class="banner-title" [innerHtml]="heroBanner[localeService.localeLang]['title']"> </span>
            <p class="banner-desc">{{heroBanner[localeService.localeLang]['sub_title']}}</p>
            <button mat-button color="accent" class="ghost btn-chk"
                (click)="redirectBooking()">{{'landing-page.check-in'
                |
                translate}} <span class="btn-icon icon-ic-right"></span></button>
        </div>
    </div>

    <div class="patientJourney ">
        <div class="mainLayout">
            <!-- {{patientJourney[localeService.localeLang]['steps'][0].image_url}} -->
            <span class="shortBorder" [innerHtml]="patientJourney[localeService.localeLang]['section_title']"></span>
            <div class="journey-card-container">
                <div class="journey-card"
                    *ngFor="let step of patientJourney[localeService.localeLang]['steps'];let i=index">
                    <!-- <img src="../../../assets/images/application-logos/svg/c{{i+1}}.svg"> -->
                    <img [src]="step.image_url+'?v='+randomNumber">
                    <div class="textDesc" [innerHtml]="step.desc">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="document-info ">
        <div class="mainLayout">
            <span class="header" innerHtml="{{'landing-page.doc-info.doc-header' | translate}}"></span>
            <div class="document-card-container">
                <div class="document-card">
                    <img src="../../../assets/images/landing-page/ic-prescription.svg">
                    <div class="text-wrapper">
                        <div class="textTitle">
                            {{'landing-page.doc-info.doc1-title' | translate}}
                        </div>
                        <div class="textDesc">
                            {{'landing-page.doc-info.doc1-desc' | translate}}
                        </div>
                    </div>
                </div>
                <div class="document-card">
                    <img src="../../../assets/images/landing-page/ic-id.svg">
                    <div class="text-wrapper">
                        <div class="textTitle">
                            {{'landing-page.doc-info.doc2-title' | translate}}
                        </div>
                        <div class="textDesc">
                            {{'landing-page.doc-info.doc2-desc' | translate}}
                        </div>
                    </div>
                </div>
                <div class="document-card">
                    <img src="../../../assets/images/landing-page/ic-mutual.svg">
                    <div class="text-wrapper">
                        <div class="textTitle">
                            {{'landing-page.doc-info.doc3-title' | translate}}
                        </div>
                        <div class="textDesc">
                            {{'landing-page.doc-info.doc3-desc' | translate}}
                        </div>
                    </div>
                </div>
            </div>
            <button mat-button class="custom-btn" (click)="redirectBooking()">{{'landing-page.check-in' | translate}}
            </button>
        </div>
    </div>

    <div class="rmContainer">
        <div class="mainLayout">
            <span class="header only-mobile"
                [innerHtml]="companyInfo[localeService.localeLang]['section_title']"></span>
            <div class="rm-section-wrapper">
                <div class="imageContainer">
                    <img src="../../../assets/images/landing-page/ic-rm.svg">
                </div>
                <div class="rm-content-wrapper">
                    <span class="header only-web"
                        [innerHtml]="companyInfo[localeService.localeLang]['section_title']"></span>
                    <div class="textTitle"> {{companyInfo[localeService.localeLang]['website_link_field_name']}}</div>
                    <div class="featureDesc"><span
                            class="icon-ic-tick_circle_filled "></span>{{companyInfo[localeService.localeLang]['field_one_name']}}
                    </div>
                    <div class="featureDesc"><span
                            class="icon-ic-tick_circle_filled"></span>{{companyInfo[localeService.localeLang]['field_two_name']}}
                    </div>
                    <div class="featureDesc"><span
                            class="icon-ic-tick_circle_filled"></span>{{companyInfo[localeService.localeLang]['field_three_name']}}
                    </div>
                    <div class="btn-wrapper">
                        <button mat-button class="custom-btn"
                            (click)="redirectGuestRelative()">{{'landing-page.rm-info.btn-text' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="faq-info ">
        <div class="mainLayout">
            <span class="header" innerHtml="{{'landing-page.faq.header' | translate}}"></span>
            <div class="faq-container">
                <div class="accordion-wrapper">

                    <mat-accordion class="example-headers-align">
                        <mat-expansion-panel [expanded]="faqIndex==0" class="panel-container" (click)="clickFaq(0)">
                            <mat-expansion-panel-header class="acc-panel-header" id="pHeader1">
                                <mat-panel-title class="panel-title" id="pTitle1">
                                    {{'landing-page.faq.question1' | translate}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="faq-answer">
                                <p> {{'landing-page.faq.answer1.p1' | translate}}
                                <p>
                                <p>{{'landing-page.faq.answer1.p2' | translate}}</p>
                                <p>{{'landing-page.faq.answer1.p3' | translate}} </p>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <mat-accordion class="example-headers-align">
                        <mat-expansion-panel [expanded]="faqIndex==1" class="panel-container" (click)="clickFaq(1)">
                            <mat-expansion-panel-header class="acc-panel-header" id="pHeader2">
                                <mat-panel-title class="panel-title" id="pTitle2">
                                    {{'landing-page.faq.question2' | translate}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="faq-answer">
                                <p>{{'landing-page.faq.answer2.p1' | translate}}
                                <ul>
                                    <li innerHtml="{{'landing-page.faq.answer2.li1' | translate}}"> </li>
                                    <li innerHtml="{{'landing-page.faq.answer2.li2' | translate}}"> </li>
                                    <li innerHtml="{{'landing-page.faq.answer2.li3' | translate}}"> </li>
                                    <li innerHtml="{{'landing-page.faq.answer2.li4' | translate}}"> </li>

                                </ul>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion class="example-headers-align">
                        <mat-expansion-panel class="panel-container" [expanded]="faqIndex==2" (click)="clickFaq(2)">
                            <mat-expansion-panel-header class="acc-panel-header" id="pHeader3">
                                <mat-panel-title class="panel-title" id="pTitle3">
                                    {{'landing-page.faq.question3' | translate}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="faq-answer">
                                <p> {{'landing-page.faq.answer3.p1' | translate}}
                                <p>
                                <p>{{'landing-page.faq.answer3.p2' | translate}}</p>
                                <p>{{'landing-page.faq.answer3.p3' | translate}} </p>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
            <button mat-button class="custom-btn" (click)="redirectFAQ()">{{'landing-page.faq.btn-text' |
                translate}}
            </button>
        </div>
    </div>
    <div class="checkout">
        <span>{{'landing-page.faq.learn-more' | translate}}</span>
        <span><a href="https://www.eurofins-biologie-medicale.com/fr/">{{'landing-page.faq.check-website' | translate}}</a></span>
    </div>

</div>