<div class="forgot-password-container">
    <h3 class="desc-title">
        <circle-progress class="only-web" [options]="loader.optionsG" [percent]="percent" [renderOnClick]="false"
            imageSrc="assets/images/application-logos/svg/ic-suser.svg" class="copy">
        </circle-progress> {{'login-prebook.title'|translate}}
    </h3>
    <div class="reqnumber-section">
        <div class="prebook-container">
            <ng-container *ngIf="forgotPassword">
                <div class="outer-container">
                    <div id="forgetPassword_container" class="form-inner-wrapper" [formGroup]="sendEmailForm">
                        <h4>{{'login.trouble' | translate}}</h4>
                        <span>{{'login.enter-mail' | translate}}</span>
                        <mat-form-field class="mb-20 mt-20" class="RegisteredEmail">
                            <mat-label class="label-float">{{'login.mail' | translate}}</mat-label>
                            <input matInput class="email-input" name="RegisteredEmail" #RegisteredEmail
                                [isRequired]="true" (keyup)="isValid()" [formControl]="registeredEmail" />
                            <mat-error *ngIf="registeredEmail.invalid">{{'login.valid-mail' |
                                translate}}</mat-error>
                        </mat-form-field>
                        <div *ngIf="userExistError" id="invalidUserError">{{'login.email-not-exist-error' |
                            translate}}</div>
                        <button mat-raised-button color="primary" id="sendButton" type="button" class="mb-20"
                            (click)="onSend($event)">
                            {{'login.send' | translate}}
                        </button>
                        <div>{{'login.back' | translate}}<a class="linkurl"
                                (click)="openLogin.emit();showLogin=true;showRegister=false;forgotPassword=false;">{{'login.Login'
                                |
                                translate}}</a></div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="forgetPasswordMailSent">
                <div class="outer-container">
                    <div id="checkmail_container" class="view-inner-wrapper">
                        <img src="./assets/images/application-logos/svg/mail-sent-ic.svg" />
                        <h4>{{'login.check-mail' | translate}}</h4>
                        <p>
                            <span> {{'login.mail-instructions-1' | translate}}</span>
                            <span id="email">{{username}},</span>
                            <span> {{'login.mail-instructions-2' | translate}}</span>
                        </p>
                        <p>
                            {{'login.mail-instructions-3' | translate}}
                        </p>

                        <p>{{'login.note' | translate}}</p>
                        <p>
                            {{'login.no-account' | translate}}
                            <a class="linkurl"
                                (click)="forgetPasswordMailSent=false;openCreateAccount.emit();showRegister=true;forgotPassword=false;">{{'login.create-account'
                                | translate}}</a>
                        </p>
                        <p>
                            <span>{{'login.back' | translate}} <a class="linkurl"
                                    (click)="openLogin.emit();showLogin=true;forgotPassword=false;forgetPasswordMailSent=false;">{{'login.Login'
                                    |
                                    translate}}</a></span>
                        </p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>