<table class="ecl-table-result" width="100%" mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="test">
    <th mat-header-cell *matHeaderCellDef>{{'waiting-results.test'|translate}} </th>
    <td mat-cell *matCellDef="let element" class="flex-td">
      <div class="avatar onlyMobile"><span class="titleWrapBox">{{element.test?.title.charAt(0)}}</span></div>
      <span class="title onlyDesktop wb" *ngIf="element.test?.title">{{element.test?.title}}</span>
      <span class="subText onlyDesktop wb" *ngIf="element.test?.subText">{{element.test?.subText}}</span>
      <div class="mobile-td onlyMobile">
        <span class="title" *ngIf="element.test?.title">{{element.test?.title}}</span>
        <span class="subText pl-0" *ngIf="element.test?.subText">{{element.test?.subText}}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="lab">
    <th mat-header-cell *matHeaderCellDef> {{'waiting-results.lab'|translate}} </th>
    <td mat-cell *matCellDef="let element">
      <span class="title" *ngIf="element.lab?.title"><span class="icon-ic-location onlyMobile"></span><span
          class="wb">{{element.lab?.title}}</span></span>
      <span class="subText wb" *ngIf="element.lab?.subText">{{element.lab?.subText}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="dateTime">
    <th mat-header-cell *matHeaderCellDef>{{'waiting-results.date'|translate}} </th>
    <td mat-cell *matCellDef="let element">
      <span class="title"><span class="icon-icon-booking-calendar onlyMobile"></span><span>{{element.dateTime |
          dateFormat}} </span></span>
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>{{'waiting-results.status'|translate}}</th>
    <td mat-cell *matCellDef="let element">
      <app-ecl-steps [steps]="element.status"></app-ecl-steps>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="force-display">
      <div class="mobile-action" *ngIf="element.resultAvailable">
        <button mat-button color="accent" class="ghost" (click)="showResultData(element)" type="button">
          {{'results.view-report' | translate}}</button>
      </div>
      <div class="action-btn">
        <button class="mat-tbl-icon" mat-icon-button *ngIf="element.resultAvailable" (click)="showResultData(element)"
          mat-icon-button matTooltip="{{'waiting-results.view-result'|translate}}" matTooltipClass="tooltip-style">
          <span class="icon-ic-view-prescription"></span>
        </button>
      </div>
      <div [ngClass]="element?.kitResult?.toLowerCase() =='negative' ? '' : 'text-red'" class="text-green">
        {{element.kitResult}}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="noRecord">
    <td colspan="5" mat-footer-cell *matFooterCellDef>
      <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
      <div class="desc">
        <p>{{'waiting-results.empty'|translate}}</p>
        <p *ngIf="loader.userRole !=4">{{'waiting-results.search-patient'|translate}}</p>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <ng-template [ngIf]="dataSource && dataSource.length === 0">
    <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
  </ng-template>
</table>