import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import * as CryptoJS from "crypto-js";
import jwtDecode from "jwt-decode";
import { NgOtpInputConfig } from "ng-otp-input";
import { environment } from "../../../../environments/environment";
import { LocaleService } from "../../../app.locale.service";
import { AppSettingsService, SpinnerService } from "../../../core";
import { LoaderService, MessageService } from "../../../main";
import { AuthenticationService } from "../../services/authentication.service";
import {
  GetAuthLoginRequest,
  GetAuthOtpRequest,
  SaltRequest,
} from "../../services/Models/getAuthAccessTokenRequestModel";
import { ResetPasswordServiceService } from "../../services/reset-password-service.service";
import { PasswordPolicyService } from "../password-policy/password-policy.service";
import { PatientService } from "../../services/patient.service";
import { constants, constantsarr } from "../constant";
import { CookieService } from "ngx-cookie";
import { FindMyAccountComponent } from "../find-my-account/find-my-account.component";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-login-prebook",
  templateUrl: "./login-prebook.component.html",
  styleUrls: ["./login-prebook.component.scss"],
})
export class LoginPrebookComponent implements OnInit {
  readonly rconstantsarr = constantsarr;

  @Output() createAccountClicked = new EventEmitter<any>();
  @Output() forgetPasswordClicked = new EventEmitter<any>();
  @Output() goToBack = new EventEmitter<any>();
  @Output() loggedin = new EventEmitter<any>();
  @Output() registereduser = new EventEmitter<any>();
  @ViewChild("newpassword") newpassword: ElementRef;
  @Input() userName: any;
  @Input() Password: any;
  @Input() showLogin: boolean = false;
  @Input() showRegister: boolean = false;
  @Input() isConfirmLinkClicked: any;
  @Input() displayMap: boolean = true;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  @ViewChild(FindMyAccountComponent) findMyAccountComponent!: FindMyAccountComponent;
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
  };
  public step: number = 1;
  public adminRole = constants.adminRole;
  public supervisorRole = constants.supervisorRole;
  public patientRole = constants.patientRole;
  public nurseRole = constants.nurseRole;
  tokenResponse: any;
  passwordSalted: any;
  username: any;
  forgetPasswordMailSent: boolean;
  forgotPassword: boolean;
  isOpen = false;
  hideRadio = false;
  btndisabled = true;
  otp: string;
  verifyID: string;
  display: any;
  hide = true;
  token: string | undefined;
  lastLogin: string;
  automationEmail: string;
  _flow_id: string;
  deviceId: any;
  public percent: number;
  private subscription: Subscription = new Subscription();
  captchaVerified = false;
  @ViewChild("RegisteredEmail") RegisteredEmail: ElementRef;
  public registeredEmail = new FormControl("", [
    Validators.required,
    Validators.pattern(this.patientService.emailPattern),
  ]);
  public sendEmailForm = new FormGroup({
    RegisteredEmail: this.registeredEmail,
  });
  public authType = new FormControl("", [Validators.required]);
  public loginName = new FormControl("", [Validators.required]);
  public password = new FormControl("", [Validators.required]);
  public resetpassword = new FormControl("", [
    Validators.required,
    Validators.pattern(constants.passwordValidation),
  ]);
  public repeatresetpassword = new FormControl("", [Validators.required]);
  public rememberMe = new FormControl(false);
  public Loginform = this.fb.group({
    loginName: this.loginName,
    password: this.password,
    rememberMe: this.rememberMe,
    deviceDetail: ""
  });
  public forgetPasswordForm = new FormGroup({
    password: this.resetpassword,
    repeatpassword: this.repeatresetpassword,
  });
  passwordsMatch = false;
  isDisable = true;
  isAccountLocked = false;
  isAccountDeActive = false;
  isRecLogin = false;
  userEmail: any;
  isResetPasswordHide = true;
  isPasswordValid: boolean;
  public isVerified: boolean = false;
  public patientData: any;
  public activeStep: number = 1;
  public unique_id: any;
  isLoginReceptionist: boolean;
  public noRecordFound: boolean = false;
  @Output() goToConfirm = new EventEmitter<any>();
  public searchData: any;
  public bookAppointment: boolean = false;
  showQuestion: boolean = true;
  readonly constants = constants;
  public showBackBtn: boolean = true;
  public standy_test_id: string = "";
  constructor(
    private fb: FormBuilder,
    public loader: LoaderService,
    private readonly translateService: TranslateService,
    public localeService: LocaleService,
    public authenticationService: AuthenticationService,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private resetPasswordService: ResetPasswordServiceService,
    private translate: TranslateService,
    private passwordPolicy: PasswordPolicyService,
    public readonly appSettings: AppSettingsService,
    private cookieService: CookieService,
    private patientService: PatientService,
    public cdr: ChangeDetectorRef,
    public route: ActivatedRoute
  ) {
    this.translateService.setDefaultLang(this.localeService.localeLang);
    this.translateService.use(this.localeService.localeLang);
    this.token = undefined;
    this.automationEmail = environment.automationEmail
    this.standy_test_id = this.route.snapshot.params["te_id"] != undefined ? this.route.snapshot.params["te_id"] : "";
  }

  ngOnInit(): void {

    if (this.loader.flow_detail == constants.complete_booking_flow) {
      this.showQuestion = false;
      let is_active = this.loader.toBoolean(this.route.snapshot.params["is_active"]);
      if (!is_active) {
        this.isVerified = true;
        this.noRecordFound = false
      }
      //this.showLogin = true;
    }
    this.subscription.add(
      this.loader.variable$.subscribe(value => {
        if (this.standy_test_id != "") {
          if (JSON.stringify(this.loader.flow_arr) == JSON.stringify(this.rconstantsarr.inside_lab_withoutprescwithtest)) {
            this.showBackBtn = false;
          }
          else {
            this.showBackBtn = true;
          }
        }
        this.percent = (value / this.loader.flow_arr.length) * 100;
        this.cdr.detectChanges(); // Manually trigger change detection
      })
    );
    this.loader.getLoginCookie(this.Loginform, this.rememberMe);
    this.resetpassword.valueChanges.subscribe(() => {
      this.showErrorBox();
    });
    this.loader.uiMode = 1;
    this.isLoginReceptionist =
      JSON.parse(sessionStorage.getItem("access_token"))?.role == constants.nurseRole;
    if (this.isLoginReceptionist) {
      this.authType.setValue("2");
      this.isVerified = false;
    } else {
      this.authType.setValue("");
    }

    if (this.showLogin == true) {
      this.authType.setValue("1");
    }
    if (this.showRegister == true) {
      this.authType.setValue("2");
    }
    this.localeService.setCaptchalang();
    if (window.location.href.includes("patient/book-appointment") && screen.width < 962) {
      this.bookAppointment = true;
    }
  }

  ngOnchanges() {
    this.loader.uiMode = 1;
    this.authType.setValue("");
    if (this.showLogin) {
      this.authType.setValue("1");
    }
    if (this.showRegister) {
      this.authType.setValue("2");
    }
  }

  showCapchaError = false;

  openLogin() {
    this.loader.uiMode = 1;
  }
  RegisterSuccessful(response: any) {
    if (response["access_token"]) {
      const access_token = jwtDecode(response["access_token"]);
      if (this.loader.checkExpiryDate(response["password_expiry_time"])) {
        this.spinnerService.show(false);
        this.loader.passwordExpired = true;
        this.loader.uiMode = 4;
        this.loader.user_id = access_token["user_id"];
      } else {
        this.tokenResponse = response;
        this.loader.startRefreshTokenTimer(response);
        this.spinnerService.show(false);
        sessionStorage.setItem(
          "access_token",
          JSON.stringify(access_token)
        );
        sessionStorage.setItem(
          "header",
          this.tokenResponse["access_token"]
        );
        sessionStorage.setItem(
          "refresh_token",
          this.tokenResponse["refresh_token"]
        );
        this.loader.userRole = access_token["role"];
        if (access_token["role"] == constants.patientRole) {
          this.loader.loginService
            .getPatientDetails()
            .subscribe((resp: any) => {
              sessionStorage.setItem("userDetails", JSON.stringify(resp));
              this.loader.user_name =
                resp.first_name + " " + resp.birth_name;
              this.loader.dob = resp.dob;
              this.loader.loggedIn = true;
              this.loggedin.emit(true);
              this.spinnerService.show(false);
              this.loader.subjectLogin.next(true);
            });
        } else {
          this.spinnerService.show(false);
        }
      }
    }
    else {
      this.loader.bookingPatientId = response.id
      //response.isConfirmed=true;
      this.registereduser.emit(response);
    }
  }
  HideRadioVal(event: boolean) {
    this.hideRadio = event;
  }

  logIn() {
    this.Loginform.controls["loginName"].setValue(
      this.Loginform.controls["loginName"].value.toLowerCase()
    );
    this.Loginform.markAllAsTouched();
    if ((this.captchaVerified && environment.isCaptchaRequired) || environment.automationEmail.split(';').includes(this.Loginform.controls['loginName'].value.toLowerCase()) || (!environment.isCaptchaRequired)) {
      const loginRequest: GetAuthLoginRequest = new GetAuthLoginRequest();
      loginRequest.username = this.Loginform.controls.loginName.value;
      loginRequest.password = this.Loginform.controls.password.value;
      const saltRequest: SaltRequest = new SaltRequest();

      saltRequest.user_name = this.Loginform.controls.loginName.value.trim();
      this.authenticationService.getSalt(saltRequest).subscribe(
        (response: any) => {
          const salt = response.Salt;
          const password = this.loader.encryptData(
            this.Loginform.controls.password.value.trim(),
            salt
          );
          this.passwordSalted = password;
          this.loader.setResetLoginCookie(
            this.Loginform,
            this.rememberMe,
            response.Salt
          );
          const cookiekey = 'device-unique-id-' + this.loader.encryptData(this.Loginform.controls.loginName.value.trim(), response.Salt)
          this.deviceId = this.cookieService.get(cookiekey);
          if (!this.deviceId) {
            this.loader.generateDeviceId(this.Loginform, cookiekey);
          } else {
            this.Loginform.controls.deviceDetail.setValue(this.deviceId)
          }
          this.loader.login(this.Loginform, password, true).subscribe(
            (response: any) => {
              this._flow_id = response._flow_id;
              if (!response.device_verified) {
                this.loader.generateDeviceId(this.Loginform, cookiekey);
                if (response.sent) {
                  this.loader.uiMode = 9;
                  this.timer();
                  this.spinnerService.show(false);
                } else {
                  this.messageService.alert(response.message);
                  this.spinnerService.show(false);
                }
              } else {
                this.verifylogIn();
              }
            },
            (error: any) => {
              const resetPasswordRequest = {
                email: this.Loginform.controls.loginName.value.trim(),
              };
              if (error.error.internal_code == 1003) {
                // send email for reset password for patient
                this.resetPasswordService
                  .postAuthforget(resetPasswordRequest)
                  .subscribe(
                    (inputValue) => {
                      inputValue;
                      this.showUserBlockedMessage();
                    },
                    (err: any) => {
                      console.log(err.error.message);
                      this.spinnerService.show(false);
                    }
                  );
              } else if (error.error.internal_code == 1004) {
                // send email for reset password for user
                this.resetPasswordService
                  .postAdminAuthforget(resetPasswordRequest)
                  .subscribe(
                    (inputValue) => {
                      inputValue;
                      this.showUserBlockedMessage();
                    },
                    (err: any) => {
                      console.log(err.error.message);
                      this.spinnerService.show(false);
                    }
                  );
              } else if (
                error.error.internal_code == 1005 ||
                error.error.internal_code == 1006
              ) {
                // no email, just show message
                this.showUserBlockedMessage();
              } else if (error.error.internal_code == 2000) {
                // send activation email, and show message
                this.loader.uiMode = 7;
                this.userEmail =
                  this.Loginform.controls["loginName"].value.toLowerCase();
                this.timer();
                this.loader.sendactivationLink(this.userEmail);
                this.spinnerService.show(false);
              } else if (error.error.internal_code == 2001) {
                this.resetPasswordService
                  .postAuthforget(resetPasswordRequest)
                  .subscribe(
                    (inputValue) => {
                      inputValue;
                    },
                    (err: any) => {
                      console.log(err.error.message);
                      this.spinnerService.show(false);
                    }
                  );
                this.showDeActiveMessage();
              } else if (error.error.internal_code == 2011) {
                this.showUnautorizedMessage()
              } else {
                this.spinnerService.show(false);
                this.Loginform.controls.password.setErrors([
                  { passwordMismatch: true },
                ]);
              }
            }
          );
        },
        (error: any) => {
          error;
          this.Loginform.controls.password.setErrors([
            { passwordMismatch: true },
          ]);
        }
      );
    } else {
      this.showCapchaError = true;
    }
  }

  onSend() {
    if (!this.registeredEmail.invalid) {
      const inputValue = {
        email: this.RegisteredEmail.nativeElement.value,
      };
      this.username = inputValue.email;
      this.resetPasswordService.postAuthforget(inputValue).subscribe(
        (inputValue) => {
          console.log(inputValue);
          this.forgetPasswordMailSent = true;
          this.forgotPassword = false;
        },
        (error) => {
          console.log(error);
          this.forgetPasswordMailSent = true;
          this.forgotPassword = false;
        }
      );
    }
  }

  showUserBlockedMessage() {
    this.spinnerService.show(false);
    this.lastLogin = this.Loginform.controls.loginName.value;
    this.Loginform.controls.password.setErrors([{ accountblocked: true }]);
    this.isAccountLocked = true;
  }
  showDeActiveMessage() {
    this.spinnerService.show(false);
    this.lastLogin = this.Loginform.controls.loginName.value;
    this.Loginform.controls.password.setErrors([{ accountblocked: true }]);
    this.isAccountDeActive = true;
  }
  showUnautorizedMessage() {
    this.spinnerService.show(false);
    this.lastLogin = this.Loginform.controls.loginName.value;
    this.Loginform.controls.password.setErrors([{ recLogin: true }]);
    this.isRecLogin = true;
  }
  goBacktoSearch() {
    this.noRecordFound = false;
  }
  onResetPassword() {
    if (this.resetpassword.valid) {
      this.hide = true;
      const newPasswordBody = {
        Id: this.loader.user_id,
        Password: this.newpassword.nativeElement.value,
        ModifyUser: 0, // when the patient himself updates the password we are sending this value as zero
      };
      if (
        this.resetpassword.value === this.repeatresetpassword.value &&
        this.resetpassword.value !== ""
      ) {
        const saltRequest: SaltRequest = new SaltRequest();
        saltRequest.id = this.loader.user_id;

        const salt = CryptoJS.lib.WordArray.random(128 / 20).toString();

        const password = this.loader.encryptData(this.resetpassword.value, salt);
        this.loader.salt = salt;
        this.loader.password = password;
        newPasswordBody.Password = password;
        newPasswordBody["salt"] = salt;

        this.spinnerService.show(true);
        this.resetPasswordService.putAuthsavePassword(newPasswordBody).subscribe(
          (newPasswordBody) => {
            this.spinnerService.show(false);
            if (newPasswordBody != null) {
              this.messageService.success(
                this.translate.instant("login.password-reset"),
                5000
              );
              this.loader.uiMode = 1;
            }
          },
          (error) => {
            console.log(error);
            this.messageService.alert(
              this.translate.instant("login.password-reset-error"),
              5000
            );
            this.spinnerService.show(false);
          }
        );
      }
    }
  }

  passwordMatch() {
    if (this.repeatresetpassword.value === "") {
      this.passwordsMatch = false;
      this.isDisable = true;
    } else if (
      this.repeatresetpassword.value !== "" &&
      this.resetpassword.value !== this.repeatresetpassword.value
    ) {
      this.passwordsMatch = true;
    } else {
      this.passwordsMatch = false;
      this.isDisable = false;
    }
  }

  rememberMeCheck(event: any) {
    this.rememberMe.setValue(event.checked);
  }

  clearPassword() {
    this.password.reset();
    this.Loginform.controls.password.setErrors([{ accountblocked: false }]);
    this.isAccountLocked = false;
    this.isAccountDeActive = false;
    this.isRecLogin = false;
  }

  captchaResolved(event: any) {
    if (event != null) {
      this.captchaVerified = true;
      this.showCapchaError = false;
    } else {
      this.captchaVerified = false;
      this.showCapchaError = true;
    }
  }

  /**
   * This method will execute the when user will try to use space key keyboard and in the search box if the space is first character then
   * it will wont allow space to execute, It will block the space untill user enter a character.
   * @param event
   */
  disableSpace(event: Event) {
    event.preventDefault();
  }

  checkIsValid(event: any) {
    if (event) {
      this.hideErrorBox();
    }
  }

  hideErrorBox() {
    this.passwordPolicy.hide();
  }

  showErrorBox() {
    this.passwordPolicy.show(
      this.resetpassword.value,
      constants.intMinPwdLength,
      constants.pwdMaxLength
    );
    this.isPasswordValid = this.passwordPolicy.valid(
      this.resetpassword.value,
      constants.intMinPwdLength,
      constants.pwdMaxLength
    );
  }

  timer() {
    const minute = 5;
    let seconds: number = minute * 60;
    let textSec = 0;
    let statSec = 60;
    const prefix = minute < 10 ? "0" : "";
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) {
        statSec--;
      } else {
        statSec = 59;
      }
      if (statSec < 10) {
        textSec = 0 + statSec;
      } else {
        textSec = statSec;
      }
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec
        .toString()
        .padStart(2, "0")}`;
      if (seconds == 0) {
        clearInterval(timer);
        this.btndisabled = false;
      }
    }, 1000);
  }

  onOtpChange(otp: string) {
    this.otp = otp;
  }
  setVal(val: any) {
    this.ngOtpInput.setValue(val);
  }

  resetOTP() {
    this.timer();
    this.btndisabled = true;
    const otpRequest: GetAuthLoginRequest = new GetAuthLoginRequest();
    otpRequest.password = this.passwordSalted;
    otpRequest.username = this.Loginform.controls.loginName.value.trim();
    this.authenticationService
      .postAuthOtpSend(otpRequest)
      .subscribe((res: any) => {
        this._flow_id = res._flow_id;
      });
  }

  verifylogIn() {
    const otpRequest: GetAuthOtpRequest = new GetAuthOtpRequest();
    otpRequest.password = this.passwordSalted;
    otpRequest.username = this.Loginform.controls.loginName.value.trim();
    otpRequest.otp = this.otp;
    otpRequest._flow_id = this._flow_id;
    otpRequest.device_detail = this.Loginform.controls.deviceDetail.value;
    this.spinnerService.show(true);
    this.authenticationService.postAuthOtpVerify(otpRequest).subscribe(
      (response) => {
        if (response["access_token"]) {
          const access_token = jwtDecode(response["access_token"]);
          if (this.loader.checkExpiryDate(response["password_expiry_time"])) {
            this.spinnerService.show(false);
            this.loader.passwordExpired = true;
            this.loader.uiMode = 4;
            this.loader.user_id = access_token["user_id"];
          } else {
            this.tokenResponse = response;
            this.loader.startRefreshTokenTimer(response);
            this.spinnerService.show(false);
            sessionStorage.setItem(
              "access_token",
              JSON.stringify(access_token)
            );
            sessionStorage.setItem(
              "header",
              this.tokenResponse["access_token"]
            );
            sessionStorage.setItem(
              "refresh_token",
              this.tokenResponse["refresh_token"]
            );
            if (access_token["role"] == constants.patientRole) {
              this.loader.userRole = access_token["role"];
              this.loader.loginService
                .getPatientDetails()
                .subscribe((resp: any) => {
                  sessionStorage.setItem("userDetails", JSON.stringify(resp));
                  this.loader.user_name =
                    resp.first_name + " " + resp.birth_name;
                  this.loader.dob = resp.dob;
                  this.loader.loggedIn = true;
                  this.loggedin.emit(true);
                  this.spinnerService.show(false);
                  this.loader.subjectLogin.next(true);
                });
            } else {
              this.spinnerService.show(false);
            }
          }
        } else {
          this.messageService.alert("Please Check the code you entered.");
          this.spinnerService.show(false);
        }
      },
      (error) => {
        const resetPasswordRequest = {
          email: this.Loginform.controls.loginName.value.trim(),
        };
        if (error.error.internal_code == 1003) {
          // send email for reset password for patient
          this.resetPasswordService
            .postAuthforget(resetPasswordRequest)
            .subscribe(
              (inputValue) => {
                inputValue;
                this.messageService.alert(
                  this.translate.instant("login.password-account-blocked")
                );
              },
              (err: any) => {
                console.log(err.error.message);
                this.spinnerService.show(false);
              }
            );
        } else if (error.error.internal_code == 1004) {
          // send email for reset password for user
          this.resetPasswordService
            .postAdminAuthforget(resetPasswordRequest)
            .subscribe(
              (inputValue) => {
                inputValue;
                this.messageService.alert(
                  this.translate.instant("login.password-account-blocked")
                );
              },
              (err: any) => {
                console.log(err.error.message);
                this.spinnerService.show(false);
              }
            );
        } else if (
          error.error.internal_code == 1006 ||
          error.error.internal_code == 1005
        ) {
          // no email, just show message
          this.messageService.alert(
            this.translate.instant("login.password-account-blocked")
          );
        } else if (error.error.code == 422) {
          // invalid OTP error message
          this.messageService.alert(
            this.translate.instant("login.invalid-otp")
          );
        } else {
          this.messageService.alert(error.error.message);
        }
        this.spinnerService.show(false);
      }
    );
  }

  verified(evt: any) {
    console.log(evt.verified);
    this.patientData = evt.data;
    this.isVerified = evt.verified;
    this.unique_id = evt.unique_id;
    this.otp = evt.otp;
    this.verifyID = evt.verify_id;
  }

  getActiveStep(step: number) {
    this.activeStep = step;
  }

  proceedLogin() {
    this.isVerified = false;
    this.authType.setValue("1");
  }

  public noRecordFoundFn(evt: any) {
    this.noRecordFound = evt.found;
    this.searchData = evt.searchData;
  }

  public selectAppointmentFor(forSelf: boolean) {
    this.step = 2;
    this.loader.forSelf = forSelf;
  }

  public goBack() {

    if (this.loader.uiMode == 9) {
      this.loader.uiMode = 1;
    } else
      this.goToBack.emit(true);
  }

  findMyAccount() {
    this.findMyAccountComponent.form.markAllAsTouched();
    this.findMyAccountComponent.findAccount();
  }
}
