import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KeyFeature, KeyFeatureWrapper, RequestData } from '../landing-page-config.model';
import { MessageService } from '../../../../main';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../../../../core';

@Component({
  selector: 'app-key-feature',
  templateUrl: './key-feature.component.html',
  styleUrls: ['./key-feature.component.scss']
})
export class KeyFeatureComponent implements OnInit {

  @Input() keyFeatures: KeyFeatureWrapper;
  @Output() cancelClicked = new EventEmitter();
  @Output() updateData = new EventEmitter<RequestData>();
  public fieldMaxLength: number = 120;
  public selectedLangTabIndex: number = 0;
  public enForm: FormGroup = this.fb.group({
    faq: ['', [Validators.required, Validators.maxLength(this.fieldMaxLength)]],
    key_feature_1: ['', [Validators.required, Validators.maxLength(this.fieldMaxLength)]],
    key_feature_2: ['', [Validators.required, Validators.maxLength(this.fieldMaxLength)]],
    key_feature_3: ['', [Validators.required, Validators.maxLength(this.fieldMaxLength)]],
    key_feature_4: ['', [Validators.required, Validators.maxLength(this.fieldMaxLength)]]
  });
  public frForm: FormGroup = this.fb.group({
    faq: ['', [Validators.required, Validators.maxLength(this.fieldMaxLength)]],
    key_feature_1: ['', [Validators.required, Validators.maxLength(this.fieldMaxLength)]],
    key_feature_2: ['', [Validators.required, Validators.maxLength(this.fieldMaxLength)]],
    key_feature_3: ['', [Validators.required, Validators.maxLength(this.fieldMaxLength)]],
    key_feature_4: ['', [Validators.required, Validators.maxLength(this.fieldMaxLength)]]
  });

  constructor(private fb: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private spinner: SpinnerService) { }

  ngOnInit(): void {
    this.bindData();
  }

  ngOnChanges() {
    this.bindData();
  }

  bindData() {
    const enFormData: KeyFeature = {
      faq: this.keyFeatures.en.faq,
      key_feature_1: this.keyFeatures.en.key_feature_1,
      key_feature_2: this.keyFeatures.en.key_feature_2,
      key_feature_3: this.keyFeatures.en.key_feature_3,
      key_feature_4: this.keyFeatures.en.key_feature_4
    };

    this.enForm.patchValue(enFormData);

    const frFormData: KeyFeature = {
      faq: this.keyFeatures.fr.faq,
      key_feature_1: this.keyFeatures.fr.key_feature_1,
      key_feature_2: this.keyFeatures.fr.key_feature_2,
      key_feature_3: this.keyFeatures.fr.key_feature_3,
      key_feature_4: this.keyFeatures.fr.key_feature_4
    };

    this.frForm.patchValue(frFormData);
  }

  save() {
    this.enForm.markAllAsTouched();
    this.frForm.markAllAsTouched();
    if (this.enForm.valid && this.frForm.valid) {
      this.messageService
        .confirm(
          this.translateService.instant('landing-page-config.save-confirm-description'),
          this.translateService.instant('landing-page-config.save-title'),
          this.translateService.instant('landing-page-config.yes'),
          this.translateService.instant('landing-page-config.no')
        )
        .subscribe((actionResult) => {
          if (actionResult) {
            this.spinner.show(true);
            let payload = {
              "tab_name": "keyfeatures",
              "page_data": JSON.stringify({
                "en": this.enForm.value,
                "fr": this.frForm.value
              }),
              "image_data": [
                {
                  "image_url": "",
                  "image_data": ""
                }
              ]
            }

            this.updateData.emit(payload);
          }
        });
    }
    else {
      if (this.selectedLangTabIndex == 0 && this.enForm.valid && !this.frForm.valid) {
        this.selectedLangTabIndex = 1;
      }
      else if (this.selectedLangTabIndex == 1 && !this.enForm.valid && this.frForm.valid) {
        this.selectedLangTabIndex = 0;
      }
    }
  }

  public onLangTabChange(event: any) {
    this.selectedLangTabIndex = event.index;
  }
}
