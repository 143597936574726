import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrescriptionDocument } from '../../appointment-prescription/appointment-prescription.model';
import { LoaderService } from '../../../../main';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-prescription-upload',
  templateUrl: './prescription-upload.component.html',
  styleUrl: './prescription-upload.component.scss'
})
export class PrescriptionUploadComponent implements OnInit {
  protected noDocuments: boolean = true;
  protected uploadStatus: boolean = false;
  protected isClicked: boolean = false;

  @Input() item: PrescriptionDocument = new PrescriptionDocument();
  @Output() updateDocument = new EventEmitter<any>();
  @Output() isImageClicked = new EventEmitter<boolean>();

  @Output() goBack = new EventEmitter<any>();
  @Output() goNext = new EventEmitter();
  public showBackBtn: boolean = true;
  public nextClicked: boolean = false;
  private subscription: Subscription = new Subscription();
  percent: number;
  constructor(public loader: LoaderService, private cdr: ChangeDetectorRef,
    public route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.loader.variable$.subscribe(value => {
        this.showBtn();
        this.percent = (value / this.loader.flow_arr.length) * 100;
        this.cdr.detectChanges(); // Manually trigger change detection
      })
    );
  }

  public showBtn() {
    let standy_test_id = this.route.snapshot.params["te_id"] != undefined ? this.route.snapshot.params["te_id"] : "";

    if (window.location.href.includes("patient/complete-appointment") && this.loader.loggedIn) {
      let is_prescription = this.loader.toBoolean(this.route.snapshot.params["is_prescription"]);
      let is_active = this.loader.toBoolean(this.route.snapshot.params["is_active"]);
      let doc_id = this.route.snapshot.params["doc_id"];
      if (!is_active && is_prescription) {
        //document not required
        this.showBackBtn = false;
      }
      else if (is_active && is_prescription && doc_id == "0") {
        this.showBackBtn = false;
      }
    }
    else {
      if (standy_test_id) {
        this.showBackBtn = false;

      }
      else {
        this.showBackBtn = true;
      }
    }


  }
  public getUpdateDocument(evt) {
    this.updateDocument.emit(evt);
  }

  public getClickImageStatus(evt) {
    this.isImageClicked.emit(evt);
  }

  goToNext() {
    this.nextClicked = true;
    this.goNext.emit(true);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

  }

  goToBack() {
    this.goBack.emit(true);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.showBackBtn = true;
  }

  ngOnChanges() {
    this.showBtn();
  }
}
