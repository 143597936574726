<div id="policy" [class.displayas]="modal" [style.color]="getColor(valid)" [class.loader-hidden]="!show">
  <span>
    <span class="header">{{'password-policy.password-label'|translate}}</span>
    <ul>
      <li>
        <span class="inline">
          <ng-container *ngIf="min==12">
            <span class="dot m-r-5 w-9" [style.backgroundColor]="getColor(length)"></span>
            {{PasswordPolicies.intLengthRequirement |translate}}
          </ng-container>
          <ng-container *ngIf="min==24">
            <span class="dot m-r-5 w-9" [style.backgroundColor]="getColor(length)"></span>
            {{PasswordPolicies.priLengthRequirement |translate}}
          </ng-container>
          <ng-container *ngIf="min==15">
            <span class="dot m-r-5 w-9" [style.backgroundColor]="getColor(length)"></span>
            {{PasswordPolicies.nurseLengthRequirement |translate}}
          </ng-container>
          <!-- <span class="dot m-r-5" [style.backgroundColor]="getColor(length)"></span>
            {{PasswordPolicies.lengthRequirement |translate}} -->
        </span>
      </li>
      <li id="capital">
        <span class="inline">
          <span class="dot m-r-5" [style.backgroundColor]="getColor(capital)"></span>
          {{ PasswordPolicies.upperCaseRequirement |translate}}
        </span>
      </li>
      <li id="small">
        <span class="inline">
          <span class="dot m-r-5" [style.backgroundColor]="getColor(small)"></span>
          {{PasswordPolicies.lowerCaseRequirement |translate}}
        </span>
      </li>
      <li id="number">
        <span class="inline">
          <span class="dot m-r-5" [style.backgroundColor]="getColor(number)"></span>
          {{ PasswordPolicies.numericRequirement |translate}}
        </span>
      </li>
      <li id="symbol">
        <span class="inline">
          <span class="dot m-r-5 w-11" [style.backgroundColor]="getColor(symbol)"></span>
          {{ PasswordPolicies.specialCharRequirement |translate}}
        </span>
      </li>
    </ul>
  </span>
</div>