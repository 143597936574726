import { DatePipe } from '@angular/common';

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { LocaleService } from '../../../app.locale.service';
import { Company, SamplingStationObj, SamplingStationSlot, SlotCount } from '../../models/common.model';
import { CommonApiService } from '../../services/common-api.service';
import { SpecialDate } from '../booking/special-dates/special-date';

@Component({
    selector: 'app-booking',
    templateUrl: './booking-calendar.component.html',
    styleUrls: ['./booking-calendar.component.scss']
})


export class BookingCalendarComponent implements OnInit {
    companies: Company[] = null;
    samplingStations: any[];
    calendars: SamplingStationSlot[] = null;
    calender: SamplingStationSlot;
    specialDates: SpecialDate[];
    bookedStotsCount: SlotCount[];
    hasCalendar = false;
    hasErrorCode = false;
    hasCalendarSelectd = false;
    ErrorStatusCode = '';
    status = '';
    weeks: any = [];
    // const lastday = new Date(new Date().getFullYear(),11,31);

    maxweek = 52;
    pipeval: number;
    year: number;
    public filteredOptions: Observable<any[]>;
    public myControl = new FormControl();

    slots: {
        sundaySlots: string[];
        mondaySlots: string[];
        tuesdaySlots: string[];
        wednesdaySlots: string[];
        thursdaySlots: string[];
        fridaySlots: string[];
        saturdaySlots: string[];
    } = {
            sundaySlots: [],
            mondaySlots: [],
            tuesdaySlots: [],
            wednesdaySlots: [],
            thursdaySlots: [],
            fridaySlots: [],
            saturdaySlots: []
        };
    selectedWeek = '';
    weekSelected = false;
    s_id: any;
    currentweekval: any;
    constructor(private commonAPi: CommonApiService, private datepipe: DatePipe,    private translate: TranslateService, public localeService: LocaleService) {

        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);

    }

    ngOnInit(): void {
        this.commonAPi.GetCompany().subscribe(x => {
            this.companies = x;
        });
        const today = new Date();
        this.year = today.getFullYear();
        this.pipeval = Number(this.datepipe.transform(today, 'w'));

        for (let i = 0; i <= 20; i++) {
            const weekval: any = this.commonAPi.getDateOfISOWeek(this.pipeval, this.year);
            if (i == 0) {
                this.currentweekval = weekval;
            }
            this.weeks.push({ weekname: this.pipeval, val: weekval });
            if (this.pipeval == 52) {
                this.year = this.year + 1;
                this.pipeval = 1;
            } else {
                this.pipeval++;
            }

        }
    }
    displayFn(option?: any): string {

        return option?.sampling_station_name;
    }
    public companyChange(id: any, event: any): void {
        this.myControl.setValue('');
        this.calender = null;
        this.selectedWeek = null;
        this.hasCalendar = false;
        this.weekSelected = false;
        if (event.isUserInput) {
            this.commonAPi.GetSamplingStation(id).subscribe((x: SamplingStationObj) => {

                this.samplingStations = x.sampling_stations;
                this.filteredOptions = this.myControl.valueChanges
                    .pipe(
                        startWith(''),
                        map(value => this._filter(value))
                    );
            });
        }
    }

    private _filter(value?: any): any[] {
        if (this.samplingStations != null) {
            if (typeof (value) == 'string') {
                const filterval = value?.toLowerCase();
                return this.samplingStations.filter(option => option.sampling_station_name.toLowerCase().includes(filterval));
            } else {
                const filterval = value?.sampling_station_name?.toLowerCase();
                return this.samplingStations.filter(option => option.sampling_station_name.toLowerCase().includes(filterval));

            }

        }
        return null;
    }
    public samplingStationChange(event: any): void {
        this.selectedWeek = null;
        this.s_id = event.option.value.id;
        this.myControl.setValue(event.option.value.sampling_station_name);

        this.commonAPi.GetCalendar(event.option.value.id).subscribe(x => {
            if (x.sampling_station_slot?.length > 0) {
                x.sampling_station_slot.forEach((element: any) => {
                    element.dows = element.dows.filter((i: any) => i.active == true);
                });
            }
            this.calendars = x.sampling_station_slot;
            if (this.calendars == null) {
                this.hasCalendar = false;
                this.hasErrorCode = true;
                this.weekSelected = false;
            } else {
                this.hasCalendar = true;
                this.hasErrorCode = false;
            }

        });

        this.commonAPi.GetCalenderSpecialDates(event.option.value.id).subscribe(x => {
            this.specialDates = x;
            this.specialDates.forEach(element => {
                element.start_date = new Date(element.start_date);
                element.end_date = new Date(element.end_date);
            });
        });
    }
    public calendarChange(val: any, event: any): void {
        if (event.isUserInput) {
            this.hasCalendarSelectd = true;
            this.calender = this.calendars.find(s => s.id === val);
            const finalobj = this.commonAPi.calculateSlots( this.calender, this.slots);
            this.calender = finalobj.calendar;
            this.slots = finalobj.slots;
            this.weekChange(this.currentweekval, null);
        }
    }
    public weekChange(val: any, event: any): void {
        if (event?.isUserInput || event == null) {
            this.CalculateSlotsWeek(val);
        }
    }
    private CalculateSlotsWeek(val: any) {
        this.selectedWeek = val;
        const finalobj = this.commonAPi.calculateSlots(this.calender, this.slots);
        this.calender = finalobj.calendar;
        this.slots = finalobj.slots;
        const td = new Date(val);
        const ws_date = new Date(td.getFullYear(), td.getMonth(), td.getDate() - 1, 0, 0, 1);
        const we_date = new Date(td.getFullYear(), td.getMonth(), td.getDate(), 23, 59, 59);
        we_date.setDate(we_date.getDate() + 7);
        // exclude already booked dates
        this.commonAPi.GetCalenderBookedSlots(this.calender.id, this.datepipe.transform(ws_date, 'yyyy-MM-ddThh:mm:ss').toString() + 'Z', this.datepipe.transform(we_date, 'yyyy-MM-ddThh:mm:ss').toString() + 'Z').subscribe(x => {
            this.bookedStotsCount = x;
        });
        // exclude special date
        for (let i = 0; i < 7; i++) {
            var date = new Date(val);
            date.setDate(date.getDate() + i);
            const eSpecialDates = this.specialDates.find(d => ((date >= d.start_date && date <= d.end_date) || (d.start_date.toDateString() == date.toDateString()) || (d.end_date.toDateString() == date.toDateString())));
            if (eSpecialDates != null && eSpecialDates != undefined) {
                switch (i) {
                    case 1: {
                        this.slots.mondaySlots = null;
                        break;
                    }
                    case 2: {
                        this.slots.tuesdaySlots = null;
                        break;
                    }
                    case 3: {
                        this.slots.wednesdaySlots = null;
                        break;
                    }
                    case 4: {
                        this.slots.thursdaySlots = null;
                        break;
                    }
                    case 5: {
                        this.slots.fridaySlots = null;
                        break;
                    }
                    case 6: {
                        this.slots.saturdaySlots = null;
                        break;
                    }
                    case 0: {
                        this.slots.sundaySlots = null;
                        break;
                    }
                }

            }
        }
        this.weekSelected = true;
    }
}
