import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FrequentlyBookedTestWrapper, RequestData } from '../landing-page-config.model';
import { MessageService } from '../../../../main';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../../../../core';

@Component({
  selector: 'app-frequently-booked-test',
  templateUrl: './frequently-booked-test.component.html',
  styleUrls: ['./frequently-booked-test.component.scss']
})
export class FrequentlyBookedTestComponent implements OnInit {
  @Input() fbtw: FrequentlyBookedTestWrapper;
  @Output() cancelClicked = new EventEmitter();
  @Output() updateData = new EventEmitter<RequestData>();
  public selectedLangTabIndex: number = 0;
  public field1MaxLength: number = 40;

  constructor(private fb: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private spinner: SpinnerService) { }


  public enForm: FormGroup = this.fb.group({
    section_title: ['', [Validators.required, Validators.maxLength(this.field1MaxLength)]]
  });

  public frForm: FormGroup = this.fb.group({
    section_title: ['', [Validators.required, Validators.maxLength(this.field1MaxLength)]]
  });

  ngOnInit(): void {
    this.bindData();
  }

  ngOnChanges() {
    this.bindData();
  }

  public bindData() {
    const enFormData = {
      section_title: this.fbtw.en.section_title
    };
    this.enForm.patchValue(enFormData);

    const frFormData = {
      section_title: this.fbtw.fr.section_title
    };
    this.frForm.patchValue(frFormData);
  }

  save() {
    this.enForm.markAllAsTouched();
    this.frForm.markAllAsTouched();
    if (this.enForm.valid && this.frForm.valid) {
      this.openPopup();
    }
    else {
      if (this.selectedLangTabIndex == 0 && this.enForm.valid && !this.frForm.valid) {
        this.selectedLangTabIndex = 1;
      }
      else if (this.selectedLangTabIndex == 1 && !this.enForm.valid && this.frForm.valid) {
        this.selectedLangTabIndex = 0;
      }
    }
  }

  public onLangTabChange(index: number) {
    this.selectedLangTabIndex = index;
  }

  public openPopup() {
    let popupRef = this.messageService
      .confirm(
        this.translateService.instant('landing-page-config.save-confirm-description'),
        this.translateService.instant('landing-page-config.save-title'),
        this.translateService.instant('landing-page-config.yes'),
        this.translateService.instant('landing-page-config.no')
      );

    popupRef.subscribe((actionResult) => {
      if (actionResult) {
        this.spinner.show(true);
        let payload = {
          "tab_name": "frequentbooktests",
          "page_data": JSON.stringify({
            "en": this.enForm.value,
            "fr": this.frForm.value
          }),
          "image_data": [
            {
              "image_url": "",
              "image_data": ""
            }
          ]
        }
        this.updateData.emit(payload);
      }
    });
  }
}
