import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { LoaderService, MessageService } from '../../../../main';
import { constantsarr } from '../../constant';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-check-prescription',
  templateUrl: './check-prescription.component.html',
  styleUrl: './check-prescription.component.scss'
})
export class CheckPrescriptionComponent implements OnInit {
  @Output() goBack = new EventEmitter<any>();
  @Output() goNext = new EventEmitter<any>();
  @Output() selectedHavePres = new EventEmitter<any>();
  @Input() hideBack: boolean = false;
  public isSelected: boolean = false;
  public havePrescription = new FormControl("", [Validators.required]);
  private subscription: Subscription = new Subscription();
  percent: number;
  constructor(
    public loader: LoaderService, private cdr: ChangeDetectorRef,
    public messageService: MessageService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.loader.variable$.subscribe(value => {
        this.percent = (value / this.loader.flow_arr.length) * 100;
        this.cdr.detectChanges(); // Manually trigger change detection
      })
    );
  }

  onChange(event: any) {
    if (event.value == 0) {
      this.loader.flow_arr = constantsarr.inside_lab_withoutpresc
    }
    this.loader.variable$.subscribe(value => {
      this.percent = (value / this.loader.flow_arr.length) * 100;
      this.cdr.detectChanges(); // Manually trigger change detection
    })
    this.loader.action_required = false;
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }

  protected select(option: any) {
    if (this.havePrescription.value == "" || this.havePrescription.value != option) {
      this.isSelected = !this.isSelected;
      this.havePrescription.setValue(option);
    }
    if (this.havePrescription.value == "0") {
      this.selectedHavePres.emit(true);
    }
    else if (this.havePrescription.value == "1") {
      this.selectedHavePres.emit(false);
    }
  }

  goToNext() {
    if (this.havePrescription.value == "0" || this.havePrescription.value == "1") {
      this.goNext.emit();
    }
    else {
      this.messageService.alert(
        this.translateService.instant("select-proceed"),
        5000
      );
    }
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

  }

  goToBack() {
    this.goBack.emit(true);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
