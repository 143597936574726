import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
//import { CommonApiService } from '../../services/common-api.service';
//import { SpinnerService } from '../../../core/spinner/spinner.service';
import { LoaderService } from "../../../main";
import { MatPaginator } from '@angular/material/paginator';
import { UserAddComponent } from '../user-add/user-add.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss'
})
export class UserListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>();

  search: String = '';
  searchinput = '';
  status = '';
  public columnList: string[] = ['name', 'email', 'role', 'type', 'establishment','dept','status', 'action'];

  filterObject = {
    search: '',
    status: ''
  };

  savealert = {
    edit: 'department-list.save-alert.edit',
    add: 'department-list.save-alert.add',
    delete: 'department-list.save-alert.delete'
  };

  sampleData: { [key: string]: string } = {
    All: "All",
    Active: "department-list.active",
    Pending: "department-list.pending",
    Suspended: "department-list.inactive"    
  };

  constructor(
    //private commonAPi: CommonApiService,
    public loader: LoaderService,
    //private spinner: SpinnerService,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  ngOnInit(): void {
    this.loadUsers();

    this.loader.refreshDialog.subscribe((refresh) => {
      if (refresh) {
        this.loadUsers();
        this.loader.refreshState(false);
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  unsorted() { }

  onSearch() {
    if (!this.searchinput || this.searchinput.trim() === '') {
      this.dataSource.filter = '';
    } else {
      this.filterObject.search = this.searchinput;
      this.dataSource.filter = JSON.stringify(this.filterObject);
    }
  }

  clearSearch() {
    this.searchinput = ''; 
    this.onSearch(); 
  }

  public statusFilter() {
    console.log("Selected Status:", this.status);
  
    if (this.status === 'All') {
      this.filterObject.status = 'All';
      this.dataSource.filter = JSON.stringify(this.filterObject);
    } else if (this.status === 'Active') {
      this.filterObject.status = 'true';
    } else if (this.status === 'Suspended') {
      this.filterObject.status = 'false';
    } else if (this.status === 'Pending') {
      this.filterObject.status = null;
    }
  
    this.dataSource.filter = JSON.stringify(this.filterObject);
  } 
  
  public loadUsers() {
  }
  

  addUser() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      UserAddComponent
    );

    const data = { action: 'add', edit: false, savealert: this.savealert };

    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "55%" : "100%",
      [data]
    );

    this.loadUsers();
  }

  editUser(entity: any) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      UserAddComponent
    );

    const data = { action: 'edit', edit: true, UserData: entity, savealert: this.savealert };

    console.log(entity);

    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "55%" : "100%",
      [data]
    );

    this.loadUsers();
  }

}
