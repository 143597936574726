<div id="patientInformation">
  <div class="header">
    <h3>
      <span>{{'patient-info.patient' | translate}}</span>
      <button class="only-mobile mat-tbl-icon" mat-icon-button (click)="editPatientIfo()">
        <span class="icon-ic-edit"></span>
      </button>
    </h3>
    <button class="mat-tbl-icon only-web" (click)="editPatientIfo()" mat-icon-button>
      <span class="icon-ic-edit" matTooltip="{{'receptionist.edit' | translate}}"></span>
    </button>
    <mat-form-field class="search-input" hidden>
      <input matInput #searchInput id="searchInput" [formControl]="seacrchInput" (keyup)="onSearch()"
        placeholder="{{'patient-info.search' | translate}}" name="search" id="search" />
      <div *ngIf="!searchInput.value" class="bar-code-icon" (click)="scanQR()"><span
          class="icon-ic-scan-barcode"></span></div>
      <div *ngIf="searchInput.value" (click)="searchInput.value = '';" class="clear">
        <p>{{'patient-info.clear-search' | translate}}</p>
      </div>
    </mat-form-field>
  </div>
  <div *ngIf="!refresh">
    <div class="header-description">
      <div class="info-section">
        <div class="only-web">
          <div class="headerContent" [ngClass]="!checkUploadedDocuments() ? 'background-warning' : 'background-normal'">
            <span class="titleWrapBox" [ngClass]="{'rm-user':is_secondary==true}">{{avatar}}</span>
            <div class="name-cover">
              <p class="headerTitle">{{fname}} {{lname}}
                <strong>{{'patient-information.born'|translate}}</strong>
                {{bname}}
              </p>
              <div *ngIf="ssn" class="ssn-header">
                <span class="form-label mb-10">{{'patient-info.ssn' | translate}} :</span>
                <span class="form-value">{{ssn}}</span><span *ngIf="!checkUploadedDocuments()"
                  class="icon-ic_alert only-web warning-icon"
                  matTooltip="{{'patient-info.document-warning' | translate}}"
                  matTooltipClass="tooltip-style-warning"></span>
              </div>

              <div class="only-web mb-10">
                <span class="form-label mb-10">{{'patient-info.private-insurance' | translate}} :</span>
                <span class="form-value">{{pvtInsurance | translate}}</span>
              </div>
              <div *ngIf="is_secondary" class="ssn-header mb-10">
                <span class="form-label mb-10">{{'patient-info.acc-linked-to' | translate}} :</span>
                <span class="form-value">{{patient?.p_first_name}} {{patient?.p_last_name}}
                  {{patient?.p_birth_name}}</span>
              </div>
            </div>
            <div class="reset-cover">
              <button mat-button color="accent" *ngIf="!is_secondary && mail != ''"
                (click)="resetPasswordSlider()">{{'patient-info.reset-password' |
                translate}}</button>
              <button mat-button color="accent" *ngIf="is_secondary"
                (click)="elevateAccountSlider()">{{'patient-info.create-account' | translate}}</button>
              <div class="only-mobile">
                <br>
                <div *ngIf="!checkUploadedDocuments()" class="warning-mobile-container">
                  <span class="icon-ic_alert warning-icon"></span>
                  <p>{{'patient-info.document-warning' | translate}}</p>
                </div>
              </div>
              <button class="mat-tbl-icon deleteEntity_btn ml-5" mat-icon-button
                (click)="redirectReceiptionistBooking()" *ngIf="mail != ''">
                <span class="icon-icon-my-appointment book-icon"
                  matTooltip="{{'patient-info.book-new-appointment' | translate}}"
                  matTooltipClass="tooltip-style"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="only-mobile">
          <div class="headerContent" [ngClass]="!checkUploadedDocuments() ? 'background-warning' : 'background-normal'">
            <div class="row-dir">
              <span class="titleWrapBox" [ngClass]="{'rm-user':is_secondary==true}">{{avatar}}</span>
              <div class="patientdetails">
                <p class="headerTitle">{{fname}} {{lname}}
                  <strong>{{'patient-information.born'|translate}}</strong>
                  {{bname}}
                </p>
                <div *ngIf="ssn" class="ssn-header">
                  <span class="form-label mb-10">{{'patient-info.ssn' | translate}} :</span>
                  <span class="form-value">{{ssn}}</span><span *ngIf="!checkUploadedDocuments()"
                    class="icon-ic_alert only-web warning-icon"
                    matTooltip="{{'patient-info.document-warning' | translate}}"
                    matTooltipClass="tooltip-style-warning"></span>
                </div>
                <!-- //private insuarance displayed in only-web -->
                <div *ngIf="is_secondary" class="ssn-header mb-10">
                  <span class="form-label mb-10">{{'patient-info.acc-linked-to' | translate}} :</span>
                  <span class="form-value">{{patient?.p_first_name}} {{patient?.p_last_name}}
                    {{patient?.p_birth_name}}</span>
                </div>
                <button mat-button color="accent" *ngIf="!is_secondary && mail != ''" class="nopadding"
                  (click)="resetPasswordSlider()">{{'patient-info.reset-password' | translate}}</button>
                <button mat-button color="accent" *ngIf="is_secondary" class="nopadding"
                  (click)="elevateAccountSlider()">{{'patient-info.create-account' | translate}}</button>
              </div>
            </div>
            <div class="reset-cover">
              <div class="only-mobile">
                <br>
                <div *ngIf="!checkUploadedDocuments()" class="warning-mobile-container">
                  <span class="icon-ic_alert warning-icon"></span>
                  <p>{{'patient-info.document-warning' | translate}}</p>
                </div>
              </div>
              <button class="mat-tbl-icon deleteEntity_btn ml-5" *ngIf="mail != ''" mat-icon-button
                (click)="redirectReceiptionistBooking()">
                <span class="icon-icon-my-appointment book-icon"
                  matTooltip="{{'patient-info.book-new-appointment' | translate}}"
                  matTooltipClass="tooltip-style"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="bookingInfoDiv">
          <div class="info-header">
            <h5 class="mb-20 nobreak">{{'patient-info.personal-info' | translate}}</h5>

            <div class="patientStatus">
              <div class="active-link"><button *ngIf="is_active" id="active">{{'Active' | translate}}</button></div>
              <div *ngIf="!is_active" class="sus-link">
                <button id="suspended">{{'Suspended' | translate}}</button>
                <button mat-button color="accent" id="suspendedText" class="pd-0" *ngIf="!is_active && mail != ''"
                  (click)="sendActivationLink()"><b class="nobreak"><u>{{'patient-info.act-link' |
                      translate}}</u></b></button>
              </div>
            </div>
          </div>
          <div class="threeColumnGrid">
            <div class="infoTabs oneline pl-30">
              <span class="icon-ic-date font-l"></span>
              <p class="form-label mb-0">{{'patient-info.dob' | translate}} :</p>
              <div class="form-value ">{{details?.Dob | date:'dd/MM/yyyy'}}</div>
            </div>
            <div class="infoTabs oneline pl-30">
              <span class="icon-ic-date font-l"></span>
              <p class="form-label mb-0">{{'patient-info.age' | translate}} :</p>
              <div class="form-value ">{{act_age}}</div>
            </div>
            <div class="infoTabs oneline pl-30">
              <span class="icon-ic-gender font-l"></span>
              <p class="form-label mb-0">{{'patient-info.gender' | translate}} :</p>
              <div class="form-value ">{{details.Gender| translate}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="qr-code">
        <app-qr-code width="220" [data]="qrid"></app-qr-code>
        <footer>
          <table>
            <tr>
              <td class="tdpull"><span>{{'patient-info.qr-id' | translate}} </span></td>
              <td class="td1pull"><span>{{qrid}}</span></td>
            </tr>
            <tr *ngIf="details.IppNumber">
              <td class="tdpull"><span>{{'patient-info.ipp-no' | translate}} </span></td>
              <td class="td1pull"><span>{{details.IppNumber}}</span></td>
            </tr>
          </table>
        </footer>
      </div>
    </div>
    <div class="only-web bookingInfoDiv mt-20">
      <h5 class="mb-20">{{'patient-info.comm-details' | translate}}</h5>
      <div class="threeColumnGrid">
        <div class="commTabs pl-30">
          <span class="icon-ic-mail font-l"></span>
          <p class="form-label mb-0">{{'patient-info.primary' | translate}} </p>
          <div matTooltip="{{mail}}" matTooltipPosition="above" class="form-valu">
            {{mail}}
            <i class="icon-ic-edit edit-icon" *ngIf="!is_secondary" (click)="editEmail()"></i>
          </div>
        </div>
        <div class="infoTabs pl-30">
          <span class="icon-ic-mobile font-l"></span>
          <p class="form-label mb-0">{{'patient-info.mobile' | translate}} </p>
          <div class="form-value">{{"+"+phone}}</div>
        </div>
        <div class="infoTabs pl-30">
          <span class="icon-ic-location font-l"></span>
          <p class="form-label mb-0">{{'patient-info.address' | translate}} </p>
          <div class="form-value">{{address}}</div>
        </div>
      </div>
    </div>
    <div class="only-mobile bookingInfoDiv mt-20">
      <h5 class="mb-20">{{'patient-info.comm-details'|translate}}</h5>
      <div class="threeColumnGrid">
        <div class="infoTabs pl-30 mb-15">
          <div matTooltip="{{mail}}" matTooltipPosition="above" class="form-value">
            <span class="icon-ic-mail font-l"></span>
            {{mail}}
            <i class="icon-ic-edit edit-icon" (click)="editEmail()"></i>
          </div>
        </div>
        <div class="infoTabs pl-30">
          <span class="icon-ic-mobile font-l"></span>
          <p class="form-value">{{phone}}</p>
        </div>
        <div class="infoTabs pl-30">
          <span class="icon-ic-location font-l"></span>
          <p class="form-value">{{address}}</p>
        </div>
      </div>
    </div>

    <div class="accordion-wrapper">
      <mat-accordion class="example-headers-align">
        <mat-expansion-panel class="panel-container" [expanded]="true">
          <mat-expansion-panel-header class="acc-panel-header" id="rm-panel-header">
            <mat-panel-title class="panel-title" id="rm-container">
              <h5>{{'patient-info.appointment-details' | translate}}</h5>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-tab-group disableRipple [@.disabled]="true" class="tab-app pull-left" animationDuration="1000ms">
            <mat-tab
              label="{{'my-apointment.appointments' | translate}} ({{patientAppointmens?.length > 0 ? patientAppointmens.length : 0}})">
              <ng-container *ngIf="patientAppointmens?.length>0">
                <app-view-appointments [appointments]="patientAppointmens"
                  (confirmArrival)="confirmDeleteWithYesNo($event)" [isFuture]="true"></app-view-appointments>

              </ng-container>
              <ng-container *ngIf="!patientAppointmens.length">
                <span>
                  <p>{{'patient-info.noschedule'|translate}}</p>
                </span></ng-container>
            </mat-tab>
            <mat-tab
              label="{{'my-apointment.past-appointments' | translate}} ({{pastAppointmens?.length > 0 ? pastAppointmens.length : 0}})">
              <ng-container *ngIf="pastAppointmens?.length>0">
                <app-view-appointments [appointments]="pastAppointmens" [isFuture]="false"></app-view-appointments>

              </ng-container>
              <ng-container *ngIf="!pastAppointmens.length">
                <span>
                  <p>{{'patient-info.noschedule'|translate}}</p>
                </span></ng-container>
            </mat-tab>
          </mat-tab-group>
        </mat-expansion-panel>
      </mat-accordion>
    </div>


    <div class="accordion-wrapper" *ngIf="!is_secondary">
      <mat-accordion class="example-headers-align">
        <mat-expansion-panel class="panel-container" [expanded]="true">
          <mat-expansion-panel-header class="acc-panel-header" id="rm-panel-header">
            <mat-panel-title class="panel-title" id="rm-container">
              <h5>{{'patient-info.result-title' | translate}}</h5>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-results [infoPage]="true"></app-results>
        </mat-expansion-panel>
      </mat-accordion>
    </div>



    <app-pdf-viewer *ngIf="isPdfViewerOpened" [blobSrc]="pdfBlob" [allPages]="true" (closed)="isPdfViewerOpened = false"
      pdftitle="{{'documents.document'|translate}}" [pdfFileName]="pdfTitle">
    </app-pdf-viewer>
    <pdf-viewer hidden></pdf-viewer>
    <div class="bookingInfoDiv mt-20">
      <h5 class="mb-20">{{'patient-info.documents' | translate}}</h5>
      <div class="threeColumnGrid mb-30">
        <div class="infoTabs pl-30" class="document-heading" *ngFor="let documentType of documentTypes">
          <span class="icon-icon-documents font-l document-icon"></span>
          <p class="form-label mb-1 document-name">{{documentType.KeyName | translate}}</p>
          <div *ngIf="isDocumentUploaded(documentType)" class="document-cover only-web">
            <!-- <div *ngIf="!documentType.Expiry && getDocumentValidity(documentType)==''" class="heading form-value mt-20"></div> -->
            <div *ngIf="getDocumentValidity(documentType)!=''" class="heading form-value">{{'patient-info.valid-till' |
              translate}}
              {{getDocumentValidity(documentType) | date: 'dd/MM/yyyy'}}</div>
            <button class="view-button" mat-button color="accent" (click)="openImage(documentType)"
              matTooltip="{{'patient-info.view' | translate}}">
              <span class="download-icon-span icon-ic-view-prescription"></span>
            </button>
            <button class="download-icon" mat-icon-button (click)="downloadPres(documentType)"
              matTooltip="{{'image-viewer.download' | translate}}">
              <span class="icon-ic-download download-icon-span"></span>
            </button>
            <div
              *ngIf="getDocumentValidity(documentType)!= '' && isDocumentUploaded(documentType) && checkExpiry(documentType)"
              class="expired">
              {{'documents.expired'|translate}}</div>
          </div>
          <div *ngIf="isDocumentUploaded(documentType)" class="document-cover only-mobile">
            <div *ngIf="!documentType.Expiry" class="heading form-value mt-20"></div>
            <div *ngIf="documentType.Expiry" class="heading-valid form-value">{{'patient-info.valid-till' | translate}}
              {{getDocumentValidity(documentType) | date: 'dd/MM/yyyy'}}</div>
            <button class="download-icon" mat-icon-button color="primary" (click)="openImage(documentType)">
              <span class="download-icon-span icon-ic-view-prescription"></span>
            </button>
            <button class="download-icon" mat-icon-button color="primary" (click)="downloadPres(documentType)">
              <span class="icon-ic-download download-icon-span"></span></button>
          </div>
          <div *ngIf="!isDocumentUploaded(documentType)">
            <p class="color-red">{{'patient-info.upload-pending' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>