@if (loaderService.flow_arr.indexOf(constants.upload_presc)>-1 ){
<div [ngClass]="{'hide':constants.upload_presc !== step }">
    <app-prescription-upload [hideBack]="currentindex == 0" [item]="documents[0]" [noDocuments]="noDocuments"
        (updateDocument)="getUpdateDocument($event)" (isImageClicked)="getClickImageStatus($event)"
        (goNext)="checkDocument()" (goBack)="goBack()"></app-prescription-upload>
</div>
}

@if (loaderService.flow_arr.indexOf(constants.doc_upload)>-1 && !invalidLink){
<div [ngClass]="{'hide':constants.doc_upload !== step }">
    <div class="doc-upload-container"
        [ngClass]="loaderService.loggedIn ? 'doc-upload-container-loggedin' : 'doc-upload-container-guest'">
        <div class="section-header"
            [ngClass]="loaderService.loggedIn ? 'section-header-login' : 'section-header-guest'">
            <circle-progress class="only-web" [options]="loaderService.optionsG" [percent]="percent"
                [renderOnClick]="false" imageSrc="assets/images/application-logos/svg/ic-sdocument.svg" class="copy">
            </circle-progress>
            <h3 class="desc-title">{{'createAccount.upload-document' | translate}}</h3>
        </div>
        <div class="slot-selection-section"
            [ngClass]="loaderService.loggedIn ? 'slot-selection-section-login' : 'slot-selection-section-guest'">
            <app-doc-upload [minor]="age<18" [nationality]="loaderService.ppaInfo?.nationality"
                (submitEmit)="uploadDoc($event)" [showBack]="!loaderService.loggedIn" (backEmit)="checkLogin()"
                [doc_ids]="doc_id" fromAppointment="false"></app-doc-upload>
        </div>
    </div>
</div>
}

@if (loaderService.flow_arr.indexOf(constants.login)>-1 ){
<div [ngClass]="{'hide':constants.login !== step }">

    <ng-container *ngIf="showLoginPrebook">
        <app-login-prebook (loggedin)="loginSuccessfull()" (registereduser)="RegisterSuccessful($event)"
            [userName]="username" [Password]="password" [showRegister]="showRegister" [displayMap]="!isMassiveTesting"
            [showLogin]="showLogin" (goToBack)="goBack()" (forgetPasswordClicked)="openForgotPassword($event)">
        </app-login-prebook>
    </ng-container>
    <div class="password-container" *ngIf="!showLoginPrebook && (forgotPassword || forgetPasswordMailSent)">
        <app-forgot-password [percent]="progressPercent"
            (openLogin)="showLoginPrebook=true;showLogin=true;showRegister=false;"
            (openCreateAccount)="showLoginPrebook=true;showLogin=false;showRegister=true;">
        </app-forgot-password>
    </div>
</div>
}

@if (loaderService.flow_arr.indexOf(constants.confirm_page)>-1 ){
<div [ngClass]="{'hide':constants.confirm_page !== step }">
    <div *ngIf="(registerSuccessful || loginSuccessful) && showConfirmBooking">
        <app-con-booking [showConfirmBooking]="showConfirmBooking" [prescription_url]="documents[0]?.prescriptionData"
            [selectedLab]="selectedLab" [selectedtest]="selectedTest" [calendar]="selectedTest?.calendar_id"
            [user_name]="loaderService.user_name" [dob]="loaderService.dob" [start_time]="selectedTest?.start_time"
            [isConfirmed]="isConfirmed" (showLoginScreen)="ShowConfirmationTab($event)" (goToBack)="goBack()">
        </app-con-booking>
    </div>
</div>
}


<div id="invalidlink_container" *ngIf="invalidLink">
    <div id="expiredlink_container">
        <span>{{'backend-errors.500' | translate}}</span>
    </div>
</div>