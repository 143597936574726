import { FormGroup, AbstractControl } from '@angular/forms';
import { MessageService } from '../../../main';
import { TranslateService } from '@ngx-translate/core';

export function validateTimeControl(control: AbstractControl): boolean {
    const timeComponents = control.value.split(':');
    const hour = parseInt(timeComponents[0], 10);
    const minute = parseInt(timeComponents[1], 10);
    const totalMinutes = hour * 60 + minute;
    return totalMinutes % 5 === 0;
}

export function convertTimeToDate(timeInput: string, sd: Date) {
    const [hours, minutes] = timeInput.split(":").map(Number);

    return new Date(sd.getFullYear(), sd.getMonth(), sd.getDate(), hours, minutes, 0);
}

export function validateTime(form: FormGroup, selectedDow: any, messageService: MessageService, translateService: TranslateService): void {
    const startTimeControl = form.controls["StartTime"];
    const endTimeControl = form.controls["EndTime"];
    const startDate = form.controls["StartDate"].value;
    if (startTimeControl && endTimeControl) {
        const isStartValid = validateTimeControl(startTimeControl);
        const isEndValid = validateTimeControl(endTimeControl);

        const startTimeInput = startTimeControl.value;
        const startTimeInputDate = convertTimeToDate(startTimeInput, new Date(startDate));
        const dowStartTimeDate = convertTimeToDate(selectedDow.start_time, new Date(startDate));
        const lunchStartTimeDate = convertTimeToDate(selectedDow.break_begins, new Date(startDate));

        const endTimeInput = endTimeControl.value;
        const endTimeInputDate = convertTimeToDate(endTimeInput, new Date(startDate));
        const dowEndTimeDate = convertTimeToDate(selectedDow.end_time, new Date(startDate));
        const lunchEndTimeDate = convertTimeToDate(selectedDow.break_ends, new Date(startDate));

        if (!isStartValid) {
            startTimeControl.setErrors({ incorrect: true });
            messageService.alert(
                translateService.instant("start-time-validation")
            );
        } else {
            if (startTimeInputDate.getTime() < dowStartTimeDate.getTime() ||
                startTimeInputDate.getTime() > dowEndTimeDate.getTime() ||
                // startTimeInputDate.getTime() < new Date().getTime() ||
                (startTimeInputDate.getTime() >= lunchStartTimeDate.getTime() && endTimeInputDate.getTime() <= lunchEndTimeDate.getTime())) {
                startTimeControl.setErrors({ incorrect: true });
            }
            else {
                startTimeControl.setErrors(null);
            }
        }

        if (!isEndValid) {
            endTimeControl.setErrors({ incorrect: true });
            messageService.alert(
                translateService.instant("end-time-validation")
            );
        } else {
            if (endTimeInputDate.getTime() > startTimeInputDate.getTime() &&
                endTimeInputDate.getTime() <= dowEndTimeDate.getTime()
            ) {
                if (endTimeInputDate.getTime() > lunchStartTimeDate.getTime() && endTimeInputDate.getTime() < lunchEndTimeDate.getTime()) {
                    endTimeControl.setErrors({ incorrect: true });

                }
                else {
                    endTimeControl.setErrors(null);
                }
            }
            else {
                endTimeControl.setErrors({ incorrect: true });
            }
        }
    }
}