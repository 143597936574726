import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

import { Component, Inject, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';

import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
import { AppSettingsService, DrawerComponent } from '../../../core';
import { SpinnerService } from '../../../core/spinner/spinner.service';
import { LoaderService, MessageService } from '../../../main';
import { Company } from '../../models/common.model';
import { TestService } from '../../services/add-test-service.service';
import { CommonApiService } from '../../services/common-api.service';
import { PatientService } from '../../services/patient.service';
import { GoogleMapService } from '../../shared/lab-google/google-map.service';
import { Test, TestType, TimeZone } from '../sampling/sampling';
import { SamplingService } from './sampling.service';
//import { init } from '../../shared/anyline/types';
declare var woosmap: any;

export class WeekendData {
    id: number;
    day: string;
    active: boolean;
    start_time: string;
    end_time: string;
    break_begins: string;
    break_ends: string;
    is_disabled: boolean;
    is_open_valid: boolean;
    is_close_valid: boolean;
    is_break_start_valid: boolean;
    is_break_end_valid: boolean;
}


@Component({
    selector: 'app-add-sampling',
    templateUrl: './add-sampling.component.html',
    styleUrls: ['./add-sampling.component.scss']
})
export class AddSamplingComponent implements OnInit, DrawerComponent {
    @Input() sliderFormData: any;
    @Input() public isSliderDataEdited = false;

    title: string;
    place: any;
    SamplingForm: FormGroup;
    googleAddress: string;
    saveData: {};
    saveAlert = {
        edit: 'admin.save-alert.edit',
        add: 'admin.save-alert.add'
    };
    errorMessage = 'admin.error-message';
    selectAll = 'add-sampling.select-all';

    loggedInRole = JSON.parse(sessionStorage.getItem('access_token'))['role'];

    companies: Company[] = null;
    testTypeList: TestType[];
    allTestList: any[] = [];
    selectedTestList: Test[];
    selectedTestTypeList: any[] = [];
    paymentTypeList: any[] = [];
    timezoneList: TimeZone[];

    weekdays: WeekendData[] = [
        { id: 1, day: 'admin.monday', start_time: '00:00', end_time: '00:00', break_begins: '00:00', break_ends: '00:00', active: false, is_disabled: true, is_open_valid: true, is_close_valid: true, is_break_end_valid: true, is_break_start_valid: true },
        { id: 2, day: 'admin.tuesday', start_time: '00:00', end_time: '00:00', break_begins: '00:00', break_ends: '00:00', active: false, is_disabled: true, is_open_valid: true, is_close_valid: true, is_break_end_valid: true, is_break_start_valid: true },
        { id: 3, day: 'admin.wednesday', start_time: '00:00', end_time: '00:00', break_begins: '00:00', break_ends: '00:00', active: false, is_disabled: true, is_open_valid: true, is_close_valid: true, is_break_end_valid: true, is_break_start_valid: true },
        { id: 4, day: 'admin.thursday', start_time: '00:00', end_time: '00:00', break_begins: '00:00', break_ends: '00:00', active: false, is_disabled: true, is_open_valid: true, is_close_valid: true, is_break_end_valid: true, is_break_start_valid: true },
        { id: 5, day: 'admin.friday', start_time: '00:00', end_time: '00:00', break_begins: '00:00', break_ends: '00:00', active: false, is_disabled: true, is_open_valid: true, is_close_valid: true, is_break_end_valid: true, is_break_start_valid: true },
        { id: 6, day: 'admin.saturday', start_time: '00:00', end_time: '00:00', break_begins: '00:00', break_ends: '00:00', active: false, is_disabled: true, is_open_valid: true, is_close_valid: true, is_break_end_valid: true, is_break_start_valid: true },
        { id: 7, day: 'admin.sunday', start_time: '00:00', end_time: '00:00', break_begins: '00:00', break_ends: '00:00', active: false, is_disabled: true, is_open_valid: true, is_close_valid: true, is_break_end_valid: true, is_break_start_valid: true }
    ];

    slotConfigHours: any[] = [
        { KeyName: "0 Heure ", Key: "0" },
        { KeyName: "0.5 Heure ", Key: "30" },
        { KeyName: "1 Heure ", Key: "60" },
        { KeyName: "1.5 Heures ", Key: "90" },
        { KeyName: "2 Heures ", Key: "120" },
        { KeyName: "2.5 Heures", Key: "150" },
        { KeyName: "3 Heures", Key: "180" },
        { KeyName: "3.5 Heures", Key: "210" },
        { KeyName: "4 Heures", Key: "240" },
        { KeyName: "4.5 Heures", Key: "270" },
        { KeyName: "5 Heures", Key: "300" },
        { KeyName: "5.5 Heures", Key: "330" },
        { KeyName: "6 Heures", Key: "360" },
        { KeyName: "6.5 Heures", Key: "390" },
        { KeyName: "7 Heures", Key: "420" },
        { KeyName: "7.5 Heures", Key: "450" },
        { KeyName: "8 Heures", Key: "480" },
        { KeyName: "10 Heures", Key: "600" },
        { KeyName: "12 Heures", Key: "720" },
        { KeyName: "16 Heures", Key: "960" },
        { KeyName: "20 Heures", Key: "1200" },
        { KeyName: "24 Heures", Key: "1440" },
        { KeyName: "28 Heures", Key: "1680" },
        { KeyName: "32 Heures", Key: "1920" },
        { KeyName: "36 Heures", Key: "2160" },


    ]

    openFrom = new FormControl();
    openTo = new FormControl();
    PaymnetType = new FormControl();
    fromDateMin: Date;
    toDateMin: Date;
    selectedDate: Date;
    isAnyOpeningHoursSelected = false;
    protected isTriggerEmail = false;
    protected isTriggerQrcode = false;
    protected displayOnMap = true;
    protected slotOne = new FormControl('');
    protected slotTwo = new FormControl('');
    protected qmsNumber = new FormControl('');
    protected qmsQueueNumber = new FormControl('');

    regexStr = '^[a-zA-Z0-9_]*$';
    selectedTT: number[];
    selectedTest: number[];


    constructor(
        private readonly dialogRef: MatDialogRef<AddSamplingComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private readonly spinner: SpinnerService,
        private commonAPi: CommonApiService,
        private testService: TestService,
        private samplingService: SamplingService,
        private readonly messageService: MessageService,
        private translate: TranslateService,
        private googleMapService: GoogleMapService,
        private localeService: LocaleService,
        public loader: LoaderService,
        public patientService: PatientService,
        private readonly appSettings: AppSettingsService,
    ) {
        this.intitializeSamplingForm();
        this.title = this.data.edit ? 'add-sampling.edit' : 'add-sampling.add';
        this.fromDateMin = new Date();
        this.toDateMin = new Date();
        this.toDateMin.setDate(this.fromDateMin.getDate() + 1);
        this.selectedTestList = [];
        this.translate.use(this.localeService.localeLang);
    }

    intitializeSamplingForm() {
        this.SamplingForm = this.formBuilder.group({
            SamplingStationID: [
                null,
                [Validators.required, Validators.pattern(this.regexStr)]
            ],
            Status: ['Active', [Validators.required]],
            SamplingStation: [null, [Validators.required]],
            Tests: [null, [Validators.required]],
            Company: [null, [Validators.required]],
            Type: [null, [Validators.required]],
            PaymnetType: [null, [Validators.required]],
            openFrom: [''],
            openTo: [''],
            PhoneNumber: [null],
            CodeFiness: [''],
            slotOne: this.slotOne,
            slotTwo: this.slotTwo,
            qmsNumber: this.qmsNumber,
            qmsQueueNumber: this.qmsQueueNumber,
            Email: [
                null,
                [
                    Validators.pattern('.{1,60}$'),
                    Validators.pattern(this.patientService.emailPattern)
                ]
            ],
            Timezone: [null, [Validators.required]],
            Address: [null],
            Latitude: [null],
            Longitude: [null],
            SlotHours: [null, [Validators.required]],

        });

    }

    @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

    ngAfterViewInit(): void {
        if (typeof woosmap !== 'undefined') {
          //this.initializeMap();
          this.initWoos();
        } else {
          console.error('Woosmap SDK is not loaded!');
        }
        setTimeout(
            () => this.formFields.forEach((ff) => ff.updateOutlineGap()),
            100
        );
    }

    ngOnInit(): void {
        if (this.data.edit) {
            this.bindUserObject(this.data.samplingData);

            // Getting Company List
            this.commonAPi.GetCompany().subscribe((companyList) => {
                this.companies = companyList;
                let idx = companyList.findIndex(x => x.id === this.data.samplingData.org_id);
                if (idx >= 0) {
                    this.SamplingForm.patchValue({
                        Company: this.data.samplingData.org_id
                    });
                }
            });

            // this.commonAPi.getSlotConfiguration().subscribe((res: any) => {
            //     this.slotConfigHours = res;
            //     this.SamplingForm.patchValue({
            //         Timezone: this.data.samplingData.org_id
            //     });
            //     this.SamplingForm.controls.Timezone.setValue(this.data.samplingData.time_zone);
            // });

            this.initWoos()

            // Getting Test Type List
            this.commonAPi.GetAlActivelTestType().subscribe((testType: any) => {
                this.testTypeList = testType;
                this.testTypeList = this.testTypeList.sort((a: any, b: any) => a.KeyOrder - b.KeyOrder);
                this.bindingTestAndTestTypeInEditMode();
            });

            this.commonAPi.getPaymentType().subscribe((paymentypes) => {
                paymentypes.sort((a, b) => a.Id - b.Id);
                this.paymentTypeList = paymentypes;
                this.SamplingForm.patchValue({
                    PaymnetType: this.data.samplingData.payment_type
                });
            });
            this.commonAPi.getTimezone().subscribe((timezone: any) => {
                this.timezoneList = timezone;
                this.timezoneList = this.timezoneList.sort((a: any, b: any) => a.KeyOrder - b.KeyOrder);
            });
        } else {
            const currentDate = new Date();
            this.openFrom.setValue(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
            this.openTo.setValue(new Date(2030, 11, 31));
            // Getting All Tests.
            this.testService.getAdminTests().subscribe((testList) => {
                this.allTestList = testList;
            });
            // Getting Company List
            this.commonAPi.GetCompany().subscribe((companyList) => {
                this.companies = companyList;
            });
            this.initWoos()

            // Getting Test Type List
            this.commonAPi.GetAlActivelTestType().subscribe((testType: any) => {
                this.testTypeList = testType;
                this.testTypeList = this.testTypeList.sort((a: any, b: any) => a.KeyOrder - b.KeyOrder);

            });

            this.commonAPi.getPaymentType().subscribe((paymentypes) => {
                paymentypes.sort((a, b) => a.Id - b.Id);
                this.paymentTypeList = paymentypes;
                let paymentTypeNoneId: any;
                for (const item of this.paymentTypeList) {
                    if (item.KeyName === 'None') {
                        paymentTypeNoneId = item.Id;
                    }
                }
                this.SamplingForm.patchValue({
                    PaymnetType: paymentTypeNoneId
                });
            });
            this.commonAPi.getTimezone().subscribe((timezone: any) => {
                this.timezoneList = timezone;
                this.timezoneList = this.timezoneList.sort((a: any, b: any) => a.KeyOrder - b.KeyOrder);
            });
        }
    }
    
    public initWoos(): void {
        this.googleMapService.api.then(() => {
            const options = {
            "components": {
                "country": this.appSettings.country.split(";")
            },
            "types": ['locality', 'postal_code', 'address', 'admin_level', 'country', 'train_station', 'metro_station', 'shopping', 'airport', 'museum', 'zoo', 'amusement_park', 'art_gallery', 'tourist_attraction']
            };
            //@ts-ignore
            const localitiesWidget = new window.woosmap.localities.Autocomplete("autocomplete-input", options);
            // Listen for selected suggestion from autocomplete
            localitiesWidget.addListener("selected_suggestion", () => {
                const selectedLocality = localitiesWidget.getSelectedSuggestionDetails();
                this.SamplingForm.controls.Latitude.setValue(selectedLocality.geometry.location.lat);
                this.SamplingForm.controls.Longitude.setValue(selectedLocality.geometry.location.lng);
                console.log(selectedLocality)
                // const tz = selectedLocality.utc_offset_minutes / 60;
                
                const lat = selectedLocality.geometry.location.lat;
                const lng = selectedLocality.geometry.location.lng;
                this.googleMapService.getTimezone(lat, lng).then(timezoneData => {
                    const tz = timezoneData.raw_offset / 3600; // Convert seconds to hours
                    if (tz < 0) {
                        this.SamplingForm.controls.Timezone.setValue('UTC' + tz);
                    } else {
                        this.SamplingForm.controls.Timezone.setValue('UTC+' + tz);
                    }
                    console.log('Timezone data:', timezoneData);
                })
            });
        });
    }

    bindingTestAndTestTypeInEditMode() {
        // Getting All Tests.
        this.testService.getAdminTests().subscribe((testList) => {
            this.allTestList = testList;
            let selectedTestTypeIds: any[] = [];
            selectedTestTypeIds = this.allTestList
                .filter((item) =>
                    this.data.samplingData.tests.map((i: any) => i.id).includes(item.id)
                )
                .map((k) => k.test_type_id);
            // removed duplicate
            selectedTestTypeIds = Array.from(new Set(selectedTestTypeIds));
            this.selectedTT = selectedTestTypeIds;
            this.selectedTest = this.data.samplingData.tests.map((i: any) => i.id);
            this.buildTT(selectedTestTypeIds);
        });
    }

    bindUserObject(samplingData: any) {
        let parseCoordinate: any;
        let latitude: any;
        let longitude: any;
        if (samplingData.coordinates != "") {
            parseCoordinate = samplingData.coordinates.substring(1, samplingData.coordinates.length - 1).split(",")
            latitude = parseCoordinate[0].split(":")[1]
            longitude = parseCoordinate[1].split(":")[1]
        }
        const address =
            samplingData.address === 'n/a' ? '' : samplingData.address;

        const slotHour = this.getSelectedSlotHoursData(samplingData.slot_before).Key
        this.SamplingForm.patchValue({
            SamplingStationID: samplingData.external_id,
            SamplingStation: samplingData.sampling_station_name,
            PhoneNumber: samplingData.phone_number,
            Email: samplingData.email,
            Address: address,
            Status: samplingData.status,
            Timezone: samplingData.time_zone,
            CodeFiness: samplingData.code_finess,
            Latitude: latitude,
            Longitude: longitude,
            SlotHours: slotHour,
        });

        this.isTriggerEmail = samplingData.trigger_email_for_appointments;
        this.isTriggerQrcode = samplingData.is_qr_required;
        this.displayOnMap = samplingData.display_map;

        if (this.isTriggerEmail) {
            this.slotOne.setValue(samplingData.trigger_email_slot_1);
            this.slotTwo.setValue(samplingData.trigger_email_slot_2);
        }

        if (this.isTriggerQrcode) {
            this.qmsNumber.setValue(samplingData.qms_id)
            this.qmsQueueNumber.setValue(samplingData.qms_queue_id)
        }

        if (samplingData.open_from !== null) {
            this.openFrom.setValue(samplingData.open_from.replace('Z', ''));
            this.changeMinDateInFromDatePicker(this.openFrom.value.replace('Z', ''))
        }

        if (samplingData.open_until !== null) {
            this.openTo.setValue(samplingData.open_until.replace('Z', ''));
            this.changeMinDateInDatePicker(this.openFrom.value.replace('Z', ''));
        }

        this.updateOpeningAndClosingDetails(
            samplingData.opening_and_closing_details
        );
    }

    private updateOpeningAndClosingDetails(weekdaysData: any[]) {
        if (weekdaysData.length === 0) {
            return;
        }

        this.weekdays.forEach((element: any, index: number) => {
            const wdd = weekdaysData.find(i => i.day == element.id);
            if (wdd) {
                element.start_time = wdd.start_time;
                element.end_time = wdd.end_time;
                element.break_begins = wdd.break_begins ? wdd.break_begins : '00:00';
                element.break_ends = wdd?.break_ends ? wdd.break_ends : '00:00';
                element.active = true;
                element.is_disabled = false;
            }
            if (element.active) {
                this.compareMinAndMaxInputForLunchHours(
                    element.start_time,
                    element.end_time,
                    element.break_begins + ':00',
                    element.break_ends + ':00',
                    index
                );
            }
        });
    }


    getSelectedSlotHoursData(slotData: any) {
        var selectedPos = 0;
        for (let i = 0; i < this.slotConfigHours.length; i++) {
            if (slotData == this.slotConfigHours[i].Key) {
                selectedPos = i;
                break;
            }
        }
        return this.slotConfigHours[selectedPos];
    }

    /**
   * This method will envoke when user will change the date by datepicker or manually.
   * @param e ChangedDate Value
   */
    onSelectedDate(e: any) {
        this.changeMinDateInDatePicker(e);
    }

    /**
   * This Method will set the min date on To Date Picker field according to selected value in From Date Picker
   * @param inputData Date Value
   * @returns returns the method without setting any min date if the date value is invalid.
   */

    changeMinDateInDatePicker(inputData: any) {
        this.selectedDate = inputData;
        const datePipe = new DatePipe('en-US');

        const formattedDate = datePipe.transform(this.selectedDate, 'dd-MM-yyyy');
        if (formattedDate == null) {
            return;
        }

        const splittedDateArray = formattedDate.split('-');

        const year = Number(splittedDateArray[2]);
        const month = Number(splittedDateArray[1]) - 1;
        const date = Number(splittedDateArray[0]) + 1;

        this.toDateMin = new Date(year, month, date);
    }

    private changeMinDateInFromDatePicker(inputData: any) {
        this.selectedDate = inputData;
        const datePipe = new DatePipe('en-US');

        const formattedDate = datePipe.transform(this.selectedDate, 'dd-MM-yyyy');
        if (formattedDate == null) {
            return;
        }

        const splittedDateArray = formattedDate.split('-');

        const year = Number(splittedDateArray[2]);
        const month = Number(splittedDateArray[1]) - 1;
        const date = Number(splittedDateArray[0]);
        let openFrom = new Date(year, month, date);
        let today = new Date();
        let currDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        if (openFrom > currDate) {
            this.fromDateMin = currDate;
        } else {
            this.fromDateMin = openFrom;
        }
    }

    /**
   * This method will reset the selected test list on the basis of selected test type.
   * @param testType selected test type
   * @param event event
   */
    public onTestTypeSelected(event: any): void {
        const ttArr = event.value.map((item: any) => item.Id);
        const stt = this.selectedTestList.map((item: any) => item.test_type_id);

        const removedTT = stt.filter(a => !ttArr.some((b: number) => a === b));
        removedTT.forEach(rEle => {
            this.selectedTestList.forEach((ele: any, index: number) => {
                if (ele.test_type_id == rEle) {
                    this.selectedTestList.splice(index, 1);

                }
            });
            this.SamplingForm.controls.Tests.value.forEach((ele: any, index: number) => {
                if (ele.test_type_id == rEle) {
                    this.SamplingForm.controls.Tests.value.splice(index, 1);
                }
            });
        });
        if (ttArr.length > 0) {
            ttArr.forEach((tt: number) => {
                const atts = this.allTestList.filter(i => i.test_type_id == tt);
                atts.forEach((itemTestType: any) => {
                    const ind = this.selectedTestList.findIndex(i => i.test_id == itemTestType.id);
                    if (ind == -1) {
                        this.selectedTestList.push({
                            test_id: itemTestType.id,
                            testName: itemTestType.short_name,
                            test_type_id: itemTestType.test_type_id
                        });
                    }
                });
            });
        } else {
            this.selectedTestList = new Array<Test>();
        }
    }

    allChecked(event: any) {
        if (event) {
            this.allTestList.forEach(itemTestType => {
                this.selectedTestList.push({
                    test_id: itemTestType.id,
                    testName: itemTestType.short_name,
                    test_type_id: itemTestType.test_type_id
                });
            });
        } else {
            this.selectedTestList = new Array<Test>();
        }
    }

    updateTestTypeDropdown(value: number[]) {
        this.selectedTestTypeList = value;
        this.selectedTestList = [];

        for (const itemTestType of this.allTestList) {
            for (const selectedTestTypeID of this.selectedTestTypeList) {
                if (itemTestType.test_type_id === selectedTestTypeID) {
                    this.selectedTestList.push({
                        test_id: itemTestType.id,
                        testName: itemTestType.short_name,
                        test_type_id: itemTestType.test_type_id
                    });
                }
            }
        }
        if (this.data.edit) {
            const ttl = this.testTypeList.filter(i => value.includes(i.Id));
            this.SamplingForm.get('Type').setValue(ttl);
            const st = this.data.samplingData.tests.map((i: any) => i.id);
            const stl = this.selectedTestList.filter((k: any) => st.includes(k.test_id));
            this.SamplingForm.get('Tests').setValue(stl);
            this.SamplingForm.updateValueAndValidity();
        } else {
        }
    }

    public dismiss() {
        this.dialogRef.close(null);
    }

    clear(formDirective: FormGroupDirective) {
        this.SamplingForm.controls.SamplingStationID.setValue(null);
        this.SamplingForm.controls.Status.setValue(null);
        this.SamplingForm.controls.SamplingStation.setValue(null);
        this.SamplingForm.controls.Tests.setValue(null);
        this.SamplingForm.controls.Company.setValue(null);
        this.SamplingForm.controls.CodeFiness.setValue('');
        this.SamplingForm.controls.Type.setValue(null);
        this.SamplingForm.controls.PhoneNumber.setValue(null);
        this.SamplingForm.controls.Timezone.setValue(null);
        this.SamplingForm.controls.Address.setValue(null);
        this.SamplingForm.controls.Latitude.setValue(null);
        this.SamplingForm.controls.Longitude.setValue(null);
        this.SamplingForm.controls.SlotHours.setValue(null);



        this.openFrom.setValue(null);
        this.openTo.setValue(null);
        this.changeMinDateInDatePicker(new Date());
        formDirective.resetForm();
        this.SamplingForm.reset();
        this.weekdays = new Array<WeekendData>();
        this.weekdays = [
            { id: 1, day: 'admin.monday', start_time: '00:00', end_time: '00:00', break_begins: '00:00', break_ends: '00:00', active: false, is_disabled: true, is_open_valid: true, is_close_valid: true, is_break_end_valid: true, is_break_start_valid: true },
            { id: 2, day: 'admin.tuesday', start_time: '00:00', end_time: '00:00', break_begins: '00:00', break_ends: '00:00', active: false, is_disabled: true, is_open_valid: true, is_close_valid: true, is_break_end_valid: true, is_break_start_valid: true },
            { id: 3, day: 'admin.wednesday', start_time: '00:00', end_time: '00:00', break_begins: '00:00', break_ends: '00:00', active: false, is_disabled: true, is_open_valid: true, is_close_valid: true, is_break_end_valid: true, is_break_start_valid: true },
            { id: 4, day: 'admin.thursday', start_time: '00:00', end_time: '00:00', break_begins: '00:00', break_ends: '00:00', active: false, is_disabled: true, is_open_valid: true, is_close_valid: true, is_break_end_valid: true, is_break_start_valid: true },
            { id: 5, day: 'admin.friday', start_time: '00:00', end_time: '00:00', break_begins: '00:00', break_ends: '00:00', active: false, is_disabled: true, is_open_valid: true, is_close_valid: true, is_break_end_valid: true, is_break_start_valid: true },
            { id: 6, day: 'admin.saturday', start_time: '00:00', end_time: '00:00', break_begins: '00:00', break_ends: '00:00', active: false, is_disabled: true, is_open_valid: true, is_close_valid: true, is_break_end_valid: true, is_break_start_valid: true },
            { id: 7, day: 'admin.sunday', start_time: '00:00', end_time: '00:00', break_begins: '00:00', break_ends: '00:00', active: false, is_disabled: true, is_open_valid: true, is_close_valid: true, is_break_end_valid: true, is_break_start_valid: true }
        ];
    }

    onSave() {
        if (this.SamplingForm.valid && this.checkIfDateInputIsValid()) {
            this.validateAllTime();
            const userSelectedTestList: any[] =
                this.SamplingForm.controls.Tests.value;
            const selectedTestValue: any[] = [];
            for (const item of userSelectedTestList) {
                if (item != 0) {
                    selectedTestValue.push({
                        id: item.test_id,
                        active: true
                    });
                }
            }
            const selectedDays = this.weekdays.filter(i => i.active == true);
            if (selectedDays.length <= 0) {
                this.messageService.warning(
                    this.translate.instant('add-sampling.opening-hour-select-msg')
                );
                return;
            }
            const invalidFormats = this.weekdays.filter(i => i.active && (i.is_break_end_valid == false || i.is_break_start_valid == false || i.is_close_valid == false || i.is_open_valid == false));
            if (invalidFormats.length > 0) {
                return;
            }

            const selectedEmail = this.getSelectedData(
                this.SamplingForm.controls.Email.value
            );

            const phoneNumber = this.getSelectedData(
                this.SamplingForm.controls.PhoneNumber.value
            );

            const fromDate = this.getFormattedDate(this.openFrom.value);
            const toDate = this.getFormattedDate(this.openTo.value);

            const address = this.getSelectedData(
                this.SamplingForm.controls.Address.value
            );

            const openingAndClosingData: any[] = [];

            for (const item of this.weekdays) {
                if (item.active) {
                    openingAndClosingData.push(item);
                }
            }
            const selectedSlotData = this.getSelectedData(
                this.SamplingForm.controls.SlotHours.value
            );

            const selectedCoordinates = "{lat:" + this.SamplingForm.controls.Latitude.value + ",lng:" + this.SamplingForm.controls.Longitude.value + "}"


            this.displaySpinner(true);

            if (this.data.edit) {
                const obj = {
                    id: this.data.samplingData.id,
                    status: this.SamplingForm.controls.Status.value,
                    sampling_station_external_id:
                        this.SamplingForm.controls.SamplingStationID.value,
                    code_finess:
                        this.SamplingForm.controls.CodeFiness.value,
                    sampling_station_name:
                        this.SamplingForm.controls.SamplingStation.value,
                    company_id: this.SamplingForm.controls.Company.value,
                    tests: selectedTestValue,
                    phone_number: phoneNumber,
                    email: selectedEmail,
                    opening_and_closing_details: openingAndClosingData,
                    time_zone: this.SamplingForm.controls.Timezone.value,
                    address,
                    payment_type: this.SamplingForm.controls.PaymnetType.value,
                    open_from: fromDate,
                    open_until: toDate,
                    trigger_email_for_appointments: this.isTriggerEmail,
                    is_qr_required: this.isTriggerQrcode,
                    display_map: this.displayOnMap,
                    trigger_email_slot_1: this.slotOne.value,
                    trigger_email_slot_2: this.slotTwo.value,
                    coordinates: selectedCoordinates,
                    slot_before: selectedSlotData,
                    qms_id: this.isTriggerQrcode ? this.qmsNumber.value : 0,
                    qms_queue_id: this.isTriggerQrcode ? this.qmsQueueNumber.value : 0
                };
                this.samplingService.editSamplingStation(obj).subscribe(
                    () => {
                        this.displaySpinner(false);
                        this.messageService.success(
                            this.translate.instant(this.saveAlert[this.data.action])
                        );
                        this.dialogRef.close(null);
                    },
                    (errorResponse: HttpErrorResponse) => {
                        this.displaySpinner(false);
                        if (errorResponse.error.internal_code === 1007) {
                            this.messageService.warning(
                                this.translate.instant('add-sampling.invalid-email')
                            );
                        } else if (errorResponse.error.internal_code === 1006) {
                            this.messageService.warning(
                                this.translate.instant('add-sampling.invalid-external')
                            );
                        } else {
                            this.messageService.warning(
                                this.translate.instant('add-sampling.invalid-input')
                            );
                        }
                    }
                );
            } else {
                const obj = {
                    status: this.SamplingForm.controls.Status.value,
                    sampling_station_external_id:
                        this.SamplingForm.controls.SamplingStationID.value,
                    code_finess:
                        this.SamplingForm.controls.CodeFiness.value,
                    sampling_station_name:
                        this.SamplingForm.controls.SamplingStation.value,
                    company_id: this.SamplingForm.controls.Company.value,
                    tests: selectedTestValue,
                    phone_number: phoneNumber,
                    email: selectedEmail,
                    opening_and_closing_details: openingAndClosingData,
                    time_zone: this.SamplingForm.controls.Timezone.value,
                    address,
                    payment_type: this.SamplingForm.controls.PaymnetType.value,
                    open_from: fromDate,
                    open_until: toDate,
                    trigger_email_for_appointments: this.isTriggerEmail,
                    is_qr_required: this.isTriggerQrcode,
                    display_map: this.displayOnMap,
                    trigger_email_slot_1: this.slotOne.value,
                    trigger_email_slot_2: this.slotTwo.value,
                    coordinates: selectedCoordinates,
                    slot_before: selectedSlotData,
                    qms_id: this.isTriggerQrcode ? this.qmsNumber.value : 0,
                    qms_queue_id: this.isTriggerQrcode ? this.qmsQueueNumber.value : 0
                };

                this.samplingService.addSamplingStation(obj).subscribe(
                    () => {
                        this.displaySpinner(false);
                        this.messageService.success(
                            this.translate.instant(this.saveAlert[this.data.action])
                        );
                        this.dialogRef.close(null);
                    },
                    (errorResponse: HttpErrorResponse) => {
                        this.displaySpinner(false);
                        if (errorResponse.error.internal_code === 1007) {
                            this.messageService.warning(
                                this.translate.instant('add-sampling.invalid-email')
                            );
                        } else if (errorResponse.error.internal_code === 1006) {
                            this.messageService.warning(
                                this.translate.instant('add-sampling.invalid-external')
                            );
                        } else {
                            this.messageService.warning(
                                this.translate.instant('add-sampling.invalid-input')
                            );
                        }
                    }
                );
            }
        }
    }

    getSelectedData(selectedData: any) {
        if (selectedData === undefined) {
            return '';
        } else if (selectedData === null) {
            return '';
        } else {
            return selectedData;
        }
    }

    protected checkIfDateInputIsValid() {
        return this.openFrom.valid && this.openTo.valid;
    }

    /**
   * This method will convert the date string into a formatted date.
   * @param dateValue
   * @returns formatted date.
   */
    getFormattedDate(dateValue: any) {
        const datePipe = new DatePipe('en-US');

        if (dateValue === undefined) {
            return '';
        }

        const formattedDate =
            dateValue !== null ? datePipe.transform(dateValue, 'dd-MM-yyyy') : '';

        return formattedDate;
    }

    getUtcTime(timeValue: any) {

        if (timeValue === undefined || timeValue === "") {
            return '';
        }
        var hourminits = timeValue.split(":");
        var date = new Date();
        date.setHours(hourminits[0]);
        date.setMinutes(hourminits[1]);

        var resultTime = date.toUTCString().split(" ")[4];

        return resultTime.substring(0, 5);
    }

    getLocalTime(timeValue: any) {

        if (timeValue === undefined || timeValue === "") {
            return '';
        }
        var hourminits = timeValue.split(":");
        var date = new Date();
        date.setUTCHours(hourminits[0]);
        date.setUTCMinutes(hourminits[1]);

        var resultTime = date.toTimeString().split(' ')[0];

        return resultTime.substring(0, 5);
    }

    public displaySpinner(flag: boolean): void {
        this.spinner.show(flag);
    }
    selectCountry() { }

    keyPressNumbers(event: any): boolean {
        const inp = String.fromCharCode(event.keyCode);
        if (/[0-9]/.test(inp)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    removeSpaces(e: ClipboardEvent) {
        const cf = e.clipboardData.getData('text');
        const regex = /^\d+$/;
        if (cf.match(regex) === null) {
            e.preventDefault();
            return false;
        }
        return true;
    }

    keyPressAlphaNumeric(event: any) {
        const inp = String.fromCharCode(event.keyCode);

        if (/[a-zA-Z0-9]/.test(inp)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    /**
   * This method will execute the when user will try to use space key keyboard and in the search box if the space is first character then
   * it will wont allow space to execute, It will block the space untill user enter a character.
   * @param event
   */
    keyDownHandler(event: Event) {
        if (this.SamplingForm.controls.SamplingStation.value.length === 0) {
            event.preventDefault();
        }
    }

    checkIfAnyOpeningHoursSelected() {
        let isSelected = false;

        for (const item of this.weekdays) {
            if (item.active) {
                isSelected = true;
                break;
            }
        }
        return isSelected;
    }

    private buildTT(value: any) {
        this.updateTestTypeDropdown(value);
    }

    validateAllTime() {
        this.weekdays.forEach((element: any, index: number) => {
            if (element.active) {
                this.compareMinAndMaxInputForLunchHours(
                    element.start_time,
                    element.end_time,
                    element.break_begins + ':00',
                    element.break_ends + ':00',
                    index, false
                );
            } else {
                element.is_break_end_valid = true;
                element.is_break_start_valid = true;
                element.is_close_valid = true;
                element.is_disabled = true;
                element.is_open_valid = true;
            }
        });

    }

    validateTime(index: number) {
        const wd = this.weekdays[index];
        if (wd.active) {
            this.compareMinAndMaxInputForLunchHours(
                wd.start_time,
                wd.end_time,
                wd.break_begins + ':00',
                wd.break_ends + ':00',
                index
            );
        } else {
            wd.is_break_end_valid = true;
            wd.is_break_start_valid = true;
            wd.is_close_valid = true;
            wd.is_disabled = true;
            wd.is_open_valid = true;
            wd.break_begins = '00:00:00';
            wd.break_ends = '00:00:00';
            wd.start_time = '00:00';
            wd.end_time = '00:00';
        }
    }

    private compareMinAndMaxInputForLunchHours(
        ssOpeningHours: string,
        ssClosingHours: string,
        lunchStartHours: string,
        lunchEndHours: string,
        index: number, showErrorBox: boolean = true
    ) {
        const ssOpenTime = new Date();
        const samplingStationOpeningHoursSplitted: string[] =
            ssOpeningHours.split(':');
        ssOpenTime.setHours(
            Number(samplingStationOpeningHoursSplitted[0]),
            Number(samplingStationOpeningHoursSplitted[1]),
            Number('00')
        );

        const ssClosingTime = new Date();
        const samplingStationClosingHoursSplitted: string[] =
            ssClosingHours.split(':');
        ssClosingTime.setHours(
            Number(samplingStationClosingHoursSplitted[0]),
            Number(samplingStationClosingHoursSplitted[1]),
            Number('00')
        );

        const lunchStartTime = new Date();
        const lunchStartSplitTime: string[] =
            lunchStartHours.split(':');

        lunchStartTime.setHours(
            Number(lunchStartSplitTime[0]),
            Number(lunchStartSplitTime[1]),
            Number('00')
        );

        const lunchEndTime = new Date();
        const lunchEndSplitTime: string[] = lunchEndHours.split(':');
        lunchEndTime.setHours(
            Number(lunchEndSplitTime[0]),
            Number(lunchEndSplitTime[1]),
            Number('00')
        );
        // if (parseInt(ssOpeningHours[0], 10) > 0 || parseInt(ssOpeningHours[1], 10) > 0 || parseInt(ssClosingHours[0], 10) > 0 || parseInt(ssClosingHours[1], 10) > 0) {

        if (ssOpenTime.getTime() == ssClosingTime.getTime()) {
            // start time is greater than end time, invalid case
            if (showErrorBox) {
                this.messageService.alert(
                    this.translate.instant('add-calendar.can-not-same')
                );
            }
            this.weekdays[index].is_disabled = true;
            this.weekdays[index].break_begins = '00:00';
            this.weekdays[index].break_ends = '00:00';
            this.weekdays[index].is_close_valid = false;
        } else if (ssOpenTime.getTime() > ssClosingTime.getTime()) {
            // start time is greater than end time, invalid case
            if (showErrorBox) {
                this.messageService.alert(
                    this.translate.instant('add-calendar.invalid-start-end-time')
                );
            }
            this.weekdays[index].is_disabled = true;
            this.weekdays[index].break_begins = '00:00';
            this.weekdays[index].break_ends = '00:00';
            this.weekdays[index].is_close_valid = false;
        } else {
            this.weekdays[index].is_disabled = false;
            this.weekdays[index].is_close_valid = true;
            if (parseInt(lunchStartSplitTime[0]) != 0 || parseInt(lunchStartSplitTime[1]) != 0 || parseInt(lunchEndSplitTime[0]) != 0 || parseInt(lunchEndSplitTime[1]) != 0) {
                // not default value
                if (
                    lunchStartTime.getTime() >= ssOpenTime.getTime() &&
                    lunchStartTime.getTime() <= ssClosingTime.getTime()
                ) {
                    this.weekdays[index].is_break_start_valid = true;
                    if (
                        lunchEndTime.getTime() > lunchStartTime.getTime() &&
                        lunchEndTime.getTime() <= ssClosingTime.getTime()
                    ) {
                        // lunch end time valid
                        this.weekdays[index].is_break_end_valid = true;
                    } else {
                        this.weekdays[index].is_break_end_valid = false;
                    }
                } else {
                    this.weekdays[index].is_break_start_valid = false;
                    this.weekdays[index].is_break_end_valid = false;
                    if (showErrorBox) {
                        this.messageService.alert(
                            this.translate.instant('add-calendar.select-between') + ' ' +
                            ssOpeningHours + ' ' +
                            this.translate.instant('add-calendar.and') + ' ' +
                            ssClosingHours
                        );
                    }
                }
            } else {
                this.weekdays[index].is_break_end_valid = true;
                this.weekdays[index].is_break_start_valid = true;
            }
        }

        // }
    }

    daySelect(event: any, index: number) {
        if (!event.checked) {
            this.weekdays[index].break_begins = '00:00';
            this.weekdays[index].break_ends = '00:00';
            this.weekdays[index].start_time = '00:00';
            this.weekdays[index].end_time = '00:00';
            this.validateTime(index);
        }
    }

    protected triggerMapDisplay(event: any) {
        if (!event.checked) {
            this.displayOnMap = false;

        }
        else {
            this.displayOnMap = true;
        }
    }

    protected triggerEmailSelect(event: any) {
        if (!event.checked) {
            this.isTriggerEmail = false;
            this.SamplingForm.get('slotOne').clearValidators();
            this.SamplingForm.updateValueAndValidity();
            this.slotOne.setValue('');
            this.slotTwo.setValue('');
        } else {
            this.isTriggerEmail = true;
        }
    }
    protected triggerQrCode(event: any) {
        if (!event.checked) {
            this.isTriggerQrcode = false;
            this.SamplingForm.get('qmsNumber').clearValidators();
            this.SamplingForm.get('qmsQueueNumber').clearValidators();
            this.SamplingForm.updateValueAndValidity();
            this.qmsNumber.setValue('');
            this.qmsQueueNumber.setValue('');
        } else {
            this.isTriggerQrcode = true;
        }
    }
}
