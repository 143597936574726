import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';

@Component({
    selector: 'app-ecl-steps',
    templateUrl: './ecl-steps.component.html',
    styleUrls: ['./ecl-steps.component.scss']
})
export class EclStepsComponent implements OnInit {
    @Input() steps: any;
    @Input() infoPage: boolean = false;

    constructor(private translate: TranslateService,
        private localeService: LocaleService,) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    ngOnInit(): void {
    }

}
