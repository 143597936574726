
import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from '../../../main/services/loader.service';
import { AddProfessionalComponent } from './add-professional/add-professional.component';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SpinnerService } from '../../../core/spinner/spinner.service';
import { Subscription } from 'rxjs';
import { ProfessionalsService } from './professionals.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../../main';
import { HealthcareProfessionals } from '../../models/common.model';


@Component({
  selector: 'app-healthcare-professional',
  templateUrl: './healthcare-professional.component.html',
  styleUrl: './healthcare-professional.component.scss'
})
export class HealthcareProfessionalComponent implements OnInit, OnDestroy {

  dataSource = new MatTableDataSource<any>();
  searchinput = '';
  status = 'All';
  isAddTitle: boolean;
  public columnList: string[] = ['name', 'healthcare professional code', 'healthcare professional ADELI', 'healthcare professional RPPS', 'status', 'action'];

  sampleData: { [key: string]: string } = {
    All: 'All',
    Active: 'Active',
    null: 'Pending',
    Suspended: 'Suspended'
  };
  savealert = {
    edit: 'admin.save-alert.edit',
    add: 'admin.save-alert.add',
    delete: 'admin.save-alert.delete'
  };
  filterObject = {
    search: '',
    status: ''
  };
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private refreshSubscription: Subscription;
  ehp = {
    noRecords: 'ehp.noProfessionals',
    InternalServerError: 'ehp.internal-server-error',
    unauthorized: 'ehp.unauthorized',
  }

  constructor(
    private spinnerService: SpinnerService,
    public loader: LoaderService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private professionalsService: ProfessionalsService,
    private translate: TranslateService,
    private messageService: MessageService,
  ) { }
  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.professionalsService.resetRefresh();
    }
  }


  ngOnInit(): void {
    this.refreshSubscription = this.professionalsService.refreshList$.subscribe(refresh => {
      if (refresh) {
        this.loadProfessionals();
      }
    });
    this.loadProfessionals();
  }
  loadProfessionals() {
    this.spinnerService.show(true);
    this.professionalsService.GetAllProfessionals().subscribe(
      (professionals) => {
        this.spinnerService.show(false)
        professionals.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.dataSource.data = professionals;
        // Define filter predicate
        this.dataSource.filterPredicate = (data, filter) => {
          const filterObj = JSON.parse(filter);
          if (filterObj.search !== '' && filterObj.status == '') {
            return (
              String(data.id).toLowerCase().includes(filterObj.search.toLowerCase()) ||
              String(data.name).toLowerCase().includes(filterObj.search.toLowerCase()) ||
              String(data.external_code).toLowerCase().includes(filterObj.search.toLowerCase())
            );
          } else if (filterObj.search == '' && filterObj.status !== '') {
            return (
              String(data.is_active) === filterObj.status ||
              filterObj.status == 'All'
            );
          } else {
            return (
              (String(data.id).toLowerCase().includes(filterObj.search.toLowerCase()) ||
                String(data.name).toLowerCase().includes(filterObj.search.toLowerCase()) ||
                String(data.external_code).toLowerCase().includes(filterObj.search.toLowerCase())) &&
              (String(data.is_active) === filterObj.status ||
                filterObj.status == 'All')
            );
          }
        };
      },
      (errorResponse: HttpErrorResponse) => {
        this.spinnerService.show(false);
        if (errorResponse.error.internal_code === 4041) {
          this.messageService.alert(
            this.translate.instant(this.ehp.noRecords),
            10000
          );
        } else if (errorResponse.error.code == 401) {
          this.messageService.alert(
            this.translate.instant(this.ehp.unauthorized),

            10000
          );
        }
        else {

          this.messageService.alert(
            this.translate.instant(this.ehp.InternalServerError),
            10000
          );
        }
      }
    )
  }
  statusFilter() {
    if (this.status != 'All') {
      this.filterObject.status = this.status == 'Active' ? 'true' : (this.status == 'Suspended') ? 'false' : 'null';
      this.dataSource.filter = JSON.stringify(this.filterObject);
    } else {
      this.filterObject.status = 'All';
      this.dataSource.filter = JSON.stringify(this.filterObject);
    }
  }

  onSearch() {
    this.filterObject.status = this.status == 'Active' ? 'true' : (this.status == 'Suspended') ? 'false' : (this.status == 'All') ? 'All' : 'null';
    if (this.searchinput) {
      this.filterObject.search = this.searchinput;
      this.dataSource.filter = JSON.stringify(this.filterObject);
    }
    else {
      this.filterObject.search = ''
      this.dataSource.filter = JSON.stringify(this.filterObject);
    }
  }
  clearSearch() {
    this.searchinput = '';
    this.onSearch();
  }

  keyDownHandler(event: Event) {
    if (this.searchinput.length === 0) {
      event.preventDefault();
    }
  }

  addProfessional() {
    this.isAddTitle = true;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      AddProfessionalComponent
    );
    const data = { action: 'add', edit: false, savealert: this.savealert };
    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "55%" : "100%",
      [data]
    );
  }

  unsorted() { }

  editProfessioanl(element: HealthcareProfessionals) {
    this.isAddTitle = false;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      AddProfessionalComponent
    );
    const data = { action: 'edit', edit: true, professionalData: element, savealert: this.savealert };
    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "55%" : "100%",
      [data]
    );
    this.loadProfessionals();
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


}
