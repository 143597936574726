import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from '../../../main';

@Component({
  selector: 'app-preview-file',
  templateUrl: './preview-file.component.html',
  styleUrls: ['./preview-file.component.scss']
})
export class PreviewFileComponent implements OnInit {
  @Input() sliderFormData: any;
  constructor(public loader: LoaderService) {

  }
  ngOnInit(): void {
  }

  public dismiss() {
    this.loader.cancelForm();
  }
}
