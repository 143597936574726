import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../../../../main';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../../../../core';
import { ImageInfo, PatientJourneyWrapper, RequestData } from '../landing-page-config.model';

@Component({
  selector: 'app-patient-journey',
  templateUrl: './patient-journey.component.html',
  styleUrls: ['./patient-journey.component.scss']
})
export class PatientJourneyComponent implements OnInit {
  @Input() patientJourney: PatientJourneyWrapper;
  @Output() cancelClicked = new EventEmitter();
  @Output() updateData = new EventEmitter<RequestData>();
  public selectedLangTabIndex: number = 0;
  public type1MaxLength: number = 100;
  public type2MaxLength: number = 200;
  public type3MaxLength: number = 20;
  public updatedImages: Array<ImageInfo>;
  constructor(private fb: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private spinner: SpinnerService) {
    this.updatedImages = new Array<ImageInfo>();
  }
  public enForm: FormGroup;
  public frForm: FormGroup;
  public enSteps: FormArray;
  public frSteps: FormArray;

  ngOnInit(): void {
    this.bindData();
  }

  ngOnChanges() {
    this.bindData();
  }

  public bindData() {
    this.enForm = this.fb.group({
      section_title: [this.patientJourney.en.section_title, [Validators.required, Validators.maxLength(this.type1MaxLength)]],
      steps: this.fb.array([])
    });
    this.frForm = this.fb.group({
      section_title: [this.patientJourney.fr.section_title, [Validators.required, Validators.maxLength(this.type1MaxLength)]],
      steps: this.fb.array([])
    });

    this.enSteps = this.enForm.get('steps') as FormArray;
    this.frSteps = this.frForm.get('steps') as FormArray;
    let randomNumber = Math.floor(1000 + Math.random() * 9000);
    this.patientJourney.en.steps.forEach(stepData => {
      const stepEnFormGroup = this.fb.group({
        title: [stepData.title, [Validators.required, Validators.maxLength(this.type3MaxLength)]],
        desc: [stepData.desc, [Validators.required, Validators.maxLength(this.type2MaxLength)]],
        image_url: [stepData.image_url, [Validators.required]],
        step_image_url: [stepData.image_url + '?v=' + randomNumber, [Validators.required]],
        is_invalid_file: false,
        is_file_changed: false,
        image_name: [stepData.image_name]
      });
      this.enSteps.push(stepEnFormGroup);
    });


    this.patientJourney.fr.steps.forEach(stepData => {
      const stepEnFormGroup = this.fb.group({
        title: [stepData.title, [Validators.required, Validators.maxLength(this.type3MaxLength)]],
        desc: [stepData.desc, [Validators.required, Validators.maxLength(this.type2MaxLength)]],
        image_url: [stepData.image_url, [Validators.required]],
        step_image_url: [stepData.image_url + '?v=' + randomNumber, [Validators.required]],
        is_file_changed: false,
        is_invalid_file: false,
        image_name: [stepData.image_name]

      });
      this.frSteps.push(stepEnFormGroup);
    });
  }

  save() {
    this.enForm.markAllAsTouched();
    this.frForm.markAllAsTouched();
    let enFormInvalid = this.enForm.controls["steps"].value.some((obj: any) => obj.is_invalid_file === true);
    let frFormInvalid = this.frForm.controls["steps"].value.some((obj: any) => obj.is_invalid_file === true);
    if ((this.enForm.valid && this.frForm.valid) && enFormInvalid == false && frFormInvalid == false) {
      this.openPopup();
    }
    else {
      if (this.selectedLangTabIndex == 0 && this.enForm.valid && enFormInvalid == false && !this.frForm.valid) {
        this.selectedLangTabIndex = 1;
      }
      else if (this.selectedLangTabIndex == 1 && !this.enForm.valid && frFormInvalid == false && this.frForm.valid) {
        this.selectedLangTabIndex = 0;
      }
    }
  }

  public onLangTabChange(index: number) {
    this.selectedLangTabIndex = index;
  }

  public openPopup() {
    let popupRef = this.messageService
      .confirm(
        this.translateService.instant('landing-page-config.save-confirm-description'),
        this.translateService.instant('landing-page-config.save-title'),
        this.translateService.instant('landing-page-config.yes'),
        this.translateService.instant('landing-page-config.no')
      );

    popupRef.subscribe((actionResult) => {
      if (actionResult) {
        this.spinner.show(true);
        this.enForm.get("steps").value.forEach((element: any) => {
          element.step_image_url = "";
        });
        this.frForm.get("steps").value.forEach((element: any) => {
          element.step_image_url = "";
        });
        //this.enForm.get("step_image_url").setValue('');
        //this.frForm.get("step_image_url").setValue('');
        let payload = {
          "tab_name": "yourpatientjourney",
          "page_data": JSON.stringify({
            "en": this.enForm.value,
            "fr": this.frForm.value
          }),
          "image_data": this.updatedImages
        }

        this.updateData.emit(payload);
      }
    });
  }

  isValidImage(file: any): boolean {
    let isImageValid = true;
    if (file.size == 0 || file.size > 500 * 1024) {
      isImageValid = false;
    }
    if (
      file.type != 'image/png' &&
      file.type != 'image/jpg' &&
      file.type != 'image/svg+xml' &&
      file.type != 'image/jpeg'
    ) {
      isImageValid = false;
    }

    if (
      !file.name.toLowerCase().includes('.png') &&
      !file.name.toLowerCase().includes('.jpg') &&
      !file.name.toLowerCase().includes('.svg') &&
      !file.name.toLowerCase().includes('.jpeg')
    ) {
      isImageValid = false;
    }

    return isImageValid;
  }

  inputChange(event: any, stepIndex: number) {
    let enStep = this.enSteps.at(stepIndex);
    let frStep = this.frSteps.at(stepIndex);
    const reader = new FileReader();
    const file = event.target.files[0];
    if (file) {
      if (!this.isValidImage(file)) {
        enStep.get('is_invalid_file').setValue(true);
        frStep.get('is_invalid_file').setValue(true);
      } else {
        reader.readAsDataURL(file);
        reader.onload = () => {
          enStep.get('is_file_changed').setValue(true);
          enStep.get('is_invalid_file').setValue(false);
          enStep.get('step_image_url').setValue(reader.result.toString());
          enStep.get('image_name').setValue(file.name);

          frStep.get('is_file_changed').setValue(true);
          frStep.get('is_invalid_file').setValue(false);
          frStep.get('step_image_url').setValue(reader.result.toString());
          frStep.get('image_name').setValue(file.name);

          let iIndex = this.updatedImages.findIndex(i => i.image_url == enStep.get('image_url').value);
          if (iIndex > -1) {
            this.updatedImages[iIndex].image_data = reader.result.toString();
          }
          else {
            this.updatedImages.push({ "image_url": enStep.get('image_url').value, "image_data": reader.result.toString() });
          }
        };
      }
    }
  }
}
