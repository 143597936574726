<div class="error-container">
    <div class="error-container-details">
        <div>
            <img src="{{ 'assets/images/error-icons/' + errorStatusCode + '.svg' }}" width="100%" alt="error icon">

            <ng-container *ngIf="errorStatusCode === '401' else forbidden">
                <p i18n="@@Common.GlobalError.UnauthorizedErrorTitle">{{'Common.GlobalMessages.UnauthorizedErrorTitle'|translate}}</p>
                <p i18n="@@Common.GlobalError.UnauthorizedErrorMessage">{{'Common.GlobalMessages.UnauthorizedErrorMessage'|translate}}
                </p>
            </ng-container>

            <ng-template #forbidden>
                <ng-container *ngIf="errorStatusCode === '403' else notfound">
                    <p i18n="@@Common.GlobalError.ForbiddenErrorTitle">{{'Common.GlobalMessages.ForbiddenErrorTitle'|translate}}</p>
                    <p i18n="@@Common.GlobalError.ForbiddenErrorMessage">{{'Common.GlobalMessages.ForbiddenErrorMessage'|translate}}
                    </p>
                </ng-container>
            </ng-template>

            <ng-template #notfound>
                <ng-container *ngIf="errorStatusCode === '404' else internal">
                    <p i18n="@@Common.GlobalError.NotFoundErrorTitle">{{'Common.GlobalMessages.NotFoundErrorTitle'|translate}}</p>
                    <p i18n="@@Common.GlobalError.NotFoundErrorMessage">{{'Common.GlobalMessages.NotFoundErrorMessage'|translate}}
                    </p>
                </ng-container>
            </ng-template>

            <ng-template #internal>
                <p i18n="@@Common.GlobalError.InternalServerErrorTitle">{{'Common.GlobalMessages.InternalServerErrorTitle'|translate}}</p>
                <p i18n="@@Common.GlobalError.InternalServerErrorMessage">{{'Common.GlobalMessages.InternalServerErrorMessage'|translate}}
                </p>
            </ng-template>
            <ng-template #nocalendar>
                <p i18n="@@Common.GlobalError.noCalendar">{{'global-error.NoCalendarMessage'|translate}}
                </p>
            </ng-template>
            <button *ngIf="isNavigateBackRequired" mat-button color="accent" class="ghost" (click)="navigateBack()">{{'global-error.goback'|translate}}</button>
        </div>
    </div>
</div>
