<div class="test-kit-container">
  <header class="pull-left">
    <h3 class="pull-left">{{'testkits-without-result-com.testkit-without-result'|translate}} <span
        class="count">{{totalCount}}</span></h3>
    <app-datepicker id="dateFilter" [dateInput]="dateFilter" (selectedDate)="onSelectedDate($event)"></app-datepicker>
  </header>
  <div class="div">
    <h6 class="pull-left">{{'testkits-without-result-com.header-text'|translate}}</h6>
  </div>
  <table *ngIf="refresh" class="ecl-table" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{'testkits-without-result-com.name' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="flex-td alignMiddle">
        <div class="avatar onlyMobile"><span class="titleWrapBox">{{element.name.charAt(0).toUpperCase()}}</span></div>
        <span class="title onlyDesktop">{{element.name}}</span>
        <div class="mobile-td onlyMobile">
          <span class="title"><span>{{element.name}}</span></span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="qr_id">
      <th mat-header-cell *matHeaderCellDef>{{'testkits-without-result-com.qr' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="alignMiddle">
        <span class="title"><span class="icon-ic-mail-1 onlyMobile"></span><span>{{element.qr_id}}</span></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="barcode">
      <th mat-header-cell *matHeaderCellDef>{{'testkits-without-result-com.testkitcode' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="alignMiddle">
        <span class="title"><span
            class="icon-ic-test-kit-code onlyMobile"></span><span>{{element.barcode}}</span></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="short_name">
      <th mat-header-cell *matHeaderCellDef>{{'testkits-without-result-com.test' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="alignMiddle">
        <span class="title"><span class="icon-ic-test onlyMobile"></span><span>{{element.short_name}}</span></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="sampling_time">
      <th mat-header-cell *matHeaderCellDef>{{'testkits-without-result-com.date' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="alignMiddle">
        <span class="title"><span class="icon-ic-date onlyMobile"></span><span>{{element.sampling_time |
            date:'dd/MM/yyyy HH:mm'}}</span></span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="force-display">
        <div class="mobile-action">
          <button mat-button color="accent" class="ghost" type="button"
            (click)="submitResult(element)">{{'testkits-without-result-com.submit-result' | translate}}</button>
          <button mat-button mat-raised-button color="accent" class="ghost" type="button"
            (click)="cancelTestKit(element)">{{'testkits-without-result-com.cancel' | translate}}</button>
        </div><div class="onlyDesktop">
          <button mat-button color="accent" class="ghost submitDesktop" type="button"
            (click)="submitResult(element)">{{'testkits-without-result-com.submit-result' | translate}}</button>
          <button mat-icon-button matTooltip="{{'testkits-without-result-com.cancel' | translate}}" color="accent" class="mat-tbl-icon cancelDesktop" type="button"
            (click)="cancelTestKit(element)"><span class="icon-ic-cancel-testkits"></span></button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="noRecord">
      <td colspan="5" mat-footer-cell *matFooterCellDef>
        <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
        <div class="desc">
          <p>{{'testkits-without-result-com.no-record-found'|translate}}</p>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <ng-template [ngIf]="dataSource && dataSource.length === 0">
      <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
    </ng-template>
  </table>
</div>