import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { LocaleService } from '../../../app.locale.service';

@Component({
    selector: 'app-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
    @Input() public blobSrc: any;
    @Input() public pdftitle: any;
    @Input() public pdfFileName: string;
    @Input() allPages: boolean;
    @Input() replacePrescription = false;
    @Input() patientComments: string;
    @Output() replacePrescriptionClicked = new EventEmitter();
    @Output() downloaded = new EventEmitter();
    @Output() closed = new EventEmitter();
    totalPage: number;
    isPdfLoaded: boolean;
    pages: number[] = [];
    pdfPage = 1;
    lastPage: number;
    viewComment: boolean = false;

    constructor(public sanitizer: DomSanitizer, private translate: TranslateService,
        private localeService: LocaleService,) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    ngOnInit(): void {
    }

    downloadPres(blobUrl: any) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = blobUrl;
        if (this.pdfFileName) {
            a.download = this.pdfFileName + '.pdf';
        } else if (this.pdftitle) {
            a.download = this.pdftitle + '.pdf';
        } else {
            a.download = 'sample.pdf';
        }
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        a.remove();
    }

    closePdf() {
        this.closed.emit();
    }

    protected replacePres() {
        this.replacePrescriptionClicked.emit();
    }

    afterLoadComplete(pdf: PDFDocumentProxy) {
        this.isPdfLoaded = true;
        this.pages = Array(pdf.numPages).fill(1).map((_x, i) => i + 1);
        this.totalPage = pdf.numPages;
    }
}
