import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ComponentFactoryResolver,
} from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { int } from "@zxing/library/esm/customTypings";
import { distinctUntilChanged, tap } from "rxjs/operators";
import { LocaleService } from "../../../app.locale.service";
import { SpinnerService } from "../../../core";
import { LoaderService } from "../../../main/services/loader.service";
import { MessageService } from "../../../main/services/message.service";
import { PatientService } from "../../services/patient.service";
import { PatientDocument } from "./patient-document.model";
import { UploadDocumentComponent } from "./upload-document/upload-document.component";
import * as moment from "moment";
import { Location } from '@angular/common';

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.scss"],
})
export class DocumentsComponent implements OnInit {
  @Output() editButtonClicked = new EventEmitter();
  @Output() viewPdf = new EventEmitter();
  @Output() uploadPresc = new EventEmitter();
  ELEMENT_DATA: any[] = [];
  avilableDocumentTypes: any[] = [];
  selectedDoc: number;
  refresh = true;
  patientId = "";
  deleteTitle = "patient.delete-title";
  deleteconfirmtext = "patient.delete-confirmtext";
  pdfBlob: string;
  isPdfViewerOpened: boolean;
  document: any;
  path: any;
  docs: any;
  uploadedDocs: any;
  docTypes: any;
  displayedColumns: string[] = [
    "type",
    "fileDesc",
    "details",
    "dateTime",
    "actions",
  ];
  dataSource: any = [];
  uploadSliderContainer: any;
  protected patientDocsUploaded = 0;
  sub: any;
  private loginId: any;
  date = new Date();
  optionalMutualCertificateIdentifier = "Mutual certificate(";
  mandataryMutualCertificateIdentifier = "Mutual certificate";

  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
    .pipe(
      tap((value) => console.log(value)),
      distinctUntilChanged()
    );
  protected tabSelection: number = 0;
  protected navigationDetails: any;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private componentFactoryResolver: ComponentFactoryResolver,
    public router: Router,
    public loader: LoaderService,
    private readonly messageService: MessageService,
    private translate: TranslateService,
    private localeService: LocaleService,
    private patientService: PatientService,
    private spinner: SpinnerService,
    private location: Location
  ) {
    this.breakpoint$.subscribe(() => this.breakpointChanged());
    this.ELEMENT_DATA = new Array<PatientDocument>();
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
    this.navigationDetails = this.location.getState();
    if (
      this.navigationDetails && this.navigationDetails?.document && !this.navigationDetails?.path
    ) {
      this.document = this.navigationDetails?.document;
      this.docTypes = this.navigationDetails?.doctype;
      this.uploadDocument(this.document, this.docTypes);
    }
    if (
      this.navigationDetails && this.navigationDetails?.path
    ) {
      this.path = this.navigationDetails?.path;
      this.openImage(this.path.path);
    }
  }

  ngOnInit(): void {
    // this.loader.onFormClose().subscribe(() => {
    //   this.getPatientDocument();
    // });
    this.patientId = JSON.parse(sessionStorage.getItem("userDetails")).id;
    this.loginId = JSON.parse(sessionStorage.getItem("userDetails")).login_id;
    //this.getPatientDocument();
    if (this.loader.redirectRelMem) {
      this.tabSelection = 1;
      this.loader.redirectRelMem = false;
  }
  }

  protected checkExpiry(input: any) {
    let currTime = moment(this.date);
    let expTime = moment(input.expiry_date);
    let val =
      moment(currTime).format("YYYY-MM-DD") >=
      moment(expTime).format("YYYY-MM-DD");
    return val;
  }

  public getPatientDocument() {
    this.refresh = false;
    this.spinner.show(true);
    this.patientService
      .getPatientDocuments(this.patientId)
      .subscribe((documents: any) => {
        if (documents != null) {
          this.ELEMENT_DATA = documents;
          this.patientDocsUploaded = documents.length;
        } else {
          this.ELEMENT_DATA = [];
        }
        this.dataSource = [];
        this.dataSource = this.ELEMENT_DATA;
        this.patientService
          .getRequiredDocumentByPatientId(this.patientId, this.loginId, true)
          .subscribe((documents: any) => {
            documents = documents.documents;
            if (documents != null) {
              this.ELEMENT_DATA = documents.filter((item: any) => item["eng_key_name"].indexOf(this.optionalMutualCertificateIdentifier) < 0);
              for (let key of documents) {
                key["document_type"] = key["key_name"];
              }
            } else {
              this.ELEMENT_DATA = [];
            }
            this.docs = documents;
            this.uploadedDocs = this.dataSource;
            if (!this.dataSource.some((item: any) => item["document_path"] && item["document_type"].indexOf(this.mandataryMutualCertificateIdentifier) >= 0)) {
              this.docs = this.docs.filter((item: any) => item["eng_key_name"].indexOf(this.optionalMutualCertificateIdentifier) < 0);
            }
            this.dataSource = [...this.dataSource, ...this.ELEMENT_DATA];
            this.dataSource.sort((a: any, b: any) =>
              this.translate
                .instant(a.document_type)
                .localeCompare(this.translate.instant(b.document_type))
            );
            if (this.loader.redirectDocuments) {
              const index = this.docs.findIndex((item: any) => item.id == this.loader.redirectDocuments);
              if (index >= 0) {
                var data = this.docs[index];
                this.selectDocument(data);
              }
              this.loader.redirectDocuments = null;
            }
            this.spinner.show(false);
          });
        this.refresh = true;
      });
  }

  private breakpointChanged() {
    if (
      this.breakpointObserver.isMatched(
        "(max-width: 1050px) and (min-width: 601px)"
      )
    ) {
      this.uploadSliderContainer = 640;
    } else if (this.breakpointObserver.isMatched("(max-width: 600px)")) {
      this.uploadSliderContainer = "100%";
    } else {
      this.uploadSliderContainer = 664;
    }
  }

  uploadDocument(data?: any, docType?: any) {
    this.avilableDocumentTypes = this.docs;
    if (docType) {
      this.avilableDocumentTypes = docType;
    } else {
      data.patient_id = this.patientId;
    }
    data.EditAction = 0;
    data.avilableDocumentTypes = this.avilableDocumentTypes;
    data.uploadedDocs = this.uploadedDocs;
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        UploadDocumentComponent
      );
    this.loader.addDynamicComponent(
      componentFactory,
      this.uploadSliderContainer,
      data
    );
  }

  editDocument(data?: any) {
    data.EditAction = 1;
    data.patient_id = this.patientId;
    data.avilableDocumentTypes = this.dataSource;
    data.uploadedDocs = this.uploadedDocs;
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        UploadDocumentComponent
      );
    this.loader.addDynamicComponent(
      componentFactory,
      this.uploadSliderContainer,
      data
    );
  }
  selectDocument(data?: any) {
    data.Action = 1;
    data.EditAction = 0;
    data.patient_id = this.patientId;
    data.avilableDocumentTypes = this.docs;
    data.uploadedDocs = this.uploadedDocs;
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        UploadDocumentComponent
      );
    this.loader.addDynamicComponent(
      componentFactory,
      this.uploadSliderContainer,
      data
    );
  }

  confirmDeleteWithYesNo(id: int) {
    this.messageService
      .confirm(this.deleteconfirmtext, this.deleteTitle)
      .subscribe((actionResult: boolean) => {
        if (actionResult) {
          this.spinner.show(true);
          this.patientService
            .deletePatientDocument(id)
            .subscribe((res: any) => {
              res;
              this.loader.onFormClose(true);
              this.messageService.success(
                this.translate.instant("documents.deleted-successfully")
              );
              this.spinner.show(false);
            });
        }
      });
  }

  openImage(path: any) {
    const docName = path.substring(path.lastIndexOf("/") + 1);
    this.spinner.show(true);
    this.patientService.getPatientDocument(docName).subscribe((res: any) => {
      const byteArray = new Uint8Array(
        atob(res)
          .split("")
          .map((char) => char.charCodeAt(0))
      );
      const file = new Blob([byteArray], { type: "application/octet-stream" });
      this.pdfBlob = URL.createObjectURL(file);
      this.spinner.show(false);
      this.isPdfViewerOpened = true;
    });
  }

  protected isOptionalMutualCertificate(element: any) {
    return (
      element.key_name &&
      element.eng_key_name.indexOf(this.optionalMutualCertificateIdentifier) < 0
    );
  }

  protected checkUploadPending(element: any): boolean {
    const privateInsurance = JSON.parse(
      sessionStorage.getItem("userDetails")
    ).private_insurance;
    if (privateInsurance) {
      return (
        element.key_name &&
        element.key != "CovidPassport" &&
        element.key != "Prescription" &&
        element.eng_key_name.indexOf(this.optionalMutualCertificateIdentifier) < 0
      );
    } else {
      return (
        element.key_name &&
        element.key != "CovidPassport" &&
        element.key != "Prescription" &&
        element.key != "Insurance"
      );
    }
  }
}
