<div id="createAccount_Slider" class="slider-data-container">
    <div class="header">
        <div style="text-align: center; margin-bottom: 10px;">
            <app-section-title [title]="title"></app-section-title>
        </div>
    </div>
    <mat-divider></mat-divider>
    <mat-list *ngFor="let listname of listData;  let indexOfelement = index;">
        <mat-list-item>{{indexOfelement+1}} . {{listname}} </mat-list-item>
        <mat-divider></mat-divider>

    </mat-list>

    <div class="actions">
        <button mat-button color="accent" mat-dialog-close="true" class="ghost button_width" type="submit"
            id="return_btn">
            Close
        </button>

    </div>
</div>