<div class="hero-banner-container">
    <div class="d-flex flex-column">
        <header class="pull-left">
            <h5 class="pull-left"> {{'patient-journey.heading'|translate}}</h5>
        </header>
        <div class="select-lang-title">
            {{'landing-page-config.select-lang'|translate}}
        </div>
        <mat-tab-group class="pull-left languageTab mt-10" [(selectedIndex)]="selectedLangTabIndex"
            (selectedTabChange)="onLangTabChange($event.index)" animationDuration="1000ms">
            <mat-tab label="{{'landing-page-config.lang1'|translate}}">
                <form [formGroup]="enForm">
                    <div class="heroBannerWrapper">
                        <div class="field-wrapper">
                            <mat-form-field>
                                <mat-label class="label-float">{{'patient-journey.field1' | translate}}</mat-label>
                                <input matInput formControlName="section_title" name="enField1" id="enField1"
                                    max="{{type1MaxLength}}" maxlength="{{type1MaxLength}}" />
                            </mat-form-field>
                            <div class="character-count">
                                {{enForm.get('section_title')?.value?.length}}/{{type1MaxLength}}
                            </div>
                        </div>
                        <div> </div>
                    </div>
                    <div formArrayName="steps">
                        <div class="heroBannerWrapper" *ngFor="let enStep of enSteps.controls; let i = index"
                            [formGroupName]="i">
                            <div class="section-title">
                                {{'patient-journey.step'|translate}} {{i+1}}
                            </div>
                            <div></div>
                            <div class="field-wrapper">
                                <mat-form-field>
                                    <mat-label class="label-float">{{'patient-journey.step-title' |
                                        translate}}</mat-label>
                                    <textarea matInput cols="30" name="enStepOneTitle" id="enStepOneTitle"
                                        formControlName="title" rows="2" [maxlength]="type3MaxLength"></textarea>
                                </mat-form-field>
                                <div class="character-count">
                                    {{enStep.get('title')?.value?.length}}/{{type3MaxLength}}
                                </div>
                            </div>
                            <div class="field-wrapper">
                                <mat-form-field>
                                    <mat-label>{{'patient-journey.step-desc' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="enStepOneDesc" id="enStepOneDesc"
                                        formControlName="desc" rows="2" [maxlength]="type2MaxLength"></textarea>
                                </mat-form-field>
                                <div class="character-count">
                                    {{enStep.get('desc')?.value?.length}}/{{type2MaxLength}}
                                </div>
                            </div>
                            <div class="field-row-wrapper">
                                <img src="{{enStep.get('step_image_url').value}}">
                                <div class="field-wrapper w-100">
                                    <mat-form-field class="pointCursor browse-input" (click)="fileInput.click()">
                                        <mat-label class="form-label"
                                            i18n="@@Common.Component.BrowseFile">{{'landing-page-config.browse-image'
                                            |
                                            translate}}</mat-label>

                                        <input matInput formControlName="image_name"
                                            *ngIf="enStep.get('image_name').value == ''" id="uploadFileName" readonly />
                                        <input matInput formControlName="image_name"
                                            *ngIf="enStep.get('image_name').value != ''"
                                            placeholder="{{enStep.get('image_name').value}}" id="uploadFileName"
                                            readonly />
                                        <button class="mat-tbl-icon buttonInsideField" id="uploadFile">
                                            {{'upload-pic.browse' | translate}}
                                        </button>
                                    </mat-form-field>
                                    <input hidden type="file" accept=".png, .jpeg, .jpg, .svg" #fileInput
                                        (change)="inputChange($event,i)" />

                                    <div class="character-count"
                                        [ngClass]="(enStep.get('is_invalid_file').value)?'color-error':''">
                                        {{'patient-journey.image-info'|translate}}
                                    </div>
                                </div>
                            </div>
                            <div>
                            </div>

                        </div>
                    </div>
                </form>
            </mat-tab>
            <mat-tab label="{{'landing-page-config.lang2'|translate}}">
                <form [formGroup]="frForm">
                    <div class="heroBannerWrapper">
                        <div class="field-wrapper">
                            <mat-form-field>
                                <mat-label class="label-float">{{'patient-journey.field1' | translate}}</mat-label>
                                <input matInput formControlName="section_title" name="frField1" id="frField1"
                                    max="{{type1MaxLength}}" maxlength="{{type1MaxLength}}" />
                            </mat-form-field>
                            <div class="character-count">
                                {{enForm.get('section_title')?.value?.length}}/{{type1MaxLength}}
                            </div>
                        </div>
                        <div> </div>
                    </div>
                    <div formArrayName="steps">
                        <div class="heroBannerWrapper" *ngFor="let frStep of frSteps.controls; let i = index"
                            [formGroupName]="i">
                            <div class="section-title">
                                {{'patient-journey.step'|translate}}{{i+1}}
                            </div>
                            <div></div>
                            <div class="field-wrapper">
                                <mat-form-field>
                                    <mat-label class="label-float">{{'patient-journey.step-title' |
                                        translate}}</mat-label>
                                    <textarea matInput cols="30" name="frStepOneTitle" id="frStepOneTitle"
                                        formControlName="title" rows="2" [maxlength]="type3MaxLength"></textarea>
                                </mat-form-field>
                                <div class="character-count">
                                    {{frStep.get('title')?.value?.length}}/{{type3MaxLength}}
                                </div>
                            </div>
                            <div class="field-wrapper">
                                <mat-form-field>
                                    <mat-label>{{'patient-journey.step-desc' | translate}}</mat-label>
                                    <textarea matInput cols="30" name="frStepOneDesc" id="frStepOneDesc"
                                        formControlName="desc" rows="2" [maxlength]="type2MaxLength"></textarea>
                                </mat-form-field>
                                <div class="character-count">
                                    {{frStep.get('desc')?.value?.length}}/{{type2MaxLength}}
                                </div>
                            </div>
                            <div class="field-row-wrapper">
                                <img src="{{frStep.get('step_image_url').value}}">
                                <div class="field-wrapper w-100">
                                    <mat-form-field class="pointCursor browse-input" (click)="fileInput.click()">
                                        <mat-label class="form-label"
                                            i18n="@@Common.Component.BrowseFile">{{'landing-page-config.browse-image'
                                            |
                                            translate}}</mat-label>

                                        <input matInput formControlName="image_name"
                                            *ngIf="frStep.get('image_name').value == ''" id="uploadFileName" readonly />
                                        <input matInput formControlName="image_name"
                                            *ngIf="frStep.get('image_name').value != ''"
                                            placeholder="{{frStep.get('image_name').value}}" id="uploadFileName"
                                            readonly />
                                        <button class="mat-tbl-icon buttonInsideField" id="uploadFile">
                                            {{'upload-pic.browse' | translate}}
                                        </button>
                                    </mat-form-field>
                                    <input hidden type="file" accept=".png, .jpeg, .jpg, .svg" #fileInput
                                        (change)="inputChange($event,i)" />

                                    <div class="character-count"
                                        [ngClass]="(frStep.get('is_invalid_file').value)?'color-error':''">
                                        {{'patient-journey.image-info'|translate}}
                                    </div>
                                </div>
                            </div>
                            <div>
                            </div>

                        </div>
                    </div>
                </form>
            </mat-tab>
        </mat-tab-group>
        <div class="actions">
            <button mat-raised-button color="primary" class="ghost submit-click"
                (click)="save()">{{'landing-page-config.save' |
                translate}}</button>
            <button mat-button color="accent" class="ghost"
                (click)="cancelClicked.emit()">{{'landing-page-config.cancel' |
                translate}}</button>
        </div>
    </div>
</div>