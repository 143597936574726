<div class="qr-code-container">
  <header class="pull-left">
    <h3 class="pull-left">{{ 'qr-code-comp.myqr-code' | translate }}</h3>
  </header>
  <mat-tab-group [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabChange()" class="tab-app pull-left" animationDuration="1000ms">
    <!-- all members -->
    <mat-tab label="All">
      <div class="qr-code-grid">
        <div class="qr-code-cover">
          <app-qr-code width="236" [data]="qr_id"></app-qr-code>
          <footer>
            <h2>{{firstName }} {{lastName }}</h2>
            <p><span>{{ 'qr-code-comp.qr-id' | translate }}</span><span>{{qr_id }}</span></p>
          </footer>
        </div>
        <div *ngFor="let record of RM_DATA" class="qr-code-cover">
          <app-qr-code width="236" [data]="record.qr_id"></app-qr-code>
          <footer>
            <h2>{{record.rm_fname }} {{record.rm_lname }}</h2>
            <p><span>{{ 'qr-code-comp.qr-id' | translate }}</span><span>{{ record.qr_id }}</span></p>
          </footer>
        </div>
      </div>
    </mat-tab>

    <!-- primary account -->
    <mat-tab label="{{firstName}} {{lastName}}">
      <div class="qr-code-cover">
        <app-qr-code width="236" [data]="qr_id"></app-qr-code>
        <footer>
          <h2>{{firstName }} {{lastName }}</h2>
          <p><span>{{ 'qr-code-comp.qr-id' | translate }}</span><span>{{qr_id }}</span></p>
        </footer>
      </div>
    </mat-tab>
    <!-- rm members -->
    <mat-tab *ngFor="let record of RM_DATA" [label]="record.rm_fname + ' ' + record.rm_lname">
      <div class="qr-code-cover">
        <app-qr-code width="236" [data]="record.qr_id"></app-qr-code>
        <footer>
          <h2>{{ record.rm_fname }} {{record.rm_lname }}</h2>
          <p><span>{{ 'qr-code-comp.qr-id' | translate }}</span><span>{{ record.qr_id }}</span></p>
        </footer>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
