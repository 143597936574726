import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookingSetupComponent } from '../admin/booking/booking-setup/booking-setup.component';
import { FaqComponent } from '../shared/faq/faq.component';

import { AppointmentsComponent } from './appointments/appointments.component';
import { FindPatientComponent } from './find-patient/find-patient.component';
import { PatientInformationComponent } from './patient-information/patient-information.component';
import { ReceptionistComponent } from './receptionist.component';
import { RegisterResultComponent } from './register-result/register-result.component';
import { RegisterTestKitComponent } from './register-test-kit/register-test-kit.component';
import { ResultsComponent } from './results/results.component';
import { TestkitsWithoutResultComponent } from './testkits-without-result/testkits-without-result.component';
import { HelpManualComponent } from '../shared/help-manual/help-manual.component';
import { BookingFlowComponent } from '../shared/booking-flow/booking-flow.component';
const routes: Routes = [{
    path: '', component: ReceptionistComponent,
    children: [
        { path: 'find-patient', component: FindPatientComponent },
        { path: 'patient-info', component: PatientInformationComponent },
        { path: 'appointments', component: AppointmentsComponent },
        { path: 'results', component: ResultsComponent },
        // { path: 'booking', component: BookingCalendarComponent},
        { path: 'book-appointment', component: BookingSetupComponent },
        { path: 'book-appointment/:l_id', component: BookingFlowComponent },
        { path: 'register-result', component: RegisterResultComponent },
        { path: 'register-test-kit', component: RegisterTestKitComponent },
        { path: 'testkits-without-result', component: TestkitsWithoutResultComponent },
        { path: 'faq', component: FaqComponent },
        { path: 'help-manual', component: HelpManualComponent }
    ]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ReceptionistRoutingModule { }
