import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../../app.locale.service';
import { LoaderService } from '../../../../main';
import { ResultStatus } from '../../../patient/results/results.model';

@Component({
    selector: 'app-results-ready',
    templateUrl: './results-ready.component.html',
    styleUrls: ['./results-ready.component.scss']
})
export class ResultsReadyComponent implements OnInit {
    @Input() displayedColumns: any;
    @Input() dataSource: any;
    @Input() refresh: boolean;
    @Input() infoPage: boolean = false;
    @Output() pdfviewClicked = new EventEmitter();
    @Input() resultStatusList: ResultStatus[];
    @Output() showResult = new EventEmitter();
    @Output() nameClicked = new EventEmitter();


    constructor(
        private translate: TranslateService,
        private localeService: LocaleService,
        public loader: LoaderService
    ) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    ngOnInit(): void { }

    /**
   * This method will form an object for displaying status in stepper, It will form the object depending on the statusID.
   * @param statusId - StatusId received from Appointment data.
   * @returns Status Object containing an array of status for stepper.
   */
    getStatusArray(statusId: number) {
        if (this.resultStatusList[0].Id === statusId) {
            return [
                { title: 'results.recieved', status: 'Completed' },
                { title: 'results.in-progress', status: 'InProgress' },
                { title: 'results.analysis-validation', status: 'InProgress' },
                { title: 'results.result-available', status: 'InProgress' }
            ];
        } else if (this.resultStatusList[1].Id === statusId) {
            return [
                { title: 'results.recieved', status: 'Completed' },
                { title: 'results.in-progress', status: 'Completed' },
                { title: 'results.analysis-validation', status: 'InProgress' },
                { title: 'results.result-available', status: 'InProgress' }
            ];
        } else if (this.resultStatusList[2].Id === statusId) {
            return [
                { title: 'results.recieved', status: 'Completed' },
                { title: 'results.in-progress', status: 'Completed' },
                { title: 'results.analysis-validation', status: 'Completed' },
                { title: 'results.result-available', status: 'InProgress' }
            ];
        } else if (this.resultStatusList[3].Id === statusId) {
            return [
                { title: 'results.recieved', status: 'Completed' },
                { title: 'results.in-progress', status: 'Completed' },
                { title: 'results.analysis-validation', status: 'Completed' },
                { title: 'results.result-available', status: 'Completed' }
            ];
        } else {
            return [
                { title: 'results.recieved', status: 'InProgress' },
                { title: 'results.in-progress', status: 'InProgress' },
                { title: 'results.analysis-validation', status: 'InProgress' },
                { title: 'results.result-available', status: 'InProgress' }
            ];
        }
    }

    /**
   * This method will check if result is available or not, so if TestResult and InternetAccessId field are not empty and statusID is fall under
   * getResultstatusId APIs response then will return true otherwise false.
   * @param item Appointment Data
   * @returns true - if result is available otherwise false.
   */
    checkifResultIsAvailable(item: any) {
        if (
            item.StatusId < this.resultStatusList[this.resultStatusList.length - 1].Id
        ) {
            return false;
        }

        if (item.TestResult === undefined) {
            return false;
        }

        if (item.InternetAccessId === undefined) {
            return false;
        }

        if ((item.TestResult.lenght !== 0 && item.InternetAccessId.lenght !== 0) || item.DiagnosisReportUrl != "") {
            return true;
        }

        return false;
    }

    onNameClicked(element: any) {
        this.nameClicked.emit({ 'data': element });
    }

}
