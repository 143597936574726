<table class="ecl-table" width="100%" mat-table *ngIf="refresh" [dataSource]="dataSource">
  <ng-container matColumnDef="patient">
    <th mat-header-cell *matHeaderCellDef class="width-17">{{'all-apointment.patient'|translate}}</th>
    <td mat-cell *matCellDef="let element" class="flex-td"
      [ngClass]="{'cancelled-column':element.IsDeleted, 'modified-column':element.IsModified}">
      <span class="title">{{element.FirstName}} {{element.LastName}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="test">
    <th mat-header-cell *matHeaderCellDef> {{'past-apointment.test'|translate}}</th>
    <td mat-cell *matCellDef="let element" class="flex-td"
      [ngClass]="{'cancelled-row':element.IsDeleted, 'modified-row':element.IsModified}">
      <div class="avatar onlyMobile"><span class="titleWrapBox">{{element.TestShortName.charAt(0)}}</span></div>
      <span class="title onlyDesktop" *ngIf="element.TestShortName">{{element.TestShortName}}</span>
      <span class="subText onlyDesktop" *ngIf="element.TestType">{{loader.TranslateLang(element.TestType)}}</span>
      <div class="mobile-td onlyMobile">
        <span class="title" *ngIf="element.TestShortName">{{element.TestShortName}}</span>
        <span class="subText" *ngIf="element.TestType">{{loader.TranslateLang(element.TestType)}}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="lab">
    <th mat-header-cell *matHeaderCellDef> {{'past-apointment.lab'|translate}} </th>
    <td mat-cell *matCellDef="let element" class="flex-td"
      [ngClass]="{'cancelled-row':element.IsDeleted, 'modified-row':element.IsModified}">
      <div class="onlyDesktop">
        <span class="title" *ngIf="element.SamplingStationName"><span>{{element.SamplingStationName}}</span></span>
        <span class="subText link" *ngIf="element.AddressDetails">{{element.AddressDetails}}</span>
      </div>
      <div class="mobile-td onlyMobile">
        <span class="title" *ngIf="element.SamplingStationName"><span
            class="icon-ic_navigate onlyMobile link"></span><span
            class="link">{{element.SamplingStationName}}</span></span>
        <span class="title onlyMobile light" *ngIf="element.fileDesc?.title">{{element.fileDesc?.title}}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="labContact">
    <th mat-header-cell *matHeaderCellDef> {{'past-apointment.lab-contact'|translate}} </th>
    <td mat-cell *matCellDef="let element"
      [ngClass]="{'cancelled-row':element.IsDeleted, 'modified-row':element.IsModified}">
      <span class="title" *ngIf="isMobile"><span class="icon-ic-mobile onlyMobile expadding"></span>
        <span class="marginb numcolor">
          <a href="tel:{{element.PhoneNumber | removeZero}} ">{{element.PhoneNumber |
            phonenoFormate}}</a>
        </span></span>
      <span class="title" *ngIf="!isMobile"><span class="icon-ic-mobile onlyMobile expadding"></span>
        <span class="marginb  expadding">{{element.PhoneNumber}}
        </span>
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="requestNo">
    <th mat-header-cell *matHeaderCellDef>{{'all-apointment.requestno'|translate}} </th>
    <td mat-cell *matCellDef="let element"
      [ngClass]="{'cancelled-row':element.IsDeleted, 'modified-row':element.IsModified}">
      <span class="onlyDesktop title expadding">
        {{element.AppointmentCode}}
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="dateTime">
    <th mat-header-cell *matHeaderCellDef class="pl-50 width-15"> {{'past-apointment.date-time'|translate}} </th>
    <td mat-cell *matCellDef="let element"
      [ngClass]="{'cancelled-row':element.IsDeleted, 'modified-row':element.IsModified}">
      <div class="appointment-info1">
        <div class="mobile-reqno onlyMobile">{{'all-apointment.requestno'|translate}}
          {{element.AppointmentCode}} </div>
        <div class="mobile-dateua onlyMobile">{{element.SlotTime | dateFormat}} </div>
      </div>
      <!-- <span class="mobile-date onlyMobile">{{element.SlotTime | dateFormat}} </span> -->
      <span class="onlyDesktop">
        <div [ngClass]="element.IsSlotAvailable=='true' ? 'slot-display' : 'ml-35'">
          <button *ngIf=" element.IsSlotAvailable=='true'" class=" mat-tbl-icon"
            matTooltip="{{'appointments.with-appointment' | translate}}" matTooltipClass="tooltip-style"
            mat-icon-button>
            <span class="icon-ic-preappoint"></span>
          </button>
          {{element.SlotTime | dateFormat}}
        </div>
      </span>
      <div class="appointment-info" *ngIf="element?.IsDeleted || element?.IsModified">
        <div class="isCancelled" *ngIf="element.IsDeleted">{{'appointments.cancelled' | translate}}</div>
        <div class="modicontainer" *ngIf="element.IsModified">
          <div class="isModified" *ngIf="element.IsModified">{{'appointments.modified' | translate}}</div>
          <div style="position: relative">
            <div class="history-info" *ngIf="element.IsModified" (click)="getHistory(element)">i</div>
            <div class="read-container" *ngIf="element.isOpen && element.history" (click)="getHistory(element)">
              <div class="sb10"
                [ngClass]="element.IsSlotAvailable=='true' ? 'container-content-slot' : 'container-content' ">
                <div class="history-label">{{'appointments.app-shifted' | translate}}</div>
                <div class="slots" *ngFor="let row of element.history">
                  {{row | dateFormat}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="isArrived zeromar" *ngIf="element.IsArrived && !element.IsDeleted">
          {{'appointments.arrived' |
          translate}}
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="prescrip">
    <th mat-header-cell *matHeaderCellDef> {{prescription}} </th>
    <td mat-cell *matCellDef="let element" class="pulright"
      [ngClass]="{'cancelled-row':element.IsDeleted, 'modified-row':element.IsModified}">
      <div *ngIf="element.PrescriptionUrl !== ''">
        <button class="mat-tbl-icon " matTooltip="{{'all-apointment.view_prescription'|translate}}" mat-icon-button
          *ngIf="!(!element.IsPrescriptionRequired || element.PrescriptionUrl=='')" (click)="viewPdf.emit(element)">
          <span class="icon-ic-view-prescription pulright"></span>
        </button>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="force-display"
      [ngClass]="{'cancelled-row':element.IsDeleted, 'modified-row':element.IsModified}">
      <div class="mobile-action" *ngIf="element.PrescriptionUrl !== ''">
        <div *ngFor="let url of element.PrescriptionUrl.split('|'); index as i" style="display: flex; margin-top:15px;">
          <div style="width:70%; display: flex; height: 45px;">
            <div style="display: flex; flex-direction: column; line-height: 0px;">
              <div>{{'view-prescription.prescription'|translate}} - {{i+1}}</div>
              <hr style="width:100%;">
              <div style="margin: -15px;"><button mat-button color="accent"
                  (click)="uploadPresc.emit({'row_data':element, 'url': url})">{{'view-prescription.replace-prescription'|translate}}</button>
              </div>
            </div>
          </div>
          <div style="width:30%; display: flex;">
            <button style="width:50%;" class="mat-tbl-icon"
              matTooltip="{{'all-apointment.view_prescription'|translate}}" mat-icon-button
              (click)="viewPdfMobile.emit({'row_data':element, 'url': url})">
              <span class="icon-ic-view-prescription"></span>
            </button>

            <button style="width:50%;" class="mat-tbl-icon"
              matTooltip="{{'all-apointment.view_prescription'|translate}}" mat-icon-button
              (click)="deletePdfMobile.emit({'row_data':element, 'url': url})">
              <span sclass="icon-ic-delete"></span>
            </button>
          </div>
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="noRecord">
    <td colspan="5" mat-footer-cell *matFooterCellDef>
      <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
      <div class="desc">
        <p> {{'past-apointment.empty'|translate}}</p>
        <p *ngIf="loader.userRole !=4"> {{'past-apointment.search-patient'|translate}}</p>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <ng-template [ngIf]="dataSource && dataSource.length === 0">
    <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
  </ng-template>
</table>
<mat-paginator *ngIf="totalRecords>0" [length]="totalRecords" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20, 50]"
  showFirstLastButtons="true" (page)="onPageChange($event)">
</mat-paginator>