import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '../../../core';
import { User } from './User';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    error: any;
    user: User;



    Url = 'http://localhost:2000/api/v1/fr/admin/user/update';
    HttpHeaders: any;

    constructor( public httpClient: HttpClient, private readonly appSettings: AppSettingsService) { }

    EditUserPutRequest(user: User) {
    // this.HttpHeaders.add('Access-Control-Allow-Origin','*');
    // this.Options=new httpOptions();
        const httpHeaders = new HttpHeaders();
        httpHeaders.append('Access-Control-Allow-Origin', 'http://localhost:2000');
        // this.httpClient.head("Access-Control-Allow-Origin: *");
        return (this.httpClient.put(('http://localhost:2000/api/v1/fr/admin/user/update'), user, {headers: httpHeaders}));
        console.log(user);
        // $(this.Url+"org/user/update)"+$("/id")), user);
        // $(this.Url+"org/user/update/id" )

    }

    getAdminUsers() {
        return this.httpClient.get(this.appSettings.adminBaseUrl + 'users');

    }

    updateAdminUserPut(putData: Object) {
        return this.httpClient.put(this.appSettings.adminBaseUrl + 'user/update', putData);

    }

    AddUserPostRequest(postData: Object) {
        return this.httpClient.post(this.appSettings.adminBaseUrl + 'user/add', postData);

    }

    success(arg0: string, arg1: { keepAfterRouteChange: boolean }) {
        console.log(arg0, arg1);

    }

}
