import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LogEntry } from '../models/log-entry.model';
import { LogPublisher } from '../models/log-publishers.model';

/** Class for Logging in Web api */
export class LogWebApi extends LogPublisher {

    constructor(private readonly http: HttpClient) {
        super();
        this.Location = 'http://localhost:56590/api/log';
    }

    /* #region Public Methods */
    /** Method to log an entry in web api */
    public log(record: LogEntry): Observable<boolean> {
        return this.http.post<boolean>(this.Location, record)
            .pipe(
                tap((response: any) => {
                    console.log(`Result returned by web api logger: ${response}`);
                }),
                catchError((error: any, operation: any) => {
                    console.log(`Error thrown by web api logger: ${error} - ${operation}`);
                    return of(true);
                })
            );
    }

    /** Method to get log entry from web api */
    public get(): Observable<LogEntry[]> {
        return this.http.get<LogEntry[]>(this.Location);
    }

    /** Method to clear log entry in web api */
    public clear(): Observable<boolean> {
        return this.http.delete<boolean>(this.Location);
    }
    /* #endregion */
}
