import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../../app.locale.service';

@Component({
    selector: 'app-confirm-result',
    templateUrl: './confirm-result.component.html',
    styleUrls: ['./confirm-result.component.scss']
})
export class ConfirmResultComponent {

    constructor(
        private readonly dialogRef: MatDialogRef<ConfirmResultComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public translate: TranslateService,
        private localeService: LocaleService, ) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    /* #region Events and Methods */
    /** This method is to close the pop up */
    public dismiss(): void {
        this.dialogRef.close(false);
    }

    /** This method is to close the pop up and to pass confirmation action */
    public confirmed(): void {
        this.dialogRef.close(true);
    }
    /* #endregion */

}
