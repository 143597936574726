import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LogPublishersService } from '../logger/log-publishers.service';
import { LogEntry } from '../models/log-entry.model';
import { LogLevel } from '../models/log-level.enum';
import { LogPublisher } from '../models/log-publishers.model';
import { LogLocalStorage } from './log-local-storage';
import { LogWebApi } from './log-web-api';

@Injectable()
/** Service for Logging based on config */
export class LoggerService {

    /* #region Fields */
    private readonly level: LogLevel = LogLevel.All;
    private readonly logWithDate = true;
    private publishers: LogPublisher[];
    /* #endregion */

    constructor(private readonly publisher: LogPublishersService) { }

    /* #region Public Methods */
    /** Method to add debug message in logs */
    public debug(msg: string, ...optionalParams: any[]): void {
        this.writeToLog(msg, LogLevel.Debug, optionalParams);
    }

    /** Method to add info message in logs */
    public info(msg: string, ...optionalParams: any[]): void {
        this.writeToLog(msg, LogLevel.Info, optionalParams);
    }

    /** Method to add warn message in logs */
    public warn(msg: string, ...optionalParams: any[]): void {
        this.writeToLog(msg, LogLevel.Warn, optionalParams);
    }

    /** Method to add error message in logs */
    public error(msg: string, ...optionalParams: any[]): void {
        this.writeToLog(msg, LogLevel.Error, optionalParams);
    }

    /** Method to add fatal message in logs */
    public fatal(msg: string, ...optionalParams: any[]): void {
        this.writeToLog(msg, LogLevel.Fatal, optionalParams);
    }

    /** Method to add log message in logs */
    public log(msg: any, ...optionalParams: any[]): void {
        this.writeToLog(msg, LogLevel.All, optionalParams);
    }

    // can be removed not used anywhere
    public readLog(source: string): Observable<LogEntry[]> {
        this.populatePublishers();
        if (source === 'localstorage') {
            const tmp = this.publishers.find(p => p.Name === 'localstorage');
            if (tmp != null) {
                const local = tmp as LogLocalStorage;
                return local.getAll();
            }
        }
        if (source === 'webapi') {
            const tmp = this.publishers.find(p => p.Name === 'webapi');
            if (tmp != null) {
                const local = tmp as LogWebApi;
                return local.get();
            }
        }
        return null;
    }

    // can be removed not used anywhere
    public clearLog(source: string): Observable<boolean> {
        this.populatePublishers();
        // if (source === 'localstorage') {
        //     const tmp = this.publishers.find(p => p.Name === 'localstorage');
        //     if (tmp != null) {
        //         const local = tmp as LogLocalStorage;
        //         return local.clear();
        //     }
        // }
        if (source === 'webapi') {
            const tmp = this.publishers.find(p => p.Name === 'webapi');
            if (tmp != null) {
                const local = tmp as LogWebApi;
                return local.clear();
            }
        }
        return null;
    }
    /* #endregion */

    /* #region Public Methods */
    private shouldLog(level: LogLevel): boolean {
        let ret = false;
        if (this.level !== LogLevel.Off && level >= this.level) {
            ret = true;
        }
        return ret;
    }

    private populatePublishers(): void {
        if (this.publishers === undefined) {
            this.publishers = this.publisher.buildPublishers();
        }
    }

    private writeToLog(msg: string, level: LogLevel, params: any[]): void {
        if (this.shouldLog(level)) {
            this.populatePublishers();
            const entry: LogEntry = new LogEntry(msg, level, params, this.logWithDate);

            // Log the value to all publishers
            for (const logger of this.publishers) {
                if (logger.Name === 'console') {
                    logger.log(entry).subscribe();
                }
                if (logger.Name === 'localstorage') {
                    logger.log(entry).subscribe();
                }
                if (logger.Name === 'webapi') {
                    logger.log(entry).subscribe();
                }
            }
        }
    }
    /* #endregion */
}
