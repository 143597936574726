<div class="pull-right">
    <button class="slider_closebtn" mat-button color="accent" type="button" (click)="dialogRef.close(false)">
        <span class="icon-ic-close"></span>
    </button>
</div>
<div id="otp_divContainer">
    <div id="otpinner_container">
        <h3 class="otp-header">{{'verify-my-account.otp-entermessage' | translate}}</h3>
        <mat-hint class="otp-hint">{{'verify-my-account.otp-hint-initial-email' | translate}}
            {{data?.email}} {{'verify-my-account.otp-hint-initial_end' |translate}}</mat-hint>
        <br>
        <ng-otp-input clas="otp" #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
        <div class="otp-counter">
            <h5>{{'login.otp-time-remaining' | translate}} : {{display}}</h5>
        </div>
        <div class="otp-buttonss">
            <button mat-raised-button *ngIf="btndisabled" color="primary" type="submit" class="btn-login"
                (click)="verifyOTP()">
                {{'login.otp-verify' | translate}} </button>
            <button mat-raised-button *ngIf="!btndisabled" color="primary" type="submit" class="btn-login"
                (click)="sendOTP()">
                {{'login.otp-resend' | translate}} </button>

        </div>
    </div>
</div>