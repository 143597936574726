<!--This div includes close button-->
<div class="dismiss-container">
  <span class="icon-ic-close dismiss-icon" (click)="dismiss()"></span>
</div>
<!--Application info container-->
<div class="app-info-container">
  <!--Application info left container-->
  <div class="app-info-left-content">
    <img
      src="{{'assets/images/application-logos/svg/' + applicationInformation?.ApplicationShortName?.toUpperCase() + '.svg'}}"
      width="60%" alt="application logo">
  </div>
  <!--Application info right container-->
  <div class="app-info-right-content">
    <!--Application info right container Full name-->
    <p>
      <span class="app-info-right-content-full-name">{{applicationFullName}}</span>
    </p>
    <!--Application info right container Server version info-->
    <p>
      <span class="form-value" i18n="@@Common.AboutUs.ApplicationServerVersion">Application Server Version</span>
      <span class="form-label">: {{applicationInformation?.Version}}</span>
    </p>
    <!--Application info right container Client version info-->
    <p>
      <span class="form-value" i18n="@@Common.AboutUs.ClientVersion">Web Client Version</span>
      <span class="form-label">: {{applicationClientVersion}}</span>
    </p>
    <!--Application info right container Environment name-->
    <p>
      <span class="form-value" i18n="@@Common.AboutUs.Environment">Environment</span>
      <span class="form-label">: {{applicationInformation?.EnvironmentName}}</span>
    </p>
  </div>
</div>
<app-footer></app-footer>