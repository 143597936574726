<div class="mainContainerCalender text-center" *ngIf="calendar">
  <h3 class="desc-title">{{'calender.slots-desc'|translate}}</h3>
  <div class="weekContainer">
    <div class="btn-group">
      <div class="nav-link-up" [ngClass]="isDisabled ? 'disablearrow':'' " class="btn btn-primary"
        mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (click)="disablePreviousWeek($event)">
        <span class="icon-ic-left icon-color"></span>
      </div>
      <div class="alignMiddle">
        <div class="thisWeek" *ngIf="calendarRender">{{ calendarRender.period.start | date: 'dd/MM/yyyy'}} - {{
          calendarRender.period.end | date: 'dd/MM/yyyy'}}</div>
        <button mat-raised-button class="today-btn" color="primary" mwlCalendarToday [(viewDate)]="viewDate"
          (click)="todayClick()">{{'admin.today' | translate}}</button>
      </div>
      <div class="btn btn-primary nav-link-up2" [ngClass]="isDisabledNext ? 'disablearrow':'' " mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
        (click)="disablePreviousWeek($event)">
        <span class="icon-ic-right icon-color"></span>
      </div>
    </div>
  </div>
  <div [ngSwitch]="view" class="weekContainer" *ngIf="calendar">
    <mwl-calendar-week-view [headerTemplate]="headerTemplate" class="calendar" (eventClicked)="selectSlot($event)"
      *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [hourDuration]="calendar.duration"
      [locale]="localeLang" (beforeViewRender)="updateCalendarEvents($event)" [dayStartHour]="minStartTime"
      [dayEndHour]="maxStartTime" [minimumEventHeight]="15" [refresh]="refresh" [hourSegments]="1">
    </mwl-calendar-week-view>


    <ng-template #headerTemplate let-days="days">
      <div class="cal-day-headers cursor-default">
        <div class="cal-header" *ngFor="let day of days">
          <div>
            <div>
              {{ day.date | calendarDate:'weekViewColumnHeader':localeLang }}
            </div>
            <div class="day-number">
              {{ day.date | date :'dd/MM/yyyy' }}
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

</div>