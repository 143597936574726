<div class="container" [formGroup]="forgetPasswordForm" *ngIf="step1 else step2">
    <div class="setPasswordContainer">
        <h2 class="title">{{'patient.title'| translate}}</h2>
        <div class="inputs">
            <header>{{'patient.set-password'| translate}}</header>
            <div class="form-fields">
                <mat-form-field id="passwords">
                    <mat-label>{{'login.password' | translate}}</mat-label>
                    <input matInput name="password" (blur)="hideErrorBox()" (focus)="showErrorBox();"
                        [formControl]="resetpassword" #newpassword (change)="passwordMatch()" id="password"
                        [type]="hide ? 'password' : 'text'" (keydown.space)="disableSpace($event)" />
                    <mat-icon matSuffix class="password_icon" (click)="hide = !hide">{{hide ? "visibility_off" :
                        "visibility"}}</mat-icon>
                    <mat-error
                        *ngIf="resetpassword.invalid && resetpassword.value && resetpassword.hasError('whitespace')">
                        {{'createAccount.space-error'
                        | translate}}</mat-error>
                    <mat-error
                        *ngIf="resetpassword.invalid && resetpassword.value && !resetpassword.hasError('whitespace')">
                        {{'login.valid-password'
                        | translate}}</mat-error>
                    <mat-error
                        *ngIf="resetpassword.invalid && !resetpassword.value && resetpassword.hasError('whitespace')">
                        {{'login.valid-password'
                        | translate}}</mat-error>
                    <app-password-policy (isValid)="checkIsValid($event)"></app-password-policy>
                </mat-form-field>
                <mat-form-field id="passwords">
                    <mat-label>{{'login.repeat-password' | translate}}</mat-label>
                    <input matInput name="repeatresetpassword" [formControl]="repeatresetpassword"
                        (change)="passwordMatch()" type="password" [type]="isResetPasswordHide ? 'password' : 'text'"
                        (keydown.space)="disableSpace($event)" />
                    <mat-icon matSuffix class="password_icon" (click)="isResetPasswordHide = !isResetPasswordHide">{{
                        isResetPasswordHide ? "visibility_off" : "visibility"
                        }}</mat-icon>
                    <mat-error *ngIf="passwordsMisMatch" id="passwordMatcherror">{{'login.password-match-error' |
                        translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="mt-30" *ngIf="isAddressRequired">
                <header>{{'createAccount.address-heading' | translate}}</header>
                <div class="form-fields">
                    <mat-form-field class="field-class">
                        <mat-label>{{'createAccount.address' | translate}}</mat-label>
                        <input matInput type="text" id="autocomplete-input" name="Address"
                           placeholder="Search a locality or a postal code..." autocomplete="off" (focus)="isAutoCompleteFocused = true" [formControl]="address"/>
                        
                        <mat-error *ngIf="address.invalid">{{'createAccount.valid-address' | translate}}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="field-class">
                        <mat-label>{{'createAccount.country' | translate}}</mat-label>
                        <mat-select name="country" id="country" [formControl]="country">
                            <mat-option *ngFor="let nationality of nationalities" [value]="nationality.id">
                                {{nationality.country_name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="country.invalid">{{'createAccount.valid-country' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-fields field-two">
                    <mat-form-field class="field-class">
                        <mat-label>{{'createAccount.city' | translate}}</mat-label>
                        <input matInput name="City" id="City" [formControl]="city" />
                        <mat-error *ngIf="city.invalid">{{'createAccount.valid-city' | translate}}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="field-class">
                        <mat-label>{{'createAccount.zipcode' | translate}}</mat-label>
                        <input matInput name="Zipcode" id="Zipcode" [formControl]="zip" />
                        <mat-error *ngIf="zip.invalid">{{'createAccount.valid-zipcode' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="declaration mt-20" *ngIf="isTermsFlagRequired">
                <mat-checkbox [formControl]="privacy"><a class="termspolicy"
                        href="{{'login.privacy-policy-link' | translate}}" target="_blank">{{'login.privacy-policy' |
                        translate}}</a></mat-checkbox>
                <mat-checkbox [formControl]="terms"><a class="termspolicy"
                        href="{{'login.terms-of-use-link' | translate}}" target="_blank">{{'login.terms-of-use' |
                        translate}}</a></mat-checkbox>
            </div>
        </div>
        <button mat-raised-button color="primary" id="resetBtn" (click)="Next()" type="submit"
            [disabled]="enableButton()">
            {{'patient.next' | translate}}
        </button>
    </div>
</div>
<ng-template #step2>
    <div>
        <div class="upload-documents-container p-20">
            <div class="header">
                <app-section-title title="{{ 'createAccount.upload-document' | translate}}"></app-section-title>
            </div>
            <div class="formContainer">
                <form [formGroup]="docForm" #formReference>
                    <div class="form-layout">
                        <div *ngIf="nationality==1 && minor">
                            <mat-label class="upload-header">{{'createAccount.passport-confirm' |
                                translate}}</mat-label>
                            <br>
                            <mat-radio-group [formControl]="minorPassport" required>
                                <mat-radio-button *ngFor="let val of choices" value="{{val.Id}}"
                                    (click)="resetBirthField(val.Id)">{{val.KeyName|translate}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="identity-card">
                            <div>
                                <p class="upload-header" *ngIf="!(nationality==1 && minor && minorPassport.value!='1')">
                                    {{'createAccount.identity-card' | translate}}
                                </p>
                                <p class="upload-header" *ngIf="nationality==1 && minor && minorPassport.value!='1'">
                                    {{'createAccount.parents-identity-card' | translate}}
                                </p>
                                <p>{{'createAccount.identity-comment' | translate}}</p>

                            </div>
                            <app-appointment-prescription [document]="identityItem"
                                (updateDocument)="getUpdateIdentityDocument($event)" [destroyData]=false
                                (deleteDocument)="removeIdentityFileOnly()">
                            </app-appointment-prescription>
                        </div>
                        <div class="birth-certificate" *ngIf="minorPassport.value=='2' && minor">
                            <div>
                                <p class="upload-header only-web">
                                    {{'createAccount.certificate' | translate}}
                                </p>
                                <p class="upload-header only-mobile">
                                    {{'createAccount.certificate' | translate}}
                                </p>
                                <p>{{'createAccount.birth-comment' | translate}}</p>

                            </div>
                            <app-appointment-prescription [document]="birthItem" [destroyData]=false
                                (updateDocument)="getUpdateBirthDocument($event)" (deleteDocument)="removeBirthFile()">
                            </app-appointment-prescription>
                        </div>
                        <div>
                            <p class="upload-header only-web">
                                {{'createAccount.insurance' | translate}}
                            </p>
                            <p class="upload-header only-mobile">
                                {{'createAccount.insurance' | translate}}
                            </p>
                            <label style="display: block">{{'createAccount.private-insurance' | translate}}<span
                                    style="color:red;">
                                    *</span></label>
                            <mat-radio-group [formControl]="insurance" required>
                                <mat-radio-button *ngFor="let val of choices" value="{{val.Id}}"
                                    (click)="resetField(val.Id)">{{val.KeyName|translate}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="insurance.value=='1'">
                            <div>
                                <p>{{'createAccount.insurance-comment' | translate}}</p>
                            </div>
                            <app-appointment-prescription [document]="insuranceItem"
                                (updateDocument)="getUpdateInsuranceDocument($event)" [destroyData]=false
                                (deleteDocument)="removeInsuranceFileOnly()"></app-appointment-prescription>
                            <app-datepicker [ngClass]="{'disabled':insuranceItem?.PrescriptionParts?.length==0}"
                                class="browse-input mb-20" id="insuranceExpiry"
                                labelText="{{'createAccount.expiry' | translate}}" [dateInput]="insuranceExpiry"
                                [minDate]="minDate" [isRequiredError]="registerUserClickedinsuranceExpiry"
                                [isRequired]="registerUserClickedinsuranceExpiry"
                                (deleteDocument)="removeInsuranceFile()">
                            </app-datepicker>
                        </div>
                    </div>
                </form>
            </div>
            <div class="action-wrapper">
                <button mat-button color="accent" class="ghost button_width pull-right" type="reset" (click)="back()"
                    id="clear_btn">
                    {{'createAccount.back' | translate}}
                </button>
                <button mat-raised-button color="primary" class="button_width pull-right " type="submit"
                    (click)="SaveDocuments()" id="save_btn">{{'createAccount.register' | translate}} </button>
            </div>
        </div>
    </div>
</ng-template>