<div class="check-requestnumber-container">
    <div class="section-header">
        <circle-progress class="only-web" [options]="loader.optionsG" [percent]="percent" [renderOnClick]="false"
            imageSrc="assets/images/application-logos/svg/ic-scheckin.svg" class="copy">
        </circle-progress>
        <h3 class="desc-title">{{ 'check-rn.title' | translate }}</h3>​
    </div>
    <div class="selection-section">
        <div class="main-section">
        <div class="options" [ngClass]="optionChosen.value == '0' ? 'option-yes' : 'option-no'" (click)="select(0)">
            <div class="content">
                <img src="assets/images/application-logos/svg/ic-request-no.svg" alt="pres-yes">
                <div class="option-description">
                    <p class="option-title">{{ 'check-rn.have-number' | translate }}​</p>
                    <div class="desc">{{ 'check-rn.option-description' | translate }}​</div>
                </div>
            </div>
            <div class="select-button" [ngClass]="optionChosen.value == '0' ? 'selected' : 'select'">
                {{ (optionChosen.value == '0' ? 'check-rn.selected' : 'check-rn.select') | translate }}</div>
        </div>
        <div class="options" [ngClass]="optionChosen.value == '1' ? 'option-yes' : 'option-no'" (click)="select(1)">
            <div class="content">
                <img src="assets/images/application-logos/svg/ic-new-req.svg" alt="pres-yes">
                <div class="option-description">
                    <p class="option-title">{{ 'check-rn.should-register' | translate }}​​</p>
                    <div class="desc">{{ 'check-rn.should-register-description' | translate }}​</div>
                </div>
            </div>
            <div class="select-button" [ngClass]="optionChosen.value == '1' == true  ? 'selected' : 'select'">
                {{ (optionChosen.value == '1' ? 'check-rn.selected' : 'check-rn.select') | translate }}</div>
        </div>
        </div>
        <!-- <div class="section-info">{{ 'check-rn.info' | translate }}​</div> -->
    </div>
    <div class="action-btn">
        <button mat-raised-button class="next-btn" type="button" (click)="moveToNext()">
            {{ 'next' | translate }}
        </button>
    </div>
</div>