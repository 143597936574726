<div class="test-selection-container">
    <circle-progress class="only-web" [percent]="0" [renderOnClick]="false"
        imageSrc="assets/images/application-logos/svg/ic-sdocument.svg" class="copy">
    </circle-progress>
    <h3 *ngIf="!testTypeSelection && selectTest.length>0" class="desc-title">
        {{'test-selection.test-type-desc'|translate}}</h3>
    <h3 *ngIf="testTypeSelection && enteredSearchValue?.length==0" class="desc-title">
        {{'test-selection.test-desc'|translate}}
        <button mat-button color="accent" class="ghost male" (click)="filterByGender(15)">male</button>
        <button mat-button color="accent" class="ghost female" (click)="filterByGender(16)">female</button>

    </h3>
    <ng-container *ngIf="!testTypeSelection && selectTest.length>0">
        <div class="testingContent">
            <div class="testItems cp" *ngFor="let t of testType; let i = index">
                <div class="productImage" (click)="onTestTypeSelected(t)">
                    <img [src]=t.ImageUrl (error)="onTestTypeError(i)" />
                </div>
                <div class="product-details">
                    <div class="productName" (click)="onTestTypeSelected(t)">{{loader.TranslateLang(t.Name)}}</div>
                    <div class="productDescription testTypeDesc" (click)="onTestTypeSelected(t)"
                        matTooltip="{{loader.TranslateLang(t.Description)}}" matTooltipPosition="above">
                        {{loader.TranslateLang(t.Description) | descriptionShortener:characters}}</div>
                    <a class="only-mobile" *ngIf="!t.isOpen" (click)="openCurrent(i)">
                        {{'test-selection.read-more'| translate}}
                    </a>
                    <a class="only-mobile" *ngIf="t.isOpen" (click)="t.isOpen=false">
                        {{'test-selection.read-less'| translate}}
                    </a>
                    <button mat-button color="accent" class="ghost" id="btn_test_select_{{t.short_name}}"
                        (click)="onTestTypeSelected(t)">{{'test-selection.select' |
                        translate}}</button>

                </div>
                <div class="read-container" *ngIf="t.isOpen">
                    <div class="sb10 read-more">
                        <div class="read-name">{{loader.TranslateLang(t.Name)}}</div>
                        <div class="read-decs">{{loader.TranslateLang(t.Description)}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="action-btn">
            <button mat-raised-button class="back-btn" type="button" (click)="goToBack()">
                Back
            </button>
            <button mat-raised-button class="next-btn" type="button">
                Next
            </button>
        </div>
    </ng-container>
    <!-- <div class="backButton" *ngIf="testTypeSelection && enteredSearchValue?.length==0" (click)="clearSearch()"> <span
            class="icon-ic-back"></span>
        <span class="testTypeName">{{testTypeName | translate}} </span>
    </div> -->
    <ng-container *ngIf="testTypeSelection && !noRecordFound && selectTest.length>0">
        <div class="testingContent">
            <div class="testItems" *ngFor="let t of selectTestGender; let i = index">
                <div class="productImage">
                    <img [src]=t.url (error)="onError(i)" />
                </div>
                <div class="product-details">
                    <div class="productDis">{{t.short_name}}</div>
                    <div class="productDescription" matTooltip="{{t.description}}" matTooltipPosition="above">
                        {{t.description | descriptionShortener:characters}}</div>
                    <a class="only-mobile testOpen" *ngIf="!t.isOpen" (click)="openCurrentTest(i)">
                        {{'test-selection.read-more'| translate}}
                    </a>
                    <a class="only-mobile testOpen" *ngIf="t.isOpen" (click)="t.isOpen=false">
                        {{'test-selection.read-less'| translate}}
                    </a>
                    <button mat-button color="accent" class="ghost" id="btn_test_select_{{t.short_name}}"
                        (click)="onTestSelected(t)">{{'test-selection.select' |
                        translate}}</button>
                </div>
                <div class="read-container" *ngIf="t.isOpen">
                    <div class="sb10 read-more read-test">
                        <div class="read-name">{{t.short_name}}</div>
                        <div class="read-decs">{{t.description}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="action-btn" *ngIf="testTypeSelection && !noRecordFound && selectTest.length>0">
            <button mat-raised-button class="back-btn" type="button" (click)="clearSearch()">
                Back
            </button>
            <button mat-raised-button class="next-btn" type="button">
                Next
            </button>
        </div>
    </ng-container>

    <div class="in-d-flex p-20" *ngIf="noRecordFound && selectTest.length>0">
        <span>Some of the tests are not configured for the sampling station. Please get in touch with
            administrator/Receptionist for further help.</span>
    </div>
    <div class="test-selection-container" *ngIf="selectTest.length==0">
        <div class="testingContent d-flex justify-content-center align-items-center">
            {{'results.test-unconfigure' | translate}}
        </div>
    </div>
</div>