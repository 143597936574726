import { Pipe, PipeTransform } from '@angular/core';

export class SamplingStations {
    id: Number;
    name: string;
}

@Pipe({
    name: 'samplingStationsName'
})
export class SamplingStationsNamePipe implements PipeTransform {

    transform(value: SamplingStations[]): string {
        let ssNames = '';
        // console.log(value);
        value.forEach(ss => {
            if (ss.name !== '') {
                ssNames += (', ' + ss.name) ;
            }
        });
        return ssNames.slice(2);
    }

}
