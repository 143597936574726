<div class="CalenderContainer">
  <form #calendarForm="ngForm" novalidate (ngSubmit)="submitMe(calendarForm)">

    <div class="d-flex ml-40 mt-20">
      <label class="customwidth">{{'add-calendar.calendar-name' | translate}} : </label>
      <mat-form-field *ngIf="isCalendarAvailable && isEditMode " style="width:300px">
        <!-- <mat-label>Calendar Name </mat-label> -->
        <input id="name" name="calendar_name" matInput [(ngModel)]="calendarInfo.calendar_name" required />
        <!-- <mat-error class="mt-15" *ngIf="calendarform.get('calendar_name').hasError('pattern')">Please enter a valid Calendar Name.</mat-error> -->
      </mat-form-field>

      <mat-form-field *ngIf="!isCalendarAvailable" style="width:300px">
        <!-- <mat-label>Calendar Name </mat-label> -->
        <input id="name" name="calendar_name" matInput [(ngModel)]="calendarInfo.calendar_name" required />
        <!-- <mat-error class="mt-15" *ngIf="calendarform.get('calendar_name').hasError('pattern')">Please enter a valid Calendar Name.</mat-error> -->
      </mat-form-field>

      <mat-form-field floatLabel="never" class="dropdown-filter " *ngIf="isCalendarAvailable && !isEditMode"
        style="width:300px">
        <mat-select disableOptionCentering id="calendar" name="calendar_id" [(ngModel)]="calendarInfo.id"
          (selectionChange)="onCalendarSelect($event)" [(value)]="calendarInfo.id">
          <mat-option *ngFor="let stepOption of calendar" [value]="stepOption.id">
            {{ stepOption.calendar_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="btn-wraper" *ngIf="isCalendarAvailable">
        <button mat-raised-button color="primary" id="btn_edit_calendar" class="calendar-btn " type="button"
          (click)="editCalendar()">
          {{'add-calendar.edit' | translate}}
        </button>

        <button mat-raised-button color="primary" id="btn_delete_calendar" class="calendar-btn" type="button"
          (click)="deleteCalendar()">
          {{'add-calendar.delete' | translate}}
        </button>
      </div>
    </div>
    <div class="d-flex ml-40 mt-20">
      <label class="customwidth">{{'add-calendar.slot-duration' | translate}}</label>
      <mat-form-field floatLabel="never" class="dropdown-filter " style="width:300px">
        <mat-select disableOptionCentering id="range" name="duration" [disabled]="fieldDisabled"
          [(ngModel)]="calendarInfo.duration" required>
          <mat-option *ngFor="let stepOption of range" [value]="stepOption.id">
            {{ stepOption.displayText }}{{'add-calendar.mins' | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="d-flex ml-50">
        <label class="mr-20">{{'add-calendar.max-slots' | translate}}</label>
        <mat-form-field floatLabel="never" class="dropdown-filter " name="capacity" style="width:300px">
          <mat-select disableOptionCentering id="range" [(ngModel)]="calendarInfo.capacity" [disabled]="fieldDisabled"
            name="capacity" required>
            <mat-option *ngFor="let stepOption of slots" [value]="stepOption">
              {{ stepOption }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div style="width: 90%" class="d-flex ml-40 mt-20">
      <label class="customwidth">{{'add-calendar.tests' | translate}}</label>

      <app-multi-select-dropdown [selectedItems]="selectedtestList" [items]="testList" isRequired="true"
        placeHolder="{{'add-calendar.choose-test' | translate}}" panelClassInput="customWidthDropdown disabled" required
        [fieldDisabled]="fieldDisabled" (selectedOptions)="selectTests($event)">
      </app-multi-select-dropdown>
    </div>
    <div class="mt-20 ml-40">
      <table id="calendertable" class="w-100">
        <tbody>
          <ng-container *ngFor="let weekday of WeeklyCalender; let i=index" style="align-items:center">
            <tr>
              <td>
                <mat-checkbox class="example-margin" name="active{{i}}" [disabled]="weekday.isDisabled || fieldDisabled"
                  [(ngModel)]="weekday.active" (change)="shouldDisableCheckboxClick($event,i);">{{weekday.day |
                  translate}}
                </mat-checkbox>
              </td>
              <td><span>{{'add-calendar.opening-hours' | translate}}</span></td>
              <td>
                <mat-form-field class="mr-20 timeInputField" [ngClass]="weekday.is_close_valid==false?' showError ':''">
                  <input matInput type="time" placeholder="{{'add-calendar.time-placeholder' | translate}}" name="open"
                    name="open{{i}}" [disabled]="weekday.isDisabled || fieldDisabled" [(ngModel)]="weekday.start_time"
                    (blur)="validateTime(i)" [readonly]="fieldDisabled">
                </mat-form-field>
                <mat-form-field class="timeInputField" [ngClass]="weekday.is_close_valid==false?' showError ':''">
                  <input matInput type="time" placeholder="{{'add-calendar.time-placeholder' | translate}}" name="close"
                    name="close{{i}}" [disabled]="weekday.isDisabled || fieldDisabled" [(ngModel)]="weekday.end_time"
                    (blur)="validateTime(i)" [readonly]="fieldDisabled">
                </mat-form-field>
              </td>
              <td>
                <span>{{'add-calendar.lunch-break' | translate}}</span>
              </td>
              <td>
                <mat-form-field class="mr-20 timeInputField"
                  [ngClass]="weekday.is_break_start_valid==false?' showError ':''">
                  <input matInput type="time" placeholder="{{'add-calendar.time-placeholder' | translate}}"
                    name="breakbegins" name="break_begins{{i}}" [disabled]="weekday.isDisabled || fieldDisabled"
                    [(ngModel)]="weekday.break_begins" [readonly]="fieldDisabled" (blur)="validateTime(i)">
                </mat-form-field>
                <mat-form-field class="timeInputField" [ngClass]="weekday.is_break_end_valid==false?' showError ':''">
                  <input matInput type="time" placeholder="{{'add-calendar.time-placeholder' | translate}}"
                    name="breakends" name="break_ends{{i}}" [disabled]="weekday.isDisabled || fieldDisabled"
                    [(ngModel)]="weekday.break_ends" [readonly]="fieldDisabled" (blur)="validateTime(i)">
                </mat-form-field>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

    </div>
    <div class="actions mb-20">
      <button mat-raised-button color="primary" id="btn_cancel" class="specialdates-btn" (click)="cancelProcess()"
        type="button">
        {{'admin.cancel' | translate}}
      </button>

      <button mat-raised-button color="primary" id="btn_clear" class="calendar-btn ml-60" type="button"
        [disabled]="fieldDisabled" (click)="reset()">
        {{'admin.clear' | translate}}
      </button>

      <button mat-raised-button color="primary" id="btn_save" class="specialdates-btn ml-60" [disabled]="fieldDisabled"
        type="submit">
        {{'admin.save' | translate}}
      </button>

    </div>
  </form>