<div class="create-account-container register-align mt-20">
    <form id="createAccount_form">

        <p class="mediumShadelabel mb-20">{{'find-account.personal-information' | translate}}</p>

        <div class="createAccountwrapper">
            <div>
                <label style="display: block">{{'find-account.gender' | translate}}<span style="color:red;">
                        *</span></label>
                <mat-radio-group [formControl]="gender" required>
                    <mat-radio-button *ngFor="let gen of genders" value="{{gen.Id}}">{{gen.KeyName|translate}}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="gender.value && gender.invalid">{{'find-account.valid-gender' | translate}}
                </mat-error>
            </div>

            <mat-form-field>
                <mat-label>{{'find-account.first-name' | translate}}</mat-label>
                <input matInput name="FirstName" id="FirstName" [formControl]="firstName"
                    (keypress)="loader.keyPressAlphabets($event)" (paste)="loader.pasteAlphabets($event)" />
                <mat-error *ngIf="firstName.invalid">{{'find-account.valid-first-name' | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'find-account.birth-name' | translate}}</mat-label>
                <input matInput name="BirthName" id="BirthName" [formControl]="birthName"
                    (keypress)="loader.keyPressAlphabets($event)" (paste)="loader.pasteAlphabets($event)" />
                <mat-error *ngIf="birthName.invalid">{{'find-account.valid-birth-name' | translate}}</mat-error>
            </mat-form-field>


            <app-datepicker id="dateofBirth" labelText="{{'find-account.dob' | translate}}" [dateInput]="dateOfBirth"
                isRequired="true" isRequiredError="true" [maxDate]="maxDate">
            </app-datepicker>
        </div>

        <!-- <div class="actions">
            <div class="inner-btn">
                <button mat-button color="accent" class="ghost button_width mr-20" type="button" (click)="clear()"
                    id="btn_clear">
                    {{'find-account.clear' | translate}}
                </button>
                <button mat-raised-button color="primary" class="button_width" type="submit" id="btn_save"
                    (click)="findAccount()">
                    {{'find-account.btn-find' | translate}}
                </button>

            </div>
        </div> -->
    </form>
</div>