import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { LocaleService } from "../../../app.locale.service";
import { DrawerComponent, SpinnerService } from "../../../core";
import { LoaderService, MessageService } from "../../../main";
import { AppointmentWrapper, PatientAppointment } from "../../patient/my-apointment/my-appointment.model";
import { PatientService } from "../../services/patient.service";
import { DateSlot } from "../date-slot/date-slot.model";

import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import * as moment from "moment";

@Component({
  selector: "app-edit-patient-appointment",
  templateUrl: "./edit-patient-appointment.component.html",
  styleUrls: ["./edit-patient-appointment.component.scss"],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: "DD-MM-YYYY",
        },
        display: {
          dateInput: "DD-MM-YYYY",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        },
      },
    },
  ],
})
export class EditPatientAppointmentComponent
  implements OnInit, DrawerComponent {
  @Input() sliderFormData: AppointmentWrapper;
  @Input() public isSliderDataEdited = false;
  date = new FormControl();
  age: number;
  slotTimeval: any;
  slotGroupId: number;
  maxDate: any;
  minDate: any;
  protected slotTime = new FormControl(null, [Validators.required]);
  weekSelected = false;
  hasCalendar: boolean;
  hasErrorCode: boolean;
  showCalendar: boolean;
  slotSelected = false;
  protected slotData: DateSlot;
  protected activeDates: any[] = [];
  myFilter = (d: Date): boolean => {
    let dpd = new Date(d);
    const year = dpd.getFullYear();
    const month = String(dpd.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so adding 1
    const day = String(dpd.getDate()).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;
    return this.activeDates.some((x) => x === dateString);
  };
  protected isFuture: boolean;
  protected isPastDate: boolean = false;
  protected isNonWorkingDay: boolean = false;
  protected isUploaded: boolean = false;
  public a_data: PatientAppointment;
  constructor(
    private loaderService: LoaderService,
    private patientService: PatientService,
    private messageService: MessageService,
    private translate: TranslateService,
    public localeService: LocaleService,
    private datepipe: DatePipe,
    private spinner: SpinnerService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.slotData = new DateSlot();
    // this.adapter.setLocale("en-GB");
    const today = new Date();
    this.maxDate = new Date(new Date().setDate(today.getDate() + 30));
    this.slotTime.setValue(today);
    this.minDate = new Date();
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
    this.dateAdapter.setLocale(this.localeService.localeLang);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  ngOnInit(): void {
    this.slotData = this.sliderFormData.slotData;
    this.a_data = this.sliderFormData.appointment_detail;
    if (this.slotData.calendar_id == 1) {
      this.hasCalendar = true;
      this.showCalendar = true;
      this.hasErrorCode = false;
    } else {
      this.hasErrorCode = true;
      this.hasCalendar = false;
      this.showCalendar = false;
      this.slotData.slots.forEach((element: any) => {
        this.activeDates.push(
          moment.utc(new Date(element.date)).local().format('YYYY-MM-DDTHH:mm:SS').split("T")[0]
        );
      });
    }
    this.slotTimeval = this.sliderFormData.appointment_detail.SlotTime;

    this.age = this.calculate_age(
      new Date(this.sliderFormData.appointment_detail.DOB)
    );
    this.slotTime.setValue(this.sliderFormData.appointment_detail.SlotTime);

    this.localeService.locale.subscribe(() =>
      this.dateAdapter.setLocale(this.localeService.localeLang)
    );
  }

  calculate_age(dob: Date) {
    const diff_ms = Date.now() - dob.getTime();
    const age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }
  deleteAppointment() {
    let msg = this.translate.instant("patient.delete-description");
    const app_date = this.datepipe.transform(
      new Date(this.sliderFormData.appointment_detail.SlotTime),
      "dd/MM/yyyy"
    );
    const at = this.translate.instant("patient.at");
    const app_hours = this.datepipe.transform(
      new Date(this.sliderFormData.appointment_detail.SlotTime),
      "HH:mm"
    );
    let app_datetimeval = app_date + " " + at + " " + app_hours;
    if (app_hours == "00:00") {
      app_datetimeval = app_date;
    }
    const mapObj = {
      app_date: app_datetimeval,
      app_hours: this.datepipe.transform(
        new Date(this.sliderFormData.appointment_detail.SlotTime),
        "HH"
      ),
      test_name: this.sliderFormData.appointment_detail.TestShortName,
      app_minutes: this.datepipe.transform(
        new Date(this.sliderFormData.appointment_detail.SlotTime),
        "mm"
      ),
      lab_name: this.sliderFormData.appointment_detail.SamplingStationName,
    };

    msg = msg.replace(
      /app_date|lab_name|test_name/gi,
      (matched: any) => mapObj[matched]
    );
    if (this.sliderFormData?.appointment_detail?.KitImagePath != "") {
      this.messageService.alert(
        this.translate.instant("patient.cancel-warning"),
        5000
      );
    } else {
      this.messageService
        .confirm(msg, this.translate.instant("patient.delete-appointmenttext"))
        .subscribe((actionResult: boolean) => {
          if (actionResult) {
            this.patientService
              .deleteAppointment(
                this.sliderFormData.appointment_detail.AppointmentId,
                this.sliderFormData.appointment_detail.PatientID
              )
              .subscribe(
                (data: any) => {
                  data;
                  this.messageService.success(
                    this.translate.instant("patient.delete-appointment"),
                    5000
                  );
                  this.loaderService.refreshState(true);
                  this.dismiss();
                },
                (err: { error: { message: string }; message: any }) => {
                  this.messageService.alert(err.error.message, 5000);
                }
              );
          }
        });
    }
  }

  updateAppointment() {
    if (!this.slotSelected || this.sliderFormData.appointment_detail.StatusId > 0 || !this.slotTime.valid) {
      this.slotTime.setErrors({ slotTimeInvalid: true });
      this.isUploaded = true;
      return;
    }
    let c_id = this.slotData?.slots.length;
    if (c_id == 0) console.log(this.slotTime);
    const payload = {
      id: this.sliderFormData.appointment_detail.AppointmentId,
      calendar_id: this.sliderFormData.appointment_detail.CalendarId,
      patient_id: this.sliderFormData.appointment_detail.PatientID,
      slot_group_id: this.slotGroupId,
      slot_time:
        c_id == 0
          ? moment.utc(new Date(this.slotTime.value)).local().format('YYYY-MM-DDTHH:mm:SS') + "Z"
          : moment.utc(new Date(this.slotTimeval)).local().format('YYYY-MM-DDTHH:mm:SS') + "Z",
      capacity: this.sliderFormData.appointment_detail.Capacity,
      tests: [this.sliderFormData.appointment_detail.TestId],
      sampling_station_id:
        this.sliderFormData.appointment_detail.SamplingStationId,
    };
    this.spinner.show(true);

    this.patientService.updateAppointment(payload).subscribe(
      (response: any[]) => {
        response;
        this.messageService.success(
          this.translate.instant("view-booking.booking-saved"),
          5000
        );
        this.spinner.show(false);
        this.loaderService.refreshState(true);
        this.dismiss();
      },
      (error) => {
        this.spinner.show(false);
        if (error.error["internal_code"] === 424) {
          this.messageService.alert(
            this.translate.instant('view-booking.booking-full-error'),
            5000
          );
        } else {
          this.messageService.alert(error["message"], 5000);
        }
      }
    );
  }

  dismiss() {
    this.loaderService.cancelForm();
  }

  selectedSlot(e: any) {
    var stime = e.split("|")[0];
    this.slotGroupId = parseInt(e.split("|")[1]);
    this.slotTimeval = stime;
    this.showCalendar = false;
    this.slotSelected = true;
    this.sliderFormData.appointment_detail.SlotTime = stime;
  }

  protected onSelectAppointmentDate(e: any) {
    let dpd = new Date(e.value);
    this.slotTimeval = new Date(this.slotTime.value);
    const year = dpd.getFullYear();
    const month = String(dpd.getMonth() + 1).padStart(2, "0");
    const day = String(dpd.getDate()).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;
    if (this.isDateLessThanToday(dateString)) {
      this.isPastDate = true;
      this.isFuture = false;
      this.isNonWorkingDay = false;
    } else if (this.activeDates.includes(dateString)) {
      this.isPastDate = false;
      this.isFuture = false;
      this.isNonWorkingDay = false;
      this.sliderFormData.appointment_detail.SlotTime = this.slotTimeval;
    } else {
      let isExist = this.isWithin30Days(dateString);
      if (!isExist) {
        this.isPastDate = false;
        this.isFuture = true;
        this.isNonWorkingDay = false;
      } else {
        this.isPastDate = false;
        this.isFuture = false;
        this.isNonWorkingDay = true;
      }
    }
    this.slotSelected = true;
  }

  private isDateLessThanToday(dateString: string): boolean {
    const today = new Date();
    const inputDate = new Date(dateString);
    return inputDate < today;
  }
  private isWithin30Days(dateString: string): boolean {
    const today = new Date();
    const inputDate = new Date(dateString);
    const thirtyDaysFromNow = new Date(today.setDate(today.getDate() + 30));
    return inputDate <= thirtyDaysFromNow;
  }
}
