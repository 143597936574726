import { Component, Input, OnInit } from '@angular/core';
import { LoaderService, MessageService } from '../../../main';
//import { SpinnerService } from '../../../core';
import { CommonApiService } from '../../services/common-api.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Hospitals, Department, HealthcareProfessionals } from '../../models/common.model';
import { ProfessionalsService } from '../healthcare-professional/professionals.service';
import { PatientService } from '../../services/patient.service';
import { DatePipe } from '@angular/common';
//import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrl: './user-add.component.scss'
})
export class UserAddComponent implements OnInit {
  @Input() sliderFormData: any;
  @Input() public isSliderDataEdited = false;

  public FirstName = new FormControl("", [Validators.required, Validators.pattern(".{1,50}$"), this.loader.validateAllowedCharacters()]);
  public FamilyName = new FormControl("", [Validators.required, Validators.pattern(".{1,50}$"), this.loader.validateAllowedCharacters()]);
  public status = new FormControl(true, [Validators.required]);
  public Email = new FormControl("", [
    Validators.required,
    Validators.pattern('.{1,60}$'),
    Validators.pattern(this.patientService.emailPattern)
  ]);
  public Dept = new FormControl("", [Validators.required]);
  public Establishment = new FormControl("", [Validators.required]);
  public HCP = new FormControl("", [Validators.required]);
  public phoneNumber = new FormControl('');
  openFrom = new FormControl();
  openTo = new FormControl();


  public EhpuserForm = this.formBuilder.group({
    FirstName: this.FirstName,
    status: this.status,
    FamilyName: this.FamilyName,
    Dept: this.Dept,
    Establishment: this.Establishment,
    HCP: this.HCP,
    openFrom: this.openFrom,
    openTo: this.openTo
  });

  saveAlert = {
    edit: 'ehp-user.save-alert.edit',
    add: 'ehp-user.save-alert.add'
  };

  ehp = {
    editConfirmationMessage: 'ehp-user.edit-confirmation-message',
    editConfirmationTitle: 'ehp-user.edit-confirmation-title',
    cancel: 'ehp-user.cancel',
    confirm: 'ehp-user.confirm',
    externalIDExists: 'ehp-user.externalIdExists',
    internalServerError: 'ehp-user.internal-server-error',
    unauthorized: 'ehp-user.unauthorized',
  }

  departments: Department[] = null;
  establishments: Hospitals[] = null;
  healthcareprofs: HealthcareProfessionals[] = null;

  fromDateMin: Date;
  toDateMin: Date;
  selectedDate: Date;

  constructor(
    public loader: LoaderService,
    //private spinnerService: SpinnerService,
    private commonAPi: CommonApiService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    public messageService: MessageService,
    private patientService: PatientService,
    private professionalsService: ProfessionalsService,
    //private EstablishmentsService: EstablishmentsService,
  ) {
    this.fromDateMin = new Date();
    this.toDateMin = new Date();
    this.toDateMin.setDate(this.fromDateMin.getDate() + 1);
  }

  ngOnInit(): void {

    // Ensure edit mode is always true for testing
    // this.sliderFormData[0].edit = true;

    if (this.sliderFormData[0].edit) {
      //this.bindUserObject(this.sliderFormData[0].DeptData);
    }
    else {
      this.commonAPi.GetDepartments().subscribe((deptList) => {
        this.departments = deptList;
      });
      this.commonAPi.GetHospitals().subscribe((EstablishList) => {
        this.establishments = EstablishList;
      });
      this.professionalsService.GetAllProfessionals().subscribe((HcpList) => {
        this.healthcareprofs = HcpList;
      });
    }
  }

  bindUserObject(samplingData: any) {
    if (samplingData.open_from !== null) {
      this.openFrom.setValue(samplingData.open_from.replace('Z', ''));
      this.changeMinDateInFromDatePicker(this.openFrom.value.replace('Z', ''))
    }

    if (samplingData.open_until !== null) {
      this.openTo.setValue(samplingData.open_until.replace('Z', ''));
      this.changeMinDateInDatePicker(this.openFrom.value.replace('Z', ''));
    }
  }

  onSave() { }

  getEstablishName(estId: number): string {
    const est = this.establishments.find(d => d.id === estId);
    return est ? est.name : 'Unknown';
  }

  getDepartmentName(deptId: number): string {
    const dept = this.departments.find(d => d.id === deptId);
    return dept ? dept.name : 'Unknown';
  }

  protected checkIfDateInputIsValid() {
    return this.openFrom.valid && this.openTo.valid;
  }

  /**
  * This method will convert the date string into a formatted date.
  * @param dateValue
  * @returns formatted date.
  */
  getFormattedDate(dateValue: any) {
    const datePipe = new DatePipe('en-US');

    if (dateValue === undefined) {
      return '';
    }

    const formattedDate =
      dateValue !== null ? datePipe.transform(dateValue, 'dd-MM-yyyy') : '';

    return formattedDate;
  }

  /**
   * This method will envoke when user will change the date by datepicker or manually.
   * @param e ChangedDate Value
   */
  onSelectedDate(e: any) {
    this.changeMinDateInDatePicker(e);
  }

  /**
  * This Method will set the min date on To Date Picker field according to selected value in From Date Picker
  * @param inputData Date Value
  * @returns returns the method without setting any min date if the date value is invalid.
  */

  changeMinDateInDatePicker(inputData: any) {
    this.selectedDate = inputData;
    const datePipe = new DatePipe('en-US');

    const formattedDate = datePipe.transform(this.selectedDate, 'dd-MM-yyyy');
    if (formattedDate == null) {
      return;
    }

    const splittedDateArray = formattedDate.split('-');

    const year = Number(splittedDateArray[2]);
    const month = Number(splittedDateArray[1]) - 1;
    const date = Number(splittedDateArray[0]) + 1;

    this.toDateMin = new Date(year, month, date);
  }

  private changeMinDateInFromDatePicker(inputData: any) {
    this.selectedDate = inputData;
    const datePipe = new DatePipe('en-US');

    const formattedDate = datePipe.transform(this.selectedDate, 'dd-MM-yyyy');
    if (formattedDate == null) {
      return;
    }

    const splittedDateArray = formattedDate.split('-');

    const year = Number(splittedDateArray[2]);
    const month = Number(splittedDateArray[1]) - 1;
    const date = Number(splittedDateArray[0]);
    let openFrom = new Date(year, month, date);
    let today = new Date();
    let currDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    if (openFrom > currDate) {
      this.fromDateMin = currDate;
    } else {
      this.fromDateMin = openFrom;
    }
  }

  public dismiss() {
    this.loader.cancelForm();
  }
  reset() {
    this.EhpuserForm.reset();
    this.EhpuserForm.markAsUntouched();
  }
  public success(message: any): void {
    this.messageService.success(this.translate.instant(message));
  }
  public alert(error: any): void {
    this.messageService.alert(this.translate.instant(error), 10000);
  }

}
