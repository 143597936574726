<mat-list-item>
    <mat-form-field floatLabel="{{ floatLabel }}">
        <mat-select [formControl]="selectFormControl" placeholder="{{placeHolder | translate}}"
            [disabled]="fieldDisabled" [required]="isRequired" [compareWith]="equals" (openedChange)="close($event)"
            multiple disableOptionCentering (selectionChange)="handleSelectAll()"
            panelClass="ml-30 {{ panelClassInput}}" #selector>
            <mat-form-field class="search-field" floatLabel="never">
                <input matInput #search name="search-text" autocomplete="off" (keydown)="$event.stopPropagation()"
                    placeholder="{{'Common.Search.Placeholder' | translate}}" [formControl]="searchTextboxControl">
                <span class="icon-search-link" matSuffix *ngIf="!search.value"></span>
                <button *ngIf="search.value" matSuffix mat-icon-button (click)="clearSearch()">
                    <span class="icon-close mat-menu-tag-close"></span>
                </button>
            </mat-form-field>
            <mat-checkbox (click)="selectAll(checkAll.checked)" [(ngModel)]="IsChecked"
                *ngIf="(filteredOptions | async).length > 1" #checkAll class="select-all"
                i18n="@@Common.MultiSelect.SelectAll">{{'add-sampling.select-all' | translate}}</mat-checkbox>
            <div class="search-field" *ngIf="(filteredOptions | async).length == 0"
                i18n="@@Common.MultiSelect.NoResults">No results found!</div>
            <mat-option *ngFor="let item of filteredOptions | async" [value]="item" class="multi-select-option"
                (onSelectionChange)="selectionChange($event)">
                {{item.displayText}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-list-item>