<div class="cross-button">
    <button mat-icon-button color="accent" id="btn_close" (click)="dismiss()">
        <span class="icon-ic-close"></span>
    </button>
</div>
<div class="dialog-container">
    <h5>{{'dashboard.upload-required' | translate}}</h5>
    <div class="dialog-content">
        {{ 'dashboard.upload-text' | translate}}
    </div>
    <div class="actions">
        <button mat-raised-button color="primary" id="btn_upload" type="submit" class="button_content"
            (click)="uploadDocument()">{{
            'dashboard.ok' | translate}}</button>
    </div>