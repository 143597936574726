<div class="heading">
    <h1 class="heading">{{'faq.faqHeading' | translate}}</h1>
</div>
<div class="faqMainBox" id="faqMainBox">
    <div class="box1" id="box1">
        <div class="topic">
            <mat-toolbar class="contact">
                <span class="question">?</span>
                <div class="supportPhone">
                    {{'faq.SupportPhone' | translate}} &nbsp; <a href="mailto:support.eclinical@biologie.eurofinseu.com"
                        target="_blank"> {{'login.support-phone' |
                        translate}}</a>
                </div>
            </mat-toolbar>
        </div>

        <div class="topic">
            <h2 class="subHeading">{{'faq.My-Account' | translate}}</h2>
            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'faq.registrationQuestion1' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'faq.registrationQuestion1Point1' | translate}}</p>
                </mat-expansion-panel>
            </div>
            <div class="gap"></div>
            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'faq.My-AccountQuestion1' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'faq.My-AccountQuestion1Point1' | translate}}</p>
                </mat-expansion-panel>
            </div>
            <div class="gap"></div>
            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'faq.My-AccountQuestion2' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p> {{'faq.My-AccountQuestion2Point1' | translate}}</p>
                </mat-expansion-panel>
            </div>
            <div class="gap"></div>
            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'faq.My-AccountQuestion3' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                        <li>
                            {{'faq.My-AccountQuestion3Point1' | translate}}
                        </li>
                        <li>
                            {{'faq.My-AccountQuestion3Point2' | translate}}
                        </li>
                    </ul>
                </mat-expansion-panel>
            </div>
            <div class="gap"></div>
            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'landing-page.faq.question3' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p> {{'landing-page.faq.answer3.p1' | translate}}
                    <p>
                    <p>{{'landing-page.faq.answer3.p2' | translate}}</p>
                    <p>{{'landing-page.faq.answer3.p3' | translate}} </p>
                </mat-expansion-panel>
            </div>
            <div class="gap"></div>

            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'landing-page.faq.question1' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p> {{'landing-page.faq.answer1.p1' | translate}}
                    <p>
                    <p>{{'landing-page.faq.answer1.p2' | translate}}</p>
                    <p>{{'landing-page.faq.answer1.p3' | translate}} </p>
                </mat-expansion-panel>
            </div>
        </div>
        <div class="topic">
            <h2 class="subHeading">{{'faq.Appointments' | translate}}</h2>
            <!-- <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'faq.AppointmentsQuestion1' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                        <li>
                            {{'faq.AppointmentsQuestion1Point1' | translate}}
                        </li>
                        <li>
                            {{'faq.AppointmentsQuestion1Point2' | translate}}
                        </li>
                    </ul>
                </mat-expansion-panel>
            </div> -->

            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'faq.processQuestion1' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ol>
                        <li>{{'faq.processQuestion1Point1' | translate}}</li>
                        <li>{{'faq.processQuestion1Point2' | translate}}</li>
                        <li>
                            {{'faq.processQuestion1Point3' | translate}}
                        </li>
                        <li>{{'faq.processQuestion1Point4' | translate}}</li>
                        <li>{{'faq.processQuestion1Point5' | translate}}</li>
                        <li>
                            {{'faq.processQuestion1Point6' | translate}}
                        </li>
                    </ol>
                </mat-expansion-panel>
            </div>
            <div class="gap"></div>
            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'faq.processQuestion2' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ol>
                        <li>{{'faq.processQuestion2Point1' | translate}}</li>
                        <li>
                            {{'faq.processQuestion2Point2' | translate}}
                        </li>
                        <li>
                            {{'faq.processQuestion2Point3' | translate}}
                        </li>
                        <li>
                            {{'faq.processQuestion2Point4' | translate}}
                        </li>
                    </ol>
                </mat-expansion-panel>
            </div>
            <div class="gap"></div>
            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'faq.AppointmentsQuestion2' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                        <li>
                            {{'faq.AppointmentsQuestion2Point1' | translate}}
                        </li>
                        <li>
                            {{'faq.AppointmentsQuestion2Point2' | translate}}
                        </li>
                    </ul>
                </mat-expansion-panel>
            </div>

            <div class="gap"></div>
            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'landing-page.faq.question2' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'landing-page.faq.answer2.p1' | translate}}
                    <ul class="number-style">
                        <li innerHtml="{{'landing-page.faq.answer2.li1' | translate}}"> </li>
                        <li innerHtml="{{'landing-page.faq.answer2.li2' | translate}}"> </li>
                        <li innerHtml="{{'landing-page.faq.answer2.li3' | translate}}"> </li>
                        <li innerHtml="{{'landing-page.faq.answer2.li4' | translate}}"> </li>

                    </ul>
                </mat-expansion-panel>
            </div>
        </div>
    </div>
    <div class="box2" id="box2">
        <div class="topic">
            <h2 class="subHeading">{{'faq.Results' | translate}}</h2>
            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'faq.ResultsQuestion1' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p> {{'faq.ResultsQuestion1Point1' | translate}}</p>
                </mat-expansion-panel>
            </div>
        </div>
        <div class="topic">
            <h2 class="subHeading">{{'faq.Laboratories' | translate}}</h2>
            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'faq.LaboratoriesQuestion1' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'faq.LaboratoriesQuestion1Point1' | translate}}</p>
                </mat-expansion-panel>
            </div>
            <div class="gap"></div>
            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'faq.LaboratoriesQuestion2' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p> {{'faq.LaboratoriesQuestion2Point1' | translate}}</p>
                </mat-expansion-panel>
            </div>
        </div>
        <div class="topic">
            <h2 class="subHeading">{{'faq.QRCode' | translate}}</h2>
            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'faq.QRCodeQuestion1' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'faq.QRCodeQuestion1Point1' | translate}}</p>
                </mat-expansion-panel>
            </div>
            <div class="gap"></div>
            <div class="faqbox">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="MatHeader">
                        <mat-panel-title>
                            {{'faq.QRCodeQuestion2' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'faq.QRCodeQuestion2Point1' | translate}}</p>
                </mat-expansion-panel>
            </div>
        </div>
    </div>
</div>
<div class="protected-data-box">
    <h1>{{'faq.protected-data.question' | translate}}</h1>
    <div class="protected-data-box-desc">
        <p>{{'faq.protected-data.p1' | translate}}</p>
        <p>{{'faq.protected-data.p2' | translate}}</p>
        <p>{{'faq.protected-data.p3' | translate}}</p>
        <p>{{'faq.protected-data.p4' | translate}}</p>
        <p>{{'faq.protected-data.p5' | translate}} <a href="{{'faq.protected-data.link' | translate}}"
                target="_blank">{{'faq.protected-data.link-desc' | translate}}</a></p>
    </div>
</div>