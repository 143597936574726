<div class="upload-pic-container p-20">
  <div class="header">
    <div class="pull-left">
      <app-section-title title="{{title|translate}}"></app-section-title>
    </div>
    <div class="pull-right">
      <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
        <span class="icon-ic-close"></span>
      </button>
    </div>
  </div>
  <div class="formCointainer">
    <form #documentForm="ngForm">
      <div class="form-layout">
        <div class="fields">
          <mat-form-field>
            <mat-label class="label-float">{{'upload-document.document-type'|translate}}</mat-label>
            <mat-select disableOptionCentering name="doctype" [(ngModel)]="selectedDocumentType"
              (selectionChange)="selectDoc($event)" [disabled]="isEditMode || action" required>
              <mat-option *ngFor="let documentType of documentTypeList" [value]="documentType.id">
                {{documentType.document_type | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'upload-document.file-description'|translate}}</mat-label>
            <input matInput name="desc" id="desc" [(ngModel)]="selectedDescription" maxlength="50" autocomplete="off" />
          </mat-form-field>
          <!-- <mat-form-field>
            <textarea rows="3" name="detail" matInput placeholder="{{'upload-document.details'|translate}}"
              [(ngModel)]="selectedDetail" maxlength="250" autocomplete="off"></textarea>
          </mat-form-field> -->
        </div>
        <div>
          <p class="upload-header only-web">
            {{'upload-document.upload-file'|translate}} *
          </p>

        </div>
        <div class="appointment-container" [class.grey]="!uploadStatus" [class.red]="uploadStatus">
          <app-appointment-prescription [document]="item" (updateDocument)="getUpdateDocument($event)"
            (isImageClicked)="getClickImageStatus($event)" (deleteDocument)="removeIdentityFile()">
          </app-appointment-prescription>
        </div>
        <app-datepicker [hidden]="!showExpiry" class="browse-input mt-30"
          [ngClass]="{'disabled': !isEditMode && this.Documents.PrescriptionParts?.length==0}" id="identityExpiry"
          labelText="{{'upload-document.expiry' | translate}}" [dateInput]="docExpiry" [minDate]="maxDate"
          [isRequired]="showExpiry">
        </app-datepicker>
        <div class="pres-preview mt-30" *ngIf="isEditMode">
          <span class="pdf-uploaded icon-ic-pdf"></span>
          <span class="ml-5 web-only" matTooltip="{{uploadedFile}}.pdf">{{uploadedFileWeb}}.pdf</span>
          <span class="ml-5 mobile-only" matTooltip="{{uploadedFile}}.pdf">{{uploadedFileMobile}}.pdf</span>
          <button class="mat-tbl-icon pull-right  mt-5" matTooltip="{{'all-apointment.view-document'|translate}}"
            mat-icon-button (click)="openImage(prescriptionFilePath)">
            <span class="icon-ic-view-prescription"></span>
          </button>
        </div>
        <div class="actions">
          <button mat-raised-button color="primary" (click)="saveDocument(documentForm)" type="submit"
            class="button_content primary-hover">
            {{'upload-document.upload'|translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<app-pdf-viewer *ngIf="isPdfViewerOpened" [blobSrc]="pdfBlob" pdftitle="{{'documents.document'|translate}}"
  (closed)="isPdfViewerOpened = false" [allPages]="true"></app-pdf-viewer>