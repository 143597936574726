<div class="mobile-drawer">
  <header class="user-main">
    <span class="titleWrapBox">{{userInitial}}</span>
    <div class="user-infor">
      <p class="name">{{userName}}</p>
      <p class="email">{{getLoggedInUserData()}}</p>
      <!-- <p class="station">{{samplingStation?.sampling_stations[0].sampling_station_name}}</p> -->
    </div>
    <div class="user-infor">
      <div class="options">
        <mat-form-field *ngIf="loader.loggedIn && loader.userRole == 1" class="selectLab p-0 onlyMobile">
          <mat-select #selectedCompanyValue [formControl]="loader.samplingcompany"
            placeholder="{{'toolbar.select-company'|translate}}">
            <mat-option class="selectLabOption" *ngFor="let company of loader.sampling_companies_list"
              [value]='company.org_id' (click)='loadSamplingStations(selectedCompanyValue.value)'>
              {{company["org_name"]}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="options">
        <mat-form-field *ngIf="loader.loggedIn && loader.userRole == 1" class="selectLab p-0 onlyMobile">
          <mat-select #selectedValue [formControl]="loader.sampling" placeholder="{{'toolbar.select-lab'|translate}}">
            <mat-option class="selectLabOption" *ngFor="let station of loader.sampling_stations_list"
              [value]='station.id' (click)='getSampling(selectedValue.value)'>
              {{station["sampling_station_name"]}}</mat-option>
            <!-- <mat-option value="Active">Lab1</mat-option>
                <mat-option value="Suspended">Lab2</mat-option> -->
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </header>
  <div class="nav-list" *ngFor="let node of navNodes">
    <h2>{{node.TranslationLabel | translate}}</h2>
    <nav>
      <ul>
        <ng-container *ngFor="let navNode of node.Children">
          <li [class.selected-submenu]="navNode.Id == loader.selectedMenuId"
            *ngIf="navNode.isHide != true && navNode.mobileHide != true  && ignoreMenuList.indexOf(submenu?.Id.toString())==-1">
            <span class="{{navNode.Icon}}"></span>
            <a (click)="onSubmenuClick(navNode, node, $event);">{{navNode.TranslationLabel | translate}}</a>
          </li>
        </ng-container>
      </ul>
    </nav>
  </div>
  <footer>
    <nav>
      <ul>
        <li><span class="icon-icon-dashboard"></span><a (click)="loader.Logout()">{{'mobile-drawer.signout' |
            translate}}</a></li>
      </ul>
    </nav>
  </footer>
</div>