<div class="Container">
  <div class="d-flex justify-content-between search-mr">
    <div class="d-flex Search">
      <div id="inputsearch">
        <mat-form-field>
          <mat-label>{{'sampling.id-station' | translate}}</mat-label>
          <input [(ngModel)]="searchinput" matInput (keydown.enter)="onSearch()"
            (keydown.space)="keyDownHandler($event)" />
        </mat-form-field>
      </div>
      <div>
        <button (click)="onSearch()" id="btn_search" style="margin-left: 8%" mat-raised-button color="primary"
          class="search-btn">
          {{'sampling.search' | translate}}
        </button>
      </div>

      <div class="mx-8" style="text-align: center">
        <button mat-button color="accent" id="btn_reset" class="ghost" (click)="reset()" style="margin-left: 15%">
          {{'sampling.reset' | translate}}
        </button>
      </div>
    </div>
    <div class="d-flex Button" style="margin-right: 6.5%">
      <div id="statusfilter" style="text-align: center">
        <mat-form-field floatLabel="never" class="dropdown-filter">
          <mat-select placeholder="{{'admin.status' | translate}}" disableOptionCentering [(ngModel)]="status">
            <mat-option *ngFor="let stepOption of sampleData | keyvalue: unsorted" (click)="statusFilter()"
              [value]="stepOption.key">
              {{ stepOption.value | translate }}
            </mat-option>

          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <button mat-raised-button color="primary" id="btn_add" (click)="addSampling()" class="test-btn"
          style="margin-left: 10%">
          {{'sampling.add-sampling-station' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="main-container main-container-res">
    <mat-table #table [dataSource]="dataSource" matSort class="table-height input-min-spacing table-flex"
      id="SamplingStationListingTable">

      <ng-container matColumnDef="SamplingStationId">
        <mat-header-cell *matHeaderCellDef mat-sort-header="external_id">
          <span>{{'sampling.sampling-station-id' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity.external_id }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="SamplingStationName">
        <mat-header-cell *matHeaderCellDef mat-sort-header="sampling_station_name">
          <span>{{'sampling.sampling-station-name' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity" matTooltip="{{ entity.sampling_station_name}}">
          {{ entity.sampling_station_name }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CompanyName">
        <mat-header-cell *matHeaderCellDef mat-sort-header="org_name">
          <span>{{'sampling.company-name' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity" matTooltip="{{ entity.org_name}}">
          {{ entity.org_name }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Status">
        <mat-header-cell *matHeaderCellDef>
          <span>{{'admin.status' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity"
          matTooltip="{{ entity.status=='Active'?('admin.active'|translate):('admin.suspended'|translate)}}">
          {{ entity.status=="Active" ? ('admin.active'|translate):('admin.suspended'|translate) }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="OpenFrom">
        <mat-header-cell *matHeaderCellDef mat-sort-header="open_from">
          <span>{{'sampling.open-from' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity.open_from.replace('Z', '') | date :'dd/MM/yyyy'}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="OpenUntill">
        <mat-header-cell *matHeaderCellDef mat-sort-header="open_until">
          <span>{{'sampling.open-until' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity.open_until.replace('Z', '') | date :'dd/MM/yyyy'}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ADELI"> <!--changed Contact to ADELI -->
        <mat-header-cell *matHeaderCellDef mat-sort-header="code_finess">
          <span>{{'sampling.code_finess' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity" matTooltip="{{ entity.code_finess }}">
          {{ entity.code_finess }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="icon-column">
        <mat-header-cell *matHeaderCellDef>
          {{'sampling.options' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          <button class="mat-tbl-icon" (click)="editSampling(entity)" mat-icon-button>
            <span class="icon-ic-edit" matTooltip="{{'user.edit' | translate}}"></span>
          </button>
        </mat-cell>
      </ng-container>



      <mat-header-row *matHeaderRowDef="columnList; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnList; let i = index" class="element-grid-row"
        (mouseover)="rowMouseOver(row)">
      </mat-row>
    </mat-table>


    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons="true">
    </mat-paginator>
  </div>
</div>