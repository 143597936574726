<button class="close" mat-dialog-close mat-button id="btn_close_dialog">X</button>
<div class="slider-data-container">
    <div class="header">
        <div style="text-align: center;">
            <app-section-title title="{{title | translate}}"></app-section-title>
        </div>
    </div>
    <form class="form-layout" [formGroup]="testForm" [appAutoScroll]="testForm">
        <div id="add_test" class="form-layout pt-30 input-min-spacing">
            <div [ngClass]="this.data.action!='edit' ? 'invisible' : 'form-layout'">
                <mat-form-field>
                    <mat-label class="label-float">{{'add-test.id' | translate}}</mat-label>
                    <input matInput name="Id" id="Id" [value]="data?.obj?.id" disabled />
                </mat-form-field>
            </div>
            <div></div>
            <mat-form-field class="md 40">
                <mat-label class="label-float">{{'add-test.short-name' | translate}}</mat-label>
                <input matInput formControlName="ShortName" maxlength="50" autocomplete="off">
            </mat-form-field>

            <mat-form-field def="TestType">
                <mat-label class="label-float">{{'add-test.type' | translate}}</mat-label>
                <mat-select formControlName="TestType" [disableOptionCentering]="true">
                    <mat-option *ngFor="let tsttpe of testTypes" [value]="tsttpe.Id">
                        {{loader.TranslateLang(tsttpe.Name)}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label class="label-float">{{'add-test.long-name' | translate}}</mat-label>
                <input matInput formControlName="LongName" autocomplete="off">
                <mat-error *ngIf="testForm.get('LongName').hasError('pattern')">{{'add-test.valid-input' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field def="Status">
                <mat-label class="label-float">{{'admin.status' | translate}}</mat-label>
                <mat-select formControlName="Status" [disableOptionCentering]="true">
                    <mat-option [value]="true">{{'admin.active' | translate}}</mat-option>
                    <mat-option [value]="false">{{'admin.suspended' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="Descbox">
                <mat-label>{{'add-test.description' | translate}}</mat-label>
                <textarea matInput formControlName="Description" cols="30" rows="10"></textarea>
                <mat-error *ngIf="testForm.get('Description').hasError('maxlength')">{{'add-test.valid-input' |
                    translate}}</mat-error>
            </mat-form-field>
            <div>
                <mat-checkbox formControlName="Prescription">{{'add-test.prescription' | translate}}</mat-checkbox>
                <div></div>
                <mat-checkbox formControlName="Appointment">{{'add-test.appointment' | translate}}</mat-checkbox>

                <div></div>
                <mat-checkbox formControlName="TestKit">{{'add-test.register-testkit' | translate}}</mat-checkbox>
                <div></div>

                <mat-checkbox formControlName="TestResult">{{'add-test.register-testresult' | translate}}</mat-checkbox>
                <!-- <input matInput placeholder="Browse and upload Image" readonly>
                        <a mat-button (click)="fileInputs.click()" >Browse</a> -->
                <!-- <mat-label class="form-label">Browse File</mat-label> -->

                <div></div>
                <!--  
                <div *ngFor="let gender of genders" formControlName="Gender">
                      <mat-checkbox  [value]="gender.Id" [checked]="checked(item)">
                            {{ gender.KeyName | translate }}
                        </mat-checkbox>  
                <div>   -->
                <!-- <mat-radio-group formControlName="Gender">
                    <mat-radio-button *ngFor="let gender of genders" [value]=gender.Id>{{gender.KeyName | translate}}
                    </mat-radio-button>
                </mat-radio-group> -->
                <div class="form-group genderdiv" formGroupName="myCheckboxGroup">
                    <div><span class="highlight">{{'add-test.available' | translate}}</span></div>
                    <mat-checkbox formControlName="Male" id="Male" class="gender-checkbox">{{'add-test.male' |
                        translate}}</mat-checkbox>
                    <mat-checkbox formControlName="Female" id="Female">{{'add-test.female' | translate}}</mat-checkbox>
                </div>
                <span class="invalid-feedback" *ngIf="testForm.controls['myCheckboxGroup'].errors 
                            && testForm.controls['myCheckboxGroup'].errors.requireOneCheckboxToBeChecked">
                    {{'add-test.errormsg' | translate}}
                </span>
            </div>

            <mat-form-field>
                <mat-label>{{'add-test.external-id' | translate}}</mat-label>
                <input matInput formControlName="ExternalId" />
                <mat-error *ngIf="testForm.get('ExternalId').hasError('pattern')">{{'add-test.valid-input' | translate}}
                </mat-error>
                <mat-error
                    *ngIf="testForm.get('ExternalId').invalid && !testForm.get('ExternalId').errors?.required && !testForm.get('ExternalId').hasError('pattern')">
                    {{'add-test.id-exists' | translate}}</mat-error>
            </mat-form-field>
            <div>
                <mat-form-field (click)="fileInputs.click()" class="pointCursor browse-input mt-remove">
                    <input *ngIf="fileName == ''" matInput placeholder="{{'add-test.upload-file' | translate}}"
                        readonly />
                    <input *ngIf="fileName != ''" matInput placeholder="{{fileName}}" readonly />
                    <button class="buttonInsideField upload-image">
                        {{'add-test.browse' | translate}} *
                    </button>
                </mat-form-field>
                <mat-hint align="start" class="browse-hint">{{'add-test.upload-description' | translate}}</mat-hint>
                <!-- {{fileName}} -->
                <!-- </mat-form-field> -->
                <input hidden type="file" accept=".png, .jpeg, .jpg, .svg" (change)="inputChange($event)" #fileInputs>
                <mat-error *ngIf="isInvalidFileSize">{{'add-test.size-error' | translate}}</mat-error>
                <mat-error *ngIf="isIncorrectFileFormat">{{'add-test.type-error' | translate}}</mat-error>
            </div>
        </div>
        <div class="actions" inline>
            <!-- <button mat-button color="accent" mat-dialog-close="true" class="ghost button_width" type="submit" id="back_btn">Back</button>
        <button mat-button color="accent" (click)="reset()" class="ghost button_width" type="submit" id="clear_btn">Clear</button>
        <button mat-button color="accent" (click)="save()" class="ghost button_width" type="submit" id="save_btn">Save</button> -->
            <button mat-button color="accent" class="ghost button_width" type="submit" id="btn_close"
                (click)="dismiss()">{{'admin.close' | translate}}</button>
            <button mat-button color="accent" class="ghost button_width" *ngIf="adminRole" id="btn_clear" type="button"
                (click)="clear()">{{'admin.clear'
                | translate}}</button>
            <button mat-button color="accent" class="ghost button_width" type="submit" id="btn_save" *ngIf="adminRole"
                [disabled]="this.data.action!='edit' && this.imageUrl == ''" (click)="saveTest()">{{'admin.save' |
                translate}}</button>
        </div>
    </form>
</div>