

// import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { SpinnerService } from '../../../core';
import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    Inject,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
import { SpinnerService } from '../../../core/spinner/spinner.service';
import { MessageService } from '../../../main/services/message.service';
import { AddCompanyService } from '../../services/add-company.service';
import { Company } from './Company';

@Component({
    selector: 'app-company-dialog',
    templateUrl: './company-dialog.component.html',
    styleUrls: ['./company-dialog.component.scss']
})
export class CompanyDialogComponent implements OnInit {
    CompanyForm: FormGroup;
    isBlur = false;
    // id = new FormControl('');
    // ExternalId = new FormControl('', [Validators.required, Validators.pattern('^.{1,20}$'), Validators.pattern('^[a-zA-Z0-9 ]+$')]);
    // Name = new FormControl('', [Validators.required, Validators.pattern('^.{1,100}$'), Validators.pattern('^[a-zA-Z0-9 ]+$')]);
    // Status = new FormControl(true,);
    company: Company;
    title: string;
    saveData: {};
    dataFieldsFilled: boolean;
    adminRole: boolean;
    savealert = {};
    errorMessage = '';

    // data: any["edit"]=true;

    constructor(
        private readonly dialogRef: MatDialogRef<CompanyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public addCompanyService: AddCompanyService,
        private formBuilder: FormBuilder,
        private readonly spinner: SpinnerService,
        private readonly messageService: MessageService,
        private translate: TranslateService,
        private localeService: LocaleService
    ) {
        this.initializeCompanytForm();

        this.title =
      this.data.action == 'edit'
          ? 'company.edit-company'
          : 'company.add-company';
        this.savealert = this.data.savealert;
        this.errorMessage = this.data.errMsg;
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }
    initializeCompanytForm() {
        this.CompanyForm = this.formBuilder.group({
            Id: new FormControl(0),
            ExternalId: new FormControl('', [
                Validators.required,
                Validators.pattern('^.{1,15}$'),
                Validators.pattern('^[a-zA-Z0-9\s]+$')
                // Validators.pattern("^\s"),
            ]),
            Name: new FormControl('', [
                Validators.required
            ]),
            Status: new FormControl(true, [Validators.required])
        });
    }

    @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

    ngAfterViewInit(): void {
        setTimeout(
            () => this.formFields.forEach((ff) => ff.updateOutlineGap()),
            100
        );
    }

    bindCompanyObject(companydetail: any) {
        this.CompanyForm.patchValue({
            Id: companydetail?.id,
            ExternalId: companydetail?.external_id,
            Name: companydetail?.org_name,
            Status: companydetail?.is_active
        });
    }
    ngOnInit(): void {
    // this.company=this.inputdata;
        console.log('oninit');
        console.log(this.data);
        this.adminRole = JSON.parse(sessionStorage.getItem('access_token'))['role'] == 5;
        if (this.data.obj != null) {
            this.bindCompanyObject(this.data.obj);
        }
    }
    public displaySpinner(flag: boolean): void {
        this.spinner.show(flag);
    // setTimeout(() => {
    //     this.spinner.show(false);
    // }, 5000);
    }
    public success(message: any): void {
        this.messageService.success(this.translate.instant(message));
    }
    public alert(error: any): void {
        this.messageService.alert(this.translate.instant(error), 10000);
    }

    saveCompany() {
    //   if(!this.CompanyForm.valid){
    //     this.dialog.open(PopupWindowComponent, {
    //     width: '500px',
    //     height: '340px',
    //     data: {header:"Error!",body:"Fill the required details"},
    //     autoFocus: false,
    //     disableClose: true

        // });
        // }
        if (this.CompanyForm.valid) {
            // this.loader.show();
            this.spinner.show(true);
            const company = this.updateCompanyObject();
            this.addCompanyService
                .postAdminCompany(company, this.data.action)
                .subscribe(
                    () => {
                        this.spinner.show(false);

                        this.success(this.savealert[this.data.action]);
                        this.dialogRef.close(null);
                    },
                    (errorResponse: HttpErrorResponse) => {
                        this.spinner.show(false);
                        if (errorResponse.error.code == 423) {
                            this.CompanyForm.controls.ExternalId.setErrors([
                                { existingId: true }
                            ]);
                        } else {
                            this.alert(this.errorMessage);
                            console.log(errorResponse);
                            this.dialogRef.close(null);
                            // if (errorResponse.status !== 400) {
                            //   this.notification.warn(this.ValidationMessages.GENERAL_FAILURE);
                            // }
                        }
                    }
                );
        }
    }
    updateCompanyObject() {
        return {
            id: this.CompanyForm.controls.Id.value,
            external_id: this.CompanyForm.controls.ExternalId.value,
            org_name: this.CompanyForm.controls.Name.value,
            is_active: this.CompanyForm.controls.Status.value
        };
    }

    reset() {
        this.CompanyForm.controls.ExternalId.setValue('');
        this.CompanyForm.controls.Name.setValue('');
        this.CompanyForm.controls.Status.setValue(true);
        this.CompanyForm.markAsUntouched();
    }

    dismiss() {
        this.dialogRef.close(null);
    }
}
