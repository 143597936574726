import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogPublisherConfig } from '../models/log-publisher-config.model';
import { LogPublisher } from '../models/log-publishers.model';
import { AppSettingsService } from './../config/app-settings.service';
import { LogConsole } from './log-console';
import { LogLocalStorage } from './log-local-storage';
import { LogWebApi } from './log-web-api';

@Injectable()
/** Service to build Publishers for logging based on logger configuration */
export class LogPublishersService {

    constructor(private readonly http: HttpClient, private readonly appSettings: AppSettingsService) { }

    /* #region Public Methods */
    /** Method to generate publishers which are active based on logger configuration */
    public buildPublishers(): LogPublisher[] {
        const publishers: LogPublisher[] = [];
        let logPub: LogPublisher;
        const settings: LogPublisherConfig[] = this.appSettings.loggerConfiguration();
        for (const publisher of settings.filter((config: LogPublisherConfig) => config.isActive)) {
            switch (publisher.loggerName.toLowerCase()) {
                case 'localstorage':
                    logPub = new LogLocalStorage();
                    logPub.Name = 'localstorage';
                    break;
                case 'webapi':
                    logPub = new LogWebApi(this.http);
                    logPub.Name = 'webapi';
                    break;
                default:
                    logPub = new LogConsole();
                    logPub.Name = 'console';
                    break;
            }
            // Set location, if any, of the logging
            logPub.Location = publisher.loggerLocation;
            // Add publisher to array
            publishers.push(logPub);
        }
        return publishers;
    }
    /* #endregion */
}
