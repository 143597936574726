<div class="hero-banner-container">
    <div class="d-flex flex-column">
        <header class="pull-left">
            <h5 class="pull-left"> {{'seo.heading'|translate}}</h5>
        </header>
        <form [formGroup]="seoForm">
            <div class="heroBannerWrapper">
                <div class="field-wrapper">
                    <mat-form-field>
                        <mat-label class="label-float">{{'seo.html-tags' | translate}}</mat-label>
                        <textarea matInput cols="30" name="seoField1" id="seoField1" formControlName="html_tags"
                            rows="2"></textarea>
                    </mat-form-field>
                </div>
                <div class="field-wrapper">
                    <mat-form-field>
                        <mat-label>{{'seo.meta-desc' | translate}}</mat-label>
                        <textarea matInput cols="30" name="seoField2" id="seoField2" formControlName="meta_desc"
                            rows="2"></textarea>
                    </mat-form-field>

                </div>
            </div>
        </form>
        <div class="actions">
            <button mat-raised-button color="primary" class="ghost submit-click"
                (click)="save()">{{'landing-page-config.save' |
                translate}}</button>
            <button mat-button color="accent" class="ghost"
                (click)="cancelClicked.emit()">{{'landing-page-config.cancel' |
                translate}}</button>
        </div>
    </div>
</div>