import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '../../../core';


@Injectable({
    providedIn: 'root'
})
export class SamplingService {
    constructor(public httpClient: HttpClient, private readonly appSettings: AppSettingsService) {}

    addSamplingStation(samplingStationData: Object) {
        return this.httpClient.post(
            this.appSettings.adminBaseUrl + 'company/stations',
            samplingStationData
        );
    }

    editSamplingStation(samplingStationData: Object) {
        return this.httpClient.put(
            this.appSettings.adminBaseUrl + 'company/stations',
            samplingStationData
        );
    }
}
