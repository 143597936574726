<div class="pull-right">
    <button mat-icon-button color="accent" (click)="dismiss()">
        <span class="icon-ic-close"></span>
    </button>
</div>
<div class="dialog-container">
    <div >
        <img src="./assets/images/application-logos/svg/mail-sent-ic.svg" />
        <h4>{{'login.account-creation-confirmation' | translate}}</h4>
        <p>
          {{'login.mail-confirmation-instructions' | translate}}
          <span id="email">{{username}}</span>
        </p>
        <p>{{'login.note' | translate}}</p> 
        <p>{{'login.proceed' | translate}}</p>

       <p>
          {{'login.no-link' | translate}}
          <a class="linkurl" role="button"   (click)="sendactivationLink()">{{'login.resend-link' | translate}}</a>
        </p>
      </div>

    <div class="actions">
        <button mat-raised-button color="primary" type="submit" class="button_content" (click)="confirmed()">{{
            'login.proceed-button' | translate}}</button>    
    </div>
</div>
