<div class="hero-banner-container">
    <div class="d-flex flex-column">
        <header class="pull-left">
            <h5 class="pull-left"> {{'hero-banner.heading'|translate}}</h5>
        </header>
        <div class="select-lang-title">
            {{'landing-page-config.select-lang'|translate}}
        </div>

        <mat-tab-group class="pull-left languageTab mt-10" [(selectedIndex)]="selectedLangTabIndex"
            (selectedTabChange)="onLangTabChange($event.index)" animationDuration="1000ms">
            <mat-tab label="{{'landing-page-config.lang1'|translate}}">
                <form [formGroup]="enForm">
                    <div class="heroBannerWrapper">
                        <div class="field-wrapper">
                            <mat-form-field>
                                <mat-label class="label-float">{{'hero-banner.field1' | translate}}</mat-label>
                                <input matInput formControlName="top_blue_bar_message" name="enField1" id="enField1"
                                    max="{{field1MaxLength}}" maxlength="{{field1MaxLength}}" />
                            </mat-form-field>
                            <div class="character-count">
                                {{enForm.get('top_blue_bar_message')?.value?.length}}/{{field1MaxLength}}
                            </div>
                        </div>
                        <div> </div>
                        <div class="field-wrapper">
                            <mat-form-field>
                                <mat-label>{{'hero-banner.field2' | translate}}</mat-label>
                                <textarea matInput cols="30" name="enField2" id="enField2" formControlName="title"
                                    rows="2" maxlength="{{field2MaxLength}}"></textarea>
                            </mat-form-field>
                            <div class="character-count">
                                {{enForm.get('title')?.value?.length}}/{{field2MaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <mat-form-field>
                                <mat-label>{{'hero-banner.field3' | translate}}</mat-label>
                                <textarea matInput cols="30" name="enField3" id="enField3" formControlName="sub_title"
                                    rows="2" maxlength="{{field3MaxLength}}"></textarea>
                            </mat-form-field>
                            <div class="character-count">
                                {{enForm.get('sub_title')?.value?.length}}/{{field3MaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <mat-form-field>
                                <mat-label class="label-float">{{'hero-banner.field4' | translate}}</mat-label>
                                <input matInput name="enField4" id="enField4" (blur)="syncWithFrValue()"
                                    [maxlength]="field4MaxLength" [max]="field4MaxLength" formControlName="video_url" />
                            </mat-form-field>
                            <div class="character-count">
                                {{'hero-banner.youtube-link-info'|translate}}
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </form>
            </mat-tab>
            <mat-tab label="{{'landing-page-config.lang2'|translate}}">
                <form [formGroup]="frForm">
                    <div class="heroBannerWrapper">
                        <div class="field-wrapper">
                            <mat-form-field>
                                <mat-label class="label-float">{{'hero-banner.field1' | translate}}</mat-label>
                                <input matInput formControlName="top_blue_bar_message" name="frField1" id="frField1"
                                    maxlength="{{field1MaxLength}}" />
                            </mat-form-field>
                            <div class="character-count">
                                {{frForm.get('top_blue_bar_message')?.value?.length}}/{{field1MaxLength}}
                            </div>
                        </div>
                        <div> </div>
                        <div class="field-wrapper">
                            <mat-form-field>
                                <mat-label>{{'hero-banner.field2' | translate}}</mat-label>
                                <textarea matInput cols="30" name="frField2" id="frField2" formControlName="title"
                                    rows="2" maxlength="{{field2MaxLength}}"></textarea>
                            </mat-form-field>
                            <div class="character-count">
                                {{frForm.get('title')?.value?.length}}/{{field2MaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <mat-form-field>
                                <mat-label>{{'hero-banner.field3' | translate}}</mat-label>
                                <textarea matInput cols="30" name="frField3" id="frField3" formControlName="sub_title"
                                    rows="2" maxlength="{{field3MaxLength}}"></textarea>
                            </mat-form-field>
                            <div class="character-count">
                                {{frForm.get('sub_title')?.value?.length}}/{{field3MaxLength}}
                            </div>
                        </div>
                        <div class="field-wrapper">
                            <mat-form-field>
                                <mat-label class="label-float">{{'hero-banner.field4' | translate}}</mat-label>
                                <input matInput name="frField4" id="frField4" (blur)="syncWithEnValue()"
                                    formControlName="video_url" [maxlength]="field4MaxLength" [max]="field4MaxLength" />
                            </mat-form-field>
                            <div class="character-count">
                                {{'hero-banner.youtube-link-info'|translate}}
                            </div>
                        </div>


                        <div>

                        </div>
                    </div>
                </form>
            </mat-tab>
        </mat-tab-group>
        <div class="actions">
            <button mat-raised-button color="primary" class="ghost submit-click"
                (click)="save()">{{'landing-page-config.save' |
                translate}}</button>
            <button mat-button color="accent" class="ghost"
                (click)="cancelClicked.emit()">{{'landing-page-config.cancel' |
                translate}}</button>
        </div>
    </div>
</div>