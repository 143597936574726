import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '../../core';
import { Observable } from 'rxjs';
import { RMData } from '../patient/relative-members/relative-member-data.model';

@Injectable({
  providedIn: 'root'
})
export class RelativeMemberService {

  constructor(private httpClient: HttpClient,
    private readonly appSettings: AppSettingsService) { }

  findMember(postData: any): Observable<any> {
    const body = JSON.stringify(postData);
    return this.httpClient.post<any>(this.appSettings.patientBaseUrl + 'rm/search', body);
  }

  getPatientInfo(id: any) {
    return this.httpClient.get(this.appSettings.patientBaseUrl + 'find?id=' + id);
  }

  getRmData(patientId: string): Observable<RMData[]> {
    return this.httpClient.get<RMData[]>(this.appSettings.patientBaseUrl + "rm/list?patientId=" + patientId);
  }

  shareAccess(postData: any): Observable<any> {
    const body = JSON.stringify(postData);
    return this.httpClient.post<any>(this.appSettings.patientBaseUrl + 'rm/add', body);
  }

  deletePatient(id: any): Observable<any> {
    return this.httpClient.delete<any>(this.appSettings.patientBaseUrl + "deletepatient?id=" + id, { observe: 'response' });
  }
  removeAccess(rm_id: any, sec_id: any): Observable<any> {
    return this.httpClient.delete<any>(this.appSettings.patientBaseUrl + "rm/remove?rm_id=" + rm_id + "&p_id=" + sec_id, { observe: 'response' });
  }

  elevateRM(postData: any): Observable<any> {
    const body = JSON.stringify(postData);
    return this.httpClient.post<any>(this.appSettings.patientBaseUrl + 'satopa', body);
  }
}