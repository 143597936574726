import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '../../core';

@Injectable({
    providedIn: 'root'
})
export class BookingAppointmentGuestService {

    constructor(private httpClient: HttpClient, private readonly appSettings: AppSettingsService) { }
    getAdmintesttype(ss_id: any = 0) {
        return (this.httpClient.get(this.appSettings.adminBaseUrl + 'testtype?sampling_station_id=' + ss_id));
    }
    getAdmintests(ss_id: any = 0) {
        return (this.httpClient.get(this.appSettings.adminBaseUrl + 'calendar/tests?sampling_station_id=' + ss_id));
    }

    getAdminSamplingStaions(testType: any) {
        return (this.httpClient.get(this.appSettings.adminBaseUrl + 'company/stationsbytesttype?test_id=' + testType));
    }
    getAdminSamplingStaionsByTestExtId(testType: any) {
        return (this.httpClient.get(this.appSettings.adminBaseUrl + 'company/stationsbytesttype?test_ext_id=' + testType));
    }
    getAdmintestsSorted(ss_id: number = 0) {
        return (this.httpClient.get(this.appSettings.adminBaseUrl + 'calendar/tests?sampling_station_id=' + ss_id + '&sort_appointment_order=true'));
    }

    getAdminTestSortedByExtId(ss_id: any = 0) {
        return (this.httpClient.get(this.appSettings.adminBaseUrl + 'calendar/tests?external_code=' + ss_id + '&sort_appointment_order=true'));
    }
    getAdminTestTypeByExtId(ss_id: any = "") {
        return (this.httpClient.get(this.appSettings.adminBaseUrl + 'testtype?external_code=' + ss_id));
    }

    getAdminSamplingStaionByExtId(ext_id: string) {
        return (this.httpClient.get(this.appSettings.adminBaseUrl + 'company/stationsbytesttype?search_text=' + ext_id));
    }
}
