import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-title',
    template: `
  <div class="sec-title">{{title}}<span class="count-icon" *ngIf="dataCount">{{dataCount}}</span><span style="margin-left: 16px;" *ngIf="count"></span> <span [id]="countId" class="count-icon" *ngIf="count">{{count}}</span></div>
  `
})
export class SectionTitleComponent {
    @Input() title: string;
    @Input() count: number;
    @Input() countId?: string;
    @Input() dataCount: number;
}
