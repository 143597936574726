<div class="slider-data-container" [ngClass]="{'p-0':!sliderFormData}">
    <ng-container *ngIf="sliderFormData">
        <div class="header">
            <div class="pull-left">
                <app-section-title *ngIf="!isEdit" title="{{'add-member.title'|translate}}"></app-section-title>
                <app-section-title *ngIf="isEdit" title="{{'add-member.modify-title'|translate}}"></app-section-title>
            </div>
            <div class="pull-right">
                <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
                    <span class="icon-ic-close"></span>
                </button>
            </div>
        </div>

        <div class="member-form-container">
            <div class="note-container">
                <div class="img-container">
                    <img src="/assets/images/application-logos/svg/ic-data-safety.svg">
                </div>
                <span>{{'add-member.note-text'|translate}}<a class="privacy-link"
                        href="{{'login.privacy-policy-link' | translate}}"
                        target="_blank">{{'add-member.note-link'|translate}}</a>
                </span>
            </div>
        </div>
    </ng-container>
    <form (ngSubmit)="findMember()" *ngIf="!noRecord" #ngForm [appAutoScroll]="ngForm">
        <div class="searchFormwrapper">
            <div>
                <label style="display: block">{{'add-member.gender' | translate}}<span style="color:red;">
                        *</span></label>
                <mat-radio-group [formControl]="gender" required>
                    <mat-radio-button *ngFor="let gen of genders" value="{{gen.Id}}">{{gen.KeyName|translate}}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="gender.value && gender.invalid">{{'add-member.valid-gender' | translate}}
                </mat-error>
            </div>
            <div class="onlyDesktop"> </div>

            <mat-form-field>
                <mat-label>{{'add-member.first-name' | translate}}</mat-label>
                <input matInput name="FirstName" id="FirstName" [formControl]="firstName"
                    (keypress)="loader.keyPressAlphabets($event)" (paste)="loader.pasteAlphabets($event)" />
                <mat-error *ngIf="firstName.invalid">{{'add-member.valid-first-name' | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'add-member.birth-name' | translate}}</mat-label>
                <input matInput name="BirthName" id="BirthName" [formControl]="birthName"
                    (keypress)="loader.keyPressAlphabets($event)" (paste)="loader.pasteAlphabets($event)" />
                <mat-error *ngIf="birthName.invalid">{{'add-member.valid-birth-name' | translate}}</mat-error>
            </mat-form-field>

            <app-datepicker id="dateofBirth" labelText="{{'add-member.dob' | translate}}" [dateInput]="dateOfBirth"
                isRequired="true" isRequiredError="true" [maxDate]="maxDate">
            </app-datepicker>
        </div>

        <div class="info-container" *ngIf="activeUser">
            <p class="info-title">{{'add-member.existing-account' |translate}}</p>
            <div>{{'add-member.existing-account-line1'|translate}} {{accounts[0]?.email}}
            </div>
            <div>{{'add-member.existing-account-line2'|translate}}
            </div>
        </div>

        <div class="actions" *ngIf="sliderFormData">
            <div class="inner-btn">
                <button mat-raised-button color="primary" class="button_width" type="submit" id="btn_save">
                    {{'add-member.btn-next' | translate}}
                </button>
            </div>
        </div>
    </form>
    <div *ngIf="noRecord">
        <app-add-member [firstNameInput]="firstName.value" [birthNameInput]="birthName.value" (goToBack)="goBack()"
            [dobInput]="dateOfBirth.value" [genderInput]="gender.value" [isEdit]="isEdit" [rmInfo]="rmInfo"
            [updateList]="updateList" (submitRelativeMember)="submitRelativeMember.emit($event)"></app-add-member>
    </div>
</div>