import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'descriptionShortener'
})
export class DescriptionShortenerPipe implements PipeTransform {

  transform(value: any, size:any): String {
    if(value.length<=size)
      return value;
    else return value.substring(0,size)+"..."
  }

}
