import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '../../../core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HealthcareProfessionals } from '../../models/common.model';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalsService {

  private refreshSubject = new BehaviorSubject<boolean>(false);
  constructor(public httpClient: HttpClient, private readonly appSettings: AppSettingsService) { }

  refreshList$ = this.refreshSubject.asObservable();

  GetAllProfessionals(): Observable<HealthcareProfessionals[]> {
    return this.httpClient.get<HealthcareProfessionals[]>(
      this.appSettings.adminBaseUrl + 'professionals'
    );
  }
  addProfessionals(professionalData: Object) {
    return this.httpClient.post(
      this.appSettings.adminBaseUrl + 'professional', professionalData);
  }

  editProfessionals(professionalData: Object) {
    return this.httpClient.put(
      this.appSettings.adminBaseUrl + 'professional', professionalData);
  }
  getMastersByCategory(key: string) {
    return this.httpClient.get(this.appSettings.adminBaseUrl + `master?key=${key}`);
  }

  triggerRefresh() {
    this.refreshSubject.next(true);
  }
  resetRefresh() {
    this.refreshSubject.next(false);
  }
}
