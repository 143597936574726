import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
//import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LocaleService } from '../../../app.locale.service';
import { SpinnerService } from '../../../core';
import { LoaderService } from '../../../main';
import { MessageService } from '../../../main/services/message.service';
import { TestService } from '../../services/add-test-service.service';
import { PatientService } from '../../services/patient.service';
import { ScanQrComponent } from '../find-patient/scan-qr/scan-qr.component';
import { AntigenPCRTest, PatientAppointment } from './register-test-kit.model';
import { Location } from '@angular/common';

@Component({
    selector: 'app-register-test-kit',
    templateUrl: './register-test-kit.component.html',
    styleUrls: ['./register-test-kit.component.scss']
})
export class RegisterTestKitComponent implements OnInit {
    steps: number;
    selectedAppointment: PatientAppointment;
    seacrchInput = new FormControl();
    testSelection = new FormControl();
    displayedColumns: string[] = [
        'firstName',
        'lastName',
        'birthName',
        'dateOfBirth',
        'ssn',
        'gender'
    ];
    dataSource: any = [];
    refresh = true;
    isDOB = false;
    hasNoSpecialChars = /^[A-Za-z0-9àâçéèêëîïôûùüÿñæœÀÂÇÉÈÊËÎÏÔÛÙÜŸÑÆŒ\s@.]+$/;
    newDOB = '';
    selectedPatient: any;
    testKitCode: string;
    tests: Array<AntigenPCRTest>;
    appointments: Array<PatientAppointment>;
    protected navigationDetails: any;

    public qr_id = new FormControl('', [
        Validators.required,
        Validators.pattern('^[A-Za-z0-9 ]{13,13}$')
    ]);

    public test_kit_id = new FormControl('');
    public test_id = new FormControl('', [Validators.required]);
    public registerKitForm = this.formBuilder.group({
        qr_id: this.qr_id,
        test_id: this.test_id,
        test_kit_id: this.test_kit_id
    });
    patientId: any;
    appointmentId: any;
    patient: any;

    constructor(
        private dialog: MatDialog,
        public patientService: PatientService,
        private translate: TranslateService,
        private localeService: LocaleService,
        private formBuilder: FormBuilder,
        public loader: LoaderService,
        private readonly messageService: MessageService,
        private datePipe: DatePipe,
        public spinner: SpinnerService,
        public testService: TestService,
        //private router: Router,
        private location: Location
    ) {
        this.appointments = new Array<PatientAppointment>();
        this.selectedAppointment = new PatientAppointment();
        this.tests = new Array<AntigenPCRTest>();
        this.navigationDetails = this.location.getState();
        this.patientId =
            this.navigationDetails?.patientId;
        this.appointmentId =
            this.navigationDetails?.appointmentId;
        this.qr_id.setValue(
            this.navigationDetails?.patientQrId
        );
    }

    ngOnInit(): void {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
        this.seacrchInput.setValue(this.loader.patientSearchText);
        this.patient = this.loader.patient;
        this.getAntigenPCRTests();

        if ((this.patientId && this.appointmentId && this.qr_id)) {
            this.getPatientDetail(this.qr_id.value);
            // this.qr_id.setValue(this.qr_id);
        } else if (this.patient?.id) {
            // this.qr_id=this.patient?.qr_id;
            this.rowClicked(this.patient);
        } else if (this.loader.patientSearchText?.length > 2) {
            this.findPatient(false);
        }
    }

    findPatient(isChange: boolean) {
        if (isChange) {
            this.loader.patient = {};
            this.patient = {};
        }
        this.loader.patientSearchText = this.seacrchInput.value;
        if (this.seacrchInput?.value?.length < 3) {
            this.reset();
        } else {
            const re = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/; // regex to validate DOB format
            if (re.test(this.seacrchInput.value)) {
                this.isDOB = true;
                this.newDOB = this.seacrchInput.value.split('/').reverse().join('-');
            } else {
                this.isDOB = false;
            }
            setTimeout(() => {
                this.findPatientList();
            }, 0);
        }
    }

    private findPatientList() {
        const self = this;
        self.refresh = false;
        const searchReq = this.isDOB ? { DOB: this.newDOB } : { MixedData: this.seacrchInput.value, org_id: this.loader.ss_selected_org_id };
        if (this.isDOB) {
            this.patientService.getPatients(searchReq).subscribe(
                (response: any) => {
                    if (response['message'] !== 'No Patient Found') {
                        self.refresh = true;
                        self.steps = null;
                        self.dataSource = response.data;
                    } else {
                        this.reset();
                    }
                },
                (error) => {
                    this.reset();
                    console.log(error);
                }
            );
        } else if (this.hasNoSpecialChars.test(searchReq.MixedData)) {
            this.patientService.getPatients(searchReq).subscribe(
                (response: any) => {
                    if (response['message'] !== 'No Patient Found') {
                        self.refresh = true;
                        self.steps = null;
                        self.dataSource = response.data;
                    } else {
                        this.reset();
                    }
                },
                (error) => {
                    this.reset();
                    console.log(error);
                }
            );
        }

    }
    scanQR() {
        this.dialog
            .open(ScanQrComponent, {
                width: '500px',
                height: '450px',
                autoFocus: false
            })
            .afterClosed()
            .subscribe((res) => {
                setTimeout(() => {
                    if (res == undefined) {
                        return;
                    }
                    this.getPatientDetail(res);
                }, 0);
            });
    }

    private getPatientDetail(res: any) {
        const searchReq = { QrId: res, org_id: this.loader.ss_selected_org_id };
        this.patientService.getPatients(searchReq).subscribe((response: any) => {
            if (response['message'] !== 'No Patient Found') {
                if (this.patientId) {
                    this.steps = 2;
                } else {
                    this.refresh = false;
                    this.steps = 1;
                }
                this.loader.patient = response.data[0];
                this.selectedPatient = response.data[0];
                this.getPatientAppointment(this.selectedPatient.id);
                this.qr_id.setValue(this.selectedPatient.qr_id);
                // this.loader.highlightLeftMenu(["receptionist/patient-info"], {
                //   state: { patient: response[0] },
                // });
            } else {
                this.messageService.warning(this.translate.instant('receptionist.invalid-QR'));
            }
        });
    }

    scanTestKitCode() {
        // this.testKitCode="";
        this.dialog
            .open(ScanQrComponent, {
                width: '500px',
                height: '450px',
                autoFocus: false
            })
            .afterClosed()
            .subscribe((res) => {
                setTimeout(() => {
                    this.test_kit_id.setValue(res);
                }, 0);
            });
    }
    reset() {
        const self = this;
        if (this.seacrchInput.value) {
            self.refresh = false;
            // this.seacrchInput.reset();
            setTimeout(() => {
                self.refresh = true;
                self.steps = null;
                self.dataSource = [];
            }, 0);
        } else {
            self.refresh = false;
            self.steps = null;
            setTimeout(() => {
                self.refresh = true;
                self.dataSource = [];
            }, 0);
        }
    }

    clearSearch() {
        const self = this;
        self.refresh = false;
        self.steps = null;
        this.seacrchInput.reset();
        this.loader.patientSearchText = '';
        this.loader.patient = {};
        setTimeout(() => {
            self.refresh = true;
            self.dataSource = [];
        }, 0);
    }

    getAntigenPCRTests() {
        this.testService.getAntigenPCRTests(this.loader.ss_id).subscribe(
            (response: any) => {
                console.log(response);
                this.tests = response;
            },
            (error) => {
                this.reset();
                console.log(error);
            }
        );
    }
    rowClicked(row: any) {
        this.selectedAppointment = new PatientAppointment();
        this.appointments = new Array<PatientAppointment>();
        this.refresh = false;
        // this.selectedAppointment
        this.steps = 1;
        this.test_kit_id = new FormControl('');
        // get patient appointment
        this.selectedPatient = row;
        this.loader.patient = row;
        this.patient = row;
        this.qr_id.setValue(this.selectedPatient.qr_id);
        this.getPatientAppointment(row.id);
    }

    private getPatientAppointment(p_id: any) {
        this.patientService
            .getPatientsAntigenPCRAppointment(
                this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
                p_id,
                this.loader.ss_id
            )
            .subscribe(
                (response: any[]) => {
                    console.log(response);
                    this.appointments = [];
                    if (response) {
                        // appointment is there
                        this.appointments = response;
                        if (this.appointmentId) {
                            this.appointments = this.appointments.filter(
                                (i) => i.AppointmentId == this.appointmentId
                            );
                            // this.selectedAppointment = appointmentDetail ? appointmentDetail : new PatientAppointment();
                        }
                        if (this.appointments.length > 0) {
                            this.selectedAppointment = this.appointments[0];
                        } else {
                            this.selectedAppointment = new PatientAppointment();
                        }
                    } else {
                    }
                    this.refresh = false;
                    this.steps = 1;
                    // this.selectedPatient=row;
                    // if (response["message"] !== "No Patient Found") {
                    //   this.refresh = false;
                    //   this.steps = 1;
                    //   this.selectedPatient=row;
                    // } else {
                    //   this.reset();
                    // }
                },
                (error) => {
                    this.reset();
                    console.log(error);
                }
            );
    }

    testChange(evt: any) {
        this.test_id.setValue(evt.source.value);
    }

    onRegister() {
        if (this.steps === 1) {
            if (this.registerKitForm.controls.qr_id.valid) {
                if (this.selectedAppointment.AppointmentId) {
                    this.test_id.setValue(this.selectedAppointment.TestId.toString());
                    if (this.selectedAppointment.Barcode) {
                        this.test_kit_id.setValue(
                            this.selectedAppointment.Barcode.toString()
                        );
                    }
                } else {

                    if (this.tests?.length > 0) {
                        this.test_id.setValue(this.tests[0].id.toString());
                        this.test_kit_id.setValue('');
                        this.registerKitForm.markAsUntouched();

                    } else {
                        this.messageService.alert(
                            this.translate.instant('appointments.no-test'),
                            5000
                        );
                        return;
                    }
                }
                this.steps = 2;
            }
        } else if (this.steps === 2) {
            if (
                this.test_kit_id.value.length < 12 ||
                this.test_kit_id.value.length > 12
            ) {
                this.test_kit_id = new FormControl(this.test_kit_id.value, [
                    Validators.required,
                    Validators.pattern('^[0-9]{12,12}$')
                ]);
            } else {
                // if (this.selectedAppointment) {
                //   this.test_id.setValue(this.selectedAppointment.AppointmentId.toString())
                // }
                if (this.test_kit_id.value.match('^[0-9]*$')) {
                    this.messageService
                        .confirm(
                            this.translate.instant('appointments.confirm-desc'),
                            this.translate.instant('appointments.confirm-title'),
                            this.translate.instant('appointments.yes'),
                            this.translate.instant('appointments.no'),
                            this.tests.filter(t => t.id === parseInt(this.test_id.value))[0].short_name,
                            this.test_kit_id.value
                        )
                        .subscribe((actionResult) => {
                            if (actionResult) {
                                if (this.selectedAppointment.AppointmentId) {
                                    // update appointment
                                    const payload = {
                                        calendar_id: this.selectedAppointment.CalendarId,
                                        slot_time: moment.utc(this.selectedAppointment.SlotTime).local().format('YYYY-MM-DDTHH:mm:SS') + "Z",
                                        // capacity: this.selectedAppointment.Capacity,
                                        tests: [parseInt(this.test_id.value, 10)],
                                        sampling_station_id:
                                            this.selectedAppointment.SamplingStationId,
                                        patient_id: this.selectedPatient.id,
                                        test_kit_barcode: this.test_kit_id.value,
                                        id: this.selectedAppointment.AppointmentId
                                    };
                                    console.log(payload);

                                    this.updateAppointment(payload);
                                } else {
                                    // Add appointments
                                    const currDate = new Date();
                                    currDate.setHours(0);
                                    currDate.setMinutes(0);
                                    currDate.setSeconds(0);

                                    const payload = {
                                        tests: [parseInt(this.test_id.value, 10)],
                                        sampling_station_id: this.loader.ss_id,
                                        patient_id: this.selectedPatient.id,
                                        test_kit_barcode: this.test_kit_id.value,
                                        slot_time: moment.utc(currDate).local().format('YYYY-MM-DDTHH:mm:SS') + "Z"
                                    };

                                    this.addAppointment(payload);
                                }
                            }
                        });
                } else {
                    this.test_kit_id.setErrors([{ patternMismatch: true }]);
                }
            }
        }
    }

    updateAppointment(payload: any) {
        this.patientService.updateAppointment(payload).subscribe(
            (response: any[]) => {
                response;
                this.messageService.success(
                    this.translate.instant('view-booking.booking-saved'),
                    5000
                );
                this.clearSearch();
                this.reset();

                // this.steps=1;

                // this.step = 1;
                // this.getPatientBooking();
            },

            (error) => {
                if (error.error.internal_code === 1022) {
                    this.messageService.alert(
                        this.translate.instant('view-booking.already-in-use-error'),
                        5000
                    );
                } else if (error.status === 423) {
                    this.messageService.alert(error.error.message, 5000);
                } else {
                    alert(error['message']);
                    this.messageService.alert(error['message'], 5000);
                }
            }
        );
    }

    addAppointment(payload: any) {
        this.spinner.show(true);
        this.patientService.saveAppointment(payload).subscribe(
            (response: any[]) => {
                this.spinner.show(false);
                response;
                this.messageService.success(
                    this.translate.instant('view-booking.booking-saved'),
                    5000
                );
                this.clearSearch();
                this.reset();
            },

            (errorResponse: HttpErrorResponse) => {
                if (errorResponse.error.internal_code === 425) {
                    this.spinner.show(false);
                    this.messageService.alert(
                        this.translate.instant('view-booking.already-booked-error'),
                        5000
                    );
                } else if (errorResponse.error.internal_code === 424) {
                    this.spinner.show(false);
                    this.messageService.alert(
                        this.translate.instant('view-booking.booking-full-error'),
                        5000
                    );
                } else if (errorResponse.error.internal_code === 1022) {
                    this.spinner.show(false);
                    this.messageService.alert(
                        this.translate.instant('view-booking.already-in-use-error'),
                        5000
                    );
                } else {
                    this.spinner.show(false);
                    this.messageService.alert(
                        this.translate.instant(
                            'Common.GlobalMessages.InternalServerErrorMessage'
                        ),
                        5000
                    );
                }
            }
        );
    }

    goToPrevious() {
        this.steps = this.steps - 1;
        if (this.steps == 0) {
            this.findPatient(false);
        }
    }
}
