import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { LocaleService } from "../../../app.locale.service";
import { SpinnerService } from "../../../core";
import { Product } from "../../../features/styles/models/product.model";
import { MessageService } from "../../../main";
import { AddUserComponent } from "../add-user/add-user.component";
import { CommonDialogComponent } from "../common-dialog/common-dialog.component";
import { UserService } from "./user.service";
import { CommonApiService } from "../../services/common-api.service";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  public isFilterable = false;
  public isSearchable = false;
  public errorStatusCode: string;
  totalCount: any;
  public dataSource: MatTableDataSource<any>;
  public hoverindex = "";
  // public steps: SampleProcess[] = sampleData;
  sampleData: { [key: string]: string } = {
    All: "company.all",
    Active: "admin.active",
    Suspended: "admin.suspended",
  };
  searchinput = "";
  status = "All";
  loading = true;
  editUserFlag = false;
  public columnList = [
    "ID",
    "Name",
    "Email",
    "Status",
    "Role",
    "Companyaffectations",
    "SSaffectations",
    "icon-column",
  ];
  filterObject = {
    search: "",
    status: "",
  };
  samplingStations = {
    id: 0,
    name: "",
  };
  savealert = {
    edit: "admin.save-alert.edit",
    add: "admin.save-alert.add",
  };
  selectedIndex: number;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private readonly dialog: MatDialog,
    private readonly messageService: MessageService,
    private readonly spinner: SpinnerService,
    public userService: UserService,
    private translate: TranslateService,
    private localeService: LocaleService,
    public adminService: CommonApiService
  ) {
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
    this.dataSource = new MatTableDataSource<any>();
  }

  ngOnInit(): void {
    this.loadUser();
    // this.userService.getAdminUsers().subscribe(
    //   (response: any[]) => { response.forEach(y =>{ y
    //     //console.log(y.sampling_stations);
    //    // this.samplingStation=y.sampling_stations;
    //     // response[y].samplingStation=y.sampling_stations;
    //     // console.log(response[y].samplingStation);
    //     //console.log("My response")
    //   });

    //     // this.samplingStations.id=response[0].sampling_stations.id;
    //     // this.samplingStations.name=response[0].sampling_stations.name;
    //     this.dataSource.data=response;
    //     this.spinner.show(false) ;
    //     // console.log(response);
    //     // console.log(this.samplingStations.name);
    //   },

    //   error => {console.log(error)}

    // );

    this.dataSource.sortingDataAccessor = (
      data: any,
      sortHeaderId: string
    ): string => {
      if (typeof data[sortHeaderId] === "string") {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };
  }

  expandRow(index: number) {
    this.selectedIndex = index !== this.selectedIndex ? index : null;
  }

  public loadUser() {
    // this.spinner.show(true);
    this.userService.getAdminUsers().subscribe((userList: any[]) => {
      userList.sort(
        (a: any, b: any) => b.sampling_station_name - a.sampling_station_name
      );

      this.spinner.show(true);
      this.dataSource.data = userList;
      this.spinner.show(false);

      this.totalCount = this.dataSource.data.length;
      this.dataSource.filterPredicate = (data, filter) => {
        const filterObj = JSON.parse(filter);
        if (filterObj.search !== "" && filterObj.status == "") {
          return (
            String(data.id)
              .toLowerCase()
              .includes(filterObj.search.toLowerCase()) ||
            String(data.first_name)
              .toLowerCase()
              .includes(filterObj.search.toLowerCase()) ||
            String(data.last_name)
              .toLowerCase()
              .includes(filterObj.search.toLowerCase())
          );
        } else if (filterObj.search == "" && filterObj.status !== "") {
          return (
            String(data.is_active) === filterObj.status ||
            filterObj.status == "All"
          );
        } else {
          const result =
            (String(data.id)
              .toLowerCase()
              .includes(filterObj.search.toLowerCase()) ||
              String(data.first_name)
                .toLowerCase()
                .includes(filterObj.search.toLowerCase()) ||
              String(data.last_name)
                .toLowerCase()
                .includes(filterObj.search.toLowerCase())) &&
            (String(data.is_active) === filterObj.status ||
              filterObj.status == "All");
          return result;
        }
      };
    });

    // this.spinner.show(false);
  }
  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  /** Method to identify hovered grid row */
  public rowMouseOver(row: any): void {
    this.hoverindex = row.ID;
  }
  public addUser(): void {
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: "900px",
      height: "90vh",
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
      data: { action: "add", edit: false, savealert: this.savealert },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadUser();
    });
  }
  public editUser(entity: any): void {
    this.editUserFlag = true;
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: "900px",
      height: "90vh",
      disableClose: true,
      restoreFocus: false,
      data: {
        action: "edit",
        edit: true,
        user: entity,
        savealert: this.savealert,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadUser();
    });
  }
  public edit($event: MouseEvent, product: Product): void {
    const message = `${product.Pid}. '${product.Title
      }'  '${this.translate.instant(this.savealert.edit)}`;
    this.messageService.primary(this.translate.instant(message));
    $event.stopPropagation();
  }
  public reset(): void {
    this.dataSource.filter = "";
    this.searchinput = "";
    this.status = "All";
    this.filterObject.search = "";
    this.filterObject.status = "";
  }
  public statusFilter() {
    if (this.status != "All") {
      this.filterObject.status = this.status == "Active" ? "true" : "false";
      this.dataSource.filter = JSON.stringify(this.filterObject);
    } else {
      this.filterObject.status = "All";
      this.dataSource.filter = JSON.stringify(this.filterObject);
    }
  }

  public displaySpinner(): void {
    this.spinner.show(true);
    // setTimeout(() => {
    //     this.spinner.show(false);
    // }, 5000);
  }

  onSearch() {
    this.filterObject.search = this.searchinput;
    this.dataSource.filter = JSON.stringify(this.filterObject);
  }

  /**
   * This method will execute the when user will try to use space key keyboard and in the search box if the space is first character then
   * it will wont allow space to execute, It will block the space untill user enter a character.
   * @param event
   */
  keyDownHandler(event: Event) {
    if (this.searchinput.length === 0) {
      event.preventDefault();
    }
  }
  /**
   * This method will show the data in list dialog.
   * @param entity Selected Row Data
   */
  showAffecationsData(entity: any) {
    if (entity.SampDetails[0].id !== 0) {
      const dialogRef = this.dialog.open(CommonDialogComponent, {
        width: "450px",
        height: "350px",
        disableClose: true,
        data: { title: "SS Affecations", user: entity },
      });
      dialogRef.afterClosed().subscribe(() => {
        // this.loadSamplingStations();
      });
    }
  }

  showCompData(entity: any) {
    if (entity.OrgDetails[0].id !== 0) {
      const dialogRef = this.dialog.open(CommonDialogComponent, {
        width: "450px",
        height: "350px",
        disableClose: true,
        data: { title: "Companie(s) List", user: entity, company: true },
      });
      dialogRef.afterClosed().subscribe(() => { });
    }
  }

  public deleteUser(id: any): void {
    this.messageService
      .confirm(
        this.translate.instant("user.delete-sure"),
        this.translate.instant("user.delete-confirm")
      )
      .subscribe((actionResult: boolean) => {
        if (actionResult) {
          this.adminService.deleteUser(id).subscribe(
            () => {
              this.messageService.success(
                this.translate.instant('admin.save-alert.delete')
              );
              this.loadUser();
            },
            () => {
              this.messageService.alert(
                this.translate.instant("user.error.422")
              );
            }
          );
        }
      });
  }

  /* Method to view data in the given order */
  unsorted() { }
}
