import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataUrl, DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { LocaleService } from '../../../app.locale.service';
import { DrawerComponent, SpinnerService } from '../../../core';
import { FileData } from '../../../features/styles/models/file-data';
import { LoaderService, MessageService } from '../../../main';
import { PatientAppointmentService } from '../../services/patient-appointment.service';

@Component({
    selector: 'app-upload-prescription',
    templateUrl: './upload-prescription.component.html',
    styleUrls: ['./upload-prescription.component.scss']
})
export class UploadPrescriptionComponent implements OnInit, DrawerComponent {
    @Input() sliderFormData: any;
    @Input() public isSliderDataEdited = false;
    @Input() public appointmentId = 0;
    @Output() skipMe = new EventEmitter<any>();

    public isUpdate: boolean;
    public file: FileData = null;
    public isInvalidFileSize: boolean;
    public isHovering: boolean;
    public isEmailNotificationRequired: boolean;
    public isIncorrectFileFormat: boolean;
    public isSaveClicked: boolean;
    public prescriptionData: string | ArrayBuffer;
    public imageCapture = false;
    public imageCaptured = false;
    public errors: WebcamInitError[] = [];
    public trigger: Subject<void> = new Subject<void>();

    public FileName = new FormControl('', [Validators.required]);
    public form = this.fb.group({
        FileName: this.FileName
    });
    constructor(
        private fb: FormBuilder,
        private loaderService: LoaderService,
        private translate: TranslateService,
        private localeService: LocaleService,
        private patientAppointmentService: PatientAppointmentService,
        private messageService: MessageService,
        private spinner: SpinnerService,
        private imageCompress: NgxImageCompressService
    ) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    ngOnInit(): void {
    }

    public removeUploadedFile(event: any): void {
        this.file = null;
        this.form.controls.FileName.setValue('');
        event.stopPropagation();
    }

    public triggerSnapshot(): void {
        this.trigger.next();
        this.imageCaptured = true;
    }

    public handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
    }

    public retakeSnapshot() {
        this.imageCaptured = false;
    }

    captureImage() {
        this.imageCapture = true;
    }

    public handleImage(webcamImage: WebcamImage): void {
        this.prescriptionData = webcamImage.imageAsDataUrl;
        this.isIncorrectFileFormat = false;
        this.isInvalidFileSize = false;
        this.form.controls.FileName.setValue('captured_image');
        fetch(webcamImage.imageAsDataUrl)
            .then(async response => {
                const blob = await response.blob();
                this.file = await new FileData(new File([blob], 'captured_image.jpg', {}));
            });

    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    isValidPrescription(file: File): Boolean {
        let isFileValid = true;
        this.isIncorrectFileFormat = false;
        this.isInvalidFileSize = false;
        const prescriptionType = file.type;
        if (file.size <= 0 || file.size > 3 * 1024 * 1024) {
            this.form.controls.FileName.setValue('');
            this.isInvalidFileSize = true;
            isFileValid = false;
        }
        if (prescriptionType == 'application/pdf' && file.size > 250 * 1024) {
            this.form.controls.FileName.setValue('');
            this.isInvalidFileSize = true;
            isFileValid = false;
        }
        if (
            prescriptionType != 'application/pdf' &&
            prescriptionType != 'image/png' &&
            prescriptionType != 'image/jpeg'
        ) {
            this.isIncorrectFileFormat = true;
            isFileValid = false;
        }

        if (
            !file.name.toLowerCase().includes('.png') &&
            !file.name.toLowerCase().includes('.pdf') &&
            !file.name.toLowerCase().includes('.jpg') &&
            !file.name.toLowerCase().includes('.jpeg')
        ) {
            this.isIncorrectFileFormat = true;
            isFileValid = false;
        }

        return isFileValid;
    }

    public handleFilteredFileInput(filelist: FileList) {
        const reader = new FileReader();
        const file = filelist[0];
        this.form.controls.FileName.setValue('');
        this.form.controls.FileName.markAllAsTouched();
        if (file) {
            if (!this.isValidPrescription(file)) {
                this.form.controls.FileName.setValue('');
                this.prescriptionData = '';
            } else {
                setTimeout(() => {
                    const fdata = new FileData(file);
                    this.file = fdata;
                    this.form.controls.FileName.setValue(file.name);
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.compress(reader.result.toString(), file);
                        // this.prescriptionData = reader.result.toString();
                    };
                    this.form.controls.FileName.markAsDirty();
                }, 100);
            }
        }
    }

    public calculateQueality(size: any) {
        if (size < 1) {
            return 75;
        } else if (size >= 1 && size < 2) {
            return 50;
        } else {
            return 22;
        }
    }

    public compress(data: string, file: File) {

        const fileSizeinMB = (file.size / 1024) / 1024;
        const quality = this.calculateQueality(fileSizeinMB);
        if (file.size > 200 * 1024 && file.type != 'application/pdf') {
            this.imageCompress.compressFile(data, DOC_ORIENTATION.Default, quality, quality).then(
                (result: DataUrl) => {
                    fetch(result).then(s => s.blob()).then(b => {
                        if ((b.size / 1024) > 200) {
                            this.compress(result, new File([b], file.name));
                        } else {
                            this.prescriptionData = result;
                        }
                    });
                },
                () => {
                    console.error('Compression failed, Retry and check later.');
                });
        } else {
            this.prescriptionData = data;
        }
    }

    public dropzoneState(event: any) {
        this.isHovering = event;
    }

    skip() {
        this.file = null;
        this.form.controls.FileName.setValue('');
        this.skipMe.emit(true);
    }

    prescriptionObject() {
        return {
            AppointmentId: this.sliderFormData.row_data.AppointmentId,
            AzureId: this.sliderFormData.row_data.PrescriptionUrl,
            PrescriptionData: this.prescriptionData
        };
    }

    uploadprescription() {
        if (this.prescriptionData != '' && this.prescriptionData != undefined) {
            const prescription = this.prescriptionObject();
            this.spinner.show(true);
            this.patientAppointmentService.updatePrescription(prescription).subscribe(
                (data: any) => {
                    console.log(data);
                    this.messageService.success(
                        this.translate.instant('view-booking.booking-prescription-saved'),
                        5000
                    );
                    this.spinner.show(false);
                    this.loaderService.refreshState(true);
                    this.dismiss();
                },
                (errorResponse: HttpErrorResponse) => {
                    this.spinner.show(false);
                    this.messageService.alert(this.translate.instant(errorResponse.error.message), 5000);
                }
            );
        }
    }

    dismiss() {
        this.loaderService.cancelForm();
    }
}
