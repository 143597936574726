import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
const CONFIGURATION_FILE = './config/config.json';
@Injectable()
/** Service to get Configuartion Details */
export class ConfigService {
    /* #region Fields */
    private config: any;
    /* #endregion */

    constructor(private readonly http: HttpClient) {
    }

    /* #region Public Methods */
    /** Method to get data from config based on the key */
    public getConfig<T>(key: string): T {
        return this.config[key];
    }

    /** Method to load configuration -  used on app initializer */
    public getSettings(): Promise<Object> {
        return this.http.get(CONFIGURATION_FILE).toPromise().then((settings: any) => {
            this.config = settings;
            return settings;
        });
    }
    /* #endregion */
}
