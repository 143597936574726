import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
import { SpinnerService } from '../../../core';
import { LoaderService, MessageService } from '../../../main';
import { TestService } from '../../services/add-test-service.service';
import { CommonApiService } from '../../services/common-api.service';
import { AddTestComponent } from '../add-test/add-test.component';
// import { Company } from "../company-dialog/Company";

@Component({
    selector: 'app-test-type',
    templateUrl: './test-type.component.html',
    styleUrls: ['./test-type.component.scss']
})
export class TestTypeComponent implements OnInit {
    public dataSource: MatTableDataSource<any>;
    public hoverindex = '';
    search: String = '';
    searchinput = '';
    adminRole: boolean;
    filter: string;
    editComapnyFlag = false;
    public columnList = [
        'Id',
        'ShortName',
        'LongName',
        'Type',
        'ExtendedCode',
        'Description',
        'Status',
        'Prescription',
        'Appointment',
        'TestKit',
        'TestResult',
        'icon-column'
    ];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    status = 'All';
    sampleData: { [key: string]: string } = {
        All: 'company.all',
        Active: 'admin.active',
        Suspended: 'admin.suspended'
    };

    filterObject = {
        search: '',
        status: ''
    };

    constructor(
        private readonly dialog: MatDialog,
        private readonly messageService: MessageService,
        public testService: TestService,
        public commonService: CommonApiService,
        private readonly spinner: SpinnerService,
        private translate: TranslateService,
        private localeService: LocaleService,
        public loader: LoaderService
    ) {
        this.dataSource = new MatTableDataSource<any>();
        // this.dataSource = new MatTableDataSource<Product>();
        translate.setDefaultLang(this.localeService.localeLang);
        translate.use(this.localeService.localeLang);
    }
    deleteTitle = 'admin.confirm-delete';
    deleteconfirmtext = 'admin.delete-confirm-text';
    savealert = {
        edit: 'admin.save-alert.edit',
        add: 'admin.save-alert.add',
        delete: 'admin.save-alert.delete'
    };
    errorMessage = 'admin.error-message';
    testtypelist: any;

    ngOnInit(): void {
        // this.totalCount = this.dataSource.data.length;
        this.loadTest();
        this.commonService.GetAlActivelTestType().subscribe((testypes) => {
            this.testtypelist = testypes;
        });
        this.adminRole = JSON.parse(sessionStorage.getItem('access_token'))['role'] == 5;
        this.dataSource.sortingDataAccessor = (
            data: any,
            sortHeaderId: string
        ): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            }

            return data[sortHeaderId];
        };
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    /** Method to identify hovered grid row */
    public rowMouseOver(row: any): void {
        this.hoverindex = row.id;
    }

    public add(): void {
        const dialogRef = this.dialog.open(AddTestComponent, {
            width: '900px',
            height: "90vh",
            data: {
                action: 'add',
                savealert: this.savealert,
                errMsg: this.errorMessage,
                testtype: this.testtypelist,

            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            console.log(result);
            this.loadTest();
        });
    }

    public edit($event: MouseEvent, item: any): void {
        // const message = `${product.Pid}. '${product.Title}'  Edited Susccessfully`;
        const dialogRef = this.dialog.open(AddTestComponent, {
            width: '900px',
            height: '90vh',
            data: {
                action: 'edit',
                obj: item,
                savealert: this.savealert,
                errMsg: this.errorMessage,
                testtype: this.testtypelist
            }
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result);
            this.loadTest();
        });
        // this.messageService.primary(message);
        $event.stopPropagation();
    }

    public confirmDeleteWithYesNo(company: any): void {
        this.messageService
            .confirm(
                this.translate.instant(this.deleteconfirmtext),
                this.translate.instant(this.deleteTitle)
            )
            .subscribe((actionResult: boolean) => {
                if (actionResult) {
                    this.deleteTest(company);
                }
            });
    }

    deleteTest(test: any) {
        this.spinner.show(true);
        test.is_active = false;

        this.testService.postAdminTestTypes(test).subscribe(
            () => {
                this.spinner.show(false);

                this.messageService.success(
                    this.translate.instant(this.savealert['delete'])
                );
                this.loadTest();
            },
            (errorResponse: HttpErrorResponse) => {
                this.spinner.show(false);
                this.messageService.alert(
                    this.translate.instant(this.errorMessage),
                    10000
                );
                console.log(errorResponse);
            }
        );
    }

    public loadTest() {
        this.testService.getAllAdminTestTypes().subscribe((testList) => {
            testList.sort((a: any, b: any) => b.id - a.id);

            this.dataSource.data = testList;
            this.dataSource.filterPredicate = (data, filter) => {
                const filterObj = JSON.parse(filter);
                if (filterObj.search !== '' && filterObj.status == '') {
                    return (
                        String(data.id).toLowerCase() +
                        String(data.test_type).toLowerCase() +
                        String(data.short_name).toLowerCase() +
                        String(data.long_name).toLowerCase() +
                        String(data.external_code).toLowerCase()
                    ).includes(filterObj.search.toLowerCase());
                } else if (filterObj.search == '' && filterObj.status !== '') {
                    return (
                        String(data.is_active) === filterObj.status ||
                        filterObj.status == 'All'
                    );
                } else {
                    return (
                        (String(data.id)
                            .toLowerCase()
                            .includes(filterObj.search.toLowerCase()) ||
                            String(data.test_type)
                                .toLowerCase()
                                .includes(filterObj.search.toLowerCase()) ||
                            String(data.short_name)
                                .toLowerCase()
                                .includes(filterObj.search.toLowerCase()) ||
                            String(data.long_name)
                                .toLowerCase()
                                .includes(filterObj.search.toLowerCase())) &&
                        (String(data.is_active) === filterObj.status ||
                            filterObj.status == 'All')
                    );
                }
            };
        });
    }

    /* Method for search button */
    onSearch() {
        this.filterObject.search = this.searchinput;
        this.dataSource.filter = JSON.stringify(this.filterObject);
    }

    /* Method for Reset Button */
    public reset(): void {
        this.dataSource.filter = '';
        this.searchinput = '';
        this.status = '';
        this.filterObject.search = '';
        this.filterObject.status = '';
    }

    /* Method for Status Dropdown */
    public statusFilter() {
        if (this.status != 'All') {
            this.filterObject.status = this.status == 'Active' ? 'true' : 'false';
            this.dataSource.filter = JSON.stringify(this.filterObject);
        } else {
            this.filterObject.status = 'All';
            this.dataSource.filter = JSON.stringify(this.filterObject);
        }
    }

    /**
   * This method will execute the when user will try to use space key keyboard and in the search box if the space is first character then
   * it will wont allow space to execute, It will block the space untill user enter a character.
   * @param event
   */
    keyDownHandler(event: Event) {
        if (this.searchinput.length === 0) {
            event.preventDefault();
        }
    }

    /* Method to view data in the given order */
    unsorted() { }

    getPrescriptionOrAppointmentStatus(entity: any) {
        return entity.prescription
            ? this.translate.instant('test-type.required-prescription')
            : '';
    }

    getPrescription(entity: any) {
        return entity.prescription
            ? this.translate.instant('test-type.required-prescription')
            : '';
    }
    getStatus(val: any) {
        return val
            ? this.translate.instant('test-type.required-prescription')
            : '';
    }

    getAppointmentStatus(entity: any) {
        return entity.appointment
            ? this.translate.instant('test-type.required-prescription')
            : '';
    }
}
