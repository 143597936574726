<!-- <div>
    <label class="d-block">check prescription</label>
    <mat-radio-group [formControl]="haveFileReady" required>
        <mat-radio-button value="0">{{'createAccount.yes'|translate}}
        </mat-radio-button>
        <mat-radio-button value="1">{{'createAccount.no'|translate}}
        </mat-radio-button>
    </mat-radio-group>
</div> -->
<div class="check-file-container" [ngClass]="loaderService.loggedIn ? 'check-file-container-loggedin' : 'check-file-container-guest'">
    <div class="section-header" [ngClass]="loaderService.loggedIn ? 'section-header-loggedin' : 'section-header-guest'">
        <circle-progress class="only-web" [options]="loaderService.optionsG" [percent]="percent" [renderOnClick]="false"
            imageSrc="assets/images/application-logos/svg/ic-sappintment.svg" class="copy">
        </circle-progress>
        <h3 class="desc-title">{{'file-ready.title' | translate}}​</h3>
    </div>
    <div class="doc-info-section" [ngClass]="loaderService.loggedIn ? 'doc-info-loggedin' : 'doc-info-guest'">
        <div class="options" [ngClass]="haveFileReady.value == '0' ? 'option-yes' : 'option-no'" (click)="select(0)">
            <div class="option-description">
                <img src="assets/images/application-logos/svg/ic-wappointment.svg" alt="w-app">
                <div class="option-title">{{'file-ready.with-app-1' | translate}} <br>{{'file-ready.with-app-2' |
                    translate}}​</div>
            </div>
            <div class="select-button" [ngClass]="haveFileReady.value == '0' ? 'selected' : 'select'">
                {{(haveFileReady.value == '0' ? 'file-ready.selected' : 'file-ready.select') | translate}}</div>
        </div>
        <div class="options" [ngClass]="haveFileReady.value == '1' ? 'option-yes' : 'option-no'" (click)="select(1)">
            <div class="option-description">
                <img src="assets/images/application-logos/svg/ic-woappointment.svg" alt="wo-app">
                <div class="option-title">{{'file-ready.without-app-1' | translate}} <br>{{'file-ready.without-app-2' |
                    translate}}​</div>
            </div>
            <div class="select-button" [ngClass]="haveFileReady.value == '1' == true  ? 'selected' : 'select'">
                {{(haveFileReady.value == '1' ? 'file-ready.selected' : 'file-ready.select') | translate}}</div>
        </div>
    </div>
    <div class="action-btn" [ngClass]="loaderService.loggedIn ? 'action-btn-login' : 'action-btn-guest'">
        <button mat-raised-button class="back-btn" type="button" (click)="goToBack()">
            {{'file-ready.back' | translate}}
        </button>
        <button mat-raised-button class="next-btn" type="button" (click)="goToNext()">
            {{'file-ready.next' | translate}}
        </button>
    </div>
</div>