import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    Inject,
    Input,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
    ValidatorFn
} from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatFormField } from '@angular/material/form-field';
// import { Test } from './Test';
// import { CompanyService } from '../service/company.service';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
import { DrawerComponent, SpinnerService } from '../../../core';
import { LoaderService, MessageService } from '../../../main';
import { TestService } from '../../services/add-test-service.service';
import { LoginServiceService } from '../../services/login-service.service';

@Component({
    selector: 'app-add-test',
    templateUrl: './add-test.component.html',
    styleUrls: ['./add-test.component.scss']
})
export class AddTestComponent implements OnInit, DrawerComponent {
    @Input() sliderFormData: any;
    @Input() public isSliderDataEdited = false;
    Tests: any;
    testForm: FormGroup;

    title: string;
    isInvalidFileSize: boolean;
    isIncorrectFileFormat: boolean;
    fileName: string;
    imageUrl: string | ArrayBuffer = '';
    saveData: {};
    adminRole: boolean;
    testTypes: any[] = null;
    deleteTitle = 'admin.confirm-delete';
    deleteconfirmtext = 'admin.delete-confirm-text';
    savealert = {
        edit: 'admin.save-alert.edit',
        add: 'admin.save-alert.add',
        delete: 'admin.save-alert.delete'
    };
    errorMessage = 'admin.error-message';
    genders: any[];
    savedValue: number;

    constructor(
        private readonly dialogRef: MatDialogRef<AddTestComponent>,

        @Inject(MAT_DIALOG_DATA) public data: any,

        public testService: TestService,
        private formBuilder: FormBuilder,
        private readonly spinner: SpinnerService,
        private readonly messageService: MessageService,
        private translate: TranslateService,
        private localeService: LocaleService,
        public loginServiceService: LoginServiceService,
        public loader: LoaderService
    ) {
        this.title =
            this.data.action == 'edit' ? 'add-test.edit-test' : 'add-test.add-test';
        this.savealert = this.data.savealert;
        this.errorMessage = this.data.errMsg;
        this.testTypes = this.data.testtype;
        console.log(this.data.testtype)
        this.isIncorrectFileFormat = false;
        this.isInvalidFileSize = false;
        this.fileName = '';
        this.initializetesttForm();
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }
    initializetesttForm() {
        this.testForm = this.formBuilder.group({
            Id: new FormControl(0),
            ShortName: new FormControl(null, [
                Validators.required,
                Validators.pattern('^.{1,50}$')
            ]),
            LongName: new FormControl(null, [
                Validators.required,
                Validators.pattern('^.{1,150}$')
            ]),
            Description: new FormControl(null, [Validators.maxLength(1000)]),
            Status: new FormControl(true, [Validators.required]),

            TestType: new FormControl('', [Validators.required]),

            ExternalId: new FormControl(null, [
                Validators.required,
                Validators.pattern('^.{1,50}$'),
                Validators.pattern('^[a-zàâçéèêëîïôûùüÿñæœA-ZÀÂÇÉÈÊËÎÏÔÛÙÜŸÑÆŒ0-9!@#$%^&*()+-\\s_,]+$')
            ]),
            Appointment: new FormControl(false),
            Prescription: new FormControl(false),
            TestKit: new FormControl(false),
            TestResult: new FormControl(false),
            Gender: new FormControl(true),
            FileName: new FormControl('', [
                Validators.pattern('^.*.(jpg|JPG|jpeg|JPEG|png|PNG|svg|SVG)$')
            ]),
            // ...more form controls...
            myCheckboxGroup: new FormGroup({
                Male: new FormControl(false),
                Female: new FormControl(false),
            }
                , this.requireCheckboxesToBeCheckedValidator())
        });
    }

    ngOnInit(): void {
        this.testTypes = this.data.testtype;
        this.adminRole = JSON.parse(sessionStorage.getItem('access_token'))['role'] == 5;
        this.getGenders();
        if (this.data.obj != null && this.testTypes != null) {
            this.bindtestObject(this.data.obj);
            this.loadSavedValue();
        }
        this.initializeCheckboxes(this.savedValue);
    }

    @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

    ngAfterViewInit(): void {
        setTimeout(
            () => this.formFields.forEach((ff) => ff.updateOutlineGap()),
            100
        );
    }

    renderTest() {
        // this.companyService.getTestTypes();
    }
    public TranslateLang(entity: any) {
        return JSON.parse(entity)[this.localeService.localeLang];
    }

    back() {
        this.dialogRef.close(null);
    }
    updateTestObject() {
        return {
            id: this.testForm.controls.Id.value,
            short_name: this.testForm.controls.ShortName.value,
            long_name: this.testForm.controls.LongName.value,
            description: this.testForm.controls.Description.value,
            is_active: this.testForm.controls.Status.value,
            test_type: this.testForm.controls.TestType.value,
            external_code: this.testForm.controls.ExternalId.value,
            appointment: this.testForm.controls.Appointment.value,
            prescription: this.testForm.controls.Prescription.value,
            is_test_kit_required: this.testForm.controls.TestKit.value,
            is_test_result_required: this.testForm.controls.TestResult.value,
            applicable_gender: this.testForm.controls.Gender.value,
            file_name: this.fileName,
            url: this.imageUrl
        };
    }
    bindtestObject(testdetail: any) {
        this.fileName = testdetail.file_name;
        console.log(testdetail);
        this.testForm.patchValue({
            Id: testdetail?.id,
            ShortName: testdetail?.short_name,
            LongName: testdetail?.long_name,
            Description: testdetail?.description,
            Status: testdetail?.is_active,
            TestType: testdetail?.test_type_id,
            ExternalId: testdetail?.external_code,
            Appointment: testdetail?.appointment,
            Prescription: testdetail?.prescription,
            TestKit: testdetail?.is_test_kit_required,
            TestResult: testdetail?.is_test_result_required,
            Gender: testdetail?.applicable_gender,
            FileName: testdetail?.file_name
        });
        console.log("hello");
        console.log(this.data.obj.gender);
    }
    loadSavedValue() {
        this.savedValue = this.data.obj.gender;
    }
    initializeCheckboxes(value: number) {
        const checkboxGroup = this.testForm.get('myCheckboxGroup');
        if (value === 0) {
            checkboxGroup.patchValue({ Male: true, Female: true });
        } else if (value === 15) {
            checkboxGroup.patchValue({ Male: true, Female: false });
        } else if (value === 16) {
            checkboxGroup.patchValue({ Male: false, Female: true });
        } else {
            checkboxGroup.patchValue({ Male: false, Female: false });
        }
    }
    saveTest() {
        this.testForm.controls.FileName.setValue(this.fileName);
        // Determine value based on checkbox selection
        const checkboxValues = this.testForm.get('myCheckboxGroup').value;
        let GenderId: number;
        if (checkboxValues.Male && checkboxValues.Female) {
            GenderId = 0; // Both selected
        } else if (checkboxValues.Male) {
            GenderId = 15; // Male selected
        } else if (checkboxValues.Female) {
            GenderId = 16; // Female selected
        } else {
            GenderId = 0; // No selection, handle as needed
        }
        this.testForm.controls.Gender.setValue(GenderId);

        if (this.testForm.valid && this.fileName !== '') {
            // this.loader.show();
            const test = this.updateTestObject();
            this.spinner.show(true);
            this.testService.postAdminTestTypes(test).subscribe(
                () => {
                    this.spinner.show(false);
                    this.messageService.success(
                        this.translate.instant(this.savealert[this.data.action])
                    );
                    this.dialogRef.close(null);
                },
                (errorResponse: HttpErrorResponse) => {
                    this.spinner.show(false);
                    if (errorResponse.error.code == 423) {
                        this.testForm.controls.ExternalId.setErrors([{ existingId: true }]);
                    } else {
                        this.messageService.alert(
                            this.translate.instant(this.errorMessage)
                        );
                        console.log(errorResponse);
                        this.dialogRef.close(null);
                    }
                }
            );
        } else if (this.fileName === '') {
        }
    }

    clear() {
        this.testForm.controls.ShortName.setValue(null);
        this.testForm.controls.LongName.setValue(null);
        this.testForm.controls.TestType.setValue(null);
        this.testForm.controls.ExternalId.setValue(null);
        this.testForm.controls.Description.setValue(null);
        this.testForm.controls.Status.setValue(true);
        this.testForm.controls.Appointment.setValue(null);
        this.testForm.controls.Prescription.setValue(null);
        this.testForm.controls.TestKit.setValue(null);
        this.testForm.controls.TestResult.setValue(null);
        this.testForm.controls.Gender.setValue(null);
        this.fileName = '';
        this.testForm.controls.FileName.setValue(null);
        this.testForm.markAsUntouched();
        this.testForm.controls.refreshState;
    }

    public dismiss() {
        this.dialogRef.close();
    }

    selectCountry() { }

    requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
        return function validate(formGroup: FormGroup) {
            let checked = 0;

            Object.keys(formGroup.controls).forEach(key => {
                const control = formGroup.controls[key];

                if (control.value === true) {
                    checked++;
                }
            });

            if (checked < minRequired) {
                return {
                    requireOneCheckboxToBeChecked: true,
                };
            }

            return null;
        };
    }

    public getGenders() {
        this.loginServiceService
            .getGenderMasters()
            .subscribe((data: Array<any>) => {
                const filteredGenders = data.filter(gender => gender.KeyName !== 'Other');
                this.genders = filteredGenders.sort((a, b) => a.KeyOrder - b.KeyOrder);
                if (this.data.action == "edit") {
                    this.testForm.controls.Gender.setValue(this.data.obj.gender);
                } else {
                    this.testForm.controls.Gender.patchValue(this.genders[0].Id);
                }
            });
    }

    isValidImage(file: any): boolean {
        let isImageValid = true;
        if (file.size == 0 || file.size > 500 * 1024) {
            this.isInvalidFileSize = true;
            isImageValid = false;
        }
        if (
            file.type != 'image/png' &&
            file.type != 'image/jpg' &&
            file.type != 'image/jpeg' &&
            file.type != 'image/svg+xml'
        ) {
            this.isIncorrectFileFormat = true;
            isImageValid = false;
        }

        if (
            !file.name.toLowerCase().includes('.png') &&
            !file.name.toLowerCase().includes('.jpg') &&
            !file.name.toLowerCase().includes('.jpeg') &&
            !file.name.toLowerCase().includes('.svg')
        ) {
            this.isIncorrectFileFormat = true;
            isImageValid = false;
        }

        return isImageValid;
    }
    inputChange(event: any) {
        this.isInvalidFileSize = false;
        this.isIncorrectFileFormat = false;

        const reader = new FileReader();
        const file = event.target.files[0];
        if (file) {
            this.fileName = file.name;
            if (!this.isValidImage(file)) {
                this.testForm.controls.FileName.setValue(null);
                this.imageUrl = '';
            } else {
                this.isIncorrectFileFormat = false;
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const s = reader.result.toString().split(',');
                    this.imageUrl = s[1];
                };
            }
        }
    }
}
