export class SearchPatientRequest {
    public MixedData = '';
    public Dob = '';
    public QrId = '';
}
export class BookedAppointment {
    public patient_id: string;
    public id: number;
    public patient_name: string;
    public test_name: string;
    public slot_time: string;
    public test_id = '';
}

export class PatientDetail {
    public id: number;
    public pid: string;
    public qr_id: string;
    public org_id: number;
    public first_name: string;
    public last_name: string;
    public birth_name: string;
    public dob: Date;
    public gender: number;
    public nationality: number;
    public ssn: string;
    public passport: string;
    public phone_number: string;
    public email: string;
    public login_id: number;
    public address: string;
    public city: string;
    public state: string;
    public country_id: number;
    public zip: string;
    public is_active: boolean;
    public external_unique_id: string;
    public create_user: number;
    public modify_user: number;
}

// calendar model

export class Dow {
    public id: number;
    public calendar_id: number;
    public dow: number;
    public day: string;
    public is_active: boolean;
    public start_time: string;
    public end_time: string;
    public break_begins: string;
    public break_ends: string;
}

export class Test {
    public id: number;
    public calendar_id: number;
    public test_id: number;
    public active: boolean;
}

export class CalendarModel {
    constructor() {
        this.dows = new Array<Dow>();
        this.tests = new Array<Test>();
    }
    public id: number;
    public calendar_name: string;
    public duration: number;
    public capacity: number;
    public status: string;
    public dows: Dow[];
    public tests: Test[];
}


