<div class="otp-container">

    <button class="slider_closebtn" mat-button color="accent" type="button" (click)="dialogRef.close(false)">
        <span class="icon-ic-close"></span>
    </button>

    <div class="lock-svg" > <img src="assets/images/application-logos/svg/ic-otp-lock.svg"
            alt="pres-no">
    </div>
</div>
<div id="otp_divContainer">
    <div id="otpinner_container" *ngIf="otpScreen">
        <h3 class="popup-heading">{{'verify-my-account.title-otp' | translate}}</h3>
        <mat-hint class="subtext-hint" *ngIf="!phoneSelectedMessage">{{
            'verify-my-account.otp-hint-initial-email' | translate}}

        </mat-hint>
        <mat-hint class="subtext-hint" *ngIf="phoneSelectedMessage">{{!smsMode ?('OTP-msg.otp-whatsapp' |
            translate):('OTP-msg.otp-sms' | translate)}}
        </mat-hint>
        <div>
            <mat-hint class="email-phone-bold">{{ !phoneSelectedMessage ?data.email:data?.phone_number}}</mat-hint>
        </div>
        <div>
            <ng-otp-input clas="otp" #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
            <div class="otp-counter">
                <span class="stopwatch-timer">{{'login.otp-time-remaining' | translate}} : {{display}}</span>
            </div>
            <div class="otp-buttons">
                <button mat-raised-button  color="primary" type="submit" class="btn-login verify-btn"
                    (click)="verifyOTP()">
                    {{'login.otp-verify' | translate}} </button>

            </div>
        </div>
    </div>
    <div id="otp_container" *ngIf="!otpScreen">
        <div id="otpinner_container">
            <h3 class="otp-header headinginCenter">{{'OTP-msg.heading-option-top'| translate}}</h3>
            <h2 class="otp-container-text" *ngIf="displayMessage==1">{{'OTP-msg.disable-email-option'|
                translate}}</h2>
            <h2 class="otp-container-text">{{'OTP-msg.heading-alloption'| translate}}</h2>

            <div class="option-btn ">
                <button mat-button type="submit" class="border-mail" (click)="sendOTP('mail')"
                    *ngIf="(!phoneSelected && (otpClickCount<1)|| !disableEmail)">
                    <span class="icon-ic-mailcircle mail-btn btn-alignment"></span>{{'OTP-msg.mail-btn'| translate}}
                </button>
                <button mat-button type="submit" class="border-whatsapp" (click)="sendOTP('whatsapp')"
                    *ngIf="!disableWhatsapp">
                    <span class="icon-ic-whatsapp whatsapp-btn btn-alignment"></span>{{'OTP-msg.Whatsapp-btn'|
                    translate}}
                </button>
                <button mat-button type="submit" class="border-msg " (click)="sendOTP('sms')" *ngIf="!disableSms">
                    <span class="icon-ic-sms sms-btn btn-alignment"></span>{{'OTP-msg.sms-btn'| translate}}</button>
            </div>
        </div>
    </div>
</div>