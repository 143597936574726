<div id="patientInformation">
    <header class="flex-end">
        <h3 class="pull-left">{{'patient-information.patient-information'|translate}}</h3>
        <button mat-button color="accent" (click)="editPatientIfo()"
            class="only-web pull-right">{{'patient-information.edit'|translate}}</button>
        <button class="only-mobile mat-tbl-icon" mat-icon-button (click)="editPatientIfo()">
            <span class="icon-ic-edit"></span>
        </button>
    </header>
    <div class="infoContent" [ngClass]="(!checkUploadedDocuments())? 'background-warning' : 'background-normal'">
        <div class="headerContent">
            <div class="only-web user-info">
                <p class="headerTitle oneline">{{name}}</p>
                <div *ngIf="ssn" class="ssn-header">
                    <span class="form-label mb-10">{{'patient-information.ssn'|translate}} : </span>
                    <span class="form-value">{{ssn}}</span>
                    <span *ngIf="(!checkUploadedDocuments() || expiry)" class="icon-ic_alert only-web warning-icon"
                        matTooltip="{{'patient-info.document-warning' | translate}}"
                        matTooltipClass="tooltip-style-warning"></span>
                </div>
                <div class="ssn-header">
                    <span class="form-label mb-10">{{'patient-info.private-insurance' | translate}} :</span>
                    <span class="form-value">{{ privateInsurance | translate }}</span>
                    <span *ngIf="(!checkUploadedDocuments() || expiry) && !ssn"
                        class="icon-ic_alert only-web warning-icon"
                        matTooltip="{{'patient-info.document-warning' | translate}}"
                        matTooltipClass="tooltip-style-warning"></span>
                </div>
            </div>
            <button mat-button color="accent" (click)="resetPasswordSlider()" class="pull-right1 only-web">
                {{'patient-information.reset-password'|translate}}
            </button>
            <div class="only-mobile user-info">
                <p class="headerTitle">{{name}}</p>
                <div class="paitentcontainer">
                    <div class="ssncontainer">
                        <span *ngIf="ssn" class="form-label">{{'patient-information.ssn'|translate}} : </span>
                        <span *ngIf="ssn" class="form-value">{{ssn}}</span>
                    </div>
                    <div class="ssncontainer">
                        
                        <span class="form-label mb-10">{{'patient-info.private-insurance' | translate}} : </span>
                        <span class="form-value">{{ privateInsurance | translate }}</span>
                    </div>
                </div>
                <button mat-button color="accent" (click)="resetPasswordSlider()" class="pull-right1 pd-0 resetbtn">
                    <span>{{'patient-information.reset-password'|translate}}</span>
                </button>
            </div>

        </div>
        <div class="only-mobile">
            <br>
            <div *ngIf="(!checkUploadedDocuments() || expiry)" class="warning-mobile-container">
                <span class="icon-ic_alert warning-icon"></span>
                <p>{{'patient-info.document-warning' | translate}}</p>
            </div>
        </div>
    </div>
    <div class="only-web bookingInfoDiv">
        <h5 class="mb-20">{{'patient-information.personal-information'|translate}}</h5>
        <div class="threeColumnGrid">
            <div class="infoTabs oneline pl-30">
                <span class="icon-ic-date font-l"></span>
                <p class="form-label mb-0">{{'patient-information.date-of-birth'|translate}}&nbsp;</p>
                <div class="form-value ">{{dob | date:'dd/MM/yyyy'}}</div>
            </div>
            <div class="infoTabs oneline pl-30">
                <span class="icon-ic-date font-l"></span>
                <p class="form-label mb-0">{{'patient-information.age'|translate}}&nbsp;</p>
                <div class="form-value ">{{act_age}}</div>
            </div>
            <div class="infoTabs oneline pl-30">
                <span class="icon-ic-gender font-l"></span>
                <p class="form-label mb-0">{{'patient-info.gender' | translate}} :&nbsp;</p>
                <div class="form-value ">{{Gender | translate}}</div>
            </div>
        </div>
    </div>
    <div class="only-mobile bookingInfoDiv">
        <h5 class="mb-20">{{'patient-information.personal-information'|translate}}</h5>
        <div class="parentcontainer">
            <div class="picontainer">
                <div class="piTabs oneline pl-30">
                    <span class="icon-ic-date font-l"></span>
                    <p class="form-label mb-0">{{'patient-information.date-of-birth'|translate}}&nbsp;:</p>
                </div>
                <div class="piTabs oneline pl-30">
                    <span class="icon-ic-date font-l"></span>
                    <p class="form-label mb-0">{{'patient-information.age'|translate}}&nbsp;:</p>
                </div>
                <div class="piTabs oneline pl-30">
                    <span class="icon-ic-gender font-l"></span>
                    <p class="form-label mb-0">{{'patient-info.gender' | translate}}:</p>
                </div>
            </div>
            <div class="picontainer">   
                    <div class="form-value ">{{dob | date:'dd/MM/yyyy'}}</div>
                    <div class="form-value ">{{act_age}}</div>
                    <div class="form-value ">{{Gender | translate}}</div>
            </div>
        </div>
    </div>
    <div class="only-web bookingInfoDiv mt-20">
        <h5 class="mb-20">{{'patient-information.communication-details'|translate}}</h5>
        <div class="threeColumnGrid">
            <div class="infoTabs pl-30">
                <span class="icon-ic-mail font-l"></span>
                <p class="form-label mb-0">{{'patient-information.primary'|translate}}</p>
                <div matTooltip="{{mail}}" matTooltipPosition="above" class="form-value">
                    {{mail}}
                    <!-- | descriptionShortener:characters -->
                    <i class="icon-ic-edit edit-icon" (click)="editEmail()"></i>
                </div>
            </div>
            <div class="infoTabs pl-30">
                <span class="icon-ic-mobile font-l"></span>
                <p class="form-label mb-0">{{'patient-information.mobile'|translate}}</p>
                <div class="form-value">{{phone}}</div>
            </div>
            <div class="infoTabs pl-30">
                <span class="icon-ic-location font-l"></span>
                <p class="form-label mb-0">{{'patient-information.address'|translate}} </p>
                <div class="form-value">{{address}}</div>
            </div>
        </div>
    </div>
    <div class="only-mobile bookingInfoDiv mt-20">
        <h5 class="mb-20">{{'patient-information.communication-details'|translate}}</h5>
        <div class="threeColumnGrid">
            <div class="infoTabs pl-30 mb-15">  
                <!-- <p class="form-label mb-0">{{'patient-information.primary'|translate}}</p> -->
                <div matTooltip="{{mail}}" matTooltipPosition="above" class="form-value">
                    <span class="icon-ic-mail font-l"></span>
                    {{mail}}
                    <i class="icon-ic-edit edit-icon" (click)="editEmail()"></i>
                </div>
            </div>
            <div class="infoTabs pl-30">
                <span class="icon-ic-mobile font-l"></span>
                <!-- <p class="form-label mb-0">{{'patient-information.mobile'|translate}}</p> -->
                <p class="form-value">{{phone}}</p>
            </div>
            <div class="infoTabs pl-30">
                <span class="icon-ic-location font-l"></span>
                <!-- <p class="form-label mb-0">{{'patient-information.address'|translate}} </p> -->
                <p class="form-value">{{address}}</p>
            </div>
        </div>
    </div>
    <app-pdf-viewer *ngIf="isPdfViewerOpened" [blobSrc]="pdfBlob" [allPages]="true"
        pdftitle="{{'documents.document'|translate}}" (closed)="isPdfViewerOpened = false">
    </app-pdf-viewer>
    <app-pdf-viewer *ngIf="isPdfViewerOpened" [blobSrc]="pdfBlob" [allPages]="true"
        pdftitle="{{'documents.document'|translate}}" (closed)="isPdfViewerOpened = false">
    </app-pdf-viewer>
    <div class="bookingInfoDiv mt-20">
        <h5 class="mb-20">{{'patient-information.documents'|translate}}</h5>
        <div class="threeColumnGrid" *ngIf="isDocLoaded">
            <div *ngFor="let documentType of documentTypes">
                <div class="dt-wrapper">
                    <div>
                        <div class="title-wrapper">
                            <span class="icon-icon-documents font-l document-icon"></span>
                            <p class="form-label mb-0">{{documentType.KeyName|translate}}</p>
                        </div>
                        <div class="expiry-margin">
                            <!-- <div *ngIf="!documentType.Expiry && getDocumentValidity(documentType)==''"
                                class="heading form-value mt-20">
                            </div> -->
                            <div *ngIf="getDocumentValidity(documentType)!=''" class="heading form-value ml-5">
                                {{'patient-info.valid-till' |
                                translate}} {{getDocumentValidity(documentType) | date: 'dd/MM/yyyy'}} </div>
                            <div *ngIf="getDocumentValidity(documentType)!= '' && isDocumentUploaded(documentType) && checkExpiry(documentType)"
                                class="expired">
                                {{'documents.expired'|translate}}</div>
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <div *ngIf="isDocumentUploaded(documentType)" class="d-flex">
                            <button class="view-button" mat-button color="accent" (click)="openImage(documentType)"
                                matTooltip="{{'patient-info.view' | translate}}">
                                <span class="download-icon-span icon-ic-view-prescription"></span>
                            </button>
                            <button class="download-icon" mat-icon-button (click)="downloadPres(documentType)"
                                matTooltip="{{'patient-info.download' | translate}}">
                                <span class="icon-ic-download download-icon-span"></span>
                            </button>
                        </div>
                        <div *ngIf="!isDocumentUploaded(documentType)">
                            <button class="uploadBtn" mat-icon-button (click)="documentViewAll(documentType)"
                                matTooltip="{{'patient-info.upload' | translate}}">
                                <span class="download-icon-span upload-size icon-ic-upload"></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bookingInfoDiv mt-20">
        <div class="delete-account">
            <h5 class="pul-left">{{'patient-information.delete'|translate}}</h5>
            <div class="del-acc" (click)="deleteAccount()">
                <span class="icon-ic_alert pull-right color-error"></span>
                <a class="color-error pull-right">{{'patient-information.delete-account'|translate}}</a>
            </div>
        </div>
        <p class="delete-message">{{'patient-information.delete-message'|translate}} </p>

    </div>
</div>
<router-outlet></router-outlet>