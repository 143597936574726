import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
/** Service to handle Page Title */
export class PageTitleService {

    /* #region Fields */
    private readonly headerMessageSource: Subject<string[]> = new Subject<string[]>();
    /* #endregion */

    constructor() { }

    /* #region Public Methods */
    /** Method to subscribe page Title changes */
    public titleChange(): Observable<string[]> {
        return this.headerMessageSource.asObservable();
    }

    /** Method to set Page Title */
    public setPageTitle(pageTitles: string[]): void {
        this.headerMessageSource.next(pageTitles);
    }
    /* #endregion */
}
