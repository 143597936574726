import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgOtpInputConfig } from 'ng-otp-input';
import { AuthenticationService } from '../../services/authentication.service';
import { LoaderService, MessageService } from '../../../main';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {
  public otp: string;
  public display: any;
  public btndisabled = true;
  public showOTPScreen: boolean = false;
  public verifyID: string;
  public config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
  };
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  constructor(public readonly dialogRef: MatDialogRef<VerifyOtpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private authService: AuthenticationService,
    public loader: LoaderService,
    public messageService: MessageService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.timer();
  }

  public onOtpChange(otp: string) {
    this.otp = otp;
  }

  public timer() {
    const minute = 5;
    let seconds: number = minute * 60;
    let textSec = 0;
    let statSec = 60;
    const prefix = minute < 10 ? "0" : "";
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) {
        statSec--;
      } else {
        statSec = 59;
      }
      if (statSec < 10) {
        textSec = 0 + statSec;
      } else {
        textSec = statSec;
      }
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec
        .toString()
        .padStart(2, "0")}`;
      if (seconds == 0) {
        clearInterval(timer);
        this.btndisabled = false;
      }
    }, 1000);
  }



  public sendOTP() {
    this.timer();
    this.ngOtpInput.setValue("");
    this.btndisabled = true;
    const req = {
      id: "",
      email: this.data.email
    };

    this.authService.sendOTP(req).subscribe(
      (response) => {
        this.data.verify_id = response.verify_id
      },
      (err: {
        error: { code: any; message: string };
        message: any;
        code: any;
      }) => {

        this.messageService.alert(err.error.message);
      });
  }

  public verifyOTP() {
    const updateEmailData = {
      id: this.data.id,
      email: this.data.email,
      otp: this.otp,
      verify_id: this.data.verify_id
    };

    this.authService.verifyOTP(updateEmailData).subscribe(
      (res:any) => {
        this.dialogRef.close({ verified: true, otp: this.otp, verify_id: this.data.verify_id, id:res.id });
      },
      (err: {
        error: { code: any; message: string };
        message: any;
        code: any;
      }) => {
        if (err.error.code == 422) {
          // invalid OTP error message
          this.messageService.alert(
            this.translate.instant("login.invalid-otp")
          );
        } else {
          this.messageService.alert(err.error.message);
        }

      });
  }
}
