
<div *ngIf="data.email !==''" class="pull-img">
    <div class="pull-right">
        <button class="slider_closebtn" mat-button color="accent" type="button" (click)="dismiss()">
            <span class="icon-ic-close"></span>
        </button>
    </div>
    <div class="lock-svg"> <img src="assets/images/application-logos/svg/ic-otp-user.svg" alt="pres-no">
    </div>
</div>
<div class="cross-button" *ngIf="data.email ==''">
    <button mat-icon-button color="accent" (click)="dismiss()">
        <span class="icon-ic-close"></span>
    </button>
</div>

<div class="dialog-container">
    <div *ngIf="data.email">
        <h3 class="popup-heading">{{'verify-my-account.title' | translate}}</h3>
        <mat-hint class="otp-hint">{{data.title}}</mat-hint>
        <div class="email-margin-bottom">
            <mat-hint class="email-phone-bold">{{ data.email}}</mat-hint>
        </div>
        <mat-hint class="otp-hint">{{data.message | translate}}</mat-hint>
    </div>

    <h5  *ngIf="data.email==''">{{ data.title | translate}}</h5>
    <div  *ngIf="data.email==''" class="dialog-content">
        {{data.message | translate}}
        <span *ngIf="data.optionalValue" class="optionalValue">{{data.optionalValue}}</span>
        <span *ngIf="data.optionalValue1" class="optionalValue">{{data.optionalValue1}}</span>
    </div>

    <div class="actions">
        <button mat-button (click)="dismiss()" color="accent" class="ghost" *ngIf="data.cancelLabel">{{ data.cancelLabel
            | translate
            }}</button>
        <button mat-raised-button color="primary" type="submit" *ngIf="data.confirmLable" class="button_content"
             (click)="confirmed()">{{
            data.confirmLable | translate}}</button>
    </div>
</div>