import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '../../core';

@Injectable({
    providedIn: 'root'
})
export class PatientAppointmentService {
    constructor(
        private http: HttpClient,
        private readonly appSettings: AppSettingsService
    ) { }
    getAppointmentDetails(
        patientId: string,
        fromAppointment: boolean,
        limit?: number,
        isFutureRequired?: boolean
    ) {
        return this.http.get(
            this.appSettings.patientBaseUrl +
            'appointment/details?patient_id=' +
            patientId +
            '&fromAppointment=' +
            fromAppointment +
            '&limit=' +
            limit + '&isFutureRequired=' + isFutureRequired
        );
    }

    getAppointments(
        patientId: string,
        fromAppointment: boolean,
        limit: number,
        timeRelation: string,
        pageId: number
    ) {
        return this.http.get(
            this.appSettings.patientBaseUrl +
            'appointment/details?patient_id=' + patientId +
            '&fromAppointment=' + fromAppointment +
            '&limit=' + limit +
            '&timeRelation=' + timeRelation +
            '&pageId=' + pageId
        );
    }

    getTestHistory(
        status: string = '',
        startDate: string = '',
        endDate: string = '',
        ssId: number = 0,
        testId: number = 0
    ) {
        return this.http.get(
            this.appSettings.patientBaseUrl +
            'test/history?status_filter=' +
            status +
            '&start_date_filter=' +
            startDate +
            '&end_date_filter=' +
            endDate +
            '&sampling_station_filter=' +
            ssId +
            '&test_id_filter=' +
            testId
        );
    }

    getTestKitWithoutResult(samplingStationId: any, samplingTime = '') {
        return this.http.get(
            this.appSettings.patientBaseUrl +
            'testkitWithoutResult?sampling_station_id=' +
            samplingStationId +
            '&sampling_time=' +
            samplingTime
        );
    }

    getPrescription(path: string) {
        const prescriptionName = path.substring(path.lastIndexOf('/') + 1);
        return this.http.get(
            this.appSettings.patientBaseUrl + 'prescription?path=' + prescriptionName
        );
    }
    getSampleKit(path: string) {
        const prescriptionName = path.substring(path.lastIndexOf('/') + 1);
        return this.http.get(
            this.appSettings.patientBaseUrl + 'kitsample?path=' + prescriptionName
        );
    }

    updatePrescription(inputPescription: any) {
        return this.http.put(
            this.appSettings.patientBaseUrl + 'prescription',
            inputPescription
        );
    }

    updateReplacePrecription(inputPescription: any) {
        return this.http.put(
            this.appSettings.patientBaseUrl + 'prescription',
            inputPescription
        );
    }

    getAppointmentListByPatientInfo(
        searchInput: any,
        ss_id: any,
        fromAppintment: boolean = true,
        isDob: boolean = false
    ) {
        return this.http.get(
            this.appSettings.patientBaseUrl +
            'appointment/details?search_text=' +
            searchInput +
            '&ss_id=' +
            ss_id +
            '&fromAppointment=' +
            fromAppintment +
            '&isDob=' +
            isDob
        );
    }


    getPatientAppointmentList(
        p_id: any,
        ss_id: any,
        fromAppintment: boolean = true
    ) {
        return this.http.get(
            this.appSettings.patientBaseUrl +
            'appointment/details?patient_id=' +
            p_id +
            '&ss_id=' +
            ss_id +
            '&fromAppointment=' +
            fromAppintment
        );
    }

    getPatientOtherAppointmentList(
        p_id: any,
        ss_id: any,
        otherAppointment: boolean = true
    ) {
        return this.http.get(
            this.appSettings.patientBaseUrl +
            'appointment/details?patient_id=' +
            p_id +
            '&ss_id=' +
            ss_id +
            '&otherAppointment=' +
            otherAppointment
        );
    }

    deletePatientAppointment(obj: any) {
        return this.http.delete(this.appSettings.patientBaseUrl +
            'appointments?id=' + obj.appointment_id + '&patient_id=' + obj.patientId + '&test_id=' + obj.test_id + '&kit_code=' + obj.barCode);
    }

    deletePatientPrescription(appointmentId: string, path: string) {
        return this.http.delete(this.appSettings.patientBaseUrl + 'prescription' + '?appointment_id=' + appointmentId + '&path=' + path);
    }

    getAppointmentListBySearch(searchReq: any) {
        return this.http.post(
            this.appSettings.patientBaseUrl + 'appointment/details',
            searchReq
        );
    }

    getResultCertificate(path: string) {
        var CertificateName = path.substring(path.lastIndexOf("/") + 1);
        return this.http.get(
            this.appSettings.patientBaseUrl + "certificate?path=" + CertificateName
        );
    }

    getAppointmentHistory(patientId: string) {
        return this.http.get(
            this.appSettings.patientBaseUrl +
            'appointment/history?id=' +
            patientId
        );
    }

}

