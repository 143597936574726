<div class="prescription-container"
    [ngClass]="loader.loggedIn ? 'prescription-container-login' : 'prescription-container-guest'">
    <div class="section-header" [ngClass]="loader.loggedIn ? 'section-header-login' : 'section-header-guest'">
        <circle-progress class="only-web" [options]="loader.optionsG" [percent]="percent" [renderOnClick]="false"
            imageSrc="assets/images/application-logos/svg/ic-sdocument.svg" class="copy">
        </circle-progress>
        <h3 class="desc-title">{{'upload-prescription.prescription-desc'|translate}}</h3>
    </div>
    <div class="reqnumber-section" [class.red]="nextClicked && item.PrescriptionParts?.length==0"
        [ngClass]="loader.loggedIn ? 'reqnumber-section-login' : 'reqnumber-section-guest'">
        <app-appointment-prescription class="pres-section" [document]="item" [prescription]="true"
            (updateDocument)="getUpdateDocument($event)"
            (isImageClicked)="getClickImageStatus($event)"></app-appointment-prescription>
    </div>
    <div class="action-btn" [ngClass]="{
        'action-btn-login': loader.loggedIn,
        'action-btn-guest': !loader.loggedIn,
        'justify-content-end': showBackBtn==false
      }">
        <button mat-raised-button class="back-btn" type="button" *ngIf="showBackBtn" (click)="goToBack()">
            {{'back' | translate}}
        </button>
        <button mat-raised-button class="next-btn" type="button" (click)="goToNext()">
            {{'next' | translate}}
        </button>
    </div>
</div>