<div class="slider-data-container">
    <div class="header">
        <div class="pull-left">
            <app-section-title title="{{'reset-password-comp.reset-password'|translate}}"></app-section-title>
        </div>
        <div class="pull-right">
            <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
                <span class="icon-ic-close"></span>
            </button>
        </div>
    </div>

    <form class="pt-30" [formGroup]="resetForm">
        <div id="reset_form" class="form-layout">
            <mat-form-field class="mb-5">
                <mat-label>{{'reset-password-comp.current-password'|translate}}</mat-label>
                <input matInput type="password" name="CurrentPassword" id="CurrentPassword"
                    formControlName="currentPassword" [type]="hideCurrentPassword ? 'password' : 'text'" />
                <mat-error
                    *ngIf="resetForm.controls['currentPassword'].invalid && !resetForm.controls['currentPassword'].errors?.required">
                    {{'reset-password-comp.valid-password'|translate}}</mat-error>
                <mat-error *ngIf="resetForm.controls['currentPassword'].hasError('required')">
                    {{'reset-password-comp.mandatory-field'|translate}}
                </mat-error>
                <mat-icon matSuffix class="password_icon" (click)="hideCurrentPassword = !hideCurrentPassword">{{
                    hideCurrentPassword ? "visibility_off" : "visibility"
                    }}</mat-icon>
            </mat-form-field>
            <mat-form-field class="mb-5">
                <mat-label>{{'reset-password-comp.new-password'|translate}}</mat-label>
                <input matInput type="password" (blur)="hideErrorBox()" (focus)="showErrorBox()" name="Password"
                    id="Password" formControlName="password" [type]="hideNewPassword ? 'password' : 'text'"
                    (change)="onPasswordChange()" (keydown.space)="loader.disableSpace($event)"  />
                <mat-icon matSuffix class="password_icon" (click)="hideNewPassword = !hideNewPassword">{{
                    hideNewPassword ? "visibility_off" : "visibility"
                    }}</mat-icon>

                    <mat-error *ngIf="resetForm.controls['password'].invalid && resetForm.controls['password'].value && resetForm.controls['password'].hasError('whitespace')">{{'createAccount.space-error' |
                        translate}}</mat-error>
                    <mat-error *ngIf="resetForm.controls['password'].invalid && resetForm.controls['password'].value && !resetForm.controls['password'].hasError('whitespace')">{{'reset-password-comp.valid-password' | translate}}</mat-error>
                    <mat-error *ngIf="resetForm.controls['password'].invalid && !resetForm.controls['password'].value && resetForm.controls['password'].hasError('whitespace')">{{'reset-password-comp.valid-password' | translate}}</mat-error>
                <!-- <mat-error *ngIf="resetForm.controls['currentPassword'].value != '' && resetForm.controls['currentPassword'].value == resetForm.controls['password'].value">
                        {{'reset-password-comp.current-password-new-password-not-same'|translate}}</mat-error>  
                <mat-error *ngIf="resetForm.controls['password'].hasError('required')">
                    {{'reset-password-comp.mandatory-field'|translate}}
                </mat-error>
                <mat-error
                    *ngIf="resetForm.controls['password'].invalid && !resetForm.controls['password'].errors?.required">
                    {{'reset-password-comp.valid-password'|translate}}</mat-error> -->
                <app-password-policy (isValid)="checkIsValid($event)"></app-password-policy>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'reset-password-comp.repeat-password'|translate}}</mat-label>
                <input matInput type="password" name="RepeatPassword" id="RepeatPassword"
                    formControlName="repeatPassword" [type]="hideRepeatPassword ? 'password' : 'text'"
                    (change)="onPasswordChange()" (keydown.space)="loader.disableSpace($event)" />
                <mat-icon matSuffix class="password_icon" (click)="hideRepeatPassword = !hideRepeatPassword">{{
                    hideRepeatPassword ? "visibility_off" : "visibility"
                    }}</mat-icon>
                <mat-error *ngIf="resetForm.controls['repeatPassword'].invalid">{{'reset-password-comp.password-match-check-message' | translate}}</mat-error>
                
                <!-- <mat-error *ngIf="resetForm.controls['repeatPassword'].hasError('required')">
                    {{'reset-password-comp.mandatory-field'|translate}}
                </mat-error>
                <mat-error
                    *ngIf="resetForm.controls['repeatPassword'].invalid && !resetForm.controls['repeatPassword'].errors?.required">
                    {{'reset-password-comp.password-match-check-message'|translate}}</mat-error> -->
            </mat-form-field>
        </div>

        <div class="actions">
            <button mat-raised-button color="primary" class="button_width mr-20" type="submit" id="save_btn"
                (click)="updatePassword()">
                {{'reset-password-comp.update-password'|translate}}
            </button>
        </div>
    </form>
</div>