<div class="slider-data-container">
    <ng-container *ngIf="step==1">
        <div class="header">
            <div class="pull-left">
                <app-section-title title="{{'elevate-account.title'|translate }}">
                </app-section-title>
            </div>
            <div class="pull-right">
                <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
                    <span class="icon-ic-close"></span>
                </button>
            </div>
        </div>
        <div class="member-form-container">
            <div class="note-container">
                <span>{{'elevate-account.head1'|translate}}
                </span>
            </div>
            <div class="feature-wrapper">
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue">
                    </span>
                    <span>{{'elevate-account.feature1'|translate}}
                    </span>
                </div>
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue">
                    </span>
                    <span>{{'elevate-account.feature2'|translate}}
                    </span>
                </div>
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue ">
                    </span>
                    <span>{{'elevate-account.feature3'|translate}}
                    </span>
                </div>
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue ">
                    </span>
                    <span>{{'elevate-account.feature4'|translate}}
                    </span>
                </div>
            </div>
        </div>

        <div class="access-container">
            <div class="note-container">
                {{'elevate-account.sub-title-1'|translate }} {{sliderFormData.patient?.first_name}}
                {{sliderFormData.patient?.last_name}} {{'elevate-account.sub-title-2'|translate }}
            </div>
            <div class="section-wrapper">
                <div class="section">
                    <mat-form-field>
                        <mat-label>{{'add-member.email' | translate}}</mat-label>
                        <input matInput name="email" id="email" [formControl]="elevateEmail" required />
                        <mat-error *ngIf="!elevateEmail.valid  && !elevateEmail.hasError('emailExist')">
                            {{'createAccount.valid-email'| translate}}</mat-error>
                        <mat-error *ngIf="!elevateEmail.valid && elevateEmail.hasError('emailExist')">
                            {{'update-email.current-email-new-email-not-same' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="actions">
            <div class="inner-btn">
                <button mat-raised-button color="primary" class="button_width" type="button" (click)="updateEmail()"
                    id="btn_save">
                    {{'elevate-account.send-invite' | translate}}
                </button>

            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="step==2">
        <div class="header">
            <div class="pull-left">
                <app-section-title title="{{'elevate-account.title'|translate }}">
                </app-section-title>
            </div>
            <div class="pull-right">
                <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
                    <span class="icon-ic-close"></span>
                </button>
            </div>
        </div>
        <div id="otpinner_container">
            <p class="otp-header">{{'update-email.confirm-email' | translate}}{{elevateEmail.value}}</p>
            <h3>{{'login.otp-entermessage' | translate}}</h3>
            <mat-hint class="otp-hint">{{'login.otp-hint' | translate}}</mat-hint>
            <br>
            <ng-otp-input class="otp" #OtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
            <div class="otp-counter">
                <h5>{{'login.otp-time-remaining' | translate}} : {{display}}</h5>
                <button [disabled]="btndisabled" color="primary" type="submit" class="btn-login" (click)="resetOTP()">
                    {{'login.otp-resend' | translate}} </button>
            </div>
            <div class="otp-buttons">
                <button mat-raised-button color="primary" type="submit" class="btn-login" (click)="elevateAccess()">
                    {{'login.otp-verify' | translate}} </button>
            </div>
        </div>
    </ng-container>
</div>