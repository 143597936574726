<div class="otp-container">
    <div class="lock-svg" *ngIf="step==1"> <img src="assets/images/application-logos/svg/ic-otp-user.svg" alt="pres-no" *ngIf="!phoneSelected">
    </div>
    <div class="lock-svg" *ngIf="step !=1"> <img src="assets/images/application-logos/svg/ic-otp-lock.svg"
            alt="pres-no" *ngIf="!phoneSelected">
    </div>
    <div class="close-btn"> <button class="close" mat-dialog-close mat-button id="btn_close_dialog"><span
                class="icon-ic-close"></span></button></div>
</div>
<div class="unavailabilty-container" [ngClass]="{'unavailabilty-container-flex': uiModeforSentOtp ==1}" *ngIf="!phoneSelected">
    <div class="sub-container" *ngIf="uiModeforSentOtp !=1">
        <div class="popup-heading">
            {{step===1?('verify-my-account.title'| translate):('verify-my-account.title-otp'| translate)}}
            <!-- <app-section-title title="{{title | translate}}"></app-section-title> -->
        </div>
        <div style="text-align: center;">
            <form #intitalForm="ngForm" novalidate *ngIf="step==1">
                <ng-container>
                    <div class="emailonly" *ngIf="!onlyPhoneNo">
                        <ng-container *ngIf="lengthOfEmailOne">
                            <div>
                                <mat-hint class="subtext-hint">{{ selectedAccount.email ?
                                    ('verify-my-account.description_mail-top' |
                                    translate):('verify-my-account.description_ph-top' |
                                    translate)
                                    }}</mat-hint>
                            </div>
                            <mat-hint class="subtext-hint">
                                {{ selectedAccount.email ?
                                ('verify-my-account.description_mail-mid' |
                                translate):('verify-my-account.otp-hint-initial-phone' |
                                translate)
                                }}
                            </mat-hint>
                        </ng-container>
                        <ng-container *ngIf="!lengthOfEmailOne">
                            <mat-hint class="subtext-hint">
                                {{ 'OTP-msg.multiple-email-top' | translate }}
                            </mat-hint>
                        </ng-container>
                        <!-- //verify email address suspended account -->
                        <mat-radio-group [formControl]="selectedAccountId" name="dsd"
                            (change)="accountSelectionChange()" required>
                            <div *ngIf="lengthOfEmailOne">
                                <span class="email-phone" *ngFor="let da of data?.dynamicData">{{ da.email ?
                                    da.email :'+'+
                                    da.phone_number
                                    }}</span>
                            </div>
                            <div *ngIf="!lengthOfEmailOne" class="radio-btn">
                                <mat-radio-button *ngFor="let da of data?.dynamicData" name="{{da.id}}" id="{{da.id}}"
                                    value="{{da.id}}" class="radio-gap"> {{ da.email ? da.email :'+'+
                                    da.phone_number }}
                                </mat-radio-button>
                            </div>
                        </mat-radio-group>
                        <mat-hint class="subtext-hint" *ngIf="!lengthOfEmailOne">
                            {{ 'OTP-msg.multiple-email-bottom' | translate }}
                        </mat-hint>

                        <div class="actions">
                            <button mat-raised-button color="primary" class="button_width" type="submit" id="save_btn"
                                (click)="sendOTP()">
                                {{'verify-my-account.btn-submit'|translate}}
                            </button>
                        </div>
                    </div>
                    <!--  -->
                    <div class="onlyPhoneNo form-alignment" *ngIf="onlyPhoneNo">
                        <ng-container>
                            <div>
                                <mat-hint class="subtext-hint">
                                    {{ 'verify-my-account.text-for-account-with-phone-only' |translate }}
                                </mat-hint>
                                
                                <mat-hint class="subtext-hint">
                                    {{ 'verify-my-account.text-for-account-after-phone-no' |translate }}
                                </mat-hint>
                                <span class="email-phone"
                                    *ngFor="let da of data?.dynamicData">+{{da.phone_number}}</span>
                            </div>

                        </ng-container>
                        <mat-radio-group [formControl]="selectedAccountId" name="dsd"
                            (change)="accountSelectionChange()" required>
                        </mat-radio-group>
                        <mat-hint class="subtext-hint imp-msg">
                            {{'verify-my-account.account-with-phone-only' | translate}}
                        </mat-hint>
                        <div class="otp-option-container text-for-whatsapp">
                            <button mat-button type="submit" class="border-whatsapp" *ngIf="!disableWhatsapp"
                                (click)="sendOTP('whatsapp')">
                                <span class="icon-ic-whatsapp whatsapp-btn btn-alignment"></span>
                                {{'OTP-msg.Whatsapp-btn'|
                                translate}}
                            </button>
                            <button mat-button type="submit" class="border-msg " *ngIf="!disableSMS"
                                (click)="sendOTP('sms')">
                                <span class="icon-ic-sms sms-btn btn-alignment"></span>{{'OTP-msg.sms-btn'|
                                translate}}</button>
                        </div>
                    </div>


                </ng-container>
            </form>
            <form #finalForm="ngForm" novalidate *ngIf="step==2">
                <div id="otpinner_container">
                    <!-- add phone to get otp -->
                    <mat-hint class="subtext-hint" *ngIf="!onlyPhoneNo">
                        {{ 'verify-my-account.otp-hint-initial-email' | translate }}


                        <!-- <span class="email-phone" *ngIf="phoneSelectedMessage">{{selectedAccount.phone_number
                                || PhoneNumber}}</span> -->
                        <!-- {{'verify-my-account.otp-hint-initial_end' | translate}} -->
                    </mat-hint>
                    <mat-hint class="subtext-hint" *ngIf="onlyPhoneNo">

                        {{whatsappmessage ?('OTP-msg.otp-whatsapp' | translate):('OTP-msg.otp-sms' | translate)}}

                        <span class="email-phone" *ngIf="phoneSelectedMessage">+{{selectedAccount.phone_number
                            || PhoneNumber}}</span>
                    </mat-hint>

                    <mat-hint *ngIf="!phoneSelectedMessage" class="email-phone">
                        {{selectedAccount.email}}
                    </mat-hint>
                    <div>
                        <ng-otp-input class="otp" #ngOtpInput (onInputChange)="onOtpChange($event)"
                            [config]="config"></ng-otp-input>
                        <div class="otp-counter">
                            <span class="stopwatch-timer">{{'verify-my-account.otp-time-remaining' | translate}} :
                                {{display}}</span>
                            <!-- <button [disabled]="btndisabled" color="primary" type="submit" class="btn-login"
                                (click)="sendOTP('sms')">
                                {{'verify-my-account.otp-resend' | translate}} </button> -->

                        </div>
                        <div class="otp-buttons">
                            <button mat-raised-button color="primary" type="submit" class="btn-login verify-btn"
                                (click)="sendOTP()" *ngIf="!btndisabled">
                                {{'verify-my-account.otp-resend' | translate}}</button>
                            <button mat-raised-button color="primary" type="submit" class="btn-login verify-btn"
                                (click)="verifyOTP()" *ngIf="btndisabled">
                                {{'verify-my-account.otp-verify'|translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    </div>
    <div id="otp_container" *ngIf="uiModeforSentOtp ==1">

        <div id="otpinner_container" class="otpinner-container">
            <h3 class="otp-header headinginCenter">{{'OTP-msg.heading-option-top'| translate}}</h3>
            <h2 class="otp-container-text" *ngIf="displayMessage==1">{{'OTP-msg.disable-email-option'|
                translate}}</h2>
            <h2 class="otp-container-text">{{'OTP-msg.heading-alloption'| translate}}</h2>
            <br>
            <div class="option-btn ">
                <button mat-button type="submit" class="border-mail" (click)="sendOTP('mail')"
                    *ngIf="!phoneSelected && (otpClickCount<1)|| !disableEmail">
                    <span class="icon-ic-mailcircle mail-btn btn-alignment"></span>{{'OTP-msg.mail-btn'| translate}}
                </button>
                <button mat-button type="submit" class="border-whatsapp" (click)="sendOTP('whatsapp')"
                    *ngIf="!disableWhatsapp">
                    <span class="icon-ic-whatsapp whatsapp-btn btn-alignment"></span> {{'OTP-msg.Whatsapp-btn'|
                    translate}}
                </button>
                <button mat-button type="submit" class="border-msg " (click)="sendOTP('sms')" *ngIf="!disableSms">
                    <span class="icon-ic-sms sms-btn btn-alignment"></span> {{'OTP-msg.sms-btn'|
                    translate}}</button>
            </div>
        </div>
    </div>

    <mat-hint class="important-msg" *ngIf="step==1">{{ selectedAccount.email ?
        ('verify-my-account.description_mail-bottom' |
        translate):('verify-my-account.description_ph-bottom' |
        translate)
        }}</mat-hint>
</div>
<div *ngIf="phoneSelected" class="full-height">
    {{'verify-my-account.otp-hint-error' |    translate    }}
</div>