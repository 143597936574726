import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-receptionist',
    template: '<router-outlet></router-outlet>'
})
export class ReceptionistComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
