import { Component, Input, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    Validators,
    FormGroup
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
import { DrawerComponent, SpinnerService } from '../../../core';
import { LoaderService, MessageService } from '../../../main';
import { AuthenticationService } from '../../services/authentication.service';
import { LoginServiceService } from '../../services/login-service.service';
import { SaltRequest } from '../../services/Models/getAuthAccessTokenRequestModel';
import { ResetPasswordService } from '../../services/reset-password.service';
import { PasswordPolicyService } from '../../shared/password-policy/password-policy.service';
import { constants } from '../../shared/constant';
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, DrawerComponent {
    @Input() sliderFormData: any;
    @Input() public isSliderDataEdited = false;
    resetForm: FormGroup;
    verifyInput: any;
    hideCurrentPassword = true;
    hideRepeatPassword = true;
    hideNewPassword = true;
    isPasswordValid: boolean;
    isUser = false;
    isNurse = false;
    ifFrance = false;
    passwordErrorMessage = 'reset-password-comp.current-password-new-password-not-same';

    constructor(
        private loaderService: LoaderService,
        public resetPasswordService: ResetPasswordService,
        private formBuilder: FormBuilder,
        // private readonly dialog: MatDialog,
        public loginServiceService: LoginServiceService,
        private readonly spinner: SpinnerService,
        private translate: TranslateService,
        private localeService: LocaleService,
        public loader: LoaderService,
        private passwordPolicy: PasswordPolicyService,
        private messageService: MessageService,
        public authenticationService: AuthenticationService
    ) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    ngOnInit(): void {
        this.resetForm = this.formBuilder.group({
            currentPassword: new FormControl(
                '', [Validators.pattern(constants.passwordValidation)
            ]
            ),
            password: new FormControl(
                '',
                [
                    Validators.pattern(constants.passwordValidation),
                    this.noWhitespaceValidator
                ]
            ),
            repeatPassword: new FormControl('')
        });

        this.resetForm.controls.password.valueChanges.subscribe(() => {
            this.showErrorBox();
        });
    }

    dismiss() {
        this.loaderService.cancelForm();
    }

    public noWhitespaceValidator(password: FormControl) {
        const isWhitespace = (password.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    // ValidateRepeatPassword(control: AbstractControl): { invalid: boolean } {
    //   if (control.get("password").value === control.get("repeatPassword").value) {
    //     return null;
    //   }
    //   return { invalid: true };
    // }

    onPasswordChange() {
        if (this.resetForm.get('password').value.indexOf(' ') > -1) {
            this.resetForm.controls['password'].setErrors({ whitespace: true });
        }
        if (
            this.resetForm.get('password').value !==
            this.resetForm.get('repeatPassword').value
        ) {
            this.resetForm.controls['repeatPassword'].setErrors({ incorrect: true });

        } else {
            this.resetForm.controls['repeatPassword'].setErrors({ incorrect: null });
            this.resetForm.controls['repeatPassword'].updateValueAndValidity();
        }
    }
    public ResetPasswordPopup() {
        this.messageService.success(this.translate.instant('login.password-resetSucessfull'), 1500);
        this.loaderService.cancelForm();
    }

    updatePassword() {
        if (this.resetForm.valid) {
            if (this.resetForm.getRawValue().currentPassword != this.resetForm.getRawValue().password) {
                const saltRequest: SaltRequest = new SaltRequest();
                saltRequest.id = JSON.parse(sessionStorage.getItem('access_token'))['unique_id'];
                this.authenticationService.getSalt(saltRequest).subscribe(
                    (response: any) => {
                        const encryptedcurrentPassword = this.loaderService.encryptData(this.resetForm.getRawValue().currentPassword, response.Salt);

                        //const newsalt = CryptoJS.lib.WordArray.random(128 / 20).toString();
                        const encryptednewPassword = this.loaderService.encryptData(this.resetForm.getRawValue().password, response.Salt);
                        const verifyInput = {
                            login_id: String(
                                JSON.parse(sessionStorage.getItem('userDetails')).login_id
                            ),
                            Password: encryptedcurrentPassword
                        };
                        const updateInput = {
                            Id: JSON.parse(sessionStorage.getItem('access_token'))['unique_id'],
                            Password: encryptednewPassword,
                            Salt: response.Salt,
                            ModifyUser: 0
                        };
                        this.resetPasswordService.getVerifyPassword(verifyInput).subscribe(
                            (getResponse) => {
                                this.spinner.show(false);

                                if (!getResponse['message']) {
                                    this.resetForm.controls.currentPassword.setErrors([
                                        { passwordMismatch: true }
                                    ]);
                                }
                                if (getResponse['message']) {
                                    this.resetPasswordService.putUpdatePassword(updateInput).subscribe(
                                        (updateInput) => {
                                            this.spinner.show(false);
                                            if (updateInput != null) {
                                                this.ResetPasswordPopup();
                                                setTimeout(() => {
                                                    this.loaderService.Logout();
                                                }, 2000);
                                            }
                                        },
                                        (error) => {
                                            this.spinner.show(false);
                                            this.messageService.alert(
                                                this.translate.instant(
                                                    "backend-errors." + error.error.internal_code
                                                ),
                                                5000
                                            );
                                            console.log(error);
                                        }
                                    );
                                    this.spinner.show(false);
                                }
                            },
                            (error) => {
                                this.spinner.show(false);
                                console.log(error);
                            }
                        );
                    });
            } else {
                this.messageService.alert(this.translate.instant(this.passwordErrorMessage), 5000);
            }
        } else {
            console.log('Data Error');
        }
    }

    // showErrorBox() {
    //     this.passwordPolicy.show(this.resetForm.controls.password.value, environment.intMinPwdLength, environment.pwdMaxLength);
    //     this.isPasswordValid = this.passwordPolicy.valid(
    //         this.resetForm.controls.password.value, environment.intMinPwdLength,
    //         environment.pwdMaxLength
    //     );
    // }

    showErrorBox() {
        if (this.isUser) {
            // for user role
            this.passwordPolicy.show(
                this.resetForm.controls.password.value,
                constants.extMinPwdLength,
                constants.pwdMaxLength
            );
            this.isPasswordValid = this.passwordPolicy.valid(
                this.resetForm.controls.password.value,
                constants.extMinPwdLength,
                constants.pwdMaxLength
            );
        } else if (this.isNurse) {
            // for nurse role
            this.passwordPolicy.show(
                this.resetForm.controls.password.value,
                constants.nurseMinPwdLength,
                constants.pwdMaxLength
            );
            this.isPasswordValid = this.passwordPolicy.valid(
                this.resetForm.controls.password.value,
                constants.nurseMinPwdLength,
                constants.pwdMaxLength
            );
        } else {
            // for patient role
            this.passwordPolicy.show(
                this.resetForm.controls.password.value,
                constants.intMinPwdLength,
                constants.pwdMaxLength
            );
            this.isPasswordValid = this.passwordPolicy.valid(
                this.resetForm.controls.password.value,
                constants.intMinPwdLength,
                constants.pwdMaxLength
            );
        }
    }

    hideErrorBox() {
        this.passwordPolicy.hide();
    }

    checkIsValid(event: any) {
        if (event) {
            this.hideErrorBox();
        }
    }
}
