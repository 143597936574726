<div class="unavailabilty-container">
    <div>
        <button class="close" mat-dialog-close mat-button id="btn_close_dialog">X</button>
    </div>
    <div class="sub-container">
        <div class="header">
            <app-section-title title="{{title | translate}}"></app-section-title>
        </div>
        <div>
            <form #intitalForm="ngForm" novalidate *ngIf="step==1">
                <ng-container>
                    <ng-container>
                        <p>
                            {{ selectedAccount.email ? 
                                ('verify-my-account.description_mail' | translate):('verify-my-account.description_ph' | translate) 
                            }}
                        </p>
                    </ng-container>
                    <!-- //verify email address suspended account -->
                    <mat-radio-group [formControl]="selectedAccountId" name="dsd" (change)="accountSelectionChange()"
                        required>
                        <mat-radio-button *ngFor="let da of data?.dynamicData" name="{{da.id}}" id="{{da.id}}"
                            value="{{da.id}}"> {{ da.email ? da.email : da.phone_number }}
                        </mat-radio-button>
                    </mat-radio-group>

                    <div class="actions">
                        <button mat-raised-button color="primary" class="button_width mr-20" type="submit" id="save_btn"
                            (click)="sendOTP()">
                            {{'verify-my-account.btn-submit'|translate}}
                        </button>
                    </div>
                </ng-container>
            </form>
            <form #finalForm="ngForm" novalidate *ngIf="step==2">
                <div id="otpinner_container">
                    <!-- add phone to get otp -->
                    <mat-hint class="otp-hint">
                        {{ selectedAccount.email ? 
                          ('verify-my-account.otp-hint-initial-email' | translate) + ' ' + selectedAccount.email :
                          ('verify-my-account.otp-hint-initial-phone' | translate) + ' ' + selectedAccount.phone_number 
                        }} 
                        {{'verify-my-account.otp-hint-initial_end' | translate}}
                    </mat-hint>
                    <br>
                    <ng-otp-input class="otp" #ngOtpInput (onInputChange)="onOtpChange($event)"
                        [config]="config"></ng-otp-input>
                    <div class="otp-counter">
                        <h5>{{'verify-my-account.otp-time-remaining' | translate}} : {{display}}</h5>
                        <button [disabled]="btndisabled" color="primary" type="submit" class="btn-login"
                            (click)="sendOTP()">
                            {{'verify-my-account.otp-resend' | translate}} </button>
                    </div>
                    <div class="otp-buttons">
                        <button mat-raised-button color="primary" type="submit" class="btn-login" (click)="verifyOTP()">
                            {{'verify-my-account.otp-verify' | translate}} </button>
                    </div>
                </div>
            </form>
        </div>