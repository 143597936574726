import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
import { CommonApiService } from '../../services/common-api.service';
import { PatientAppointmentService } from '../../services/patient-appointment.service';
import { ResultStatus } from './results.model';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from '../../../core';
import { RedirectDialogComponent } from './redirect-dialog/redirect-dialog.component';
import { LoaderService } from '../../../main';

export interface PeriodicElement {
    test: Object;
    lab: Object;
    dateTime: string;
    status: Array<{ title: string; status: string }>;
    resultLink: string;
    resultAccessCode: string;
    DiagnosisReportUrl: string;
    resultAvailable: boolean;
    kitResult: string;
}

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
    displayedColumns: string[] = ['test', 'dateTime', 'lab', 'status', 'actions'];
    allResultsdataSource: any = [];
    watitingResultsdataSource: any = [];
    readyResultsdataSource: any = [];

    refresh = true;
    resultStatusList: ResultStatus[];
    pdfBlob: any;
    isPdfViewerOpened: boolean;
    resultId: any;

    constructor(
        private translate: TranslateService,
        private localeService: LocaleService,
        private getAppointmentDetails: PatientAppointmentService,
        private commonAPi: CommonApiService,
        private readonly spinner: SpinnerService,
        private readonly dialog: MatDialog,
        public loader: LoaderService
    ) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    ngOnInit(): void {
        this.getMasterResultStatusList();
        this.updateDisplayedColumns();

    }
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.updateDisplayedColumns();
    }

    updateDisplayedColumns() {
        if (window.innerWidth < 768) {
            // Mobile screen (e.g., less than 768px wide)
            this.displayedColumns = ['test', 'dateTime', 'lab', 'status', 'actions'];
        } else {
            // Desktop screen
            this.displayedColumns = ['test', 'lab', 'dateTime', 'status', 'actions'];
        }
    }

    getMasterResultStatusList() {
        // Getting Result Status List
        this.commonAPi.getResultStatusList().subscribe((resultStatus) => {
            resultStatus.sort((a, b) => a.KeyOrder - b.KeyOrder);
            this.resultStatusList = resultStatus;
            this.getAppointment();
        });
    }
    showResultData(entity: any) {
        if (!entity.resultAvailable) return;
        if (entity.DiagnosisReportUrl != "") {
            this.openPdf(entity);
        }
        else {
            const dialogRef = this.dialog.open(RedirectDialogComponent, {
                width: "600px",
                height: "500px",
                disableClose: true,
                data: { title: this.translate.instant("history.Result available"), result: entity, isFromPatient: true },
            });
            dialogRef.afterClosed().subscribe(() => {
            });
        }
    }

    /**
   * Getting the appointment list for loggedIn patient.
   */
    getAppointment() {
        this.refresh = false;
        this.getAppointmentDetails
            .getAppointmentDetails(

                JSON.parse(sessionStorage.getItem('userDetails')).id,
                false,undefined,undefined,'desc'
            )
            .subscribe((appointmentDetails: any) => {
                const allData: PeriodicElement[] = [];
                if (appointmentDetails !== null) {
                    for (const item of appointmentDetails) {
                        const resultStatus = this.checkifResultIsAvailable(item);

                        const statusData: any = this.getStatusArray(item.StatusId);
                        var test: any;
                        test = {
                            test: {
                                title: item.TestShortName,
                                subText: this.loader.TranslateLang(item.TestType)
                            },
                            lab: {
                                title: item.SamplingStationName,
                                subText: item.AddressDetails
                            },
                            dateTime: item.SlotTime,
                            status: statusData,
                            resultLink: item.TestResult,
                            resultAccessCode: item.InternetAccessId,
                            resultAvailable: resultStatus,
                            kitResult: item.KitResult,
                            DiagnosisReportUrl: item.DiagnosisReportUrl,
                            Barcode: item.Barcode
                        };
                        allData.push(test);
                        if (item.StatusId !== this.resultStatusList[3].Id) {
                            this.watitingResultsdataSource.push(test);
                        }
                        if (item.StatusId === this.resultStatusList[3].Id) {
                            this.readyResultsdataSource.push(test);
                        }
                    }

                    this.allResultsdataSource = allData;
                }
                this.refresh = true;
            });
    }

    /**
   * This method will check if result is available or not, so if TestResult and InternetAccessId field are not empty and statusID is fall under
   * getResultstatusId APIs response then will return true otherwise false.
   * @param item Appointment Data
   * @returns true - if result is available otherwise false.
   */
    checkifResultIsAvailable(item: any) {
        if (
            item.StatusId < this.resultStatusList[this.resultStatusList.length - 1].Id
        ) {
            return false;
        }
        if (item.TestResult === undefined) {
            return false;
        }
        if (item.InternetAccessId === undefined) {
            return false;
        }
        if ((item.TestResult.length !== 0 && item.InternetAccessId.length !== 0) || item.DiagnosisReportUrl != "") {
            return true;
        }
        return false;
    }
    openPdf(selectedRowData: any) {
        this.spinner.show(true);
        var resultCertificateName = selectedRowData.DiagnosisReportUrl.substring(selectedRowData.DiagnosisReportUrl.lastIndexOf("/") + 1);
        this.getAppointmentDetails
            .getResultCertificate(resultCertificateName)
            .subscribe((res: any) => {
                const byteArray = new Uint8Array(
                    atob(res)
                        .split("")
                        .map((char) => char.charCodeAt(0))
                );
                const file = new Blob([byteArray], { type: "application/octet-stream" });
                this.pdfBlob = URL.createObjectURL(file);
                this.spinner.show(false);
                this.resultId = selectedRowData.Barcode;
                this.isPdfViewerOpened = true;
            });
    }

    /**
   * This method will form an object for displaying status in stepper, It will form the object depending on the statusID.
   * @param statusId - StatusId received from Appointment data.
   * @returns Status Object containing an array of status for stepper.
   */
    getStatusArray(statusId: number) {
        if (this.resultStatusList[0].Id === statusId) {
            return [
                { title: 'results.recieved', status: 'Completed' },
                { title: 'results.in-progress', status: 'InProgress' },
                { title: 'results.analysis-validation', status: 'InProgress' },
                { title: 'results.result-available', status: 'InProgress' }
            ];
        } else if (this.resultStatusList[1].Id === statusId) {
            return [
                { title: 'results.recieved', status: 'Completed' },
                { title: 'results.in-progress', status: 'Completed' },
                { title: 'results.analysis-validation', status: 'InProgress' },
                { title: 'results.result-available', status: 'InProgress' }
            ];
        } else if (this.resultStatusList[2].Id === statusId) {
            return [
                { title: 'results.recieved', status: 'Completed' },
                { title: 'results.in-progress', status: 'Completed' },
                { title: 'results.analysis-validation', status: 'Completed' },
                { title: 'results.result-available', status: 'InProgress' }
            ];
        } else if (this.resultStatusList[3].Id === statusId) {
            return [
                { title: 'results.recieved', status: 'Completed' },
                { title: 'results.in-progress', status: 'Completed' },
                { title: 'results.analysis-validation', status: 'Completed' },
                { title: 'results.result-available', status: 'Completed' }
            ];
        } else {
            return [
                { title: 'results.recieved', status: 'InProgress' },
                { title: 'results.in-progress', status: 'InProgress' },
                { title: 'results.analysis-validation', status: 'InProgress' },
                { title: 'results.result-available', status: 'InProgress' }
            ];
        }
    }
}
