import { Observable, of } from 'rxjs';
import { LogEntry } from '../models/log-entry.model';
import { LogPublisher } from '../models/log-publishers.model';

/** Class for Logging in Console */
export class LogConsole extends LogPublisher {

    constructor() {
        super();
        this.Location = 'console';
    }

    /* #region Public Methods */
    /** Method to log an entry in console */
    public log(record: LogEntry): Observable<boolean> {
        console.log(record.buildLogString());
        return of(true);
    }

    /** Method to clear logs from console */
    public clear(): Observable<boolean> {
        console.clear();
        return of(true);
    }
    /* #endregion */
}
