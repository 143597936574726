import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../app.locale.service';
import { AppSettingsService, NavNode } from '../../core';
import { LoaderService } from '../services/loader.service';
import { MessageService } from '../services/message.service';
import { constants } from '../../eClinical/shared/constant';

@Component({
    selector: 'app-mobile-drawer',
    templateUrl: './mobile-drawer.component.html',
    styleUrls: ['./mobile-drawer.component.scss']
})
export class MobileDrawerComponent implements OnInit {
    @Input() navNodes: any;
    @Output() public ssChanged = new EventEmitter();

    @Output() close = new EventEmitter();
    displayStickyMenu: boolean;
    selectedMenuId: number;
    selectedPMenuId: number;
    userDetails: any;
    userInitial: string;
    userName: string;
    samplingStation: any;
    public ignoreMenuList: any = [];

    constructor(
        private router: Router,
        private messageService: MessageService,
        private readonly translateService: TranslateService,
        public localeService: LocaleService,
        public loader: LoaderService,
        private readonly appSettings: AppSettingsService) {
        this.translateService.setDefaultLang(this.localeService.localeLang);
        this.translateService.use(this.localeService.localeLang);
    }

    ngOnInit(): void {
        this.ignoreMenuList = this.appSettings.getIgnoreMenu.split(",");
        const access_token: any = JSON.parse(sessionStorage.getItem('access_token'));
        this.userDetails = (this.loader.userRole === constants.patientRole) ?
            JSON.parse(sessionStorage.getItem('userDetails')) : access_token;
        this.userInitial = this.userDetails?.first_name ? this.userDetails?.first_name?.charAt(0).toUpperCase()
            : this.userDetails?.user_name?.charAt(0).toUpperCase() || 'G';
        this.userName = this.userDetails?.first_name ? this.userDetails?.first_name
            : this.userDetails?.user_name || 'Guest';
        this.samplingStation = JSON.parse(sessionStorage.getItem('sampling_stations'));
        this.loader.sampling.setValue(this.samplingStation?.sampling_stations[0].id);
        this.loader.samplingcompany.setValue(this.samplingStation?.sampling_stations[0].org_id);
    }

    onLogoClick(): void {

    }

    onSubmenuClick(menuItem: NavNode, pmenu: NavNode, event: MouseEvent): void {
        this.loader.selectedMenuId = menuItem.Id;
        event.stopPropagation();
        this.loader.lastSelectedRoute = this.loader.selectedRoute;
        this.loader.selectedRoute = menuItem.Route;
        const routePath = `/${menuItem.Route}`;
        this.loader.highlightLeftMenu([routePath]);
        this.selectedMenuId = menuItem.Id;
        this.selectedPMenuId = pmenu.Id;
        this.navNodes.forEach((x: any) => (x.Expand = false));
        pmenu.Expand = true;
        this.messageService.setPageTitle('', pmenu.TranslationLabel);
        this.close.emit();
        this.router.navigate([routePath]);
    }

    getLoggedInUserData() {
        const access_token: any = JSON.parse(sessionStorage.getItem('access_token'));
        this.userDetails = (this.loader.userRole === constants.patientRole) ?
            JSON.parse(sessionStorage.getItem('userDetails')) : access_token;
        this.userInitial = this.userDetails?.first_name ? this.userDetails?.first_name?.charAt(0).toUpperCase()
            : this.userDetails?.user_name?.charAt(0).toUpperCase() || 'G';
        this.userName = this.userDetails?.first_name ? this.userDetails?.first_name
            : this.userDetails?.user_name || 'Guest';
        return this.userDetails?.email;
    }

    loadSamplingStations(data: any) {
        const samplingStations = JSON.parse(sessionStorage.getItem('sampling_stations')).sampling_stations.filter((s: any) => s.org_id == data);
        this.loader.sampling_stations_list = samplingStations;
        this.loader.sampling.setValue(samplingStations[0].id);
        this.loader.ss_id = samplingStations[0].id;
        this.loader.ss_selected_org_id = samplingStations[0].org_id;
        this.loader.ss_external_id = samplingStations[0].external_id;
        if (this.router.url.includes('book-appointment')) {
            this.redirectGuestBookingFlow()
        }
    }


    getSampling(data: any) {
        this.loader.ss_id = data;
        this.loader.onSSChange();
        const selectedindex = this.loader.sampling_stations_list.findIndex((s) => s.id == data);
        this.loader.ss_external_id =
            this.loader.sampling_stations_list[selectedindex].external_id;
        if (this.router.url.includes('book-appointment')) {
            this.redirectGuestBookingFlow()
        } else {
            this.ssChanged.emit(true);
        }
        this.close.emit();
        // this.ss_external_id = this.loader.sampling_stations_list[selectedindex].external_id;
    }
    redirectGuestBookingFlow() {
        this.loader.bookingPatientId = '';
        this.loader.bookingPatientName = this.translateService.instant('patient-info.guest');
        this.loader.user_name = '';
        this.loader.dob = '';
        this.loader.highlightLeftMenu([
            '/receptionist/book-appointment/' + this.loader.ss_external_id
        ]);
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }
}
