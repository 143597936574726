import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgOtpInputConfig } from 'ng-otp-input';
import { AuthenticationService } from '../../services/authentication.service';
import { LoaderService, MessageService, OtpStatusService } from '../../../main';
import { TranslateService } from '@ngx-translate/core';
import { MessageStatus } from '../../../shared/enums/message-status.enum';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {
  public otp: string;
  public display: any;
  public btndisabled = true;
  public showOTPScreen: boolean = false;
  public verifyID: string;
  modecheck = "mail";
  uiModeforSentOtp = 0;
  PhoneNumber: any;
  otpScreen:boolean=true;
  messageId: string;
  userEmail: any;
  idForststus: string;
  timerInterval = null;
  phoneSelectedMessage = false;
  otpClickCount = 0;
  disableEmail = false;
  displayMessage: any = 0;
  smsMode:boolean;
  public config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
  };
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  disableWhatsapp = true;
  disableSms = true;
  constructor(public readonly dialogRef: MatDialogRef<VerifyOtpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private authService: AuthenticationService,
    public loader: LoaderService,
    public messageService: MessageService,
    public translate: TranslateService,
    public otpStatus: OtpStatusService) { }

  ngOnInit(): void {
    this.timer();
    this.messageId = this.data.message_id;
    this.idForststus = this.data?.idForststus;
    this.loginStatus();
  }

  public onOtpChange(otp: string) {
    this.otp = otp;
  }

 timer() {
    const minute = 5;
    let seconds = minute * 60;
    let statSec = 60;
    const prefix = minute < 10 ? "0" : "";
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    const startTime = Date.now();
    const endTime = startTime + seconds * 1000;

    this.timerInterval = setInterval(() => {
      const now = Date.now();
      const remainingTime = Math.max(0, endTime - now);
      seconds = Math.floor(remainingTime / 1000);

      if (remainingTime === 0) {
        clearInterval(this.timerInterval);
        this.btndisabled = false;
        this.uiModeforSentOtp = 1;
        this.otpScreen=false;
        this.closeOtpOptions();
        if (this.otpClickCount == 1 && this.modecheck == "mail") {
        this.showFailureMessage();
        }
      }

      const minutes = Math.floor(seconds / 60);
      statSec = seconds % 60;

      this.display = `${prefix}${minutes}:${statSec.toString().padStart(2, "0")}`;
    }, 1000);
  }



  public sendOTP(incomingmode?: string) {
    this.uiModeforSentOtp = 0;
    this.otpScreen=true;
    if (incomingmode == "sms" || incomingmode == "whatsapp") {
      this.phoneSelectedMessage = true;
      if (this.otpClickCount == 1) {
        this.displayMessage = 0;
      }if(incomingmode== "sms"){
        this.smsMode=true;
      }else{
        this.smsMode=false;
      }
    } else if (incomingmode == 'mail') {
      this.phoneSelectedMessage = false;
      this.otpClickCount++;
      if (this.otpClickCount == 1) {
        this.disableEmail = true;
        this.displayMessage = 1;
      }
    }

    this.modecheck = incomingmode;
    this.timer();
    this.btndisabled = true;
    const req = {
      id: "",
      email: this.data.email,
      mode: this.modecheck,
      phone_number: incomingmode != "mail" ? this.data.phone_number : ""
    };

    this.authService.sendOTP(req).subscribe(
      (response) => {
        this.data.verify_id = response.verify_id;
        this.messageId = response.message_id;
        this.idForststus = response.id;
        this.loginStatus();
      },
      (err: {
        error: { code: any; message: string };
        message: any;
        code: any;
      }) => {
        if (err.error.code == 400) {
          this.loginStatus();
        } else {
          this.messageService.alert(err.error.message);
        }

      });
  }

  public verifyOTP() {
    const updateEmailData = {
      id: this.data.id,
      email: this.data.email,
      otp: this.otp,
      verify_id: this.data.verify_id,
      phone_number: "",
      mode:this.modecheck
    };

    this.authService.verifyOTP(updateEmailData).subscribe(
      (res: any) => {
        this.dialogRef.close({ verified: true, otp: this.otp, verify_id: this.data.verify_id, id: res.id });
      },
      (err: {
        error: { code: any; message: string };
        message: any;
        code: any;
      }) => {
        if (err.error.code == 422) {
          // invalid OTP error message
          this.messageService.alert(
            this.translate.instant("login.invalid-otp")
          );
        } else {
          this.messageService.alert(err.error.message);
        }

      });
  }
  loginStatus() {
    let intervalId: any;
    let timeoutId: any;
    let attemptCount = 0; // Counter for attempts

    setTimeout(() => {
      intervalId = setInterval(() => {
        this.otpStatus.loginStatus(null, '', true, this.messageId, this.modecheck, this.idForststus).subscribe(
          (response: any) => {
            if (response.phone != "") {
              this.PhoneNumber = response.phone;
            } else if (response.phone == "") {
              this.disableSms=true;
              this.disableWhatsapp=true;
            }

            if (response.message_status === MessageStatus.Deferred ||
              response.message_status === MessageStatus.Dropped ||
              response.message_status === MessageStatus.Bounced ||
              response.message_status === MessageStatus.Failed) {
              this.uiModeforSentOtp = 1;
              this.otpScreen=false;
              this.showFailureMessage();
              clearInterval(intervalId);
              clearTimeout(timeoutId);
              this.btndisabled = false;
              return;
            }else if (response.message_status === "unknown") {
              attemptCount++;
              if (attemptCount >= 29) {
                clearInterval(intervalId);
                clearTimeout(timeoutId);
                this.btndisabled = false;
                return;
              }
            } else {
              clearInterval(intervalId);
              clearTimeout(timeoutId);
            }
          },
          (error: any) => {
            console.log("Error: Message NOT Delivered found", error);
            this.uiModeforSentOtp = 1;
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            this.showFailureMessage();
            this.btndisabled = false;
            return;
          }
        );
      }, 10000);

      timeoutId = setTimeout(() => {
        clearInterval(intervalId);
      }, 300000);
    });
  }

  showFailureMessage() {
    if (this.modecheck == "mail") {
      this.disableEmail = true;
      this.otpClickCount = 1;
      this.displayMessage = 1;
      this.messageService.alert(this.translate.instant('OTP-msg.failed-mail'), 5000);
    } else if (this.modecheck == "sms") {
      this.disableSms = true;
      this.messageService.alert(this.translate.instant('OTP-msg.failed-sms'), 5000);
    } else {
      this.disableWhatsapp = true;
      this.messageService.alert(this.translate.instant('OTP-msg.failed-whatsapp'), 5000);
    }
    this.closeOtpOptions();
    
  }
  closeOtpOptions(){
    if(this.disableWhatsapp && this.disableSms && this.disableEmail){
      this.dialogRef.close(false)
    }}
}
