export const environment = {
  production: false,

  adminBaseUrl: "https://dev-fr.eurofins-eclinical.com/api/v1/fr/admin/",
  authBaseUrl: "https://dev-fr.eurofins-eclinical.com/api/v1/fr/auth/",
  patientBaseUrl: "https://dev-fr.eurofins-eclinical.com/api/v1/fr/patient/",
  saltval: "#@1$eits@1",
  encryptpipelineKey: "#{employeeCount}#",
  orgID: 1,
  matomoUrl: "https://eurofinseclinical.matomo.cloud/",
  matomoSiteId: 0,
  isCaptchaRequired: false,
  automationEmail: "automationadm@mailinator.com",
  woosmap_api_val: "woos-3c56a684-8e4c-38ef-bf84-0e66f84612f6",
  genericTestID: 106
};
