import { Component, Input, OnInit } from '@angular/core';
import { LoaderService, MessageService } from '../../../main';
import { SpinnerService } from '../../../core';
import { CommonApiService } from '../../services/common-api.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Company } from '../../models/common.model';
import { EstablishmentsService } from '../add-establishment/establishments.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-edit-department',
  templateUrl: './edit-department.component.html',
  styleUrl: './edit-department.component.scss'
})
export class EditDepartmentComponent implements OnInit {
  @Input() sliderFormData: any;
  @Input() public isSliderDataEdited = false;


  public DeptName = new FormControl("", [
    Validators.required,
    Validators.maxLength(100),
    Validators.pattern('^[a-zA-Z][a-zA-Z ]*$')
  ]);
  public status = new FormControl(true, [Validators.required]);
  public CorrCode = new FormControl("", [Validators.required]);
  public Company = new FormControl("", [Validators.required]);
  public Establishment = new FormControl("")

  public DeptForm = this.formBuilder.group({
    DeptName: this.DeptName,
    status: this.status,
    CorrCode: this.CorrCode,
    Company: this.Company,
    Establishment: this.Establishment
  });

  saveAlert = {
    edit: 'department-list.save-alert.edit',
    add: 'department-list.save-alert.add'
  };

  ehp = {
    editConfirmationMessage: 'department-list.edit-confirmation-message',
    editConfirmationTitle: 'department-list.edit-confirmation-title',
    cancel: 'department-list.cancel',
    confirm: 'department-list.confirm',
    externalIDExists: 'department-list.externalIdExists',
    internalServerError: 'department-list.internal-server-error',
    unauthorized: 'department-list.unauthorized',
  }

  companies: Company[] = null;
  establishments: any[] = null;

  constructor(
    public loader: LoaderService,
    private spinnerService: SpinnerService,
    private commonAPi: CommonApiService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    public messageService: MessageService,
    private EstablishmentsService: EstablishmentsService,
  ) { }

  ngOnInit(): void {
    if (this.sliderFormData[0].edit) {
      this.bindUserObject(this.sliderFormData[0].DeptData);
      if (this.sliderFormData[0].DeptData.is_from_api === true) {
        this.DeptForm.controls.CorrCode.disable();
      }

      if (this.sliderFormData[0].DeptData.is_active) {
        this.Establishment.setValidators([Validators.required]);
      } else {
        this.Establishment.clearValidators();
      }
      console.log(this.sliderFormData)
    }
    else {
      this.commonAPi.GetCompany().subscribe((companyList) => {
        this.companies = companyList;
      });
      this.commonAPi.GetHospitals().subscribe((EstablishList) => {
        this.establishments = EstablishList;
      });
    }
  }

  onSave() {
    if (this.DeptForm.valid) {
      if (this.sliderFormData[0].edit) {
        const obj = {
          id: this.sliderFormData[0].DeptData.id,
          external_code: this.DeptForm.controls.CorrCode.value,
          name: this.DeptForm.controls.DeptName.value,
          is_active: this.DeptForm.controls.status.value,
          hospital_id: this.DeptForm.controls.Establishment.value || 0,
          org_id: this.DeptForm.controls.Company.value
        };

        this.messageService.confirm(
          this.translate.instant(this.ehp.editConfirmationMessage).toString(),
          this.translate.instant(this.ehp.editConfirmationTitle).toString(),
          this.translate.instant(this.ehp.confirm).toString(),
          this.translate.instant(this.ehp.cancel).toString()
        ).subscribe((actionResult: boolean) => {
          if (actionResult) {
            this.spinnerService.show(true);
            this.EstablishmentsService.editDepartment(obj).subscribe(
              () => {
                this.messageService.success(
                  this.translate.instant(this.saveAlert['edit'])
                );
                this.spinnerService.show(false);
                this.loader.refreshState(true);
                this.dismiss();
              },
              (errorResponse: HttpErrorResponse) => {
                this.spinnerService.show(false);
                console.log(errorResponse.error.code);
                if (errorResponse.error.code == 422) {
                  this.messageService.alert(
                    this.translate.instant(this.ehp.externalIDExists),
                    10000
                  );
                } else if (errorResponse.error.code == 401) {
                  this.messageService.alert(
                    this.translate.instant(this.ehp.unauthorized),
                    10000
                  );
                }
                else {
                  this.messageService.alert(
                    this.translate.instant(this.ehp.internalServerError),
                    10000
                  );
                }
              }
            );
            return
          }
        });
      } else {
        const hospitalId = this.DeptForm.controls.Establishment.value || 0;
        const isActive = hospitalId !== 0 ? true : null;

        const obj = {
          external_code: this.DeptForm.controls.CorrCode.value,
          name: this.DeptForm.controls.DeptName.value,
          is_active: isActive, 
          hospital_id: hospitalId,
          org_id: this.DeptForm.controls.Company.value
        };
        this.EstablishmentsService.addDepartment(obj).subscribe(
          (data: any) => {
            data;
            this.loader.onFormClose();
            this.messageService.success(
              this.translate.instant(this.saveAlert['add']),
              5000
            );
            this.spinnerService.show(false);
            this.loader.refreshState(true);
            this.dismiss();
          },
          (errorResponse: HttpErrorResponse) => {
            this.spinnerService.show(false);
            if (errorResponse.error.code == 422) {
              this.messageService.alert(
                this.translate.instant(this.ehp.externalIDExists),
                10000
              );
            } else if (errorResponse.error.code == 401) {
              this.messageService.alert(
                this.translate.instant(this.ehp.unauthorized),
                10000
              );
            }
            else {
              this.messageService.alert(
                this.translate.instant(this.ehp.internalServerError),
                10000
              );
            }
          }
        );
      }
    }
  }

  bindUserObject(DeptData: any) {
    this.commonAPi.GetCompany().subscribe((companyList) => {
      this.companies = companyList;
      const companyExists = companyList.some(x => x.id === DeptData.CompanyId);
      if (companyExists) {
        this.DeptForm.patchValue({ Company: DeptData.CompanyId });
      } else {
        console.warn("Company ID not found in list:", DeptData.CompanyId);
      }
    });
    this.commonAPi.GetHospitals().subscribe((DeptList) => {
      this.establishments = DeptList;
      const establishmentExists = DeptList.some(x => x.id === DeptData.establishment_id);
      if (establishmentExists) {
        this.DeptForm.patchValue({ Establishment: DeptData.establishment_id });
      }
    });
    this.DeptForm.patchValue({
      DeptName: DeptData.name,
      status: DeptData.is_active,
      CorrCode: DeptData.external_code
    });
  }


  public dismiss() {
    this.loader.cancelForm();
  }
  reset() {
    this.DeptForm.reset();
    this.DeptForm.markAsUntouched();
  }
  public success(message: any): void {
    this.messageService.success(this.translate.instant(message));
  }
  public alert(error: any): void {
    this.messageService.alert(this.translate.instant(error), 10000);
  }

}
