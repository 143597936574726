import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../main';

@Component({
    selector: 'app-help-manual',
    templateUrl: './help-manual.component.html',
    styleUrls: ['./help-manual.component.scss']
})
export class HelpManualComponent implements OnInit {

    constructor(public loader: LoaderService) { }

    ngOnInit(): void {
        this.helpManualByRole();
    }

    helpManualByRole(){
        const path = this.loader.userRole == 1 ? '../../../assets/data/Receptionist_Manual_docx.pdf' : (this.loader.userRole == 5 ? '../../../assets/data/Admin_Handbook.pdf' : '');
        window.open(path, '_blank');
        this.loader.highlightLeftMenu([this.loader.lastSelectedRoute]);
    }
}

