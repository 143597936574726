import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../../features/login/login.component';
 
const routes: Routes = [{
  path: '', component: LoginComponent
},
{ path: 'login', component: LoginComponent },
{ path: 'password_reset/:user_id', component: LoginComponent },
{ path: 'password_reset/:user_id/:role_id', component: LoginComponent },
{ path: 'verify_email/:ve_user_id', component: LoginComponent },
{ path: 'account-confirmation/:ac_mail', component: LoginComponent },
{ path: 'set_password/:sp_u_id/:role_id', component: LoginComponent },
{ path: 'login/:bc_mail', component: LoginComponent},
{ path: 'document/:doc_id', component: LoginComponent},
{ path: 'relative_member', component: LoginComponent},
{ path: 'qr_code', component: LoginComponent},
{ path: 'result', component: LoginComponent},
{ path: 'ss/:org_id/:sampling_station_id/:action', component: LoginComponent},
{ path: 'ss/:org_id/:sampling_station_id/:action/:qr_id', component: LoginComponent},
{ path: 'ss/:org_id/:sampling_station_id/:action/:patient_id/:appointment_id', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
