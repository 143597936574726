import { Component, ComponentFactoryResolver, OnInit, ViewChild, } from '@angular/core';
import { LoaderService } from "../../../main";
import { AddEstablishmentComponent } from '../add-establishment/add-establishment.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CommonApiService } from '../../services/common-api.service';
import { SpinnerService } from '../../../core/spinner/spinner.service';

@Component({
  selector: 'app-establishement',
  templateUrl: './establishement.component.html',
  styleUrls: ['./establishement.component.scss']
})
export class EstablishementComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  search: String = '';
  searchinput = '';
  status = 'All';
  public columnList: string[] = ['name', 'departments', 'address', 'status', 'action'];

  filterObject = {
    search: '',
    status: ''
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;

  sampleData: { [key: string]: string } = {
    All: "All Statuses",
    Active: "department-list.active",
    Pending: "department-list.pending",
    Suspended: "department-list.inactive"    
  };

  uploadSliderContainer: any;

  savealert = {
    edit: 'admin.save-alert.edit',
    add: 'admin.save-alert.add',
    delete: 'admin.save-alert.delete'
  };

  constructor(
    public loader: LoaderService,
    private spinner: SpinnerService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private commonAPi: CommonApiService
  ) { }

  ngOnInit(): void {
    this.loadHospitals();

    this.loader.refreshDialog.subscribe((refresh) => {
      if (refresh) {
        this.loadHospitals();
        this.loader.refreshState(false);
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public loadHospitals() {
    this.commonAPi.GetAllHospitals().subscribe((hospitallist) => {

      hospitallist.sort((a: any, b: any) => a.name.localeCompare(b.name));

      this.dataSource.data = hospitallist;

      this.dataSource.filterPredicate = (data, filter) => {
        const filterObj = JSON.parse(filter);
        if (filterObj.search !== '' && filterObj.status == '') {
          return (
            String(data.id)
              .toLowerCase()
              .includes(filterObj.search.toLowerCase()) ||
            String(data.name)
              .toLowerCase()
              .includes(filterObj.search.toLowerCase()) ||
            String(data.external_code)
              .toLowerCase()
              .includes(filterObj.search.toLowerCase())
          );
        } else if (filterObj.search == '' && filterObj.status !== '') {
          return (
            String(data.is_active) === filterObj.status ||
            filterObj.status == 'All'
          );
        } else {
          return (
            (String(data.id)
              .toLowerCase()
              .includes(filterObj.search.toLowerCase()) ||
              String(data.name)
                .toLowerCase()
                .includes(filterObj.search.toLowerCase()) ||
              String(data.external_code)
                .toLowerCase()
                .includes(filterObj.search.toLowerCase())) &&
            (String(data.is_active) === filterObj.status ||
              filterObj.status == 'All')
          );
        }
      };
      this.spinner.show(false);
    });
  }


  addEstablishment() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      AddEstablishmentComponent
    );

    const data = { action: 'add', edit: false, savealert: this.savealert };

    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "55%" : "100%",
      [data]
    );

    this.loadHospitals();
  }

  editEstablishment(entity: any) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      AddEstablishmentComponent
    );

    const data = { action: 'edit', edit: true, EstablishData: entity, savealert: this.savealert };

    console.log(entity);

    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "55%" : "100%",
      [data]
    );

    this.loadHospitals();
  }

  onSearch() {
    if (!this.searchinput || this.searchinput.trim() === '') {
      this.dataSource.filter = '';
    } else {
      this.filterObject.search = this.searchinput;
      this.dataSource.filter = JSON.stringify(this.filterObject);
    }
  }

  clearSearch() {
    this.searchinput = ''; 
    this.onSearch(); 
  }

  public statusFilter() {
    if (this.status != 'All') {
      this.filterObject.status = this.status == 'Active' ? 'true' : 'false';
      this.dataSource.filter = JSON.stringify(this.filterObject);
    } else {
      this.filterObject.status = 'All';
      this.dataSource.filter = JSON.stringify(this.filterObject);
    }
  }

  unsorted() { }
  /**
  * This method will execute the when user will try to use space key keyboard and in the search box if the space is first character then
  * it will wont allow space to execute, It will block the space untill user enter a character.
  * @param event
  */
  keyDownHandler(event: Event) {
    if (this.searchinput.length === 0) {
      event.preventDefault();
    }
  }

  public getDepartmentNames(departments: { id: number; name: string }[]) {
    if (!departments || departments.length === 0) {
      return { firstDepartment: '', additionalCount: 0 };
    }

    if (departments.length === 1) {
      return { firstDepartment: departments[0].name, additionalCount: 0 };
    }

    const firstDepartment = departments[0].name;
    const additionalCount = departments.length - 1;

    return { firstDepartment, additionalCount };
  }

}
