import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ImageProcessorService {

  constructor() { }

  convertToGrayScale(base64Image: string): Promise<string> {
    const canvas = document.createElement('canvas');
    const image = new Image();

    return new Promise<string>((resolve, reject) => {
      image.onload = async () => {
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0);
        const imageData = ctx.getImageData(0, 0, image.width, image.height);
        const data = imageData.data;

        // Convert each pixel to grayscale
        for (let i = 0; i < data.length; i += 4) {
          const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
          data[i] = Math.min(255, avg + 50);  // Red
          data[i + 1] = Math.min(255, avg + 50); // Green
          data[i + 2] = Math.min(255, avg + 50); // Blue
        }

        // Put the grayscale image back to the canvas
        ctx.putImageData(imageData, 0, 0);

        // Convert the canvas content back to base64
        base64Image = canvas.toDataURL('image/jpeg');
        resolve(base64Image);
      };

      image.onerror = (error) => {
        reject(error);
      };

      image.src = base64Image;
    });
  }

}
