import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    constructor( public localeService: LocaleService, private translate: TranslateService, ) { }

    ngOnInit(): void {
    }
    changeLocale(locale: string) {
        this.translate.use(locale);
        this.localeService.setLocale(locale);
        // this.changeRecaptchaLanguage(locale)
    // this.selectedLanguage = locale;
    }
}
