<div class="document-container">
    <table class="ecl-table" width="100%" *ngIf="refresh" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>{{'documents.document-type' | translate}}
            </th>
            <td mat-cell *matCellDef="let element" class="flex-td">
                <div class="top-action">
                    <button *ngIf="!element.key_name" class="mat-tbl-icon" mat-icon-button
                        matTooltip="{{'documents.edit'|translate}}" matTooltipClass="tooltip-style"
                        (click)="editDocument(element)">
                        <span class="icon-ic-edit"></span>
                    </button>
                    <button *ngIf="!element.key_name && !(element.expiry_date && checkExpiry(element))"
                        class="mat-tbl-icon" mat-icon-button matTooltip="{{'documents.delete'|translate}}"
                        matTooltipClass="tooltip-style" (click)="confirmDeleteWithYesNo(element.id)">
                        <span class="icon-ic-delete transcol"></span>
                    </button>
                    <button *ngIf="!element.key_name && (element.expiry_date && checkExpiry(element))"
                        class="mat-tbl-icon red" mat-icon-button matTooltip="{{'documents.delete'|translate}}"
                        matTooltipClass="tooltip-style" (click)="confirmDeleteWithYesNo(element.id)">
                        <span class="icon-ic-delete transcol"></span>
                    </button>
                </div>
                <div class="avatar"><span class="titleWrapBox">{{element.document_type.charAt(0)}}</span></div>
                <div class="alert-box">
                    <span class="title onlyDesktop" *ngIf="element.document_type">{{element.document_type | translate}}
                        <span *ngIf="checkUploadPending(element)" class="pendingDoc">
                            {{'documents.document-warning' | translate}}</span>
                        <span *ngIf="element.expiry_date && checkExpiry(element)"
                            class="expired">{{'documents.expired'|translate}}
                        </span></span>
                    <div class="mobile-td onlyMobile">
                        <span class="title upload" *ngIf="element.document_type"><span
                                class="content">{{element.document_type |
                                translate}}
                                <span *ngIf="checkUploadPending(element)"
                                    class="pendingDoc">{{'documents.document-warning'
                                    |
                                    translate}}
                                </span>
                                <span *ngIf="element.expiry_date && checkExpiry(element)"
                                    class="expired">{{'documents.expired'|translate}}
                                </span>
                            </span>
                        </span>
                        <span class="form-value" *ngIf="element.description">{{element.description}}
                        </span>
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="fileDesc">
            <th mat-header-cell *matHeaderCellDef>{{'documents.file-description'|translate}} </th>
            <td mat-cell *matCellDef="let element" class="flex-td gap-25 hide-column">
                <div class="spacer"></div>
                <span class="title light" *ngIf="element.description">{{element.description}}</span>
            </td>
        </ng-container>
        <!-- <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>{{'documents.details'|translate}} </th>
            <td mat-cell *matCellDef="let element" class="flex-td gap-25">
                <div class="spacer"></div><span class="light">{{element.details}} </span>
            </td>
        </ng-container> -->
        <ng-container matColumnDef="dateTime">
            <th mat-header-cell *matHeaderCellDef>{{'documents.expiry'|translate}} </th>
            <td mat-cell *matCellDef="let element" class="flex-td gap-25">
                <div class="spacer"></div>
                <span *ngIf="element.expiry_date" class="flex-table-row"><span
                        class="onlyMobile">{{'documents.expiry'|translate}}</span><span
                        class="light">{{element.expiry_date
                        |
                        date:"dd/MM/yyyy"}}</span></span>
            </td>
        </ng-container>
        <ng-container matColumnDef="noRecord">
            <td colspan="5" mat-footer-cell *matFooterCellDef>
                <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
                <div class="desc">
                    <p>{{'documents.empty'|translate}}</p>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="force-display">
                <div class="mobile-action" [ngClass]="element.optional_insurance_id > 0 ? '' : 'fix-direction-column'">
                    <button *ngIf="!element.key_name" mat-button color="accent" class="ghost"
                        (click)="viewDocPdf.emit(element.document_path)"
                        type="button">{{'documents.view-document'|translate}}</button>
                    <button *ngIf="!element.key_name && element.optional_insurance_id > 0" mat-button color="accent"
                        class="ghost" (click)="uploadDocument($event, element)"
                        type="button">{{'documents.upload-more'|translate}}</button>
                    <button *ngIf="element.key_name" class="ghost" mat-button (click)="selectDocument(element)">
                        {{'documents.upload-document'|translate}}
                    </button>
                </div>
                <div class="action-btn">
                    <button *ngIf="!element.key_name" class="mat-tbl-icon"
                        id="view-document{{element?.document_type.replace(' ', '')}}" mat-icon-button
                        matTooltip="{{'documents.view-document'|translate}}" matTooltipClass="tooltip-style"
                        (click)="viewDocPdf.emit(element.document_path)">
                        <span class="icon-ic-view-prescription"></span>
                    </button>
                    <button *ngIf="!element.key_name && element.optional_insurance_id == 0" class="mat-tbl-icon"
                        id="edit-document{{element?.document_type.replace(' ', '')}}" mat-icon-button
                        matTooltip="{{'documents.edit'|translate}}" matTooltipClass="tooltip-style"
                        (click)="editDocument(element)">
                        <span class="icon-ic-edit"></span>
                    </button>
                    <button
                        *ngIf="!element.key_name && !(element.expiry_date && checkExpiry(element)) && element.optional_insurance_id == 0"
                        id="delete-document{{element?.document_type.replace(' ', '')}}" class="mat-tbl-icon"
                        mat-icon-button matTooltip="{{'documents.delete'|translate}}" matTooltipClass="tooltip-style"
                        (click)="confirmDeleteWithYesNo(element.id)">
                        <span class="icon-ic-delete transcol"></span>
                    </button>
                    <button
                        *ngIf="!element.key_name && (element.expiry_date && checkExpiry(element)) && element.optional_insurance_id == 0"
                        id="delete-document{{element?.document_type.replace(' ', '')}}" class="mat-tbl-icon red"
                        mat-icon-button matTooltip="{{'documents.delete'|translate}}" matTooltipClass="tooltip-style"
                        (click)="confirmDeleteWithYesNo(element.id)">
                        <span class="icon-ic-delete"></span>
                    </button>
                    <button *ngIf="!element.key_name && element.optional_insurance_id > 0" class="mat-tbl-icon"
                        mat-icon-button matTooltip="{{'documents.upload-more'|translate}}"
                        (click)="uploadDocument($event, element)">
                        <span class="icon-ic-add-prescription"></span>
                    </button>
                    <span *ngIf="element.key_name" class="uploadBtn"
                        id="upload-document{{element?.key.replace(' ', '')}}" mat-button
                        (click)="selectDocument(element)">
                        {{'documents.upload-document'|translate}}
                    </span>

                    <button class="mat-tbl-icon" matTooltip="{{'receptionist.more' | translate}}"
                        matTooltipClass="tooltip-style" mat-icon-button [matMenuTriggerFor]="userMenu"
                        #more="matMenuTrigger" *ngIf="element.optional_insurance_id > 0">
                        <span class="icon-ic_more"></span>
                    </button>
                    <mat-menu #userMenu="matMenu" class="table-popover" xPosition="before">
                        <div class="arrow-top"></div>
                        <div class="main">
                            <button mat-menu-item id="edit-document{{element?.document_type.replace(' ', '')}}"
                                (click)="editDocument(element)">{{'documents.edit'|translate}}</button>

                            <button mat-menu-item id="delete-document{{element?.document_type.replace(' ', '')}}"
                                (click)="confirmDeleteWithYesNo(element.id)">{{'documents.delete'|translate}}</button>
                        </div>
                    </mat-menu>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>