import { Component, EventEmitter, Input, OnInit, Output, OnChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MultiSelect } from '../models/multiple-select';

@Component({
    selector: 'app-multi-select-dropdown',
    templateUrl: './multi-select-dropdown.component.html',
    styleUrls: ['./multi-select-dropdown.component.scss']
})

export class MultiSelectDropdownComponent implements OnInit, OnChanges {
    /* #region Fields */
    @Input() fieldDisabled = false;
    @Input() isRequired = false;
    @Input() public selectedItems: MultiSelect[];
    @Input() public items: MultiSelect[];
    @Input() public placeHolder: string;
    @Input() public panelClassInput = '';
    @Input() public floatLabel = '';
    @Output() public selectedOptions = new EventEmitter();
    public IsChecked: boolean;
    @Input() public selectFormControl = new FormControl();
    @ViewChild('selector') selector: MatSelect;
    public searchTextboxControl = new FormControl();
    public filteredOptions: Observable<any[]>;

    private displayItems: MultiSelect[];
    /* #endregion */

    constructor() { }

    /* #region Public Methods */
    public ngOnInit(): void {
        if (this.placeHolder === undefined || this.placeHolder === null || this.placeHolder.trim() === '') {
            this.placeHolder = 'Common.Search.Placeholder';
        }
        this.filteredOptions = this.searchTextboxControl.valueChanges
            .pipe(
                startWith<string>(''),
                map(name => this._filter(name))
            );
    }

    public ngOnChanges(): void {
        this.selectFormControl.patchValue([]);
        this.searchTextboxControl.patchValue('');
    }
    public ngAfterViewInit(): void {
        (<any> this.selector).baseonselect = (<any> this.selector)._onSelect;
        (<any> this.selector)._onSelect = (ev: any) => {
            (<any> this.selector).baseonselect(ev, false);
        };
    }

    /** Method to handle Select All check-box */
    public handleSelectAll(): void {
        let count = 0;
        if (this.selectFormControl.value) {
            for (const displayItem of this.displayItems) {
                let flag = false;
                this.selectFormControl.value.forEach((item: MultiSelect) => {
                    if (item.id === displayItem.id) {
                        count++;
                        flag = true;
                    }
                });
                if (!flag) {
                    break;
                }
            }
            if (count === this.displayItems.length) {
                this.IsChecked = true;
            } else {
                this.IsChecked = false;
            }
        }
    }

    /** Method to handle de-select an option and to remove from selected list */
    public selectionChange(event: any): void {
        if (event.isUserInput && event.source.selected === false) {
            const index = this.selectedItems.findIndex((selectedItem: any) => selectedItem.id === event.source.value.id);
            this.selectedItems.splice(index, 1);
        }
    // console.log(event);
    }

    /** Method to support 'compareWith' when objects are used in mat-select */
    public equals(objOne: any, objTwo: any): boolean {
        return objOne && objTwo ? objOne.id === objTwo.id : objOne === objTwo;
    }

    /** Method to select all displayed options in the component */
    public selectAll(checkAll: any): void {
        if (!checkAll) {
            this.updateSelectedItems(this.displayItems);
        } else {
            this.displayItems.forEach((item: any) => {
                const index = this.selectedItems.findIndex((selectedItem: any) => selectedItem.id === item.id);
                if (index > -1) {
                    this.selectedItems.splice(index, 1);
                }
            });
        }
        // Setting the value to empty as the actual values are not reflecting in UI in Angular 10
        this.selectFormControl.patchValue([]);
        this.selectFormControl.patchValue(this.selectedItems);
    }

    /** Method to clear the search text */
    public clearSearch(): void {
        this.searchTextboxControl.patchValue('');
    }

    /** Method to handle close and emit the output */
    public close(opened: boolean): void {
        if (!opened.valueOf() && this.selectedItems !== undefined) {
            this.searchTextboxControl.patchValue('');
            this.setSelectedValues();
            this.selectedOptions.emit(this.selectFormControl.value);
        }
    }
    /* #endregion */

    /* #region Private Methods */
    /** Method to filter the list based on search text and to maintain existing selected values */
    private _filter(name: string): MultiSelect[] {
        const filterValue = name.toLowerCase();
        if (!this.selectedItems) {
            this.selectedItems = [];
        }
        // Set selected values to retain the selected checkbox state
        this.setSelectedValues();
        this.selectFormControl.patchValue(this.selectedItems);
        this.displayItems = this.items.filter(option => new RegExp(filterValue, 'gi').test(option.displayText));
        this.handleSelectAll();
        return this.displayItems;
    }

    /** Method to store selected values as the mat-select re-constructs everytime whenever list option changes */
    private setSelectedValues(): void {
        if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
            this.updateSelectedItems(this.selectFormControl.value);
        }
    }

    private updateSelectedItems(list: MultiSelect[]): void {
        list.forEach((item: any) => {
            if (!this.selectedItems.find((selectedItem: any) => selectedItem.id === item.id)) {
                this.selectedItems.push(item);
            }
        });
    }
    /* #endregion */
}
