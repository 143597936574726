import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BarcodeFormat } from '@zxing/library';
import { MessageService } from '../../../../main';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-scan-qr',
    templateUrl: './scan-qr.component.html',
    styleUrls: ['./scan-qr.component.scss']
})
export class ScanQrComponent {
    formatsEnabled: BarcodeFormat[] = [
        BarcodeFormat.ITF,
        BarcodeFormat.QR_CODE,
        BarcodeFormat.CODABAR,
        BarcodeFormat.CODE_128,
        BarcodeFormat.CODE_39,
        BarcodeFormat.CODE_93,
        BarcodeFormat.EAN_13,
        BarcodeFormat.DATA_MATRIX
    ];
    public beepAudio = new Audio("https://ecldevprescription.blob.core.windows.net/scanner/beep.mp3");

    constructor(private readonly dialogRef: MatDialogRef<ScanQrComponent>,
        private message: MessageService,
        private translate: TranslateService) { }

    scanSuccessHandler(event: any) {
        this.beep(event);
    }

    camerasNotFound(){
        this.message.alert(this.translate.instant('scan-component.camera-failure'))
    }

    scanError(){
        this.message.alert(this.translate.instant('scan-component.qr-failure'))
    }

    scanFailure(){
        this.message.alert(this.translate.instant('scan-component.scan-failure'))
    }


    beep(event?: any) {
        this.beepAudio.play();
        if(window.navigator.vibrate){
            window.navigator.vibrate(200);
        }
        this.dialogRef.close(event);
    }
}
