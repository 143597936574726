<div class="container">
    <div class="d-flex justify-content-between heading-box">
        <div class="d-flex align-items-center">
            <span class="heading">{{'ehp.healthcare-professionals.title' | translate}}</span>
        </div>

        <div class="d-flex right-box">
            <div id="statusfilter" class="status-filter">
                <mat-form-field floatLabel="never">
                    <mat-select placeholder="All" [(ngModel)]="status">
                        <mat-option *ngFor="let stepOption of sampleData | keyvalue: unsorted" (click)="statusFilter()"
                            [value]="stepOption.key">
                            {{stepOption.value | translate}}
                        </mat-option>

                    </mat-select>
                </mat-form-field>
            </div>

            <div id="inputsearch">
                <mat-form-field>
                    <mat-label>{{'ehp.search' | translate}}</mat-label>
                    <span *ngIf="!searchinput" class="icon-ic-Search mat-icon" matSuffix (click)="onSearch()"></span>
                    <span *ngIf="searchinput" class="icon-ic-clear mat-icon" matSuffix (click)="clearSearch()"></span>
                    <input [(ngModel)]="searchinput" matInput (keydown.enter)="onSearch()" />
                </mat-form-field>
            </div>

            <div class="divider"></div>
            <div class="mx-4">
                <button mat-raised-button color="primary" id="btn_add" class="add-hospital" (click)="addProfessional()">
                    {{'ehp.add-healthcare-professional' | translate}}
                </button>
            </div>
        </div>

    </div>
    <div class="main-container main-container-res">
        <table mat-table [dataSource]="dataSource"
            class="table-height input-min-spacing table-flex table-with-rounded-corners">



            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{'ehp.name' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
            </ng-container>

            <!--EHP Code-->
            <ng-container matColumnDef="healthcare professional code">
                <th mat-header-cell *matHeaderCellDef> {{'ehp.healthcare-Professional-Code' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{ element.external_code }} </td>
            </ng-container>

            <!-- healthcare professional ADELI Column -->
            <ng-container matColumnDef="healthcare professional ADELI">
                <th mat-header-cell *matHeaderCellDef> {{'ehp.healthcare-professional-ADELI' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{ element.adeli }} </td>
            </ng-container>

            <!-- healthcare professional RPPS Column -->
            <ng-container matColumnDef="healthcare professional RPPS">
                <th mat-header-cell *matHeaderCellDef>{{'ehp.healthcare-professional-RPPS' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{ element.rpps }} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> {{'ehp.status'| translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <span [ngClass]="{
                        'active': element.is_active,
                        'suspended': !element.is_active,
                        'pending': element.is_active==null 
                      }">
                        {{ element.is_active ? ('ehp.Active' | translate) : (element.is_active==null)?
                        ( 'ehp.Pending'| translate):('ehp.Suspended' | translate) }}
                    </span>
                </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button class="mat-tbl-icon" mat-icon-button>
                        <span class="icon-ic-edit" matTooltip="Edit" (click)="editProfessioanl(element)"></span>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnList"></tr>
            <tr mat-row *matRowDef="let row; columns: columnList;"></tr>
        </table>
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons="true">
        </mat-paginator>
    </div>
</div>