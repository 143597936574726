import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CommonApiService } from '../../services/common-api.service';
import { SpinnerService } from '../../../core/spinner/spinner.service';
import { LoaderService } from "../../../main";
import { MatPaginator } from '@angular/material/paginator';
import { EditDepartmentComponent } from '../edit-department/edit-department.component';

@Component({
  selector: 'app-list-department',
  templateUrl: './list-department.component.html',
  styleUrl: './list-department.component.scss'
})
export class ListDepartmentComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>();

  search: String = '';
  searchinput = '';
  status = '';
  public columnList: string[] = ['corrcode', 'deptname', 'company', 'establishment', 'status', 'action'];

  filterObject = {
    search: '',
    status: ''
  };

  savealert = {
    edit: 'department-list.save-alert.edit',
    add: 'department-list.save-alert.add',
    delete: 'department-list.save-alert.delete'
  };

  sampleData: { [key: string]: string } = {
    All: "All Statuses",
    Active: "department-list.active",
    Pending: "department-list.pending",
    Suspended: "department-list.inactive"    
  };

  constructor(
    private commonAPi: CommonApiService,
    public loader: LoaderService,
    private spinner: SpinnerService,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  ngOnInit(): void {
    this.loadDepartments();

    this.loader.refreshDialog.subscribe((refresh) => {
      if (refresh) {
        this.loadDepartments();
        this.loader.refreshState(false);
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  unsorted() { }

  onSearch() {
    if (!this.searchinput || this.searchinput.trim() === '') {
      this.dataSource.filter = '';
    } else {
      this.filterObject.search = this.searchinput;
      this.dataSource.filter = JSON.stringify(this.filterObject);
    }
  }

  clearSearch() {
    this.searchinput = ''; 
    this.onSearch(); 
  }

  public statusFilter() {
    console.log("Selected Status:", this.status);
  
    if (this.status === 'All') {
      this.filterObject.status = 'All';
      this.dataSource.filter = JSON.stringify(this.filterObject);
    } else if (this.status === 'Active') {
      this.filterObject.status = 'true';
    } else if (this.status === 'Suspended') {
      this.filterObject.status = 'false';
    } else if (this.status === 'Pending') {
      this.filterObject.status = null;
    }
  
    this.dataSource.filter = JSON.stringify(this.filterObject);
  } 
  
  public loadDepartments() {
    this.commonAPi.GetAllDepartments().subscribe((hospitallist) => {
      if (!hospitallist) return;
  
      hospitallist.sort((a: any, b: any) => a.name.localeCompare(b.name));
  
      this.dataSource.data = hospitallist;
  
      this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
        const filterObj = JSON.parse(filter);
        const searchValue = filterObj.search.toLowerCase();
        const statusFilter = filterObj.status;
        const dataStatus = String(data.is_active);
  
        if (statusFilter === 'All') {
          return (
            data.id.toString().toLowerCase().includes(searchValue) ||
            data.name.toLowerCase().includes(searchValue) ||
            data.external_code.toLowerCase().includes(searchValue)
          );
        }
  
        if (searchValue && !statusFilter) {
          return (
            data.id.toString().toLowerCase().includes(searchValue) ||
            data.name.toLowerCase().includes(searchValue) ||
            data.external_code.toLowerCase().includes(searchValue)
          );
        }
  
        if (!searchValue && statusFilter) {
          return dataStatus === statusFilter;
        }
  
        return (
          (data.id.toString().toLowerCase().includes(searchValue) ||
            data.name.toLowerCase().includes(searchValue) ||
            data.external_code.toLowerCase().includes(searchValue)) &&
          dataStatus === statusFilter
        );
      };
  
      this.spinner.show(false);
    });
  }
  

  addDepartment() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      EditDepartmentComponent
    );

    const data = { action: 'add', edit: false, savealert: this.savealert };

    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "55%" : "100%",
      [data]
    );

    this.loadDepartments();
  }

  editDepartment(entity: any) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      EditDepartmentComponent
    );

    const data = { action: 'edit', edit: true, DeptData: entity, savealert: this.savealert };

    console.log(entity);

    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "55%" : "100%",
      [data]
    );

    this.loadDepartments();
  }

}
