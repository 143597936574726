import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestData, SEOWrapper } from '../landing-page-config.model';
import { MessageService } from '../../../../main';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../../../../core';

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.scss']
})
export class SeoComponent implements OnInit {
  @Input() seoData: SEOWrapper;
  @Output() cancelClicked = new EventEmitter();
  @Output() updateData = new EventEmitter<RequestData>();

  constructor(private fb: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private spinner: SpinnerService) { }


  public seoForm: FormGroup = this.fb.group({
    html_tags: ['', [Validators.required]],
    meta_desc: ['', [Validators.required]]
  });

  ngOnInit(): void {
    this.bindData();
  }

  ngOnChanges() {
    this.bindData();
  }

  public bindData() {
    if (this.seoData && this.seoData.page_data) {
      const enFormData = {
        html_tags: this.seoData.page_data.html_tags,
        meta_desc: this.seoData.page_data.meta_desc

      };
      this.seoForm.patchValue(enFormData);
    }
  }

  save() {
    this.seoForm.markAllAsTouched();
    if (this.seoForm.valid) {
      this.openPopup();
    }
  }

  public openPopup() {
    let popupRef = this.messageService
      .confirm(
        this.translateService.instant('landing-page-config.save-confirm-description'),
        this.translateService.instant('landing-page-config.save-title'),
        this.translateService.instant('landing-page-config.yes'),
        this.translateService.instant('landing-page-config.no')
      );

    popupRef.subscribe((actionResult) => {
      if (actionResult) {
        this.spinner.show(true);
        let payload = {
          "tab_name": "seo",
          "page_data": JSON.stringify({ "html_tags": this.seoForm.value.html_tags, "meta_desc": this.seoForm.value.meta_desc }),
          "image_data": [
            {
              "image_url": "",
              "image_data": ""
            }
          ]
        }
        this.updateData.emit(payload);
      }
    });
  }
}
