import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SpinnerService } from '../../../core';
import { LocaleService } from '../../../app.locale.service';
import { TranslateService } from '@ngx-translate/core';
import { Slot } from './date-slot.model';
import { CommonApiService } from '../../services/common-api.service';
import { LoaderService, MessageService } from "../../../main";
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-date-slot',
  templateUrl: './date-slot.component.html',
  styleUrls: ['./date-slot.component.scss']
})
export class DateSlotComponent implements OnInit {

  @Input() calendarDates: Array<Slot>;
  @Input() s_id: string;
  @Input() test_id: string;
  @Input() fromBookingFlow: boolean = false;
  @Output() fromModalEvent = new EventEmitter();
  @Output() goBack = new EventEmitter<any>();
  @Output() goNext = new EventEmitter();
  protected filteredDates: Array<any> = [];
  protected localeLang: any;
  private subscription: Subscription = new Subscription();
  public percent: number;
  public calendar_id: number = 1;
  public slotSelected: boolean = false;
  selectedSlotIndex: number | null = null;
  selectedSlot: { panelIndex: number; slotIndex: number } | null = null;
  constructor(private spinner: SpinnerService,
    private localeService: LocaleService,
    public loader: LoaderService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService, public commonAPi: CommonApiService) {
    this.localeLang = this.localeService.localeLang;
    this.translate.use(this.localeLang);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.loader.variable$.subscribe(value => {
        this.percent = (value / this.loader.flow_arr.length) * 100;
        this.cdr.detectChanges(); // Manually trigger change detection
      })
    );
    //this.totalLength = this.calendarDates?.length;
    this.calendarDates.forEach((element: Slot) => {
      element.date = new Date(element.date);
      element.filterSlots = new Array<string>();
      this.updateDisplayedRecords(element);
    });
    this.filteredDates = this.calendarDates;
    this.filteredDates?.forEach((element: any) => {
      element.filterSlots?.forEach((fs: any) => {
        fs.isSelected = false;
      });
    });
    this.spinner.show(false);
  }

  updateDisplayedRecords(date: any): void {
    if (this.isDesktop()) {
      date.filterSlots = date?.slots.slice(0, date?.filterSlots?.length + 18);
    } else if (this.isMobile()) {
      date.filterSlots = date?.slots.slice(0, date?.filterSlots?.length + 9);
    }
  }

  isDesktop(): boolean {
    return window.innerWidth >= 962;
  }

  isMobile(): boolean {
    return window.innerWidth < 962;
  }

  viewMore(date: any) {
    this.updateDisplayedRecords(date);
  }

  viewMoreDays() {
    this.spinner.show(true);
    let length = this.filteredDates.length;
    let nextDate = this.filteredDates[length - 1].date;

    nextDate.setDate(nextDate.getDate() + 1);
    const year = nextDate.getFullYear();
    const month = String(nextDate.getMonth() + 1).padStart(2, "0");
    const day = String(nextDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    this.commonAPi
      .GetSlots(this.s_id, this.test_id, 7, formattedDate)
      .subscribe((x) => {
        x;
        let slotData = x;
        this.calendar_id = slotData?.calendar_id;
        if (this.calendar_id > 0) {
          slotData.slots.forEach((element: any) => {
            element.date = new Date(element.date);
            element.filterSlots = new Array<string>();
            this.updateDisplayedRecords(element);

          });

          this.filteredDates.push(...slotData.slots);
        }
        this.spinner.show(false);

        //this.filteredDates += slotData.slots;
      }, () => {
        this.spinner.show(false);

      });
    //this.filteredDates = this.calendarDates.slice(0, this.filteredDates?.length + 7)
  }

  selectSlot(slot: any, slotIndex: number, panelIndex: number) {
    this.selectedSlot = { panelIndex, slotIndex };
    this.fromModalEvent.emit(slot);
    this.slotSelected = true;
  }

  public goToBack() {
    this.goBack.emit(true);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  goToNext() {
    if (!this.slotSelected) {
      this.messageService.alert(
        this.translate.instant("date-slot.select-slot-error"),
        5000
      );
    }
    else {
      this.goNext.emit(true);
    }
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
}

