<div class="container">
    <div class="d-flex justify-content-between heading-box">
        <div class="d-flex align-items-center">
            <span class="heading">{{'establishment-list.establishment'|translate}}</span>
        </div>

        <div class="d-flex right-box">
            <div id="statusfilter" class="status-filter">
                <mat-form-field floatLabel="never">
                    <mat-select placeholder="All Statuses" [(ngModel)]="status">
                        <mat-option *ngFor="let stepOption of sampleData | keyvalue: unsorted" (click)="statusFilter()"
                            [value]="stepOption.key">
                            {{stepOption.value| translate }}
                        </mat-option>

                    </mat-select>
                </mat-form-field>
            </div>

            <div id="inputsearch">
                <mat-form-field>
                    <mat-label>{{'establishment-list.search' | translate}}</mat-label>
                    <span *ngIf="!searchinput" class="icon-ic-Search mat-icon" matSuffix (click)="onSearch()"></span>
                    <span *ngIf="searchinput" class="icon-ic-clear mat-icon"  matSuffix (click)="clearSearch()"></span>
                    <input [(ngModel)]="searchinput" matInput (keydown.enter)="onSearch()" />
                </mat-form-field>
            </div>

            <div class="divider"></div>

            <div>
                <button mat-raised-button color="primary" id="btn_add" (click)="addEstablishment()"
                    class="add-hospital">
                    + {{'establishment-list.add'|translate}}
                </button>
            </div>
        </div>

    </div>
    <div class="main-container">
        <table mat-table [dataSource]="dataSource" 
            class="table-height input-min-spacing table-flex table-with-rounded-corners">

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{'establishment-list.name'|translate}} </th>
                <td mat-cell *matCellDef="let element"> <span class="text-break">{{ element.name }}</span> </td>
            </ng-container>

            <!-- Departments Column -->
            <ng-container matColumnDef="departments">
                <th mat-header-cell *matHeaderCellDef> {{'establishment-list.department'|translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-column">
                        <span class="text-break">{{ getDepartmentNames(element.departments).firstDepartment }}</span>
                        <ng-container *ngIf="getDepartmentNames(element.departments).additionalCount > 0">
                            <span class="view-more" [matMenuTriggerFor]="menu">
                                +{{ getDepartmentNames(element.departments).additionalCount }}
                                {{'establishment-list.more'|translate}}
                            </span>
                        </ng-container>
                    </div>


                    <!-- Mat Menu for the additional departments -->
                    <mat-menu #menu="matMenu" class="establish-popover">
                        <div class="arrow-top"></div>
                        <span mat-menu-item *ngFor="let dept of element.departments">
                            {{ dept.name }}
                        </span>
                    </mat-menu>
                </td>
            </ng-container>


            <!-- Address Column -->
            <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef> {{'establishment-list.address'|translate}} </th>
                <td mat-cell *matCellDef="let element"> {{ element.address || 'N/A' }} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> {{'establishment-list.status'|translate}} </th>
                <td mat-cell *matCellDef="let element">                    
                    <span class="status" [ngClass]="{
                        'active': element.is_active === true,
                        'inactive': element.is_active === false,
                        'pending': element.is_active === null
                      }">
                        {{ 
                          element.is_active === true ? ('establishment-list.active'|translate) : 
                          (element.is_active === false ? ('establishment-list.suspended'|translate) : 
                          ('establishment-list.pending'|translate))
                        }}
                      </span>                      
                </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <button class="mat-tbl-icon" (click)="editEstablishment(element)" mat-icon-button>
                        <span class="icon-ic-edit" matTooltip="{{'establishment-list.edit'|translate}}"></span>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnList"></tr>
            <tr mat-row *matRowDef="let row; columns: columnList;"></tr>
        </table>
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons="true">
        </mat-paginator>
    </div>
</div>