import { Component, Inject, Input, OnInit } from '@angular/core';
import { validateTime } from '../../service/time-field-validator';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonApiService } from '../../../services/common-api.service';
import { MessageService } from '../../../../main';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../../../../core';

import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { MasterData, SSTest } from '../ss-slot-configuration.model';
import { LoginServiceService } from '../../../services/login-service.service';
import { BookingAppointmentGuestService } from '../../../services/booking-appointment-guest.service';

@Component({
  selector: 'app-add-unavailability',
  templateUrl: './add-unavailability.component.html',
  styleUrls: ['./add-unavailability.component.scss']
})
export class AddUnavailabilityComponent implements OnInit {
  public title: string = "add-unavailability.edit-title";
  public titleMaxLength: number = 100;
  public reasonMaxLength: number = 500;
  public StartDate = new FormControl();
  public EndTime = new FormControl("");
  public StartTime = new FormControl("");
  public editMode: boolean = false;
  //public FormType = new FormControl("0");
  public Comment = new FormControl();
  @Input() tests: Array<SSTest>;
  @Input() samplingStation: any;
  @Input() selectedDateTime: Date;
  public occurences: Array<MasterData>;

  public fromMinDate: Date = new Date();

  public selectedDow: any = {};

  public saveAlert = {
    edit: "admin.save-alert.edit",
    add: "admin.save-alert.add"
  };
  public UnavailabilityForm: FormGroup =
    this.fb.group({
      TestIds: ['', [Validators.required]],
      StartDate: [this.StartDate.value, [Validators.required]],
      StartTime: [this.StartTime.value, [Validators.required]],
      EndTime: [this.EndTime.value, [Validators.required]],
      SlotTitle: ['', [Validators.required]],
      Comment: [this.Comment.value, [Validators.maxLength(this.reasonMaxLength)]],
    });
  configuredData: any;
  constructor(private readonly dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService,
    private commonAPi: CommonApiService, private messageService: MessageService, private fb: FormBuilder,
    private spinner: SpinnerService, private loginService: LoginServiceService, public bookingAppointmentService: BookingAppointmentGuestService) {
    this.occurences = new Array<MasterData>();
  }

  ngOnInit(): void {
    this.getOccurence();
    if (this.data?.edit) {
      this.editMode = true;
      this.samplingStation = this.data?.samplingStation;
      this.selectedDateTime = this.data.selectedDateTime;
      this.configuredData = this.data?.ConfiguredData;
      this.UnavailabilityForm.controls["SlotTitle"].setValue(this.data?.ConfiguredData?.slot_name);
      this.UnavailabilityForm.controls["Comment"].setValue(this.data?.ConfiguredData?.unavilable_reason);
      this.getAllTest();
    }

    this.setDowTime(this.selectedDateTime);
    this.StartDate.setValue(new Date(this.selectedDateTime.getFullYear(), this.selectedDateTime.getMonth(), this.selectedDateTime.getDate()));
    this.UnavailabilityForm.controls["StartDate"].setValue(new Date(this.selectedDateTime.getFullYear(), this.selectedDateTime.getMonth(), this.selectedDateTime.getDate()));
  }

  getAllTest() {
    this.bookingAppointmentService.getAdmintests(this.data?.ConfiguredData?.ss_id).subscribe(
      (response: any) => {
        this.tests = response;

        const commonTests: SSTest[] = this.tests
          .map(item => item)
          .filter(id => this.data?.ConfiguredData?.tests.some((obj: any) => obj.test_id === id.id));

        this.UnavailabilityForm.controls["TestIds"].setValue(commonTests);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public getOccurence() {
    this.spinner.show(true);
    this.loginService.getOccurenceMasters().subscribe((data: Array<any>) => {
      this.occurences = data.sort((a, b) => a.KeyOrder - b.KeyOrder);
      this.spinner.show(false);
    });
  }
  validateStartTimeRange() {
    //let form = this.FormType.value == '1' ? this.UnavailabilityForm : this.SlotGroupForm;
    validateTime(this.UnavailabilityForm, this.selectedDow, this.messageService, this.translateService);
  }

  onDateChange() {
    this.setDowTime(new Date(this.StartDate.value));
  }

  private setDowTime(date: Date) {
    this.selectedDateTime = date;
    let selectedDay = this.selectedDateTime.getDay();
    let segStartDate = new Date(date);
    let segEndDate = null;
    if (this.data?.edit) {
      segEndDate = this.data?.selectedEndDateTime;
    }
    else {
      segEndDate = new Date(segStartDate.getTime() + 30 * 60 * 1000);
    }


    let startTime = this.getTimeString(segStartDate);
    let endTime = this.getTimeString(segEndDate);
    let dowIndex = this.samplingStation.opening_and_closing_details.findIndex((i: any) => i.day == selectedDay);
    if (dowIndex > -1) {
      this.selectedDow = this.samplingStation.opening_and_closing_details[dowIndex];
    }
    this.UnavailabilityForm.controls["StartTime"].setValue(startTime);
    this.UnavailabilityForm.controls["EndTime"].setValue(endTime);

  }

  private getTimeString(date: Date) {
    return date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + ":00";
  }

  onSaveClick() {
    if (this.UnavailabilityForm.valid) {
      let popupRef = this.messageService
        .confirm(
          this.translateService.instant('landing-page-config.save-confirm-description'),
          this.translateService.instant('landing-page-config.save-title'),
          this.translateService.instant('landing-page-config.yes'),
          this.translateService.instant('landing-page-config.no')
        );

      popupRef.subscribe((actionResult) => {
        if (actionResult) {
          this.addUnavailability();
        }
      });
    }
  }

  public addUnavailability() {
    const datePipe = new DatePipe('en-US');
    let start_date = datePipe.transform(this.StartDate.value, 'yyyy-MM-dd');
    let payload = {
      "ss_id": this.samplingStation.id,
      "slot_name": this.UnavailabilityForm.get("SlotTitle").value,
      "start_date": start_date,
      "start_time": this.UnavailabilityForm.get("StartTime").value.split(":").length == 3 ? this.UnavailabilityForm.get("StartTime").value : this.UnavailabilityForm.get("StartTime").value + ":00",
      "end_time": this.UnavailabilityForm.get("EndTime").value.split(":").length == 3 ? this.UnavailabilityForm.get("EndTime").value : this.UnavailabilityForm.get("EndTime").value + ":00",
      "occurance": this.occurences.filter(i => i.Key == "DoesNotRepeat").map(i => i.Id)[0],
      "is_unavilability": true,
      "test_ids": this.UnavailabilityForm.get("TestIds").value.map((item: any) => item.id),
      "unavilable_reason": this.UnavailabilityForm.get("Comment").value
    }
    if (this.editMode) {
      payload["id"] = this.configuredData.id;
      this.commonAPi.updateUnavailiablity(payload).subscribe(
        () => {
          this.spinner.show(false);
          this.messageService.success(
            this.translateService.instant(this.saveAlert[this.data.action])
          );
          this.dialog.closeAll();
        },
        (errorResponse: HttpErrorResponse) => {
          this.spinner.show(false);
          console.log(errorResponse);
          if (errorResponse.error.code === 400 && errorResponse.error.internal_code == 3003) {
            this.messageService.alert(
              this.translateService.instant("add-unavailability.error.422")
            );
          }
          else {
            this.messageService.alert(
              this.translateService.instant("add-unavailability.error.422")
            );
          }
          this.dialog.closeAll();

        }
      );
    }
    else {

      this.commonAPi.addUnavailiablity(payload).subscribe(
        () => {
          this.spinner.show(false);
          this.messageService.success(
            this.translateService.instant(this.saveAlert[this.data.action])
          );
          this.dialog.closeAll();

        },
        (errorResponse: HttpErrorResponse) => {
          this.spinner.show(false);
          console.log(errorResponse);
          if (errorResponse.error.code === 400 && errorResponse.error.internal_code == 3003) {
            this.messageService.alert(
              this.translateService.instant("add-unavailability.error.422")
            );
          }
          else {
            this.messageService.alert(
              this.translateService.instant("add-unavailability.error.422")
            );
          }
          this.dialog.closeAll();
        }
      );
    }
  }

  clearUnavailaibilityForm() {
    this.UnavailabilityForm.controls["SlotTitle"].setValue("");
    this.UnavailabilityForm.controls["TestIds"].setValue([]);
    this.UnavailabilityForm.controls["Comment"].setValue("");
  }
}
