<div class="doc-selection-container"
    [ngClass]="loader.loggedIn ? 'doc-selection-container-login' : 'doc-selection-container-guest'">
    <div class="section-header" [ngClass]="loader.loggedIn ? 'section-header-login' : 'section-header-guest'">
        <circle-progress class="only-web" [options]="loader.optionsG" [percent]="percent" [renderOnClick]="false"
            imageSrc="assets/images/application-logos/svg/ic-sdocument.svg" class="copy">
        </circle-progress>
        <h3 class="desc-title">{{'doc-info.title' | translate}}</h3>
    </div>
    <div class="document-info-section"
        [ngClass]="loader.loggedIn ? 'document-info-section-login' : 'document-info-section-guest'">
        <div *ngIf="selectedLab" class="lab-name"><span class="location icon-ic-location"></span>
            {{selectedLab.sampling_station_name}}</div>
        <div class="doc-info-section">
            <div class="docs-section">
                <div class="id-doc">
                    <img src="/assets/images/application-logos/svg/ic-virtual.svg">
                    <div class="option-description">
                        <p class="option-title">{{'doc-info.health-insurance' | translate}}​</p>
                        <div class="desc">{{'doc-info.insurance-validity' | translate}}</div>
                    </div>
                </div>
                <div class="id-doc">
                    <img src="/assets/images/application-logos/svg/ic-idcard.svg">
                    <div class="option-description">
                        <p class="option-title">{{'doc-info.id-card' | translate}}​</p>
                        <div class="desc">{{'doc-info.id-validity' | translate}}</div>
                    </div>
                </div>
            </div>
            <div class="divider only-web"></div>
            <div class="pres-section">
                <div class="id-doc pres">
                    <div class="card-icon">
                        <img src="/assets/images/application-logos/svg/ic-pres.svg">
                    </div>
                    <div class="option-description pres-description">
                        <p class="option-title">{{'doc-info.prescription' | translate}}</p>
                        <div class="desc mb-10">{{'doc-info.pres-option' | translate}}​</div>
                        <div class="desc only-web"><span class="datahglt-top">
                                {{ (isStandy ? 'doc-info.note-standy' : 'doc-info.note') | translate}}
                            </span>
                            <span class="datahglt-top">
                                {{ (isStandy ? 'doc-info.note-standy-mid' : 'doc-info.note-mid') | translate }}</span>
                        </div>
                        <div class="desc only-web">{{ (isStandy ? 'doc-info.note-standy-last' : 'doc-info.note-last') |
                            translate }}</div>
                    </div>
                </div>
            </div>
            <div class="only-mobile pres-note desc">
                <p class="datahglt-top">
                    {{ (isStandy ? 'doc-info.note-standy' : 'doc-info.note') |translate }}
                    <span class="datahglt">{{ (isStandy ? 'doc-info.note-standy-mid' : 'doc-info.note-mid') | translate
                        }}</span>
                </p>
            </div>
            <div class="only-mobile pres-note desc pdbtm">{{ (isStandy ? 'doc-info.note-standy-last' :
                'doc-info.note-last') | translate }}</div>
        </div>
    </div>
    <div class="action-btn" [ngClass]="loader.loggedIn ? 'action-btn-login' : 'action-btn-guest'">
        <button mat-raised-button class="next-btn" [ngClass]="{'full-width':hideBack}" type="button"
            (click)="moveToNext()">
            {{'next' | translate}}
        </button>
        <button mat-raised-button class="back-btn" *ngIf="!hideBack" type="button" (click)="goToBack()">
            {{'back' | translate}}
        </button>
    </div>
</div>