import { Injectable } from '@angular/core';
import { LogPublisherConfig } from '../models/log-publisher-config.model';
import { ConfigService } from './config.service';

@Injectable()
/** Service to access App Settings based on configuration */
export class AppSettingsService {
    constructor(private readonly configService: ConfigService) { }

    /* #region Public Methods */
    /** Property to get base api url from config */
    public get baseApiUrl(): string {
        return this.configService.getConfig('applicationServiceUrl');
    }

    /** Property to get environment name from config */
    public get environmentName(): string {
        return this.configService.getConfig('environmentName');
    }
    public get adminBaseUrl(): string {
        return this.configService.getConfig('adminBaseUrl');
    }
    public get authBaseUrl(): string {
        return this.configService.getConfig('authBaseUrl');
    }
    public get patientBaseUrl(): string {
        return this.configService.getConfig('patientBaseUrl');
    }

    /** Property to get version from config */
    public get version(): string {
        return this.configService.getConfig('version');
    }

    /** Method to get logger config from config */
    public loggerConfiguration(): LogPublisherConfig[] {
        return this.configService.getConfig('logger-config');
    }
    /* #endregion */

    public get locale(): string {
        return this.configService.getConfig('locale');
    }

    public get otpRequired(): string {
        return this.configService.getConfig('otpRequired');
    }
    public get captchaRequired(): string {
        return this.configService.getConfig('captchaRequired');
    }

    public get automationEmail(): string {
        return this.configService.getConfig('automationEmail');
    }

    public get country(): string {
        return this.configService.getConfig('mapCountry');
    }

    public get mapCenterLat(): string {
        return this.configService.getConfig('mapCenterLat');
    }

    public get mapCenterLang(): string {
        return this.configService.getConfig('mapCenterLang');
    }

    public get mapCenterZoom(): string {
        return this.configService.getConfig('mapCenterZoom');
    }

    public get getEmployeeCount(): string {
        return this.configService.getConfig('employeeCount');
    }
    public get getIgnoreMenu(): string {
        return this.configService.getConfig('ignoreMenu');
    }

    public get getPatientPerDay(): string {
        return this.configService.getConfig('patientPerDay');
    }

    public get getLabCount(): string {
        return this.configService.getConfig('labCount');
    }

    public get getGoogleApiKey(): string {
        return this.configService.getConfig('WOOSMAP_MAPS_API_KEY');
    }
}
