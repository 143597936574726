import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

    constructor(private readonly translateService: TranslateService, private readonly dialogRef: MatDialogRef<ConfirmationModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }
    public dayDate: any[] = [];
    datePipe = new DatePipe("en-US");
    private getDay(detail: any) {
        let dayString = "";
        switch (detail) {
            case 1: {
                dayString = "admin.monday";
                break;
            }
            case 2: {
                dayString = "admin.tuesday";

                break;
            }
            case 3: {
                dayString = "admin.wednesday";

                break;
            }
            case 4: {
                dayString = "admin.thursday";

                break;
            }
            case 5: {
                dayString = "admin.friday";

                break;
            }
            case 6: {
                dayString = "admin.saturday";
                break;
            }
            case 7: {
                dayString = "admin.sunday";
                break;
            }
        }
        return dayString;
    }
    ngOnInit(): void {
        for (let index = 1; index < 8; index++) {
            const dayString = this.getDay(index);
            const oacds: any[] = [];
            this.data.station?.opening_and_closing_details.forEach((element: any) => {
                if (element.day == index) {
                    oacds.push(element);
                }
            });

            if (oacds.length > 0) {
                oacds.forEach((detail: any) => {
                    const stArr = detail.start_time.split(":");
                    const endArr = detail.end_time.split(":");
                    const startDate = new Date();
                    startDate.setHours(stArr[0]);
                    startDate.setMinutes(stArr[1]);
                    startDate.setSeconds(stArr[2]);
                    const endDate = new Date();
                    endDate.setHours(endArr[0]);
                    endDate.setMinutes(endArr[1]);
                    endDate.setSeconds(endArr[2]);
                    let opening_and_closing_string = "";
                    switch (detail?.break_begins) {
                        case "":
                            opening_and_closing_string =
                                this.datePipe.transform(startDate, "HH:mm") +
                                " - " +
                                this.datePipe.transform(endDate, "HH:mm");
                            break;
                        case "00:00:00":
                            opening_and_closing_string =
                                this.datePipe.transform(startDate, "HH:mm") +
                                " - " +
                                this.datePipe.transform(endDate, "HH:mm");
                            break;
                        case "00:00":
                            opening_and_closing_string =
                                this.datePipe.transform(startDate, "HH:mm") +
                                " - " +
                                this.datePipe.transform(endDate, "HH:mm");
                            break;
                        default:
                            const lunchStartArr = detail.break_begins.split(":");
                            var lunchStartDate = new Date();
                            lunchStartDate.setHours(lunchStartArr[0]);
                            lunchStartDate.setMinutes(lunchStartArr[1]);
                            lunchStartDate.setSeconds(lunchStartArr[2]);
                            const lunchEndArr = detail.break_ends.split(":");
                            var lunchEndDate = new Date();
                            lunchEndDate.setHours(lunchEndArr[0]);
                            lunchEndDate.setMinutes(lunchEndArr[1]);
                            lunchEndDate.setSeconds(lunchEndArr[2]);
                            opening_and_closing_string =
                                this.datePipe.transform(startDate, "HH:mm") +
                                " - " +
                                this.datePipe.transform(lunchStartDate, "HH:mm") +
                                " | " +
                                this.datePipe.transform(lunchEndDate, "HH:mm") +
                                " - " +
                                this.datePipe.transform(endDate, "HH:mm");
                            break;
                    }
                    this.dayDate.push(
                        {
                            day: this.translateService.instant(dayString),
                            isToday: new Date().getDay() == index,
                            time: opening_and_closing_string

                        }
                    )
                });
            }
            else {
                this.dayDate.push(
                    {
                        day: this.translateService.instant(dayString),
                        isToday: new Date().getDay() == index,
                        time: this.translateService.instant("lab-google.closed")

                    })
            }
        }
    }

    public dismiss(): void {
        this.dialogRef.close(false);
    }

    public confirmed(): void {
        this.dialogRef.close(true);
    }

}
