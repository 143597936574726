import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgOtpInputConfig } from 'ng-otp-input';
import { AuthenticationService } from '../../services/authentication.service';
import { MessageService } from '../../../main';
import { TranslateService } from '@ngx-translate/core';
import { FindAccountResponse } from '../find-my-account/find-my-account.model';
import { PatientService } from '../../services/patient.service';

@Component({
  selector: 'app-verify-my-account',
  templateUrl: './verify-my-account.component.html',
  styleUrls: ['./verify-my-account.component.scss']
})
export class VerifyMyAccountComponent implements OnInit {
  public display: any;
  public btndisabled = true;
  public title: string = 'verify-my-account.title';
  public step: number = 1;
  public selectedAccountId = new FormControl('');
  public selectedAccount: FindAccountResponse;

  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
  };
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  public verifyID: any;
  public otp: string;
  public patientData: any;

  constructor(
    private readonly dialogRef: MatDialogRef<VerifyMyAccountComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService,
    private messageService: MessageService,
    private translate: TranslateService,
    public patientService: PatientService) { }

  ngOnInit(): void {
    this.selectedAccount = this.data?.dynamicData[0];

    if (!this.selectedAccount?.email) {
      this.title = "verify-my-account.phone-title"
    }
    if (this.data?.dynamicData?.length == 1) {
      this.selectedAccount = this.data?.dynamicData[0];
      this.selectedAccountId.setValue(this.selectedAccount.id);
    }
  }



  // verifyOTP() {
  //   this.dialogRef.close(null);
  // }

  public sendOTP() {

    const req = {
      id: this.selectedAccount.unique_id,
      email: "",
      ipp_id: this.selectedAccount.id
    };

    this.authService.sendOTP(req).subscribe(
      (response) => {
        this.verifyID = response.verify_id
        this.timer();
        this.step = 2;
        this.title = 'verify-my-account.otp-entermessage';
      },
      (err: {
        error: { code: any; message: string };
        message: any;
        code: any;
      }) => {
        if (err.error.code == 422) {
          // this.messageService.alert(
          //   this.translate.instant(this.passwordErrorMessage)
          // );
        }
        else {
          this.messageService.alert(err.error.message);
        }
      });
  }

  public onOtpChange(otp: string) {
    this.otp = otp;
  }

  public verifyOTP() {
    const updateEmailData = {
      id: this.selectedAccount.unique_id,
      email: "",
      ipp_id: this.selectedAccount.id,
      otp: this.otp,
      verify_id: this.verifyID
    };

    this.authService.verifyOTP(updateEmailData).subscribe(
      (response: FindAccountResponse) => {
        this.selectedAccount.id = response.id;
        this.getPatientInfo(response.id);
      },
      (err: {
        error: { code: any; message: string };
        message: any;
        code: any;
      }) => {
        if (err.error.code == 422) {
          // invalid OTP error message
          this.messageService.alert(
            this.translate.instant("login.invalid-otp")
          );
        } else {
          this.messageService.alert(err.error.message);
        }

      });
  }

  accountSelectionChange() {
    if (this.selectedAccountId.value) {
      this.selectedAccount = this.data.dynamicData.find((i: any) => i.id == this.selectedAccountId.value);
    }
  }

  timer() {
    const minute = 5;
    let seconds: number = minute * 60;
    let textSec = 0;
    let statSec = 60;
    const prefix = minute < 10 ? "0" : "";
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) {
        statSec--;
      } else {
        statSec = 59;
      }
      if (statSec < 10) {
        textSec = 0 + statSec;
      } else {
        textSec = statSec;
      }
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec
        .toString()
        .padStart(2, "0")}`;
      if (seconds == 0) {
        clearInterval(timer);
        this.btndisabled = false;
      }
    }, 1000);
  }

  getPatientInfo(id: string) {
    this.patientService.getPatientInfo(id).subscribe((response: any) => {
      this.patientData = response;
      this.dialogRef.close({ verified: true, otp: this.otp, data: response, unique_id: id, verify_id: this.verifyID });
    });
  }
}



