<mat-form-field class="date-field" [class.highlighted-field]="highlighted" (click)="onDateFieldClicked()">
    <input matInput [matDatepicker]="picker" [formControl]="dateTimeValue" placeholder='{{datePlaceholder | translate}}'
        #dateInput [min]="min" [max]="max" (dateChange)="onDatePicked($event)" (focus)="onDateInputFocused()"
        (focusout)="highlighted = false" (keydown.Tab)="picker.close()" (keydown.Shift.Tab)="picker.close()" [readonly]="isDisable" [disabled]="isDisable">
    <mat-datepicker #picker></mat-datepicker>
    <mat-datepicker-toggle matSuffix [for]="picker" class="ml-10 date-field-toggle-button">
        <span class="icon-ChangeDate calendar-button" matDatepickerToggleIcon></span>
    </mat-datepicker-toggle>
</mat-form-field>

<mat-form-field class="hour-field" [class.highlighted-field]="highlighted">
    <input type="text" #hourAutoInput placeholder='{{hourPlaceholder | translate}}' aria-label="Hour" matInput
        [formControl]="hourControl" [matAutocomplete]="hourAuto" (change)="onHourChanged()" (focus)="highlighted = true"
        (focusout)="highlighted = false"  [readonly]="isDisable">
    <mat-autocomplete class="auto-complete-width" #hourAuto="matAutocomplete" (optionSelected)="onHourSelected()"
        (closed)="onAutoCompleteClose()" >
        <mat-option *ngFor="let option of filteredHours | async" [value]="option">
            {{option}}
        </mat-option>
    </mat-autocomplete>
    <span matSuffix class="icon-Down-arrow"></span>
    <span matSuffix> :</span>
</mat-form-field>

<mat-form-field class="minute-field" [class.highlighted-field]="highlighted">
    <input type="text" #minuteAutoInput placeholder='{{minutePlaceholder | translate}}' aria-label="Minutes" matInput
        [formControl]="minuteControl" [matAutocomplete]="minuteAuto" (change)="onMinuteChanged()"
        (focus)="highlighted = true" (focusout)="highlighted = false"  [readonly]="isDisable">
    <mat-autocomplete class="auto-complete-width" #minuteAuto="matAutocomplete" (optionSelected)="onMinuteSelected()"
        (closed)="onAutoCompleteClose()">
        <mat-option *ngFor="let option of filteredMinutes | async" [value]="option">
            {{option}}
        </mat-option>
    </mat-autocomplete>
    <span matSuffix class="icon-Down-arrow"></span>
    
</mat-form-field>

<button *ngIf="isDisplayReset" mat-button color="accent" class="reset-button"
    (click)="setDateValue(true)" i18n="@@Common.TimePicker.Reset">Reset</button>