<mat-form-field
    [ngStyle]="{'padding-bottom' : dateValue.errors?.matDatepickerMin && (dateValue.dirty || dateValue.touched) ? '5px' : '0px' }"
    class="datepicker-container">
    <mat-label class="label-float">{{labelText}}</mat-label>
    <input matInput [class]="elementClass" #inputEffectiveDate [matDatepicker]="datePicker" [formControl]="dateValue"
        placeholder="dd-mm-yyyy" [id]="elementId" (dateChange)="onDateValueChange($event)"
        (dateInput)="onDateEditChange($event)" [min]="minDate" [max]="maxDate" (keydown.Tab)="datePicker.close()"
        (keydown.Shift.Tab)="datePicker.close()" [required]="isRequired" [disabled]="isDisabled" [readonly]="isReadOnly"
        [matDatepickerFilter]="myFilter">
    <mat-datepicker-toggle matSuffix [for]="datePicker"><span matDatepickerToggleIcon
            class="icon-ic-calender"></span></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
    <mat-error *ngIf="isRequiredError" class="color-error">
        <span *ngIf="dateValue.value==null || dateValue.value==''">{{labelText}}{{'admin.required' | translate }}</span>
    </mat-error>
    <mat-error *ngIf="dateValue.errors?.matDatepickerMin && (dateValue.dirty || dateValue.touched)" class="color-error">
        {{minDateViolationMessage}}
    </mat-error>
    <mat-error *ngIf="dateValue.errors?.matDatepickerMax && (dateValue.dirty || dateValue.touched)" class="color-error">
        {{maxDateViolationMessage}}
    </mat-error>
</mat-form-field>