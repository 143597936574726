import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../../core/config/config.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-power-bi-report',
  templateUrl: './power-bi-report.component.html',
  styleUrls: ['./power-bi-report.component.scss']
})
export class PowerBiReportComponent implements OnInit {
  embedLink: SafeResourceUrl;

  constructor(private readonly configService: ConfigService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.initialisePowerBISrc();
  }

  initialisePowerBISrc() {
    this.embedLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.configService.getConfig('embedLink'))
  }

}
