export class PatientAppointment {
    PatientID: string;
    AppointmentId: number;
    TestUniqueId: number;
    Status: string;
    StatusId: number;
    CalendarId: number;
    Capacity: number;
    TestId: number;
    PrescriptionUrl: string;
    TestTypeId: number;
    TestShortName: string;
    TestLongName: string;
    Description: string;
    ExternalCode: string;
    TestType: string;
    SampleId: number;
    SlotTime?: any;
    OldSlotTime?: any;
    PhoneNumber: string;
    Email: string;
    BirthName: string;
    FirstName: string;
    LastName: string;
    SamplingStationName: string;
    SamplingStationId: number;
    TestResult: string;
    LabArrivedTime: string;
    InternetAccessId: string;
    AddressDetails: string;
    SSN: string;
    DOB: Date;
    Passport: string;
    EtwsProcessedTime: string;
    ArrivedTime: string;
    QrId: string;
    PatientEmail: string;
    LabExternalId: string;
    SlotTimeInCET?: any;
    IsTestKitRequired: boolean;
    IsTestResultRequired: boolean;
    Barcode: string;
    SamplingTime: Date;
}


export class AntigenPCRTest {
    id: number;
    short_name: string;
    long_name: string;
    description: string;
    test_type_id: number;
    test_type: string;
    external_code: string;
    is_active: boolean;
    prescription: boolean;
    appointment: boolean;
    is_test_kit_required: boolean;
    is_test_result_required: boolean;
    url: string;
    file_name: string;
}
