import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    DataUrl,
    DOC_ORIENTATION,
    NgxImageCompressService
} from 'ngx-image-compress';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { SpinnerService } from '../../../core';
import { FileData } from '../../../features/styles/models/file-data';
import { LoaderService, MessageService } from '../../../main';
import { CommonApiService } from '../../services/common-api.service';
import { PatientService } from '../../services/patient.service';
import { ScanQrComponent } from '../find-patient/scan-qr/scan-qr.component';
import { ConfirmResultComponent } from './confirm-result/confirm-result.component';
import { Location } from '@angular/common';

@Component({
    selector: 'app-register-result',
    templateUrl: './register-result.component.html',
    styleUrls: ['./register-result.component.scss']
})
export class RegisterResultComponent implements OnInit {
    selectedPageIndex = 0;
    testKitId = '';
    public imageCapture = false;
    public imageCaptured = false;
    private trigger: Subject<void> = new Subject<void>();
    public errors: WebcamInitError[] = [];
    public prescriptionData: string | ArrayBuffer;
    selectedPatient: any = null;
    kitResults: any[];
    selectedResult: string;
    testKitScanerror = false;
    protected navigationDetails: any;
    testKitData: any;

    constructor(
        private readonly dialog: MatDialog,
        public patientService: PatientService,
        private imageCompress: NgxImageCompressService,
        public loader: LoaderService,
        private readonly messageService: MessageService,
        public commonApi: CommonApiService,
        public translate: TranslateService,
        public router: Router,
        public spinner: SpinnerService,
        private location: Location
    ) {
        this.navigationDetails = this.location.getState();
        if (this.navigationDetails && this.navigationDetails?.testKitData) {
            this.testKitData = this.navigationDetails?.testKitData;
            this.testKitId = this.testKitData.barcode;
        }
    }

    ngOnInit(): void {}

    kitResultObject(result: number) {
        return {
            id: Number(this.selectedPatient.id),
            sample_result: result,
            samplekit_photo_data: this.prescriptionData
        };
    }

    openConfirmation(type: string) {
        this.selectedResult = type;
        this.dialog
            .open(ConfirmResultComponent, {
                data: { result: type, kitId: this.testKitId }
            })
            .afterClosed()
            .subscribe((option: any) => {
                if (option) {
                    if (!this.imageCaptured) {
                        this.messageService.warning(
                            this.translate.instant('register-result.image-not-taken-error')
                        );
                    } else {
                        this.spinner.show(true);
                        this.commonApi.getKitResults().subscribe((r) => {
                            this.kitResults = r;
                            const kitResult = this.kitResults.filter((kr) => kr.Key == type);
                            this.patientService
                                .putPatientAntigenResult(
                                    this.kitResultObject(kitResult[0].Id)
                                )
                                .subscribe(
                                    (response) => {
                                        response;
                                        this.spinner.show(false);
                                        this.messageService.success(
                                            this.translate.instant('register-result.result-success')
                                        );
                                        this.selectedPageIndex = 0;
                                    },
                                    (error) => {
                                        this.spinner.show(false);
                                        this.messageService.warning(error['message'], 5000);
                                        this.selectedPageIndex = 0;
                                    }
                                );
                        });
                    }
                }
            });
    }

    scanQR() {
        this.dialog
            .open(ScanQrComponent, {
                width: '500px',
                height: '450px',
                autoFocus: false
            })
            .afterClosed()
            .subscribe((res) => {
                setTimeout(() => {
                    this.testKitId = res;
                }, 0);
            });
    }

    submitTestKitId() {
        if (this.testKitId.length < 1) {
            this.testKitScanerror = true;
        } else {
            this.patientService
                .getAntigenPatientAppointment(this.testKitId, this.loader.ss_id)
                .subscribe(
                    (response: any[]) => {
                        if (response['can_test_result_update']) {
                            if (response['result_id'] !== 0) {
                                this.messageService.warning(
                                    this.translate.instant(
                                        'register-result.testkit-registered-already'
                                    ),
                                    5000
                                );
                            } else if (response['message'] !== 'Invalid BarCode') {
                                this.selectedPatient = JSON.parse(JSON.stringify(response));
                                this.selectedPageIndex = 1;
                            } else {
                                this.messageService.warning(
                                    this.translate.instant('register-result.invalid-qr'),
                                    5000
                                );
                            }
                        } else {
                            this.messageService.warning(
                                this.translate.instant('register-result.pcr-code-error'),
                                5000
                            );
                        }
                    },
                    (error) => {
                        error;
                        this.messageService.warning(
                            this.translate.instant('register-result.invalid-qr'),
                            5000
                        );
                    }
                );
        }
    }

    captureImage() {
        this.imageCapture = true;
    }

    onConfirm(type: any) {
        this.messageService
            .confirm(
                this.translate.instant('register-result.invalid-result-confirm'),
                this.translate.instant('register-result.confirm-result'),
                this.translate.instant('register-result.confirm'),
                this.translate.instant('register-result.cancel')
            )
            .subscribe((actionResult) => {
                if (actionResult) {
                    if (!this.imageCaptured) {
                        this.messageService.warning(
                            this.translate.instant('register-result.image-not-taken-error')
                        );
                    } else {
                        this.commonApi.getKitResults().subscribe((r) => {
                            this.kitResults = r;
                            const kitresult = this.kitResults.filter((kr) => kr.Key == type);
                            this.spinner.show(true);
                            this.patientService
                                .putPatientAntigenResult(
                                    this.kitResultObject(kitresult[0].Id)
                                )
                                .subscribe(
                                    (response) => {
                                        response;
                                        this.spinner.show(false);
                                        this.messageService.success(
                                            this.translate.instant('register-result.result-success')
                                        );
                                        this.selectedPageIndex = 0;
                                    },
                                    (error) => {
                                        this.spinner.show(false);
                                        this.messageService.warning(error['message'], 5000);
                                        this.selectedPageIndex = 0;
                                    }
                                );
                        });
                    }
                }
            });
    }

    public handleFilteredFileInput(filelist: FileList) {
        const reader = new FileReader();
        const file = filelist[0];
        if (file) {
            if (!this.isValidPrescription(file)) {
            } else {
                setTimeout(() => {
                    const fdata = new FileData(file);
                    fdata;
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.compress(reader.result.toString(), file);
                    };
                }, 100);
            }
        }
    }

    private isValidPrescription(file: File): Boolean {
        let isFileValid = true;
        const prescriptionType = file.type;
        if (file.size <= 0 || file.size > 3 * 1024 * 1024) {
            isFileValid = false;
        }
        if (prescriptionType == 'application/pdf' && file.size > 250 * 1024) {
            isFileValid = false;
        }
        if (
            prescriptionType != 'application/pdf' &&
      prescriptionType != 'image/png' &&
      prescriptionType != 'image/jpeg'
        ) {
            isFileValid = false;
        }

        if (
            !file.name.toLowerCase().includes('.png') &&
      !file.name.toLowerCase().includes('.pdf') &&
      !file.name.toLowerCase().includes('.jpg') &&
      !file.name.toLowerCase().includes('.jpeg')
        ) {
            isFileValid = false;
        }

        return isFileValid;
    }

    public compress(data: string, file: File) {
        const fileSizeinMB = file.size / 1024 / 1024;
        const quality = this.calculateQueality(fileSizeinMB);
        if (file.size > 200 * 1024 && file.type != 'application/pdf') {
            this.imageCompress
                .compressFile(data, DOC_ORIENTATION.Default, quality, quality)
                .then(
                    (result: DataUrl) => {
                        fetch(result).then(s => s.blob()).then(b => {
                            if ((b.size / 1024) > 200) {
                                this.compress(result, new File([b], file.name));
                            } else {
                                this.prescriptionData = result;
                            }
                        });
                    },
                    () => {
                        console.error('Compression failed, Retry and check later.');
                    }
                );
        } else {
            this.prescriptionData = data;
        }
    }

    public calculateQueality(size: any) {
        if (size < 1) {
            return 75;
        } else if (size >= 1 && size < 2) {
            return 50;
        } else {
            return 22;
        }
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public handleImage(webcamImage: WebcamImage): void {
        this.prescriptionData = webcamImage.imageAsDataUrl;
        fetch(webcamImage.imageAsDataUrl).then(async (response) => {
            const blob = await response.blob();
            const file = await new FileData(new File([blob], 'captured_image.jpg', {}));
            file;
        });
    }

    public triggerSnapshot(): void {
        this.trigger.next();
        this.imageCaptured = true;
    }

    public handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
    }

    public retakeSnapshot() {
        this.imageCaptured = false;
    }
}
