import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { GoogleMapService } from "../../shared/lab-google/google-map.service";
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { AppSettingsService } from "../../../core";
import { SpinnerService } from "../../../core/spinner/spinner.service";
import { TranslateService } from "@ngx-translate/core";
import { LoaderService, MessageService } from "../../../main";
import { PatientService } from "../../services/patient.service";
import { constants } from "../../shared/constant";
import { Department } from '../../models/common.model';
import { CommonApiService } from '../../services/common-api.service';
import { EstablishmentsService } from './establishments.service';
import { HttpErrorResponse } from '@angular/common/http';
declare var woosmap: any;

@Component({
  selector: 'app-add-establishment',
  templateUrl: './add-establishment.component.html',
  styleUrl: './add-establishment.component.scss'
})
export class AddEstablishmentComponent implements OnInit, AfterViewInit {
  @Input() sliderFormData: any;
  @Input() public isSliderDataEdited = false;
  @Input() data: any;


  public establishName = new FormControl("", [
    Validators.required,
    Validators.maxLength(100),
    Validators.pattern('^[a-zA-Z][a-zA-Z ]*$')
  ]);
  public address = new FormControl("");
  public status = new FormControl(true, [Validators.required]);
  public department = new FormControl([])


  public EstablishForm = this.formBuilder.group({
    establishName: this.establishName,
    status: this.status,
    address: this.address,
    department: this.department
  });

  nationalities: any[];
  countryList: any;
  title: string;
  departments: Department[] = null;


  saveAlert = {
    edit: 'admin.save-alert.edit',
    add: 'admin.save-alert.add'
  };
  errorMessage = 'admin.error-message';

  constructor(
    public loader: LoaderService,
    private googleMapService: GoogleMapService,
    private spinnerService: SpinnerService,
    private commonAPi: CommonApiService,
    private readonly appSettings: AppSettingsService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private patientService: PatientService,
    public messageService: MessageService,
    private EstablishmentsService: EstablishmentsService,
  ) { }

  ngOnInit(): void {
    this.patientService.getCoutryFlags().subscribe(
      (data: any) => {
        this.nationalities = data;
        const frNationIndex = this.nationalities.findIndex((i: any) => i.id == constants.nationality);
        const frNation = this.nationalities[frNationIndex];
        this.nationalities.splice(frNationIndex, 1);
        this.nationalities.sort((i) => i.country_name);
        this.nationalities.unshift(frNation);
        this.countryList = data;
      },
      (error: any) => {
        console.log(error);
      }
    );

    if (this.sliderFormData[0].edit) {
      this.addressAutocomplete();
      this.bindUserObject(this.sliderFormData[0].EstablishData);
    }
    else {
      this.commonAPi.GetDepartments().subscribe((DeptList) => {
        this.departments = DeptList;
      });
      this.addressAutocomplete();
    }
  }

  ngAfterViewInit(): void {
    if (typeof woosmap !== 'undefined') {
      this.addressAutocomplete();
    } else {
      console.error('Woosmap SDK is not loaded!');
    }
  }
  public addressAutocomplete() {
    this.googleMapService.api.then(() => {
      const options = {
        "components": {
          country: this.appSettings?.country?.split(";")
        },
        "types": ['locality', 'postal_code', 'address', 'admin_level', 'country', 'train_station', 'metro_station', 'shopping', 'airport', 'museum', 'zoo', 'amusement_park', 'art_gallery', 'tourist_attraction']
      };
      //@ts-ignore
      const localitiesWidget = new window.woosmap.localities.Autocomplete("autocompleteinput", options);
      localitiesWidget.addListener("selected_suggestion", () => {
        this.spinnerService.show(true);
        const selectedLocality = localitiesWidget.getSelectedSuggestionDetails();
        console.log(selectedLocality);
        if (selectedLocality.address_components) {
          console.log("address:", selectedLocality.formatted_address);
          this.EstablishForm.controls.address.setValue(selectedLocality.formatted_address);
          this.spinnerService.show(false);
        } else {
          this.spinnerService.show(false);
        }
      });
    });
  }


  public dismiss() { this.loader.cancelForm(); }

  reset() {
    this.EstablishForm.reset();
    this.EstablishForm.markAsUntouched();
  }

  public success(message: any): void {
    this.messageService.success(this.translate.instant(message));
  }
  public alert(error: any): void {
    this.messageService.alert(this.translate.instant(error), 10000);
  }

  getTruncatedDepartmentName(deptId: any): { displayName: string, tooltip?: string } {
    const fullName = this.getDepartmentName(deptId);
    const maxLength = 20;

    if (fullName.length > maxLength) {
      return {
        displayName: fullName.substring(0, maxLength) + '...',
        tooltip: fullName
      };
    } else {
      return { displayName: fullName };
    }
  }

  onSave() {
    if (this.EstablishForm.valid) {
      const address = this.getSelectedData(
        this.EstablishForm.controls.address.value
      );

      let selectedDepartments = this.EstablishForm.controls.department?.value || [];

      let DeptListArr = selectedDepartments
        .map((item: any) => (typeof item === 'object' ? item.id : item))
        .filter((item: any, index: any, self: any) => self.indexOf(item) === index)
        .join(',');

      if (this.sliderFormData[0].edit) {
        const obj = {
          id: this.sliderFormData[0].EstablishData.id,
          name: this.EstablishForm.controls.establishName.value,
          is_active: this.EstablishForm.controls.status.value,
          address: address,
          dept_ids: DeptListArr
        };
        this.spinnerService.show(true);
        this.EstablishmentsService.editEstablishment(obj).subscribe(
          () => {
            this.messageService.success(
              this.translate.instant(this.saveAlert['edit'])
            );
            this.spinnerService.show(false);
            this.loader.refreshState(true);
            this.dismiss();
          },
          (errorResponse: HttpErrorResponse) => {
            this.spinnerService.show(false);
            this.messageService.alert(errorResponse["message"], 5000);
          }
        );
      } else {
        const obj = {
          name: this.EstablishForm.controls.establishName.value,
          is_active: this.EstablishForm.controls.status.value,
          address: this.EstablishForm.controls.address.value,
          dept_ids: DeptListArr
        };

        this.EstablishmentsService.addEstablishment(obj).subscribe(
          (data: any) => {
            data;
            this.loader.onFormClose();
            this.messageService.success(
              this.translate.instant("add-establish.establish-saved-success"),
              5000
            );
            this.spinnerService.show(false);
            this.loader.refreshState(true);
            this.dismiss();
          },
          (errorResponse: HttpErrorResponse) => {
            this.spinnerService.show(false);
            console.error(errorResponse);
            this.messageService.alert(errorResponse["message"], 5000);
          }
        );
      }
    }
  }

  bindUserObject(EstablishData: any) {
    const address = EstablishData.address === 'n/a' ? '' : EstablishData.address;

    const selectedDepartmentIds = EstablishData?.departments.map((dept: any) => dept.id) || [];
    console.log("Bind data dept IDs:", selectedDepartmentIds);

    if (!this.departments || this.departments.length === 0) {
      this.commonAPi.GetDepartments().subscribe((DeptList) => {
        this.departments = DeptList;
        this.EstablishForm.patchValue({
          establishName: EstablishData.name,
          status: EstablishData.is_active,
          address: address,
          department: selectedDepartmentIds
        });
      });
    } else {
      this.EstablishForm.patchValue({
        establishName: EstablishData.name,
        status: EstablishData.is_active,
        address: address,
        department: selectedDepartmentIds
      });
    }
  }

  getSelectedData(selectedData: any) {
    if (selectedData === undefined) {
      return '';
    } else if (selectedData === null) {
      return '';
    } else {
      return selectedData;
    }
  }

  getDepartmentName(deptId: number): string {
    const dept = this.departments.find(d => d.id === deptId);
    return dept ? dept.name : 'Unknown';
  }

}


