import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from '../../features/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../shared';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { NgOtpInputModule } from 'ng-otp-input';
import { EClinicalSharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AuthRoutingModule,
    TranslateModule,
    ReactiveFormsModule,
    EClinicalSharedModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgOtpInputModule
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: "6Ldbi_ciAAAAAHO6PjUxLLtQWnAkK0dGnbwig9Xw",
    } as RecaptchaSettings,
  }]
})
export class AuthModule { }
