import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../../app.locale.service';
import { DrawerComponent } from '../../../../core';
import { LoaderService, MessageService } from '../../../../main';
import { GoogleMapService } from '../../../shared/lab-google/google-map.service';

@Component({
    selector: 'app-get-directions',
    templateUrl: './get-directions.component.html',
    styleUrls: ['./get-directions.component.scss']
})
export class GetDirectionsComponent implements OnInit, DrawerComponent {
    @Input() sliderFormData: any;
    @Input() isSliderDataEdited?: boolean;

    private map: google.maps.Map;
    totalDistance: string = null;
    totalTime: string = null;

    // private lat: any;
    // private lng: any;
    public SamplingSationname: string;
    public Addresss: string;
    public cordinates: string;
    constructor(private loaderService: LoaderService, private translate: TranslateService,
        private localeService: LocaleService, public googleMapService: GoogleMapService, private messageService: MessageService) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }
    ngOnInit(): void {

        this.SamplingSationname = this.sliderFormData.sampling_station_name;
        this.Addresss = this.sliderFormData.address;
        this.cordinates = this.sliderFormData.coordinates;
        this.totalDistance = null;
        this.totalTime = null;
        // this.lat = this.cordinates.substring(this.cordinates.indexOf(':') + 1, this.cordinates.indexOf(','));
        // this.lng = this.cordinates.substring(this.cordinates.lastIndexOf(':') + 1, this.cordinates.indexOf('}'));

        this.googleMapService.api
            .then(() => this.calculateAndDisplayRoute());

    }
    dismiss() {
        this.loaderService.cancelForm();
    }
    calculateAndDisplayRoute() {
        const that = this;
        // let lat1 = that.sliderFormData.coordinates.substring(that.sliderFormData.coordinates.indexOf(':') + 1, that.sliderFormData.coordinates.indexOf(','));
        // let lng1 = that.sliderFormData.coordinates.substring(that.sliderFormData.coordinates.lastIndexOf(':') + 1, that.sliderFormData.coordinates.indexOf('}'));
        this.map = new google.maps.Map(document.getElementById('map') as HTMLElement,
            {
                zoom: 7,
                center:
                    { lat: 41.85, lng: -87.65 },
                disableDefaultUI: true
            });
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((postion) => {
                const lat = that.sliderFormData.coordinates.substring(that.sliderFormData.coordinates.indexOf(':') + 1, that.sliderFormData.coordinates.indexOf(','));
                const lng = that.sliderFormData.coordinates.substring(that.sliderFormData.coordinates.lastIndexOf(':') + 1, that.sliderFormData.coordinates.indexOf('}'));
                const directionsService = new google.maps.DirectionsService();
                const directionsRenderer = new google.maps.DirectionsRenderer();
                const start = new google.maps.LatLng(postion.coords.latitude, postion.coords.longitude);
                const end = new google.maps.LatLng(lat, lng);
                directionsService
                    .route({
                        origin: start,
                        destination: end,
                        travelMode: google.maps.TravelMode.DRIVING
                    })
                    .then((response) => {
                        that.totalDistance = response.routes[0].legs[0].distance.text;
                        that.totalTime = response.routes[0].legs[0].duration.text;
                        directionsRenderer.setDirections(response);
                    })
                    .catch((e) =>
                        this.messageService.alert('This is error Navigation' + e.message, 3000));
                directionsRenderer.setMap(this.map);
            });
        } else {
            console.log('geolocation not supported.');
        }
    }



}
