import { Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService} from '../../../main';
import { LocaleService } from '../../../../app/app.locale.service'
@Component({
  selector: 'app-document-popup',
  templateUrl: './document-popup.component.html',
  styleUrls: ['./document-popup.component.scss']
})
export class DocumentPopupComponent  {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private localeService:LocaleService,
    private translate: TranslateService,
    private loader:LoaderService
  ) {
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
   }

  protected uploadDocument() {
    this.loader.highlightLeftMenu(["patient/documents"]);
    this.dismiss();
  }

  protected dismiss(){
    this.dialog.closeAll();
  }
}
