<div class="stepper-container " *ngIf="flow==1">
    <div class="group pl-12">
        <span class="icon-ic-back" (click)="stepperBack(stepper.selectedIndex)"
            *ngIf="isBackEnabled && stepper.selectedIndex === 0"></span>
        <span class="icon-ic-back" (click)="stepperBack(stepper.selectedIndex)"
            *ngIf="stepper.selectedIndex > 0"></span>
        <h3 class="only-web">{{stepperTitles[stepper.selectedIndex]|translate}}</h3>
        <h3 class="only-mobile">
            <circle-progress [percent]="((stepper.selectedIndex+1)/stepperTitles.length)*100"
                [title]="stepper.selectedIndex+1" [showSubtitle]="false" [showTitle]="true" [showUnits]="false">
            </circle-progress>
            {{stepperTitles[stepper.selectedIndex]|translate}}
        </h3>
    </div>
    <mat-horizontal-stepper linear #stepper ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}"
        labelPosition="bottom" (selectionChange)="clearNextSteps($event)"
        [class.CalenderStepper]="stepper.selectedIndex == 2">
        <ng-container>
            <mat-step [stepControl]="firstFormGroup" errorMessage="{{'stepper-flow.error-message' | translate}}"
                [completed]="false" [editable]="testeditable">
                <div *ngIf="loaderService.loggedIn && loaderService.userRole == nurseRole" class="patient-info">
                    {{'stepper-flow.bookingFor' | translate}} : {{loaderService.bookingPatientName | translate}}</div>
                <app-test-selection *ngIf="testArray.length >0" (selectedTest)="onSelectTest($event)"
                    [selectTest]="selectTest" [pre_selected_test_id]="test_ext_id" [testArray]="testArray"
                    [allTestType]="testType" [pre_selected_lab_id]="s_id"></app-test-selection>
                <ng-template matStepLabel>
                    <span matTooltip="{{'stepper-flow.test' | translate}}" class="only-web">{{'stepper-flow.test' |
                        translate}}</span>
                    <span class="optionnalLabel only-web" matTooltip="{{stepperCollection.test|translate}}">
                        {{stepperCollection.test|translate}}
                    </span>
                </ng-template>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup" errorMessage="{{'stepper-flow.address-message' | translate}}"
                [completed]="false">
                <div *ngIf="loaderService.loggedIn && loaderService.userRole == nurseRole" class="patient-info">
                    {{'stepper-flow.bookingFor' | translate}} : {{loaderService.bookingPatientName | translate}}</div>
                <app-lab-google *ngIf="selectedtest" [pre_selected_lab_id]="ext_id" [testType]="selectedtest"
                    [stepperSelectedIndex]="stepper.selectedIndex" (selectedLocation)="onSelectLocation($event)"
                    (selectedFavroitestation)="onselectedFavroitestation($event)"></app-lab-google>
                <ng-template matStepLabel>
                    <span matTooltip="{{'stepper-flow.laboratory' | translate}}"
                        class="only-web">{{'stepper-flow.laboratory' | translate}}</span>
                    <span class="optionnalLabel only-web" matTooltip="{{stepperCollection.laboratory}}">
                        {{stepperCollection.laboratory}}
                    </span>
                </ng-template>
            </mat-step>
            <mat-step [stepControl]="thirdFormGroup" errorMessage="{{'stepper-flow.home-address-message' | translate}}"
                [completed]="false">
                <div *ngIf="loaderService.loggedIn && loaderService.userRole == nurseRole" class="patient-info">
                    {{'stepper-flow.bookingFor' | translate}} : {{loaderService.bookingPatientName | translate}}</div>
                <app-date-slot *ngIf="hasCalendar && labSelected" [calendarDates]="slotData.slots"
                    (fromModalEvent)="onSelectAppointmnt($event)" [s_id]="s_id"
                    [test_id]="selectedtest.id"></app-date-slot>
                <div *ngIf="!hasCalendar && labSelected">
                    <div class="upload-pic-container">
                        <h3 class="desc-title">{{'calender.datepicker-desc'|translate}}</h3>
                        <div class="formCointainer">
                            <form #formReference>
                                <div class="form-layout  pt-30 input-min-spacing">
                                    <div>
                                        <p class="upload-header" [innerHTML]="'stepper-flow.no-calendar' | translate">
                                        </p>
                                        <span class="datepicker-container-stepper">
                                            <div class="datepicker-container">
                                                <mat-form-field class="example-full-width mb-0">
                                                    <input id="slotTime" matInput [matDatepickerFilter]="myFilter"
                                                        (dateInput)="onDateValueChange($event)" [formControl]="slotTime"
                                                        [min]="minDate" (dateChange)="onDateValueChange($event)"
                                                        [matDatepicker]="picker" [dateInput]="slotTime"
                                                        placeholder="{{'stepper-flow.choose-date' | translate}}">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker [dateAdapter]="adapter"></mat-datepicker>
                                                </mat-form-field>
                                                <mat-error class="color-error" *ngIf="slotTime.value!=undefined">
                                                    <span
                                                        *ngIf="!slotTime.valid && isFuture">{{'stepper-flow.future-date'
                                                        | translate}}</span>
                                                    <span
                                                        *ngIf="!slotTime.valid && isNonWorkingDay">{{'stepper-flow.non-working-day'
                                                        | translate}}</span>
                                                    <span
                                                        *ngIf="!slotTime.valid && isPastDate">{{'stepper-flow.past-date'
                                                        | translate}}</span>
                                                </mat-error>
                                                <mat-error class="color-error" *ngIf="slotTime.hasError('notExist')">
                                                    {{'stepper-flow.select-date' |
                                                    translate}}</mat-error>
                                            </div>
                                        </span>
                                    </div>
                                    <div class="actions">
                                        <button mat-raised-button color="accent" class="ghost" id="btn_apointment_date"
                                            [disabled]="!slotTime.valid" (click)="onSelectAppointmentDate()"
                                            type="button">
                                            {{'stepper-flow.continue' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ng-template matStepLabel>
                    <span matTooltip="{{'stepper-flow.appointment' | translate}}"
                        class="only-web">{{'stepper-flow.appointment' | translate}}</span>
                    <span class="optionnalLabel only-web" matTooltip="{{stepperCollection.appointment | dateFormat}}">
                        {{stepperCollection.appointment | dateFormat}}
                    </span>
                </ng-template>
            </mat-step>
            <mat-step [stepControl]="forthFormGroup" errorMessage="{{'stepper-flow.home-address-message' | translate}}"
                *ngIf="!(loaderService.userRole == nurseRole || !showPrescription)" [completed]="false">
                <ng-container>
                    <div class="accordion-wrapper">
                        <h3 class="desc-title">{{'upload-prescription.prescription-desc'|translate}}</h3>
                        <mat-accordion class="example-headers-align">
                            <mat-expansion-panel class="panel-container" [class.grey]="!uploadStatus"
                                [class.red]="uploadStatus" *ngFor="let item of documents; index as index"
                                [expanded]="index===0"
                                [disabled]="documents[index-1]?.PrescriptionParts?.length == 0 && documents[index]?.PrescriptionParts?.length == 0">
                                <mat-expansion-panel-header class="acc-panel-header"
                                    [ngClass]="documents[index-1]?.PrescriptionParts?.length == 0 && documents[index]?.PrescriptionParts?.length == 0 ? 'opacity-disabled' : 'opacity-normal'">
                                    <mat-panel-title class="panel-title">
                                        <span class="icon-ic-add customAddIcon" *ngIf="!item.Expanded"></span>
                                        <span class="uploaded-message"
                                            *ngIf="documents[index]?.PrescriptionParts?.length == 1">{{documents[index]?.PrescriptionParts?.length}}
                                            {{'stepper-flow.file-added' | translate}}</span>
                                        <span class="uploaded-message"
                                            *ngIf="documents[index]?.PrescriptionParts?.length > 1">{{documents[index]?.PrescriptionParts?.length}}
                                            {{'stepper-flow.files-added' | translate}}</span>
                                        <span class="optionalLabel"
                                            *ngIf="index != 0 && documents[index]?.PrescriptionParts?.length == 0">{{'stepper-flow.optional'
                                            | translate}}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <app-appointment-prescription [document]="item" [prescription]="true"
                                    (updateDocument)="getUpdateDocument($event)"
                                    (isImageClicked)="getClickImageStatus($event)"></app-appointment-prescription>
                            </mat-expansion-panel>
                        </mat-accordion>

                        <div class="actions-wrapper">
                            <button mat-raised-button id="btn_upload_prescription" color="primary" type="button"
                                class="ghost ml-10" (click)="fileUploaded(documents)">
                                {{'upload-pic.upload' | translate}}
                            </button>
                        </div>
                    </div>
                </ng-container>
                <ng-template matStepLabel>
                    <span matTooltip="{{'stepper-flow.prescription' | translate}}"
                        class="only-web">{{'stepper-flow.prescription' | translate}}</span>
                </ng-template>
            </mat-step>
            <mat-step [stepControl]="fifthFormGroup" errorMessage="{{'stepper-flow.error-message' | translate}}"
                [completed]="false" *ngIf="showLoginorRegisterStep">
                <ng-container *ngIf="showLoginPrebook">
                    <app-login-prebook (loggedin)="loginSuccessfull()" (registereduser)="RegisterSuccessful($event)"
                        [userName]="username" [Password]="password" [showRegister]="showRegister"
                        [displayMap]="!isMassiveTesting" [showLogin]="showLogin"
                        (forgetPasswordClicked)="showLoginPrebook=false;forgotPassword=true;">
                    </app-login-prebook>
                </ng-container>
                <ng-container *ngIf="forgotPassword">
                    <div class="outer-container">
                        <div id="forgetPassword_container" class="inner-wrapper" [formGroup]="sendEmailForm">
                            <h4>{{'login.trouble' | translate}}</h4>
                            <span>{{'login.enter-mail' | translate}}</span>
                            <mat-form-field class="mb-20 mt-20">
                                <mat-label class="label-float">{{'login.mail' | translate}}</mat-label>
                                <input matInput class="email-input" name="RegisteredEmail" #RegisteredEmail
                                    [isRequired]="true" (keyup)="isValid()" [formControl]="registeredEmail" />
                                <mat-error *ngIf="registeredEmail.invalid">{{'login.valid-mail' |
                                    translate}}</mat-error>
                            </mat-form-field>
                            <div *ngIf="userExistError" id="invalidUserError">{{'login.email-not-exist-error' |
                                translate}}</div>
                            <button mat-raised-button color="primary" id="sendButton" type="button" class="mb-20"
                                (click)="onSend($event)">
                                {{'login.send' | translate}}
                            </button>
                            <div>{{'login.back' | translate}}<a class="linkurl"
                                    (click)="showLoginPrebook=true;showLogin=true;showRegister=false;forgotPassword=false;">{{'login.Login'
                                    |
                                    translate}}</a></div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="forgetPasswordMailSent">
                    <div class="outer-container">
                        <div id="checkmail_container" class="inner-wrapper">
                            <img src="./assets/images/application-logos/svg/mail-sent-ic.svg" />
                            <h4>{{'login.check-mail' | translate}}</h4>
                            <p>
                                <span> {{'login.mail-instructions-1' | translate}}</span>
                                <span id="email">{{username}},</span>
                                <span> {{'login.mail-instructions-2' | translate}}</span>
                            </p>
                            <p>
                                {{'login.mail-instructions-3' | translate}}
                            </p>

                            <p>{{'login.note' | translate}}</p>
                            <p>
                                {{'login.no-account' | translate}}
                                <a class="linkurl"
                                    (click)="forgetPasswordMailSent=false;showLoginPrebook=true;showRegister=true;forgotPassword=false;">{{'login.create-account'
                                    | translate}}</a>
                            </p>
                            <p>
                                <span>{{'login.back' | translate}} <a class="linkurl"
                                        (click)="showLoginPrebook=true;showLogin=true;forgotPassword=false;forgetPasswordMailSent=false;">{{'login.Login'
                                        |
                                        translate}}</a></span>
                            </p>
                        </div>
                    </div>
                </ng-container>
                <ng-template matStepLabel>
                    <span matTooltip="{{'stepper-flow.login-register' | translate}}"
                        class="only-web">{{'stepper-flow.login-register' | translate}}</span>
                    <span class="optionnalLabel only-web" matTooltip="{{stepperCollection.loggedIn}}">
                        {{stepperCollection.loggedIn}}
                    </span>
                </ng-template>
            </mat-step>
            <mat-step [completed]="false">
                <ng-container *ngIf="(registerSuccessful || loginSuccessful ) && showConfirmBooking">
                    <app-con-booking *ngIf="selectedAppointment" [showConfirmBooking]="showConfirmBooking"
                        [prescription_url]="selectedprescription.prescriptionData"
                        [start_time]="stepperCollection.appointment" [selectedLab]="selectedLab"
                        [selectedtest]="selectedtest" [calendar]="slotData?.calendar_id"
                        [user_name]="loaderService.user_name" [dob]="loaderService.dob"
                        [start_time]="stepperCollection.appointment" [isConfirmed]="isConfirmed"
                        (showLoginScreen)="ShowConfirmationTab($event)">
                    </app-con-booking>
                </ng-container>

                <ng-template matStepLabel>
                    <span matTooltip="{{finalStep | translate}}" class="only-web">{{finalStep |
                        translate}}</span>
                </ng-template>
            </mat-step>
        </ng-container>
    </mat-horizontal-stepper>
</div>

<div class="stepper-container" *ngIf="flow==2">
    <div class="group pl-12">
        <span class="icon-ic-back" (click)="stepperBack(stepper.selectedIndex)"
            *ngIf="isBackEnabled && stepper.selectedIndex === 0"></span>
        <span class="icon-ic-back" (click)="stepperBack(stepper.selectedIndex)"
            *ngIf="stepper.selectedIndex > 0"></span>
        <h3 class="only-web">{{stepperTitles[stepper.selectedIndex]|translate}} </h3>
        <h3 class="only-mobile">
            <circle-progress [percent]="((stepper.selectedIndex+1)/stepperTitles.length)*100"
                [title]="stepper.selectedIndex+1" [showSubtitle]="false" [showTitle]="true" [showUnits]="false">
            </circle-progress>
            {{stepperTitles[stepper.selectedIndex]|translate}}
        </h3>
    </div>
    <mat-horizontal-stepper linear #stepper ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}"
        labelPosition="bottom" (selectionChange)="clearNextSteps($event)">
        <ng-container>
            <mat-step [stepControl]="firstFormGroup" errorMessage="{{'stepper-flow.address-message' | translate}}"
                [completed]="false" [editable]="mapeditable">
                <div *ngIf="loaderService.loggedIn && loaderService.userRole == 1" class="patient-info">
                    {{'stepper-flow.bookingFor' | translate}} : {{loaderService.bookingPatientName | translate}}</div>
                <app-lab-google [pre_selected_lab_id]="ext_id" [pre_selected_test_id]="test_ext_id"
                    [fav_lab_id]="fav_lab_id" (selectedLocation)="onSelectLocation($event)"
                    (selectedFavroitestation)="onselectedFavroitestation($event)"></app-lab-google>
                <ng-template matStepLabel>
                    <span matTooltip="{{'stepper-flow.laboratory' | translate}}"
                        class="only-web">{{'stepper-flow.laboratory' | translate}}</span>
                    <span class="optionnalLabel only-web" matTooltip="{{stepperCollection.laboratory}}">
                        {{stepperCollection.laboratory}}
                    </span>
                </ng-template>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup" errorMessage="{{'stepper-flow.error-message' | translate}}"
                [completed]="false" [editable]="testeditable">
                <div *ngIf="loaderService.loggedIn && loaderService.userRole == nurseRole" class="patient-info">
                    {{'stepper-flow.bookingFor' | translate}} : {{loaderService.bookingPatientName | translate}}</div>
                <app-test-selection *ngIf="testArray.length >0" [pre_selected_test_id]="test_ext_id_actual"
                    (selectedTest)="onSelectTest($event)" [selectTest]="selectTest" [testArray]="testArray"
                    [allTestType]="testType" [pre_selected_lab_id]="s_id"></app-test-selection>
                <ng-template matStepLabel>
                    <span matTooltip="{{'stepper-flow.test' | translate}}" class="only-web">{{'stepper-flow.test' |
                        translate}}</span>

                    <span class="optionnalLabel only-web" matTooltip="{{stepperCollection.test|translate}}">
                        {{stepperCollection.test|translate}}
                    </span>
                </ng-template>
            </mat-step>
            <mat-step [stepControl]="thirdFormGroup" errorMessage="{{'stepper-flow.home-address-message' | translate}}"
                [completed]="false">
                <div *ngIf="loaderService.loggedIn && loaderService.userRole == nurseRole" class="patient-info">
                    {{'stepper-flow.bookingFor' | translate}} : {{loaderService.bookingPatientName | translate}}</div>
                <app-date-slot *ngIf="hasCalendar && labSelected" [calendarDates]="slotData.slots"
                    (fromModalEvent)="onSelectAppointmnt($event)" [s_id]="s_id"
                    [test_id]="selectedtest.id"></app-date-slot>
                <div *ngIf="noCalendar && labSelected">
                    <div class="upload-pic-container">
                        <h3 class="desc-title">{{'calender.datepicker-desc'|translate}}</h3>
                        <div class="formCointainer">
                            <form #formReference>
                                <div class="form-layout  pt-30 input-min-spacing">
                                    <div>
                                        <p class="upload-header" [innerHTML]="'stepper-flow.no-calendar' | translate">
                                        </p>
                                        <span class="datepicker-container-stepper">
                                            <div class="datepicker-container">
                                                <mat-form-field class="example-full-width mb-0">
                                                    <input id="slotTime" matInput [matDatepickerFilter]="myFilter"
                                                        (dateInput)="onDateValueChange($event)" [formControl]="slotTime"
                                                        [min]="minDate" (dateChange)="onDateValueChange($event)"
                                                        [matDatepicker]="picker" [dateInput]="slotTime"
                                                        placeholder="{{'stepper-flow.choose-date' | translate}}">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                                <mat-error class="color-error" *ngIf="slotTime.value!=undefined">
                                                    <span
                                                        *ngIf="!slotTime.valid && isFuture">{{'stepper-flow.future-date'
                                                        | translate}}</span>
                                                    <span
                                                        *ngIf="!slotTime.valid && isNonWorkingDay">{{'stepper-flow.non-working-day'
                                                        | translate}}</span>
                                                    <span
                                                        *ngIf="!slotTime.valid && isPastDate">{{'stepper-flow.past-date'
                                                        | translate}}</span>
                                                </mat-error>
                                                <mat-error class="color-error" *ngIf="slotTime.hasError('notExist')">
                                                    {{'stepper-flow.select-date' |
                                                    translate}}</mat-error>
                                            </div>
                                        </span>
                                    </div>
                                    <div class="actions">
                                        <button mat-raised-button color="accent" class="ghost" id="btn_appointment_date"
                                            (click)="onSelectAppointmentDate($event)" type="button"
                                            [disabled]="!slotTime.valid">
                                            {{'stepper-flow.continue' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ng-template matStepLabel>
                    <span matTooltip="{{'stepper-flow.appointment' | translate}}"
                        class="only-web">{{'stepper-flow.appointment' | translate}}</span>

                    <span class="optionnalLabel only-web" matTooltip="{{stepperCollection.appointment | dateFormat}}">
                        {{stepperCollection.appointment | dateFormat}}
                    </span>
                </ng-template>
            </mat-step>
            <mat-step *ngIf="!(loaderService.userRole == 1 || !showPrescription)" [stepControl]="forthFormGroup"
                errorMessage="{{'stepper-flow.home-address-message' | translate}}" [completed]="false">
                <ng-container>
                    <div class="accordion-wrapper">
                        <h3 class="desc-title">{{'upload-prescription.prescription-desc'|translate}}</h3>
                        <mat-accordion class="example-headers-align">
                            <mat-expansion-panel class="panel-container" [class.grey]="!uploadStatus"
                                [class.red]="uploadStatus" *ngFor="let item of documents; index as index"
                                [expanded]="index===0" [disabled]="documents[index-1]?.PrescriptionParts?.length == 0">
                                <mat-expansion-panel-header class="acc-panel-header"
                                    [ngClass]="documents[index-1]?.PrescriptionParts?.length == 0 && documents[index]?.PrescriptionParts?.length == 0 ? 'opacity-disabled' : 'opacity-normal'">
                                    <mat-panel-title class="panel-title">
                                        <span class="icon-ic-add customAddIcon" *ngIf="!item.Expanded"></span>
                                        <span class="uploaded-message"
                                            *ngIf="documents[index]?.PrescriptionParts?.length == 1">{{documents[index]?.PrescriptionParts?.length}}
                                            {{'stepper-flow.file-added' | translate}}</span>
                                        <span class="uploaded-message"
                                            *ngIf="documents[index]?.PrescriptionParts?.length > 1">{{documents[index]?.PrescriptionParts?.length}}
                                            {{'stepper-flow.files-added' | translate}}</span>
                                        <span class="optionalLabel"
                                            *ngIf="index != 0 && documents[index]?.PrescriptionParts?.length == 0">{{'stepper-flow.optional'
                                            | translate}}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <app-appointment-prescription [document]="item" [prescription]="true"
                                    (updateDocument)="getUpdateDocument($event)"
                                    (isImageClicked)="getClickImageStatus($event)"></app-appointment-prescription>
                            </mat-expansion-panel>
                        </mat-accordion>

                        <div class="actions-wrapper">
                            <button mat-raised-button color="primary" type="button" id="btn_upload_prescription"
                                class="ghost ml-10" (click)="fileUploaded(documents)">
                                {{'upload-pic.upload' | translate}}
                            </button>
                        </div>
                    </div>
                </ng-container>
                <ng-template matStepLabel>
                    <span matTooltip="{{'stepper-flow.prescription' | translate}}"
                        class="only-web">{{'stepper-flow.prescription' | translate}}</span>
                </ng-template>
            </mat-step>
            <mat-step [stepControl]="fifthFormGroup" errorMessage="{{'stepper-flow.error-message' | translate}}"
                [completed]="false" *ngIf="showLoginorRegisterStep==true">
                <ng-container *ngIf="showLoginPrebook">
                    <app-login-prebook (loggedin)="loginSuccessfull()" (registereduser)="RegisterSuccessful($event)"
                        [userName]="username" [Password]="password" [showRegister]="showRegister"
                        [showLogin]="showLogin" [displayMap]="selectedLab?.display_map"
                        (forgetPasswordClicked)="showLoginPrebook=false;forgotPassword=true;">
                    </app-login-prebook>
                </ng-container>
                <ng-container *ngIf="forgotPassword">
                    <div class="outer-container">
                        <div id="forgetPassword_container" class="inner-wrapper" [formGroup]="sendEmailForm">
                            <h4>{{'login.trouble' | translate}}</h4>
                            <span>{{'login.enter-mail' | translate}}</span>
                            <mat-form-field class="mb-20 mt-20">
                                <mat-label class="label-float">{{'login.mail' | translate}}</mat-label>
                                <input matInput class="email-input" name="RegisteredEmail" #RegisteredEmail
                                    [isRequired]="true" (keyup)="isValid()" [formControl]="registeredEmail" />
                                <mat-error *ngIf="registeredEmail.invalid">{{'login.valid-mail' |
                                    translate}}</mat-error>
                            </mat-form-field>
                            <div *ngIf="userExistError" id="invalidUserError">{{'login.email-not-exist-error' |
                                translate}}</div>
                            <button mat-raised-button color="primary" id="sendButton" type="button" class="mb-20"
                                (click)="onSend($event)">
                                {{'login.send' | translate}}
                            </button>
                            <div>{{'login.back' | translate}}<a class="linkurl"
                                    (click)="showLoginPrebook=true;showLogin=true;showRegister=false;forgotPassword=false;">{{'login.Login'
                                    |
                                    translate}}</a></div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="forgetPasswordMailSent">
                    <div class="outer-container">
                        <div id="checkmail_container" class="inner-wrapper">
                            <img src="./assets/images/application-logos/svg/mail-sent-ic.svg" />
                            <h4>{{'login.check-mail' | translate}}</h4>
                            <p>
                                <span> {{'login.mail-instructions-1' | translate}}</span>
                                <span id="email">{{username}},</span>
                                <span> {{'login.mail-instructions-2' | translate}}</span>
                            </p>
                            <p>
                                {{'login.mail-instructions-3' | translate}}
                            </p>

                            <p>{{'login.note' | translate}}</p>
                            <p>
                                {{'login.no-account' | translate}}
                                <a class="linkurl"
                                    (click)="forgetPasswordMailSent=false;showLoginPrebook=true;showRegister=true;forgotPassword=false;">{{'login.create-account'
                                    | translate}}</a>
                            </p>
                            <p>
                                <span>{{'login.back' | translate}} <a class="linkurl"
                                        (click)="showLoginPrebook=true;showLogin=true;forgotPassword=false;forgetPasswordMailSent=false;">{{'login.Login'
                                        |
                                        translate}}</a></span>
                            </p>
                        </div>
                    </div>
                </ng-container>
                <ng-template matStepLabel>
                    <span matTooltip="{{'stepper-flow.login-register' | translate}}"
                        class="only-web">{{'stepper-flow.login-register' |
                        translate}}</span>
                    <span class="optionnalLabel only-web" matTooltip="{{stepperCollection.login-register|translate}}">
                        {{stepperCollection.loggedIn |translate}}
                    </span>
                </ng-template>
            </mat-step>
            <mat-step [completed]="false">
                <ng-container
                    *ngIf="(registerSuccessful || loginSuccessful || isLoginReceptionist ) && showConfirmBooking">
                    <app-con-booking *ngIf="selectedAppointment" [showConfirmBooking]="showConfirmBooking"
                        [prescription_url]="selectedprescription.prescriptionData"
                        [start_time]="stepperCollection.appointment" [selectedLab]="selectedLab"
                        [selectedtest]="selectedtest" [calendar]="slotData?.calendar_id"
                        [user_name]="loaderService.user_name" [dob]="loaderService.dob" [isConfirmed]="isConfirmed"
                        (showLoginScreen)="ShowConfirmationTab($event)">
                    </app-con-booking>

                </ng-container>
                <ng-template matStepLabel>
                    <span matTooltip="{{finalStep | translate}}" class="only-web">{{finalStep | translate}}</span>
                </ng-template>
            </mat-step>
        </ng-container>
    </mat-horizontal-stepper>
</div>