import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeZero',
})
export class RemoveZeroPipe implements PipeTransform {

  transform(phone: string): string {
    if (!phone) return phone; {
      phone = phone.replace(/\D/g, '');
    }
    if (phone.startsWith('0')) {
      phone = '+33' + phone.slice(1);
    } else {
      phone = '+33' + phone
    }
    // return phone.replace(/(\+33)(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5 $6');
    return phone
  }

}
