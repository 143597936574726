import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    Inject,
    Input,
    OnInit,
    Output,
    EventEmitter,
    QueryList,
    ViewChildren,
    ViewChild
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { LocaleService } from '../../../app.locale.service';
import { DrawerComponent } from '../../../core';
import { SpinnerService } from '../../../core/spinner/spinner.service';
import { LoaderService, MessageService } from '../../../main';
import { Company, Role } from '../../models/common.model';
import { CommonApiService } from '../../services/common-api.service';
import { PatientService } from '../../services/patient.service';
import { ResetPasswordServiceService } from '../../services/reset-password-service.service';
import { User, SamplingStations } from '../user/User';
import { UserService } from '../user/user.service';
@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit, DrawerComponent {
    @Input() sliderFormData: any;
    @Input() public isSliderDataEdited = false;
    @Output() userList = new EventEmitter<User>();
    dateFrom = new FormControl();
    dateTo = new FormControl();
    phoneNumber = new FormControl();
    selectedPhoneCountryCode: string;
    title: string;
    companies: Company[] = null;
    roles: Role[] = null;
    user: User[];
    samplingStations: SamplingStations[];
    samplingNames: any[] = [];
    selectedSamplingStation: any[];
    UserForm: FormGroup;
    saveData: {};
    loginId: any;
    dataFieldsFilled: boolean;
    sendPasswordconfirmtext = 'add-user.generate-password';
    saveAlert = {
        edit: 'admin.save-alert.edit',
        add: 'admin.save-alert.add'
    };
    errorMessage = 'admin.error-message';
    fromDateMin: Date;
    toDateMin: Date;
    selectedDate: Date;
    disableDropDowns = false;
    enableFocus = true;
    selectAll = 'add-sampling.select-all';

    constructor(
        private readonly dialogRef: MatDialogRef<AddUserComponent>,
        private commonAPi: CommonApiService,
        public userService: UserService,
        private readonly messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private readonly spinner: SpinnerService,
        private translate: TranslateService,
        private localeService: LocaleService,
        private resetPasswordService: ResetPasswordServiceService,
        private patientService: PatientService,
        public loader: LoaderService,
    ) {
        this.samplingStations = new Array<SamplingStations>();
        this.intitializeUserForm();
        this.title = this.data.edit ? 'add-user.edit' : 'add-user.add';
        this.setInitialMinDate();
        this.selectedSamplingStation = new Array<any>();
        if (this.data.edit) {
            this.loginId = this.data.user.login_id;
        }
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }
    intitializeUserForm() {
        this.UserForm = this.formBuilder.group({
            Id: [''],
            FirstName: [
                null,
                [Validators.required, Validators.pattern(".{1,50}$")]
            ],
            LastName: [
                null,
                [Validators.required, Validators.pattern(".{1,50}$")]
            ],
            Email: [
                null,
                [
                    Validators.required,
                    Validators.pattern('.{1,60}$'),
                    Validators.pattern(this.patientService.emailPattern)
                ]
            ],
            Company: [null, [Validators.required]],
            Role: 0,
            Status: ['true', [Validators.required]],
            dateFrom: [null],
            dateTo: [null],
            SamplingStationsIds: [null, [Validators.required]],
            phoneNumber: ['']
        });
    }

    @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

    ngAfterViewInit(): void {
        setTimeout(
            () =>
                this.formFields.forEach((ff) => {
                    // console.log(ff);
                    ff.updateOutlineGap();
                }),
            100
        );
    }
    bindUserObject(userDetails: any) {
        const sss: Array<any> = new Array<any>();
        const sc: Array<any> = new Array<any>();
        userDetails?.OrgDetails?.forEach((element: any) => {
            const comp = this.companies.find(i => i.id == element.id);
            if (comp != undefined) {
                sc.push(comp);
            }
        });
        this.samplingStations.forEach((element: any) => {
            const ss = userDetails?.SampDetails.find((i: { id: any; }) => i.id == element.id);
            if (ss != undefined) {
                sss.push(ss);
            }
        });
        this.UserForm.patchValue({
            Id: userDetails.id,
            FirstName: userDetails.first_name,
            LastName: userDetails.last_name,
            Email: userDetails.email,
            Status: userDetails.is_active,
            Company: sc,
            SamplingStationsIds: sss,
            Role: userDetails.role_id,
            dateFrom: userDetails.valid_from,
            dateTo: userDetails.valid_to,
            phoneNumber: userDetails.phone_number
        });
        this.dateFrom.setValue(userDetails.valid_from);
        this.fromDateMin = new Date(this.dateFrom.value);

        this.dateTo.setValue(userDetails.valid_to);
        this.changeMinDateInDatePicker(this.dateFrom.value);
        if (userDetails.phone_number != '') {
            this.phoneNumber.setValue('+' + userDetails.phone_number);
        }
    }
    @ViewChild('phone')
    phone: NgxMatIntlTelInputComponent;
    ngOnInit(): void {
        this.getRoles();
        this.getCompanyList();
        this.getAllSamplingStations();
    }

    private getRoles() {
        this.commonAPi.getAllRoles().subscribe((y) => {
            this.roles = y;
            if (this.data.edit) {
                this.UserForm.patchValue({
                    Role: this.data.user.role_id
                });
                this.resetValidation(this.data.user.role_id)
            }
        });
    }

    private getAllSamplingStations() {
        this.commonAPi.GetAllSamplingStations(true).subscribe((stationsList: any) => {
            this.samplingStations = stationsList.sampling_stations;
            this.selectedSamplingStation = stationsList.sampling_stations;
            if (this.data.edit) {
                this.UserForm.controls.Email.disable();
                if (this.data.user.role_id == 5) {
                    // admin role
                    this.data.user.org_id = 0;
                    this.disableDropDowns = true;
                    this.UserForm.controls['Company'].setValue(0);
                    this.UserForm.controls['SamplingStationsIds'].setValue('');
                    this.bindUserObject(this.data.user);
                } else {
                    this.bindUserObject(this.data.user);
                }
                this.changeFocusOfRenewButton();
                this.ssFilterOnCompany(this.data.user.OrgDetails);
            }
        });
    }

    private getCompanyList() {
        this.commonAPi.GetCompany().subscribe((x) => {
            this.companies = x;
        });
        if (this.data.edit) {
            this.UserForm.patchValue({
                Company: this.data.user.OrgDetails,
                SamplingStationsIds: this.data.user.SampDetails
            });
        }
    }

    ssFilterOnCompany(event: any) {
        this.samplingStations = Object.assign([], this.selectedSamplingStation);
        const cArr = event.map((item: any) => item.id);
        const myArrayFiltered = this.samplingStations.filter((el: any) => {
            return cArr.some((f: any) => {
                return f === el.org_id;
            });
        });
        this.samplingStations = myArrayFiltered;


        let selectedSS = this.UserForm.controls['SamplingStationsIds'].value;
        let filterSelectedSS: any = [];
        selectedSS.forEach((element: any) => {
            let availableSS = this.samplingStations.filter(i => i.id == element.id);
            if (availableSS.length > 0) {
                filterSelectedSS.push(availableSS[0]);
            }
        });
        if (this.UserForm.controls['Role'].value != 5) {
            this.UserForm.controls['SamplingStationsIds'].setValue(filterSelectedSS);
        }
        else {
            this.UserForm.controls['SamplingStationsIds'].setValue(0);
        }
    }

    allChecked(event: any) {
        if (event) {
            this.selectedSamplingStation.forEach(item => {
                this.samplingStations.push(item);
            });
        } else {
            this.samplingStations = [];
        }
    }

    public roleChange(event: any): void {
        if (event.source.value == 5) {
            this.disableDropDowns = true;
            this.UserForm.controls['Company'].setValue(0);
            this.UserForm.controls['SamplingStationsIds'].setValue(0);
        } else {
            this.disableDropDowns = false;
            this.UserForm.controls['Company'].setValue('');
            this.UserForm.controls['SamplingStationsIds'].setValue('');
            if (!this.data.edit) {
                this.UserForm.controls['Company'].setValidators([Validators.required]);
                this.UserForm.controls['SamplingStationsIds'].setValidators([Validators.required]);
            }
            // this.samplingStations = [];
        }
    }

    public resetValidation(roldid: number) {
        if (roldid == 5) {
            this.UserForm.controls['Company'].clearValidators();
        }
    }

    public dismiss() {
        this.dialogRef.close(null);
    }

    clear() {
        this.dateFrom.setValue(null);
        this.dateTo.setValue(null);
        this.dateFrom.reset();
        this.dateTo.reset();
        this.phone.reset();

        if (this.data.edit) {
            this.UserForm.reset({ Id: this.UserForm.get('Id').value });
            this.UserForm.controls['Email'].setValue(this.data.user.email);
        } else {
            this.UserForm.reset();
        }
    }

    onSave() {
        const datePipe = new DatePipe("en-US");
        if (this.UserForm.valid && this.dateFrom.valid && this.dateTo.valid) {
            this.displaySpinner(true);
            let ssListArr = '0';
            let companyListArr = 0;
            if (!this.disableDropDowns) {
                ssListArr = this.UserForm.controls.SamplingStationsIds.value.map((item: any) => item?.id).filter(function (item: any, pos: any, self: any) {
                    return self.indexOf(item) == pos;
                }).join(',');

                companyListArr = this.UserForm.controls.Company.value[0].id;
            }

            const obj = {
                id: this.UserForm.controls.Id.value,
                first_name: this.UserForm.controls.FirstName.value,
                last_name: this.UserForm.controls.LastName.value,
                email: this.UserForm.controls.Email.value,
                is_active: this.UserForm.controls.Status.value,
                role_id: this.UserForm.controls.Role.value,
                org_id: companyListArr,
                valid_to: datePipe.transform(this.dateTo.value, "yyyy-MM-dd"),
                valid_from: datePipe.transform(this.dateFrom.value, "yyyy-MM-dd"),
                sampling_station_ids: ssListArr,
                login_id: this.loginId,
                phone_number: this.phoneNumber?.value?.replace('+', '')
            };
            if (this.data.edit) {
                this.userService.updateAdminUserPut(obj).subscribe(
                    () => {
                        this.displaySpinner(false);
                        this.messageService.success(
                            this.translate.instant(this.saveAlert[this.data.action])
                        );
                        this.dialogRef.close(null);
                    },
                    (errorResponse: HttpErrorResponse) => {
                        this.displaySpinner(false);
                        console.log(errorResponse);
                        this.dialogRef.close(null);
                    }
                );
                // this.userService.getAdminUsers();
                // this.success(this.saveAlert[this.data.action]);
            } else {

                this.userService.AddUserPostRequest(obj).subscribe(
                    () => {
                        this.displaySpinner(false);
                        this.messageService.success(
                            this.translate.instant(this.saveAlert[this.data.action])
                        );
                        this.dialogRef.close(null);
                    },
                    (errorResponse: HttpErrorResponse) => {
                        this.displaySpinner(false);
                        console.log(errorResponse);
                        if (errorResponse.error.code === 422) {
                            this.messageService.alert(
                                this.translate.instant(errorResponse.error.message)
                            );
                        }
                        this.dialogRef.close(null);
                    }
                );
            }
        }
    }
    public displaySpinner(flag: boolean): void {
        this.spinner.show(flag);
    }
    selectCountry() { }

    /**
   * Setting the initial Min Date to date pickers.
   */
    setInitialMinDate() {
        this.fromDateMin = new Date();
        this.toDateMin = new Date();
        this.toDateMin.setDate(this.fromDateMin.getDate() + 1);
    }

    /**
   * This method will envoke when user will change the date by datepicker or manually.
   * @param e ChangedDate Value
   */
    onSelectedDate(e: any) {
        this.changeMinDateInDatePicker(e);
    }

    /**
   * This Method will set the min date on To Date Picker field according to selected value in From Date Picker
   * @param inputData Date Value
   * @returns returns the method without setting any min date if the date value is invalid.
   */

    changeMinDateInDatePicker(inputData: any) {
        this.selectedDate = inputData;
        const datePipe = new DatePipe('en-US');

        const formattedDate = datePipe.transform(this.selectedDate, 'dd-MM-yyyy');
        if (formattedDate == null) {
            return;
        }

        const splittedDateArray = formattedDate.split('-');

        const year = Number(splittedDateArray[2]);
        const month = Number(splittedDateArray[1]) - 1;
        const date = Number(splittedDateArray[0]) + 1;

        this.toDateMin = new Date(year, month, date);
    }

    confirmSendPasswordWithYesNo() {
        this.messageService
            .confirm(this.translate.instant(this.sendPasswordconfirmtext), '')
            .subscribe((actionResult: boolean) => {
                if (actionResult) {
                    this.sendPasswordLink();
                }
            });
    }

    sendPasswordLink() {
        const inputValue = {
            email: this.UserForm.controls.Email.value
        };
        this.resetPasswordService.postAdminAuthforget(inputValue).subscribe(
            (inputValue) => {
                console.log(inputValue);
                this.messageService.success(
                    this.translate.instant('login.mail-success')
                );
            },
            (errorResponse: HttpErrorResponse) => {
                console.log(errorResponse);
                this.dialogRef.close(null);
            }
        );
    }

    /**
   * Change the fcus of Reset button
   */
    changeFocusOfRenewButton() {
        setTimeout(() => {
            this.enableFocus = false;
        }, 2000);
    }

    selectedCountry(event: any) {
        console.log(event);
        // this.selectedPhoneCountryCode = "+" + event.dialCode;
        // console.log(this.selectedPhoneCountryCode);
    }

    public deleteUser(): void {
        this.messageService
            .confirm(
                this.translate.instant("user.delete-sure"),
                this.translate.instant("user.delete-confirm")
            )
            .subscribe((actionResult: boolean) => {
                if (actionResult) {
                    this.commonAPi.deleteUser(this.data?.user?.id).subscribe(
                        () => {
                            this.messageService.success(
                                this.translate.instant('admin.save-alert.delete')
                            );
                            this.dismiss();
                        },
                        () => {
                            this.messageService.alert(
                                this.translate.instant("user.error.422")
                            );
                        }
                    );
                }
            });
    }
}
