import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { LoaderService, MessageService } from '../../../../main';
import { constantsarr } from '../../constant';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-check-file-ready',
  templateUrl: './check-file-ready.component.html',
  styleUrl: './check-file-ready.component.scss'
})
export class CheckFileReadyComponent {
  @Output() goBack = new EventEmitter<any>();

  @Output() goNext = new EventEmitter<any>();
  @Output() selectedHaveFile = new EventEmitter<any>();
  public isSelected: boolean = false;
  public haveFileReady = new FormControl("0", [Validators.required]);
  private subscription: Subscription = new Subscription();
  percent: number;
  constructor(
    public loaderService: LoaderService, public messageService: MessageService,
    public translateService: TranslateService, private cdr: ChangeDetectorRef
  ) { }
  ngOnInit(): void {
    this.subscription.add(
      this.loaderService.variable$.subscribe(value => {
        this.percent = (value / this.loaderService.flow_arr.length) * 100;
        this.cdr.detectChanges(); // Manually trigger change detection
      })
    );
  }
  onChange(event: any) {
    if (event.value == 0) {
      this.loaderService.flow_arr = constantsarr.inside_lab_withoutpresc
    }
    this.loaderService.action_required = false;
  }

  protected select(option: any) {
    if (this.haveFileReady.value == "" || this.haveFileReady.value != option) {
      this.isSelected = !this.isSelected;
      this.haveFileReady.setValue(option);
    }
    if (this.haveFileReady.value == "0") {
      //this.loaderService.flow_arr = constantsarr.inside_lab_withpresc;
      this.selectedHaveFile.emit(true);
    }
    else if (this.haveFileReady.value == "1") {
      //this.loaderService.flow_arr = constantsarr.inside_lab_withoutpresc;
      this.selectedHaveFile.emit(false);
    }
  }

  goToNext() {
    if (this.haveFileReady.value == "0" || this.haveFileReady.value == "1") {
      this.goNext.emit();
    }
    else {
      this.messageService.alert(
        this.translateService.instant("select-proceed"),
        5000
      );
    }
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  goToBack() {
    this.goBack.emit(true);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

}
