export class HeroBannerWrapper {
    constructor() {
        this.en = new HeroBanner();
        this.fr = new HeroBanner();
    }
    public en: HeroBanner;
    public fr: HeroBanner;
    public tab_name: string;
}

export class HeroBanner {
    public title?: string;
    public sub_title?: string;
    public video_url?: string;
    public top_blue_bar_message?: string;
}

export class KeyFeatureWrapper {
    constructor() {
        this.en = new KeyFeature();
        this.fr = new KeyFeature();
    }
    public en: KeyFeature;
    public fr: KeyFeature;
    public tab_name: string;
}

export class KeyFeature {
    public faq?: string;
    public key_feature_1?: string;
    public key_feature_2?: string;
    public key_feature_3?: string;
    public key_feature_4?: string;
}

export class OurPresenceWrapper {
    constructor() {
        this.en = new OurPresence();
        this.fr = new OurPresence();
    }
    public en: OurPresence;
    public fr: OurPresence;
    public tab_name: string;
}

export class OurPresence {
    public section_title: string;
    public section_image_url: string;
    public section_image_name: string;
}
export class PatientJourneyWrapper {
    constructor() {
        this.en = new PatientJourney();
        this.fr = new PatientJourney();
    }
    public en: PatientJourney;
    public fr: PatientJourney;
    public tab_name: string;
}
export class PatientJourney {
    constructor() {
        this.steps = new Array<JourneyStep>();
    }
    public section_title: string;
    public steps: Array<JourneyStep>
    // public step_one_title: string;
    // public step_one_desc: string;
    // public step_one_image_url: string;
    // public step_two_title: string;
    // public step_two_desc: string;
    // public step_two_image_url: string;
    // public step_three_desc: string;
    // public step_three_title: string;
    // public step_three_image_url: string;
    // public step_four_desc: string;
    // public step_four_title: string;
    // public step_four_image_url: string;
}

export class JourneyStep {
    public title: string;
    public desc: string;
    public image_url: string;
    public image_name: string;
}

export class FrequentlyBookedTestWrapper {
    constructor() {
        this.en = new FrequentlyBookedTest();
        this.fr = new FrequentlyBookedTest();
    }
    public en: FrequentlyBookedTest;
    public fr: FrequentlyBookedTest;
    public tab_name: string;
}
export class FrequentlyBookedTest {
    public section_title: string;
}

export class CompanyInfoWrapper {
    constructor() {
        this.en = new CompanyInfo();
        this.fr = new CompanyInfo();
    }
    public en: CompanyInfo;
    public fr: CompanyInfo;
    public tab_name: string;
}
export class CompanyInfo {
    public section_title: string;
    public field_one_name: string;
    public field_two_name: string;
    public field_one_value: string;
    public field_two_value: string;
    public field_three_name: string;
    public field_three_value: string;
    public website_link_field_name: string;
    public website_link_field_value: string;
}
export class SEOWrapper {
    public page_data: SEO;
    public tab_name: string;
}
export class SEO {
    public html_tags: string;
    public meta_desc: string;
}




export class LandingPageData {
    public tab_name: string;
    public page_data: PageData
}

export class PageData {
    public en: En
    public fr: Fr
}
export class En {
    public section_title?: string;
    public title?: string;
    public sub_title?: string;
    public video_url?: string;
    public top_blue_bar_message?: string;
    public faq?: string;
    public key_feature_1?: string;
    public key_feature_2?: string;
    public key_feature_3?: string;
    public key_feature_4?: string;
    public html_tags?: string;
    public meta_desc?: string;
    public field_one_name?: string;
    public field_two_name?: string;
    public field_one_value?: string;
    public field_two_value?: string;
    public field_three_name?: string;
    public field_three_value?: string;
    public website_link_field_name?: string;
    public website_link_field_value?: string;
    public section_image_url?: string;
    public step_one_desc?: string;
    public step_two_desc?: string;
    public step_four_desc?: string;
    public step_one_title?: string;
    public step_two_title?: string;
    public step_four_title?: string;
    public step_three_desc?: string;
    public step_three_title?: string;
    public step_one_image_url?: string;
    public step_two_image_url?: string;
    public step_four_image_url?: string;
    public step_three_image_url?: string;
}

export class Fr {
    public section_title?: string;
    public title?: string;
    public sub_title?: string;
    public video_url?: string;
    public top_blue_bar_message?: string;
    public faq?: string;
    public key_feature_1?: string;
    public key_feature_2?: string;
    public key_feature_3?: string;
    public key_feature_4?: string;
    public html_tags?: string;
    public meta_desc?: string;
    public field_one_name?: string;
    public field_two_name?: string;
    public field_one_value?: string;
    public field_two_value?: string;
    public field_three_name?: string;
    public field_three_value?: string;
    public website_link_field_name?: string;
    public website_link_field_value?: string;
    public section_image_url?: string;
    public step_one_desc?: string;
    public step_two_desc?: string;
    public step_four_desc?: string;
    public step_one_title?: string;
    public step_two_title?: string;
    public step_four_title?: string;
    public step_three_desc?: string;
    public step_three_title?: string;
    public step_one_image_url?: string;
    public step_two_image_url?: string;
    public step_four_image_url?: string;
    public step_three_image_url?: string;
}


export class JsonResponse {
    public tab_name: string;
    public page_data: string;
}

export class RequestData {
    constructor() {
        this.image_data = new Array<ImageInfo>();
    }
    public tab_name: string;
    public page_data: string;
    public image_data: ImageInfo[];
}

export class ImageInfo {
    public image_url: string;
    public image_data: string;
}
