import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
/** Service to store and retrieve data from local storage */
export class LocalStorageCacheService {

    constructor(private readonly localStorage: LocalStorage) { }

    /* #region Public Methods */
    /** Method used to set data in local storage asynchronously */
    public setItem<T>(key: string, value: T): Observable<boolean> {
        return this.localStorage.setItem(key, value);
    }

    /** Method used to get data from local storage asynchronously */
    public getItem<T>(key: string): Observable<T> {
        return this.localStorage.getItem(key) as any;
    }
    /* #endregion */
}
