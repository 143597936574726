<!-- {{calendarSlots|json}} -->
<!-- {{slotDuration|json}} -->
<button class="close" mat-dialog-close mat-button>X</button>
<mat-dialog-content class="mat-typography view-booking">
    <div *ngIf="step==1">
        <ng-container>
            <div class="d-flex">
                <div class="section-title">{{'view-booking.existing-appointment' | translate}}</div>
                <div class="progress-bar-container">
                    {{bookedAppointments.length}}/{{slotDuration.capacity}}
                    <mat-progress-bar [value]="progressValue" class="progress-bar mt-30"></mat-progress-bar>
                </div>
            </div>
            <mat-table [dataSource]="dataSource" matSort class="table-height input-min-spacing table-flex"
                id="EntityListingTable">
                <ng-container matColumnDef="PatientID">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="PatientID">
                        <span>{{'view-booking.patientid' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let entity">
                        {{ entity.patient_id }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="PatientName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="PatientName">
                        <span>{{'view-booking.patientname' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let entity">
                        {{ entity.patient_name }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="Date">
                        <span>{{'view-booking.date' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let entity">
                        {{ entity.slot_time | date :'dd/MM/yyyy, HH:mm'}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Test">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="Test">
                        <span>{{'view-booking.test' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let entity">
                        {{entity.test_name}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Options">
                    <mat-header-cell *matHeaderCellDef> {{'view-booking.options' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let entity">
                        <button rippleOnHover class="mat-tbl-icon editEntity_btn mr-8" mat-icon-button type="button"
                            (click)="editMode=true;editAppointment(entity)">
                            <span class="icon-ic-edit" matTooltip="{{'view-booking.edit' | translate}}"
                                matTooltipClass="tooltip-style"></span>
                        </button>
                        <button rippleOnHover class="mat-tbl-icon deleteEntity_btn mr-8" type="button"
                            (click)="deleteAppointment(entity.id,entity.patient_id)" mat-icon-button>
                            <span class="icon-ic-delete" matTooltip="{{'view-booking.delete' | translate}}"
                                matTooltipClass="tooltip-style"></span>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columnList; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columnList; let i = index" class="element-grid-row"
                    (mouseover)="rowMouseOver(row)">
                </mat-row>
            </mat-table>
            <ng-container *ngIf="dataSource.data.length==0">
                <div class="no-record">{{'view-booking.no-record' | translate}}</div>
            </ng-container>
            <div *ngIf="dataSource.data.length< bookedAppointments.length" class="d-flex justify-content-end pt-10">
                <button (click)="viewAll()" mat-raised-button color="primary">
                    {{'view-booking.view-all' | translate}}
                </button>
            </div>


        </ng-container>

        <ng-container>
            <div class="d-flex py-15">
                <div class="section-title">{{'view-booking.new-appointment' | translate}}</div>
            </div>
            <form (ngSubmit)="onSearch()">
                <div class="grid-container">
                    <div id="inputsearch" class="">
                        <mat-form-field>
                            <mat-label>{{'view-booking.name-ssn' | translate}}</mat-label>
                            <input [(ngModel)]="spr.MixedData" name="mixed_data" matInput
                                [disabled]="bookedAppointments.length==slotDuration.capacity" />
                        </mat-form-field>
                    </div>
                    <div>
                        <app-datepicker id="dateofBirth" labelText="{{'view-booking.dob' | translate}}"
                            name="search_dob" [dateInput]="dateofBirth" [maxDate]="maxDate"
                            [isReadOnly]="bookedAppointments.length==slotDuration.capacity"
                            [isDisabled]="bookedAppointments.length==slotDuration.capacity"></app-datepicker>
                    </div>
                    <div id="inputsearch" class="">
                        <mat-form-field>
                            <mat-label>{{'view-booking.qr-id' | translate}}</mat-label>
                            <input [(ngModel)]="spr.QrId" maxlength="13" (keypress)="omit_special_char($event)" max="13"
                                name="qr_id" matInput [disabled]="bookedAppointments.length==slotDuration.capacity" />
                        </mat-form-field>
                    </div>
                    <div id="statusfilter" class="mr-4">
                        <mat-form-field def="Status" class="dropdown-filter">
                            <mat-label class="label-float">{{'admin.status' | translate}}</mat-label>
                            <mat-select name="Test" name="test-item" [formControl]="statusControl"
                                (selectionChange)="onStatusSelect($event)"
                                [disabled]="bookedAppointments.length==slotDuration.capacity">
                                <mat-option value=true>{{'admin.active' | translate}}</mat-option>
                                <mat-option value=false>{{'admin.suspended' | translate}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div id="searchbtn" class="">
                        <button type="submit" mat-raised-button color="primary"
                            [disabled]="bookedAppointments.length==slotDuration.capacity">
                            {{'view-booking.search' | translate}}
                        </button>
                    </div>
                    <div id="resetbtn" class="">
                        <button type="button" (click)="reset()" mat-button color="accent" class="ghost"
                            [disabled]="bookedAppointments.length==slotDuration.capacity">
                            {{'view-booking.reset' | translate}}
                        </button>
                    </div>
                </div>
            </form>
            <div class="main-container" *ngIf="newAppointmentDataSource.data.length>0">
                <mat-table [dataSource]="newAppointmentDataSource" matSort
                    class="table-height input-min-spacing table-flex">
                    <ng-container matColumnDef="ID">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>{{'view-booking.id' | translate}}</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let entity">
                            {{ entity.id }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="FamilyName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>{{'view-booking.family-name' | translate}}</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let entity">
                            {{ entity.birth_name }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="FirstName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>{{'view-booking.first-name' | translate}}</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let entity">
                            {{ entity.first_name }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="DateofBirth">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>{{'view-booking.dob' | translate}}</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let entity">
                            {{ entity.dob | date }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Gender">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>{{'view-booking.gender' | translate}}</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let entity">
                            {{ entity.gender }}
                        </mat-cell>
                    </ng-container>
                    <!-- <ng-container matColumnDef="Nationality">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>Nationality</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let entity">
                            {{ entity.nationality }}
                        </mat-cell>
                    </ng-container> -->
                    <ng-container matColumnDef="SSN/Passport">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>{{'view-booking.passport' | translate}}</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let entity">
                            {{ entity.ssn }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Email">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>{{'view-booking.email' | translate}}</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let entity">
                            {{ entity.email }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="PhoneNumber">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>{{'view-booking.phone-number' | translate}}</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let entity">
                            {{ entity.phone_number }}
                        </mat-cell>
                    </ng-container>
                    <!-- <ng-container matColumnDef="Address">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>Address</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let entity">
                            {{ entity.address }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="City">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>City</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let entity">
                            {{ entity.city }}
                        </mat-cell>
                    </ng-container> -->
                    <mat-header-row *matHeaderRowDef="newAppointmentColumnList; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: newAppointmentColumnList; let i = index"
                        class="element-grid-row" (mouseover)="rowMouseOver(row,true)" (click)="selectRow(row)">
                    </mat-row>
                </mat-table>
            </div>
            <ng-container *ngIf="newAppointmentDataSource.data.length==0 && isSubmitted">
                <div class="no-record"> {{noPatientFoundText | translate}}</div>
            </ng-container>
        </ng-container>
    </div>
    <div *ngIf="step==2" class="d-flex-center h-100">
        <div class="update-booking-container">
            <div class="header">
                <div class="text-center">
                    <app-section-title title="{{editBookingTitle | translate}}"></app-section-title>
                </div>
            </div>

            <form class="pt-30" #bookAppForm="ngForm" (ngSubmit)="submitAppointment(bookAppForm.valid)">
                <div id="viewBooking_form" class="form-layout">
                    <mat-form-field>
                        <mat-label class="label-float">{{'view-booking.patient-id' | translate}}</mat-label>
                        <input matInput name="patient_id" id="patient_id" [(ngModel)]="selectedPatient.id"
                            aria-readonly="true" readonly />
                    </mat-form-field>


                    <mat-form-field>
                        <mat-label class="label-float">{{'view-booking.patient-name' | translate}}</mat-label>
                        <input matInput name="patient_name" id="patient_name" [(ngModel)]="selectedPatient.patient_name"
                            aria-readonly="true" readonly />
                    </mat-form-field>

                    <div class="d-flex">
                        <!-- <button mat-button>{{selectedPatient.slot_time|date :'dd/MM/yyyy, h:mm a'}}</button> -->
                        <app-time-picker class="d-flex w-100" [dateTimeValueInput]='dateTimePickerValue'
                            datePlaceholder="{{'view-booking.select-date' | translate}}" hourPlaceholder="Hours"
                            minutePlaceholder="Minutes" [isDisplayReset]="false" [min]="startDate" [max]="endDate"
                            readonly="true" isDisable="true">
                        </app-time-picker>
                        <button mat-button color="accent" type="button"
                            (click)="weekChange(selectedWeek,null)">{{'view-booking.change' | translate}}</button>
                    </div>


                    <!-- <app-datepicker id="bookingDate" labelText="Date" [dateInput]="selectedPatient.slot_time" required></app-datepicker> -->
                    <!-- <mat-form-field def="Test"> -->
                    <!-- <mat-label class="label-float">Test</mat-label> 
                        <mat-select name="Test" name="test-item" [(ngModel)]="selectedPatient.test_id"
                            (selectionChange)="onTestSelect($event.value)" required>
                            <mat-option *ngFor="let testItem of testItems" [value]="testItem.id">
                                {{ testItem.short_name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <mat-form-field class="dropdown-filter " name="capacity">
                        <mat-select disableOptionCentering id="test_item" [(ngModel)]="selectedPatient.test_id"
                            name="test_id" required>
                            <mat-option *ngFor="let testItem of testItems" [value]="testItem.id">
                                {{ testItem.short_name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="d-flex justify-content-center mt-20">
                    <button mat-raised-button color="primary" type="submit">
                        {{'admin.save' | translate}}
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div *ngIf="step==3">
        <app-calendar fromModal="true" (fromModalEvent)="selectedSlot($event)" [calendarSlots]="calendarSlots"
            [calendar]="slotDuration" [selectedWeek]="selectedWeek" [localeLang]="localeService.localeLang"
            [bookedSlotsCount]="data.bookedSlotsCount" (viewDateChange)="weekChange($event,null)"></app-calendar>

    </div>
</mat-dialog-content>