<div class="slider-data-container">
    <div class="header">
        <div class="pull-left" [ngClass]="{'back-icon':step>0}">
            <span class="icon-ic-back" *ngIf="step>0 && step!=3 && step!=5" (click)="step=0"></span>
            <app-section-title *ngIf="step==0"
                title="{{'manage-access.title'|translate }} {{rmInfo?.rm_fname}} {{rmInfo?.rm_lname}}">
            </app-section-title>
            <app-section-title *ngIf="step==1" title="{{'share-access.title'|translate }}">
            </app-section-title>
            <app-section-title *ngIf="step==2" title="{{'delete-rm.title'|translate }}">
            </app-section-title>
            <app-section-title *ngIf="step==3" title="{{'remove-access.title'|translate }}">
            </app-section-title>
            <app-section-title *ngIf="step==4 || step==5" title="{{'elevate-account.title'|translate }}">
            </app-section-title>
        </div>
        <div class="pull-right">
            <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
                <span class="icon-ic-close"></span>
            </button>
        </div>
    </div>

    <ng-container *ngIf="step==0">
        <div class="member-form-container">
            <div class="note-container">
                <span>{{'manage-access.head1'|translate}} {{rmInfo?.rm_fname}}
                    {{rmInfo?.rm_lname}}{{'manage-access.head2'|translate}}
                </span>
            </div>
            <div class="feature-wrapper">
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue">
                    </span>
                    <span>{{'manage-access.feature1'|translate}}
                    </span>
                </div>
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue">
                    </span>
                    <span>{{'manage-access.feature2'|translate}}
                    </span>
                </div>
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue ">
                    </span>
                    <span>{{'manage-access.feature3'|translate}}
                    </span>
                </div>
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue">
                    </span>
                    <span>{{'manage-access.feature4'|translate}}
                        {{'manage-access.feature4-part2'|translate}}
                    </span>
                </div>

            </div>
        </div>

        <div class="access-container">
            <div class="section-wrapper">
                <div class="section">
                    <div class="bold"> {{'manage-access.section1-head'|translate}}</div>
                    <div class="highlight-grey"> {{'manage-access.section1-note1'|translate}} {{rmInfo?.rm_fname}}
                        {{rmInfo?.rm_lname}}.
                    </div>
                    <div class="action-container" (click)="step=4">
                        <div class="icon-ic-add icon">
                        </div>
                        <span>{{'manage-access.section1-btn'|translate}}
                        </span>
                    </div>
                </div>

                <div class="section">
                    <div class="bold">{{'manage-access.section2-head'|translate}}</div>
                    <div class="highlight-grey">{{'manage-access.section2-note1'|translate}} {{rmInfo?.rm_fname}}
                        {{rmInfo?.rm_lname}} {{'manage-access.section2-note2'|translate}}.</div>
                    <div class="action-container" (click)="step=2">
                        <div class="icon-ic-delete icon">
                        </div>
                        <span>{{'manage-access.section2-btn'|translate}}
                        </span>
                    </div>
                </div>


                <div class="section">
                    <div class="bold">{{'manage-access.section3-head'|translate}} {{rmInfo?.rm_fname}}
                        {{rmInfo?.rm_lname}}</div>
                    <div class="highlight-grey"> {{'manage-access.section3-note1'|translate}} {{rmInfo?.rm_fname}}
                        {{rmInfo?.rm_lname}}.</div>
                    <div class="guardian-wrapper" *ngIf="rmInfo?.sec_id !=''">
                        <div class="info-container">
                            <span
                                class="titleWrapBox">{{rmInfo?.sec_fname.charAt(0)+rmInfo?.sec_lname.charAt(0)}}</span>
                            <div class="information">
                                <div>{{rmInfo?.sec_fname}} {{rmInfo?.sec_lname}}</div>
                                <div>{{rmInfo?.sec_email}}</div>
                            </div>
                        </div>
                        <div class="action-container" (click)="step=3">
                            <div class="icon-ic-close icon">
                            </div>
                            <span>{{'manage-access.section3-btn'|translate}}
                            </span>
                        </div>
                    </div>

                    <div class="add-guardian-wrapper cp" *ngIf="rmInfo?.sec_id==''" (click)="step=1">
                        <span class="icon-ic-add customAddIcon"></span>
                        <span class="highlight-link">{{'manage-access.section3-add-new'|translate}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step==1">
        <div class="mt-10">{{'share-access.caption-title'|translate}}</div>
        <div class="member-form-container">
            <div class="note-container">
                <span>{{'share-access.head1'|translate}}
                </span>
            </div>
            <div class="feature-wrapper">
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue">
                    </span>
                    <span>{{'share-access.feature1'|translate}}
                    </span>
                </div>
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue">
                    </span>
                    <span>{{'share-access.feature2'|translate}}
                    </span>
                </div>
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue ">
                    </span>
                    <span>{{'share-access.feature3'|translate}}
                    </span>
                </div>
            </div>
        </div>

        <div class="access-container">
            <div class="note-container">
                {{'share-access.sub-title'|translate }}
            </div>
            <div class="section-wrapper">
                <div class="section">
                    <div class="highlight-grey">{{'share-access.description'|translate}}</div>
                    <mat-form-field>
                        <mat-label>{{'add-member.email' | translate}}</mat-label>
                        <input matInput name="email" id="email" [formControl]="shareEmail" required />
                    </mat-form-field>
                    <!-- <div>{{'share-access.note-description'|translate}}</div> -->
                </div>
            </div>
        </div>
        <!-- <div class="highlight-grey">{{'share-access.info'|translate}}</div> -->

        <div class="actions">
            <div class="inner-btn">
                <button mat-raised-button color="primary" class="button_width" type="button" (click)="shareAccess()"
                    id="btn_save">
                    {{'share-access.title' | translate}}
                </button>

            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="step==2">
        <div class="member-form-container">
            <div class="note-container">
                <span>{{'delete-rm.parent-text'|translate}}
                </span>
            </div>
            <div class="feature-wrapper">
                <div class="feature-container">
                    <span class="icon-ic-cross_circle_filled highlight-blue">
                    </span>
                    <span>{{'delete-rm.child-text1'|translate}}
                    </span>
                </div>
                <div class="feature-container">
                    <span class="icon-ic-cross_circle_filled highlight-blue">
                    </span>
                    <span>{{'delete-rm.child-text2'|translate}}
                    </span>
                </div>
                <div class="feature-container">
                    <span class="icon-ic-cross_circle_filled highlight-blue ">
                    </span>
                    <span>{{'delete-rm.child-text3'|translate}}
                    </span>
                </div>
                <div class="feature-container">
                    <span class="icon-ic-cross_circle_filled highlight-blue ">
                    </span>
                    <span>{{'delete-rm.child-text4'|translate}}
                    </span>
                </div>
            </div>
            <div *ngIf="rmInfo?.sec_id.length > 0">
                <div class="note-container line-height">
                    <span>{{'delete-rm.note'|translate}}
                    </span>
                </div>
                <div class="blue-txt">
                    <span>{{rmInfo?.sec_fname + ' ' + rmInfo?.sec_lname}}</span>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="inner-btn">
                <button mat-raised-button color="primary" class="button_width" type="button" (click)="deletePatient()"
                    id="btn_delete">
                    {{'delete-rm.delete-btn' | translate}}
                </button>

            </div>
        </div>

    </ng-container>
    <ng-container *ngIf="step==3">
        <ng-container *ngIf="rmInfo.is_owner_account">
            <div class="member-form-container">
                <div class="note-container">
                    <span> {{'remove-access.parent-text-1' | translate}}
                        {{rmInfo?.sec_fname}} {{rmInfo?.sec_lname}} {{'remove-access.parent-text-2' |
                        translate}}
                        {{rmInfo?.rm_fname}}
                        {{rmInfo?.rm_lname}}'s:
                    </span>
                </div>
                <div class="feature-wrapper">
                    <div class="feature-container">
                        <span class="web-icon icon icon-ic-cross_circle_filled highlight-blue">
                        </span>
                        <span>{{'remove-access.child-text-1'|translate}}
                        </span>
                    </div>
                    <div class="feature-container">
                        <span class="web-icon icon icon-ic-cross_circle_filled highlight-blue">
                        </span>
                        <span>{{'remove-access.child-text-2'|translate}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="note-container highlight-grey" *ngIf="!rmInfo.is_primary">
                <span>{{'remove-access.note'|translate}}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="!rmInfo.is_owner_account">
            <div class="member-form-container">
                <div class="note-container">
                    <span>
                        <ng-container>{{'remove-access.parent-text-3' |
                            translate}}</ng-container>
                        {{rmInfo?.rm_fname}}
                        {{rmInfo?.rm_lname}}'s:
                    </span>
                </div>
                <div class="feature-wrapper">
                    <div class="feature-container">
                        <span class="web-icon icon icon-ic-cross_circle_filled highlight-blue">
                        </span>
                        <span>{{'remove-access.child-text-3'|translate}}
                        </span>
                    </div>
                    <div class="feature-container">
                        <span class="web-icon icon icon-ic-cross_circle_filled highlight-blue">
                        </span>
                        <span>{{'remove-access.child-text-4'|translate}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="note-container highlight-grey" *ngIf="!rmInfo.is_primary">
                <span>{{'remove-access.note-1'|translate}}
                </span>
            </div>
        </ng-container>
        <div class="actions">
            <div class="inner-btn">
                <button mat-raised-button color="primary" class="button_width" type="button" (click)="removeAccess()">
                    {{'remove-access.remove-acc-btn' | translate}}
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="step==4">
        <div class="member-form-container">
            <div class="note-container">
                <span>{{'elevate-account.head1'|translate}}
                </span>
            </div>
            <div class="feature-wrapper">
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue">
                    </span>
                    <span>{{'elevate-account.feature1'|translate}}
                    </span>
                </div>
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue">
                    </span>
                    <span>{{'elevate-account.feature2'|translate}}
                    </span>
                </div>
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue ">
                    </span>
                    <span>{{'elevate-account.feature3'|translate}}
                    </span>
                </div>
                <div class="feature-container">
                    <span class="icon-ic-tick_circle_filled highlight-blue ">
                    </span>
                    <span>{{'elevate-account.feature4'|translate}}
                    </span>
                </div>
            </div>
        </div>

        <div class="access-container">
            <div class="note-container">
                {{'elevate-account.sub-title-1'|translate}} {{rmInfo?.rm_fname}}
                {{rmInfo?.rm_lname}}{{'elevate-account.sub-title-2'|translate }}
            </div>
            <div class="section-wrapper">
                <div class="section">
                    <mat-form-field>
                        <mat-label>{{'add-member.email' | translate}}</mat-label>
                        <input matInput name="email" id="email" [formControl]="elevateEmail" required />
                        <mat-error *ngIf="!elevateEmail.valid  && !elevateEmail.hasError('emailExist')">
                            {{'createAccount.valid-email'| translate}}</mat-error>
                        <mat-error *ngIf="!elevateEmail.valid && elevateEmail.hasError('emailExist')">
                            {{'update-email.current-email-new-email-not-same' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="inner-btn">
                <button mat-raised-button color="primary" class="button_width" type="button" (click)="updateEmail()"
                    id="btn_save">
                    {{'elevate-account.send-invite' | translate}}
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="step==5">
        <div id="otpinner_container">
            <p class="otp-header">{{'update-email.confirm-email' | translate}}{{elevateEmail.value}}</p>
            <h3>{{'login.otp-entermessage' | translate}}</h3>
            <mat-hint class="otp-hint">{{'login.otp-hint' | translate}}</mat-hint>
            <br>
            <ng-otp-input clas="otp" #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
            <div class="otp-counter">
                <h5>{{'login.otp-time-remaining' | translate}} : {{display}}</h5>
                <button [disabled]="btndisabled" color="primary" type="submit" class="btn-login" (click)="resetOTP()">
                    {{'login.otp-resend' | translate}} </button>
            </div>
            <div class="otp-buttons">
                <button mat-raised-button color="primary" type="submit" class="btn-login" (click)="elevateAccess()">
                    {{'login.otp-verify' | translate}} </button>
            </div>
        </div>
    </ng-container>
</div>