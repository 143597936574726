import { LogLevel } from './log-level.enum';

export class LogEntry {
    /* #region Fields */
    private readonly message: string = '';
    private readonly level: LogLevel = LogLevel.Debug;
    private readonly extraInfo: any[] = [];
    private readonly logWithDate: boolean = true;
    /* #endregion */

    constructor(message: string, level: LogLevel, extraInfo: any[], logWithDate: boolean) {
        this.message = message;
        this.level = level;
        this.extraInfo = extraInfo;
        this.logWithDate = logWithDate;
    }

    /* #region Public Methods */
    /** Method to build or consturct a log message */
    public buildLogString(): string {
        let ret = '';
        if (this.logWithDate) {
            ret = `${new Date()} - `;
        }
        ret += `Type: ${LogLevel[this.level]} - Message: ${this.message}`;
        if (this.extraInfo.length) {
            ret += ` - Extra Info: ${this.formatParams(this.extraInfo)}`;
        }
        return ret;
    }
    /* #endregion */

    /* #region Private Methods */
    /** Method to build or construct a message based on extra info passed to logger */
    private formatParams(params: any[]): string {
        let ret: string = params.join(',');
        if (params.some(p => typeof p === 'object')) {
            ret = '';
            for (const item of params) {
                ret += `${JSON.stringify(item)},`;
            }
        }
        return ret;
    }
    /* #endregion */
}
