<div *ngIf="!pdfUpdate" class="upload-pic-container p-20">
  <div class="header">
    <div class="pull-left">
      <app-section-title title="{{'view-prescription.title'|translate}}"></app-section-title>
    </div>
    <div class="pull-right">
      <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
        <span class="icon-ic-close"></span>
      </button>
    </div>
  </div>
  <div class="formCointainer">
    <div>
      <app-section-title title="{{'view-prescription.appointment-details'|translate}}"></app-section-title>
      <div class="info-box">
        <div class="title">
          <app-section-title title="{{'view-prescription.test'|translate}}"></app-section-title>
          <div>{{sliderFormData?.TestShortName}}</div>
        </div>
        <div class="title">
          <app-section-title title="{{'view-prescription.date'|translate}}"></app-section-title>
          <div>{{sliderFormData?.SlotTime | dateFormat}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="view-prescription">
    <app-section-title title="{{'view-prescription.file-name'|translate}}"></app-section-title>
    <hr>
    <div *ngFor="let url of prescriptions; index as i">
      <div class="prescription-row">
        <div class="prescription-name">
          {{'view-prescription.prescription'|translate}}
        </div>
        <div class="prescription-controls">
          <div class="button">
            <button class="mat-tbl-icon" matTooltip="{{'all-apointment.view_prescription'|translate}}" mat-icon-button
              (click)="openPdf(url, i)">
              <span class="icon-ic-view-prescription"></span>
            </button>
          </div>
          <div class="button" *ngIf="roleId === 4">
            <button class="mat-tbl-icon" [disabled]="isPastAppointment"
              matTooltip="{{'all-apointment.replace_prescription'|translate}}" mat-icon-button
              (click)="updatePrescription(url)">
              <span class="icon-ic-replace-prescription"></span>
            </button>
          </div>
          <!-- <div class="button" *ngIf="roleId === 4">
            <button class="mat-tbl-icon" [disabled]="isPastAppointment"
              matTooltip="{{'view-prescription.delete-prescription'|translate}}" mat-icon-button
              (click)="deletePrescription(url)">
              <span class="icon-ic-delete"></span>
            </button>
          </div> -->
        </div>
      </div>
      <hr>
    </div>
  </div>
</div>
<app-pdf-viewer *ngIf="isPdfViewerOpened" pdftitle="{{'view-prescription.prescriptions'|translate}}" [blobSrc]="pdfBlob"
  (closed)="isPdfClosed()" (replacePrescriptionClicked)="isPdfReplaced()"
  [replacePrescription]="roleId === 4 && !isPastAppointment" [patientComments]="patientComments"
  [allPages]="true"></app-pdf-viewer>

<div *ngIf="pdfUpdate">
  <div class="header">
    <div class="pull-left back-icon">
      <button class="slider_closebtn" mat-button color="accent" (click)="goBack()">
        <span class="icon-ic-back"></span>
      </button>
      <app-section-title title="{{'view-prescription.replace-prescription'|translate}}"></app-section-title>
    </div>
    <div class="pull-right">
      <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
        <span class="icon-ic-close"></span>
      </button>
    </div>
  </div>
  <div class="pres-container" [class.red]="uploadStatus">
    <app-appointment-prescription [document]="item" [prescription]="true"
      (updateDocument)="getUpdateDocument($event)"></app-appointment-prescription>
  </div>
  <div class="actions-wrapper">
    <button mat-raised-button color="primary" type="submit" class="ghost ml-10" (click)="fileUploaded()">
      {{'upload-pic.upload' | translate}}
    </button>
  </div>
</div>