import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { LocaleService } from "../../../app.locale.service";
import { SpinnerService } from "../../../core";
import { Product } from "../../../features/styles/models/product.model";
import { LoaderService, MessageService } from "../../../main";
import { PatientService } from "../../services/patient.service";
import { AddPatientComponent } from "../add-patient/add-patient.component";
import { CommonDialogComponent } from "../common-dialog/common-dialog.component";
import { UserService } from "./user.service";
import { DatePipe } from '@angular/common'

@Component({
  selector: "app-patients",
  templateUrl: "./patients.component.html",
  styleUrls: ["./patients.component.scss"],
})
export class PatientsComponent implements OnInit {
  public isFilterable = false;
  public isSearchable = false;
  public errorStatusCode: string;
  public dataSource: MatTableDataSource<any>;
  public hoverindex = "";
  // public steps: SampleProcess[] = sampleData;
  sampleData: { [key: string]: string } = {
    All: "company.all",
    Active: "admin.active",
    Suspended: "admin.suspended",
  };
  searchinput = "";
  status = "All";
  loading = true;
  editUserFlag = false;
  public columnList = [
    "Icon",
    "first_name",
    "last_name",
    "birth_name",
    "dob",
    "gender",
    "nationality_name",
    "ssn",
    "email",
    "phone_number",
    "address",
    "Status",
    "icon-column",
  ];
  filterObject = {
    search: "",
    status: "",
  };
  samplingStations = {
    id: 0,
    name: "",
  };
  savealert = {
    edit: "admin.save-alert.edit",
    add: "admin.save-alert.add",
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedIndex: number;
  public pageSize = 10;
  public currentPage = 1;
  public totalRecords = 0;
  public filterText: string = "";
  public filterApplied: boolean = false;
  constructor(
    private readonly dialog: MatDialog,
    private readonly messageService: MessageService,
    private readonly spinner: SpinnerService,
    public userService: UserService,
    private translate: TranslateService,
    private localeService: LocaleService,
    public loader: LoaderService,
    public patientService: PatientService,
    public datepipe: DatePipe
  ) {
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
    this.dataSource = new MatTableDataSource<any>();
  }

  ngOnInit(): void {
    this.loadPatients();
    this.dataSource.sortingDataAccessor = (
      data: any,
      sortHeaderId: string
    ): string => {
      if (typeof data[sortHeaderId] === "string") {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };
  }

  expandRow(index: number) {
    this.selectedIndex = index !== this.selectedIndex ? index : null;
  }

  public loadPatients() {
    const searchReq = {
      MixedData: this.filterApplied ? this.filterText : "",
      QrId: "", Dob: "", pageSize: this.pageSize,
      pageNumber: this.currentPage,
      IsActive: this.status == "Active" ? 1 : this.status == "Suspended" ? 2 : 0
    };
    this.spinner.show(true);

    this.patientService.getPatients(searchReq).subscribe(
      (userList: any) => {
        // this.spinner.show(true);
        this.dataSource = userList?.data;
        if (userList?.count != this.paginator.length) {
          this.totalRecords = userList?.count;
        }
        this.spinner.show(false);

      },

      (error) => {
        console.log(error);
        this.spinner.show(false);
      }
    );
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  /** Method to identify hovered grid row */
  public rowMouseOver(row: any): void {
    this.hoverindex = row.ID;
  }
  public addPatient(): void {
    const dialogRef = this.dialog.open(AddPatientComponent, {
      width: "900px",
      disableClose: true,
      maxHeight: "90vh",
      data: { action: "add", edit: false, savealert: this.savealert },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      this.loadPatients();
    });
  }
  public editPatient(entity: any): void {
    this.editUserFlag = true;
    const dialogRef = this.dialog.open(AddPatientComponent, {
      width: "900px",
      disableClose: true,
      maxHeight: "90vh",
      data: {
        action: "edit",
        edit: true,
        patient: entity,
        savealert: this.savealert,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      this.loadPatients();
    });
  }

  onPageChange(evt: any) {
    this.currentPage = evt?.pageIndex + 1;
    this.pageSize = evt?.pageSize;
    this.loadPatients();
  }
  public edit($event: MouseEvent, product: Product): void {
    const message = `${product.Pid}. '${product.Title}'  Edited Susccessfully`;
    this.messageService.primary(this.translate.instant(message));
    $event.stopPropagation();
  }
  public reset(): void {
    this.filterApplied = false;
    this.filterText = "";
    this.searchinput = "";
    this.status = "All";
    this.loadPatients();
  }

  public displaySpinner(): void {
    this.spinner.show(true);
    // setTimeout(() => {
    //     this.spinner.show(false);
    // }, 5000);
  }

  onSearch() {
    this.pageSize = 10;
    this.currentPage = 1;
    this.totalRecords = 0;
    this.paginator.pageIndex = 0;
    this.filterApplied = true;
    this.filterText = JSON.parse(JSON.stringify(this.searchinput));
    this.loadPatients();
  }

  /**
   * This method will execute the when user will try to use space key keyboard and in the search box if the space is first character then
   * it will wont allow space to execute, It will block the space untill user enter a character.
   * @param event
   */
  keyDownHandler(event: Event) {
    if (this.searchinput.length === 0) {
      event.preventDefault();
    }
  }
  /**
   * This method will show the data in list dialog.
   * @param entity Selected Row Data
   */
  showAffecationsData(entity: any) {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      width: "450px",
      height: "350px",
      disableClose: true,
      data: { title: "SS Affecations", user: entity },
    });
    dialogRef.afterClosed().subscribe(() => {
      // this.loadSamplingStations();
    });
  }

  public deleteUser(id: any): void {
    this.messageService
      .confirm(
        this.translate.instant("user.delete-sure"),
        this.translate.instant("user.delete-confirm")
      )
      .subscribe((actionResult: boolean) => {
        if (actionResult) {
          this.patientService.deletePatient(id).subscribe(
            () => {
              this.messageService.success(
                this.translate.instant('admin.save-alert.delete')
              );
              this.loadPatients();
            },
            (errorResponse: any) => {
              if (errorResponse.error.internal_code === 3003) {
                this.messageService.alert(
                  this.translate.instant("patient.error.3003")
                );
              }
              else {
                this.messageService.alert(
                  this.translate.instant("patient.error.422")
                );
              }
            }
          );
        }
      });
  }

  /* Method to view data in the given order */
  unsorted() { }
}
