import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-common-dialog',
    templateUrl: './common-dialog.component.html',
    styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent implements OnInit {
    title: string;
    company: boolean;
    listData: string[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.title = this.data.title;
        this.company = this.data.company;
        if (this.company) {
            for (const item of this.data.user.OrgDetails) {
                this.listData.push(item.name);
            }

        } else {
            for (const item of this.data.user.SampDetails) {
                this.listData.push(item.name);
            }
        }


    }

    ngOnInit(): void {}
}
