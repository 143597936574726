import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { PasswordPolicy } from './error.messages';
import { PasswordPolicyState } from './password-policy-state.modal';
import { PasswordPolicyService } from './password-policy.service';

@Component({
    selector: 'app-password-policy',
    templateUrl: './password-policy.component.html',
    styleUrls: ['./password-policy.component.scss']
})
export class PasswordPolicyComponent implements OnInit, OnDestroy {
    @Output() isValid = new EventEmitter();
    @Input() modal: boolean;
    password: string;
    show = false;
    length: any;
    valid: any;
    capital: any;
    small: any;
    number: any;
    symbol: any;
    min: any;
    max: any;
    private subscription: Subscription;
    public PasswordPolicies = {
        intLengthRequirement: PasswordPolicy.INT_LENGTH_REQUIREMENT,
        priLengthRequirement: PasswordPolicy.PRI_LENGTH_REQUIREMENT,
        nurseLengthRequirement: PasswordPolicy.NURSE_LENGTH_REQUIREMENT,
        upperCaseRequirement: PasswordPolicy.UPPERCASE_REQUIREMENT,
        lowerCaseRequirement: PasswordPolicy.LOWERCASE_REQUIREMENT,
        numericRequirement: PasswordPolicy.NUMERIC_REQUIREMENT,
        specialCharRequirement: PasswordPolicy.SPECIAL_CHARACTER_REQUIREMENT,
        characterList: PasswordPolicy.CHARACTER_LIST_1 + PasswordPolicy.CHARACTER_LIST_2
    };

    constructor(
        private policyService: PasswordPolicyService
    ) { }

    ngOnInit() {
        this.subscription = this.policyService.policyState
            .subscribe((state: PasswordPolicyState) => {
                this.show = state.show;
                this.password = state.password;
                this.min = state.min;
                this.max = state.max;
                this.checkValidity();
                this.checkAllValidity();
            });
    }

    checkValidity() {
        this.length = this.policyService.length(this.password, this.min, this.max);
        this.capital = this.policyService.capital(this.password);
        this.small = this.policyService.small(this.password);
        this.number = this.policyService.number(this.password);
        this.symbol = this.policyService.symbol(this.password);
    }

    getColor(val: boolean) {
        if (val) {
            return '#90ee90';
        } else {
            return '#fa7268';
        }
    }

    getBackgroundColor(val: boolean) {
        if (val) {
            return '#6bd1c2';
        } else {
            return '#fa7268';
        }
    }

    checkAllValidity() {
        this.valid = this.policyService.valid(this.password, this.min, this.max);
        this.isValid.emit(this.valid);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
