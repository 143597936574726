// import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../../main/services/loader.service';
import { MessageService } from '../../../main/services/message.service';
// import { LoaderService } from '../../../main';
import { PatientAppointmentService } from '../../services/patient-appointment.service';

@Component({
    selector: 'app-testkits-without-result',
    templateUrl: './testkits-without-result.component.html',
    styleUrls: ['./testkits-without-result.component.scss']
})
export class TestkitsWithoutResultComponent implements OnInit {
    public dateFilter = new FormControl('');
    displayedColumns: string[] = [
        'name',
        'qr_id',
        'barcode',
        'short_name',
        'sampling_time',
        'actions'
    ];
    testName: any;
    dataSource: any = [];
    originalDataSource: any = [];
    totalCount = 0;
    refresh = true;
    confirmContent = 'testkits-without-result-com.confirm-title';
    confirmTitle = 'testkits-without-result-com.confirm-content';
    testkitDeleted = 'testkits-without-result-com.testkit-deleted';

    constructor(
        private patientAppointmentService: PatientAppointmentService,
        private readonly messageService: MessageService,
        private loader: LoaderService,
        public translate: TranslateService
    ) {}

    ngOnInit(): void {
        console.log(this.dateFilter);

        this.getTestKitWithoutResultData();

        this.loader.onSSChangeCallback().subscribe(() => {
            console.log('sampling stn id ' + this.loader.ss_id);
            this.getTestKitWithoutResultData();
        });
        // this.dataSource.filterPredicate = (data: any, filter: any) => {
        //   return (
        //     new Date(data.sampling_time).toDateString() ==
        //     new Date(filter).toDateString()
        //   );
        // };

    // let dataSource1 = this.originalDataSource.filter((appointment: any) => {
    //   return (
    //     new Date(appointment.sampling_time).toDateString() ==
    //     new Date(selectedDate).toDateString()
    //   );
    // });
    }
    getTestKitWithoutResultData() {
        this.refresh = false;
        this.patientAppointmentService
            .getTestKitWithoutResult(this.loader.ss_id)
            .subscribe((testKitWithoutResultList: any) => {
                this.dataSource = [];
                if (testKitWithoutResultList.testKitWithoutResult != null) {
                    this.refresh = true;
                    this.dataSource = testKitWithoutResultList.testKitWithoutResult;
                    this.originalDataSource =
            testKitWithoutResultList.testKitWithoutResult;
                    console.log(this.originalDataSource);
                }

                this.totalCount = JSON.parse(
                    JSON.stringify(testKitWithoutResultList)
                ).total;

                if (this.totalCount == 0) {
                    this.resetWithDataSource();
                } else {
                    this.resetWithoutDataSource();
                }

                // console.log(JSON.parse(JSON.stringify(testKitWithoutResultList)).testKitWithoutResult);
            });
    }




    cancelTestKit(element: any) {

        this.messageService
            .confirm(
                this.translate.instant(this.confirmTitle),
                this.translate.instant(this.confirmContent)
            )
            .subscribe((actionResult: boolean) => {
                if (actionResult) {
                    this.refresh = false;
                    const patientArrivalData = {
                        appointment_id: element.appointment_id,
                        test_id: element.test_id,
                        barCode: element.barcode,
                        patientId: element.id
                    };

                    this.patientAppointmentService.deletePatientAppointment(patientArrivalData).subscribe(data => {
                        if (data['message'] == 'appointment deleted successfully') {
                            this.messageService.success(this.translate.instant(this.testkitDeleted), 5000);
                            this.refresh = true;
                            this.getTestKitWithoutResultData();
                        } else {
                            this.messageService.warning(this.translate.instant(JSON.stringify(data)['message']), 5000);
                        }
                    },
                    (error: any) => {
                        this.messageService.warning(this.translate.instant(error['message']), 5000);

                    });
                } else {
                    this.refresh = true;
                }
            });
    }

    // public searchAPI(event:any){
    //   let selecteddateFilter="";
    //   selecteddateFilter =this.datepipe
    //   .transform(event, "yyyy-MM-dd").toString()
    //   let samplingStation="";
    //   samplingStation=this.loader.ss_id;
    //   console.log(selecteddateFilter);
    //   this.patientAppointmentService
    //   .getTestKitWithoutResult(
    //     samplingStation,
    //     selecteddateFilter,
    //   )
    //   .subscribe((testKitWithoutResultList: any)=>{
    //     this.dataSource=JSON.parse(JSON.stringify(testKitWithoutResultList)).testKitWithoutResult;
    //     this.totalCount=JSON.parse(JSON.stringify(testKitWithoutResultList)).total;
    //   })
    // }
    // public input(event:any){
    //   //console.log(event)
    //  console.log(this.datepipe
    //   .transform(event, "yyyy-MM-dd").toString()) ;

    // }
    // this.loader.ss_id
    /**
   * This method will envoke when user will change the date by datepicker or manually.
   * @param selectedDate ChangedDate Value
   */
    onSelectedDate(selectedDate: any) {
        this.dataSource = this.originalDataSource.filter((appointment: any) => (
            new Date(appointment.sampling_time).toDateString() ==
        new Date(selectedDate).toDateString()
        ));

        this.totalCount = this.dataSource.length;
        if (this.totalCount == 0) {
            this.resetWithDataSource();
        } else {
            this.resetWithoutDataSource();
        }

        console.log('data source length : ' + this.dataSource.length);
    }

    resetWithDataSource() {
        const self = this;
        self.refresh = false;
        setTimeout(() => {
            self.refresh = true;
            self.dataSource = [];
        }, 0);
    }

    resetWithoutDataSource() {
        const self = this;
        self.refresh = false;
        setTimeout(() => {
            self.refresh = true;
        }, 0);
    }

    submitResult(data: any) {
        console.log('selected row : ' + data);
        this.loader.highlightLeftMenu(['receptionist/register-result'], {
            state: { testKitData: data }
        });
    }
}
