<div class="ecl-table-container" [ngClass]="{'p-0':infoPage==true}">
  <ng-container *ngIf="!infoPage">
    <div class="header">

      <div class="result-header w-100">
        <mat-form-field class="search-input">
          <input matInput #searchInput [formControl]="seacrchInput" placeholder="{{'results.search' | translate}}"
            name="search" id="search" (keyup)="findPatient()" />
          <div *ngIf="!searchInput.value" (click)="scanQR()" class="bar-code-icon"><span
              class="icon-ic-scan-barcode"></span></div>
          <div *ngIf="searchInput.value" (click)="clearSearch(); reset()" class="clear">
            <p>{{'results.clear-search' | translate}}</p>
          </div>
        </mat-form-field>

        <mat-form-field class="test">
          <mat-label class="label-float">{{'add-sampling.type' | translate}}</mat-label>
          <mat-select [formControl]="testFormControl" multiple (selectionChange)="onTestSelected($event)">
            <app-select-check-all [model]="testFormControl" [values]="testList" (allChecked)="allChecked($event)">
            </app-select-check-all>
            <mat-option *ngFor="let testtype of testList" [value]="testtype" class="twolineText">{{testtype.short_name
              }}</mat-option>
          </mat-select>
        </mat-form-field>

        <app-datepicker id="appointmentDate" class="test1" labelText="{{'add-sampling.test-date' | translate}}"
          [dateInput]="appointmentDate" (selectedDate)="onSelectedDate($event)">
        </app-datepicker>

      </div>
    </div>
    <div>
      <h3>{{'results.results' | translate}}</h3>
    </div>
  </ng-container>
  <mat-tab-group disableRipple [@.disabled]="true" class="tab-app pull-left" animationDuration="1000ms">
    <mat-tab
      label="{{'results.all' | translate}} ({{allResultsdataSource?.length > 0 ? allResultsdataSource.length : 0}})"
      *ngIf="!infoPage">
      <app-all-results [refresh]="refresh" [displayedColumns]="displayedColumns" [dataSource]="allResultsdataSource"
        [resultStatusList]="resultStatusList" (editTestClicked)="editTestClicked($event)"
        (nameClicked)="onNameClicked($event)" (showResult)="showResultData($event)" (pdfviewClicked)="openPdf($event)">
      </app-all-results>
    </mat-tab>
    <mat-tab
      label="{{'results.waiting-results' | translate}} ({{watitingResultsdataSource?.length > 0 ? watitingResultsdataSource.length : 0}})">
      <app-waiting-results [refresh]="refresh" [infoPage]="infoPage" [displayedColumns]="displayedColumns"
        [dataSource]="watitingResultsdataSource" [resultStatusList]="resultStatusList"
        (nameClicked)="onNameClicked($event)">
      </app-waiting-results>
    </mat-tab>
    <mat-tab
      label="{{'results.results-ready' | translate}} ({{readyResultsdataSource?.length > 0 ? readyResultsdataSource.length : 0}})">
      <app-results-ready [refresh]="refresh" [infoPage]="infoPage" [displayedColumns]="displayedColumns"
        [dataSource]="readyResultsdataSource" (showResult)="showResultData($event)"
        [resultStatusList]="resultStatusList" (nameClicked)="onNameClicked($event)">
      </app-results-ready>
    </mat-tab>
  </mat-tab-group>

  <!-- <app-results-ready *ngIf="infoPage" [refresh]="refresh" [infoPage]="infoPage" [displayedColumns]="displayedColumns"
    [dataSource]="readyResultsdataSource" (showResult)="showResultData($event)" [resultStatusList]="resultStatusList"
    (nameClicked)="onNameClicked($event)">
  </app-results-ready> -->
  <app-pdf-viewer *ngIf="isPdfViewerOpened" [blobSrc]="pdfBlob" pdftitle="{{'results.sample-download'|translate}}"
    [pdfFileName]="sampleFileName" [allPages]="true" (closed)="isPdfViewerOpened = false">
  </app-pdf-viewer>
  <app-pdf-viewer *ngIf="isPdfResultViewerOpened" [blobSrc]="pdfBlob" pdftitle="{{'stepper-flow.result'|translate}}"
    [pdfFileName]="resultId" [allPages]="true" (closed)="isPdfResultViewerOpened = false">
  </app-pdf-viewer>
</div>