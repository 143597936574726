<div class="token-detail-container">
    <div class="section-header">
        <circle-progress class="only-web" [options]="loader.optionsG" [percent]="100" [renderOnClick]="false"
            imageSrc="assets/images/application-logos/svg/ic-scheckin-success.svg" class="copy">
        </circle-progress>
        <h3 class="desc-title">{{'token-detail.title' | translate}}</h3>
    </div>
    <div class="arrived-section">
        <img src="assets/images/application-logos/svg/ic-ticket.svg" alt="ticket">
        <div class="info">{{'token-detail.virtual-ticket' | translate}}</div>
        <!-- isKioskAvailable step = 2 -->
        <div class="arrived-title">
            {{ (step==1 ? 'token-detail.register-number' : 'token-detail.ticket-number') | translate }}
        </div>
        <div class="request-number">{{req_number}}</div>
        <div class="warning-section" *ngIf="!appointmentTolerance">{{'token-detail.time-warning' | translate}}</div>
        <div class="arrived-info">
            {{ (step==1 ? 'token-detail.registration-completed' : 'token-detail.registration-info') | translate }}
        </div>
        <div class="divider"></div>
        <div class="info">{{'token-detail.docs-ready' | translate}}</div>
        <div class="prepare-docs">
            <img src="assets/images/application-logos/svg/ic-virtual.svg" alt="id-card">
            <img src="assets/images/application-logos/svg/ic-pres.svg" alt="pres">
        </div>
        <div class="info mail-info">{{'token-detail.ticket-sent' | translate}}</div>
    </div>
</div>