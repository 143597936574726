import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '../../main';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../app.locale.service';
import { BookingAppointmentGuestService } from '../../eClinical/services/booking-appointment-guest.service';
import { AppSettingsService } from '../../core';
import { CompanyInfoWrapper, FrequentlyBookedTestWrapper, HeroBannerWrapper, KeyFeatureWrapper, OurPresenceWrapper, PatientJourneyWrapper, SEOWrapper } from '../../eClinical/admin/landing-page-config/landing-page-config.model';
import { constants } from '../../eClinical/shared/constant';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LandingPageComponent implements OnInit {
    @Input() heroBanner: HeroBannerWrapper;
    @Input() keyFeatures: KeyFeatureWrapper;
    @Input() fbtw: FrequentlyBookedTestWrapper;
    @Input() companyInfo: CompanyInfoWrapper;
    @Input() ourPresence: OurPresenceWrapper;
    @Input() seoInfo: SEOWrapper;
    @Input() patientJourney: PatientJourneyWrapper;
    @Input() videoUrl: any;
    public faqIndex: number = 0;
    randomNumber: number;
    testType: any[] = [];
    constructor(private translateService: TranslateService,
        public localeService: LocaleService, private readonly router: Router,
        public loader: LoaderService,
        private bookingAppointmentService: BookingAppointmentGuestService,
        protected readonly appSettings: AppSettingsService,
        public sanitizer: DomSanitizer) {
        this.translateService.setDefaultLang(this.localeService.localeLang);
        this.translateService.use(this.localeService.localeLang);
        this.randomNumber = Math.floor(1000 + Math.random() * 9000);
        this.appSettings.getLabCount;
        this.appSettings.patientBaseUrl
        this.appSettings.patientBaseUrl
    }

    ngOnInit(): void {
        if (this.loader.loggedIn) {
            switch (this.loader.userRole) {
                case 1:
                    this.loader.highlightLeftMenu(['receptionist/find-patient']);
                    break;
                case 3:
                    this.loader.highlightLeftMenu(['admin/user']);
                    break;
                case 4:
                    this.loader.highlightLeftMenu(['patient/dashboard']);
                    break;
                case 5:
                    this.loader.highlightLeftMenu(['admin/user']);
                    break;
            }
        }
        else {

            if (window.location.href.includes("patient/book-appointment") || window.location.href.includes("patient/complete-appointment") || window.location.href.includes("auth/login")
                || window.location.href.includes("auth/password_reset") || window.location.href.includes("auth/verify_email")
                || window.location.href.includes("auth/account-confirmation") || window.location.href.includes("patient/set_password")
                || window.location.href.includes("auth/set_password") || window.location.href.includes("patient/faq")
                || window.location.href.includes("auth/document") || window.location.href.includes("auth/relative_member")
                || window.location.href.includes("auth/result")
                || window.location.href.includes("auth/ss") || window.location.href.includes("patient/elevate")) {
                this.loader.isPreLoginFLow = true;
                this.loader.mobileLogin = true;
                this.loader.highlightLeftMenu([window.location.href.split("#/")[1]]);
            }
            this.getTestTypes();
        }
    }

    getTestTypes() {
        this.bookingAppointmentService.getAdmintestsSorted().subscribe(
            (getResponse) => {
                const vals = [];
                for (const i in getResponse) {
                    vals.push(getResponse[i]);
                    this.testType.push(getResponse[i]);
                }
                this.testType = this.testType.map(test => { return { ...test, isOpen: false } });
            }
        );
    }

    redirectLogin() {
        this.loader.isPreLoginFLow = true;
        this.router.navigate(['auth/login']);
    }

    redirectBooking() {
        this.loader.isPreLoginFLow = true;
        this.loader.highlightLeftMenu(["patient/book-appointment"]);
    }
    redirectLab() {
        this.loader.isPreLoginFLow = true;
        this.loader.highlightLeftMenu(["patient/book-appointment/0"]);
    }

    openFaq(): void {
        this.loader.isPreLoginFLow = true;
        if (this.loader.userRole == constants.adminRole) {
            this.router.navigate(['admin/faq']);
        } else if (this.loader.userRole == constants.patientRole) {
            this.router.navigate(['patient/faq']);
        } else if (this.loader.userRole == constants.nurseRole) {
            this.router.navigate(['receptionist/faq']);
        } else if (this.loader.userRole == constants.supervisorRole) {
            this.router.navigate(['admin/faq']);
        } else {
            this.router.navigate(['patient/faq']);
        }
    }

    onTestTypeError(index: number) {
        this.testType[index].url =
            'https://ecldevproductimage.blob.core.windows.net/productimage/20220927-3ccec799-af06-48b1-9d50-45381998d081.jpg';
    }

    redirectTestType(testTypeId: number) {
        this.loader.isPreLoginFLow = true;
        this.loader.highlightLeftMenu(["patient/book-appointment/test/" + testTypeId]);
    }

    openCurrent(index: number) {
        this.testType.forEach((element: any) => {
            element.isOpen = false;
        });
        this.testType[index].isOpen = true;
    }

    redirectGuestRelative() {
        this.loader.isPreLoginFLow = true;
        this.loader.highlightLeftMenu(['/auth/relative_member']);
        setTimeout(() => { window.scrollTo(0, 0); }, 0);
    }

    redirectFAQ() {
        this.loader.isPreLoginFLow = true;
        this.loader.highlightLeftMenu(['/patient/faq']);
        setTimeout(() => { window.scrollTo(0, 0); }, 0);
    }

    clickFaq(index: number) {
        console.log(this.faqIndex);
        this.faqIndex = index;
        console.log(this.faqIndex);
    }
}
