import { constants, constantsarr } from '../constant';

import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FormControl,
  Validators,
} from "@angular/forms";

import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { LocaleService } from "../../../app.locale.service";
import { LoaderService, MessageService } from "../../../main";
import { BookingAppointmentGuestService } from "../../services/booking-appointment-guest.service";
import { CommonApiService } from "../../services/common-api.service";
import { PatientService } from "../../services/patient.service";
import { PrescriptionDocument } from "../appointment-prescription/appointment-prescription.model";
import { DateSlot } from "../date-slot/date-slot.model";

import * as moment from "moment";
import jwtDecode from "jwt-decode";
import { LoginServiceService } from "../../services/login-service.service";
import { ToastrService } from "ngx-toastr";
import { SpinnerService } from '../../../core';
import { PatientAppointmentService } from '../../services/patient-appointment.service';
import { Subscription } from 'rxjs';
declare var _paq: Array<any>;
@Component({
  selector: 'app-complete-booking',
  templateUrl: './complete-booking.component.html',
  styleUrl: './complete-booking.component.scss',
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: "DD-MM-YYYY",
        },
        display: {
          dateInput: "DD-MM-YYYY",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        },
      },
    },
  ],
})

export class CompleteBookingComponent implements OnInit, OnDestroy {
  step: number = 0
  readonly constants = constants;
  readonly rconstantsarr = constantsarr;
  isConfirmed = false;
  isLoaded = false;
  clearFile = false;
  currentindex: number = 0;
  @Input() isBackEnabled: boolean;
  @Output() backClicked = new EventEmitter();
  isFileUploadedOrSkip = false;
  protected showPrescription: boolean;
  protected showLoginorRegister = "";
  protected showLoginorRegisterStep: boolean;
  maxDate: any;
  minDate: any;
  public slotTime = new FormControl("");
  bookingComment: string;
  protected slotData: DateSlot;
  public isWithAppointment: boolean = true;
  public patientRole = constants.patientRole;
  public registeredEmail = new FormControl("", [
    Validators.required,
    Validators.pattern(this.patientService.emailPattern),
  ]);
  login_id: any;

  calendar_id = 56;
  tests: any[] = [];
  prescription_url = "";
  selectedtest: any;
  selectedLab: any;
  slotGroupId: number;
  s_id: any = 0;
  userDetails: any;
  showLogin: boolean;
  public showLoginPrebook = true;
  showConfirmBooking = false;
  locale: string = this.localeService.localeLang;
  @Output() handleNavigationNodes = new EventEmitter();
  showRegister: boolean = false;
  showActivateMessage: boolean = false;
  forgetPasswordMailSent: boolean;
  loginSuccessful: boolean;
  registerSuccessful: boolean;
  public authType = new FormControl("", [Validators.required]);
  forgotPassword: boolean;
  ss_id = "";

  protected isClicked: boolean = false;
  protected documents: PrescriptionDocument[] = [
    {
      Id: 1,
      PrescriptionParts: [],
      IsIncorrectFileFormat: false,
      IsInvalidFileSize: false,
      Expanded: true,
    },
  ];
  protected noDocuments: boolean = true;
  isMassiveTesting: boolean = false;
  protected uploadStatus: boolean = false;
  public progressPercent: any;
  public u_id: string;
  public a_id: string;
  public is_active: boolean = true;
  public doc_id: string;
  public is_prescription: boolean = true;
  age: number = 0;
  selectedTest: { calendar_id: number, short_name: string; long_name: string; description: string; start_time: any, comment: string };
  appointmentInfo: any;
  patientDocuments: any;
  private subscription: Subscription = new Subscription();
  percent: number;
  invalidLink: boolean = false;
  constructor(
    public patientService: PatientService,
    private messageService: MessageService,
    private commonAPi: CommonApiService,
    private translate: TranslateService,
    private localeService: LocaleService,
    private route: ActivatedRoute,
    public bookingAppointmentService: BookingAppointmentGuestService,
    public loaderService: LoaderService,
    public spinner: SpinnerService,
    private dateAdapter: DateAdapter<Date>,
    private loginService: LoginServiceService,
    public toastr: ToastrService,
    public patientAppointmentService: PatientAppointmentService,
    private cdr: ChangeDetectorRef,
  ) {
    this.slotData = new DateSlot();
    const today = new Date();
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);

    this.maxDate = new Date(new Date().setDate(today.getDate() + 30));
    this.minDate = today;
    this.dateAdapter.setLocale(this.localeService.localeLang);

    this.u_id = this.route.snapshot.params["u_id"];
    this.a_id = this.route.snapshot.params["a_id"];
    this.is_active = this.loaderService.toBoolean(this.route.snapshot.params["is_active"]);

    this.doc_id = this.route.snapshot.params["doc_id"];
    this.is_prescription = this.loaderService.toBoolean(this.route.snapshot.params["is_prescription"]);
    this.is_active ? this.showLogin = true : this.showRegister = true;
    this.selectedTest = { calendar_id: 0, comment: "", description: "", long_name: "", short_name: "", start_time: "" }
    this.selectedLab = { sampling_station_name: "", formatted_phone_number: "", address: "" }
  }

  ngOnInit(): void {
    this.subscription.add(
      this.loaderService.variable$.subscribe(value => {
        this.percent = (value / this.loaderService.flow_arr.length) * 100;
        this.cdr.detectChanges(); // Manually trigger change detection
      })
    );

    this.userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    this.getPatientData(true);
    this.getBirthCities();
  }


  public getPatientData(getOffSet: boolean = false) {
    if (!this.is_active) {
      if (sessionStorage.getItem('access_token')) {
        this.u_id = JSON.parse(sessionStorage.getItem('access_token'))['unique_id']
      }
    }
    this.patientService.getPatientInfo(this.u_id).subscribe(
      (response: any) => {
        this.loaderService.id = response.id;
        this.loaderService.ppaInfo = response;

        this.loaderService.user_name = this.loaderService.ppaInfo.first_name + " " + this.loaderService.ppaInfo.birth_name;
        this.loaderService.dob = this.loaderService.ppaInfo.dob;
        this.age =
          new Date().getFullYear() -
          new Date(new Date(this.loaderService.ppaInfo.dob).toLocaleDateString()).getFullYear();
        this.getAppointmentData();
        if (getOffSet) { this.getUserOffSet(); }
        this.invalidLink = false;

      }, (error) => {
        error;
        this.invalidLink = true;
      });
  }

  public getAppointmentData() {
    let searchReq = {
      "search_text": "",
      "from_appointment": true,
      "is_dob": false,
      "patient_id": this.loaderService?.ppaInfo?.id,
      "appointment_id": this.a_id
    }
    this.spinner.show(true);
    this.patientAppointmentService
      .getAppointmentListBySearch(searchReq
      )
      .subscribe(
        (response: any[]) => {
          this.spinner.show(false);

          if (response.length > 0) {
            this.appointmentInfo = response[0];
            let test = response[0];
            this.selectedTest = { calendar_id: test?.CalendarId, short_name: test?.TestShortName, long_name: test?.TestLongName, description: test.Description, start_time: test.SlotTime, comment: test.Comments };
            this.selectedLab = { sampling_station_name: test?.SamplingStationName, formatted_phone_number: test?.PhoneNumber, address: test?.AddressDetails };
          }
        },
        (error) => {
          this.spinner.show(false);
          error;
        }
      );
  }

  updateServiceVariable() {
    this.loaderService.setVariable(0);
  }

  deleteItem(item: number) {
    const index: number = this.loaderService.flow_arr.indexOf(item);
    if (index !== -1) {
      this.loaderService.flow_arr.splice(index, 1);
    }
  }

  HideStep(stepNo: number) {
    return stepNo !== this.step ?? 'hide'
  }
  public getUserOffSet() {
    this.loaderService.flow_detail = constants.complete_booking_flow;
    if (this.is_active) {
      if (this.is_prescription) {
        if (this.doc_id == "0") {
          this.loaderService.flow_arr = constantsarr.active_complete_booking_flow_withpresc;
          this.step = constants.login;
        }
        else {
          this.loaderService.flow_arr = constantsarr.active_complete_booking_flow_withdocpresc;
          this.step = constants.login;
        }
      }
      else {
        if (this.doc_id == "0") {
          this.loaderService.flow_arr = constantsarr.active_complete_booking_flow_withoutdocpresc;
          this.step = constants.login;
        }
        else {
          this.loaderService.flow_arr = constantsarr.active_complete_booking_flow_withdoc;
          this.step = constants.login;
        }
      }
    }
    else {
      this.getPatientData();
      if (this.is_prescription) {
        this.loaderService.flow_arr = constantsarr.inactive_complete_booking_flow_withpresc
        this.step = constants.login;
      }
      else {
        this.loaderService.flow_arr = constantsarr.inactive_complete_booking_flow_withoutpresc;
        this.step = constants.login;
      }
    }
  }



  public autoLogin(response: any) {
    if (response["access_token"]) {
      this.loaderService.startRefreshTokenTimer(response);
      const access_token = jwtDecode(response["access_token"]);
      sessionStorage.setItem("access_token", JSON.stringify(access_token));
      sessionStorage.setItem("header", response["access_token"]);
      sessionStorage.setItem(
        "refresh_token",
        response["refresh_token"]
      );
      this.loaderService.userRole = access_token["role"];

      if (access_token["role"] == constants.patientRole) {
        this.loginService.getPatientDetails().subscribe((resp: any) => {
          sessionStorage.setItem("userDetails", JSON.stringify(resp));
          this.loaderService.user_name = resp.first_name + " " + resp.birth_name;
          this.loaderService.dob = resp.dob;
          this.spinner.show(false);
          this.loaderService.handleNavigationNodes(true);
          this.loaderService.updateLoginStatus(true);
        });
      }
    }
  }

  ShowConfirmationTab(event: any) {
    if (this.loaderService.ppaInfo) {
      this.uploadDocuments();
      this.updateAppointment(event);
    }
  }

  public uploadDocuments() {
    this.patientDocuments?.documents?.forEach((doc: any) => {
      this.patientService.createPatientDocument(doc).subscribe(
        (data: any) => {
          data;
        },
        (errorResponse: HttpErrorResponse) => {
          this.spinner.show(false);
          this.messageService.alert(errorResponse["message"], 5000);
        }
      );
    });
  }

  public updateAppointment(evt: any) {
    const isoDate = new Date(this.appointmentInfo.SlotTime);
    let payload = {
      "id": this.appointmentInfo?.AppointmentId,
      "calendar_id": this.appointmentInfo?.CalendarId,
      "patient_id": this.loaderService.ppaInfo?.id,
      "slot_group_id": this.appointmentInfo?.SlotGroupId,
      "slot_time": isoDate ? moment.utc(isoDate).local().format('YYYY-MM-DDTHH:mm:SS') + "Z" : null,
      "capacity": 0,
      "tests": [
        this.appointmentInfo?.TestId
      ],
      "sampling_station_id": this.appointmentInfo?.SamplingStationId,
      "test_comments": {
        [this.appointmentInfo?.TestId.toString()]: evt?.bookingComment,
      },
      "prescriptions": [
        {
          "azure_id": this.appointmentInfo.PrescriptionUrl ? this.appointmentInfo.PrescriptionUrl : "",
          "prescriptions_pages": this.documents[0].PrescriptionParts.map(
            (s: any) => s.prescriptionPartData
          )
        }
      ],
    }
    let a_data = {
      lab: this.selectedLab,
      test: this.selectedTest,
      s_time: this.appointmentInfo.SlotTime,
      slot_group_id: this.slotGroupId,
      slot_time: isoDate ? moment.utc(isoDate).local().format('YYYY-MM-DDTHH:mm:SS') + "Z" : null,
      tests: [this.appointmentInfo?.TestId],
      favourite_lab: "",
      prescriptions: [
        {
          "prescriptions_pages": this.documents[0].PrescriptionParts.map(
            (s: any) => s.prescriptionPartData
          )
        }
      ],
      sampling_station_id: this.appointmentInfo?.SamplingStationId,
      test_comments: evt?.bookingComment,
      user_name: this.loaderService.user_name,
      dob: this.loaderService.dob,
      fromAppointmentLogin: false,
      calendar_id: this.appointmentInfo?.CalendarId
    };

    sessionStorage.setItem("a_data", JSON.stringify(a_data));
    sessionStorage.setItem("a_code", JSON.stringify(this.appointmentInfo.AppointmentCode));

    this.spinner.show(true);
    this.patientService.updateAppointment(payload).subscribe(
      (response: any) => {
        response;
        this.spinner.show(false);
        a_data["turn"] = response.turn ? response.turn : response?.appointment_code;
        a_data["requestNumber"] = response.appointment_code;
        a_data["isKioskAvailable"] = false;
        a_data["appointmentTolerance"] = response.appointment_tolerance;
        a_data["inside_lab_flow"] = false;
        sessionStorage.setItem("a_data", JSON.stringify(a_data));
        this.isConfirmed = evt.isConfirmed;
        this.showLogin = evt.showLogin;
        this.showConfirmBooking = evt.showConfirmBooking;
        this.bookingComment = evt.bookingComment;
        this.loaderService.rmMode = false;
        _paq.push(['trackEvent', 'Appointment Booked', 'Success', 'Success']);
        this.loaderService.highlightLeftMenu(['patient/confirm-appointment']);

      },
      (errorResponse: HttpErrorResponse) => {
        errorResponse;
        this.spinner.show(false);
        this.messageService.alert(
          this.translate.instant(
            "Common.GlobalMessages.InternalServerErrorMessage"
          ),
          5000
        );
      }
    );
  }

  loginSuccessfull() {
    this.loginSuccessful = true;
    this.authType.setValue("1");
    _paq.push(['trackEvent', 'Login', 'Login Step Completed', 'Loggedin']);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    this.getPatientData();

    setTimeout(() => {
      if (
        (this.loaderService.bookingPatientId) ||
        this.loaderService.userRole == constants.patientRole
      ) {
        this.showLoginorRegisterStep = false;

      } else {
        this.showLoginorRegisterStep = true;

        if (this.loaderService.userRole == constants.nurseRole) {
          this.showRegister = true;
        }
      }

      if (!this.showLoginorRegisterStep) {
        this.deleteItem(constants.login)
      }

      if (this.userDetails) {
        this.deleteItem(constants.login)
      }
      this.showConfirmBooking = true;
      this.step = this.loaderService.flow_arr[this.currentindex];
      this.loaderService.setVariable(this.currentindex);

      this.loaderService.handleNavigationNodes(true);

    }, 1000);
  }

  RegisterSuccessful(data: any) {
    let payload = data;
    this.loaderService.user_name =
      payload.first_name + " " + payload.birth_name;
    this.loaderService.dob = payload.dob;
    setTimeout(() => {
      this.authType.setValue("2");

      this.showConfirmBooking = true;
      this.showActivateMessage = true;

      _paq.push(['trackEvent', 'Register', 'Register Step Completed', 'register']);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      this.registerSuccessful = true;// For Chrome, Firefox, IE and Opera
    }, 500);
    this.showConfirmBooking = true;

    this.deleteItem(constants.login)
    this.step = this.loaderService.flow_arr[this.currentindex];
  }

  protected getUpdateDocument(event: any) {
    this.uploadStatus = false;
    let currDoc = this.documents[0];
    if (currDoc) {
      currDoc = event;
    }
    const found = this.documents[0].PrescriptionParts.length !== 0
    if (this.userDetails) {
      this.loginSuccessful = true;
      this.showConfirmBooking = true;
      this.deleteItem(constants.login);
    }
    if (!found) {
      this.noDocuments = true;
    } else {
      this.noDocuments = false;
    }
  }

  protected getClickImageStatus(event: any) {
    this.isClicked = event;
    this.uploadStatus = false;
  }

  ngOnDestroy() {
    // Reset the service variable when the component is destroyed
    this.currentindex = 0;
    this.loaderService.setVariable(this.currentindex);
    this.loaderService.rmMode = false;
    this.loaderService.rm_id = "";
    this.loaderService.ppaInfo = null;
  }



  goNext() {
    this.currentindex++;
    this.step = this.loaderService.flow_arr[this.currentindex];
    this.loaderService.setVariable(this.currentindex);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  goBack() {

    this.currentindex--;
    this.step = this.loaderService.flow_arr[this.currentindex];
    this.loaderService.setVariable(this.currentindex);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  checkLogin() {
    this.userDetails = JSON.parse(sessionStorage.getItem("userDetails"));

    if (!(this.is_active && this.userDetails)) {
      this.goBack();
    }
  }
  uploadDoc(evt: any) {
    evt.documents.forEach((doc: any) => {
      doc["patient_id"] = this.loaderService?.ppaInfo?.id;
    });

    this.patientDocuments = evt;
    this.goNext();
  }

  getBirthCities() {
    this.commonAPi.getBirthCities().subscribe(
      (data: any) => {
        this.commonAPi.birthCitiesList.next(data)
      })
  }

  checkDocument() {
    if (this.documents[0].PrescriptionParts.length !== 0) {
      this.goNext();
    }
    else {
      this.noDocuments = true;
      if (this.noDocuments) {
        this.toastr.warning(this.translate.instant("upload-pic.no-prescription"));
      }
    }
  }

  openForgotPassword(evt: any) {
    this.progressPercent = evt;
    this.showLoginPrebook = false; this.forgotPassword = true;
  }
}