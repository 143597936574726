import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { distinctUntilChanged, tap } from "rxjs/operators";
import { LocaleService } from "../../../app.locale.service";
import { SpinnerService } from "../../../core/spinner/spinner.service";
import { LoaderService, MessageService } from "../../../main";
import { SpecialDate } from "../../admin/booking/special-dates/special-date";
import { SamplingStationSlot } from "../../models/common.model";
import { CommonApiService } from "../../services/common-api.service";
import { PatientAppointmentService } from "../../services/patient-appointment.service";
import { PatientService } from "../../services/patient.service";
import { EditPatientAppointmentComponent } from "../../shared/edit-patient-appointment/edit-patient-appointment.component";
import { UploadPrescriptionComponent } from "../../shared/upload-prescription/upload-prescription.component";
import { ScanQrComponent } from "../find-patient/scan-qr/scan-qr.component";
import { ViewPrescriptionComponent } from "../../shared/view-prescription/view-prescription.component";
import { DateSlot } from "../../shared/date-slot/date-slot.model";
import { BookingAppointmentGuestService } from "../../services/booking-appointment-guest.service";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DatePipe, Location } from '@angular/common';

export interface PatientAppointment {
  firstName: string;
  lastName: string;
  birthName: string;
  test: string;
  dateTime: string;
  ssn: string;
  dob: string;
  samplingStationId: string;
}

@Component({
  selector: "app-appointments",
  templateUrl: "./appointments.component.html",
  styleUrls: ["./appointments.component.scss"],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: "DD-MM-YYYY",
        },
        display: {
          dateInput: "DD-MM-YYYY",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        },
      },
    },
  ],
})
export class AppointmentsComponent implements OnInit {
  public isCancelled: boolean = true;
  public isModified: boolean = false;
  seacrchInput = new FormControl();
  testFormControl = new FormControl();
  searchStartFormControl = new FormControl(moment().format('YYYY-MM-DD'));
  searchEndFormControl = new FormControl(moment().add(30, "d").format('YYYY-MM-DD'));
  displayedColumns: string[] = [
    "name",
    "dateOfBirth",
    "ssn",
    "recordNo",
    "test",
    "dateTime",
    "actions",
  ];

  otherAppointmentsDisplayedColumns: string[] = [
    "test",
    "lab",
    "labContact",
    "dateTime",
    "actions",
  ];
  dataSource: MatTableDataSource<any>;
  isAppointmentsWithoutSlots: boolean = true;
  otherAppointmentsDataSource: MatTableDataSource<any>;
  dataList: MatTableDataSource<any>;
  OtherAppointmentsDataList = new MatTableDataSource<any>();
  searchText: "";
  isDOB = false;
  hasNoSpecialChars = /^[A-Za-z0-9àâçéèêëîïôûùüÿñæœÀÂÇÉÈÊËÎÏÔÛÙÜŸÑÆŒ\s@.]+$/;
  refresh = true;
  refreshForOtherAppointmentTab = true;
  isPdfViewerOpened: boolean;
  isBookingFlow: boolean;
  editSliderContainer: any;
  uploadPrescriptionSlider: any;
  patientAppointmens: any;
  confirmDelTitle = "receptionist.confirm-del-title";
  confirmDelContent = "receptionist.confirm-del-content";
  patientArrivedMessage = "receptionist.patient-arrived";
  confirmArrTitle = "receptionist.confirm-arr-title";
  confirmArrContent = "receptionist.confirm-arr-content";
  ssExternalId: any;
  patient: any;
  specialDates: SpecialDate[];
  calendars: SamplingStationSlot[];
  sub: any;
  pdfBlob: any;
  row_data: any;
  previousSearchInputValue: any;
  testList: any[];
  selectedTest: any;
  selectedTestList: any[] = [];
  testArray: any;
  appointmentCount: any = 0;
  otherAppointmentCount: any = 0;
  protected navigationDetails: any;
  isOtherAppointmentSelected = false;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
    .pipe(
      tap((value) => console.log(value)),
      distinctUntilChanged()
    );
  protected slotData: DateSlot;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(
    public router: Router,
    private breakpointObserver: BreakpointObserver,
    private componentFactoryResolver: ComponentFactoryResolver,
    public loader: LoaderService,
    private readonly messageService: MessageService,
    private translate: TranslateService,
    private localeService: LocaleService,
    public patientAppointmentService: PatientAppointmentService,
    private commonAPi: CommonApiService,
    private readonly spinner: SpinnerService,
    private readonly patientService: PatientService,
    private dialog: MatDialog,
    private location: Location,
    public bookingAppointmentGuestService: BookingAppointmentGuestService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dataSource = new MatTableDataSource<any>();
    this.otherAppointmentsDataSource = new MatTableDataSource<any>();
    this.dataList = new MatTableDataSource<any>();
    this.OtherAppointmentsDataList = new MatTableDataSource<any>();

    this.dateAdapter.setLocale(this.localeService.localeLang);
    this.slotData = new DateSlot();
    this.breakpoint$.subscribe(() => this.breakpointChanged());
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
    this.navigationDetails = this.location.getState();
    if (this.navigationDetails?.patient) {
      this.patientAppointmens = this.navigationDetails?.patient;
      console.log(this.patientAppointmens);

      this.applysearchfilter(this.patientAppointmens);

      this.previousSearchInputValue = this.navigationDetails?.searchInputValue;

    }
  }

  ngOnInit(): void {
    this.appointmentCount = 0;
    this.otherAppointmentCount = 0;
    this.patient = this.loader.patient;
    this.seacrchInput.setValue(this.loader.patientSearchText);
    if (this.loader.isRecShowWalkinAppointments != null) {
      this.isAppointmentsWithoutSlots = this.loader.isRecShowWalkinAppointments;
    }

    if (this.loader.recAppointmentFromDate != null && this.loader.recAppointmentFromDate != "") {
      this.searchStartFormControl.setValue(this.loader.recAppointmentFromDate);
      this.searchEndFormControl.setValue(this.loader.recAppointmentToDate);
    }

    this.getTestBySamplingStationId();

    this.loadData();

    this.localeService.locale.subscribe(() =>
      this.dateAdapter.setLocale(this.localeService.localeLang)
    );
  }

  public loadData() {
    if (this.patient?.id) {
      this.getPatientAppointmentList();
      this.getPatientOtherAppointmentList();
    } else if (this.loader.recRedirection?.action && this.loader.recRedirection?.patient_id) {
      this.getPatientAppointmentList();
      this.getPatientOtherAppointmentList();
      this.loader.recRedirection.action = null;
      this.loader.recRedirection.patient_id = null;
      this.loader.recRedirection.ss_id = null;
    } else if (
      this.loader.patientSearchText?.length == 0 ||
      this.loader.patientSearchText?.length > 2
    ) {
      this.loadpatientAppointment();
    }
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  findPatient(clearPatient = false) {
    this.ssExternalId = this.loader.ss_external_id;
    const self = this;
    this.loader.patientSearchText = this.seacrchInput.value;
    if (clearPatient) {
      this.loader.patient = {};
      this.patient = {};
    }
    if (this.patient?.id) {
      this.getPatientAppointmentList();
    } else if (this.seacrchInput?.value.length == 0 || this.seacrchInput?.value.length > 2) {
      this.loadpatientAppointment();
    } else if (this.previousSearchInputValue !== undefined) {
      self.refresh = false;
      setTimeout(() => {
        this.patientAppointmentService
          .getAppointmentListByPatientInfo(
            this.previousSearchInputValue,
            this.loader.ss_id
          )
          .subscribe(
            (response: any[]) => {
              if (response["message"] !== "No Patient Found") {
                self.refresh = true;
                response = response.map((details: any) => { return { ...details, isOpen: false, history: [] } });
                this.applysearchfilter(response);
              } else {
                this.reset();
              }
            },
            (error) => {
              this.reset();
              console.log(error);
            }
          );
      }, 0);
    } else {
      this.reset();
    }
  }

  private applysearchfilter(res: any[]) {
    setTimeout(() => {
      this.refresh = false;
    }, 0);

    this.dataList.data = res;

    if (this.selectedTest != undefined && this.selectedTest != "") {
      res = res.filter((x: any) => this.selectedTestList.includes(x.TestId));
    }

    if (
      this.searchStartFormControl.value !== null &&
      this.searchStartFormControl.value !== ""
    ) {
      res = res.filter((x: any) =>
        moment(x.SlotTime)
          .startOf("day")
          .isSameOrAfter(
            moment(this.searchStartFormControl.value).startOf("day")
          ) || x.SlotTime == ""
      );
    }

    if (
      this.searchEndFormControl.value !== null &&
      this.searchEndFormControl.value !== ""
    ) {
      res = res.filter((x: any) =>
        moment(x.SlotTime)
          .endOf("day")
          .isSameOrBefore(moment(this.searchEndFormControl.value).endOf("day")) || x.SlotTime == ""
      );
    }

    res.forEach((element: any) => {
      element.ShowComments = false;
    });

    if (!this.isAppointmentsWithoutSlots) {
      res = res.filter((option) =>
        option.SlotTime != ""
      );
    }
    this.dataSource.data = res;
    this.appointmentCount = res.length;

    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.refresh = true;
    }, 1);
  }

  private applyOtherAppointmentsSearchfilter(res: any[]) {
    setTimeout(() => {
      this.refreshForOtherAppointmentTab = false;
    }, 0);
    res.forEach((element: any) => {
      element.ShowComments = false;
    });

    this.OtherAppointmentsDataList.data = res;

    this.otherAppointmentsDataSource.data = res;
    this.otherAppointmentCount = res.length;

    setTimeout(() => {
      this.refreshForOtherAppointmentTab = true;
    }, 1);
  }

  private loadpatientAppointment() {
    this.appointmentCount = 0;

    const re = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/; // regex to validate DOB format
    if (re.test(this.seacrchInput.value)) {
      this.isDOB = true;
      this.searchText = this.seacrchInput.value.split("/").reverse().join("-");
    } else {
      this.isDOB = false;
      this.searchText = this.seacrchInput.value ? this.seacrchInput.value : "";
    }
    setTimeout(() => {
      if (this.isDOB) {
        this.patientAppointmentService
          .getAppointmentListByPatientInfo(
            this.searchText,
            this.loader.ss_id,
            true,
            this.isDOB
          )
          .subscribe(
            (response: any[]) => {
              if (
                response != null &&
                response["message"] !== "No Patient Found"
              ) {
                response = response.map((details: any) => { return { ...details, isOpen: false, history: [] } });
                this.applysearchfilter(response);
              } else {
                this.reset();
              }
            },
            (error) => {
              this.reset();
              console.log(error);
            }
          );
      } else if (
        this.searchText == "" ||
        this.hasNoSpecialChars.test(this.searchText)
      ) {
        this.patientAppointmentService
          .getAppointmentListByPatientInfo(this.searchText, this.loader.ss_id)
          .subscribe(
            (response: any[]) => {
              if (
                response != null &&
                response["message"] !== "No Patient Found"
              ) {
                response = response.map((details: any) => { return { ...details, isOpen: false, history: [] } });
                this.applysearchfilter(response);
              } else {
                this.reset();
              }
            },
            (error) => {
              this.reset();
              console.log(error);
            }
          );
      }
    }, 0);
  }

  private getPatientAppointmentList() {
    this.appointmentCount = 0;
    if (this.loader.recRedirection?.patient_id) {
      this.patient = {
        id: this.loader.recRedirection.patient_id
      }
      var redirection = this.loader.recRedirection.action;
      this.loader.recRedirection.action = null;
      this.loader.ss_id = this.loader.recRedirection.ss_id
    }
    setTimeout(() => {
      this.patientAppointmentService
        .getPatientAppointmentList(this.patient?.id, this.loader.ss_id)
        .subscribe(
          (response: any[]) => {
            if (
              response != null &&
              response["message"] !== "No Patient Found"
            ) {
              response = response.map((details: any) => { return { ...details, isOpen: false, history: [] } });
              const index = response.findIndex((item: any) => item.AppointmentId == this.loader.recRedirection?.appointment_id);
              if (index >= 0) {
                var data = response[index];
                if (redirection == "appointment-edit" && !response[index].IsDeleted)
                  this.editPatientInfo(data);
                else if (redirection == "prescription" && response[index].PrescriptionUrl)
                  this.viewPrecription(data);
              }
              this.applysearchfilter(response);
            } else {
              this.reset();
            }
          },
          (error) => {
            this.reset();
            console.log(error);
          }
        );
    }, 0);
  }

  private getPatientOtherAppointmentList() {
    this.otherAppointmentCount = 0;
    if (this.loader.recRedirection?.patient_id) {
      this.loader.ss_id = this.loader.recRedirection.ss_id;
      this.patient = {
        id: this.loader.recRedirection.patient_id
      }
    }
    setTimeout(() => {
      this.patientAppointmentService
        .getPatientOtherAppointmentList(this.patient?.id, this.loader.ss_id)
        .subscribe(
          (response: any[]) => {
            if (
              response != null &&
              response["message"] !== "No Patient Found"
            ) {
              response = response.map((details: any) => { return { ...details, isOpen: false, history: [] } });
              this.applyOtherAppointmentsSearchfilter(response);
            } else {
              this.reset();
            }
          },
          (error) => {
            this.reset();
            console.log(error);
          }
        );
    }, 0);
  }

  setDataToDataSource(response: any[]) {
    for (const item of response) {
      this.dataSource.data.push({
        firstName: item.FirstName,
        lastName: item.LastName,
        birthName: item.BirthName,
        test: item.TestShortName,
        dateTime: item.SlotTime,
        ssn: item.SampleId,
        dob: item.SlotTime,
        samplingStationId: item.SamplingStationId,
      });

    }

    this.refresh = true;
  }

  reset() {
    const self = this;
    if (this.seacrchInput.value) {
      self.refresh = false;
      setTimeout(() => {
        self.refresh = true;
        self.dataSource = new MatTableDataSource<any>();
        self.otherAppointmentsDataSource = new MatTableDataSource<any>();
      }, 0);
    }
  }

  clearSearch() {
    this.appointmentCount = 0;
    this.loader.patientSearchText = "";
    this.loader.patient = {};
    this.patient = {}
    this.seacrchInput.reset();
    this.loadpatientAppointment();
  }

  public dateFilter() {
    this.loader.isRecShowWalkinAppointments = this.isAppointmentsWithoutSlots;
    this.loader.recAppointmentFromDate = this.searchStartFormControl.value
    this.loader.recAppointmentToDate = this.searchEndFormControl.value
    this.applysearchfilter(this.dataList.data);
  }

  getTestBySamplingStationId() {
    this.bookingAppointmentGuestService
      .getAdmintests(this.loader.ss_id)
      .subscribe(
        (getResponse) => {
          this.testArray = getResponse;
          this.testList = [...this.testArray];
          if (this.loader.recAppointmentselectedTestsList?.length > 0) {
            const selTestList: Array<any> = new Array<any>();
            this.selectedTestList = this.loader.recAppointmentselectedTestsList;
            this.selectedTestList.forEach((element: any) => {
              const ss = this.testList?.find((i: { id: any; }) => i.id == element);
              if (ss != undefined) {
                selTestList.push(ss);
              }
            });
            this.testFormControl.setValue(selTestList);
            this.selectedTest = this.selectedTestList
              .map((item: any) => item)
              .join(",");
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  allChecked(event: any) {
    this.selectedTestList = [];
    if (event) {
      this.testList.forEach((itemTestType: any) => {
        this.selectedTestList.push(itemTestType.id);
      });
    } else {
      this.selectedTestList = [];
    }
    this.selectedTest = this.selectedTestList
      .map((item: any) => item)
      .join(",");
    this.loader.recAppointmentselectedTestsList = this.selectedTestList;
    this.applysearchfilter(this.dataList.data);
  }

  onTestSelected(event: any) {
    this.selectedTestList = [];
    console.log(event);
    if (this.testFormControl.value.length > 0) {
      this.testFormControl.value.forEach((itemTestType: any) => {
        this.selectedTestList.push(itemTestType.id);
      });
      this.selectedTest = this.selectedTestList
        .map((item: any) => item)
        .join(",");
    } else {
      this.selectedTest = "";
      this.selectedTestList = [];
    }
    this.loader.recAppointmentselectedTestsList = this.selectedTestList;
    this.applysearchfilter(this.dataList.data);

    console.log(this.selectedTest);
  }

  private breakpointChanged() {
    if (
      this.breakpointObserver.isMatched(
        "(max-width: 1050px) and (min-width: 601px)"
      )
    ) {
      this.editSliderContainer = 726;
      this.uploadPrescriptionSlider = 750;
    } else if (this.breakpointObserver.isMatched("(max-width: 600px)")) {
      this.editSliderContainer = "100%";
      this.uploadPrescriptionSlider = "100%";
    } else {
      this.editSliderContainer = 664;
      this.uploadPrescriptionSlider = 750;
    }
  }
  private getSlots(ss_id: any, t_id: any, selectedRowData: any) {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so adding 1
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    this.spinner.show(true);
    this.commonAPi.GetSlots(ss_id, t_id, 7, formattedDate).subscribe((x) => {
      this.slotData = x;
      this.spinner.show(false);
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          EditPatientAppointmentComponent
        );
      const srd = {};
      Object.assign(srd, selectedRowData);
      const payload = {
        fromRecpectionist: true,
        appointment_detail: srd,
        slotData: this.slotData,
      };
      this.loader.addDynamicComponent(
        componentFactory,
        this.editSliderContainer,
        payload
      );

      this.sub = this.loader.refreshDialog.subscribe((isRefresh) => {
        if (isRefresh) {
          if (this.patient?.id) {
            this.getPatientAppointmentList();
          } else if (
            this.loader.patientSearchText?.length == 0 ||
            this.loader.patientSearchText?.length > 2
          ) {
            this.loadpatientAppointment();
          }
        }
      });
    });
  }

  editPatientInfo(selectedRowData: any) {
    let srd = Object.assign(selectedRowData);
    this.getSlots(
      srd.SamplingStationId,
      srd.TestId,
      srd
    );
  }

  public uploadPrecription(details: any) {
    console.log("details " + details);

    this.row_data = {
      row_data: details,
    };

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        UploadPrescriptionComponent
      );
    this.loader.addDynamicComponent(
      componentFactory,
      this.uploadPrescriptionSlider,
      this.row_data
    );

    this.sub = this.loader.refreshDialog.subscribe((isRefresh) => {
      if (isRefresh) {
        this.findPatient();
      }
    });
  }

  openPdf(selectedRowData: any) {
    this.spinner.show(true);
    const prescriptionName = selectedRowData.substring(
      selectedRowData.lastIndexOf("/") + 1
    );
    this.patientAppointmentService
      .getPrescription(prescriptionName)
      .subscribe((res: any) => {
        const byteArray = new Uint8Array(
          atob(res)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        const file = new Blob([byteArray], {
          type: "application/octet-stream",
        });
        this.pdfBlob = URL.createObjectURL(file);
        this.spinner.show(false);
        this.isPdfViewerOpened = true;
      });
  }

  confirmDeleteWithYesNo(appointment: any) {
    this.messageService
      .confirm(
        this.translate.instant(this.confirmArrContent),
        this.translate.instant(this.confirmArrTitle)
      )
      .subscribe((actionResult: boolean) => {
        if (actionResult) {
          const patientArrivalData = {
            request_number: appointment.AppointmentCode,
          };
          this.patientService.updateArrival(patientArrivalData).subscribe(
            (data: any) => {
              console.log(data);
              this.messageService.success(
                this.translate.instant(this.patientArrivedMessage)
              );
              this.findPatient();
            },
            (error: any) => console.log(error)
          );
        }
      });
  }

  cancelAppointment(element: any) {
    this.messageService
      .confirm(
        this.translate.instant(this.confirmDelContent),
        this.translate.instant(this.confirmDelTitle)
      )
      .subscribe((actionResult: boolean) => {
        if (actionResult) {
          const patientArrivalData = {
            appointment_id: element.AppointmentId,
            test_id: element.TestId,
            barCode: element.Barcode,
            patientId: element.PatientID,
          };

          this.patientAppointmentService
            .deletePatientAppointment(patientArrivalData)
            .subscribe(
              (data) => {
                if (data["message"] == "appointment deleted successfully") {
                  this.messageService.success(this.translate.instant('appointments.appointment-delete-success'), 5000);
                  this.refresh = true;
                  this.findPatient();
                } else {
                  this.messageService.warning(
                    this.translate.instant(JSON.stringify(data)["message"]),
                    5000
                  );
                }
              },
              (error: any) => {
                this.messageService.warning(
                  this.translate.instant(error["message"]),
                  5000
                );
              }
            );
        }
      });
  }

  rowClicked(data: any) {
    const searchReq = {
      QrId: data,
      "org_id": this.loader.ss_selected_org_id,
    };
    this.patientService
      .getPatients(searchReq)
      .subscribe((response: any) => {
        if (response.data["message"] !== "No Patient Found") {
          this.loader.patient = response?.data[0];
          this.loader.patientSearchText = response?.data[0].first_name + " " + response?.data[0].last_name;
          this.loader.highlightLeftMenu(["receptionist/patient-info"], {
            state: { patient: response.data[0], searchInputValue: this.seacrchInput.value },
          });
        } else {
          this.messageService.warning(
            this.translate.instant("receptionist.invalid-QR")
          );
        }
      });

  }

  getDescription(element: any) {
    const uploadTitle = "patient-info.upload-prescription";
    const repleaceTitle = "appointments.replace-prescription";

    return element.PrescriptionUrl.length === 0
      ? this.translate.instant(uploadTitle)
      : this.translate.instant(repleaceTitle);
  }

  checkDate(element: any) {
    const st = new Date(element.SlotTime);
    if (st.getHours() > 0 || st.getMinutes() > 0) {
      return moment(element.SlotTime)
        .startOf("second")
        .isBefore(moment().startOf("second")) || element.SlotTime == "";
    } else {
      return moment(element.SlotTime)
        .startOf("date")
        .isSameOrBefore(moment().startOf("date")) || element.SlotTime == "";
    }
  }

  checkIsTodayDate(element: any) {
    if (element.SlotTime == "") {
      return true
    }
    const st = new Date(element.SlotTime);
    const myDate = new Date();
    const datePipe = new DatePipe('en-US');
    const slotDate = datePipe.transform(st, 'yyyy-MM-dd')
    const todayDate = datePipe.transform(myDate, 'yyyy-MM-dd');
    if (todayDate == slotDate) {
      return true;
    }
    return false;
  }

  protected viewPrecription(event: any) {

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        ViewPrescriptionComponent
      );
    this.loader.addDynamicComponent(
      componentFactory,
      this.uploadPrescriptionSlider,
      event
    );
  }

  redirectReceiptionistBooking(selectedPatient: any) {
    this.loader.bookingPatientId = selectedPatient.PatientID;
    this.loader.bookingPatientName =
      selectedPatient.PatientID != ""
        ? selectedPatient.FirstName + " " + selectedPatient.LastName
        : "";
    this.loader.user_name =
      selectedPatient.PatientID != ""
        ? selectedPatient.FirstName + " " + selectedPatient.LastName
        : "";
    const splittedDateArray = selectedPatient.DOB.split("/");

    const year = Number(splittedDateArray[2]);
    const month = Number(splittedDateArray[1]) - 1;
    const date = Number(splittedDateArray[0]);

    this.loader.dob =
      selectedPatient.PatientID != "" ? new Date(year, month, date) : "";

    this.loader.highlightLeftMenu([
      "/receptionist/book-appointment/" + this.loader.ss_external_id,
    ]);
  }

  redirectRegisterTestKit(rowData: any) {
    this.loader.highlightLeftMenu(["receptionist/register-test-kit"], {
      state: {
        patientId: rowData.PatientID,
        patientQrId: rowData.QrId,
        appointmentId: rowData.AppointmentId,
      },
    });
  }

  scanQR() {
    this.dialog
      .open(ScanQrComponent, {
        width: "500px",
        height: "450px",
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((res) => {
        setTimeout(() => {
          this.patientAppointmentService
            .getAppointmentListByPatientInfo(res, this.loader.ss_id)
            .subscribe((response: any[]) => {
              if (
                response != null &&
                response["message"] !== "No Patient Found"
              ) {
                this.refresh = false;
                response = response.map((details: any) => { return { ...details, isOpen: false, history: [] } });
                this.applysearchfilter(response);
                this.refresh = true;
              } else {
                this.messageService.warning(
                  this.translate.instant("receptionist.invalid-QR")
                );
              }
            });
        }, 0);
      });
  }

  setIsOtherAppointmentSelected(isSelected: any) {
    this.isOtherAppointmentSelected = isSelected;
  }

  getHistory(element: any) {
    if (element.isOpen == false) {
      element.isOpen = true;
      this.patientAppointmentService.getAppointmentHistory(element.AppointmentId).subscribe((appHistory: any) => {
        appHistory?.forEach((appointment: any) => {
          element.history.push(appointment.slot_time);
        })
        element.history.reverse();
      })
    } else {
      element.isOpen = false;
      element.history = [];
    }
  }
}
