<ng-container>
    <button class="close" mat-dialog-close mat-button>X</button>
</ng-container>
<div class="qr-code-container">
    <header class="pull-left">
        <h3 class="pull-left">{{'qr-code-comp.qr-code'|translate}}</h3>
    </header>
    <div class="qr-code-cover">
        <app-qr-code width="236" [data]="qr_id"></app-qr-code>
        <footer>
            <p>{{firstName}} {{lastName}}</p>
            <p><span>{{'qr-code-comp.qr-id'|translate}}</span><span>{{qr_id}}</span></p>
        </footer>
    </div>
</div>