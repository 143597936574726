export class Test {
    public id: number;
    public short_name: string;
    public long_name: string;
    public description: string;
    public test_type: string;
    public external_code: string;
    public is_active: boolean;
}

export class Dow {
    public id: number;
    public calendar_id: number;
    public dow: number;
    public day: string;
    public active: boolean;
    public start_time: string;
    public end_time: string;
    public break_begins: string;
    public break_ends: string;
    public isDisabled: boolean;
    public is_open_valid: boolean;
    public is_close_valid: boolean;
    public is_break_start_valid: boolean;
    public is_break_end_valid: boolean;
}

export class SelectedTest {
    public id: number;
    public calendar_id: number;
    public test_id: number;
    public active: boolean;
}

export class CalendarInfo {
    constructor() {
        this.tests = new Array<SelectedTest>();
        this.dows = new Array<Dow>();
    }
    public sampling_station_id: number;
    public id: number;
    public calendar_name: string;
    public duration: number;
    public capacity: number;
    public status: string;
    public dows: Array<Dow>;
    public tests: Array<SelectedTest>;
}
