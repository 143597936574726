import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LoaderService, MessageService } from '../../../../main';
import { FormControl, Validators } from '@angular/forms';
import { PatientService } from '../../../services/patient.service';
import { RelativeMemberService } from '../../../services/relative-member.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../../../../core';
import { NgOtpInputConfig } from 'ng-otp-input';

@Component({
  selector: 'app-manage-access',
  templateUrl: './manage-access.component.html',
  styleUrls: ['./manage-access.component.scss']
})
export class ManageAccessComponent implements OnInit {
  public lastName: string = "LastName";
  public firstName: string = "FirstName";
  public step: number = 0;
  public shareEmail = new FormControl("", [
    Validators.required,
    Validators.pattern(this.patientService.emailPattern),
  ]);
  public elevateEmail = new FormControl("", [
    Validators.required,
    Validators.pattern(this.patientService.emailPattern),
  ]);
  @Input() sliderFormData: any;
  patientId = '';
  public rmInfo: any;
  public guardianInfo: any;
  protected display: any;
  protected btndisabled = true;
  protected verifyID: string;
  protected otp: string;
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
  };
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  constructor(public loaderService: LoaderService, public patientService: PatientService,
    public rmService: RelativeMemberService, public toaster: ToastrService, public translate: TranslateService,
    public spinner: SpinnerService,
    private messageService: MessageService) {

  }
  ngOnInit(): void {
    this.patientId = JSON.parse(sessionStorage.getItem('userDetails')).id;
    this.rmInfo = this.sliderFormData.rmInfo;
    this.step = this.sliderFormData.step
  }

  dismiss() {
    this.loaderService.cancelForm();
  }

  shareAccess() {
    this.shareEmail.markAsTouched();

    if (this.shareEmail.valid) {
      this.spinner.show(true);
      let payload = {
        "email": this.shareEmail.value,
        "sec_rm_id": this.rmInfo?.rm_id
      }

      this.rmService.shareAccess(payload).subscribe(
        (response) => {
          this.spinner.show(false);
          if (response?.id) {
            if (!response.is_active) {
              this.toaster.error(this.translate.instant('share-access.not-available'));
            }
            else if (response.is_already_linked) {
              this.toaster.error(this.translate.instant('share-access.is-already-linked') + this.shareEmail.value);
            }
            else {
              this.toaster.success(this.translate.instant('share-access.access-given') + this.shareEmail.value);
            }
            this.loaderService.cancelForm();
          }
          else {
            this.loaderService.cancelForm();
            this.toaster.error(this.translate.instant('share-access.not-available'));
          }
        }, () => {
          this.spinner.show(false);
        });
    }
  }

  deletePatient() {
    this.spinner.show(true);

    this.rmService.deletePatient(this.rmInfo.rm_id).subscribe(
      (response: any) => {
        this.spinner.show(false);

        if (response.status == 200) {
          this.toaster.success(this.translate.instant('delete-rm.success-del') + " " + this.rmInfo.rm_fname + " " + this.rmInfo.rm_lname);
        }
        else {
          this.toaster.success(this.translate.instant('delete-rm.failure-del') + " " + this.rmInfo.rm_fname + " " + this.rmInfo.rm_lname);
        }
        this.loaderService.cancelForm();
      }, () => {
        this.spinner.show(false);
      }
    )
  }

  removeAccess() {
    this.spinner.show(true);

    let secid: any = this.rmInfo.sec_id != "" && this.rmInfo.sec_id != undefined ? this.rmInfo.sec_id : this.patientId
    this.rmService.removeAccess(this.rmInfo.rm_id, secid).subscribe(
      (response: any) => {
        this.spinner.show(false);
        if (response.status == 200) {
          this.toaster.success(this.translate.instant('remove-access.success') + " " + this.rmInfo.sec_fname + " " + this.rmInfo.sec_lname);
        }
        else {
          this.toaster.error(this.translate.instant('remove-access.failure'));
        }
        this.loaderService.cancelForm();
      }, () => {
        this.spinner.show(false);
      });
  }

  protected elevateAccess() {
    if (this.verifyID) {
      const elevateRMData = {
        sa_patient_id: this.rmInfo.rm_id,
        new_email: this.elevateEmail.getRawValue(),
        verify_id: this.verifyID,
        otp: this.otp
      };
      this.rmService.elevateRM(elevateRMData).subscribe(
        (response: any) => {
          if (response.message == 'success') {
            this.toaster.success(this.translate.instant('elevate-account.elevate-success'));
            this.dismiss();
          }
        },
        (err: {
          error: { code: any; message: string };
          message: any;
          code: any;
        }) => {
          if (err.error.code == 422) {
            this.messageService.alert(
              this.translate.instant('elevate-account.invalid-otp')
            );
          }
          else {
            this.toaster.error(this.translate.instant('elevate-account.failure'));
            this.dismiss();
          }
        }
      )
    }
  }

  protected updateEmail() {
    if (this.elevateEmail.valid) {
      const updateEmailData = {
        id: this.rmInfo.rm_id,
        email: this.rmInfo.email,
        new_email: this.elevateEmail.getRawValue()
      };
      this.patientService.updateEMail(updateEmailData).subscribe(
        (response: any) => {
          this.verifyID = response.verify_id
          this.timer();
          this.step = 5;
        },
        (err: {
          error: { code: any; message: string };
          message: any;
          code: any;
        }) => {
          if (err.error.code == 422) {
            this.elevateEmail.setErrors({ emailExist: true });
          }
          else {
            this.messageService.alert(err.error.message);
          }
        });
    } else {
      console.log('Data Error');
    }
  }
  protected onOtpChange(otp: string) {
    this.otp = otp;
  }
  protected resetOTP() {
    this.ngOtpInput.setValue("");
    this.timer();
    this.btndisabled = true;
    const updateEmailData = {
      id: this.rmInfo.rm_id,
      email: this.rmInfo.email,
      new_email: this.elevateEmail.getRawValue()
    };

    this.patientService.updateEMail(updateEmailData).subscribe(
      (response: any) => {
        this.verifyID = response.verify_id
        this.timer();
        this.step = 5;
      });
  }

  protected timer() {
    const minute = 5;
    let seconds: number = minute * 60;
    let textSec = 0;
    let statSec = 60;
    const prefix = minute < 10 ? "0" : "";
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) {
        statSec--;
      } else {
        statSec = 59;
      }
      if (statSec < 10) {
        textSec = 0 + statSec;
      } else {
        textSec = statSec;
      }
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec
        .toString()
        .padStart(2, "0")}`;
      if (seconds == 0) {
        clearInterval(timer);
        this.btndisabled = false;
      }
    }, 1000);
  }
}
