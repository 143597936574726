import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgForm } from "@angular/forms";
import { FormBuilder, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { LocaleService } from "../../../../app.locale.service";
import { DrawerComponent, SpinnerService } from "../../../../core";
import { FileData } from "../../../../features/styles/models/file-data";
import { LoaderService, MessageService } from "../../../../main";
import { PatientService } from "../../../services/patient.service";
import { ResultStatus } from "../../results/results.model";
import { WebcamImage } from "ngx-webcam";
import { PrescriptionDocument } from "../../../shared/appointment-prescription/appointment-prescription.model";
import { constants } from "../../../shared/constant";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
// import { ImageCroppedEvent } from 'ngx-image-cropper';
@Component({
  selector: "app-upload-document",
  templateUrl: "./upload-document.component.html",
  styleUrls: ["./upload-document.component.scss"],
})
export class UploadDocumentComponent implements OnInit, DrawerComponent {
  @Input() sliderFormData: any;
  @Input() public isSliderDataEdited = false;
  @Output() skipMe = new EventEmitter<any>();

  public isUpdate: boolean;
  public file: FileData = null;
  public isInvalidFileSize: boolean;
  public isHovering: boolean;
  public isEmailNotificationRequired: boolean;
  public isIncorrectFileFormat: boolean;
  public isInvalidDocType = false;
  public isInvalidDescription = false;
  public isInvalidDetail = false;
  public isEditMode = false;
  public prescriptionFilePath = "";
  public isSaveClicked: boolean;
  public title: any;
  public buttonText: any;
  public documentData: any;
  public documentName: string;
  public selectedDocumentType: number;
  public selectedDescription: string;
  public selectedDetail: string;
  public uploadedFile = "";
  public uploadedFileWeb = "";
  public uploadedFileMobile = "";
  isPdfViewerOpened: boolean;
  pdfBlob: string;
  documentTypeList: ResultStatus[];
  public FileName = new FormControl("", []);
  public docExpiry = new FormControl("");
  protected isClicked: boolean = false;
  mandataryMutualCertificateIdentifier = "Mutual certificate";
  public form = this.fb.group({
    FileName: this.FileName,
    DocExpiry: this.docExpiry,
  });
  protected maxDate: Date;
  protected showExpiry: boolean = false;
  action: boolean;
  protected Documents: PrescriptionDocument = {
    Id: 1,
    PrescriptionParts: [],
    IsIncorrectFileFormat: false,
    IsInvalidFileSize: false,
    Expanded: true,
  };
  protected item = {
    Id: 1,
    FrontFileName: "",
    BackFileName: "",
    IsIncorrectFrontFileFormat: false,
    IsInvalidFrontFileSize: false,
    IsIncorrectBackFileFormat: false,
    IsInvalidBackFileSize: false,
    Expanded: true,
  };
  protected uploadStatus: boolean = false;
  constructor(
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private localeService: LocaleService,
    private spinner: SpinnerService,
    private patientService: PatientService,
    private messageService: MessageService,
    public toastr: ToastrService
  ) {
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
    let current_date = new Date();
    this.maxDate = new Date(current_date.setDate(current_date.getDate() + 1));
  }

  ngOnInit(): void {
    this.title =
      this.sliderFormData.EditAction == 1
        ? "upload-document.edit-document"
        : "upload-document.upload-document";
    this.buttonText = this.sliderFormData.EditAction == 1
      ? "upload-document.update"
      : "upload-document.upload";
    if (this.sliderFormData.EditAction == 1) {
      this.isEditMode = true;
      this.selectedDescription = this.sliderFormData.description;
      this.selectedDetail = this.sliderFormData.details;
      this.selectedDocumentType = this.sliderFormData.id;
      this.uploadedFile = this.sliderFormData.document_name; // this.sliderFormData.document_path.substring(this.sliderFormData.document_path.lastIndexOf("/")+1);
      this.form.controls.FileName.setValue(this.uploadedFile);
      this.prescriptionFilePath = this.sliderFormData.document_path;
      this.uploadedFile = this.uploadedFile.indexOf(".") > 0 ? this.uploadedFile.substring(0, this.uploadedFile.indexOf(".")) : this.uploadedFile;
      this.uploadedFileWeb = this.uploadedFile.length > constants.documentFileNameLengthWeb ? this.uploadedFile.substring(0, constants.documentFileNameLengthWeb - 2) + ".." : this.uploadedFile;
      this.uploadedFileMobile = this.uploadedFile.length > constants.documentFileNameLengthMobile ? this.uploadedFile.substring(0, constants.documentFileNameLengthMobile - 4) + ".." : this.uploadedFile;
      this.form.controls.DocExpiry.setValue(this.sliderFormData?.expiry_date);
      if (this.sliderFormData.expiry) {
        this.showExpiry = true;
      }
    } else if (this.sliderFormData.Action == 1) {
      this.action = true;
      if (this.sliderFormData.id) {
        this.selectedDocumentType = this.sliderFormData.id;
        if (this.sliderFormData.expiry) {
          this.showExpiry = true;
        }
      } else if (this.sliderFormData.Id) {
        this.selectedDocumentType = this.sliderFormData.Id;

        if (this.sliderFormData.expiry) {
          this.selectedDocumentType = this.sliderFormData.Id;
          this.showExpiry = true;
        }
      }
    } else {
      this.selectedDocumentType = this.sliderFormData.id;
      if (this.sliderFormData.expiry) {
        this.showExpiry = true;
      }
      if (this.selectedDocumentType != null) {
        this.action = true;
      }


      this.uploadedFile = "";
      this.form.controls.FileName.setValue("");
      this.form.controls.DocExpiry.setValue("");
      this.isEditMode = false;
    }
    this.documentTypeList = this.sliderFormData.avilableDocumentTypes;
  }

  protected getUpdateDocument(event: any) {
    this.uploadStatus = false;
    this.Documents = event;
    this.form.controls.FileName.setValue("");
    this.form.controls.FileName.markAllAsTouched();
    if (this.Documents) {
      this.form.controls.FileName.setValue("Identity-Captured");
      this.form.controls.FileName.markAsDirty();
    }
  }

  public calculateQueality(size: any) {
    if (size < 1) {
      return 75;
    } else if (size >= 1 && size < 2) {
      return 50;
    } else {
      return 22;
    }
  }

  documentObject() {
    let doc: any;
    const datePipe = new DatePipe("en-US");
    if (this.Documents.OrgFiles?.length == 1 && this.Documents.OrgFiles[0] != "") {
      doc = {
        prescriptions_pages: this.Documents.OrgFiles.map(
          (s: any) => s
        ),
      };
    } else if (this.Documents.PrescriptionParts.length > 0) {
      doc = {
        prescriptions_pages: this.Documents.PrescriptionParts.map(
          (s: any) => s.prescriptionPartData
        ),
      };
    }
    return {
      patient_id: this.sliderFormData.patient_id,
      document_type: this.selectedDocumentType,
      description: this.selectedDescription,
      details: this.selectedDetail,
      document_data: doc,
      document_name: this.Documents.PrescriptionParts[0].PrescriptionPartName,
      expiry_date: this.docExpiry.value ? datePipe.transform(this.docExpiry.value, "yyyy-MM-dd")
        : "0001-01-01",
    };
  }

  documentObjectForUpdate() {
    console.log(this.sliderFormData);
    const datePipe = new DatePipe("en-US");
    let doc: any;
    if (this.Documents.OrgFiles?.length == 1 && this.Documents.OrgFiles[0] != "") {
      doc = {
        prescriptions_pages: this.Documents.OrgFiles.map(
          (s: any) => s
        ),
      };
    } else if (this.Documents.PrescriptionParts?.length > 0) {
      doc = {
        azure_id: this.sliderFormData.document_path,
        prescriptions_pages: this.Documents.PrescriptionParts.map(
          (s: any) => s.prescriptionPartData
        ),
      };
    }
    return {
      id: this.sliderFormData.id,
      patient_id: this.sliderFormData.patient_id,
      document_type: this.sliderFormData.document_type_id,
      description: this.selectedDescription,
      details: this.selectedDetail,
      document_data: doc,
      azure_id: this.sliderFormData.document_path,
      document_name: this.Documents?.PrescriptionParts[0]?.PrescriptionPartName,
      expiry_date: this.docExpiry.value ? datePipe.transform(this.docExpiry.value, "yyyy-MM-dd")
        : "0001-01-01",
    };
  }
  private isDataValid(data: any): Boolean {
    let isDataValid = true;

    if (data.document_type <= 0) {
      this.isInvalidDocType = true;
      isDataValid = false;
    }

    if (data.document_data.prescriptions_pages.length <= 0) {
      isDataValid = false;
    }

    return isDataValid;
  }

  saveDocument(form: NgForm) {
    this.form.markAllAsTouched();
    if (!this.isEditMode && this.Documents.PrescriptionParts?.length == 0) {
      this.uploadStatus = true;
      this.toastr.warning(this.translate.instant("upload-pic.no-document"));
      return
    }
    if (this.isEditMode && (this.showExpiry == true ? this.docExpiry.valid : true)) {
      if (this.sliderFormData.avilableDocumentTypes.some((item: any) => item["id"] == this.selectedDocumentType &&
        item["patient_id"] == this.sliderFormData.patient_id &&
        item["document_type"].indexOf(this.mandataryMutualCertificateIdentifier) >= 0)
        && moment(this.docExpiry.value).format("DD-MM-YYYY") == moment(this.sliderFormData.uploadedDocs?.filter((item: any) => item["document_type"].indexOf(this.mandataryMutualCertificateIdentifier) >= 0
          && item["document_type_id"] != this.sliderFormData.document_type_id)[0]?.expiry_date).format("DD-MM-YYYY")) {
        this.messageService.alert(this.translate.instant("upload-document.err_expiry_already_present"), 5000);
        return;
      }
      // update document
      const documentForUpdate = this.documentObjectForUpdate();
      this.spinner.show(true);
      this.patientService.updatePatientDocument(documentForUpdate).subscribe(
        (data: any) => {
          data;
          this.loaderService.onFormClose();
          this.messageService.success(
            this.translate.instant("upload-document.document-upload-success"),
            5000
          );
          this.spinner.show(false);
          this.loaderService.refreshState(true);
          this.dismiss();
        },
        (errorResponse: HttpErrorResponse) => {
          this.spinner.show(false);
          console.error(errorResponse);
          this.messageService.alert(errorResponse["message"], 5000);
        }
      );
    } else {
      if (form.valid && (this.showExpiry == true ? this.docExpiry.valid : true)) {
        if (this.sliderFormData.avilableDocumentTypes.some((item: any) => item["id"] == this.selectedDocumentType &&
          item["patient_id"] == this.sliderFormData.patient_id &&
          item["document_type"].indexOf(this.mandataryMutualCertificateIdentifier) >= 0)
          && moment(this.docExpiry.value).format("DD-MM-YYYY") == moment(this.sliderFormData.uploadedDocs?.filter((item: any) => item["document_path"]
            && item["patient_id"] == this.sliderFormData.patient_id
            && item["document_type"].indexOf(this.mandataryMutualCertificateIdentifier) >= 0)[0]?.expiry_date).format("DD-MM-YYYY")) {
          this.messageService.alert(this.translate.instant("upload-document.err_expiry_already_present"), 5000);
          return;
        }

        // insert document
        const document = this.documentObject();
        if (this.isDataValid(document)) {
          this.spinner.show(true);
          this.patientService.createPatientDocument(document).subscribe(
            (data: any) => {
              data;
              this.messageService.success(
                this.translate.instant(
                  "upload-document.document-upload-success"
                ),
                5000
              );
              this.spinner.show(false);
              this.loaderService.refreshState(true);
              this.dismiss();
            },
            (errorResponse: HttpErrorResponse) => {
              this.spinner.show(false);
              console.error(errorResponse);
              this.messageService.alert(errorResponse["message"], 5000);
            }
          );
        }
      }
    }
  }
  cropDocument(form: NgForm) {
    this.form.markAllAsTouched();
    if (!this.isEditMode && this.Documents.PrescriptionParts?.length == 0) {
      this.uploadStatus = true;
      this.toastr.warning(this.translate.instant("upload-pic.no-document"));
      return
    }
    if (this.isEditMode && (this.showExpiry == true ? this.docExpiry.valid : true)) {
      if (this.sliderFormData.avilableDocumentTypes.some((item: any) => item["id"] == this.selectedDocumentType &&
        item["patient_id"] == this.sliderFormData.patient_id &&
        item["document_type"].indexOf(this.mandataryMutualCertificateIdentifier) >= 0)
        && moment(this.docExpiry.value).format("DD-MM-YYYY") == moment(this.sliderFormData.uploadedDocs?.filter((item: any) => item["document_type"].indexOf(this.mandataryMutualCertificateIdentifier) >= 0
          && item["document_type_id"] != this.sliderFormData.document_type_id)[0]?.expiry_date).format("DD-MM-YYYY")) {
        this.messageService.alert(this.translate.instant("upload-document.err_expiry_already_present"), 5000);
        return;
      }
      // update document
      const documentForUpdate = this.documentObjectForUpdate();
      this.spinner.show(true);
      this.patientService.updatePatientDocument(documentForUpdate).subscribe(
        (data: any) => {
          data;
          this.loaderService.onFormClose();
          this.messageService.success(
            this.translate.instant("upload-document.document-upload-success"),
            5000
          );
          this.spinner.show(false);
          this.loaderService.refreshState(true);
          this.dismiss();
        },
        (errorResponse: HttpErrorResponse) => {
          this.spinner.show(false);
          console.error(errorResponse);
          this.messageService.alert(errorResponse["message"], 5000);
        }
      );
    } else {
      if (form.valid && (this.showExpiry == true ? this.docExpiry.valid : true)) {
        if (this.sliderFormData.avilableDocumentTypes.some((item: any) => item["id"] == this.selectedDocumentType &&
          item["patient_id"] == this.sliderFormData.patient_id &&
          item["document_type"].indexOf(this.mandataryMutualCertificateIdentifier) >= 0)
          && moment(this.docExpiry.value).format("DD-MM-YYYY") == moment(this.sliderFormData.uploadedDocs?.filter((item: any) => item["document_path"]
            && item["patient_id"] == this.sliderFormData.patient_id
            && item["document_type"].indexOf(this.mandataryMutualCertificateIdentifier) >= 0)[0]?.expiry_date).format("DD-MM-YYYY")) {
          this.messageService.alert(this.translate.instant("upload-document.err_expiry_already_present"), 5000);
          return;
        }

        // insert document
        const document = this.documentObject();
        if (this.isDataValid(document)) {
          this.spinner.show(true);
          this.patientService.createPatientDocument(document).subscribe(
            (data: any) => {
              data;
              this.messageService.success(
                this.translate.instant(
                  "upload-document.document-upload-success"
                ),
                5000
              );
              this.spinner.show(false);
              this.loaderService.refreshState(true);
              this.dismiss();
            },
            (errorResponse: HttpErrorResponse) => {
              this.spinner.show(false);
              console.error(errorResponse);
              this.messageService.alert(errorResponse["message"], 5000);
            }
          );
        }
      }
    }
  }

  public dropzoneState(event: any) {
    this.isHovering = event;
  }

  openImage(path: any) {
    const docName = path.substring(path.lastIndexOf("/") + 1);
    this.spinner.show(true);
    this.patientService.getPatientDocument(docName).subscribe((res: any) => {
      const byteArray = new Uint8Array(
        atob(res)
          .split("")
          .map((char) => char.charCodeAt(0))
      );
      const file = new Blob([byteArray], { type: "application/octet-stream" });
      this.pdfBlob = URL.createObjectURL(file);
      this.spinner.show(false);
      this.isPdfViewerOpened = true;
    });
  }

  public removeIdentityFile(): void {
    if (this.Documents.PrescriptionParts.length == 0) {
      this.docExpiry.setValue("");
      this.uploadStatus = true;

    }
  }

  protected getClickImageStatus(event: any) {
    this.isClicked = event;
    this.uploadStatus = false;
  }
  protected selectDoc(evt: any) {
    let selectedDoc = this.sliderFormData.avilableDocumentTypes.find(
      (i: any) => i.id == evt.source.value
    );
    if (selectedDoc && selectedDoc?.expiry) {
      this.showExpiry = selectedDoc.expiry;
    } else {
      this.showExpiry = false;
    }
  }

  skip() {
    this.skipMe.emit(true);
  }

  dismiss() {
    this.loaderService.cancelForm();
  }

  protected handleImage(webcamImage: WebcamImage): void {
    this.documentData = webcamImage.imageAsDataUrl;
    this.isIncorrectFileFormat = false;
    this.isInvalidFileSize = false;
    this.form.controls["FileName"].setValue("captured_image.jpeg");
    this.documentName = "captured_image.jpeg";
    fetch(webcamImage.imageAsDataUrl).then(async (response) => {
      const blob = await response.blob();
      this.file = await new FileData(
        new File([blob], "captured_image.jpg", {})
      );
    });
  }
}
