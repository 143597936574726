<div class="popup-container">
    <div class="popup-content">
        <!-- header -->
        <div class="popup-header">
            <!-- <span class="pull-left"> -->
            @if(!isPreviewMode){
            <div class="dropzone-text">
                <span class="dropzone-main-text">{{'upload-pic.crop-image' | translate}}</span>
            </div>}
            <!-- @if(isPreviewMode){
                <div class="dropzone-text">
                    <span class="dropzone-main-text">Croped Image </span>
                </div>} -->
            <!-- </span> -->
        </div>
        <!-- body -->
        <div class="popup-body">
            @if(!isPreviewMode){
            <div class="cropper">
                <image-cropper [imageBase64]="data.imageChangedEvent" format="jpeg"
                    [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" [aspectRatio]="4 / 3"
                    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
                    (cropperReady)="cropperReady()" [canvasRotation]="canvasRotation" [onlyScaleDown]="true"
                    [alignImage]="'center'" class="image-cropper"></image-cropper>
            </div>}
            @if(isPreviewMode){
            <div class="cropper">
                <!-- <h3>Preview</h3> -->
                <img [src]="croppedImage" />
            </div>}
        </div>
        <!-- fotter -->
        <div class="popup-footer">
            @if(!isPreviewMode){
            <div class="btn1">
                <button mat-button btn color="accent" (click)="onClose()"><span
                        class="icon-ic-cross_circle_filled highlight-blue"></span>
                    <div class="color1">{{'admin.Discard'|translate}}</div>
                </button>
                <button mat-button color="accent" (click)="rotateLeft()"><span class="icon-ic-rotate-left"></span>
                    <div class="color1">{{'admin.Left'|translate}}</div>
                </button>
                <button mat-button color="accent" (click)="rotateRight()"><span class="icon-ic-rotate-right"></span>
                    <div class="color1">{{'admin.Right'|translate}}</div>
                </button>
                <button mat-button btn color="accent" type="submit" (click)="onSave()">
                    <span class="icon-ic-tick_circle_filled highlight-blue"></span>
                    <div class="color1">{{'admin.Proceed'|translate}}</div>
                </button>
            </div>
            }
            @if(isPreviewMode){
            <div class="btn1">
                <button mat-raised-button color="primary" type="submit" (click)="backToImage()"
                    class="popup-btn primary-hover">{{'admin.back'|translate}}</button>
                <button mat-button color="accent" (click)="rotateRight()"><span
                        class="icon-ic-rotate-right"></span></button>
                <button mat-raised-button color="primary" type="submit" (click)="onSave()"
                    class="popup-btn primary-hover">
                    {{'admin.save'|translate}}
                </button>
            </div> }
        </div>
    </div>
</div>