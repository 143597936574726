import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-regsiter-toolkit',
    templateUrl: './regsiter-toolkit.component.html',
    styleUrls: ['./regsiter-toolkit.component.scss']
})
export class RegsiterToolkitComponent implements OnInit {

    constructor(private readonly dialogRef: MatDialogRef<RegsiterToolkitComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
    }

    /* #region Events and Methods */
    /** This method is to close the pop up */
    public dismiss(): void {
        this.dialogRef.close(false);
    }

    /** This method is to close the pop up and to pass confirmation action */
    public confirmed(): void {
        this.dialogRef.close(true);
    }
    /* #endregion */

}
