import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BookingAppointmentGuestService } from '../../../services/booking-appointment-guest.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { LocaleService } from '../../../../app.locale.service';
import { LoaderService, MessageService } from '../../../../main';
import { FormControl, Validators } from '@angular/forms';
import { LoginServiceService } from '../../../services/login-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-test-and-type',
  templateUrl: './test-and-type.component.html',
  styleUrl: './test-and-type.component.scss'
})


export class TestAndTypeComponent {
  @Input() allTestType: any[] = [];
  searchinput = "";
  @Input() pre_selected_lab_id: string;
  @Input() pre_selected_test_id: string;
  @Input() pre_selected_test_type_id: number;
  @Output() goBack = new EventEmitter<any>();
  @Output() goNext = new EventEmitter<any>();

  @Output() selectedHavePres = new EventEmitter<any>();
  public isSelected: boolean = false;
  public testTypeSelected = new FormControl("", [Validators.required]);

  public filterObject: any;
  public dataSource: any;
  public searchText = "";
  public searchArray: Array<string>;
  public filtered: any;
  public length: any;
  public testTypeName: any;
  public testTypeSelection = false;
  @Input() testArray: any;
  @Input() selectTest: any[] = [];
  public filteredTest: any[] = [];
  public lab_id = 0;
  public noRecordFound = false;
  public testType: any[] = [];
  public characters: number;
  public selectedTestType: any;
  public selectedTest1: any = '';
  public selectedGender: any;
  public filteredTestType: any;
  public genders: any[];
  public mfGenders: any[];
  public otherGender: any;
  public onTestTypeSelect: boolean = false;
  private subscription: Subscription = new Subscription();
  percent: number;
  previousTestTypeId: any;
  selectedGenderFromTests: any = '';
  genderIdsIsnull: any;

  constructor(
    public bookingAppointmentService: BookingAppointmentGuestService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private localeService: LocaleService,
    public loader: LoaderService,
    private messageService: MessageService,
    private readonly translateService: TranslateService,
    public loginService: LoginServiceService,
    private cdr: ChangeDetectorRef
  ) {
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
    this.lab_id = this.route.snapshot.params["l_id"];
    this.testType = new Array<any>();
  }
  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    if (window.innerWidth < 420) {
      this.characters = 32;
    } else this.characters = 47;
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.testArray.forEach(() => {
      this.testType = this.allTestType;
      this.testType = this.testType.map(test => { return { ...test, isOpen: false } });
    });
    this.subscription.add(
      this.loader.variable$.subscribe(value => {
        this.percent = (value / this.loader.flow_arr.length) * 100;
        this.cdr.detectChanges(); // Manually trigger change detection
      })
    );

    this.getGenders();
    this.filteredTestType = [...this.testType.filter(a => this.testArray.some(b => b.test_type_id === a.Id))];
  }
  ngOnChanges() {
    this.filteredTestType = [...this.testType.filter(a => this.testArray.some(b => b.test_type_id === a.Id))];
  }



  public getGenders() {
    this.loginService.getGenderMasters().subscribe((data: Array<any>) => {
      this.genders = data.sort((a, b) => a.KeyOrder - b.KeyOrder);
      this.genders.forEach((element: any) => {
        element.isClicked = false;
      });
      this.mfGenders = this.genders.filter(gender => gender.Key != 'O');
      this.mfGenders.map(gender => { return { ...gender, isClicked: false } });
      this.otherGender = this.genders.filter(gender => gender.Key == 'O')[0];
    });
  }

  sliderFormData: any;
  isSliderDataEdited?: boolean;

  @Output() selectedTest = new EventEmitter();

  testSelected: boolean = false
  onTestSelected(evt: any) {
    this.testSelected = true
    this.selectedTest1 = evt;
    this.selectedTest.emit(this.selectedTest1);

  }



  onError(index: any) {
    this.selectTest[index].url =
      "https://ecldevproductimage.blob.core.windows.net/productimage/20220927-3ccec799-af06-48b1-9d50-45381998d081.jpg";
  }

  onTestTypeError(index: any) {
    this.testType[index].ImageUrl =
      "https://ecldevproductimage.blob.core.windows.net/productimage/20220927-3ccec799-af06-48b1-9d50-45381998d081.jpg";
  }

  public onTestTypeSelection(t: any) {
    this.onTestTypeSelect = true
    this.selectedTestType = t;

  }

  public onTestTypeNext() {
    if (this.onTestTypeSelect) {
      this.filteredTest = []
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      this.filteredTest = [];
      this.testTypeName = this.loader.TranslateLang(this.selectedTestType.Name);
      if (this.selectedGenderFromTests == '' || this.genderIdsIsnull == '') {
        this.filteredTest = this.selectTest.filter(test => test.test_type_id == this.selectedTestType.Id);
      }
      else {
        this.filteredTest = this.selectTest.filter(test => test.test_type_id == this.selectedTestType.Id && this.selectedGenderFromTests.includes(test.gender));
      }

      this.testTypeSelection = true;
      if (this.previousTestTypeId != this.selectedTestType.Id) {
        this.selectedTest1 = "";
      }
    }
    else {
      this.messageService.alert(
        this.translateService.instant("select-proceed"),
        5000
      );
    }
  }

  public clearSearch() {
    this.testTypeSelection = false;
    this.previousTestTypeId = this.selectedTestType.Id;
    this.noRecordFound = false;
    this.selectTest = this.testArray;
  }

  openCurrent(index: number) {
    this.testType.forEach((element: any) => {
      element.isOpen = false;
    });
    this.testType[index].isOpen = true;
  }

  openCurrentTest(index: number) {
    this.selectTest.forEach((element: any) => {
      element.isOpen = false;
    });
    this.selectTest[index].isOpen = true;
  }

  goToNext() {
    if (this.selectedTest1) {
      this.goNext.emit(true);
    }
    else {
      this.messageService.alert(
        this.translateService.instant("select-proceed"),
        5000
      );
    }
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  goToBack() {
    this.goBack.emit(true);
    this.previousTestTypeId = this.selectedTestType.Id;
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  filterByGender(gender: any) {
    this.selectedGenderFromTests = gender.Id + ", 0";
    let ind = this.mfGenders.findIndex(i => i.Id == gender.Id);
    let oInd = this.mfGenders.findIndex(i => i.Id !== gender.Id);
    this.mfGenders[ind].isClicked = !gender.isClicked;
    this.mfGenders[oInd].isClicked = false;
    let selectedGenders = this.mfGenders.filter((gender: any) => gender.isClicked);
    let genderIds = selectedGenders.map((gender: any) => gender.Id).join(",");
    this.genderIdsIsnull = genderIds;
    if (genderIds) {
      genderIds = genderIds + ", 0";
    }
    else {
      genderIds = this.mfGenders.map(gen => gen.Id).join(",") + ",0";
    }
    let genderIdsArr = genderIds.split(",").map(id => Number(id));
    if (this.selectedTestType) {
      this.filteredTest = this.selectTest.filter(test => genderIdsArr.includes(test.gender) && test.test_type_id == this.selectedTestType?.Id);

      let tl = this.filteredTest.filter(i => i.id == this.selectedTest1.id).length;
      if (tl == 0) {
        this.selectedTest1 = "";
      }
    } else {
      this.filteredTest = this.selectTest.filter(test => genderIdsArr.includes(test.gender));
    }

    let testByGender = this.selectTest.filter(test => genderIdsArr.includes(test.gender));
    this.testArray.forEach(() => {
      this.testType = this.allTestType;
      this.testType = this.testType.map(test => { return { ...test, isOpen: false } });
    });

    let uniqueTT = Array.from(new Set(testByGender.map(t => t.test_type_id)));
    this.filteredTestType = this.allTestType.filter(att => uniqueTT.includes(att.Id));
    if (!this.testTypeSelection) {
      let testTypeList = this.filteredTestType.filter(i => i.Id == this.selectedTestType.Id).length;
      if (testTypeList == 0) {
        this.selectedTestType = "";
        this.onTestTypeSelect = false
      }
    }
  }

  public selectTestType(t: any) {

    this.selectedTestType = t
  }
}