<div class="cross-button">
    <button mat-icon-button color="accent" (click)="dismiss()">
        <span class="icon-ic-close"></span>
    </button>
</div>
<div class="dialog-container">
    <h5>{{ data.title | translate}}</h5>
    <div class="dialog-content">
        {{data.message | translate}}
        <span *ngIf="data.optionalValue" class="optionalValue">{{data.optionalValue}}</span>
        <span *ngIf="data.optionalValue1" class="optionalValue">{{data.optionalValue1}}</span>
    </div>

    <div class="actions">
        <button mat-button (click)="dismiss()" color="accent" class="ghost" *ngIf="data.cancelLabel">{{ data.cancelLabel
            | translate
            }}</button>
        <button mat-raised-button color="primary" type="submit" *ngIf="data.confirmLable" class="button_content"
             (click)="confirmed()">{{
            data.confirmLable | translate}}</button>
    </div>
</div>