<div class="unavailabilty-container">
    <div>
        <button class="close" mat-dialog-close mat-button id="btn_close_dialog">X</button>
    </div>
    <div class="sub-container">
        <div class="header">
            <app-section-title title="{{title | translate}}"></app-section-title>
        </div>

        <form class="form-container" [formGroup]="HolidayForm">
            <mat-form-field def="holidayType">
                <mat-label>{{'manage-holiday.holiday-type'|translate}}</mat-label>
                <mat-select name="holidayType" [disableOptionCentering]="true" formControlName="HolidayType"
                    (selectionChange)="holidayTypeChange($event.value)" [disabled]="editMode">
                    <mat-option *ngFor="let level of data.levels" [value]="level">{{level.KeyName
                        | translate}}</mat-option>
                </mat-select>

            </mat-form-field>


            <mat-form-field def="Company"
                *ngIf="selectedLevel?.Key==ssLevelCode || selectedLevel?.Key==companyLevelCode">
                <mat-label class="label-float">{{'add-holiday.company' | translate}}</mat-label>
                <mat-select formControlName="Company" [disableOptionCentering]="true" required multiple
                    [disabled]="editMode" (selectionChange)="ssFilterOnCompany($event.value)">
                    <app-select-check-all [model]="HolidayForm.get('Company')" [values]="companies || []"
                        (allChecked)="allChecked($event)">
                    </app-select-check-all>
                    <mat-option *ngFor="let company of companies" [value]="company" matTooltip="{{company.org_name}}">
                        <span [style.font-weight]="company.is_active ? 'bold' : 'normal'">{{company.org_name
                            }}</span></mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width" def="samplingStations" *ngIf="selectedLevel?.Key==ssLevelCode">
                <mat-label class="label-float">{{'add-holiday.sampling-station' | translate}}
                </mat-label>
                <mat-select formControlName="SamplingStationsIds" multiple required
                    [disabled]="disableDropDowns || editMode" (selectionChange)="filterOnSS($event.value)">
                    <app-select-check-all [model]="HolidayForm.get('SamplingStationsIds')"
                        [values]="samplingStations || []">
                    </app-select-check-all>
                    <mat-option *ngFor="let samplingStation of samplingStations" [value]="samplingStation"
                        matTooltip="{{samplingStation.sampling_station_name}}  ({{samplingStation.org_name}})">
                        <span
                            [style.font-weight]="samplingStation.is_active ? 'bold' : 'normal'">{{samplingStation.sampling_station_name
                            }} ({{samplingStation.org_name}})</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="date-wrapper">
                <div class="date-container">
                    <app-datepicker id="startDate" labelText="{{'add-holiday.start-date'|translate}}"
                        (selectedDate)="onSelectedDate($event)" [dateInput]="StartDate" isRequired="true"
                        [minDate]="fromMinDate"   [isDisabled]="disableSTdate && editMode">
                    </app-datepicker>
                    <app-datepicker id="endDate" labelText="{{'add-holiday.end-date'|translate}}" [dateInput]="EndDate"
                        isRequired="true" [isDisabled]="disableEndDate" [minDate]="toMinDate">
                    </app-datepicker>
                </div>
            </div>
            <mat-form-field>
                <mat-label>{{'manage-holiday.reason'|translate}}</mat-label>
                <textarea matInput cols="30" formControlName="Reason" rows="5"></textarea>
            </mat-form-field>
            <div class="actions">
                <button mat-button color="accent" class="ghost button_width" type="button" (click)="clear()"
                    id="btn_clear">
                    {{'add-holiday.clear' | translate}}
                </button>
                <button mat-button color="accent" class="ghost button_width" type="submit" (click)="onSaveClick()"
                    id="btn_save">
                    {{'add-holiday.save' | translate}}
                </button>
            </div>
        </form>
    </div>
</div>