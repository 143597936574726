@if (loaderService.flow_arr.indexOf(constants.doc_info)>-1){
<div [ngClass]="{'hide':constants.doc_info !== step }">
    <app-document-info (viewedDocInfo)="goNext()" [hideBack]="currentindex == 0" (goBack)="goBack()"
        [selectedLab]="selectedLab" />
</div>
}
@if (loaderService.flow_arr.indexOf(constants.have_request_number)>-1){
<div [ngClass]="{'hide':constants.have_request_number !== step }">
    <app-check-requestnumber [hideBack]="currentindex == 0" [check_number_val]="check_number_val"
        (haveAppointment)="haveAppointment($event)" (goNext)="goNext()" />
</div>
}

@if (loaderService.flow_arr.indexOf(constants.enter_token)>-1 ){
<div [ngClass]="{'hide':constants.enter_token !== step }">
    <app-enter-token (goBack)="goBack()" [hideBack]="currentindex == 0" [ss_id]="standy_ss_ext_id"
        (goNext)="showTokenNumber($event)" />
</div>
}

@if (loaderService.flow_arr.indexOf(constants.check_file)>-1 ){
<div [ngClass]="{'hide':constants.check_file !== step }">
    <app-check-file-ready (goBack)="goBack()" [hideBack]="currentindex == 0" (goNext)="goNext()"
        (selectedHaveFile)="haveFile($event)" />
</div>
}

@if (loaderService.flow_arr.indexOf(constants.check_presc)>-1 ){
<div [ngClass]="{'hide':constants.check_presc !== step }">
    <app-check-prescription (goBack)="goBack()" (goNext)="goNext()" [hideBack]="currentindex == 0"
        (selectedHavePres)="havePrescription($event)" />
</div>
}

@if (loaderService.flow_arr.indexOf(constants.select_lab)>-1 ){
<div [ngClass]="{'hide':constants.select_lab !== step }" class="patient-info">
    <app-lab-google *ngIf="allSamplingStation?.length > 0" [samplingStationRes]="allSamplingStation"
        (selectedLocation)="onSelectLocation($event)" (selectedFavroitestation)="onselectedFavroitestation($event)"
        (goNext)="goNext()" (goBack)="goBack()" />
</div>
}
@if (loaderService.flow_arr.indexOf(constants.select_test)>-1 && loaderService.testArray.length >0 &&
loaderService.testType.length>0){
<div [ngClass]="{'hide':constants.select_test !== step }">
    <app-test-and-type [hideBack]="currentindex == 0" (selectedTest)="onSelectTest($event)"
        [selectTest]="loaderService.testArray" [testArray]="loaderService.testArray"
        [allTestType]="loaderService.testType" (goBack)="goBack()" (goNext)="goNext()"></app-test-and-type>
</div>
}

@if (loaderService.flow_arr.indexOf(constants.select_slot)>-1 && slotData.slots?.length>0 ){

<div [ngClass]="{'hide':constants.select_slot !== step }">
    <app-date-slot *ngIf="hasCalendar" [calendarDates]="slotData.slots" [hideBack]="currentindex == 0"
        [fromBookingFlow]="true" (fromModalEvent)="onSelectAppointmnt($event)" [s_id]="s_id"
        [test_id]="selectedtest?.id" (goNext)="goNext()" (goBack)="goBack()" />
    <div *ngIf="!hasCalendar">
        <div class="no-slot-container">
            <div class="section-header">
                <circle-progress class="only-web" [options]="loaderService.optionsG" [percent]="calendarPercent"
                    [renderOnClick]="false" imageSrc="assets/images/application-logos/svg/ic-sappintment.svg"
                    class="copy">
                </circle-progress>
                <h3 class="desc-title">{{'calender.datepicker-desc'|translate}}​</h3>
            </div>
            <div class="slot-selection-section">
                <div class="formCointainer">
                    <form #formReference>
                        <div class="date-section">
                            <p class="date-header" [innerHTML]="'stepper-flow.no-calendar' | translate">
                            </p>
                            <span class="datepicker-container-stepper">
                                <div class="datepicker-container">
                                    <mat-form-field class="example-full-width mb-0">
                                        <mat-label>{{'stepper-flow.choose-date'
                                            | translate}}<span class="required"> * &nbsp;</span></mat-label>
                                        <input id="slotTime" matInput [matDatepickerFilter]="myFilter"
                                            (dateInput)="onDateValueChange($event)" [formControl]="slotTime"
                                            [min]="minDate" (dateChange)="onDateValueChange($event)"
                                            [matDatepicker]="picker" [dateInput]="slotTime">
                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                            <span matDatepickerToggleIcon class="icon-ic-calender"></span>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker [dateAdapter]="adapter"></mat-datepicker>
                                    </mat-form-field>
                                    <mat-error class="color-error" *ngIf="slotTime.value!=undefined">
                                        <span *ngIf="!slotTime.valid && isFuture">{{'stepper-flow.future-date'
                                            | translate}}</span>
                                        <span
                                            *ngIf="!slotTime.valid && isNonWorkingDay">{{'stepper-flow.non-working-day'
                                            | translate}}</span>
                                        <span *ngIf="!slotTime.valid && isPastDate">{{'stepper-flow.past-date'
                                            | translate}}</span>
                                    </mat-error>
                                    <mat-error class="color-error" *ngIf="slotTime.hasError('notExist')">
                                        {{'stepper-flow.select-date' |
                                        translate}}</mat-error>
                                </div>
                            </span>
                        </div>
                    </form>
                </div>
                <div class="action-btn">
                    <button mat-raised-button class="next-btn" type="button" [ngClass]="{'full-width':hideBack}"
                        (click)="onSelectAppointmentDate()">
                        {{'date-slot.next' | translate}}
                    </button>
                    <button mat-raised-button class="back-btn" *ngIf="!hideBack" type="button" (click)="goBack()">
                        {{'check-pres.back' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
}

@if (loaderService.flow_arr.indexOf(constants.upload_presc)>-1 ){
<div [ngClass]="{'hide':constants.upload_presc !== step }">
    <app-prescription-upload [hideBack]="currentindex == 0" [item]="documents[0]" [noDocuments]="noDocuments"
        (updateDocument)="getUpdateDocument($event)" (isImageClicked)="getClickImageStatus($event)"
        (goNext)="checkDocument()" (goBack)="goBack()"></app-prescription-upload>
</div>
}

@if (loaderService.flow_arr.indexOf(constants.login)>-1 ){
<div [ngClass]="{'hide':constants.login !== step }">

    <ng-container *ngIf="showLoginPrebook">
        <app-login-prebook (loggedin)="loginSuccessfull()" (registereduser)="RegisterSuccessful($event)"
            [userName]="username" [Password]="password" [showRegister]="showRegister"
            [displayMap]="!loaderService.isMassiveTesting" (goToConfirm)="goNext($event)" [showLogin]="showLogin"
            (goToBack)="goBack()" (forgetPasswordClicked)="openForgotPassword($event)">
        </app-login-prebook>
    </ng-container>
    <div class="password-container" *ngIf="!showLoginPrebook && (forgotPassword || forgetPasswordMailSent)">
        <app-forgot-password [percent]="progressPercent"
            (openLogin)="showLoginPrebook=true;showLogin=true;showRegister=false;"
            (openCreateAccount)="showLoginPrebook=true;showLogin=false;showRegister=true;">
        </app-forgot-password>
    </div>
</div>
}

@if (loaderService.flow_arr.indexOf(constants.confirm_page)>-1 ){
<div [ngClass]="{'hide':constants.confirm_page !== step }">
    <div *ngIf="(registerSuccessful || loginSuccessful || isLoginReceptionist) && showConfirmBooking">
        <app-con-booking [showConfirmBooking]="showConfirmBooking"
            [prescription_url]="selectedprescription?.prescriptionData" [selectedLab]="selectedLab"
            [selectedtest]="selectedtest" [calendar]="slotData?.calendar_id" [user_name]="loaderService.user_name"
            [dob]="loaderService.dob" [start_time]="this.stepconsolidate.appointment" [isConfirmed]="isConfirmed"
            (showLoginScreen)="ShowConfirmationTab($event)" (goToBack)="goBack()" (bookAnotherTest)="redirectBooking()">
        </app-con-booking>
    </div>
</div>
}
@if (loaderService.flow_arr.indexOf(constants.token_number)>-1 ){

<div [ngClass]="{'hide':constants.token_number !== step }">
    <app-token-detail [isKioskAvailable]="isKioskAvailable" [step]="isKioskAvailable==true?2:1"
        [req_number]="tokenNumber" [appointmentTolerance]="appointmentTolerance" />
</div>
}