import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateSkipTimeIfEmpty } from '../eClinical/pipes/date-pipe-if-empty';
import { DateTimeFormatPipe } from '../eClinical/pipes/date-time-format.pipe';


@NgModule({
    declarations: [DateSkipTimeIfEmpty, DateTimeFormatPipe], // <---
    imports: [CommonModule],
    exports: [DateSkipTimeIfEmpty, DateTimeFormatPipe] // <---
})

export class MainPipe { }
