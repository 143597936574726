import { HttpClient } from "@angular/common/http";
import { Component, ComponentFactoryResolver, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { LocaleService } from "../../../app.locale.service";
import { SpinnerService } from "../../../core";
import { LoaderService, MessageService } from "../../../main";
import { PatientAppointment } from "../../patient/my-apointment/my-appointment.model";
import { CommonApiService } from "../../services/common-api.service";
import { PatientAppointmentService } from "../../services/patient-appointment.service";
import { PatientService } from "../../services/patient.service";
import { RegisterComponent } from "../../shared/register/register.component";
import { ScanQrComponent } from "../find-patient/scan-qr/scan-qr.component";
import { RegsiterToolkitComponent } from "./regsiter-toolkit/regsiter-toolkit.component";
import { EditEmailComponent } from "../../shared/edit-email/edit-email.component";
import { RelativeMemberService } from "../../services/relative-member.service";
import { ResultStatus } from "../../patient/results/results.model";
import { CreateAccountComponent } from "./create-account/create-account.component";
import { Location } from '@angular/common';
@Component({
  selector: "app-patient-information",
  templateUrl: "./patient-information.component.html",
  styleUrls: ["./patient-information.component.scss"],
})
export class PatientInformationComponent implements OnInit {
  seacrchInput = new FormControl();
  resetPwdSliderWidth: 40;
  ELEMENT_DATA: PatientAppointment[] = [];
  dataSource: any = [];
  dataSourcePast: any = [];
  dataSourceFuture: any = [];
  dataSourceRM: any;

  name: string;
  ssn: string;
  dob: string;
  age: string;
  mail: string;
  address: string;
  phone: string;
  avatar: string;
  is_secondary: boolean;
  is_active: boolean;
  protected pvtInsurance: string;
  act_age: any;
  details: any;
  ss_external_id: any;
  confirmTitle = "receptionist.confirm-arr-title";
  confirmContent = "receptionist.confirm-arr-content";
  isPdfViewerOpened: boolean;
  pdfBlob: any;
  protected pdfTitle = "";
  qrid: string;
  patientAppointmens: any[] = [];
  pastAppointmens: any[] = [];
  patient: any;
  optionalMutualCertificateIdentifier = "Mutual certificate(";
  displayedColumns: string[] = [
    "firstName",
    "lastName",
    "birthName",
    "dateOfBirth",
    "ssn",
    "gender",
  ];
  refresh = false;
  sampling_stations: any;
  sampling_station_List: any;
  isActive: any;
  previousSearchInputValue: any;
  public documentTypes: any;
  public uploadedDocuments: any;
  protected expiry: any = null;
  protected navigationDetails: any;
  protected date = new Date();
  characters = 30;
  public RM_DATA: any[] = [];
  public displayedColumnsRM: string[] = ['name', 'gender', 'dob'];
  readyResultsdataSource: any;
  resultStatusList: ResultStatus[];
  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    public router: Router,
    private patientAppointmentService: PatientAppointmentService,
    public loader: LoaderService,
    private readonly messageService: MessageService,
    private readonly patientService: PatientService,
    private httpClient: HttpClient,
    private translate: TranslateService,
    private localeService: LocaleService,
    private readonly dialog: MatDialog,
    private commonApiService: CommonApiService,
    private spinner: SpinnerService,
    public rmService: RelativeMemberService,
    private location: Location
  ) {
    this.ELEMENT_DATA = new Array<PatientAppointment>();
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
    this.navigationDetails = this.location.getState();
    if (this.navigationDetails && this.navigationDetails?.patient) {
      this.patient = this.navigationDetails.patient;
      this.previousSearchInputValue = this.navigationDetails.searchInputValue;
      if (this.patient) {
        this.details = {
          Id: this.patient.id,
          FirstName: this.patient.first_name,
          LastName: this.patient.last_name,
          BirthName: this.patient.birth_name,
          Gender: this.patient.gender,
          GenderId: this.patient.gender_id,
          CountryId: this.patient.country_id,
          Ssn: this.patient.ssn,
          Dob: this.patient.dob.substring(0, 10),
          Email: this.patient.email,
          PhoneNumber: this.patient.phone_number,
          Nationality: this.patient.nationality,
          Address: this.patient.address,
          City: this.patient.city,
          qrid: this.patient.qr_id,
          Zip: this.patient.zip,
          BirthCountry: this.patient.birth_country,
          BirthCity: this.patient.birth_city,
          private_insurance: this.patient.pvt_insurance ? "Yes" : "No",
          MinorPassport: this.patient.minor_passport,
          IsActive: this.patient.is_active,
          IsSecondary: this.patient?.is_secondary,
          ParentId: this.patient?.parent_id,
          PFirstName: this.patient?.p_first_name,
          PBirthName: this.patient?.p_birth_name,
          PLastName: this.patient?.p_last_name,
          IppNumber: this.patient?.ipp_number
        };
        this.loadAppointments();
        this.patientAppointmentService
          .getAppointments(this.patient.id, true, 20, "past_self", 1)
          .subscribe((appointmentDetails: any) => {
            if (appointmentDetails) {
              this.pastAppointmens = appointmentDetails;

            }
          });
        this.commonApiService.getDocumentTypeList().subscribe((res: any) => {
          this.documentTypes = res;
          this.spinner.show(true);
          this.patientService
            .getPatientDocuments(this.patient.id)
            .subscribe((res) => {
              this.uploadedDocuments = res;
              this.filterDocuments();
              this.uploadedDocuments?.forEach((doc: any) => {
                if (
                  doc.expiry_date != "" &&
                  (this.expiry == null || this.expiry > doc.expiry_date)
                ) {
                  this.expiry = doc.expiry_date;
                }
              });
              this.spinner.show(false);
            });
        });

        this.getRelativeMemberData(this.patient.id);
        this.getMasterResultStatusList();
        this.is_secondary = this.patient?.is_secondary;
        this.is_active = this.patient.is_active;
      }
    } else {
      this.loader.highlightLeftMenu(["receptionist/find-patient"]);
    }
  }

  checkDate(element: any) {
    const st = new Date(element.SlotTime);
    if (st.getHours() > 0 || st.getMinutes() > 0) {
      return moment(element.SlotTime)
        .startOf("second")
        .isBefore(moment().startOf("second"));
    } else {
      return moment(element.SlotTime)
        .startOf("date")
        .isBefore(moment().startOf("date"));
    }
  }

  ngOnInit(): void {
    this.ss_external_id = this.loader.ss_external_id;
    this.loader.onFormClose().subscribe((data: any) => {
      if (data) {
        if (data.isRec) {
          this.details.Email = data.email;
          this.patient.email = data.email;
          this.fillValues();
        } else if (data.isElevate) {
          this.details.Email = data.email;
          this.patient.email = data.email;
          this.details.IsSecondary = data.isSecondary;
          this.details.IsActive = data.isActive
          this.fillValues();
        }
        else {

          if (data != true) { this.details = data };
          (this.details.private_insurance = this.details.private_insurance
            ? "Yes"
            : "No"),
            this.fillValues();
          this.commonApiService.getDocumentTypeList().subscribe((res: any) => {
            this.documentTypes = res;
            this.filterDocuments();
          });
        }
      }

    });
    this.qrid = this.details.qrid;
    this.fillValues();
  }
  public loadAppointments() {
    this.patientAppointmentService
      .getAppointments(this.patient.id, true, 20, "future_self", 1)
      .subscribe((appointmentDetails: any) => {
        if (appointmentDetails) {
          this.patientAppointmens = appointmentDetails;
          this.dataSource = this.patientAppointmens;
        }
      });
  }


  public checkExpiry(input: any) {
    let currTime = moment(this.date);
    let expTime = moment(this.getDocumentValidity(input));
    let val =
      moment(currTime).format("YYYY-MM-DD") >=
      moment(expTime).format("YYYY-MM-DD");
    return val;
  }

  public checkUploadedDocuments(): boolean {
    let check;
    let keepGoing = true;
    if (!this.uploadedDocuments) {
      check = false;
    } else {
      this.documentTypes?.forEach((doc: any) => {
        if (keepGoing) {
          check = this.isDocumentUploaded(doc) && !this.checkExpiry(doc);
          keepGoing = check;
        }
      });
    }
    return check;
  }

  public filterDocuments() {
    this.documentTypes = this.documentTypes.filter(
      (d: any) => d.Key !== "CovidPassport" && d.Key !== "Prescription"
    );
    if (
      this.details.private_insurance === "No" ||
      !this.details.private_insurance
    ) {
      this.documentTypes = this.documentTypes.filter(
        (d: any) => d.Key !== "Insurance"
      );
    } else {
      if (!this.uploadedDocuments?.some((ud: any) => ud.document_type_en.indexOf(this.optionalMutualCertificateIdentifier) >= 0)) {
        this.documentTypes = this.documentTypes.filter
          ((d: any) => d.EngKeyName.indexOf(this.optionalMutualCertificateIdentifier) < 0)
      }
    }
    if (
      this.loader.calculateAge(this.details.Dob) > 18 ||
      this.details.MinorPassport ||
      this.details.Nationality !== 1
    ) {
      this.documentTypes = this.documentTypes.filter(
        (d: any) => d.Key !== "BirthCertificate"
      );
    }
    this.documentTypes.sort((a: any, b: any) => a.KeyOrder - b.KeyOrder);
  }

  public fillValues() {
    this.name =
      this.details.FirstName +
      " " +
      this.details.LastName +
      " " +
      this.details.BirthName;

    this.avatar = this.name.slice(0, 1);
    this.pvtInsurance = this.details.private_insurance;
    this.ssn = this.details.Ssn;
    this.dob = this.details.Dob.substring(0, 10);
    this.act_age = this.loader.calculateAge(this.details.Dob);
    this.dob = this.dob.slice(0, 10);
    this.mail = this.details.Email;
    this.phone = this.details.PhoneNumber;
    this.address = this.details.Address + "," + this.details.City;
    this.is_secondary = this.details.IsSecondary
    this.is_active = this.details.IsActive
  }

  public deleteAccount(): void {
    this.messageService
      .confirm(
        this.translate.instant("patient-info.delete-sure"),
        this.translate.instant("patient-info.delete-confirm")
      )
      .subscribe((actionResult: boolean) => {
        if (actionResult) {
          const id = JSON.parse(sessionStorage.getItem("userDetails"))["id"];
          this.patientService.deleteAccount(id).subscribe(
            (deletedAccount) => {
              if (deletedAccount != null) {
                window.location.href = "..";
              }
            },
            (error) => {
              error;
            }
          );
        }
      });
  }

  editPatientIfo() {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(RegisterComponent);
    const patientData = {};
    Object.assign(patientData, this.details);
    patientData["Gender"] = patientData["GenderId"];
    patientData["isActive"] = patientData["is_active"];

    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "60%" : "100%",
      { patientInfo: patientData, isEdit: true }
    );
  }

  resetPasswordSlider() {
    const email = {
      email: this.patient.email,
    };
    this.patientService.postSendMailResetPassword(email).subscribe(
      (data: any) => {
        data;
        this.messageService.success(
          this.translate.instant("login.mail-success"),
          5000
        );
      },
      (error: any) => {
        error;
        this.messageService.alert(
          this.translate.instant("login.mail-fail"),
          5000
        );
      }
    );
  }

  confirmDeleteWithYesNo(appointment: any) {
    this.messageService
      .confirm(
        this.translate.instant(this.confirmContent),
        this.translate.instant(this.confirmTitle)
      )
      .subscribe((actionResult: boolean) => {
        if (actionResult) {
          const patientArrivalData = {
            request_number: appointment.appointment.AppointmentCode,
          };
          this.patientService.updateArrival(patientArrivalData).subscribe(
            (data: any) => {
              appointment.appointment.is_arrived = true;
              appointment.appointment.EtwsProcessedTime = data.ArrivedTime;
              this.loadAppointments();
            },
            (error: any) => {
              appointment.appointment.is_arrived = false;
              error;
            }
          );
        }
      });
  }
  viewAppointment() {
    this.loader.highlightLeftMenu(["receptionist/appointments"], {
      state: {
        patient: this.patientAppointmens,
        searchInputValue: this.previousSearchInputValue
      },
    });
  }
  sendActivationLink() {
    this.messageService
      .confirm(
        this.translate.instant("activation-confirmation"),
        this.translate.instant("sendactivationlink")
      )
      .subscribe((actionResult: boolean) => {
        if (actionResult) {
          if (this.patient.logged_in == true) {
            this.loader.sendactivationLink(this.patient.email);
          } else {
            this.loader.sendPasswordLink(this.patient.email);
          }
        }
      });
  }

  openPdf() {
    this.isPdfViewerOpened = true;
    const url = "../assets/data/pdfdummy.pdf";
    this.httpClient
      .get(url, { responseType: "blob" as "json" })
      .subscribe((res: any) => {
        const file = new Blob([res], { type: "application/octet-stream" });
        this.pdfBlob = URL.createObjectURL(file);
      });
  }

  public downloadPres(path: any) {
    const doc = this.uploadedDocuments?.filter(
      (doc: any) => doc.document_type_id === path.Id
    )[0];
    path = doc.document_path;
    const docName = path.substring(path.lastIndexOf("/") + 1);
    this.spinner.show(true);
    this.patientService.getPatientDocument(docName).subscribe((res: any) => {
      const byteArray = new Uint8Array(
        atob(res)
          .split("")
          .map((char) => char.charCodeAt(0))
      );
      const file = new Blob([byteArray], { type: "application/octet-stream" });
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = URL.createObjectURL(file);
      if (doc["document_name"].lastIndexOf(".") > 0) {
        a.download =
          doc["document_name"].substring(
            0,
            doc["document_name"].lastIndexOf(".")
          ) + ".pdf";
      } else if (doc["document_name"] != "") {
        a.download = doc["document_name"] + ".pdf";

      } else {
        a.download = "Document.pdf";
      }
      a.click();
      window.URL.revokeObjectURL(URL.createObjectURL(file));
      a.remove();
      this.spinner.show(false);
    });
  }

  public openImage(path: any) {
    this.pdfTitle = path.KeyName;
    path = this.uploadedDocuments?.filter(
      (doc: any) => doc.document_type_id === path.Id
    )[0].document_path;
    const docName = path.substring(path.lastIndexOf("/") + 1);
    this.spinner.show(true);
    this.patientService.getPatientDocument(docName).subscribe((res: any) => {
      const byteArray = new Uint8Array(
        atob(res)
          .split("")
          .map((char) => char.charCodeAt(0))
      );
      const file = new Blob([byteArray], { type: "application/octet-stream" });
      this.pdfBlob = URL.createObjectURL(file);
      this.spinner.show(false);
      this.isPdfViewerOpened = true;
    });
  }

  public getDocumentValidity(document: any): string {
    return this.uploadedDocuments?.filter(
      (doc: any) => doc.document_type_id === document.Id
    )[0]?.expiry_date;
  }

  public isDocumentUploaded(document: any): boolean {
    if (!this.uploadedDocuments) {
      return false;
    } else {
      return (
        this.uploadedDocuments?.filter(
          (doc: any) => doc.document_type_id === document.Id
        ).length !== 0
      );
    }
  }

  onSearch() {
    this.loader.patientSearchText = this.seacrchInput.value;
    this.loader.highlightLeftMenu(["receptionist/find-patient"], {
      state: { patient: this.seacrchInput.value },
    });
  }

  reset() {
    const self = this;
    if (this.seacrchInput.value) {
      self.refresh = false;
      setTimeout(() => {
        self.refresh = true;
        self.dataSource = [];
      }, 0);
    }
  }
  clearSearch() {
    const self = this;
    self.refresh = false;
    this.seacrchInput.reset();
    setTimeout(() => {
      self.refresh = true;
      self.dataSource = [];
    }, 0);
  }

  rowClicked(data: any) {
    this.loader.highlightLeftMenu(["receptionist/patient-info"], {
      state: { patient: data },
    });
    this.refresh = false;
  }

  redirectReceiptionistBooking() {
    this.loader.bookingPatientId = this.patient.id;
    this.loader.user_name =
      this.patient.first_name + " " + this.patient.birth_name;
    this.loader.dob = this.patient.dob;
    this.loader.bookingPatientName =
      this.patient.id != 0
        ? this.patient.first_name + " " + this.patient.birth_name
        : this.translate.instant('patient-info.guest');
    this.loader.highlightLeftMenu([
      "/receptionist/book-appointment/" + this.ss_external_id,
    ]);
  }

  openRegisterToolKit() {
    this.dialog.open(RegsiterToolkitComponent, {
      width: "500px",
      autoFocus: false,
      disableClose: true,
    });
  }

  scanQR() {
    this.dialog
      .open(ScanQrComponent, {
        width: "500px",
        height: "450px",
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((res) => {
        setTimeout(() => {
          const searchReq = { QrId: res };
          this.patientService
            .getPatients(searchReq)
            .subscribe((response: any) => {
              if (response.data["message"] !== "No Patient Found") {
                this.loader.highlightLeftMenu(["receptionist/patient-info"], {
                  state: { patient: response.data[0] },
                });
              } else {
                this.messageService.warning(
                  this.translate.instant("receptionist.invalid-QR")
                );
              }
            });
        }, 0);
      });
  }


  editEmail() {
    var patientDetails = {
      id: this.details.Id,
      email: this.details.Email
    };
    sessionStorage.setItem("patientDetails", JSON.stringify(patientDetails));

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        EditEmailComponent
      );
    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "30%" : "100%",
      []
    );
  }

  getRelativeMemberData(patientId: string) {
    let self = this;
    this.rmService.getRmData(patientId).subscribe((data: any[]) => {
      this.RM_DATA = data;
      self.dataSourceRM = data;
    }, () => {
      self.dataSourceRM = [];

    });
  }

  getMasterResultStatusList() {
    // Getting Result Status List
    this.commonApiService.getResultStatusList().subscribe((resultStatus) => {
      resultStatus.sort((a, b) => a.KeyOrder - b.KeyOrder);
      this.resultStatusList = resultStatus;
      this.seacrchInput.setValue(this.loader.patientSearchText);
      //this.patient = this.loader.patient;
      this.loadPatientResult();
    });
  }

  private loadPatientResult() {
    // let selectedDate = moment().subtract(90, 'days').format('YYYY-MM-DD');
    const re = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/; // regex to validate DOB format
    let searchText = "";
    let isDOB = false;
    if (re.test(this.seacrchInput.value)) {
      isDOB = true;
      searchText = this.seacrchInput.value.split('/').reverse().join('-');
    } else {
      isDOB = false;
      searchText = this.seacrchInput.value;
    }
    const searchReq = {
      search_text: searchText, ss_id: this.loader.ss_id, from_appointment: false,
      is_dob: isDOB, patient_id: this.patient?.id
    };

    setTimeout(() => {

      this.patientAppointmentService
        .getAppointmentListBySearch(searchReq
        )
        .subscribe(
          (response: any[]) => {
            if (
              response != null &&
              response['message'] !== 'No Patient Found'
            ) {
              this.readyResultsdataSource = response.filter(
                (obj: any) => obj.StatusId <= this.resultStatusList[2].Id
              );
              // this.readyResultsdataSource = response.filter(
              //   (obj: any) => obj.StatusId === this.resultStatusList[3].Id
              // );
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }, 0);
  }

  protected elevateAccountSlider() {
    var patientDetails = this.patient;

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        CreateAccountComponent
      );
    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "40%" : "100%",
      { patient: patientDetails }
    );
  }
}
