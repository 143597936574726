<div class="Container">
    <div class="d-flex justify-content-between search-mr">
        <div class="d-flex Search">
            <div id="inputsearch" class="">
                <mat-form-field>
                    <mat-label>{{'user.id-name' | translate}}</mat-label>
                    <input [(ngModel)]="searchinput" matInput (keydown.enter)="onSearch()"
                        (keydown.space)="keyDownHandler($event)" />
                </mat-form-field>
            </div>
            <div id="searchbtn" class="">
                <button (click)="onSearch()" style="margin-left: 15%" id="btn_search" mat-raised-button color="primary">
                    {{'user.search' | translate}}
                </button>
            </div>
            <div id="resetbtn" class="">
                <button (click)="reset()" style="text-align: center; margin-left: 25%;" id="btn_reset" mat-button
                    color="accent" class="ghost">
                    {{'user.reset' | translate}}
                </button>
            </div>
        </div>
        <div class="d-flex Button">
            <div id="statusfilter" class="mr-4">
                <mat-form-field floatLabel="never" class="dropdown-filter">
                    <mat-select placeholder="{{'admin.status' | translate}}" disableOptionCentering
                        [(ngModel)]="status">
                        <mat-option *ngFor="let stepOption of sampleData | keyvalue: unsorted" (click)="statusFilter()"
                            [value]="stepOption.key">
                            {{ stepOption.value | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div id="adduserbtn" (click)="addUser()" class="mx-4">
                <button id="btn_add" mat-raised-button color="primary">{{'user.add-user' | translate}}</button>
            </div>
            <!-- <div id="exportuserbtn" style="text-align: right" class="ml-4">
                <button [matMenuTriggerFor]="menu" mat-raised-button color="primary">
                    {{'user.export' | translate}}
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="exporter.exportTable('csv', { fileName: 'Users' })">
                        .csv
                    </button>
                    <button mat-menu-item (click)="exporter.exportTable('xlsx', { fileName: 'Users' })">
                        .xlsx
                    </button>
                </mat-menu>
            </div> -->
        </div>
    </div>
    <div class="main-container main-container-res">
        <mat-table [dataSource]="dataSource" matSort class="table-height input-min-spacing table-flex"
            id="EntityListingTable">

            <ng-container matColumnDef="ID">
                <mat-header-cell *matHeaderCellDef mat-sort-header="id">
                    <span>{{'user.id' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity; let i = index">
                    {{ entity.id }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Name">
                <mat-header-cell *matHeaderCellDef mat-sort-header="last_name">
                    <span>{{'user.name' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity.last_name + ', ' + entity.first_name}}">
                    {{ entity.last_name }}, {{entity.first_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Email">
                <mat-header-cell *matHeaderCellDef mat-sort-header="email">
                    <span>{{'user.email' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity.email}}">
                    {{ entity.email }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Status">
                <mat-header-cell *matHeaderCellDef>
                    <span>{{'admin.status' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{entity.is_active == 'true' ? 'Active': 'Suspended'}}">
                    {{entity.is_active == 'true' ? ('admin.active'|translate):('admin.suspended'|translate)}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Role">
                <mat-header-cell *matHeaderCellDef mat-sort-header="role_name">
                    <span>{{'user.role' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity.role_name }}">
                    {{ entity.role_name }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Companyaffectations">
                <mat-header-cell *matHeaderCellDef mat-sort-header="org_name">
                    <span>{{'user.company-affectations' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity.OrgDetails | orgName}}"
                    (click)="showCompData(entity)">
                    {{ entity.OrgDetails | orgName }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="SSaffectations">
                <mat-header-cell *matHeaderCellDef mat-sort-header="sampling_station_name">
                    <span>{{'user.ss-affectations' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let entity" matTooltip="{{ entity.SampDetails | samplingStationsName }}"
                    (click)="showAffecationsData(entity)">
                    {{ entity.SampDetails | samplingStationsName }}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="icon-column">
                <mat-header-cell *matHeaderCellDef>{{'user.options' | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let entity; let i = index" style="justify-content: flex-end;padding-bottom: 0 !important;
                        padding-top: 12px !important;">
                    <button rippleOnHover class="mat-tbl-icon editEntity_btn mr-8" *ngIf="entity.ID == hoverindex"
                        mat-icon-button (click)="editUser(entity)">
                        <span class="icon-ic-edit" matTooltip="{{'user.edit' | translate}}"
                            matTooltipClass="tooltip-style"></span>
                    </button>
                    <button rippleOnHover class="mat-tbl-icon deleteEntity_btn mr-8" *ngIf="entity.ID == hoverindex"
                        mat-icon-button (click)="deleteUser(entity?.id)">
                        <span class="icon-ic-delete" matTooltip="{{'user.delete' | translate}}"
                            matTooltipClass="tooltip-style"></span>
                    </button>
                    <!-- <button rippleOnHover class="mat-tbl-icon editEntity_btn mr-8" *ngIf="entity.ID == hoverindex"
                    mat-icon-button (click)="expandRow(i)">
                     <span class="icon-ic-edit" matTooltip="{{'user.edit' | translate}}"
                        matTooltipClass="tooltip-style"></span>
                        <mat-icon class="expand">
                            {{ selectedIndex !== i ? 'expand_more' : 'expand_less'}}
                        </mat-icon>
                </button> -->

                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnList; sticky: true"></mat-header-row>
            <mat-row [class.isExpand]="selectedIndex == i" *matRowDef="let row; columns: columnList; let i = index"
                class="element-grid-row" (mouseover)="rowMouseOver(row)" (click)="expandRow(i)">
            </mat-row>
        </mat-table>

        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons="true">
        </mat-paginator>
    </div>
</div>