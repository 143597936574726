import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FileData } from '../../../../features/styles/models/file-data';
import { PrescriptionDocument } from '../../appointment-prescription/appointment-prescription.model';
import { LoginServiceService } from '../../../services/login-service.service';
import { LoaderService, MessageService } from '../../../../main';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';

import { DatePipe } from "@angular/common";
import { constants } from '../../constant';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.scss']
})


export class DocUploadComponent implements OnInit {
  @ViewChild("newpassword") newpassword: ElementRef;
  @Input() nationality: any;
  @Input() isPatient: boolean = true;
  @Input() minor: any;
  @Input() forRM: boolean = false;
  @Input() useMySSN: string;
  @Input() doc_ids: string;
  @Input() showBack: boolean = true;
  @Input() fromAppointment: any = false;
  @Output() backEmit = new EventEmitter();
  @Output() submitEmit = new EventEmitter<any>();
  public IdentityFile = new FormControl("");
  public BirthFile = new FormControl("");
  public InsuranceFile = new FormControl("");
  public minorPassport = new FormControl("");
  public insurance = new FormControl("");
  public insuranceExpiry = new FormControl(null);
  value: string;
  maxDate: Date;
  documentType: any[];
  public newSalt: string;
  public isUpdate: boolean;
  public identity: FileData = null;
  private birthCertificate: FileData = null;
  private insuranceDoc: FileData = null;
  public isHovering: boolean;
  public isEmailNotificationRequired: boolean;
  public isSaveClicked: boolean;
  public registerUserClickedinsuranceExpiry = false;
  choices = [
    { Id: 1, KeyName: "Yes" },
    { Id: 2, KeyName: "No" },
  ];

  public docForm = this.formBuilder.group({
    insuranceDoc: this.insuranceDoc,
    IdentityFile: this.IdentityFile,
    BirthFile: this.BirthFile,
    InsuranceFile: this.InsuranceFile,
    birthCertificate: this.birthCertificate,
    identity: this.identity,
    insuranceExpiry: this.insuranceExpiry,
    insurance: this.insurance,
  });
  hide = true;
  isUser = false;
  isNurse = false;
  ifFrance = false;
  isPasswordValid: boolean;
  isResetPasswordHide = true;
  passwordsMisMatch = false;
  strAfterPercent: string;
  isDisable = true;
  params: any;
  nationalities: any[];
  isAutoCompleteFocused: boolean;
  autocomplete: any;
  place: any;
  isAddressRequired: boolean;
  isTermsFlagRequired: boolean;
  obj = {
    age: 0,
    nationality: 0,
  };
  paramsObject: any;
  public minDate = new Date();
  public terms = new FormControl(false);
  public privacy = new FormControl(false);


  public identityDocuments: PrescriptionDocument = {
    Id: 1,
    PrescriptionParts: [],
    IsIncorrectFileFormat: false,
    IsInvalidFileSize: false,
    Expanded: true,
  };
  public birthDocuments: PrescriptionDocument = {
    Id: 2,
    PrescriptionParts: [],
    IsIncorrectFileFormat: false,
    IsInvalidFileSize: false,
    Expanded: true,
  };
  public insuranceDocuments: PrescriptionDocument = {
    Id: 3,
    PrescriptionParts: [],
    IsIncorrectFileFormat: false,
    IsInvalidFileSize: false,
    Expanded: true,
  };
  public identityItem = {
    Id: 1,
    FrontFileName: "",
    BackFileName: "",
    IsIncorrectFrontFileFormat: false,
    IsInvalidFrontFileSize: false,
    IsIncorrectBackFileFormat: false,
    IsInvalidBackFileSize: false,
    PrescriptionParts: ([] = []),
    Expanded: true,
    show: false
  };
  public birthItem = {
    Id: 2,
    FrontSideData: "",
    BackSideData: "",
    FrontFileName: "",
    BackFileName: "",
    IsIncorrectFrontFileFormat: false,
    IsInvalidFrontFileSize: false,
    IsIncorrectBackFileFormat: false,
    IsInvalidBackFileSize: false,
    PrescriptionParts: ([] = []),
    Expanded: true,
    show: false
  };
  public insuranceItem = {
    Id: 3,
    FrontSideData: "",
    BackSideData: "",
    FrontFileName: "",
    BackFileName: "",
    IsIncorrectFrontFileFormat: false,
    IsInvalidFrontFileSize: false,
    IsIncorrectBackFileFormat: false,
    IsInvalidBackFileSize: false,
    PrescriptionParts: ([] = []),
    Expanded: true,
    show: false
  };
  private subscription: Subscription = new Subscription();

  public showInsurance: boolean = true;
  public ud: any;
  public bookAppointment: boolean = false;
  readonly constants = constants;
  public percent: number;
  constructor(
    public formBuilder: FormBuilder,
    public loginService: LoginServiceService,
    public loader: LoaderService,
    private translate: TranslateService,
    public router: Router,
    public authenticationService: AuthenticationService,
    private messageService: MessageService,
    public cdr: ChangeDetectorRef
  ) {
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.maxDate = new Date();
    this.documentType = new Array<any>();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.loader.variable$.subscribe(value => {
        this.percent = (value / this.loader.flow_arr.length) * 100;
        this.cdr.detectChanges(); // Manually trigger change detection
      })
    );
    this.getDocumentType();

    if (JSON.parse(sessionStorage.getItem("userDetails"))) {
      this.ud = JSON.parse(sessionStorage.getItem("userDetails"));
    }
    else {
      let p_data = JSON.parse(sessionStorage.getItem("p_data"));
      this.ud = { "first_name": p_data?.FirstName, "last_name": p_data?.LastName };
    }
    this.docForm.controls.insurance.setValue("1");
    this.minorPassport.setValue("1");
    if (this.forRM == true && this.useMySSN == '0') {
      //hide insurance doc field
      this.showInsurance = false;
    }
    if ((window.location.href.includes("patient/book-appointment") || window.location.href.includes("patient/complete-appointment")) && screen.width < 962) {
      this.bookAppointment = true;
    }
  }

  public noWhitespaceValidator(password: FormControl) {
    const isWhitespace = (password.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  back() {
    this.backEmit.emit();
  }

  disableSpace(event: Event) {
    event.preventDefault();
  }

  public getDocumentType() {
    this.loginService.getDocType().subscribe((data: Array<any>) => {
      this.documentType = data.sort((a, b) => a.KeyOrder - b.KeyOrder);
      if (this.loader.flow_detail == constants.complete_booking_flow) {
        if (this.doc_ids == "0") {
          this.insuranceItem.show = true;
          this.identityItem.show = true;
          this.birthItem.show = true;
        } else {
          let ids = this.doc_ids?.split(",");

          this.documentType.forEach((dt: any) => {
            ids.forEach((id: any) => {
              if (id == dt.Id && dt?.Key.toLowerCase() == "Insurance".toLowerCase()) {
                this.insuranceItem.show = true;
              }
              else if (id == dt.Id && dt?.Key.toLowerCase() == "BirthCertificate".toLowerCase()) {
                this.birthItem.show = true;
                this.identityItem.show = true;
              } else if (id == dt.Id && dt?.Key.toLowerCase() == "SSN".toLowerCase()) {
                this.identityItem.show = true;
                this.birthItem.show = true;
              }
            });
          });
        }

      }
      else {
        this.insuranceItem.show = true;
        this.identityItem.show = true;
        this.birthItem.show = true;
      }
    });
  }

  public proceed() {
    this.insurance.addValidators(Validators.required);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  public clearForm() {
    this.insuranceExpiry.removeValidators(Validators.required);
    this.removeIdentityFile();
    this.removeBirthFile();
    this.removeInsuranceFile();
    this.insurance.setValue("1");
    this.minorPassport.setValue("1");
  }

  public removeIdentityFile(): void {
    this.identity = null;
    this.docForm.controls.IdentityFile.setValue("");
  }
  public removeIdentityFileOnly(): void {
    this.identity = null;

    this.docForm.controls.IdentityFile.setValue("");
  }
  public removeBirthFile(): void {
    this.birthCertificate = null;
    this.docForm.controls.BirthFile.setValue("");
  }
  public removeInsuranceFile(): void {
    this.insuranceDoc = null;
    this.registerUserClickedinsuranceExpiry = false;
    this.insuranceExpiry.setValue("");
    this.docForm.controls.InsuranceFile.setValue("");
  }

  public removeInsuranceFileOnly(): void {
    this.insuranceDoc = null;
    this.docForm.controls.InsuranceFile.setValue("");
    if (this.insuranceItem.PrescriptionParts.length == 0) {
      this.registerUserClickedinsuranceExpiry = false;
      this.insuranceExpiry.setValue("");
    }
  }

  public getUpdateIdentityDocument(event: any) {
    this.identityDocuments = event;
    this.docForm.controls.IdentityFile.setValue("");
    this.docForm.controls.IdentityFile.markAllAsTouched();
    if (this.identityDocuments) {

      this.docForm.controls.IdentityFile.setValue("Identity-Captured");
      this.docForm.controls.IdentityFile.markAsDirty();
    }
  }

  public getUpdateBirthDocument(event: any) {
    this.birthDocuments = event;
    this.docForm.controls.BirthFile.setValue("");
    this.docForm.controls.BirthFile.markAllAsTouched();
    if (this.birthDocuments) {
      this.docForm.controls.BirthFile.setValue("Birth-Captured");
      this.docForm.controls.BirthFile.markAsDirty();
    }
  }

  public getUpdateInsuranceDocument(event: any) {
    this.insuranceDocuments = event;
    this.docForm.controls.InsuranceFile.setValue("");
    this.docForm.controls.InsuranceFile.markAllAsTouched();
    if (this.insuranceDocuments) {
      this.registerUserClickedinsuranceExpiry = true;
      this.docForm.controls.InsuranceFile.setValue("Identity-Captured");
      this.docForm.controls.InsuranceFile.markAsDirty();
    }
  }

  identityObject() {
    let doc: any;
    if (this.identityDocuments.OrgFiles?.length == 1 && this.identityDocuments.OrgFiles[0] != "") {
      doc = {
        prescriptions_pages: this.identityDocuments.OrgFiles.map(
          (s: any) => s
        ),
      };
    } else if (this.identityDocuments.PrescriptionParts.length > 0) {
      doc = {
        prescriptions_pages: this.identityDocuments.PrescriptionParts.map(
          (s: any) => s.prescriptionPartData
        ),
      };
    }
    return {
      document_type: this.documentType.find(
        (t) => t.KeyName == "Identity card / Passport"
      ).Id,
      description: "",
      details: "",
      expiry_date: "0001-01-01",
      document_name: this.identityDocuments.PrescriptionParts[0].PrescriptionPartName,
      document_data: doc,
    };
  }
  birthObject() {
    let doc: any;
    if (this.birthDocuments.OrgFiles?.length == 1 && this.birthDocuments.OrgFiles[0] != "") {
      doc = {
        prescriptions_pages: this.birthDocuments.OrgFiles.map(
          (s: any) => s
        ),
      };
    } else if (this.birthDocuments.PrescriptionParts.length > 0) {
      doc = {
        prescriptions_pages: this.birthDocuments.PrescriptionParts.map(
          (s: any) => s.prescriptionPartData
        ),
      };
    }
    return {
      document_type: this.documentType.find(
        (t) => t.KeyName == "Birth Certificate"
      ).Id,
      description: "",
      details: "",
      expiry_date: "0001-01-01",
      document_name: this.birthDocuments.PrescriptionParts[0].PrescriptionPartName,
      document_data: doc,
    };
  }
  insuranceObject() {
    let doc: any;
    if (this.insuranceDocuments.OrgFiles?.length == 1 && this.insuranceDocuments.OrgFiles[0] != "") {
      doc = {
        prescriptions_pages: this.insuranceDocuments.OrgFiles.map(
          (s: any) => s
        ),
      };
    } else if (this.insuranceDocuments.PrescriptionParts.length > 0) {
      doc = {
        prescriptions_pages: this.insuranceDocuments.PrescriptionParts.map(
          (s: any) => s.prescriptionPartData
        ),
      };
    }
    const datePipe = new DatePipe("en-US");
    return {
      document_type: this.documentType.find(
        (t) => t.KeyName == "Mutual certificate"
      ).Id,
      description: "",
      details: "",
      expiry_date: datePipe.transform(this.insuranceExpiry.value, "yyyy-MM-dd"),
      document_name: this.insuranceDocuments.PrescriptionParts[0].PrescriptionPartName,
      document_data: doc,
    };
  }
  public SaveDocuments() {
    if (!this.showInsurance) {
      this.insurance.setValue("2");
    }
    this.registerUserClickedinsuranceExpiry = this.InsuranceFile.value !== "";
    let insuranceCheck = this.registerUserClickedinsuranceExpiry
      ? this.insuranceExpiry.value !== null && this.insuranceExpiry.value !== ""
      : true;

    if (this.registerUserClickedinsuranceExpiry) {
      this.insuranceExpiry.markAsTouched();
    }
    if (this.insurance.value === "2") {
      insuranceCheck = true;
      this.removeInsuranceFile();
    }
    if (
      (this.InsuranceFile.value != "" && this.insuranceExpiry.value == "")
    ) {
      this.messageService.alert(
        this.translate.instant("patient.select-expiry"),
        5000
      );
    }
    if (
      this.docForm.valid &&
      insuranceCheck &&
      (this.identityItem.show && this.IdentityFile.value === "" && this.minorPassport.value === "1" ||
        (this.minorPassport.value === "2" && this.BirthFile.value === "" && this.birthItem.show) ||
        (this.showInsurance && this.insurance.value === "1" && this.InsuranceFile.value === "" && this.insuranceItem.show))
    ) {


      this.messageService
        .confirm("createAccount.alertText", "createAccount.alerTitle")
        .subscribe((actionResult: boolean) => {
          if (actionResult) {
            if (this.docForm.valid) {
              this.UploadFiles();

            }
          }
        });
    } else if (insuranceCheck && this.docForm.valid) {
      this.UploadFiles();
    }
  }

  public UploadFiles() {
    const docs: {
      document_type: any;
      description: string;
      details: string;
      expiry_date: string;
      document_name: string;
      document_data: any;
    }[] = [];
    if (this.IdentityFile.value != "") {
      docs.push(this.identityObject());
    }
    if (this.BirthFile.value != "" && this.minor) {
      docs.push(this.birthObject());
    }
    if (this.InsuranceFile.value != "") {
      docs.push(this.insuranceObject());
    }
    let payload = {
      minor_passport: this.minorPassport.value == "1",
      pvt_insurance: this.insurance.value == "1",
      documents: docs,
    };

    this.submitEmit.emit(payload);
  }

  resetBirthField(id: any) {
    this.birthCertificate = null;
    this.docForm.controls.BirthFile.setValue("");
    this.birthItem.PrescriptionParts = [];
    if (id == 2) {
      this.removeIdentityFile();
      this.identityItem.PrescriptionParts = [];
    }
  }

  resetField(id: any) {
    if (id == 2) {
      this.insuranceItem.PrescriptionParts = [];
      this.removeInsuranceFileOnly();
    }
  }
}
