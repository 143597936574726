<div class="upload-pic-container p-20">
  <div class="header">
    <div class="pull-left">
      <app-section-title
        title="{{ ( sliderFormData.row_data?.PrescriptionUrl == '' ? 'patient-info.upload-prescription' : 'all-apointment.replace_prescription' ) | translate }}"></app-section-title>
    </div>
    <div class="pull-right">
      <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
        <span class="icon-ic-close"></span>
      </button>
    </div>
  </div>
  <div class="formCointainer">
    <form [formGroup]="form" #formReference>
      <div class="form-layout">
        <div>
          <p class="upload-header only-web">
            {{ ( sliderFormData.row_data?.PrescriptionUrl == '' ? 'patient-info.upload-prescription' :
            'all-apointment.replace_prescription' ) | translate }}
          </p>
          <p class="upload-header only-mobile">
            {{'upload-pic.click-picture' | translate}}
          </p>
        </div>
        <div (click)="fileInput.click()" class="pointCursor dropzone only-web" appFileDrop
          (filesDropped)="handleFilteredFileInput($event)" (filesHovered)="dropzoneState($event)"
          [class.hovering]="isHovering" [class.invalid]="
            (form.controls.FileName.touched &&
              form.controls.FileName.invalid) ||
            (isSaveClicked && form.controls.FileName.invalid)
          " #dropZone>
          <div class="dropzone-text">
            <span class="dropzone-main-text">{{'upload-prescription.drop-file' | translate}}</span>
            <span class="dropzone-sub-text">{{'upload-prescription.upload-file-with-extension' | translate}}</span>
          </div>
          <div>
            <img class="icon-layout" src="/assets/images/application-logos/svg/ic-doc-upload.svg" />
          </div>
        </div>

        <div *ngIf="!imageCapture" (click)="captureImage()" class="pointCursor dropzone only-mobile" appFileDrop
          (filesDropped)="handleFilteredFileInput($event)" (filesHovered)="dropzoneState($event)"
          [class.hovering]="isHovering" #dropZone>
          <div>
            <span class="icon-ic-click-picture capture-image-icon"></span>
          </div>
        </div>
        <webcam [height]="300" [width]="300" *ngIf="imageCapture && !imageCaptured" class="only-mobile camera-container"
          [trigger]="triggerObservable" (imageCapture)="handleImage($event)" (initError)="handleInitError($event)"
          [imageQuality]="1"></webcam>
        <img class="only-mobile" *ngIf="imageCapture && imageCaptured" [src]="prescriptionData" />
        <button *ngIf="imageCapture && !imageCaptured" mat-button color="accent"
          class="ghost capture-image-button only-mobile" (click)="triggerSnapshot()" type="button">
          {{'upload-pic.capture' | translate}}
        </button>
        <button *ngIf="imageCapture && imageCaptured" mat-button color="accent"
          class="ghost capture-image-button only-mobile" (click)="retakeSnapshot()" type="button">
          {{'upload-pic.retake' | translate}}
        </button>

        <div *ngIf="dropZone.touched && file == null">
          {{'upload-prescription.attach' | translate}}
        </div>
        <div class="separator">
          <span class="separator-text"> {{'upload-prescription.or' | translate}} </span>
        </div>

        <mat-label class="form-label" i18n="@@Common.Component.BrowseFile">{{'upload-prescription.browse-file' |
          translate}}</mat-label>
        <mat-form-field class="pointCursor browse-input">
          <input matInput [formControl]="FileName"
            placeholder="{{'upload-prescription.browse-upload-file' | translate}}" readonly />
          <button class="mat-tbl-icon buttonInsideField" (click)="fileInput.click()" *ngIf="file == null">
            {{'upload-prescription.browse' | translate}}
          </button>
          <button class="mat-tbl-icon pull-right remove mt-4" *ngIf="file != null" (click)="removeUploadedFile($event)"
            mat-icon-button>
            <span class="icon-ic-delete"></span>
          </button>

        </mat-form-field>
        <mat-hint *ngIf="isIncorrectFileFormat">{{'upload-pic.upload-file-with-extension' | translate}}</mat-hint>
        <mat-hint *ngIf="isInvalidFileSize">{{'upload-pic.upload-file-size' | translate}}</mat-hint>
        <input hidden type="file" accept=".png, .jpeg, .pdf, .jpg" (click)="$event.target.value = null"
          (change)="handleFilteredFileInput($event.target.files)" #fileInput />

        <div class="actions">
          <button mat-raised-button color="primary" type="submit"
            [disabled]="isIncorrectFileFormat || isInvalidFileSize || file == null" (click)="uploadprescription()"
            class="button_content primary-hover">
            {{'upload-prescription.upload' | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>