export class DateSlot {
    constructor() {
        this.slots = new Array<Slot>();
    }
    public calendar_id: number;
    public slots: Array<Slot>;
}

export class Slot {
    constructor() {
        this.slots = new Array<string>();
        this.filterSlots = new Array<string>();
    }
    public date: any;
    public slots: Array<string>;
    public filterSlots: Array<string>;
}