<div class="slider-data-container">
    <div class="header">
        <div style="text-align: center;">
            <app-section-title title="{{title | translate}}"></app-section-title>
        </div>
    </div>
    <!-- <div class="col-md-11">
            <h5>ID Company</h5>
            <mat-form-field>
                <input *ngIf="!data.edit" matInput name="CompanyId" id="CompanyId" [formControl]="CompanyId" [disabled]="true">
                <input *ngIf="data.edit" matInput name="CompanyId" id="CompanyId" [disabled]="true" [value]="data.company['Id']">
            </mat-form-field>
        </div> -->
    <form class="pt-30" [formGroup]="CompanyForm" #companyForm class="input-min-spacing">
        <div id="addCompany_form" class="form-layout">


            <div [ngClass]="this.data.action!='edit' ? 'invisible' : 'form-layout'">
                <mat-form-field>
                    <mat-label class="label-float">{{'company.id' | translate}}</mat-label>
                    <input matInput name="Id" id="Id" [value]="data?.obj?.id" disabled />
                </mat-form-field>
            </div>

            <mat-form-field>
                <mat-label>{{'company.external-id' | translate}}</mat-label>
                <input matInput name="ExternalId" id="ExternalId" formControlName="ExternalId" />
                <mat-error *ngIf="CompanyForm.get('ExternalId').hasError('pattern')">{{'company.valid-input' |
                    translate}}
                </mat-error>
                <mat-error
                    *ngIf="CompanyForm.get('ExternalId').invalid && !CompanyForm.get('ExternalId').errors?.required && !CompanyForm.get('ExternalId').hasError('pattern')">
                    {{'company.external-id-exists' | translate}}</mat-error>

            </mat-form-field>
            <div class="md 40">
                <mat-form-field>
                    <mat-label>{{'company.company-name' | translate}}</mat-label>
                    <!-- <input *ngIf="!data.edit" matInput name="Name" id="Name" [formControl]="Name" required="true" >
                <input *ngIf="data.edit" matInput name="Name" id="Name" required="true" > -->
                    <input matInput formControlName="Name" maxlength="200" autocomplete="off">
                    <mat-error *ngIf="CompanyForm.get('Name').hasError('pattern')">{{'company.valid-input' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field def="Status">
                    <mat-label class="label-float">{{'admin.status' | translate}}</mat-label>
                    <mat-select placeholder="{{'admin.status' | translate}}" formControlName="Status" required="true"
                        [disableOptionCentering]="true">
                        <mat-option [value]="true">{{'admin.active' | translate}}</mat-option>
                        <mat-option [value]="false">{{'admin.suspended' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="actions" inline>

            <button mat-button color="accent" class="ghost button_width" type="submit" id="btn_close"
                (click)="dismiss()">{{'admin.close' | translate}}</button>
            <button mat-button color="accent" class="ghost button_width" type="submit" id="btn_clear" *ngIf="adminRole"
                (click)="reset()">{{'admin.clear' | translate}}</button>
            <button mat-button color="accent" class="ghost button_width" type="submit" id="btn_save" *ngIf="adminRole"
                (click)="saveCompany()">{{'admin.save' | translate}}</button>
        </div>
    </form>
</div>