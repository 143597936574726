<button class="close" mat-dialog-close="true" mat-button id="btn_close_dialog">X</button>
<div id="createAccount_Slider" class="slider-data-container">
    <div class="header">
        <div style="text-align: center;">
            <app-section-title title="{{title | translate}}"></app-section-title>
            <!-- <h5>{{title | translate}}</h5> -->
        </div>
    </div>

    <form class="pt-30" [formGroup]="SamplingForm" #formDirective="ngForm" [appAutoScroll]="SamplingForm">
        <ng-container>
            <div class="header">
                <h5>{{'add-sampling.sampling-station-detail' | translate}}</h5>
            </div>
            <div id="createAccount_form" class="form-layout">
                <mat-form-field>
                    <mat-label class="label-float">{{'add-sampling.sampling-station-id' | translate}}</mat-label>
                    <input matInput formControlName="SamplingStationID" maxlength="30" autocomplete="off" required
                        (keypress)="keyPressAlphaNumeric($event)">
                </mat-form-field>

                <mat-form-field def="Status">
                    <mat-label class="label-float">{{'admin.status' | translate}}</mat-label>
                    <mat-select formControlName="Status" required="true" [disableOptionCentering]="true">
                        <mat-option value="Active">{{'admin.active' | translate}}</mat-option>
                        <mat-option value="Suspended">{{'admin.suspended' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label class="label-float">{{'add-sampling.sampling-station' | translate}}</mat-label>
                    <input matInput formControlName="SamplingStation" maxlength="200" autocomplete="off" required
                        (keydown.space)="keyDownHandler($event)">
                </mat-form-field>


                <mat-form-field def="Company">
                    <mat-label class="label-float" appearance="none">{{'add-sampling.company' | translate}}</mat-label>
                    <mat-select formControlName="Company" required="true" [disableOptionCentering]="true">
                        <mat-option *ngFor="let company of companies" [value]="company.id">{{company.org_name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width" def="Type">
                    <mat-label class="label-float">{{'add-sampling.type' | translate}}</mat-label>
                    <mat-select formControlName="Type" (selectionChange)="onTestTypeSelected($event)" multiple>
                        <app-select-check-all [model]="SamplingForm.get('Type')" [values]="testTypeList"
                            (allChecked)="allChecked($event)">
                        </app-select-check-all>
                        <mat-option *ngFor="let testtype of testTypeList" [value]="testtype">
                            {{loader.TranslateLang(testtype.Name)}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width" def="Tests">
                    <mat-label class="label-float">{{'add-sampling.tests' | translate}}</mat-label>
                    <mat-select formControlName="Tests" multiple>
                        <app-select-check-all [model]="SamplingForm.get('Tests')" [values]="selectedTestList">
                        </app-select-check-all>
                        <mat-option *ngFor="let test of selectedTestList" [value]="test"> {{test.testName}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <app-datepicker id="openFrom" labelText="{{'add-sampling.open-from' | translate}}"
                    [dateInput]="openFrom" isRequired="true" [minDate]="fromDateMin"
                    (selectedDate)="onSelectedDate($event)">
                </app-datepicker>
                <app-datepicker id="openTo" labelText="{{'add-sampling.open-to' | translate}}" [dateInput]="openTo"
                    isRequired="true" [minDate]="toDateMin">
                </app-datepicker>
                <mat-form-field>
                    <mat-label class="label-float">{{'add-sampling.code-finess' | translate}}</mat-label>
                    <input matInput formControlName="CodeFiness" (paste)="removeSpaces($event)" autocomplete="off"
                        type="text" (keypress)="keyPressNumbers($event)">
                </mat-form-field>
                <mat-form-field def="SlotHours">
                    <mat-label class="label-float" appearance="none">{{'add-sampling.slottime' | translate}}</mat-label>
                    <mat-select formControlName="SlotHours" required="true" [disableOptionCentering]="true">
                        <mat-option *ngFor="let slotHours of slotConfigHours"
                            [value]="slotHours.Key">{{slotHours.KeyName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row-lg-4 d-flex flex-column">
                <mat-checkbox [checked]="displayOnMap" id="mapDisplay" value="map" (change)="triggerMapDisplay($event)">
                    {{'add-sampling.display-map' | translate}}</mat-checkbox>
            </div>
        </ng-container>

        <ng-container>
            <div class="header">
                <h5>{{'add-sampling.contact-detail' | translate}}</h5>
            </div>
            <div id="createAccount_form" class="form-layout">
                <mat-form-field>
                    <mat-label class="label-float">{{'add-sampling.phone-number' | translate}}</mat-label>
                    <input matInput formControlName="PhoneNumber" maxlength="20" autocomplete="off" type="text"
                        (keypress)="keyPressNumbers($event)">
                </mat-form-field>
                <mat-form-field>
                    <mat-label class="label-float">{{'add-sampling.email' | translate}}</mat-label>
                    <input matInput formControlName="Email" maxlength="60" required autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label class="label-float">{{'add-sampling.address' | translate}}</mat-label>
                    <input matInput type="text" id="autocomplete-input" name="address"
                    required="true" formControlName="Address" maxlength="150" autocomplete="off" />
                </mat-form-field>
                <mat-form-field def="Timezone">
                    <mat-label class="label-float">{{'add-sampling.time-zone' | translate}}</mat-label>
                    <mat-select formControlName="Timezone" required="true">
                        <mat-option *ngFor="let timezone of timezoneList"
                            [value]="timezone.KeyName">{{timezone.KeyName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label class="label-float">{{'add-sampling.latitude' | translate}}</mat-label>
                    <input matInput id="Latitude" required="true" formControlName="Latitude" maxlength="150">
                </mat-form-field>
                <mat-form-field>
                    <mat-label class="label-float">{{'add-sampling.longitude' | translate}}</mat-label>
                    <input matInput id="Longitude" required="true" formControlName="Longitude" maxlength="150">
                </mat-form-field>
            </div>
        </ng-container>
        <ng-container>
            <div class="header">
                <h5>{{'add-sampling.email-requests' | translate}}</h5>
            </div>
            <div class="form-layout">
                <div id="createAccount_form">
                    <div class="row-lg-4 d-flex flex-column">
                        <mat-checkbox [checked]="isTriggerEmail" (change)="triggerEmailSelect($event)">
                            {{'add-sampling.trigger-email' | translate}} </mat-checkbox>
                    </div>
                </div>

                <div class="mr-25" *ngIf="isTriggerEmail" id="createAccount_form" class="form-layout">
                    <mat-form-field class="timeInputField">
                        <mat-label class="label-float">{{'add-sampling.trigger-email-slot1' | translate}}</mat-label>
                        <input matInput type="time" placeholder="{{'add-sampling.trigger-email-slot1' | translate}}"
                            [required]="isTriggerEmail" formControlName="slotOne">
                    </mat-form-field>
                    <mat-form-field class="timeInputField">
                        <mat-label class="label-float">{{'add-sampling.trigger-email-slot2' | translate}}</mat-label>
                        <input matInput type="time" placeholder="{{'add-sampling.trigger-email-slot2' | translate}}"
                            formControlName="slotTwo">
                    </mat-form-field>
                </div>
            </div>
            <div class="form-layout">
                <div id="createAccount_form">
                    <div class="row-lg-4 d-flex flex-column">
                        <mat-checkbox [checked]="isTriggerQrcode" (change)="triggerQrCode($event)">
                            {{'add-sampling.trigger-qr-code' | translate}}</mat-checkbox>
                    </div>
                </div>
                <div id="createAccount_form" *ngIf="isTriggerQrcode">
                    <div class="header">
                        <h5>{{'add-sampling.qms-configuration' | translate}}</h5>
                    </div>
                </div>
                <div class="mr-25" *ngIf="isTriggerQrcode" id="createAccount_form" class="form-layout">
                    <mat-form-field class="timeInputField">
                        <mat-label class="label-float">{{'add-sampling.qms-branch' | translate}}</mat-label>
                        <input matInput type="number" placeholder="{{'add-sampling.qms-branch' | translate}}"
                            [required]="isTriggerQrcode" formControlName="qmsNumber">
                    </mat-form-field>
                    <mat-form-field class="timeInputField">
                        <mat-label class="label-float">{{'add-sampling.qms-queue' | translate}}</mat-label>
                        <input matInput type="number" placeholder="{{'add-sampling.qms-queue' | translate}}"
                            [required]="isTriggerQrcode" formControlName="qmsQueueNumber">
                    </mat-form-field>
                </div>

            </div>
        </ng-container>
        <ng-container>
            <div class="header">
                <h5>{{'add-sampling.payment-type' | translate}}</h5>
            </div>
            <div class="form-layout">
                <div class="row-lg-4 d-flex flex-column">
                    <mat-radio-group class="myGroup" formControlName="PaymnetType">
                        <mat-radio-button *ngFor="let paymenttype of paymentTypeList" [value]="paymenttype.Id">
                            {{paymenttype.KeyName | translate}}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div></div>
            </div>
        </ng-container>

        <ng-container>
            <div class="header pb-15">
                <h5>{{'add-sampling.opening-hours' | translate}} <span style="color:red;">*</span></h5>
                <div style="margin-top: -15px;">
                    <mat-label class="label">{{'add-sampling.opening-hour-select-msg' | translate}}</mat-label>
                </div>
            </div>
            <!-- <mat-label class="label">{{'add-sampling.opening-hour-select-msg' | translate}}</mat-label>    -->

            <div class="in-d-flex">

                <div class="control-wrapper" *ngFor="let weekday of weekdays;let i=index">
                    <div class="inner-wrapper">
                        <mat-checkbox name="day{{i}}" [(ngModel)]="weekday.active" (change)="daySelect($event,i)"
                            [ngModelOptions]="{standalone: true}"> {{weekday.day |
                            translate}} </mat-checkbox>
                        <div class="time-field-container">
                            <mat-form-field class="mr-20 timeInputField">
                                <input matInput type="time"
                                    placeholder="{{'add-sampling.time-placeholder' | translate}}" name="open"
                                    [(ngModel)]="weekday.start_time" [ngModelOptions]="{standalone: true}"
                                    [disabled]="!weekday.active" (blur)="validateTime(i)">
                            </mat-form-field>

                            <mat-form-field class="mr-20 timeInputField"
                                [ngClass]="weekday.is_close_valid==false?' showError ':''">
                                <input matInput type="time"
                                    placeholder="{{'add-sampling.time-placeholder' | translate}}" name="open"
                                    [(ngModel)]="weekday.end_time" [disabled]="!weekday.active"
                                    [ngModelOptions]="{standalone: true}" (blur)="validateTime(i)">
                            </mat-form-field>
                        </div>
                        <span>{{'add-sampling.lunch-break' | translate}}</span>
                        <div class="time-field-container">
                            <mat-form-field class="mr-20 timeInputField"
                                [ngClass]="weekday.is_break_start_valid==false?' showError ':''">
                                <input matInput type="time"
                                    placeholder="{{'add-sampling.time-placeholder' | translate}}" name="breakbegins"
                                    name="break_begins{{i}}" [(ngModel)]="weekday.break_begins"
                                    [disabled]="weekday.is_disabled" [ngModelOptions]="{standalone: true}"
                                    (blur)="validateTime(i)">
                            </mat-form-field>
                            <mat-form-field class="timeInputField"
                                [ngClass]="weekday.is_break_end_valid==false?' showError ':''">
                                <input matInput type="time"
                                    placeholder="{{'add-sampling.time-placeholder' | translate}}" name="breakends"
                                    name="break_ends{{i}}" [(ngModel)]="weekday.break_ends"
                                    [disabled]="weekday.is_disabled" [ngModelOptions]="{standalone: true}"
                                    (blur)="validateTime(i)">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="actions">
            <button mat-button color="accent" mat-dialog-close="true" class="ghost button_width" type="submit"
                id="btn_close">
                {{'admin.close' | translate}}
            </button>
            <button mat-button color="accent" (click)="clear(formDirective)" class="ghost button_width" type="reset"
                id="btn_clear">
                {{'admin.clear' | translate}}
            </button>
            <button mat-button color="accent" class="ghost button_width" type="submit" (click)="onSave()" id="btn_save">
                {{'admin.save' | translate}}
            </button>
        </div>
    </form>
</div>