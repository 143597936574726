<div class="overlay-spin-window" [hidden]="!showSpinner">
  <div class="overlay-spin-window-wrapper">
    <div class="lds-spinner ">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
<app-side-nav *ngIf="!hasErrorStatusCode" (toggleGlobalError)="onToggleGlobalErrorPage($event)"></app-side-nav>
<app-global-error *ngIf="hasErrorStatusCode" [errorStatusCode]="hasErrorStatusCode" [isNavigateBackRequired]="false">
</app-global-error>

