<div class="preview-container" *ngIf="step==1"
    [ngClass]="{'preview-container-login':loader.loggedIn,'preview-container-guest':!loader.loggedIn}">
    <div class="section-header" [ngClass]="loader.loggedIn ? 'section-header-login' : 'section-header-guest'">
        <circle-progress class="only-web" [options]="loader.optionsG" [percent]="90" [renderOnClick]="false"
            imageSrc="assets/images/application-logos/svg/ic-suser.svg" class="copy">
        </circle-progress>
        <h3 class="desc-title">{{'login-prebook.for-desc'|translate}}</h3>​
    </div>
    <div class="selection-section"
        [ngClass]="{'selection-section-login':loader.loggedIn,'selection-section-guest':!loader.loggedIn}">
        <div class="options" [ngClass]="optionChosen.value == '0' ? 'option-yes' : 'option-no'" (click)="select(0)">
            <div class="option-description">
                <img [ngClass]="optionChosen.value == '0' ? 'self-container-selected' : 'self-container'" alt="self"
                    height="80">
                <div>
                    <div class="option-title">{{'login-prebook.for-self-title'|translate}}</div>
                    <!-- <div class="option-desc only-web">{{'login-prebook.for-self-desc'|translate}}</div> -->
                </div>
            </div>
            <div class="select-button" [ngClass]="optionChosen.value == '0' ? 'selected' : 'select'">
                {{(optionChosen.value == '0' ? 'check-pres.selected' : 'check-pres.select') | translate}}</div>
        </div>

        <div class="options" [ngClass]="optionChosen.value == '1' ? 'option-yes' : 'option-no'" (click)="select(1)">
            <div class="option-description">
                <img [ngClass]="optionChosen.value == '1' ? 'others-container-selected' : 'others-container'"
                    alt="rel-mem" height="80">
                <div>
                    <div class="option-title">{{'login-prebook.for-other-title'|translate}}</div>
                    <!-- <div class="option-desc only-web">{{'login-prebook.for-other-desc'|translate}}</div> -->
                </div>
            </div>
            <div class="select-button" [ngClass]="optionChosen.value == '1' ? 'selected' : 'select'">
                {{(optionChosen.value == '1' ? 'check-pres.selected' : 'check-pres.select') | translate}}</div>
        </div>
    </div>
    <div class="action-btn"
        [ngClass]="{'mobile-booking':bookAppointment,'action-btn-login':loader.loggedIn,'action-btn-guest':!loader.loggedIn}">
        <button mat-raised-button class="back-btn" type="button" (click)="goBack()">
            {{ 'back' | translate }}
        </button>
        <button mat-raised-button class="next-btn" type="button" (click)="appointmentFor()">
            {{ 'next' | translate }}
        </button>
    </div>
</div>

<div class="preview-container" *ngIf="step==2"
    [ngClass]="{'preview-container-login':loader.loggedIn,'preview-container-guest':!loader.loggedIn}">
    <div class="section-header" [ngClass]="loader.loggedIn ? 'section-header-login' : 'section-header-guest'">
        <circle-progress class="only-web" [options]="loader.optionsG" [percent]="90" [renderOnClick]="false"
            imageSrc="assets/images/application-logos/svg/ic-suser.svg" class="copy">
        </circle-progress>
        <h3 class="desc-title p-0"> {{'login-prebook.rm-desc'|translate}}</h3>
        <!-- <h5 class="step1-desc-title sub-desc">{{'login-prebook.rm-sub-desc'|translate}}</h5>​ -->
    </div>
    <div class="selection-section p-0 no-border"
        [ngClass]="{'normal-margin':isConfirmed,'selection-section-login':loader.loggedIn,'selection-section-guest':!loader.loggedIn}">
        <div class="login-container border-class w-100">
            <p class="desc-title p-0">{{'login-prebook.rm-sub-desc'|translate}}</p>
            <div>
                <app-find-member (submitRelativeMember)="addRelativeMember($event)" [updateList]="true">
                </app-find-member>
            </div>
        </div>

    </div>
    <div class="action-btn" *ngIf="!findMemberComponent?.noRecord"
        [ngClass]="{'mobile-booking':bookAppointment,'action-btn-login':loader.loggedIn,'action-btn-guest':!loader.loggedIn}">
        <button mat-raised-button class="back-btn" type="button" (click)="handleBackFromRM()">
            {{ 'back' | translate }}
        </button>
        <button mat-raised-button class="next-btn" type="button" (click)="findRM()">
            {{ 'next' | translate }}
        </button>
    </div>
</div>

<div class="preview-container" *ngIf="step==3" [ngClass]="{'no-border-top':isConfirmed}">
    <div class="section-header" *ngIf="!isConfirmed">
        <circle-progress class="only-web" [options]="loader.optionsG" [percent]="90" [renderOnClick]="false"
            imageSrc="assets/images/application-logos/svg/ic-scheckin.svg" class="copy">
        </circle-progress>
        <h3 class="desc-title">{{'con-booking.con-desc'|translate}}</h3>​
    </div>
    <div class="selection-section p-0 no-border"
        [ngClass]="{'normal-margin':isConfirmed,'selection-section-login':loader.loggedIn, 'selection-section-guest':!loader.loggedIn}">
        <div id="con-booking-container">
            <div id="patientInformation">
                <div class="confirmation booking-confirmed only-web" *ngIf="isConfirmed && !fromRegister">
                    <span class="icon-ic-tick_circle_filled success"></span>
                    <h2>
                        <p class="text">{{'con-booking.confirmation' | translate}}</p>
                        <p class="form-value  desc" *ngIf="!isLoginReceptionist">{{'con-booking.success-info' |
                            translate}} </p>
                        <p class="form-value  desc" *ngIf="isLoginReceptionist && loader.email">
                            {{'con-booking.success-info-receptionist' |
                            translate}} {{loader.email}}.</p>
                    </h2>
                </div>
                <div class="confirmation booking-confirmed only-mobile" *ngIf="isConfirmed && !fromRegister">
                    <div class="confirm-section">
                        <span class="icon-ic-tick_circle_filled success"></span>
                        <h2 class="header">
                            <span class="text">{{'con-booking.confirmation' | translate}}</span>
                        </h2>
                    </div>
                    <p class="form-value desc" *ngIf="!isLoginReceptionist">{{'con-booking.success-info' | translate}}
                    </p>
                    <p class="form-value  desc" *ngIf="isLoginReceptionist && loader.email">
                        {{'con-booking.success-info-receptionist' |
                        translate}}
                        {{loader.email}}.</p>
                </div>
                <div class="confirmation" *ngIf="fromRegister">
                    <h2>
                        <span class="success icon-tick-thin font-xxl"></span>
                        <span class="text ml-30">{{'con-booking.confirmation' | translate}}</span>
                    </h2>
                    <p class="form-value">{{'con-booking.booking-for' | translate}} {{selectedtest.short_name}}
                        {{'con-booking.booking-success' | translate}}</p>

                    <p *ngIf="isLoginReceptionist  ">
                        {{'login.mail-confirmation-instructions-receptionist' | translate}} {{email}}
                        <span id="email">{{username}}</span>
                    </p>
                    <p *ngIf="!isLoginReceptionist">
                        {{'login.mail-confirmation-instructions-receptionist' | translate}} {{email}}
                        <span id="email">{{username}}</span>
                    </p>
                    <p *ngIf=" isLoginReceptionist">
                        {{'login.mail-confirmation-instructions-receptionist' | translate}} {{email}}
                        <span id="email">{{username}}</span>
                    </p>

                    <p *ngIf="isLoginReceptionist">
                        {{'login.activate-instruction-receptionist' |
                        translate}}</p>
                    <p *ngIf=" !isLoginReceptionist">
                        {{'login.activate-instruction-receptionist'
                        |
                        translate}}</p>
                    <p *ngIf=" isLoginReceptionist">
                        {{'login.activate-instruction-receptionist' |
                        translate}}</p>
                </div>

                <div class="test-section" *ngIf="selectedtest">
                    <div class="test-header" *ngIf="!isConfirmed">{{selectedtest.long_name}}</div>
                    <div class="test-header" *ngIf="isConfirmed">{{'con-booking.req-number' | translate}}
                        {{appointmentCode}}</div>
                    <div class="test-value" *ngIf="!isConfirmed">{{selectedtest.description}}</div>
                    <div class="test-value" *ngIf="isConfirmed">
                        <div class="test-name">{{selectedtest.long_name}}</div>
                        <div>{{selectedtest.description}}</div>
                    </div>
                </div>
                <!-- </div> -->
                <div class="bookingInfoDiv mt-20">
                    <h5 class="section-title">{{'con-booking.patient-information' | translate}}</h5>
                    <div class="threeColumnGrid">
                        <div class="infoTabs pl-30">
                            <span class="icon-ic-name font-l"></span>
                            <p class="title">{{'con-booking.name' | translate}}</p>
                            <ng-container *ngIf="loggedInId">
                                <div class="value" *ngIf="loggedInId == selectedPatient?.value"> {{user_name}}
                                </div>
                                <div class="value"
                                    *ngIf="selectedPatient?.value && loggedInId != selectedPatient?.value">
                                    {{loader.relativeMember?.user_name}}
                                </div>
                                <div class="value" *ngIf="loggedInId && !selectedPatient?.value">
                                    {{user_name}}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!loggedInId">
                                <div class="value"> {{user_name}}
                                </div>
                            </ng-container>

                        </div>
                        <div class="infoTabs pl-30">
                            <span class="icon-ic-date font-l"></span>
                            <p class="title only-web">{{'con-booking.dob' | translate}}</p>
                            <p class="title only-mobile">{{'con-booking.dob-mobile' | translate}}</p>
                            <ng-container *ngIf="loggedInId">
                                <div class="value" *ngIf="loggedInId == selectedPatient?.value"> {{dob | date:
                                    'dd/MM/yyyy'}}
                                </div>
                                <div class="value"
                                    *ngIf="selectedPatient?.value && loggedInId != selectedPatient?.value">
                                    {{loader.relativeMember?.dob| date:
                                    'dd/MM/yyyy'}} </div>
                                <div class="value" *ngIf="loggedInId && !selectedPatient?.value">
                                    {{dob| date:
                                    'dd/MM/yyyy'}} </div>
                            </ng-container>
                            <ng-container *ngIf="!loggedInId">
                                <div class="value"> {{dob | date:
                                    'dd/MM/yyyy'}}
                                </div>
                            </ng-container>

                        </div>
                        <div class="infoTabs pl-30">
                            <span class="icon-ic-date font-l"></span>
                            <p class="title">{{'con-booking.age' | translate}}</p>
                            <ng-container *ngIf="loggedInId">
                                <div class="value" *ngIf="loggedInId == selectedPatient?.value">
                                    {{loader.calculateAge(dob)}}
                                </div>
                                <div class="value"
                                    *ngIf="selectedPatient?.value && loggedInId != selectedPatient?.value">
                                    {{loader.calculateAge(loader.relativeMember?.dob)}}</div>

                                <div class="value" *ngIf="loggedInId && !selectedPatient?.value">
                                    {{loader.calculateAge(dob)}}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!loggedInId">
                                <div class="value"> {{loader.calculateAge(dob)}}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="threeColumnGrid comment-section" *ngIf="isConfirmed && bookingComment">
                        <div class="infoTabs pl-30">
                            <span class="icon-ic-remarks font-l"></span>
                            <p class="title">{{'con-booking.comment' | translate}}</p>
                            <div class="value">{{ bookingComment }}</div>
                        </div>
                    </div>
                </div>
                <div class="bookingInfoDiv mt-20" *ngIf="loader.flow_detail != constants.inside_lab_flow">
                    <h5 class="section-title" *ngIf="start_time">{{'con-booking.booking-information' | translate}}</h5>
                    <h5 class="section-title" *ngIf="!start_time">{{'con-booking.lab-information' | translate}}</h5>
                    <div class="threeColumnGrid">
                        <div class="infoTabs pl-30" *ngIf="start_time">
                            <span class="icon-ic-date font-l"></span>
                            <p class="title" *ngIf="calendar=='0'">{{'con-booking.booked-date' | translate}}</p>
                            <p class="title" *ngIf="calendar=='1'">{{'con-booking.booked-slot' | translate}}</p>

                            <div *ngIf="start_time" class="value">{{start_time | dateFormat}} </div>
                            <div *ngIf="!start_time" class="value">N/A</div>
                        </div>
                        <div class="infoTabs pl-30">
                            <span class="icon-ic-location font-l"></span>
                            <p class="title">{{'con-booking.lab-address' | translate}}</p>
                            <div class="value">{{selectedLab?.sampling_station_name+","+selectedLab?.address}}
                            </div>
                        </div>
                        <div class="infoTabs pl-30">
                            <span class="icon-ic-mobile font-l"></span>
                            <p class="title">{{'con-booking.contact' | translate}}</p>
                            <div class="value">{{selectedLab?.formatted_phone_number ?? selectedLab?.phone_number}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-20" *ngIf="!isConfirmed">
                    <div>
                        <mat-form-field>
                            <mat-label>{{'con-booking.comment'|translate}}</mat-label>
                            <textarea class="comment-area" rows="4" matInput [(ngModel)]="selectedDetail"
                                maxlength="250" autocomplete="off" name="commentarea"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="action-btn"
        [ngClass]="{'mobile-booking':bookAppointment,'action-btn-login':loader.loggedIn,'action-btn-guest':!loader.loggedIn , 'justify-content-end': !showBackBtn}"
        *ngIf="!isConfirmed">
        <button mat-raised-button class="back-btn" type="button" *ngIf="showBackBtn" (click)="back()">
            {{ 'back' | translate }}
        </button>
        <button mat-raised-button class="next-btn" type="button" (click)="checkUserLogin()">
            {{'con-booking.confirm-booking' |
            translate}}
        </button>
    </div>

    <div class="action-btn align-right" *ngIf="isConfirmed"
        [ngClass]="{'action-btn-login':loader.loggedIn,'action-btn-guest':!loader.loggedIn}">
        <button mat-raised-button class="next-btn full-width" type="button"
            *ngIf="isConfirmed  && fromAppointmentLogin && loader.userRole == patientRole && loader.docReq"
            (click)="redirectDocument()"> {{'con-booking.upload-document' | translate}} </button>

        <button mat-raised-button class="next-btn full-width" type="button"
            *ngIf="isConfirmed  && !fromAppointmentLogin && loader.userRole == patientRole" (click)="redirectBooking()">
            {{'con-booking.book-another' | translate}} </button>

    </div>
</div>

<div class="preview-container" *ngIf="step==4"
    [ngClass]="{'normal-border':isConfirmed,'preview-container-login':loader.loggedIn,'preview-container-guest':!loader.loggedIn}">
    <div class="section-header" *ngIf="!isConfirmed"
        [ngClass]="loader.loggedIn ? 'section-header-login' : 'section-header-guest'">
        <circle-progress class="only-web" [options]="loader.optionsG" [percent]="90" [renderOnClick]="false"
            imageSrc="assets/images/application-logos/svg/ic-suser.svg" class="copy">
        </circle-progress>
        <h3 class="desc-title">{{'con-booking.appointment-for' | translate}}</h3>​
    </div>
    <div class="selection-section mobile-sel-sec"
        [ngClass]="{'normal-margin':isConfirmed,'selection-section-login':loader.loggedIn,'selection-section-guest':!loader.loggedIn}">
        <div id="con-booking-container">
            <div id="patientInformation">
                <div *ngIf="isLoginReceptionist==false && loggedInId && !isConfirmed && loader.forSelf==false"
                    class="rm-list-container">
                    <div class="add-more" (click)="step=2">
                        <span class="icon-ic-add customAddIcon ng-star-inserted add-icon-style"> </span>
                        <span>{{'con-booking.add-more-rm' |translate}}</span>
                    </div>
                    <mat-radio-group [formControl]="selectedPatient" (change)="patientSelectionChange()" class="rm-list"
                        required>
                        <mat-radio-button *ngFor="let rm of RM_DATA" value="{{rm.rm_id}}" name="{{rm.rm_id}}"
                            id="{{rm.rm_id}}"
                            [matTooltip]="rm.rm_id == loggedInId? loader.user_name : rm.rm_fname +' '+ rm.rm_lname"
                            class="rm-member">
                            <span *ngIf="rm.rm_id == loggedInId">{{'con-booking.self' | translate}}
                                ({{loader.user_name}})</span>
                            <span *ngIf="rm.rm_id != loggedInId">{{rm.rm_fname}} {{rm.rm_lname}}</span>
                        </mat-radio-button>
                    </mat-radio-group>

                </div>
            </div>
        </div>

    </div>

    <div class="action-btn"
        [ngClass]="{'mobile-booking':bookAppointment,'action-btn-login':loader.loggedIn,'action-btn-guest':!loader.loggedIn}"
        *ngIf="!isConfirmed">
        <button mat-raised-button class="back-btn" type="button" (click)="step=1">
            {{ 'back' | translate }}
        </button>
        <button mat-raised-button class="next-btn" type="button" (click)="checkRMSelection()">
            {{ 'next' | translate }}
        </button>
    </div>
</div>