import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../../../main';
import { ActiveDate, MasterData, SSTest } from '../ss-slot-configuration.model';
import { DatePipe } from '@angular/common';
import { CommonApiService } from '../../../services/common-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SpinnerService } from '../../../../core';
import { LoginServiceService } from '../../../services/login-service.service';
import { validateTime } from '../../service/time-field-validator';
import { BookingAppointmentGuestService } from '../../../services/booking-appointment-guest.service';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MomentDateAdapter } from '@angular/material-moment-adapter';
@Component({
  selector: 'app-add-slot-configuration',
  templateUrl: './add-slot-configuration.component.html',
  styleUrls: ['./add-slot-configuration.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: "DD-MM-YYYY",
        },
        display: {
          dateInput: "DD-MM-YYYY",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        },
      },
    },
  ],
})
export class AddSlotConfigurationComponent implements OnInit {
  @Input() popupData: any;
  public title: string = "slot-configuration.title";
  public titleMaxLength: number = 100;
  public reasonMaxLength: number = 500;
  public StartDate = new FormControl();
  public EndTime = new FormControl("");
  public StartTime = new FormControl("");
  public FormType = new FormControl("0");
  public Comment = new FormControl();
  public today = new Date();
  public fromMinDate: Date = new Date();
  public toMaxDate: Date = new Date(this.today.setDate(this.today.getDate() + 60));

  public AllDay = false;
  public occurence = new FormControl();
  public samplingStation: any;
  public tests: Array<SSTest>;
  public step: number = 1;
  protected isFuture: boolean = false;
  protected isPastDate: boolean = false;
  protected isNonWorkingDay: boolean = false;
  public saveAlert = {
    edit: "admin.save-alert.edit",
    add: "admin.save-alert.add"
  };
  displayedColumns: string[] = [
    "name",
    "avg_time",
    "limits",
  ];

  refresh = true;
  myFilter = (d: Date): boolean => {
    let dpd = new Date(d);
    const year = dpd.getFullYear();
    const month = String(dpd.getMonth() + 1).padStart(2, "0");
    const day = String(dpd.getDate()).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;
    return this.activeDates.some((x) => x === dateString);
  };
  public SlotGroupForm: FormGroup =
    this.fb.group({
      TestIds: ['', [Validators.required]],
      StartDate: [this.StartDate.value, [Validators.required]],
      StartTime: [this.StartTime.value, [Validators.required]],
      EndTime: [this.EndTime.value, [Validators.required]],
      SlotTitle: ['', [Validators.required]],
      Occurence: [this.occurence, [Validators.required]],
      AllDay: [this.AllDay],
      RepeatEvery: 1,
      Dows: [null]
    });
  public occurenceDays: Array<any> = [{ id: 1, value: 1 }, { id: 2, value: 2 }, { id: 3, value: 3 }, { id: 4, value: 4 }];
  public occurenceWeeks: Array<any> = [{ id: 1, value: 1, name: "1 Week" }, { id: 2, value: 2, name: "2 Weeks" }, { id: 3, value: 3, name: "3 Weeks" }, { id: 4, value: 4, name: "4 Weeks" }];
  public selectedDateTime: Date;
  public selectedDow: ActiveDate;
  public dataSource: any = [];
  public days: string[] = ['admin.monday', 'admin.tuesday', 'admin.wednesday', 'admin.thursday', 'admin.friday', 'admin.saturday', 'admin.sunday'];
  public occurences: Array<MasterData>;
  public selectedDays: boolean[] = [false, false, false, false, false, false, false];
  public selectedOccurence: MasterData;
  public selectedDayMsg: string;
  public buildDowRequired: boolean = false;
  public isBack: boolean = false;
  public is_sequence = new FormControl("0");
  protected activeDates: Array<any>;
  public startDatePassed: boolean;

  constructor(private readonly dialogRef: MatDialogRef<AddSlotConfigurationComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService,
    private commonAPi: CommonApiService, private messageService: MessageService, private fb: FormBuilder,
    private spinner: SpinnerService, private loginService: LoginServiceService,
    public bookingAppointmentService: BookingAppointmentGuestService) {
    this.occurences = new Array<MasterData>();
    this.selectedOccurence = new MasterData();
    this.selectedDow = new ActiveDate();
    this.activeDates = new Array<any>();
  }

  ngOnInit(): void {
    this.samplingStation = this.data.samplingStation;
    let ads: any = [];
    Object.assign(ads, this.data.ads);

    this.activeDates = ads.map((x: any) => moment.utc(new Date(x.start_date)).local().format('YYYY-MM-DDTHH:mm:SS.sssZ').split("T")[0]);
    this.getOccurence();
    if (!this.data?.edit) {

      this.title = 'slot-configuration.title';
      let sd = new Date(this.data?.selectedDateTime?.event?.start);
      sd.setHours(0, 0, 0);
      if (sd) {
        this.setDowTime(sd, this.data?.selectedDateTime?.event?.start);
      }
      this.StartDate.setValue(new Date(this.selectedDateTime.getFullYear(), this.selectedDateTime.getMonth(), this.selectedDateTime.getDate()));
      this.SlotGroupForm.controls["StartDate"].setValue(new Date(this.selectedDateTime.getFullYear(), this.selectedDateTime.getMonth(), this.selectedDateTime.getDate()));
    }
    else {
      this.title = 'slot-configuration.modify-title';
    }
  }

  protected onDateValueChange(e: any) {
    let dpd = new Date(e.value);
    const year = dpd.getFullYear();
    const month = String(dpd.getMonth() + 1).padStart(2, "0");
    const day = String(dpd.getDate()).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;
    let sd = new Date(dpd);
    sd.setHours(0, 0, 0);
    let ad = this.data.ads.filter((ad: ActiveDate) => ad.start_date.getTime() == sd.getTime());
    if (ad.length > 0) {
      this.selectedDow = ad[0];
      if (this.AllDay) {
        this.SlotGroupForm.get("StartTime").setValue(this.selectedDow.start_time);
        this.SlotGroupForm.get("EndTime").setValue(this.selectedDow.end_time);
      }
      this.validateStartTimeRange();
    }
    if (this.isDateLessThanToday(dateString)) {
      this.isPastDate = true;
      this.isFuture = false;
      this.isNonWorkingDay = false;
    } else if (this.activeDates.includes(dateString)) {
      this.isPastDate = false;
      this.isFuture = false;
      this.isNonWorkingDay = false;
    } else {
      let isExist = this.isWithin60Days(dateString);
      if (!isExist) {
        this.isPastDate = false;
        this.isFuture = true;
        this.isNonWorkingDay = false;
      } else {
        this.isPastDate = false;
        this.isFuture = false;
        this.isNonWorkingDay = true;
      }
    }
  }

  private isDateLessThanToday(dateString: string): boolean {
    const today = new Date();
    const inputDate = new Date(dateString);
    return inputDate < today;
  }
  private isWithin60Days(dateString: string): boolean {
    const today = new Date();
    const inputDate = new Date(dateString);
    const thirtyDaysFromNow = new Date(today.setDate(today.getDate() + 60));
    return inputDate <= thirtyDaysFromNow;
  }



  public patchValue() {
    let date = this.data.startDate?.event?.start;
    let sd = new Date(this.data.startDate?.event?.start);
    sd.setHours(0, 0, 0);
    let ad = this.data.ads.filter((ad: ActiveDate) => ad.start_date.getTime() == sd.getTime());
    if (ad.length > 0) {
      this.selectedDow = ad[0];
    }
    let currDateTime = new Date();
    let currdate = new Date(currDateTime.getFullYear(), currDateTime.getMonth(), currDateTime.getDate())
    let sg_start_date = moment.utc(new Date(this.data.ConfiguredData.start_date)).local().format('YYYY-MM-DDTHH:mm:SS.sssZ').split("T")[0]
    if (new Date(sg_start_date).getTime() < currdate.getTime()) {
      this.StartDate.setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    }
    else {
      let sg_date = new Date(this.data.ConfiguredData.start_date);
      this.StartDate.setValue(new Date(sg_date.getFullYear(), sg_date.getMonth(), sg_date.getDate()));
    }

    const secondArray = this.data.ConfiguredData?.tests?.map((item: any) => {
      return {
        id: item.test_id,
        slot_group_id: item.slot_group_id,
        test_id: item.test_id,
        duration: item.duration,
        limit_bookings: item.limit_bookings,
        test_name: item.test_name
      };
    });
    this.SlotGroupForm.patchValue({
      StartDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      StartTime: this.data.startTime,
      EndTime: this.data.endTime,
      SlotTitle: this.data.ConfiguredData.slot_name,
      Occurence: this.data.ConfiguredData.occurance,
      AllDay: this.data.ConfiguredData.is_allday,
      RepeatEvery: this.data.ConfiguredData.repeat_every,
      Dows: this.data.ConfiguredData.dows,
      TestIds: secondArray
    });

    if (this.FormType.value == '1') {
      this.getAllTest();
    }
    //  else {
    //   this.getAvailableTests();
    // }
    this.AllDay = this.data.ConfiguredData.is_allday;

    this.data?.ConfiguredData?.dows?.forEach((dow: any) => {
      if (dow.dow == 0) {
        this.selectedDays[6] = true;
      }
      else {
        this.selectedDays[dow.dow - 1] = true;
      }
    });

    this.validateStartTimeRange(false);
    this.onOccurenceChange(this.data.ConfiguredData.occurance, false);
    this.buildDailyMsg();
  }

  public refreshTest() {
    if (this.FormType.value == '1') {
      this.getAllTest();
    } else {
      this.getAvailableTests();
    }
  }

  public getOccurence() {
    this.loginService.getOccurenceMasters().subscribe((data: Array<any>) => {
      this.occurences = data.sort((a, b) => a.KeyOrder - b.KeyOrder);
      this.SlotGroupForm.controls["Occurence"].setValue(this.occurences[0].Id);
      this.selectedOccurence = this.occurences[0];
      if (this.data.edit) {
        this.patchValue();
      } else {
        this.getAvailableTests();

      }

    });
  }

  private setDowTime(start_date: Date, start_date_time: Date) {
    let ad = this.data.ads.filter((ad: ActiveDate) => ad.start_date.getTime() == start_date.getTime());
    if (ad.length > 0) {
      this.selectedDow = ad[0];
    }

    this.selectedDateTime = start_date_time;
    let segStartDate = new Date(start_date_time);
    const segEndDate = new Date(segStartDate.getTime() + 30 * 60 * 1000);

    let startTime = this.getTimeString(segStartDate);
    let endTime = this.getTimeString(segEndDate);

    this.SlotGroupForm.controls["StartTime"].setValue(startTime);
    this.SlotGroupForm.controls["EndTime"].setValue(endTime);
  }



  validateStartTimeRange(refreshTest: boolean = true) {
    this.spinner.show(true);
    validateTime(this.SlotGroupForm, this.selectedDow, this.messageService, this.translateService);
    if (refreshTest) { this.getAvailableTests(); }
  }

  // onDateChange() {
  //   this.setDowTime(new Date(this.StartDate.value));
  // }

  public goBack() {
    this.step = 1;
    this.isBack = true;
  }

  clearSlotGroupForm() {
    this.SlotGroupForm.controls["SlotTitle"].setValue("");
    this.SlotGroupForm.controls["TestIds"].setValue([]);
    this.SlotGroupForm.controls["Occurence"].setValue(this.occurences[0].Id);
    this.selectedOccurence = this.occurences[0];
    this.SlotGroupForm.controls["RepeatEvery"].setValue(0);
    this.SlotGroupForm.controls["Dows"].setValue(null);
    this.selectedDayMsg = "";
    this.selectedDays = [false, false, false, false, false, false, false];
    this.dataSource = [];
  }

  toggleDay(index: number): void {
    this.selectedDays[index] = !this.selectedDays[index];
    this.calculateNextSevenDays();
    this.getAvailableTests();
  }

  onOccurenceChange(evtValue: any, reset: boolean = true) {
    const datePipe = new DatePipe('en-US');
    this.buildDowRequired = false;
    let index = this.occurences.findIndex(i => i.Id == evtValue);
    this.selectedOccurence = this.occurences[index];

    if (reset) {
      this.selectedDays = [false, false, false, false, false, false, false];
      this.SlotGroupForm.controls["Dows"].setValue([]);
    }
    if (this.selectedOccurence?.Key.toLowerCase() == "daily") {
      if (reset) {
        this.SlotGroupForm.controls["RepeatEvery"].setValue(1);
      }
      this.selectedDayMsg = this.translateService.instant("add-slot-group.initial1") + this.translateService.instant("add-slot-group.initial4") + datePipe.transform(this.StartDate.value, "dd/MM/yyyy");
    } else if (this.selectedOccurence?.Key.toLowerCase() == "weekly") {
      this.buildDowRequired = true;
      if (reset) {
        this.SlotGroupForm.controls["RepeatEvery"].setValue(1);
      }
    }
    else {
      this.SlotGroupForm.controls["RepeatEvery"].setValue(0);
    }

    this.getAvailableTests();
  }

  buildDailyMsg() {
    const datePipe = new DatePipe('en-US');
    if (this.SlotGroupForm.controls["RepeatEvery"].value == 1) {
      this.selectedDayMsg = this.translateService.instant("add-slot-group.initial1") + this.translateService.instant("add-slot-group.initial4") + datePipe.transform(this.StartDate.value, "dd/MM/yyyy");
    }
    else {
      this.selectedDayMsg = this.translateService.instant("add-slot-group.initial1") + this.SlotGroupForm.controls["RepeatEvery"].value + this.translateService.instant("add-slot-group.initial4") + datePipe.transform(this.StartDate.value, "dd/MM/yyyy");
    }
    //this.getAvailableTests();
  }

  areAnyDaysSelected(): boolean {
    const datePipe = new DatePipe('en-US');
    if (this.selectedDays?.some(day => day)) {
      let selectedDays = this.days
        .filter((_, index) => this.selectedDays[index]);

      const formattedDays = selectedDays.map(day => this.translateService.instant(this.days.find(d => d.startsWith(day))));
      let startDate = datePipe.transform(this.StartDate.value, "dd/MM/yyyy");
      if (selectedDays.length === 1) {
        this.selectedDayMsg = `${this.translateService.instant("add-slot-group.initial1")} ${formattedDays[0]} ${this.translateService.instant("add-slot-group.initial3")} ${startDate}`;
      } else if (selectedDays.length === 2) {
        this.selectedDayMsg = `${this.translateService.instant("add-slot-group.initial1")} ${formattedDays.join(this.translateService.instant("add-slot-group.initial2"))} ${this.translateService.instant("add-slot-group.initial3")} ${startDate}`;
      } else {
        const remainingDays = formattedDays.slice(0, selectedDays.length - 1).join(', ');
        const lastDay = formattedDays[selectedDays.length - 1];
        this.selectedDayMsg = `${this.translateService.instant("add-slot-group.initial1")} ${remainingDays} ${this.translateService.instant("add-slot-group.initial2")} ${lastDay} ${this.translateService.instant("add-slot-group.initial3")} ${startDate}`;
      }
    }
    return this.selectedDays.some(day => day);
  }


  getAllTest() {
    this.bookingAppointmentService.getAdmintests(this.samplingStation.id).subscribe(
      (response: any) => {
        this.tests = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }


  getAvailableTests() {
    this.spinner.show(true);
    const datePipe = new DatePipe('en-US');

    let payload =
    {
      "id": this.data?.edit == true ? this.data?.ConfiguredData?.id : 0,
      "ss_id": this.samplingStation.id,
      "start_date": datePipe.transform(this.StartDate.value, 'yyyy-MM-dd'),
      "start_time": this.SlotGroupForm.get("StartTime").value.split(":").length == 3 ? this.SlotGroupForm.get("StartTime").value : this.SlotGroupForm.get("StartTime").value + ":00",
      "end_time": this.SlotGroupForm.get("EndTime").value.split(":").length == 3 ? this.SlotGroupForm.get("EndTime").value : this.SlotGroupForm.get("EndTime").value + ":00",
      "occurance": this.SlotGroupForm.get("Occurence").value,
      "dows": this.selectedOccurence?.Key == 'Weekly' ? this.SlotGroupForm.controls["Dows"].value : [],
      "repeat_every": this.selectedOccurence?.Key != 'DoesNotRepeat' ? this.SlotGroupForm.get("RepeatEvery").value : 0,
    }
    let selectedTests = this.SlotGroupForm.get('TestIds').value;
    this.commonAPi.getAvailableTest(payload).subscribe((tests: SSTest[]) => {
      this.tests = tests;
      if (this.data.edit) {
        const commonTests: SSTest[] = this.tests
          .map(item => item)
          .filter(id => selectedTests?.some((obj: any) => obj.id === id.id));
        this.SlotGroupForm.get('TestIds').setValue(commonTests);
        commonTests.forEach((element: SSTest) => {
          let totalTime = this.calculateTotalTime();
          element.name = element.short_name;

          let pTest = this.data?.ConfiguredData?.tests.find((i: any) => i.test_id == element.id);
          if (pTest) {
            element.max_limits = Math.floor(totalTime / parseInt(pTest.duration));
            element.limits = Math.min(pTest.limit_bookings, element.max_limits);
            element.avg_time = pTest.duration.toString();
          }
          else {
            element.limits = Math.floor(totalTime / 10);
            element.avg_time = "10";
            element.max_limits = Math.floor(totalTime / 10);
          }
        });
        this.dataSource = commonTests;

      } else {
        let selectedList = this.SlotGroupForm.get('TestIds').value;
        const commonTests: SSTest[] = this.tests
          .map(item => item)
          .filter(id => selectedList?.some((obj: any) => obj.id === id.id));
        commonTests.forEach((element: SSTest) => {
          element.name = element.short_name;
        });
        this.SlotGroupForm.get('TestIds').setValue(commonTests);
      }
      this.spinner.show(false);
      //if(this.data.edit){
      // const commonTests: SSTest[] = this.tests
      // .map(item => item)
      // .filter(id => this.data?.ConfiguredData?.tests?.some((obj: any) => obj.test_id === id.id));
      //}
    },
      (errorResponse: HttpErrorResponse) => {
        errorResponse;
        this.spinner.show(false);
        this.messageService.alert(
          this.translateService.instant("add-slot-group.error.422")
        );
        // if (errorResponse.error.internal_code == 3005) {
        //   this.messageService.alert(
        //     this.translateService.instant("add-slot-group.error.3005")
        //   );
        // } else if (errorResponse.error.internal_code == 3009) {
        //   this.messageService.alert(
        //     this.translateService.instant("add-slot-group.error.3009")
        //   );
        // }
        // else if (errorResponse.error.internal_code == 3006) {
        //   this.messageService.alert(
        //     this.translateService.instant("add-slot-group.error.3006")
        //   );
        // }
        // else
        //   this.messageService.alert(
        //     this.translateService.instant("add-slot-group.error.422")
        //   );
      });
  }

  public onNextClick() {
    this.refresh = false;

    if (this.SlotGroupForm.valid) {
      if (this.selectedOccurence.Key.toLowerCase() == "weekly") {
        if (this.SlotGroupForm.controls.Dows.value?.length > 0) {
          this.validateTest();
          if (this.data.edit == true) {
            this.calculateNextSevenDays();
          }
        }
        else {
          this.messageService.alert(
            this.translateService.instant("add-slot-group.select-any-day")
          );
        }
      }
      else if (this.selectedOccurence.Key.toLowerCase() == "daily") {
        this.validateTest();

      }
      else {
        this.validateTest();
      }
    }
  }

  validateTest() {
    this.spinner.show(true);
    const datePipe = new DatePipe('en-US');
    let tests = this.SlotGroupForm.get("TestIds").value.map((test: SSTest) => {
      return { test_id: test.id };
    })
    let payload = {
      "id": this.data?.edit == true ? this.data?.ConfiguredData?.id : 0,
      "ss_id": this.samplingStation.id,
      "start_date": datePipe.transform(this.StartDate.value, 'yyyy-MM-dd'),
      "start_time": this.SlotGroupForm.get("StartTime").value.split(":").length == 3 ? this.SlotGroupForm.get("StartTime").value : this.SlotGroupForm.get("StartTime").value + ":00",
      "end_time": this.SlotGroupForm.get("EndTime").value.split(":").length == 3 ? this.SlotGroupForm.get("EndTime").value : this.SlotGroupForm.get("EndTime").value + ":00",
      "occurance": this.SlotGroupForm.get("Occurence").value,
      "dows": this.selectedOccurence?.Key == 'Weekly' ? this.SlotGroupForm.controls["Dows"].value : [],
      "repeat_every": this.selectedOccurence?.Key != 'DoesNotRepeat' ? this.SlotGroupForm.get("RepeatEvery").value : 0,
      "test_ids": tests,
    }
    this.commonAPi.validateTestInGroup(payload).subscribe((valid: boolean) => {
      if (valid) {
        this.goToStep2();
      }
    },
      (errorResponse: HttpErrorResponse) => {
        this.spinner.show(false);
        if (errorResponse.error.internal_code == 3005) {
          this.messageService.alert(
            this.translateService.instant("add-slot-group.error.3005")
          );
        } else if (errorResponse.error.internal_code == 3009) {
          this.messageService.alert(
            this.translateService.instant("add-slot-group.error.3009")
          );
        }
        else if (errorResponse.error.internal_code == 3006) {
          this.messageService.alert(
            this.translateService.instant("add-slot-group.error.3006")
          );
        }
        else
          this.messageService.alert(
            this.translateService.instant("add-slot-group.error.422")
          );
      });
  }

  goToStep2() {
    let totalTime = this.calculateTotalTime();

    let selectedTests = this.SlotGroupForm.controls["TestIds"].value;
    selectedTests.forEach((test: SSTest) => {
      test.name = test.short_name;
      test.total_time = totalTime;
      let previosTest = this.dataSource.find((ds: SSTest) => ds.id == test.id);
      if (!this.isBack && previosTest) {

        test.limits = Math.min(previosTest.limits, Math.floor(totalTime / previosTest.avg_time));
        test.avg_time = previosTest.avg_time;
        test.max_limits = Math.floor(totalTime / parseInt(test.avg_time));
      }
      else if (!this.isBack && !previosTest) {
        test.limits = Math.floor(totalTime / 10);
        test.avg_time = "10";
        test.max_limits = Math.floor(totalTime / 10);
      }
      else if (this.isBack && !previosTest) {
        test.limits = Math.floor(totalTime / 10);
        test.avg_time = "10";
        test.max_limits = Math.floor(totalTime / 10);
      }
      else if (this.isBack && previosTest) {
        test.max_limits = Math.floor(totalTime / parseInt(previosTest.avg_time));
        test.limits = Math.min(previosTest.limits, previosTest.max_limits);
        test.avg_time = previosTest.avg_time;

      }
      else {

        test.limits = Math.min(previosTest.limits, Math.floor(totalTime / 10));
        test.avg_time = previosTest.avg_time;
        test.max_limits = Math.floor(totalTime / parseInt(test.avg_time));
      }
    });

    this.dataSource = selectedTests;

    this.step = 2;
    this.spinner.show(false);
    this.refresh = true;
  }

  public validateInput(element: any, max_limit: any): void {
    if (!element.limits) {
      element.limits = 0;
    }
    if (parseInt(element.limits) > element.max_limits || parseInt(element.limits) == 0 || !element.limits) {
      max_limit.control.setErrors({ 'maxLimitExceeded': true });
    } else {
      max_limit.control.setErrors(null);
    }
  }

  updateMaxLimit(value: any, element: any) {
    element.max_limits = Math.floor(element.total_time / value);
    if (element.limits) {
      if (element.max_limits < element.limits) {
        element.limits = element.max_limits;
      }
      else {
        let old_value = parseInt(element.limits);
        element.limits = 0;
        element.limits = old_value;
      }
    }
  }

  public calculateTotalTime(): number {
    let s_time = this.SlotGroupForm.get("StartTime").value.split(":").length == 3 ? this.SlotGroupForm.get("StartTime").value : this.SlotGroupForm.get("StartTime").value + ":00";
    let e_time = this.SlotGroupForm.get("EndTime").value.split(":").length == 3 ? this.SlotGroupForm.get("EndTime").value : this.SlotGroupForm.get("EndTime").value + ":00";
    const startMinutes = this.convertTimeToMinutes(s_time);
    const endMinutes = this.convertTimeToMinutes(e_time);
    const totalDuration = endMinutes - startMinutes;
    return totalDuration;
  }

  public convertTimeToMinutes(time: string): number {
    const timeParts = time.split(':').map(Number);
    return timeParts[0] * 60 + timeParts[1] + timeParts[2] / 60;
  }

  public calculateNextSevenDays() {
    const datePipe = new DatePipe('en-US');
    //let dows = [];
    let trueIndices: number[] = [];
    this.selectedDays.forEach((value, index) => {
      if (value === true) {
        trueIndices.push(index);
      }
    });

    const sDate = new Date(this.StartDate.value);
    let monday = this.getMondayOfCurrentWeek(sDate);
    let activeWeekDows = [];
    const currentDay = new Date(monday);

    // Find the first Monday of the week
    while (currentDay.getDay() !== 1) {
      currentDay.setDate(currentDay.getDate() - 1);
    }

    for (let i = 0; i < 7; i++) {
      if (this.selectedDays[i]) {
        activeWeekDows.push({
          start_date: datePipe.transform(currentDay, "yyyy-MM-dd"),
          dow: currentDay.getDay()
        });
      }
      currentDay.setDate(currentDay.getDate() + 1);

    }

    this.SlotGroupForm.controls["Dows"].setValue(activeWeekDows);
  }

  getMondayOfCurrentWeek(today: Date): Date {
    const day = today.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const diff = today.getDate() - day + (day === 0 ? -6 : 1); // Calculate the difference to Monday
    return new Date(today.setDate(diff)); // Set the date to the calculated Monday and return
  }

  protected triggerAllDay(event: any) {
    if (!event.checked) {
      this.AllDay = false;
    }
    else {
      this.AllDay = true;
      this.SlotGroupForm.controls["StartTime"].setValue(this.selectedDow.start_time);
      this.SlotGroupForm.controls["EndTime"].setValue(this.selectedDow.end_time);
      this.getAvailableTests();
    }
  }

  public checkTimeValidity() {
    const startTimeComponents = this.SlotGroupForm.controls["StartTime"].value.split(':');
    const endTimeComponents = this.SlotGroupForm.controls["EndTime"].value.split(':');

    const startHour = parseInt(startTimeComponents[0], 10);
    const startMinute = parseInt(startTimeComponents[1], 10);

    const endHour = parseInt(endTimeComponents[0], 10);
    const endMinute = parseInt(endTimeComponents[1], 10);

    const startTotalMinutes = startHour * 60 + startMinute;
    const endTotalMinutes = endHour * 60 + endMinute;

    if (startTotalMinutes % 30 != 0) {
      this.SlotGroupForm.controls["StartTime"].setErrors({ incorrect: true });
    }
    else {
      this.SlotGroupForm.controls["StartTime"].setErrors(null);
    }

    if (endTotalMinutes % 30 != 0) {
      this.SlotGroupForm.controls["EndTime"].setErrors({ incorrect: true });
    }
    else {
      this.SlotGroupForm.controls["EndTime"].setErrors(null);
    }

  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      let desc = 'landing-page-config.save-confirm-description';
      if (this.data.edit == true) {
        let sd = this.data?.ConfiguredData?.start_date;
        const currentDate = new Date();
        const originalDate = new Date(sd.getFullYear(), sd.getMonth(), sd.getDate());
        const currentDateWithoutTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        this.startDatePassed = currentDateWithoutTime > originalDate;
        const datePipe = new DatePipe('en-US');
        let start_date = datePipe.transform(this.StartDate.value, 'dd/MM/yyyy');
        if (!this.startDatePassed) {
          desc = this.translateService.instant("add-slot-group.confirm-desc-part1") + start_date + this.translateService.instant("add-slot-group.confirm-desc-type1");
        } else {
          desc = this.translateService.instant("add-slot-group.confirm-desc-part1") + start_date + this.translateService.instant("add-slot-group.confirm-desc-type2");
        }
      }
      let popupRef = this.messageService
        .confirm(
          this.translateService.instant(desc),
          this.translateService.instant('landing-page-config.save-title'),
          this.translateService.instant('landing-page-config.yes'),
          this.translateService.instant('landing-page-config.no')
        );

      popupRef.subscribe((actionResult) => {
        if (actionResult) {
          this.addSlotGroup();
        }
      });
    }
  }

  onInput(event: any): void {
    const input = event.target.value;
    const numericInput = input.replace(/\D/g, '');
    event.target.value = numericInput;
  }

  private getTimeString(date: Date) {
    return date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + ":00";
  }

  public addSlotGroup() {
    const datePipe = new DatePipe('en-US');
    let start_date = datePipe.transform(this.StartDate.value, 'yyyy-MM-dd');
    let tests = this.dataSource.map((test: SSTest) => {
      return {
        test_id: test.id, duration: parseInt(test.avg_time), limit_bookings: parseInt(test.limits.toString())
      };
    })

    let payload = {
      "ss_id": this.samplingStation.id,
      "slot_name": this.SlotGroupForm.get("SlotTitle").value,
      "start_date": start_date,
      "start_time": this.SlotGroupForm.get("StartTime").value.split(":").length == 3 ? this.SlotGroupForm.get("StartTime").value : this.SlotGroupForm.get("StartTime").value + ":00",
      "end_time": this.SlotGroupForm.get("EndTime").value.split(":").length == 3 ? this.SlotGroupForm.get("EndTime").value : this.SlotGroupForm.get("EndTime").value + ":00",
      "occurance": this.SlotGroupForm.get("Occurence").value,
      "repeat_every": this.selectedOccurence?.Key != 'DoesNotRepeat' ? this.SlotGroupForm.get("RepeatEvery").value : 0,
      "is_unavilability": false,
      "test_ids": tests,
      "unavilable_reason": "",
      "is_allday": this.AllDay,
      "dows": this.selectedOccurence?.Key == 'Weekly' ? this.SlotGroupForm.controls["Dows"].value : []
    }
    let apiCall = null;
    payload["is_sequence"] = true;
    if (this.data?.edit == true) {
      payload["id"] = this.data?.ConfiguredData?.id
      payload["is_sequence"] = true;
      apiCall = this.commonAPi.updateSlotGroup(payload)
    }
    else {
      apiCall = this.commonAPi.addSlotGroup(payload)
    }
    apiCall.subscribe(
      () => {
        this.spinner.show(false);
        this.messageService.success(
          this.translateService.instant(this.saveAlert[this.data.action])
        );
        this.dialogRef.close(null);
      },
      (errorResponse: HttpErrorResponse) => {
        this.spinner.show(false);
        if (errorResponse.error.code === 400 && errorResponse.error.internal_code == 3004) {
          this.messageService.alert(
            this.translateService.instant("add-slot-group.error.422")
          );
        }
        else if (errorResponse.error.code === 400 && errorResponse.error.internal_code == 3005) {
          this.messageService.alert(
            this.translateService.instant("add-slot-group.error.3005")
          );
        }
        else if (errorResponse.error.code === 400 && errorResponse.error.internal_code == 3009) {
          this.messageService.alert(
            this.translateService.instant("add-slot-group.error.3009")
          );
        }
        else if (errorResponse.error.code === 400 && errorResponse.error.internal_code == 3010) {
          this.messageService.alert(
            this.translateService.instant("add-slot-group.error.3010")
          );
        } else {
          this.messageService.alert(
            this.translateService.instant("add-slot-group.error.422")
          );
        }
        this.dialogRef.close(null);
      }
    );
  }
}
