import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AppSettingsService } from '../app/core';

@Injectable()
export class LocaleService {
    localeLang: string;
    localeSubject = new Subject<string>();
    locale = this.localeSubject.asObservable();
    constructor(public translate: TranslateService,
        private readonly appSettings: AppSettingsService) {
        const langs: any = this.appSettings.locale?.split(';') ;
        this.translate.addLangs(langs);
        if (!localStorage.getItem('locale')) {
            localStorage.setItem('locale', this.appSettings.locale?.split(';')[0]);
        }
        this.localeLang = localStorage.getItem('locale');
        this.localeSubject.next(localStorage.getItem('locale'));
    }
    getLocale() {
        return this.locale;
    }
    setLocale(val: string) {
        localStorage.setItem('locale', val);
        this.localeLang = val;
        this.translate.use(val);
        this.localeSubject.next(val);
        this.setCaptchalang();
    }

    setCaptchalang() {
        if (document.getElementById('recaptcha') && document.getElementById('recaptcha').querySelector('iframe')) {
            const iframeGoogleCaptcha = document.getElementById('recaptcha').querySelector('iframe');
            const currentLang = iframeGoogleCaptcha.getAttribute('src').match(/hl=(.*?)&/).pop();
            if (currentLang !== this.localeLang) {
                iframeGoogleCaptcha.setAttribute(
                    'src',
                    iframeGoogleCaptcha.getAttribute('src').replace(
                        /hl=(.*?)&/,
                        'hl=' + this.localeLang + '&'
                    )
                );
            }
        } else {
            setTimeout(() => this.setCaptchalang(), 200);
        }

        let cookiePolicyLink = 'assets/policy/CookiePolicyVf-fr.pdf';
        if (this.localeLang == 'en') {
            cookiePolicyLink = 'assets/policy/CookiePolicyVf.pdf';
        }
        document.getElementById('eurofinsCookiePolicyLink')?.parentElement?.setAttribute('href', cookiePolicyLink);
        document.getElementById('eurofinsCookiePolicyLink')?.parentElement?.setAttribute('target', '_blank');
    }
}
