import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PatientService } from './patient.service';

@Injectable({
  providedIn: 'root'
})
export class PatientDocumentsServiceService {
  public reqDoc : any;
  public uploadedDoc : [];
  public expiredDoc : [];

  constructor(
    private readonly patientService: PatientService,
    private translate: TranslateService,) { }

  public loadDocumentTypes() {
    let patientId = JSON.parse(sessionStorage.getItem('userDetails')).id;
    let loginId = JSON.parse(sessionStorage.getItem("userDetails")).login_id;
    this.patientService.getRequiredDocumentsByPatientId(patientId,loginId).subscribe((requiredDocuments: Array<any>) => {
      if(requiredDocuments['documents']){
      requiredDocuments['documents'].sort((a: any, b: any) =>
        this.translate
          .instant(a.key_name)
          .localeCompare(this.translate.instant(b.key_name))
      );}
      requiredDocuments = requiredDocuments['documents'];
      this.reqDoc = requiredDocuments;
      this.getPatientDocument(patientId);
    });
  }

  public getPatientDocument(patientId:string) {
    this.patientService
      .getPatientDocuments(patientId)  // expry date URL
      .subscribe((documents: any) => {
        this.uploadedDoc = documents;
    })
  }
}
