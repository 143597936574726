import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '../../core';
import { GetAuthLoginRequest, GetAuthOtpRequest, SaltRequest } from './Models/getAuthAccessTokenRequestModel';
import { GetAuthRefresRequest } from './Models/getAuthRefreshRequestModel';
import { SendOTPRequest, VerifyOTPRequest } from './Models/sendAuthOTPRequestModel';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    constructor(
        private http: HttpClient,
        private readonly appSettings: AppSettingsService,
    ) { }

    getAuthLogin(loginRequest: GetAuthLoginRequest) {
        return this.http.post(this.appSettings.authBaseUrl + 'otp/send', JSON.stringify(loginRequest));
    }
    postAuthLogout() {
        return this.http.get(this.appSettings.authBaseUrl + 'logout');
    }
    getSalt(saltRequest: SaltRequest) {
        return this.http.post(this.appSettings.authBaseUrl + 'findsalt', JSON.stringify(saltRequest));
    }
    ActivateLogin(id: any) {
        return this.http.get(this.appSettings.patientBaseUrl + 'activate?id=' + id + '&is_active=true');
    }

    getAuthRefresh(refreshRequest: GetAuthRefresRequest) {
        return this.http.post(this.appSettings.authBaseUrl + 'refresh', refreshRequest);
    }

    postAuthOtpVerify(otpRequest: GetAuthOtpRequest) {
        return this.http.post(this.appSettings.authBaseUrl + 'login', JSON.stringify(otpRequest));
    }

    postAuthOtpSend(otpRequest: GetAuthLoginRequest) {
        return this.http.post(this.appSettings.authBaseUrl + 'otp/send', JSON.stringify(otpRequest));
    }

    sendOTP(postData: SendOTPRequest) {
        return this.http.post<any>(this.appSettings.authBaseUrl + 'sendemail', JSON.stringify(postData));
    }

    verifyOTP(postData: VerifyOTPRequest) {
        return this.http.post<any>(this.appSettings.authBaseUrl + 'sendemail/verify', JSON.stringify(postData));
    }

}
