import { Observable } from 'rxjs';
import { LogEntry } from './log-entry.model';

/** Base class for LogPublisher */
export abstract class LogPublisher {
    public Location: string;
    public Name: string;
    public abstract log(record: LogEntry): Observable<boolean>;
    public abstract clear(): Observable<boolean>;
}
