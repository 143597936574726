import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Company, SamplingStationObj } from '../../models/common.model';
import { FormControl } from '@angular/forms';
import { CommonApiService } from '../../services/common-api.service';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { CalendarEvent, CalendarView, CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { LocaleService } from '../../../app.locale.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { LoaderService, MessageService } from '../../../main';
import { MatDialog } from '@angular/material/dialog';
import { SpecialDate } from '../booking/special-dates/special-date';
import { AddSlotConfigurationComponent } from './add-slot-configuration/add-slot-configuration.component';
import { BookingAppointmentGuestService } from '../../services/booking-appointment-guest.service';
import * as moment from 'moment';
import { SpinnerService } from '../../../core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActiveDate, ConfiguredData } from './ss-slot-configuration.model';
import { PreviewConfigurationComponent } from './preview-configuration/preview-configuration.component';
@Component({
  selector: 'app-ss-slot-configuration',
  templateUrl: './ss-slot-configuration.component.html',
  styleUrls: ['./ss-slot-configuration.component.scss']
})


export class SsSlotConfigurationComponent implements OnInit {
  public today: Date = new Date();
  public companies: Company[] = null;
  public samplingStations: any[];
  public myControl = new FormControl();
  public s_id: any;
  public filteredOptions: Observable<any[]>;
  public view: CalendarView = CalendarView.Week;
  public viewDate: Date = new Date();
  public activeDayIsOpen: boolean = true;
  calendarRenderEvent: CalendarWeekViewBeforeRenderEvent;
  isLoaded: boolean = false;
  refresh = new Subject<void>();
  daysInWeek = 7;
  public savealert = {
    edit: "admin.save-alert.edit",
    add: "admin.save-alert.add",
  };

  uniqueEvents: CalendarEvent[] = [];
  aa: any[] = [];

  public specialDates: SpecialDate[] = [];
  selectedSamplingStation: any;
  datesInvalid = [1666575720000];
  minStartTime = 1;
  maxStartTime = 23;
  lunchbreak = 'slot-configuration.lunch-break';
  // ssTests: any;
  disablePrevious: boolean;
  disableNext: boolean;
  events: any;
  callbackFunction: any;
  overlayRef: any;
  ads: ActiveDate[];
  open_until: Date;
  get viewPortHeight(): number {
    return Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
  }
  constructor(private commonAPi: CommonApiService, private breakpointObserver: BreakpointObserver,
    public localeService: LocaleService, private translate: TranslateService, public bookingAppointmentService: BookingAppointmentGuestService,
    private cd: ChangeDetectorRef, public loader: LoaderService, private dialog: MatDialog, private datepipe: DatePipe, private messageService: MessageService,
    private spinner: SpinnerService,) {
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang,);
    registerLocaleData(this.localeService.localeLang == 'fr' ? localeFr : localeEn);
    this.ads = new Array<ActiveDate>();
  }

  ngOnChanges(): void {
    registerLocaleData(this.localeService.localeLang == 'fr' ? localeFr : localeEn);
  }

  ngOnInit(): void {
    registerLocaleData(this.localeService.localeLang == 'fr' ? localeFr : localeEn);
    this.commonAPi.GetCompany().subscribe(x => {
      this.companies = x;
    });

    const CALENDAR_RESPONSIVE = {
      medium: {
        breakpoint: '(max-width: 962px)',
        daysInWeek: 1
      }
    };

    this.breakpointObserver
      .observe(
        Object.values(CALENDAR_RESPONSIVE).map(({ breakpoint }) => breakpoint)
      )
      .pipe(takeUntil(this.refresh))
      .subscribe((state: BreakpointState) => {
        const foundBreakpoint = Object.values(CALENDAR_RESPONSIVE).find(
          ({ breakpoint }) => !!state.breakpoints[breakpoint]
        );
        if (foundBreakpoint) {
          this.daysInWeek = foundBreakpoint.daysInWeek;
          this.view = CalendarView.Day;
        } else {
          this.daysInWeek = 7;
          this.view = CalendarView.Week;
        }
        this.cd.markForCheck();
      });
  }

  public getSlotGroups(date: Date) {
    let requestPayload = {
      "ss_id": this.s_id,
      "start_date": this.datepipe.transform(date, 'yyyy-MM-dd'),
      "num_of_days": 7
    };

    return this.commonAPi.getSlotGroups(requestPayload);
  }

  public samplingStationChange(event: any): void {
    this.open_until = new Date(event.option.value.open_until);
    this.spinner.show(true);
    this.spinner;
    this.myControl.setValue(event.option.value.sampling_station_name);
    this.s_id = event.option.value.id;
    this.aa = new Array<any>();
    this.disablePrevious = false;
    this.disableNext = false;
    //this.getSSTest();
    this.subscribeSlotGroups(event.option.value);
    this.getActiveDates(this.s_id)
  }

  public getActiveDates(s_id: number) {
    const payload = {
      "ss_id": s_id,
      "start_date": this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
      "num_of_days": 60
    };
    this.commonAPi.getActiveDates(payload).subscribe((ads: Array<ActiveDate>) => {
      ads.forEach(element => {
        element.start_date = new Date(element.start_date);
      });
      this.ads = ads;
      console.log(this.ads);
    });
  }

  subscribeSlotGroups(ss: any) {
    this.isLoaded = false;
    this.getSlotGroups(this.viewDate).subscribe((sgs: Array<any>) => {
      //this.getSSTest();
      this.selectedSamplingStation = null;
      this.selectedSamplingStation = ss;
      if (sgs) {
        sgs?.forEach((element: any) => {
          element.start_time = this.datepipe.transform(element.start_time, 'yyyy-MM-ddTHH:mm:ss');
          element.end_time = this.datepipe.transform(element.end_time, 'yyyy-MM-ddTHH:mm:ss');
          element.slot_date = this.datepipe.transform(element.slot_date, 'yyyy-MM-dd');
        });
        this.events = [];
        this.uniqueEvents = [];
        this.aa = sgs;
        this.spinner.show(false);
      }
      else {
        this.events = [];
        this.uniqueEvents = [];

      }
      this.isLoaded = true;
    },
      (errorResponse: HttpErrorResponse) => {
        this.spinner.show(false);
        console.log(errorResponse);
        this.messageService.alert(
          this.translate.instant(errorResponse.error.message)
        );
        this.isLoaded = true;
      });
  }
  // getSSTest() {
  //   this.bookingAppointmentService.getAdmintests(this.s_id).subscribe(
  //     (response: any) => {
  //       this.ssTests = response;
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }

  public companyChange(id: any, event: any): void {
    this.myControl.setValue('');
    this.selectedSamplingStation = null;
    this.viewDate = new Date();

    if (event.isUserInput) {
      this.commonAPi.GetSamplingStation(id).subscribe((x: SamplingStationObj) => {

        this.samplingStations = x.sampling_stations;
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      });
    }
  }

  private _filter(value?: any): any[] {
    if (this.samplingStations != null) {
      if (typeof (value) == 'string') {
        const filterval = value?.toLowerCase();
        return this.samplingStations.filter(option => option.sampling_station_name.toLowerCase().includes(filterval));
      } else {
        const filterval = value?.sampling_station_name?.toLowerCase();
        return this.samplingStations.filter(option => option.sampling_station_name.toLowerCase().includes(filterval));

      }

    }
    return null;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    this.aa = new Array<any>();
    let open_until = new Date(this.datepipe.transform(this.open_until, 'yyyy-MM-dd')).getTime();
    let today = new Date(this.datepipe.transform(new Date(), 'yyyy-MM-dd')).getTime();
    const maxDate = new Date(open_until + (1 * 86400 * 1000)).getTime();
    const minDate = new Date(today - (28 * 86400 * 1000)).getTime();

    let weekStartDate = new Date(this.datepipe.transform(new Date(this.viewDate), 'yyyy-MM-dd')).getTime();
    this.disablePrevious = false;
    this.disableNext = false;
    if (weekStartDate <= minDate) {
      this.disablePrevious = true;
    }
    else if (weekStartDate >= maxDate) {
      this.disableNext = true;
    }

    this.spinner.show(true);
    this.getSlotGroups(this.viewDate).subscribe((sgs: any) => {
      sgs?.forEach((element: any) => {
        element.start_time = this.datepipe.transform(element.start_time, 'yyyy-MM-ddTHH:mm:ss');
        element.end_time = this.datepipe.transform(element.end_time, 'yyyy-MM-ddTHH:mm:ss');
        element.slot_date = this.datepipe.transform(element.slot_date, 'yyyy-MM-dd');
      });
      this.aa = sgs;
    });
  }

  convertTimeToDate(date: Date, timeInput: string) {
    const [hours, minutes, seconds] = timeInput.split(":").map(Number);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds);
  }

  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {

    this.calculateDayStartEndHour();
    const td = new Date(renderEvent.period.start);
    const we_date = new Date(td.getFullYear(), td.getMonth(), td.getDate(), 23, 59, 59);
    we_date.setDate(we_date.getDate() + 7);
    let dows = this.selectedSamplingStation['opening_and_closing_details'];
    let open_until = new Date(this.open_until.getFullYear(), this.open_until.getMonth(), this.open_until.getDate());
    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          let segementDate = new Date(segment.date.getFullYear(), segment.date.getMonth(), segment.date.getDate());
          if (segementDate <= open_until) {
            let segmentDay = segment.date.getDay();
            const workingDay = dows ? (dows.filter((dow: any) => dow.day == segmentDay) ? dows.filter((dow: any) => dow.day == segmentDay)[0] : null) : null;
            if (!workingDay) {
              //Non Working Day
              let minhours = this.minStartTime.toString().padStart(2, '0')
              let maxhours = (this.maxStartTime + 1).toString().padStart(2, '0')
              let endTime = this.convertTimeToDate(segment.date, maxhours + ":00:00")
              let startTime = this.convertTimeToDate(segment.date, minhours + ":00:00")

              if (!this.checkEventExists(startTime, endTime, this.uniqueEvents)) {
                this.uniqueEvents.push({
                  id: 0,
                  start: new Date(startTime),
                  end: new Date(endTime),
                  title: "",
                  cssClass: 'cell-non-working-day',
                  draggable: false
                });
              }
            }

            else {
              const segmentEvents = (this.aa?.filter((r: any) => new Date(r.start_time).getTime() >= segment.date.getTime() && new Date(r.start_time).getTime() < moment(segment.date).add(30, 'm').toDate().getTime()));
              let lunch_break_index = segmentEvents.findIndex((i: any) => i.is_lunch_break == true);
              let unavailabilities = segmentEvents.filter((i: any) => i.is_unavailable == true);
              const holiday_index = this.aa?.findIndex(item => item.is_special_date === true && this.datepipe.transform(item.slot_date, "yyyy-MM-dd") == this.datepipe.transform(segment.date, "yyyy-MM-dd"));

              if (holiday_index > -1) {
                let minhours = this.minStartTime.toString().padStart(2, '0');
                let maxhours = (this.maxStartTime + 1).toString().padStart(2, '0');
                let endTime = this.convertTimeToDate(segment.date, maxhours + ":00:00");
                let startTime = this.convertTimeToDate(segment.date, minhours + ":00:00");
                if (!this.checkEventExists(startTime, endTime, this.uniqueEvents)) {
                  this.uniqueEvents.push({
                    id: this.aa[holiday_index].test_group_id,
                    start: new Date(startTime),
                    end: new Date(endTime),
                    title: "",
                    cssClass: 'cell-error',
                    draggable: false
                  });
                }
              }
              else if (lunch_break_index > -1) {
                this.uniqueEvents.push({
                  id: this.aa[lunch_break_index].test_group_id,
                  start: new Date(segmentEvents[lunch_break_index].start_time),
                  end: new Date(segmentEvents[lunch_break_index].end_time),
                  title: this.translate.instant(this.lunchbreak),
                  cssClass: 'cell-lunch-break',
                  draggable: false
                });
                if (segmentEvents.length > 0) {
                  let slotGroups = segmentEvents.filter((i: any) => i.is_special_date == false && i.is_lunch_break == false && i.is_unavailable == false);
                  if (slotGroups.length > 0) {
                    slotGroups.forEach((sg: any) => {
                      this.uniqueEvents.push(
                        {
                          id: sg.test_group_id,
                          start: new Date(sg.start_time),
                          end: new Date(sg.end_time),
                          title: sg.test_group_name ? sg.test_group_name : "NA",
                          cssClass: 'main-event',
                          draggable: false
                        });
                    });
                  }
                  if (unavailabilities?.length > 0) {
                    unavailabilities.forEach((element: any) => {
                      this.uniqueEvents.push({
                        id: element.test_group_id,
                        start: new Date(element.start_time),
                        end: new Date(element.end_time),
                        title: element.test_group_name,
                        cssClass: slotGroups.length == 0 ? 'unavailability' : 'sub-unavailability',
                        draggable: false
                      });
                    });
                  }
                }
              }
              else {
                if (segmentEvents.length > 0) {
                  let slotGroups = segmentEvents.filter((i: any) => i.is_special_date == false && i.is_lunch_break == false && i.is_unavailable == false);
                  if (slotGroups.length > 0) {
                    slotGroups.forEach((sg: any) => {
                      this.uniqueEvents.push(
                        {
                          id: sg.test_group_id,
                          start: new Date(sg.start_time),
                          end: new Date(sg.end_time),
                          title: sg.test_group_name ? sg.test_group_name : "NA",
                          cssClass: 'main-event',
                          draggable: false
                        });
                    });
                  }
                  if (unavailabilities?.length > 0) {
                    unavailabilities.forEach((element: any) => {
                      this.uniqueEvents.push({
                        id: element.test_group_id,
                        start: new Date(element.start_time),
                        end: new Date(element.end_time),
                        title: element.test_group_name,
                        cssClass: slotGroups.length == 0 ? 'unavailability' : 'sub-unavailability',
                        draggable: false
                      });
                    });
                  }

                  let segDate = new Date(segment.date);
                  const segEndDate = new Date(segDate.getTime() + 30 * 60 * 1000);
                  let minStartTime = this.convertTimeToDate(segment.date, workingDay.start_time).getTime();
                  let maxEndTime = this.convertTimeToDate(segment.date, workingDay.end_time).getTime();
                  if (segment.date.getTime() >= minStartTime && segment.date.getTime() < maxEndTime && segment.date.getTime() > new Date().getTime()) {
                    this.uniqueEvents.push(
                      {
                        id: 0,
                        start: segment.date,
                        end: new Date(segEndDate),
                        title: "",
                        cssClass: 'cell-add-more',
                        draggable: false
                      });
                  }
                }
                else {
                  let segStartDate = new Date(segment.date);
                  const segEndDate = new Date(segStartDate.getTime() + 30 * 60 * 1000);
                  let minStartTime = this.convertTimeToDate(segment.date, workingDay.start_time).getTime();
                  let maxEndTime = this.convertTimeToDate(segment.date, workingDay.end_time).getTime();
                  if (segment.date.getTime() >= minStartTime && segment.date.getTime() < maxEndTime && segment.date.getTime() > new Date().getTime()) {
                    this.uniqueEvents.push({
                      id: 0,
                      start: segStartDate,
                      end: new Date(segEndDate),
                      title: "",
                      cssClass: 'cell-add-more',
                      draggable: false
                    });
                  }
                  else {
                    segment.cssClass = "cell-disabled";
                  }
                }
              }
            }
          }
          else {
            segment.cssClass = "cell-disabled";
          }
        });
      });
    });

    this.events = this.getUniqueEvents().sort((a, b) => b.id - a.id);
    this.spinner.show(false);
  }

  getUniqueEvents() {
    const uE = this.uniqueEvents.reduce((unique, event) => {
      if (!unique.some(a => a.start.getTime() == event.start.getTime() && a.end.getTime() == event.end.getTime() && a.id == event.id && a.cssClass == event.cssClass)) {
        unique.push(event);
      }
      return unique;
    }, []);
    return uE;
  }

  public checkEventExists(startTime: Date, endTime: Date, events: CalendarEvent[]) {
    return events.some(event => event.start.getTime() === startTime.getTime() && event.end.getTime() === endTime.getTime());
  }

  public isSegmentWithinSamplingRange(segmentDate: Date, openingTime: string, closingTime: string) {
    const openingDateTime = new Date(segmentDate.toDateString() + ' ' + openingTime);
    const closingDateTime = new Date(segmentDate.toDateString() + ' ' + closingTime);
    return segmentDate >= openingDateTime && segmentDate <= closingDateTime;
  }


  public calculateDayStartEndHour() {
    if (this.selectedSamplingStation != null && this.selectedSamplingStation?.opening_and_closing_details?.length > 0) {
      this.minStartTime = Number((this.selectedSamplingStation['opening_and_closing_details'][0].start_time).substring(0, 2));
      this.maxStartTime = Number((this.selectedSamplingStation['opening_and_closing_details'][0].end_time).substring(0, 2));
      this.selectedSamplingStation['opening_and_closing_details'].forEach((day: any) => {
        this.minStartTime = Number((day.start_time).substring(0, 2)) < this.minStartTime ? Number((day.start_time).substring(0, 2)) : this.minStartTime;
        this.maxStartTime = Number((day.end_time).substring(0, 2)) > this.maxStartTime ? Number((day.end_time).substring(0, 2)) : this.maxStartTime;
      });
    }
  }

  public handleEventClick(event: any): void {

    if (event?.event?.cssClass == "cell-add-more") {
      const dialogRef = this.dialog.open(AddSlotConfigurationComponent, {
        width: "800px",
        maxHeight: '100vh',
        disableClose: true,
        autoFocus: false,
        restoreFocus: false,
        data: { action: "add", edit: false, savealert: this.savealert, samplingStation: this.selectedSamplingStation, selectedDateTime: event, ads: this.ads },
      });
      dialogRef.afterClosed().subscribe(() => {
        this.subscribeSlotGroups(this.selectedSamplingStation);
      });
    }
    else if (event?.event?.cssClass == "main-event" || event?.event?.cssClass == "unavailability" || event?.event?.cssClass == "sub-unavailability") {
      this.commonAPi.getSlotGroupData(event?.event?.id).subscribe((response: ConfiguredData) => {
        response.start_date = new Date(response.start_date);
        response.start_time = new Date(event?.event?.start);
        response.end_time = new Date(event?.event?.end);
        response.end_date = new Date(response.end_date);

        response.end_time_string = this.datepipe.transform(new Date(event?.event?.end), 'HH:mm:ss')
        response.start_time_string = this.datepipe.transform(new Date(event?.event?.start), 'HH:mm:ss')
        this.openPreviewPopup(response, event);
      });
    }
  }


  public redirectToManageHoliday() {
    this.loader.isPreLoginFLow = true;
    this.loader.highlightLeftMenu(['admin/manage-holiday']);
  }

  public toggleView(view: any) {
    if (view == 1) {
      this.view = CalendarView.Day;
    } else {
      this.view = CalendarView.Week;
    }
  }

  public openPreviewPopup(response: ConfiguredData, segmentTime: any) {
    let e_time = this.datepipe.transform(new Date(response.end_time), 'HH:mm:ss');
    let f_e_time = this.convertTimeToDate(segmentTime?.event?.start, e_time);
    const dialogRef = this.dialog.open(PreviewConfigurationComponent, {
      width: "500px",
      height: 'auto',
      disableClose: false,
      autoFocus: false,
      restoreFocus: false,
      data: { ConfiguredData: response, samplingStation: this.selectedSamplingStation, selectedDateTime: new Date(response.start_time), selectedEndDateTime: f_e_time, segmentTime: segmentTime, ads: this.ads },
    });
    dialogRef.afterClosed().subscribe(() => {

      this.subscribeSlotGroups(this.selectedSamplingStation);
    });
  }

  resetFewControls() {
    this.myControl.setValue('');
    this.selectedSamplingStation = null;
    this.viewDate = new Date();
  }
}