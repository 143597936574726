import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    public readonly breadCrumbObs$: Observable<any>;
    private readonly breadCrumbSub$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    constructor(private router: Router) {
        this.breadCrumbObs$ = this.breadCrumbSub$.asObservable();

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            distinctUntilChanged()).
            subscribe(() => {
                const root = this.router.routerState.snapshot.root;
                const breadCrumbs: any = [];

                this.createBreadCrumb(root, [], breadCrumbs);
                this.breadCrumbSub$.next(breadCrumbs);
            });
    }

    private createBreadCrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: any) {
        if (route) {
            const routeUrl = parentUrl.concat(route.url.map(x => x.path));

            if (route.data.breadcrumb) {
                const breadcrumb = {
                    label: route.data.breadcrumb,
                    url: '/' + routeUrl.join('/')
                };
                breadcrumbs.push(breadcrumb);
            }
            this.createBreadCrumb(route.firstChild, routeUrl, breadcrumbs);
        }
    }
}
