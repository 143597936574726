import { BreakpointObserver } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";
import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  Input,
  ViewChild,
  EventEmitter,
  Output,
  ElementRef,
  OnInit,
  OnDestroy,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";

import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatStepper } from "@angular/material/stepper";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CalendarView } from "angular-calendar";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LocaleService } from "../../../app.locale.service";
import { AppSettingsService, SpinnerService } from "../../../core";
import { LoaderService, MessageService } from "../../../main";
import { BookingAppointmentGuestService } from "../../services/booking-appointment-guest.service";
import { CommonApiService } from "../../services/common-api.service";
import { PatientService } from "../../services/patient.service";
import { ResetPasswordServiceService } from "../../services/reset-password-service.service";
import { PrescriptionDocument } from "../appointment-prescription/appointment-prescription.model";
import { DateSlot } from "../date-slot/date-slot.model";
import { WebcamService } from "../../services/webcam.service";
import { constants } from "../constant";
import * as moment from "moment";
import jwtDecode from "jwt-decode";
import { LoginServiceService } from "../../services/login-service.service";
import { ToastrService } from "ngx-toastr";
import { OffsetMaster } from "../../models/common.model";
declare var _paq: Array<any>;
@Component({
  selector: "app-stepper-flow",
  templateUrl: "./stepper-flow.component.html",
  styleUrls: ["./stepper-flow.component.scss"],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: "DD-MM-YYYY",
        },
        display: {
          dateInput: "DD-MM-YYYY",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        },
      },
    },
  ],
})
export class StepperFlowComponent implements OnInit, OnDestroy {
  isConfirmed = false;
  isLoaded = false;
  clearFile = false;
  @Input() isBackEnabled: boolean;
  @Output() backClicked = new EventEmitter();
  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
  isFileUploadedOrSkip = false;
  stepperCollection: any;
  protected showPrescription: boolean;
  protected showLoginorRegister = "";
  protected showLoginorRegisterStep: boolean;
  maxDate: any;
  minDate: any;
  public slotTime = new FormControl("");
  stepperTitles: Array<any> = [];
  bookingComment: string;
  protected slotData: DateSlot;
  @ViewChild("stepper") stepper: MatStepper;

  public adminRole = constants.adminRole;
  public supervisorRole = constants.supervisorRole;
  public patientRole = constants.patientRole;
  public nurseRole = constants.nurseRole;
  stepperTitle = "Testing";
  public registeredEmail = new FormControl("", [
    Validators.required,
    Validators.pattern(this.patientService.emailPattern),
  ]);
  @ViewChild("RegisteredEmail") RegisteredEmail: ElementRef;
  login_id: any;
  firstFormGroup = new FormControl("");
  secondFormGroup = new FormControl("");
  thirdFormGroup = new FormControl("");
  forthFormGroup = new FormControl("");
  fifthFormGroup = new FormControl("");

  stepperOrientation: Observable<StepperOrientation>;
  public sendEmailForm = new FormGroup({
    RegisteredEmail: this.registeredEmail,
  });

  showBooking: boolean;
  u_id = 0;
  calendar_id = 56;
  patient_id = "";
  slot_time: any = "2023-09-04T09:30:00Z";
  tests: any[] = [];
  capacity: any = 5;
  prescription_url = "";
  saveAppointmentObj = {
    calendar_id: this.calendar_id,
    patient_id: this.patient_id,
    slot_time: this.slot_time,
    tests: this.tests,
    capacity: this.capacity,
    prescription_url: this.prescription_url,
  };
  hasCalendar = false;
  noCalendar = false;
  labSelected = false;
  selectedtest: any;
  selectedLab: any;
  selectedAppointment: any;
  slotGroupId: number;
  selectedprescription: any;
  selectedWeek = "";
  favoroteSamplingStationids = new Array();
  s_id: any = 0;
  userDetails: any;
  showLogin = false;
  public showLoginPrebook = true;
  showConfirmBooking = false;
  flow = 1;
  finalStep: any = "stepper-flow.confirm-booking";
  viewDate: Date = new Date();
  view: CalendarView = CalendarView.Week;
  locale: string = this.localeService.localeLang;
  username: any;
  password: any;
  @Output() handleNavigationNodes = new EventEmitter();
  showConfirmationLink = false;
  showCreateAccount: boolean;
  showRegister: boolean = false;
  showActivateMessage: boolean = false;
  forgetPasswordMailSent: boolean;
  showRadioButton: boolean = true;
  loginSuccessful: boolean;
  registerSuccessful: boolean;
  public authType = new FormControl("", [Validators.required]);
  forgotPassword: boolean;
  isConfirmLinkClicked: boolean;
  userExistError: any;
  ext_id = 0;
  protected test_ext_id: string = "";
  protected test_ext_id_actual: string = "";
  protected preSelectedTestType: number = 0;
  protected mapeditable: boolean = true;
  protected testeditable: boolean = true;
  public testType: string[] = [];
  testArray: any = [];
  public selectTest: any[] = [];
  protected isClicked: boolean = false;
  dateValue: FormControl;

  disabledDates: any[] = [];
  protected documents: PrescriptionDocument[] = [
    {
      Id: 1,
      PrescriptionParts: [],
      IsIncorrectFileFormat: false,
      IsInvalidFileSize: false,
      Expanded: true,
    },
  ];
  protected activeDates: any[] = [];
  protected noDocuments: boolean = true;
  myFilter = (d: Date): boolean => {
    let dpd = new Date(d);
    const year = dpd.getFullYear();
    const month = String(dpd.getMonth() + 1).padStart(2, "0");
    const day = String(dpd.getDate()).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;
    return this.activeDates.some((x) => x === dateString);
  };
  protected isFuture: boolean = false;
  protected isPastDate: boolean = false;
  protected isNonWorkingDay: boolean = false;
  protected fav_lab_id: any = 0;
  isMassiveTesting: boolean = false;
  protected uploadStatus: boolean = false;
  offsets: OffsetMaster[] = [];
  isLoginReceptionist: boolean = false;
  constructor(
    public patientService: PatientService,
    private messageService: MessageService,
    private commonAPi: CommonApiService,
    breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private localeService: LocaleService,
    private route: ActivatedRoute,
    public bookingAppointmentService: BookingAppointmentGuestService,
    public loaderService: LoaderService,
    private resetPasswordService: ResetPasswordServiceService,
    private readonly appSettings: AppSettingsService,
    public spinner: SpinnerService,
    private dateAdapter: DateAdapter<Date>,
    private webcamService: WebcamService,
    private loginService: LoginServiceService,
    public toastr: ToastrService
  ) {
    this.slotData = new DateSlot();
    const today = new Date();
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);

    this.maxDate = new Date(new Date().setDate(today.getDate() + 30));
    this.minDate = today;

    this.dateAdapter.setLocale(this.localeService.localeLang);

    this.stepperOrientation = breakpointObserver
      .observe("(min-width: 800px)")
      .pipe(map(({ matches }) => (matches ? "horizontal" : "vertical")));

    this.setState(this.firstFormGroup, true);
    this.setState(this.secondFormGroup, true);
    this.setState(this.thirdFormGroup, true);
    this.setState(this.forthFormGroup, true);
    this.setState(this.fifthFormGroup, true);
    this.ext_id = this.route.snapshot.params["l_id"];
    this.fav_lab_id = this.route.snapshot.params["fav_lab_id"];
    this.test_ext_id = this.route.snapshot.params["t_id"];
    this.test_ext_id_actual = this.test_ext_id;
    this.stepperCollection = {
      laboratory: "",
      test: "",
      appointment: "",
      prescription: "",
      loggedIn: "",
    };

  }
  ngOnInit(): void {
    this.isLoginReceptionist = JSON.parse(sessionStorage.getItem('access_token'))?.role == constants.nurseRole;
    this.getUserOffSet();
    this.getBirthCities();
  }


  getBirthCities() {
    this.commonAPi.getBirthCities().subscribe(
      (data: any) => {
        if (this.commonAPi.birthCitiesList.value == null) {
          this.commonAPi.birthCitiesList.next(data)
        }
      })
  }
  public getUserOffSet() {
    this.loginService.getOffSets().subscribe((data: Array<any>) => {
      this.offsets = data;
      let currentOffsets = new Date().getTimezoneOffset()
      if (this.offsets.length > 0) {
        let index = this.offsets.findIndex(i => i.KeyName.toString() == currentOffsets.toString());
        if (index > -1) {
          this.loaderService.latitude = Number(JSON.parse(this.offsets[index].Key).lat);
          this.loaderService.longitude = Number(JSON.parse(this.offsets[index].Key).long);
          this.loaderService.zoom = Number(JSON.parse(this.offsets[index].Key).zoom);
        }

        if (this.ext_id || this.fav_lab_id) {
          this.flow = 2;
          this.stepperTitles = [
            "stepper-flow.laboratory",
            "stepper-flow.test",
            "stepper-flow.appointment",
            "stepper-flow.prescription",
            "stepper-flow.login-register",
            "stepper-flow.confirm-booking",
          ];
        } else {
          this.stepperTitles = [
            "stepper-flow.test",
            "stepper-flow.laboratory",
            "stepper-flow.appointment",
            "stepper-flow.prescription",
            "stepper-flow.login-register",
            "stepper-flow.confirm-booking",
          ];
        }
        if (this.flow == 1) {
          this.getTestTypes();
        }
        this.localeService.locale.subscribe(() =>
          this.dateAdapter.setLocale(this.localeService.localeLang)
        );
        if (
          (this.loaderService.bookingPatientId != "" &&
            this.loaderService.bookingPatientId != null) ||
          this.loaderService.userRole == constants.patientRole
        ) {
          this.showLoginorRegisterStep = false;
        } else {
          this.showLoginorRegisterStep = true;

          if (this.loaderService.userRole == constants.nurseRole) {
            this.showRegister = true;
          }
        }
        if (!this.showLoginorRegisterStep) {
          let idx: number = this.stepperTitles.indexOf(
            "stepper-flow.login-register"
          );
          if (idx > 0) {
            this.stepperTitles.splice(idx, 1);
          }
        }
      }
    });
  }
  onRadioButtonSelect() { }

  private getTestTypes() {
    this.bookingAppointmentService.getAdmintesttype(this.s_id).subscribe(
      (getResponse) => {
        const vals = [];
        for (const i in getResponse) {
          vals.push(getResponse[i]);
          //this.testType.push(getResponse[i]);
        }
        this.testType = vals;
      },
      (error) => {
        console.log(error);
      }
    );
    this.getTestBySamplingStationId();
  }

  getTestBySamplingStationId() {
    this.isLoaded = false;
    this.testArray = [];
    this.spinner.show(true);
    this.bookingAppointmentService.getAdmintestsSorted(this.s_id).subscribe(
      (getResponse: any) => {
        this.spinner.show(false);
        this.testArray = getResponse;
        this.selectTest = [...this.testArray];
        this.isLoaded = true;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  setState(control: FormControl, state: boolean) {
    if (state) {
      control.setErrors({ required: true });
    } else {
      control.reset();
    }
  }

  onSelectTest(event: any) {
    this.selectedtest = event;
    this.showPrescription = this.selectedtest.prescription ? true : false;
    const eventText = !(this.loaderService.userRole == 1 || !this.showPrescription) ? 'Test Selected with Prescription' : 'Test Selected';
    if (this.route)
      if (this.flow == 2) {
        this.stepperCollection.test = event.short_name;
        this.setState(this.firstFormGroup, false);
        this.getSlots();
        _paq.push(['trackEvent', eventText, 'Step2 Completed', this.stepperCollection.test]);
      } else {
        this.stepperCollection.test = event.short_name;
        this.setState(this.firstFormGroup, false);
        _paq.push(['trackEvent', eventText, 'Step1 Completed', this.stepperCollection.test]);
      }

    if (this.selectedLab?.display_map == false) {
      if (
        this.test_ext_id_actual &&
        event.external_code == this.test_ext_id_actual
      )
        this.testeditable = false;
      else this.testeditable = true;
    } else {
      if (window.location.href.split("#/")[1].includes('test/massive')) {
        this.testeditable = false;
        this.isMassiveTesting = true;
      } else {
        this.testeditable = true;
      }
    }
    this.userDetails = JSON.parse(sessionStorage.getItem("userDetails"));

    if (!this.showPrescription || this.loaderService.userRole == constants.nurseRole) {
      let idx: number = this.stepperTitles.indexOf("stepper-flow.prescription");
      if (idx > 0) {
        this.stepperTitles.splice(idx, 1);
      }
    }
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.stepper.next();
  }

  onSelectLocation(event: any) {
    this.selectedLab = event;
    this.mapeditable = this.selectedLab?.display_map;
    this.s_id = event.id;

    if (this.flow == 2) {
      this.getTestTypes();
    }
    this.hasCalendar = false;
    this.labSelected = false;
    this.noCalendar = false;
    if (this.ext_id != event.external_id) {
      this.test_ext_id_actual = "";
    }
    if (this.flow == 1) {
      this.stepperCollection.laboratory = event.sampling_station_name;
      this.getSlots();
      _paq.push(['trackEvent', 'Lab Selected', 'Step2 Completed', this.stepperCollection.laboratory]);
    } else {
      this.stepperCollection.laboratory = event.sampling_station_name;
      _paq.push(['trackEvent', 'Lab Selected', 'Step1 Completed', this.stepperCollection.laboratory]);

      this.setState(this.firstFormGroup, false);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      this.stepper.next();
    }
  }
  private getSlots() {
    if (this.s_id > 0 && this.selectedtest.id > 0) {
      const currentDate = new Date();

      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      this.spinner.show(true);
      this.commonAPi
        .GetSlots(this.s_id, this.selectedtest.id, 4, formattedDate)
        .subscribe((x) => {
          this.slotData = x;
          if (this.slotData.calendar_id == 1) {
            this.slotData.slots.forEach((element: any) => {
              element.filterSlots = new Array<string>();
            });
            this.hasCalendar = true;
            this.noCalendar = false;

          } else {
            this.noCalendar = true;
            this.hasCalendar = false;
            if (this.slotData?.slots?.length > 0) {
              this.slotData.slots.forEach((element: any) => {
                this.activeDates.push(
                  moment.utc(new Date(element.date)).local().format('YYYY-MM-DDTHH:mm:SS').split("T")[0]
                );
              });
              this.spinner.show(false);
            }
            else {
              this.messageService.alert(this.translate.instant("stepper-flow.internal-server-error"));
              this.spinner.show(false);
              return;
            }
          }
          this.labSelected = true;
          this.setState(this.secondFormGroup, false);
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          this.stepper.next();
        });
    }
  }

  onselectedFavroitestation(event: any) {
    this.favoroteSamplingStationids = event;
  }
  onSelectAppointmnt(event: any) {
    this.selectedAppointment = event.split('|')[0];
    this.slotGroupId = parseInt(event.split('|')[1]);
    this.stepperCollection.appointment = event.split('|')[0];;
    this.setState(this.thirdFormGroup, false);
    _paq.push(['trackEvent', 'Slot Selected', 'Step3 Completed', this.stepperCollection.appointment]);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    let userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    if (userDetails) {
      this.loginSuccessful = true;
    }
    this.stepper.next();
    if (this.loaderService.userRole == constants.nurseRole) {
      this.fileUploaded(new Array<PrescriptionDocument>(), event);
    } else if (!this.showPrescription) {
      this.fileUploaded(new Array<PrescriptionDocument>(), event);
    }
    else {

    }
  }

  onSelectAppointmentDate(event: any) {
    if (this.slotTime) {
      if (this.slotTime.value === "" || this.slotTime.value === null) {
        this.slotTime.setErrors({ notExist: true } as ValidationErrors);
        return;
      }
      this.selectedAppointment = this.slotTime.value;
      this.stepperCollection.appointment = this.slotTime.value;
      this.setState(this.thirdFormGroup, false);
      _paq.push(['trackEvent', 'Slot Selected', 'Step3 Completed', this.stepperCollection.appointment]);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      let userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
      if (userDetails) {
        this.loginSuccessful = true;
      }
      // For Chrome, Firefox, IE and Opera
      this.stepper.next();
      if (this.loaderService.userRole == constants.nurseRole) {
        this.fileUploaded(new Array<PrescriptionDocument>(), event);
      } else if (!this.showPrescription) {
        this.fileUploaded(new Array<PrescriptionDocument>(), event);
      }
    }
  }
  protected onDateValueChange(e: any) {
    let dpd = new Date(e.value);
    const year = dpd.getFullYear();
    const month = String(dpd.getMonth() + 1).padStart(2, "0");
    const day = String(dpd.getDate()).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;
    if (this.isDateLessThanToday(dateString)) {
      this.isPastDate = true;
      this.isFuture = false;
      this.isNonWorkingDay = false;
    } else if (this.activeDates.includes(dateString)) {
      this.isPastDate = false;
      this.isFuture = false;
      this.isNonWorkingDay = false;
    } else {
      let isExist = this.isWithin30Days(dateString);
      if (!isExist) {
        this.isPastDate = false;
        this.isFuture = true;
        this.isNonWorkingDay = false;
      } else {
        this.isPastDate = false;
        this.isFuture = false;
        this.isNonWorkingDay = true;
      }
    }
  }

  private isDateLessThanToday(dateString: string): boolean {
    const today = new Date();
    const inputDate = new Date(dateString);
    return inputDate < today;
  }
  private isWithin30Days(dateString: string): boolean {
    const today = new Date();
    const inputDate = new Date(dateString);
    const thirtyDaysFromNow = new Date(today.setDate(today.getDate() + 30));
    return inputDate <= thirtyDaysFromNow;
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  stepperBack(stepperIndex: number) {
    this.webcamService.stopWebcam();
    if (!this.testeditable && stepperIndex <= 2) {
      return;
    }
    if (stepperIndex < 2) {
      this.hasCalendar = false;
      this.noCalendar = false;
    }
    if (stepperIndex == 0) {
      this.backClicked.emit();
    } else {
      this.stepper.previous();
    }

  }

  fileUploaded(event: Array<PrescriptionDocument>, clickevent?: any) {
    if (this.noDocuments && !(this.loaderService.userRole == this.nurseRole || !this.showPrescription)) {
      this.uploadStatus = true;
      this.toastr.warning(this.translate.instant("upload-pic.no-prescription"));
      return
    }
    else {
      this.uploadStatus = false;
    }
    this.selectedprescription = event;

    console.log(clickevent);
    //clickevent.stopPropagation();
    let start_time = new Date();
    if (this.slotGroupId > 0) {
      start_time = new Date(this.stepperCollection.appointment);
    } else {
      start_time = new Date(this.stepperCollection.appointment);
      start_time.setMinutes(start_time.getMinutes());
    }

    this.isFileUploadedOrSkip = true;
    this.showConfirmBooking = true;

    this.isConfirmed = false;
    this.showLogin = false;

    this.showConfirmBooking = true;
    this.isConfirmed = false;
    if (
      (this.loaderService.bookingPatientId != "" &&
        this.loaderService.bookingPatientId != null) ||
      this.loaderService.userRole == constants.patientRole
    ) {
      this.showLoginorRegisterStep = false;
    } else {
      this.showLoginorRegisterStep = true;

      if (this.loaderService.userRole == constants.nurseRole) {
        this.showRegister = true;
      }
    }
    if (!this.showLoginorRegisterStep) {
      let idx: number = this.stepperTitles.indexOf(
        "stepper-flow.login-register"
      );
      if (idx > 0) {
        this.stepperTitles.splice(idx, 1);
      }
    }
    if (this.showPrescription && this.loaderService.userRole != constants.nurseRole) {
      this.setState(this.forthFormGroup, false);
      _paq.push(['trackEvent', 'Prescription Uploaded', 'Step4 Completed', 'prescription']);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

      this.stepper.next();
    }

    //this.disabletheUnRegisterDiv();
  }

  savePatientwithAppointment(event: any) {
    //let payload:any;
    let payload = JSON.parse(sessionStorage.getItem("p_data"));

    let docs: any[] = [];
    this.documents.forEach((element: any) => {
      if (element.PrescriptionParts.length > 0) {
        let doc = {
          prescriptions_pages: element.PrescriptionParts.map(
            (s: any) => s.prescriptionPartData
          ),
        };
        docs.push(doc);
      }
    });
    const isoDate = new Date(this.stepperCollection.appointment);
    (payload["slot_group_id"] = this.slotGroupId),
      (payload["sampling_station_id"] = this.s_id),
      (payload["slot_time"] = moment.utc(isoDate).local().format('YYYY-MM-DDTHH:mm:SS') + "Z");
    payload["favourite_sampling_stations"] = this.favoroteSamplingStationids;
    payload["prescriptions"] = docs;
    payload["test_comments"] = {
      [(this.selectedtest?.id).toString()]: event.bookingComment,
    };
    payload["tests"] = [this.selectedtest.id];
    let a_data = {
      lab: this.selectedLab,
      test: this.selectedtest,
      s_time: this.stepperCollection.appointment,
      test_comments: event.bookingComment,
      user_name: this.loaderService.user_name,
      dob: this.loaderService.dob,
      fromRegister: true,
      email: payload["Email"],
    };
    this.spinner.show(true);
    sessionStorage.setItem("a_data", JSON.stringify(a_data));
    if (this.loaderService.isRMAdded) {
      payload["relative_member"] = this.loaderService.rm_data;
    }
    else {
      payload["relative_member"] = {};
    }

    console.log(payload);
    //this.spinner.show(false);
    this.patientService.createPatient(payload).subscribe(
      (response: any[]) => {
        this.authType.setValue("");
        this.spinner.show(false);
        response;
        this.isConfirmed = true;
        this.showRegister = false;
        _paq.push(['trackEvent', 'Appointment Booked', 'Success', 'Success']);
        this.autoLogin(response);
        this.loaderService.rmMode = false;
        //this.loaderService.highlightLeftMenu(["patient/confirm-appointment"]);

      },
      (error) => {
        this.spinner.show(false);
        if (error.error.internal_code === 423) {
          this.messageService.alert(
            this.translate.instant("createAccount.email-exist")
          );
        }
        else if (error.error.internal_code === 424) {
          this.messageService.alert(
            this.translate.instant('view-booking.booking-full-error'),
            5000
          );
        }
        else if (error.error.internal_code === 427) {

          this.messageService.alert(
            this.translate.instant("createAccount.invalid-ssn")
          );
        } else if (error.error.internal_code === 428) {
          this.messageService.alert(
            this.translate.instant("createAccount.duplicate-account"),
            5000
          );
        } else {
          this.messageService.alert(
            "Your request can not be processed at this moment",
            5000
          );
        }
      }
    );
  }

  public autoLogin(response: any) {
    if (response["access_token"]) {
      this.loaderService.startRefreshTokenTimer(response);
      const access_token = jwtDecode(response["access_token"]);
      sessionStorage.setItem("access_token", JSON.stringify(access_token));
      sessionStorage.setItem("header", response["access_token"]);
      sessionStorage.setItem(
        "refresh_token",
        response["refresh_token"]
      );
      this.loaderService.userRole = access_token["role"];

      if (access_token["role"] == constants.patientRole) {
        this.loginService.getPatientDetails().subscribe((resp: any) => {
          sessionStorage.setItem("userDetails", JSON.stringify(resp));
          this.loaderService.user_name = resp.first_name + " " + resp.last_name;
          this.loaderService.dob = resp.dob;
          this.spinner.show(false);
          this.loaderService.handleNavigationNodes(true);
          this.loaderService.updateLoginStatus(true);
          // this.loaderService.highlightLeftMenu(["patient/confirm-appointment"]);
        });
      }
    }
  }


  // disabletheUnRegisterDiv() {
  //   this.forgotPassword = false;
  //   this.showRegister = false;
  //   this.forgetPasswordMailSent = false;
  // }
  ShowConfirmationTab(event: any) {
    if ((this.authType.value == "1" || this.authType.value == "2") && this.loaderService.userRole !== constants.nurseRole) {
      this.bookingComment = event.bookingComment;
      this.saveAppoinmentGuestFlowafterLogin(event);
    } else {
      this.saveAppointmentNormalFlow(event);
    }
  }
  saveAppointmentNormalFlow(event: any) {
    const access_token = JSON.parse(sessionStorage.getItem("access_token"));
    let a_data;
    let payload;
    const isoDate = new Date(this.stepperCollection.appointment);

    payload = {
      slot_group_id: this.slotGroupId,
      slot_time: moment.utc(isoDate).local().format('YYYY-MM-DDTHH:mm:SS') + "Z",
      capacity: 0,
      tests: [this.selectedtest?.id],
      test_comments: {
        [(this.selectedtest?.id).toString()]: event.bookingComment,
      },
    };
    let docs: any[] = [];
    this.documents.forEach((element: any) => {
      if (element.PrescriptionParts.length > 0) {
        let doc = {
          prescriptions_pages: element.PrescriptionParts.map(
            (s: any) => s.prescriptionPartData
          ),
        };
        docs.push(doc);
      }
    });
    a_data = {
      lab: this.selectedLab,
      test: this.selectedtest,
      s_time: this.stepperCollection.appointment,
      slot_group_id: this.slotGroupId,
      slot_time: moment.utc(isoDate).local().format('YYYY-MM-DDTHH:mm:SS') + "Z",
      tests: [this.selectedtest.id],
      favourite_lab: this.favoroteSamplingStationids,
      prescriptions: docs,
      sampling_station_id: this.s_id,
      test_comments: event.bookingComment,
      user_name: this.loaderService.user_name,
      dob: this.loaderService.dob,
      fromAppointmentLogin: false,
    };
    payload["prescriptions"] = docs;
    payload["sampling_station_id"] = this.s_id;

    sessionStorage.setItem("a_data", JSON.stringify(a_data));
    // this.disabletheUnRegisterDiv();
    if (event.isConfirmed) {
      // if it is logged in then it will save appointment
      // const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
      // this.loaderService.user_name= this.userDetails.first_name +
      // " " +
      // this.userDetails.last_name +
      // " " +
      // this.userDetails.birth_name;
      // this.loaderService.dob=this.userDetails.dob;
      if (access_token && access_token?.role == 1) {
        // receptionist is booking appointment for customer
        payload["patient_id"] = this.loaderService.bookingPatientId;
      } else {
        payload["patient_id"] = this.loaderService.id;
      }
      this.spinner.show(true);
      this.patientService.saveAppointment(payload).subscribe(
        (response: any[]) => {
          this.authType.setValue("");
          response;
          this.spinner.show(false);

          this.isConfirmed = event.isConfirmed;
          this.showLogin = event.showLogin;
          this.showConfirmBooking = event.showConfirmBooking;
          this.bookingComment = event.bookingComment;
          this.loaderService.rmMode = false;
          _paq.push(['trackEvent', 'Appointment Booked', 'Success', 'Success']);
          this.loaderService.handleNavigationNodes(true);
        },
        (errorResponse: HttpErrorResponse) => {
          if (errorResponse.error.internal_code === 425) {
            this.spinner.show(false);
            this.messageService.alert(
              this.translate.instant("view-booking.already-booked-error"),
              5000
            );
          } else if (errorResponse.error.internal_code === 424) {
            this.spinner.show(false);
            this.messageService.alert(
              this.translate.instant("view-booking.booking-full-error"),
              5000
            );
          } else {
            this.spinner.show(false);
            this.messageService.alert(
              this.translate.instant(
                "Common.GlobalMessages.InternalServerErrorMessage"
              ),
              5000
            );
          }
        }
      );
    } else {
      this.finalStep = "stepper-flow.confirm-booking";
      this.showLogin = event.showLogin;
      this.showRegister = event.showRegister;
      this.showConfirmBooking = event.showConfirmBooking;
      this.isConfirmed = event.isConfirmed;
      this.stepper.selected.interacted = false;
      this.bookingComment = event.bookingComment;
    }
  }

  loginSuccessfull() {
    this.loginSuccessful = true;
    // this.userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    // this.loaderService.user_name= this.userDetails.first_name +
    // " " +
    // this.userDetails.last_name +
    // " " +
    // this.userDetails.birth_name;
    // this.loaderService.dob=this.userDetails.dob;
    this.authType.setValue("1");
    this.stepperCollection.loggedIn = "Loggedin";
    _paq.push(['trackEvent', 'Login', 'Login Step Completed', 'Loggedin']);
    this.setState(this.fifthFormGroup, false);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.stepper.next();
    this.userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    if (
      (this.loaderService.bookingPatientId != "" &&
        this.loaderService.bookingPatientId != null) ||
      this.loaderService.userRole == constants.patientRole
    ) {
      this.showLoginorRegisterStep = false;
    } else {
      this.showLoginorRegisterStep = true;

      if (this.loaderService.userRole == constants.nurseRole) {
        this.showRegister = true;
      }
    }
    if (!this.showLoginorRegisterStep) {
      let idx: number = this.stepperTitles.indexOf(
        "stepper-flow.login-register"
      );
      if (idx > 0) {
        this.stepperTitles.splice(idx, 1);
      }
    }
  }
  saveAppoinmentGuestFlowafterLogin(event: any) {
    // const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));

    const that = this;
    this.favoroteSamplingStationids.forEach((value) => {
      const datasaveFavorite = {
        sampling_station_id: [value],
        patient_id: this.loaderService.id,
        is_active: true,
      };
      that.patientService
        .savePatientFavoriteSamplingStations(datasaveFavorite)
        .subscribe();
    });

    let payload;
    let docs: any[] = [];
    this.documents.forEach((element: any) => {
      if (element.PrescriptionParts.length > 0) {
        let doc = {
          prescriptions_pages: element.PrescriptionParts.map(
            (s: any) => s.prescriptionPartData
          ),
        };
        docs.push(doc);
      }
    });
    if (this.slotGroupId > 0) {
      payload = {
        slot_group_id: this.slotGroupId,
        slot_time: moment.utc(new Date(this.selectedAppointment)).local().format('YYYY-MM-DDTHH:mm:SS') + "Z",
        tests: [this.selectedtest.id],
        patient_id: this.loaderService.id,
        prescriptions: docs,
        sampling_station_id: this.s_id,
        test_comments: {
          [this.selectedtest.id.toString()]: event.bookingComment,
        },
      };
    } else {
      payload = {
        tests: [this.selectedtest.id],
        patient_id: this.loaderService.id,
        prescriptions: docs,
        sampling_station_id: this.s_id,
        slot_time: moment.utc(new Date(this.slotTime.value)).local().format('YYYY-MM-DDTHH:mm:SS') + "Z",
        test_comments: {
          [this.selectedtest.id.toString()]: event.bookingComment,
        },
      };
    }
    // this.loaderService.user_name= this.userDetails.first_name +
    // " " +
    // this.userDetails.last_name +
    // " " +
    // this.userDetails.birth_name;
    // this.loaderService.dob=this.userDetails.dob;
    payload["user_name"] = this.loaderService.user_name;
    payload["dob"] = this.loaderService.dob;
    payload["test"] = this.selectedtest;
    payload["fromRegister"] = false;
    const isoDate = new Date(this.stepperCollection.appointment);
    let a_data = {
      lab: this.selectedLab,
      test: this.selectedtest,
      s_time: this.stepperCollection.appointment,
      slot_group_id: this.slotGroupId,
      slot_time: moment.utc(isoDate).local().format('YYYY-MM-DDTHH:mm:SS') + "Z",
      tests: [this.selectedtest.id],
      user_name: this.loaderService.user_name,
      dob: this.loaderService.dob,
      test_comments: event.bookingComment,
      fromAppointmentLogin: true,
    };
    sessionStorage.setItem("a_data", JSON.stringify(a_data));
    this.spinner.show(true);
    this.patientService.saveAppointment(payload).subscribe(
      (response: any[]) => {
        this.authType.setValue("");
        this.spinner.show(false);
        this.loaderService.rmMode = false;

        response;
        _paq.push(['trackEvent', 'Appointment Booked', 'Success', 'Success']);
        this.loaderService.handleNavigationNodes(true);
      },
      (errorResponse: HttpErrorResponse) => {
        if (errorResponse.error.internal_code === 425) {
          this.spinner.show(false);
          this.messageService.alert(
            this.translate.instant("view-booking.already-booked-error"),
            5000
          );
        } else if (errorResponse.error.internal_code === 424) {
          this.spinner.show(false);
          this.messageService.alert(
            this.translate.instant("view-booking.booking-full-error"),
            5000
          );
        } else {
          this.spinner.show(false);
          this.messageService.alert(
            this.translate.instant(
              "Common.GlobalMessages.InternalServerErrorMessage"
            ),
            5000
          );
        }
      }
    );
  }
  onSend() {
    this.registeredEmail.markAllAsTouched();
    if (!this.registeredEmail.invalid) {
      const inputValue = {
        email: this.RegisteredEmail.nativeElement.value,
      };
      this.username = inputValue.email;
      this.resetPasswordService.postAuthforget(inputValue).subscribe(
        (inputValue) => {
          console.log(inputValue);
          this.forgetPasswordMailSent = true;
          this.forgotPassword = false;
        },
        (error) => {
          console.log(error);
          this.forgetPasswordMailSent = true;
          this.forgotPassword = false;
        }
      );
    }
  }

  RegisterSuccessful() {
    let payload = JSON.parse(sessionStorage.getItem("p_data"));
    this.loaderService.user_name =
      payload.FirstName + " " + payload.LastName + " " + payload.BirthName;
    this.loaderService.dob = payload.Dob;
    setTimeout(() => {
      this.stepperCollection.loggedIn = "";
      this.authType.setValue("2");

      this.showConfirmBooking = true;
      this.showActivateMessage = true;

      _paq.push(['trackEvent', 'Register', 'Register Step Completed', 'register']);
      this.setState(this.fifthFormGroup, false);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      this.registerSuccessful = true;// For Chrome, Firefox, IE and Opera
      this.stepper.next();
    }, 500);


    // this.isConfirmed = true;
    // this.showRegister = false;
    // this.loaderService.highlightLeftMenu(["patient/confirm-appointment"]);
  }
  public ResetPasswordPopup() {
    const data = {
      username: this.username,
      activationLink: this.appSettings.patientBaseUrl + "/?mail/verify",
    };
    this.messageService.activation(data).subscribe((actionResult: boolean) => {
      if (actionResult) {
        this.showRegister = false;
        this.isConfirmLinkClicked = true;
        this.loaderService.loggedIn = true;
      }
    });
  }

  clearNextSteps(stepper: any): void {
    const index = stepper.selectedIndex;
    if (this.flow == 1) {
      switch (index) {
        case 0: {
          this.stepperCollection.test = "";
          this.stepperCollection.laboratory = "";
          this.stepperCollection.appointment = "";
          this.stepperCollection.prescription = "";
          this.stepperCollection.loggedIn = "";
          this.clearFile = !this.clearFile;
          this.loaderService.openNowClicked = false;
          this.setState(this.firstFormGroup, true);
          this.setState(this.secondFormGroup, true);
          this.setState(this.thirdFormGroup, true);
          this.setState(this.forthFormGroup, true);
          this.setState(this.fifthFormGroup, true);
          break;
        }
        case 1: {
          this.stepperCollection.laboratory = "";
          this.stepperCollection.appointment = "";
          this.stepperCollection.loggedIn = "";
          this.clearFile = !this.clearFile;
          this.loaderService.openNowClicked = false;
          this.setState(this.secondFormGroup, true);
          this.setState(this.thirdFormGroup, true);
          if (this.showPrescription) {
            this.stepperCollection.prescription = "";
            this.setState(this.forthFormGroup, true);
          }
          this.setState(this.fifthFormGroup, true);
          break;
        }
        case 2: {
          this.stepperCollection.appointment = "";

          this.stepperCollection.loggedIn = "";
          this.clearFile = !this.clearFile;
          this.setState(this.thirdFormGroup, true);
          if (this.showPrescription) {
            this.stepperCollection.prescription = "";
            this.setState(this.forthFormGroup, true);
          }
          this.setState(this.fifthFormGroup, true);
          break;
        }
        case 3: {
          this.stepperCollection.loggedIn = "";

          this.clearFile = !this.clearFile;
          if (this.showPrescription) {
            this.stepperCollection.prescription = "";
            this.setState(this.forthFormGroup, true);
          }
          this.setState(this.fifthFormGroup, true);
          break;
        }
        case 4: {
          this.stepperCollection.loggedIn = "";
          this.setState(this.fifthFormGroup, true);
        }
      }
    } else {
      switch (index) {
        case 0: {
          this.stepperCollection.test = "";
          this.stepperCollection.laboratory = "";
          this.stepperCollection.appointment = "";
          this.stepperCollection.prescription = "";
          this.stepperCollection.loggedIn = "";
          this.clearFile = !this.clearFile;
          this.loaderService.openNowClicked = false;
          this.setState(this.firstFormGroup, true);
          this.setState(this.secondFormGroup, true);
          this.setState(this.thirdFormGroup, true);
          this.setState(this.forthFormGroup, true);
          this.setState(this.fifthFormGroup, true);
          this.hasCalendar = false;
          this.noCalendar = false;
          break;
        }
        case 1: {
          this.stepperCollection.test = "";
          this.stepperCollection.appointment = "";
          // this.stepperCollection.prescription = "";
          this.stepperCollection.loggedIn = "";
          this.clearFile = !this.clearFile;
          this.setState(this.secondFormGroup, true);
          this.setState(this.thirdFormGroup, true);
          if (this.showPrescription) {
            this.stepperCollection.prescription = "";
            this.setState(this.forthFormGroup, true);
          }
          this.setState(this.fifthFormGroup, true);
          this.hasCalendar = false;
          this.noCalendar = false;
          break;
        }
        case 2: {
          this.stepperCollection.appointment = "";
          this.stepperCollection.prescription = "";
          this.stepperCollection.loggedIn = "";
          this.clearFile = !this.clearFile;
          this.setState(this.thirdFormGroup, true);
          if (this.showPrescription) {
            this.stepperCollection.prescription = "";
            this.setState(this.forthFormGroup, true);
          }
          this.setState(this.fifthFormGroup, true);
          break;
        }
        case 3: {
          this.stepperCollection.loggedIn = "";
          this.clearFile = !this.clearFile;
          if (this.showPrescription) {
            this.stepperCollection.prescription = "";
            this.setState(this.forthFormGroup, true);
          }
          this.setState(this.fifthFormGroup, true);
          break;
        }
        case 4: {
          this.stepperCollection.loggedIn = "";
          this.setState(this.fifthFormGroup, true);
          break;
        }
      }
    }
    if (stepper.previouslySelectedIndex > stepper.selectedIndex) {
      stepper.previouslySelectedStep.interacted = false;
    }
  }
  protected getUpdateDocument(event: any) {
    this.uploadStatus = false;
    let currDoc = this.documents.find((i) => i.Id == event.Id);
    if (currDoc) {
      currDoc = event;
    }
    const found = this.documents.some(
      (el) => el.PrescriptionParts.length !== 0
    );
    if (!found) {
      this.noDocuments = true;
    } else {
      this.noDocuments = false;
    }
  }
  protected getClickImageStatus(event: any) {
    this.isClicked = event;
    this.uploadStatus = false;
  }

  ngOnDestroy() {
    // Reset the service variable when the component is destroyed
    this.loaderService.rmMode = false;
    this.loaderService.rm_id = "";
    //this.loaderService.id = "";
  }
}
