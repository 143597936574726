import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../../app.locale.service';
import { LoaderService } from '../../../../main';

@Component({
    selector: 'app-results-ready',
    templateUrl: './results-ready.component.html',
    styleUrls: ['./results-ready.component.scss']
})
export class ResultsReadyComponent implements OnInit {
    @Input() displayedColumns: any;
    @Input() dataSource: any;
    @Output() showResult = new EventEmitter();

    constructor(
        private translate: TranslateService,
        private localeService: LocaleService,
        public loader: LoaderService,
    ) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    ngOnInit(): void { }

}
