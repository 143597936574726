import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { MaterialModule, SharedModule } from '../../shared';
import { OrgNamePipe } from '../pipes/org-name.pipe';
import { SamplingStationsNamePipe } from '../pipes/sampling-stations-name.pipe';
import { EClinicalSharedModule } from '../shared/shared.module';
import { AddPatientComponent } from './add-patient/add-patient.component';
import { QrCodeDialogComponent } from './add-patient/qr-code-dialog/qr-code-dialog.component';
import { AddSamplingComponent } from './add-sampling/add-sampling.component';
import { AddTestComponent } from './add-test/add-test.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { AddCalendarComponent } from './booking/add-calendar/add-calendar.component';
import { BookingSetupComponent } from './booking/booking-setup/booking-setup.component';
import { SpecialDatesComponent } from './booking/special-dates/special-dates.component';
import { ViewBookingComponent } from './booking/view-booking/view-booking.component';
import { BookingCalendarComponent } from './booking-calendar/booking-calendar.component';
import { CommonDialogComponent } from './common-dialog/common-dialog.component';
import { CompanyComponent } from './company/company.component';
import { CompanyDialogComponent } from './company-dialog/company-dialog.component';
import { PatientsComponent } from './patients/patients.component';
import { SamplingStationComponent } from './sampling-station/sampling-station.component';
import { TestComponent } from './test/test.component';
import { TestHistoryComponent } from './test-history/test-history.component';
import { TestTypeComponent } from './test-type/test-type.component';
import { UserComponent } from './user/user.component';
import { AddTestTypeComponent } from './add-test-type/add-test-type.component';
import { MainPipe } from '../../shared/MainPipe.module';
import { LandingPageConfigComponent } from './landing-page-config/landing-page-config.component';
import { HeroBannerComponent } from './landing-page-config/hero-banner/hero-banner.component';
import { KeyFeatureComponent } from './landing-page-config/key-feature/key-feature.component';
import { OurPresenceComponent } from './landing-page-config/our-presence/our-presence.component';
import { FrequentlyBookedTestComponent } from './landing-page-config/frequently-booked-test/frequently-booked-test.component';
import { CompanyInfoComponent } from './landing-page-config/company-info/company-info.component';
import { SeoComponent } from './landing-page-config/seo/seo.component';
import { PatientJourneyComponent } from './landing-page-config/patient-journey/patient-journey.component';
import { PowerBiReportComponent } from './power-bi-report/power-bi-report.component';
import { ManageHolidayComponent } from './manage-holiday/manage-holiday.component';
import { AddHolidayComponent } from './manage-holiday/add-holiday/add-holiday.component';
import { SsSlotConfigurationComponent } from './ss-slot-configuration/ss-slot-configuration.component';
import { AddSlotConfigurationComponent } from './ss-slot-configuration/add-slot-configuration/add-slot-configuration.component';
import { PreviewConfigurationComponent } from './ss-slot-configuration/preview-configuration/preview-configuration.component';
import { AddUnavailabilityComponent } from './ss-slot-configuration/add-unavailability/add-unavailability.component';
@NgModule({
    imports: [
        AdminRoutingModule,
        FormsModule,
        MaterialModule,
        CommonModule,
        RouterModule,
        MainPipe,
        ReactiveFormsModule,
        DragDropModule,
        TranslateModule,
        SharedModule,
        EClinicalSharedModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        NgxMatIntlTelInputModule
    ],
    declarations: [
        UserComponent,
        AdminComponent,
        CompanyComponent,
        TestComponent,
        CompanyDialogComponent,
        AddUserComponent,
        TestTypeComponent,
        BookingCalendarComponent,
        AddTestComponent,
        BookingSetupComponent,
        SpecialDatesComponent,
        AddCalendarComponent,
        ViewBookingComponent,
        SamplingStationsNamePipe,
        OrgNamePipe,
        AddSamplingComponent,
        SamplingStationComponent,
        CommonDialogComponent,
        PatientsComponent,
        AddPatientComponent,
        TestHistoryComponent,
        QrCodeDialogComponent,
        AddTestTypeComponent,
        LandingPageConfigComponent,
        HeroBannerComponent,
        KeyFeatureComponent,
        OurPresenceComponent,
        FrequentlyBookedTestComponent,
        CompanyInfoComponent,
        SeoComponent,
        PatientJourneyComponent,
        PowerBiReportComponent,
        ManageHolidayComponent,
        AddHolidayComponent,
        SsSlotConfigurationComponent,
        AddSlotConfigurationComponent,
        PreviewConfigurationComponent,
        AddUnavailabilityComponent
    ]
})
export class AdminModule { }
