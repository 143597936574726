import { DatePipe, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { CalendarEvent, CalendarEventTitleFormatter, CalendarView, CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';
import { ViewPeriod } from 'calendar-utils';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { LocaleService } from '../../../app.locale.service';
import { LoaderService } from '../../../main';
import { ViewBookingComponent } from '../../admin/booking/view-booking/view-booking.component';
import { CommonApiService } from '../../services/common-api.service';
import { CustomEventTitleFormatter } from './event-title-formatter.provider';
import { constants } from '../constant';

const colors: any = {
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF'
    }
};

@Component({
    selector: 'app-calendar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    providers: [
        {
            provide: CalendarEventTitleFormatter,
            useClass: CustomEventTitleFormatter
        }
    ]
})
export class CalendarComponent implements OnInit, OnChanges {
    @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
    @Input() calendarSlots: any;
    @Input() selectedWeek: string;
    @Input() ss_id: string;
    @Input() showCalendar: boolean;
    @Input() calendar: any;
    @Input() localeLang: any;
    @Input() bookedSlotsCount: any;
    @Input() fromModal = false;
    @Input() fromAppointment = false;
    @Input() ss_opening_from: string;
    @Input() ss_opening_to: string;
    @Output() fromModalEvent = new EventEmitter();
    view: CalendarView = CalendarView.Week;

    CalendarView = CalendarView;
    calendarRender: CalendarWeekViewBeforeRenderEvent;
    viewDate: Date = new Date();
    today: Date = new Date();
    modalData: {
        action: string;
        event: CalendarEvent;
    };
    events: CalendarEvent[] = [];
    viewPeriod: ViewPeriod;
    refresh = new Subject<void>();
    changeCalendar = false;
    minStartTime = 1;
    maxStartTime = 23;
    isDisabled = true;
    isDisabledNext = false;
    // locale: string = this.localeService.localeLang;
    @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
    constructor(
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private translate: TranslateService,
        public localeService: LocaleService,
        private commonAPi: CommonApiService,
        private datepipe: DatePipe,
        public loader: LoaderService
    ) {
        if (!this.localeLang) {
            this.localeLang = this.localeService.localeLang;
        }
        this.translate.use(this.localeLang);
        registerLocaleData(this.localeLang == 'fr' ? localeFr : localeEn);
        if (this.viewDate <= this.today) {
            this.isDisabled = true;
        }
    }
    ngOnInit(): void {
        this.disablePreviousWeek();
        if (this.viewDate <= this.today) {
            this.isDisabled = true;
        }
        this.calculateDayStartEndHour();
        registerLocaleData(this.localeLang == 'fr' ? localeFr : localeEn);
    }
    disablePreviousWeek(event?: any) {
        event;
        if (this.viewDate <= this.today) {
            this.isDisabled = true;
        } else {
            this.isDisabled = false;
        }
        // if(this.checkss(moment(this.viewDate).add(7,'day'))){
        //     this.isDisabledNext = true;
        // }else{
        //     this.isDisabledNext = false;
        // }
        this.viewDateChange.emit(this.viewDate);

    }
    // openFromUntil(event?:any){
    //     if(this.viewDate)
    // }

    todayClick() {
        const today = new Date();
        const curYear = today.getFullYear();
        const pipeval = Number(this.datepipe.transform(today, 'w'));
        const weekval: any = this.commonAPi.getDateOfISOWeek(pipeval, curYear);
        this.viewDateChange.emit(weekval);

    }

    // dateIsValid(date: any) {
    //     return this.checkss(moment()) ? true :
    //     (!this.loader.loggedIn || (this.loader.loggedIn && this.loader.userRole == 4)) ?
    //         (moment(date).isBefore(moment().add(2, 'h')) && this.checkss(moment())) : (moment(date).isBefore(moment()) && this.checkss(moment()));
    // }

    dateIsValid(date: any) {
        return (!this.loader.loggedIn || (this.loader.loggedIn && this.loader.userRole) == constants.patientRole) ?
            moment(date).isBefore(moment().add(2, 'h')) : moment(date).isBefore(moment());
    }

    // checkss(date: any): boolean {
    //     // console.log(date.isSameOrAfter(moment(this.ss_opening_from)));
    //     // console.log(date.isSameOrBefore(moment(this.ss_opening_to)));
    //     return !(date.isSameOrAfter(moment(this.ss_opening_from)) && date.isSameOrBefore(moment(this.ss_opening_to)));
    // }
    ngOnChanges(): void {
        if (this.viewDate <= this.today) {
            this.isDisabled = true;
        }
        // this.localeLang=this.localeService.localeLang;
        registerLocaleData(this.localeLang == 'fr' ? localeFr : localeEn);
        if (this.selectedWeek) {
            this.viewDate = this.selectedWeek.toString().includes('-') ? new Date(this.selectedWeek.replace(/-/g, '/')) : new Date(this.selectedWeek);
        }
        if (this.calendar) {
            this.calculateDayStartEndHour();
            this.changeCalendar = true;
        } else {
            this.calendarSlots = {
                'sundaySlots': [],
                'mondaySlots': [],
                'tuesdaySlots': [],
                'wednesdaySlots': [],
                'thursdaySlots': [],
                'fridaySlots': [],
                'saturdaySlots': []
            };

        }
        this.events.push({
            title: '',
            color: colors.blue.secondary,
            start: moment(new Date()).add(13, 'hours').toDate(),
            end: moment(new Date()).add(13, 'hours').add(30, 'minutes').toDate(),
            cssClass: 'calendar-blocked-event'
        });
        this.refresh.next();
    }

    beforeViewRender(body: CalendarWeekViewBeforeRenderEvent): void {
        this.calendarRender = body;
        body.hourColumns.forEach(hourCol => {
            hourCol.hours.forEach(hour => {
                hour.segments.forEach(segment => {
                    if (this.dateIsValid(segment.date)) {
                        segment.cssClass = 'calendar-blocked-event';
                    }
                });
            });
        });
    }
    updateCalendarEvents(viewRender: CalendarWeekViewBeforeRenderEvent): void {
        this.calendarRender = viewRender;
        this.beforeViewRender(viewRender);
        if (
            !this.viewPeriod || this.changeCalendar ||
            !moment(this.viewPeriod.start).isSame(viewRender.period.start) ||
            !moment(this.viewPeriod.end).isSame(viewRender.period.end)
        ) {
            this.changeCalendar = false;
            this.viewPeriod = viewRender.period;
            this.events = [];
            this.generateEvents(this.calendarSlots['sundaySlots'], 0, viewRender);
            this.generateEvents(this.calendarSlots['mondaySlots'], 1, viewRender);
            this.generateEvents(this.calendarSlots['tuesdaySlots'], 2, viewRender);
            this.generateEvents(this.calendarSlots['wednesdaySlots'], 3, viewRender);
            this.generateEvents(this.calendarSlots['thursdaySlots'], 4, viewRender);
            this.generateEvents(this.calendarSlots['fridaySlots'], 5, viewRender);
            this.generateEvents(this.calendarSlots['saturdaySlots'], 6, viewRender);
            this.cdr.detectChanges();
        }
    }

    selectSlot(e: any) {
        if (!this.fromModal && !this.fromAppointment) {
            // var calSlots = Object.assign({}, this.calendarSlots);
            this.dialog.open(ViewBookingComponent, {
                hasBackdrop: false,
                maxWidth: '95vw',
                width: '100%',
                height: '95vh',
                data: {
                    edit: false,
                    slotDuration: this.calendar,
                    calendarSlots: this.calendarSlots,
                    calendar: this.calendar,
                    ss_id: this.ss_id,
                    s_time: e.event.start,
                    bookedSlotsCount: this.bookedSlotsCount,
                    selectedWeek: this.selectedWeek
                }
            }).afterClosed().subscribe(() => {
                this.viewDateChange.emit(this.viewDate);
                // GK: Will remove commented code once testing done.
                // var td = new Date(this.selectedWeek);
                // var ws_date = new Date(td.getFullYear(), td.getMonth(), td.getDate() - 1, 0, 0, 1);
                // var we_date = new Date(td.getFullYear(), td.getMonth(), td.getDate(), 23, 59, 59);
                // we_date.setDate(we_date.getDate() + 7);
                // this.commonAPi.GetCalenderBookedSlots(this.calendar.id, this.datepipe.transform(ws_date, "yyyy-MM-ddThh:mm:ss").toString() + 'Z', this.datepipe.transform(we_date, "yyyy-MM-ddThh:mm:ss").toString() + 'Z').subscribe(x => {
                //   this.calendarSlots = Object.assign({}, calSlots);
                //   this.bookedSlotsCount = x;
                //   this.changeCalendar = true;
                //   this.updateCalendarEvents(this.calendarRender);
                // });

                // this.viewDateChange.emit(this.viewDate);

            });
        } else {
            this.fromModalEvent.emit(e);
        }
    }

    calculateDayStartEndHour() {
        if (this.calendar != null && this.calendar?.dows) {
            this.minStartTime = Number((this.calendar['dows'][0].start_time).substring(0, 2));
            this.maxStartTime = Number((this.calendar['dows'][0].end_time).substring(0, 2));
            this.calendar['dows'].forEach((day: any) => {
                this.minStartTime = Number((day.start_time).substring(0, 2)) < this.minStartTime ? Number((day.start_time).substring(0, 2)) : this.minStartTime;
                this.maxStartTime = Number((day.end_time).substring(0, 2)) > this.maxStartTime ? Number((day.end_time).substring(0, 2)) : this.maxStartTime;
            });
        }
    }

    generateEvents(daySlots: any, byweekday: number, viewRender: CalendarWeekViewBeforeRenderEvent) {

        if (daySlots) {

            daySlots.forEach((slot: string) => {
                const date = moment(new Date(viewRender.period.start).setHours(Number(slot.split(':')[0]), Number(slot.split(':')[1]))).add(byweekday, 'days').toDate();
                let bookedSlots;
                if (this.bookedSlotsCount) {
                    bookedSlots = this.bookedSlotsCount.find((slot: any) => (moment(slot['slot']).toDate().getTime() === date.getTime()));
                }
                /* if (slot == '00:00:00') {
          date = moment(date).subtract(1, 'day').toDate();
        } */
                let cssClass = 'calendar-general-event';
                if (bookedSlots && bookedSlots['patientcount'] >= this.calendar['capacity']) {
                    cssClass = (JSON.parse(sessionStorage.getItem('access_token')) && (JSON.parse(sessionStorage.getItem('access_token'))['role'] == 5 || JSON.parse(sessionStorage.getItem('access_token'))['role'] == 3))
                        ? 'calendar-booked-event-admin' : 'calendar-booked-event';
                }
                if (!this.dateIsValid(date)) {
                    this.events.push({
                        title: '',
                        start: moment(date).toDate(),
                        cssClass
                    });
                }
            });
        }
    }
}
