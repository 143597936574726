

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { AppSettingsService } from '../../core';

@Injectable({
    providedIn: 'root'
})
export class AddCompanyService {
    appData: any;
    constructor(private http: HttpClient, private readonly appSettings: AppSettingsService) {

    }
    ValidateExternalID(external_id: string) {

        return this.http.get<any>(this.appSettings.adminBaseUrl + 'company/isvalidexternalid?external_id=' + external_id);

    }

    postAdminCompany(inputValue: any, action: any) {
        console.log(inputValue, action);
        if (action == 'add') {
            return this.http.post(this.appSettings.adminBaseUrl + 'company', inputValue);
        } else if (action == 'edit') {
            return this.http.put(this.appSettings.adminBaseUrl + 'company', inputValue);
        } else if (action == 'delete') {
            return this.http.put(this.appSettings.adminBaseUrl + 'company', inputValue);
        } else {
            return null;
        }
    }

}
