import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from '../../../../main/services/loader.service';
import { ProfessionalsService } from '../professionals.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../../../main';
import { SpinnerService } from '../../../../core/spinner/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-professional',
  templateUrl: './add-professional.component.html',
  styleUrl: './add-professional.component.scss'
})
export class AddProfessionalComponent implements OnInit {

  @Input() sliderFormData: any;
  @Input() public isSliderDataEdited = false;

  saveAlert = {
    edit: 'ehp.Hp-save-alert.edit',
    add: 'ehp.Hp-save-alert.add'
  };
  ehp = {
    editConfirmationMessage: 'ehp.healthcare-professionals.edit-confirmation-message',
    editConfirmationTitle: 'ehp.healthcare-professionals.edit-confirmation-title',
    cancel: 'ehp.cancel',
    confirm: 'ehp.confirm',
    externalIDExists: 'ehp.externalIdExists',
    internalServerError: 'ehp.internal-server-error',
    unauthorized: 'ehp.unauthorized',
  }

  sampleData: { [key: string]: string } = {
    true: 'Active',
    null: 'Pending',
    false: 'Suspended'
  };


  ProfestionalFrom = new FormGroup({
    name: new FormControl('', [Validators.required,
    Validators.pattern(".{1,50}$"), this.loader.validateAllowedCharacters()
    ]),
    is_active: new FormControl(null, Validators.required),
    adeli: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
    rpps: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
    external_code: new FormControl('', Validators.required),
    hp_type: new FormControl('', Validators.required)
  })
  userTypes: any = [];
  refresh: boolean;

  constructor(
    public loader: LoaderService,
    public professionService: ProfessionalsService,
    private spinnerService: SpinnerService,
    public translate: TranslateService,
    public messageService: MessageService,
    public router: Router

  ) { }
  ngOnInit(): void {
    this.professionService.getMastersByCategory('ehptype').subscribe((res: any) => {
      res.sort((a: any, b: any) => a.KeyName.localeCompare(b.KeyName));
      this.userTypes = res;
    })

    if (this.sliderFormData[0].edit) {
      // disable correspondant code in edit mode if HP is not created using UI
      if (this.sliderFormData[0].professionalData?.is_from_api) {
        this.ProfestionalFrom.controls.external_code.disable();
      }

      //patch values
      this.ProfestionalFrom.setValue({
        name: this.sliderFormData[0].professionalData.name,
        is_active: this.sliderFormData[0].professionalData.is_active ? 'true' : this.sliderFormData[0].professionalData.is_active == null ? 'null' : 'false',
        adeli: this.sliderFormData[0].professionalData.adeli,
        rpps: this.sliderFormData[0].professionalData.rpps,
        external_code: this.sliderFormData[0].professionalData.external_code,
        // code: this.sliderFormData[0].professionalData.code || '',
        hp_type: this.sliderFormData[0].professionalData.hp_type,
      })
    }

  }

  dismiss() {
    this.loader.cancelForm();
  }
  reset() {
    if (this.sliderFormData[0].edit) {
      this.dismiss();
    } else {
      this.ProfestionalFrom.reset();
      this.ProfestionalFrom.markAsUntouched();
    }
  }
  onSave() {
    // while creating a new HP is cative should always true and to make form valid setting value
    let is_active = this.ProfestionalFrom.value.is_active == 'null' ? 'null' : (this.ProfestionalFrom.value.is_active == 'false' ? false : true)
    this.ProfestionalFrom.get("is_active").setValue(is_active);
    if (this.ProfestionalFrom.valid) {
      let profesionalData = {
        name: this.ProfestionalFrom.value.name,
        is_active: this.ProfestionalFrom.value.is_active == 'null' ? null : this.ProfestionalFrom.value.is_active,
        adeli: this.ProfestionalFrom.value.adeli,
        rpps: this.ProfestionalFrom.value.rpps,
        external_code: this.ProfestionalFrom.value.external_code,
        // code: this.ProfestionalFrom.value.code || '',
        hp_type: Number(this.ProfestionalFrom.value.hp_type),
      }

      // edit professionals
      if (this.sliderFormData[0].edit && this.sliderFormData[0].professionalData.id) {
        profesionalData['id'] = this.sliderFormData[0].professionalData.id;
        profesionalData['external_code'] = this.sliderFormData[0].professionalData?.is_from_api ? this.sliderFormData[0].professionalData.external_code : this.ProfestionalFrom.value.external_code;

        this.messageService.confirm(
          this.translate.instant(this.ehp.editConfirmationMessage).toString(),
          this.translate.instant(this.ehp.editConfirmationTitle).toString(),
          this.translate.instant(this.ehp.confirm).toString(),
          this.translate.instant(this.ehp.cancel).toString()
        ).subscribe((actionResult: boolean) => {
          if (actionResult) {
            this.spinnerService.show(true);
            this.professionService.editProfessionals(profesionalData).subscribe(
              () => {
                this.spinnerService.show(false);
                this.messageService.success(
                  this.translate.instant(this.saveAlert[this.sliderFormData[0].action])
                );
                this.professionService.triggerRefresh();
                this.dismiss()
              },
              (errorResponse: HttpErrorResponse) => {
                this.spinnerService.show(false);
                if (errorResponse.error.internal_code == 2001) {
                  this.messageService.alert(
                    this.translate.instant(this.ehp.externalIDExists),
                    10000
                  );
                } else if (errorResponse.error.code == 401) {
                  this.messageService.alert(
                    this.translate.instant(this.ehp.unauthorized),
                    10000
                  );
                }
                else {
                  this.messageService.alert(
                    this.translate.instant(this.ehp.internalServerError),
                    10000
                  );
                }
              }
            );
            return
          }
        });
      }
      // Add or Create professionals 
      else {
        this.spinnerService.show(true);
        this.professionService.addProfessionals(profesionalData).subscribe(
          () => {
            this.spinnerService.show(false);
            this.messageService.success(
              this.translate.instant(this.saveAlert[this.sliderFormData[0].action])
            )
            this.professionService.triggerRefresh();
            this.dismiss()
          },
          (errorResponse: HttpErrorResponse) => {
            this.spinnerService.show(false);
            if (errorResponse.error.internal_code === 2001) {
              this.messageService.alert(
                this.translate.instant(this.ehp.externalIDExists),
                10000
              );
            } else if (errorResponse.error.code == 401) {
              this.messageService.alert(
                this.translate.instant(this.ehp.unauthorized),
                10000
              );
            }
            else {
              this.messageService.alert(
                this.translate.instant(this.ehp.internalServerError),
                10000
              );
            }
          }
        );
        return
      }
    }
  }
}
