import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhpRoutingModule } from './ehp-routing.module';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CalendarModule } from 'angular-calendar';
import { SharedModule } from "../../shared/shared.module";
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { ResultGridComponent } from './result-grid/result-grid.component';
import { PartialResultComponent } from './partial-result/partial-result.component';
import { EstablishementComponent } from './establishement/establishement.component';
import { AddEstablishmentComponent } from './add-establishment/add-establishment.component';
import { ListDepartmentComponent } from './list-department/list-department.component';
import { EditDepartmentComponent } from './edit-department/edit-department.component';
import { AddProfessionalComponent } from './healthcare-professional/add-professional/add-professional.component';
import { HealthcareProfessionalComponent } from './healthcare-professional/healthcare-professional.component';
import { EClinicalSharedModule } from '../shared/shared.module';
import { UserListComponent } from './user-list/user-list.component';
import { UserAddComponent } from './user-add/user-add.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';


@NgModule({
  declarations: [
    PartialResultComponent,
    ResultGridComponent,
    EstablishementComponent,
    AddEstablishmentComponent,
    ListDepartmentComponent,
    EditDepartmentComponent,
    HealthcareProfessionalComponent,
    AddProfessionalComponent,
    UserListComponent,
    UserAddComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    EhpRoutingModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule, FormsModule, MatMenuModule, MatCheckboxModule,
    MatFormFieldModule, MatDatepickerModule, CalendarModule, MatSelectModule,
    SharedModule, ReactiveFormsModule, MatInputModule, MatTooltipModule, MatPaginatorModule, TranslateModule,EClinicalSharedModule,
    NgxMatIntlTelInputModule
  ]
})
export class EhpModule { }
