import { formatDate } from '@angular/common';
import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
    constructor(@Inject(LOCALE_ID) private locale: string) {
        super();
    }

    month(event: CalendarEvent): string {
        return `<span>${formatDate(event.start, 'H:m', this.locale)}</span> ${
            event.title
        }`;
    }

    week(event: CalendarEvent): string {
        return `<span>${formatDate(event.start, 'HH:mm', this.locale)}</span> ${
            event.title
        }`;
    }

    day(event: CalendarEvent): string {
        return `<span>${formatDate(event.start, 'H:m', this.locale)}</span> ${
            event.title
        }`;
    }
}
