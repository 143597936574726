import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LanguageService } from '../cache/language.service';
import { ErrorHandlerService } from './../error-handler/error-handler.service';



@Injectable()
/** Service to Intercept Http request or response */
export class HttpInterceptorService implements HttpInterceptor {
    constructor(
        public errorHandler: ErrorHandlerService,
        public languageService: LanguageService
    ) {
        this.getAcceptedLanguage();
    }

    /* #region Public Methods */
    /** Method to intercept Http request or response */
    public intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!request.withCredentials) {
            const header = sessionStorage.getItem('header');


            if (header != null) {
                var headers: HttpHeaders = new HttpHeaders()
                    .set('Content-Type', 'application/json')
                    .set('Authorization', 'Bearer ' + sessionStorage.getItem('header'))
                    .set('tz', moment.tz.guess());
            } else {
                var headers: HttpHeaders = new HttpHeaders()
                    .set('Content-Type', 'application/json')
                    .set('tz', moment.tz.guess());
            }

            request = request.clone({ headers, withCredentials: true });
        }
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse) {
                    console.log(error);

                    switch (error.status) {
                        case 400:
                            // Bad Request
                            break;
                        case 401:
                            // Unauthorized
                            break;
                        case 403:
                            // Forbidden
                            break;
                        case 404:
                            // Not Found
                            break;
                        case 405:
                            // Method Not Allowed
                            break;
                        case 408:
                            // Request Timeout
                            break;
                        case 422:
                            // Unprocessable Entity
                            break;
                        case 500:
                            // Internal Server Error
                            break;
                        case 502:
                            // Bad Gateway
                            break;
                        case 504:
                            // Gateway Timeout
                            break;
                        case 0:
                            break;
                    }
                }
                return this.errorHandler.handleError(error);
            })
        );
    }
    /* #endregion */

    /* #region Private Methods */
    /** Method to get language from storage to set accept-language in headers */
    private getAcceptedLanguage(): string {
        return this.languageService.getLanguage().Name;
    }
    /* #endregion */
}
