<div class="Container">
  <div class="d-flex justify-content-between search-mr">
    <div class="d-flex Search">
      <div id="inputsearch">
        <mat-form-field>
          <mat-label>{{'company.id-name' | translate}}</mat-label>
          <input [(ngModel)]="searchinput" matInput (keydown.enter)="onSearch()"
            (keydown.space)="keyDownHandler($event)" />
        </mat-form-field>
      </div>
      <div class="">
        <button (click)="onSearch()" id="btn_search" style="margin-left: 15%" mat-raised-button color="primary"
          class="search-btn">
          {{'company.search' | translate}}
        </button>
      </div>

      <div class="mx-8" style="text-align: center">
        <button mat-button color="accent" id="btn_reset" class="ghost" (click)="reset()" style="margin-left: 15%">
          {{'company.reset' | translate}}
        </button>
      </div>

    </div>

    <div class="d-flex Button" style="margin-right: 2%;">
      <div id="statusfilter" style="text-align: center">
        <mat-form-field floatLabel="never" class="dropdown-filter">
          <mat-select placeholder="{{'admin.status' | translate}}" disableOptionCentering [(ngModel)]="status">
            <mat-option *ngFor="let stepOption of sampleData | keyvalue: unsorted" (click)="statusFilter()"
              [value]="stepOption.key">
              {{ stepOption.value | translate}}
            </mat-option>

          </mat-select>
        </mat-form-field>
      </div>
      <div class="mx-4">
        <button mat-raised-button color="primary" *ngIf="adminRole" id="btn_add" (click)="add()" class="company-btn">
          {{'company.add-company' | translate}}
        </button>
      </div>
    </div>

  </div>
  <div class="main-container main-container-res">
    <mat-table #table [dataSource]="dataSource" matSort class="table-height input-min-spacing table-flex"
      id="CompanyListingTable">
      <ng-container matColumnDef="Id">
        <mat-header-cell *matHeaderCellDef mat-sort-header="id">
          <span>{{'company.id' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity.id }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ExternalId">
        <mat-header-cell *matHeaderCellDef mat-sort-header="external_id">
          <span>{{'company.external-id' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity.external_id }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Name">
        <mat-header-cell *matHeaderCellDef mat-sort-header="org_name">
          <span>{{'company.name' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity" matTooltip="{{ entity.org_name}}">
          {{ entity.org_name }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Status">
        <mat-header-cell *matHeaderCellDef>
          <span>{{'admin.status' | translate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let entity"
          matTooltip="{{ entity.is_active ? ('admin.active' | translate) : ('admin.suspended' | translate) }}">
          {{ entity.is_active ? ('admin.active' | translate) : ('admin.suspended' | translate) }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="icon-column">
        <mat-header-cell *matHeaderCellDef>
          {{'company.options' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          <button class="mat-tbl-icon" [disabled]="!adminRole" (click)="edit($event, entity)" mat-icon-button>
            <span class="icon-ic-edit" matTooltip="{{'user.edit' | translate}}"></span>
          </button>
          <!-- <button class="mat-tbl-icon" *ngIf="entity.id == hoverindex"
                (click)="confirmDeleteWithYesNo( entity)" mat-icon-button>
                <span class="icon-ic-delete"></span>
            </button> -->
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="columnList; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnList; let i = index" class="element-grid-row"
        (mouseover)="rowMouseOver(row)">
      </mat-row>
    </mat-table>

    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons="true">
    </mat-paginator>
  </div>
</div>