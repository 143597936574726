<div class="register-toolkit">
    <div class="pull-right">
        <button mat-icon-button color="accent" (click)="dismiss()">
            <span class="icon-ic-close"></span>
        </button>
    </div>
    <div class="dialog-container">
        <h5>Register Toolkit</h5>
        <div class="dialog-content">
            <mat-form-field>
                <mat-label class="label-float">Test</mat-label>
                <mat-select>
                    <mat-option value="COVID">COVID 19 | PCR</mat-option>
                    <mat-option value="COVID">COVID 19 | PCR</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="scan-cover">
                <label>Scan Test Kit Code</label>
                <div class="image-cover">
                    <img src="https://via.placeholder.com/200/92c952"/>
                </div>                
            </div>
            <mat-form-field>
                <mat-label class="label-float">Test Kit ID</mat-label>
                <input matInput name="testKitId" id="testKitId" />
            </mat-form-field>
        </div>
        <div class="actions">
            <button mat-raised-button color="primary" type="submit">Register</button>
        </div>
    </div>
</div>

